import useApi, { QueryKey } from 'hooks/useApi'
import { useAuth } from 'hooks/useAuth'
import { useUserGroup } from 'hooks/useUserGroup'
import React, { useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import routesDictionary from 'routes'
import CompensationDataTable from 'shared/components/CompensationDataTable'
import { CompensationUnit } from 'shared/enums'
import dateFormat from 'shared/helper/dateFormat'
import { percentFormat } from 'shared/helper/numberFormats'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { CompensationData, MfaResponseData } from 'shared/interfaces'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'
import Button, { ButtonType } from '../../shared/components/Button'

interface CompensationSummaryData extends CompensationData {
	type: string
	input: {
		[key: string]: number
	}
}

const Summary: React.FC = () => {
	let { isHigherUp } = useUserGroup()

	const data = useRef<CompensationSummaryData>()
	const quitSalaryCompensation = useRef<boolean>(false)
	const quitBonusCompensation = useRef<boolean>(false)
	const { getMainPath, getChildPath, navigateTo } = useRouteHelper()
	let backButtonPath
	const queryClient = useQueryClient()

	if (undefined === data.current) {
		const cachedData = sessionStorage.getItem('deferredCompensationSimulationData')

		if (null !== cachedData) {
			data.current = JSON.parse(cachedData) as CompensationSummaryData

			switch (data.current.type) {
				case 'bonusFull':
					backButtonPath = `${getChildPath(routesDictionary.deferredCompensation, 'selectBonusAmount')}/${
						data.current.id
					}/${data.current.x}`
					break

				case 'bonusShare':
					quitBonusCompensation.current = 0 === Object.values(data.current.input)[0]
					backButtonPath = `${getChildPath(routesDictionary.deferredCompensation, 'bonus')}/${
						data.current.id
					}/${data.current.x}`
					break

				case 'salary':
					quitSalaryCompensation.current = 0 === Object.values(data.current.input)[0]
					backButtonPath = getChildPath(routesDictionary.deferredCompensation, 'salary')
			}
		}
	}

	useBackButtonPath(backButtonPath)
	useNavigationTitle(
		<Trans
			i18nKey={`view.deferredCompensation.pageTitle.summary.${
				'salary' === data?.current?.type ? 'salary' : 'bonus'
			}`}
		/>
	)

	const { t } = useTranslation()
	const api = useApi()
	const auth = useAuth()

	const renderDisclaimer = useMemo(() => {
		if (undefined === data.current) {
			return null
		}

		const disclaimer: React.ReactElement[] = []

		switch (data.current.type) {
			case 'salary':
				if (true === quitSalaryCompensation.current) {
					disclaimer.push(
						<Trans
							key="quitSalaryCompensation"
							i18nKey="view.deferredCompensation.disclaimer.quitSalaryCompensation"
							values={{ quitCompensationYear: data.current.year }}
						/>
					)
					break
				}

				if (true === data.current.isValueLessThanOnePercent) {
					disclaimer.push(
						<Trans
							key="isValueLessThanOnePercent"
							i18nKey="view.deferredCompensation.disclaimer.salary.isValueLessThanOnePercent"
							values={{ year: data.current.year }}
						/>
					)
				}

				if (data.current.unit === CompensationUnit.percent && !isHigherUp) {
					disclaimer.push(
						<Trans
							key="amount"
							i18nKey={`view.deferredCompensation.disclaimer.salary.${data.current.unit}`}
						/>
					)
				}

				disclaimer.push(<Trans key="default" i18nKey={`view.deferredCompensation.disclaimer.salary.default`} />)

				break

			default:
				disclaimer.push(
					<Trans
						key="bonus"
						i18nKey={`view.deferredCompensation.disclaimer.${
							false === quitBonusCompensation.current
								? /** sorry for the double ternary */
								  isHigherUp
									? 'bonusEMGGET'
									: 'bonus'
								: 'quitBonusCompensation'
						}`}
						values={{
							bonusType: t(`compensationType.${data.current.bonusType}.title`),
							month: dateFormat(new Date(Date.UTC(0, data.current.x - 1)), { month: 'long' }),
							year: data.current.year,
						}}
					/>
				)
		}

		return <div className="margin--vertical">{disclaimer}</div>
	}, [t, isHigherUp])

	/**
	 * if there is no cached data, the session has been cleared.
	 * in this case navigate to deferredCompensation view
	 */
	if (undefined === data.current) {
		navigateTo(getMainPath(routesDictionary.deferredCompensation), true)
		return null
	}

	const onSubmit = async () => {
		if (undefined === data.current) {
			return
		}

		let mfaResponse: MfaResponseData | void

		switch (data.current.type) {
			case 'salary':
				mfaResponse = await api.setCompensationSalary({
					...data.current.input,
				})
				break

			default:
				mfaResponse = await api.setCompensationBonus({
					type: data.current.id,
					month: data.current.x,
					...data.current.input,
				})
		}

		if (undefined !== mfaResponse && undefined !== mfaResponse?.transactionId) {
			auth.addMfaAction({
				transactionId: mfaResponse.transactionId,
				onSuccess: () => {
					sessionStorage.removeItem('deferredCompensationSimulationData')

					const queriesToRemoveFromCache = [
						QueryKey.compensationTypes,
						QueryKey.allCompensations,
						QueryKey.compensationYear,
						QueryKey.compensationMonth,
					]
					queriesToRemoveFromCache.forEach((query: QueryKey) => queryClient.removeQueries(query))

					navigateTo(getChildPath(routesDictionary.deferredCompensation, 'success'), true)
				},
			})
		}
	}

	const renderSalarySummary = () => {
		if (undefined === data.current || false !== quitSalaryCompensation.current || 'salary' !== data.current.type) {
			return
		}

		return (
			<>
				{CompensationUnit.percent === data.current.unit && (
					<>
						<p>
							<Trans i18nKey={`view.deferredCompensation.bodytext.summary.${data.current.type}`} />:
						</p>

						<p>
							<b>
								<Trans i18nKey="view.deferredCompensation.bodytext.summary.selectedShare" />:{' '}
								<span className="padding--horizontal font-size-default">
									{percentFormat(Object.values(data.current.input)[0])}
								</span>
							</b>
						</p>
					</>
				)}

				<CompensationDataTable
					ownContribution={data.current.ownContribution}
					employerBaseAmount={data.current.employerBaseAmount}
					matchingAmount={data.current.matchingAmount}
					sum={data.current.pensionTrust}
					yearlySum={data.current.yearlySum}
					unit={data.current.unit}
				/>
			</>
		)
	}

	const renderBonusSummary = () => {
		if (undefined === data.current || false !== quitBonusCompensation.current || 'salary' === data.current.type) {
			return
		}

		if (CompensationUnit.percent === data.current.bonusUnit) {
			return (
				<>
					<p>
						<Trans i18nKey={`view.deferredCompensation.bodytext.summary.${data.current.type}`} />:
					</p>
					<p>
						<b>
							<Trans i18nKey={`compensationType.${data.current.bonusType}.title`} />
						</b>
					</p>
					{'bonusFull' !== data.current.type && (
						<p>
							<b>
								<Trans i18nKey="view.deferredCompensation.bodytext.summary.selectedShare" />:{' '}
								<span className="padding--horizontal font-size-default">
									{percentFormat(Object.values(data.current.input)[0])}
								</span>
							</b>
						</p>
					)}

					<CompensationDataTable unit={CompensationUnit.percent} hideGrossAmount={true} />
				</>
			)
		}

		if (CompensationUnit.euro === data.current.bonusUnit) {
			return (
				<>
					<p>
						<b>
							<Trans i18nKey={`compensationType.${data.current.bonusType}.title`} />
						</b>
					</p>
					<CompensationDataTable
						ownContribution={data.current.ownContribution}
						employerBaseAmount={data.current.employerBaseAmount}
						matchingAmount={data.current.matchingAmount}
						sum={data.current.pensionTrust}
						yearlySum={data.current.yearlySum}
						unit={CompensationUnit.euro}
						viewDependentTranslations="bonus"
					/>
				</>
			)
		}
	}

	return (
		<div className="deferred-compensation grid grid--center-scroll center-view">
			<h3 className="grid__top">
				{false === quitSalaryCompensation.current && false === quitBonusCompensation.current && (
					<Trans i18nKey="view.deferredCompensation.summary.headline.default" />
				)}

				{false !== quitSalaryCompensation.current && (
					<Trans i18nKey="view.deferredCompensation.summary.headline.quitSalaryCompensation" />
				)}

				{false !== quitBonusCompensation.current && (
					<Trans
						i18nKey="view.deferredCompensation.summary.headline.quitBonusCompensation"
						values={{
							bonusType: t(`compensationType.${data.current.bonusType}.title`),
							month: dateFormat(new Date(Date.UTC(0, data.current.x - 1)), { month: 'long' }),
							year: data.current.year,
						}}
					/>
				)}
			</h3>

			<div className="grid__center">
				{renderSalarySummary()}
				{renderBonusSummary()}
				{renderDisclaimer}
			</div>

			<Button
				onClick={onSubmit}
				className="grid__bottom"
				label={t(
					`view.deferredCompensation.buttonLabel.${
						false === quitSalaryCompensation.current && false === quitBonusCompensation.current
							? 'confirm'
							: 'quitCompensation'
					}`
				)}
				type={ButtonType.primary}
			/>
		</div>
	)
}

export default Summary
