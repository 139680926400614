import React from 'react'
import ReactDOM from 'react-dom'
import './i18n'
import './index.css'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import App from './views/App'

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
serviceWorkerRegistration.register({
	onUpdate: async (registration) => {
		// We want to run this code only if we detect a new service worker is
		// waiting to be activated.
		// Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
		if (registration && registration.waiting) {
			await registration.unregister()
			window.postMessage({ appUpdateAvailable: true }, window.location.origin)
		}
	},
})
