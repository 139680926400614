import useAsyncEffect from '@n1ru4l/use-async-effect'
import { IconType } from 'components/Icons'
import useApi, { QueryKey } from 'hooks/useApi'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import { CompensationInputType } from 'shared/enums'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { BonusMonthListData, CompensationData, CompensationTypeData } from 'shared/interfaces'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'
import SalaryBonusSelectWrapper from '../../components/DeferredCompensation/SalaryBonusSelectWrapper'

export enum BonusPayoutType {
	all = 'all',
	share = 'share',
}

export interface BonusPayout {
	type: BonusPayoutType
	title: string
	icon: IconType
	id: string
}

const SelectBonusAmount: React.FC<{ id: CompensationTypeData['id']; month: BonusMonthListData['month'] }> = ({
	id,
	month,
}) => {
	const { getChildPath, navigateTo } = useRouteHelper()
	useBackButtonPath(`${getChildPath(routesDictionary.deferredCompensation, 'selectBonusMonth')}/${id}`)
	useNavigationTitle(<Trans i18nKey="view.deferredCompensation.pageTitle.bonus" />)

	const { t } = useTranslation()
	const api = useApi()
	const queryClient = useQueryClient()
	const { data: settings } = useQuery(
		[QueryKey.compensationSimulationSettings, 'bonus'],
		api.getCompensationSimulationSettings
	)
	const [selectedOption, setSelectedOption] = useState<BonusPayout>()
	const [sessionStorageSaved, setSessionStorageSaved] = useState<boolean>(false)

	const [data] = useState<BonusPayout[]>([
		{
			id: '1',
			type: BonusPayoutType.all,
			title: t('view.deferredCompensation.selectBonusAmount.all'),
			icon: IconType.actionAmountFull,
		},
		{
			id: '2',
			type: BonusPayoutType.share,
			title: t('view.deferredCompensation.selectBonusAmount.share'),
			icon: IconType.actionAmountDemi,
		},
	])

	useAsyncEffect(
		function* (setErrorHandler: any, c: any) {
			if (undefined === settings) {
				return
			}

			sessionStorage.removeItem('deferredCompensationSimulationData')

			const compensationSimulatonData: CompensationData = yield* c(
				queryClient.fetchQuery(
					[
						QueryKey.compensationSimulationBonus,
						{
							type: id,
							month,
							percentage: settings.percentage.max,
						},
					],
					api.getCompensationSimulationBonus
				)
			)

			sessionStorage.setItem(
				'deferredCompensationSimulationData',
				JSON.stringify({
					...compensationSimulatonData,
					id,
					type: 'bonusFull',
					input: {
						[CompensationInputType.percentage]: settings.percentage.max,
					},
				})
			)

			setSessionStorageSaved(true)
		},
		[settings]
	)

	/**
	 * prefetch data for bonus view on option select
	 */
	useAsyncEffect(
		function* (setErrorHandler: any, c: any) {
			if (undefined !== selectedOption && undefined !== settings) {
				switch (selectedOption.type) {
					case 'share':
						const simulationQuery: [
							QueryKey.compensationSimulationBonus,
							{
								type: string
								month: number
								percentage?: number
							}
						] = [
							QueryKey.compensationSimulationBonus,
							{
								type: id,
								month,
								percentage: Math.round(settings.percentage.max / 2),
							},
						]

						yield* c(queryClient.prefetchQuery(simulationQuery, api.getCompensationSimulationBonus))

						break
				}
			}
		},
		[selectedOption, settings]
	)

	const onSubmitHandler = () => {
		if (undefined === selectedOption) {
			return
		}

		switch (selectedOption.type) {
			case BonusPayoutType.all:
				navigateTo(getChildPath(routesDictionary.deferredCompensation, 'summary'))
				break

			case BonusPayoutType.share:
				navigateTo(`${getChildPath(routesDictionary.deferredCompensation, 'bonus')}/${id}/${month}`)

				break
		}
	}

	return (
		<div className="deferred-compensation grid grid--center-scroll center-view">
			<h3 className="text-align--left grid__top">
				<Trans i18nKey="view.deferredCompensation.selectBonusAmount.headline" />
			</h3>

			<SalaryBonusSelectWrapper
				options={data}
				selectedOption={selectedOption}
				key="salary-bonus-select"
				onClick={(element: any) => {
					setSelectedOption(element)
				}}
			/>

			<Button
				className="grid__bottom"
				onClick={onSubmitHandler}
				type={ButtonType.primary}
				label={t('generic.next')}
				disabled={!selectedOption || (!sessionStorageSaved && BonusPayoutType.all === selectedOption.type)}
			/>
		</div>
	)
}

export default SelectBonusAmount
