import compositionIcon from 'assets/dashboard/compositionIcon.svg'
import { IconType } from 'components/Icons'
import SunburstChart from 'components/SunburstChart'
import useApi, { QueryKey } from 'hooks/useApi'
import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'
import routesDictionary from 'routes'
import Accordion from 'shared/components/Accordion'
import Icon from 'shared/components/Icon'
import SelectableBar from 'shared/components/SelectableBar'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { FundAllocationData, FundAllocationsData } from 'shared/interfaces'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'
import FundCompositionAccordion from '../components/FundComposition/FundCompositionAccordion'
import FundCompositionDataWrapper from '../components/FundComposition/FundCompositionContext'

const FundComposition: React.FC = () => {
	const { getMainPath } = useRouteHelper()
	useBackButtonPath(getMainPath(routesDictionary.dashboard))
	useNavigationTitle(<Trans i18nKey="view.fundComposition.pageTitle" />)

	const api = useApi()
	const { status, data }: { status: string; data?: FundAllocationsData } = useQuery(
		api.getQuery(QueryKey.fundAllocation)
	)

	const [accordionOpen, setAccordionOpen] = useState(false)
	const [accordionContentHeight, setAccordionContentHeight] = useState(0)
	const [selectedAllocation, setSelectedAllocation] = useState<number>(0)

	const compensationYearChangedHandler = (event: React.FormEvent<HTMLSelectElement>) => {
		const value = Number((event.target as HTMLInputElement).value)

		setSelectedAllocation(value)
		setAccordionOpen(false)
	}

	const onAccordionClick = () => {
		setAccordionOpen(accordionOpen ? false : true)
	}

	const afterAccordionRender = (height: any) => {
		setAccordionContentHeight(height)
	}

	const getSelectJsx = () => {
		return (
			data &&
			data.allocations.length > 1 && (
				<Accordion
					alternativeStyle={true}
					header={
						<span>
							{accordionOpen ? (
								<Trans i18nKey="view.fundComposition.chooseFund" />
							) : (
								<Trans
									i18nKey="view.fundComposition.selectedFund"
									values={{ asset: data.allocations[selectedAllocation].label }}
								/>
							)}
						</span>
					}
					open={accordionOpen}
					onOpen={() => {
						onAccordionClick()
					}}
					iconColor={'var(--color-cyan)'}
					afterRender={(height: any) => {
						afterAccordionRender(height)
					}}
				>
					{data.allocations.map((allocation: FundAllocationData, index: number) => {
						return (
							<div
								key={`radiobutton-${index}`}
								className="radiobutton-wrapper"
								style={
									{
										'--options-length': `${data.allocations.length}`,
										'--options-index': `${index}`,
									} as React.CSSProperties
								}
							>
								<SelectableBar
									onChange={compensationYearChangedHandler}
									label={allocation.label}
									value={index}
									checked={selectedAllocation === index}
									name="transform-years"
								/>
							</div>
						)
					})}
				</Accordion>
			)
		)
	}

	const getCSS = () => {
		return {
			'--accordion-content-height': `${accordionContentHeight.toString()}px`,
		} as React.CSSProperties
	}

	const renderDisclaimer = () => {
		return (
			<div className="flex flex--direction-column flex--align-items-center">
				<div className="margin--vertical flex flex--align-items-start flex--justify-content-center">
					<Icon className="margin--horizontal no-margin--left" type={IconType.info} />
					<Trans i18nKey="view.fundComposition.noDataDisclaimer" />
				</div>
				<img className="padding" src={compositionIcon} alt="" />
			</div>
		)
	}

	return (
		<div className="fund-composition font-size-s center-view" style={getCSS()}>
			{'success' === status && (undefined === data || 0 === data.allocations.length) && renderDisclaimer()}

			{getSelectJsx()}

			{data && data.allocations.length > 0 && (
				<FundCompositionDataWrapper
					key={`allocation-${selectedAllocation}`}
					isDummyData={data.dummyData}
					data={data.allocations[selectedAllocation]}
				>
					<SunburstChart />
					<div className="margin--vertical flex flex--align-items-start">
						<Icon className="margin--horizontal no-margin--left" type={IconType.info} />
						{data.dummyData ? (
							<Trans i18nKey="view.fundComposition.dummyDataDisclaimer" />
						) : (
							<Trans i18nKey="view.fundComposition.realDataDisclaimer" />
						)}
					</div>
					<FundCompositionAccordion />
				</FundCompositionDataWrapper>
			)}
		</div>
	)
}

export default FundComposition
