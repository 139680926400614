import matchesPasswordPolicy from 'helper/matchesPasswordPolicy'
import { Challenge, useAuth } from 'hooks/useAuth'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Form, { FormFields, FormFieldType } from 'shared/components/Form'
import { TextInputType } from 'shared/components/TextInput'
import Tooltip from 'shared/components/Tooltip'
import { ApiErrorCode } from 'shared/enums'
import isDev from 'shared/helper/isDev'
import { FormSubmitFields } from 'shared/hooks/useForm'
import { useResetHeaderState } from 'state/useHeaderState'

const SetPassword = () => {
	useResetHeaderState()

	const { t } = useTranslation()
	const auth = useAuth()
	const [errorMessages, setErrorMessages] = useState<string[]>()

	const inputElements: FormFields = {
		newPassword: {
			value: isDev() ? (process.env.REACT_APP_PASSWORD as string) : '',
			required: true,
			type: TextInputType.password,
			label: t('view.register.formFieldLabels.setPassword'),
			autoComplete: 'new-password',
			fieldType: FormFieldType.text,
		},
		confirmNewPassword: {
			value: isDev() ? (process.env.REACT_APP_PASSWORD as string) : '',
			required: true,
			type: TextInputType.password,
			label: t('view.register.formFieldLabels.confirmPassword'),
			fieldType: FormFieldType.text,
		},
	}

	return (
		<div className="form-view">
			<div className="form-view__title">
				<h1>
					<Trans i18nKey="view.register.setPassword.pageTitle" />
				</h1>
			</div>

			<div className="form-view__bodytext margin--vertical">
				<p>
					<Trans i18nKey="view.register.setPassword.passwordPolicy" />
				</p>

				<Tooltip
					label={t('view.register.setPassword.passwordPolicyHelp.tooltip')}
					headline={t('view.register.setPassword.passwordPolicyHelp.headline')}
					content={<Trans i18nKey="view.register.setPassword.passwordPolicyHelp.content" />}
				/>
			</div>

			<Form
				className="form-view"
				fields={inputElements}
				onSubmit={(submittedFields: FormSubmitFields) => {
					if (submittedFields.newPassword !== submittedFields.confirmNewPassword) {
						return {
							lastChallengeError: ApiErrorCode.PASSWORDS_DO_NOT_MATCH,
							successful: false,
						}
					}

					if (false === matchesPasswordPolicy(String(submittedFields.newPassword))) {
						return {
							lastChallengeError: ApiErrorCode.PASSWORD_NOT_COMPLY_WITH_POLICY,
							successful: false,
						}
					}

					return auth.sendChallengeAnswer(submittedFields)
				}}
				onError={(errorResponse: Challenge) => {
					setErrorMessages([
						t(`apiErrors.${errorResponse.lastChallengeError}`, { error: errorResponse.lastChallengeError }),
					])
				}}
				errorMessages={errorMessages}
				submitLabel={t('view.register.buttonLabel.setPassword')}
			>
				<div className="form-view__additional-fields margin--vertical text-color-cyan">
					<p>
						<Trans i18nKey="view.register.setPassword.hint" />
					</p>
				</div>
			</Form>
		</div>
	)
}

export default SetPassword
