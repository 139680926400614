import React from 'react'
import { Trans } from 'react-i18next'

const SendPasswordLoginBodytext: React.FC = () => {
	return (
		<>
			<p>
				<Trans i18nKey="view.login.sendPassword.bodytext" />
			</p>

			<p className="text-color-cyan">
				<Trans i18nKey="view.login.sendPassword.hinttext" />
			</p>
		</>
	)
}

export default SendPasswordLoginBodytext
