import { FunctionComponent, useState } from 'react'
import { Trans } from 'react-i18next'
import Button, { ButtonType } from 'shared/components/Button'
import Icon from 'shared/components/Icon'
import { IconType } from '../Icons'
import PrivacyPolicy from 'components/PrivacyPolicy'

const UpdateContactTerms: FunctionComponent<{
	onAccept: () => void
	onBack: () => void
	onPartialAccept: () => void
}> = ({ onAccept, onBack, onPartialAccept }) => {
	const [showAll, setShowAll] = useState(false)

	return (
		<div className="faq-changed-modal padding  ">
			<Icon type={IconType.change}></Icon>
			<div className="text-align--left">
				<h3 className="text-color-orange ">
					<Trans i18nKey="component.contactTerms.headline" />
				</h3>
				<Trans i18nKey="component.contactTerms.intro">
					<span
						className="link underline cursor--pointer"
						role="button"
						onClick={() => {
							setShowAll(true)
						}}
					></span>
				</Trans>
			</div>

			<div className="faq-changed-modal__button">
				<Button onClick={onPartialAccept} type={[ButtonType.primary, ButtonType.grey]}>
					<Trans i18nKey="component.contactTerms.decline" />
				</Button>{' '}
				<Button onClick={onAccept} type={ButtonType.primary}>
					<Trans i18nKey="component.contactTerms.accept" />
				</Button>{' '}
			</div>

			{!showAll && (
				<Button
					className="faq-changed-modal__show-all margin--top"
					type={[ButtonType.small]}
					onClick={() => {
						setShowAll(true)
					}}
				>
					{' '}
					<Trans i18nKey="component.contactTerms.download" />
					<Icon type={IconType.arrow} rotate={90}></Icon>
				</Button>
			)}

			{showAll && (
				<>
					<PrivacyPolicy />{' '}
					<Button
						className="faq-changed-modal__show-all margin--top"
						type={[ButtonType.small]}
						onClick={() => {
							setShowAll(false)
						}}
					>
						{' '}
						<Trans i18nKey="component.contactTerms.hide" />
						<Icon type={IconType.arrow} rotate={-90}></Icon>
					</Button>
				</>
			)}
		</div>
	)
}

export default UpdateContactTerms
