import React from 'react'
import globalHook, { Store } from 'use-global-hook'

export interface GlobalFormState {
	[key: string]: any
}

export interface GlobalFormActions {
	resetState: () => void
	setFields: (fields: { [key: string]: any }) => void
}

const resetState = (store: Store<GlobalFormState, GlobalFormActions>) => {
	const cleanState = Object.keys(store.state).reduce((state: { [key: string]: any }, key: string) => {
		state[key] = undefined
		return state
	}, {})

	store.setState(cleanState)
}

const setFields = (store: Store<GlobalFormState, GlobalFormActions>, fields: { [key: string]: any }) => {
	store.setState({ ...store.state, ...fields })
}

const initialState: GlobalFormState = {}

const actions = {
	setFields,
	resetState,
}

const useGlobalFormState = globalHook<GlobalFormState, GlobalFormActions>(React, initialState, actions)

export default useGlobalFormState
