import { Auth } from 'aws-amplify'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { trackPromise } from 'react-promise-tracker'
import routesDictionary from 'routes'

import Form, { FormFields, FormFieldType } from 'shared/components/Form'
import { TextInputType } from 'shared/components/TextInput'
import { FormSubmitFields } from 'shared/hooks/useForm'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath, useResetHeaderState } from 'state/useHeaderState'

const Email = () => {
	useResetHeaderState()
	const { navigateTo, getChildPath } = useRouteHelper()

	useBackButtonPath(getChildPath(routesDictionary.migration, 'startMigration'))

	const { t } = useTranslation()
	const [errorMessages] = useState<string[]>()

	const inputElements: FormFields = {
		email: {
			required: true,
			type: TextInputType.email,
			label: t('view.migration.email.inputLabel'),
			fieldType: FormFieldType.text,
			value: '',
		},
	}

	const onSubmit = async (submittedFields: FormSubmitFields) => {
		try {
			const email = (submittedFields.email as string).toLowerCase()
			const user = await Auth.currentAuthenticatedUser()
			await Auth.deleteUserAttributes(user, ['email'])
			await Auth.updateUserAttributes(user, {
				email,
			})

			return { successful: true, status: 200 }
		} catch (e) {
			return { successful: false, status: 500 }
		}
	}

	return (
		<div className="form-view no-auto-hyphens">
			<div className="form-view__title">
				<h1>
					<Trans i18nKey="view.migration.email.headline" />
				</h1>
			</div>

			<>
				<Form
					className="form-view"
					fields={inputElements}
					onSubmit={(submittedFields: any) => {
						return trackPromise(onSubmit(submittedFields), 'setEmail')
					}}
					onError={(error: any) => {
						//FIXME: catch error
						console.log(error)
					}}
					onSuccess={() => {
						navigateTo(`${getChildPath(routesDictionary.migration, 'tanForEmail')}`)
					}}
					errorMessages={errorMessages}
					submitLabel={t('generic.send')}
					promiseTracker={{ area: 'setEmail' }}
				>
					{' '}
					<div className="form-view__bodytext ">
						<p>
							<Trans i18nKey="view.migration.email.bodyText" />
						</p>
					</div>
				</Form>
			</>
		</div>
	)
}

export default Email
