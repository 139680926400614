import useApi, { QueryKey } from 'hooks/useApi'
import { FC, useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'
import routesDictionary from 'routes'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'
import DisplayPensionDate from './DisplayPensionDate'
import EnterPensionDate from './EnterPensionDate'

const PayoutDate: FC<{ pensionType: string | undefined }> = (props) => {
	const api = useApi()
	const { getChildPath } = useRouteHelper()
	useBackButtonPath(getChildPath(routesDictionary.payoutOptions, 'benefitType'))

	useNavigationTitle(<Trans i18nKey="view.payoutOptions.chapterOne.payoutDate.pageTitle" />)

	const { data, status } = useQuery(
		[QueryKey.pensionDate, { leistungsart: props.pensionType as any }],
		api.getPensionDate
	)

	const { data: payoutModelData } = useQuery(QueryKey.payoutModelConfirmationDate, api.getPayoutModelInProgress)

	const initialPensionDate = useMemo(() => {
		if (payoutModelData?.leistungsart && payoutModelData.leistungsart?.type === 'VorgezogenerRuhestand') {
			return payoutModelData.leistungsart.austrittsdatum
		} else {
			return null
		}
	}, [payoutModelData])

	if (status !== 'success') {
		return <></>
	}

	if (
		(props.pensionType !== undefined && props.pensionType === 'VorgezogenerRuhestand') ||
		props.pensionType === 'Ruhestand'
	) {
		return (
			<div className="payout-date center-view  grid grid--center-scroll">
				{data?.rewritable && props.pensionType && payoutModelData && (
					<EnterPensionDate
						pensionType={props.pensionType}
						exitDate={initialPensionDate || data.austrittsdatum}
						originalExitDate={data.austrittsdatum}
					/>
				)}
				{data?.austrittsdatum && !data.rewritable && (
					<DisplayPensionDate pensionType={props.pensionType} exitDate={data.austrittsdatum} />
				)}
			</div>
		)
	}

	return <></>
}

export default PayoutDate
