import React, { FunctionComponent, useRef } from 'react'
import { currencyFormat } from 'shared/helper/numberFormats'
import { PayoutModelOptions } from 'views/PayoutModel'

export interface IPayoutModelShares {
	options: Array<{
				contribution: number
		beforeSum?: string
		label: string
		color?: string
		bgColor?: string
		textColor?: string
		type: PayoutModelOptions
	}>
	exactLumpSum: number,
	sum?: number
	showSums?: boolean
	className?: string
}

const PayoutModelShares: FunctionComponent<IPayoutModelShares> = (props) => {
	let sum = 0
	let fullPercentage = 0

	const squareRefs = useRef<{ [key: string]: HTMLDivElement }>({})
	const labelRefs = useRef<{ [key: string]: HTMLDivElement }>({})

	props.options.forEach((element) => {
		if (element.contribution > fullPercentage) {
			fullPercentage = element.contribution
		}
	})

	const getClassNames = (): string => {
		const classes: string[] = ['payoutmodel-shares']

		if (props.className) {
			classes.push(props.className)
		}

		if (props.showSums) {
			classes.push('payoutmodel-shares--show-sum')
		}

		return classes.join(' ')
	}

	const calcuateSum = () => {
		if (props.sum) {
			sum = props.sum
		} else {
			sum = props.options.reduce(
				(a, b) => ({ contribution: a.contribution + b.contribution } as any)
			).contribution
		}
	}

	const getShare = (amount: number, total: number) => {
		if (0 !== amount && 0 !== total) {
			return Math.sqrt(amount / total)
		}
	}

	const getPercentage = (amount: number, returnInt = false) => {
		return returnInt ? ((amount / sum) * 100).toFixed(0) : (amount / sum).toFixed(2)
	}

	/**
	 *  a^2 = Areax
	 *
	 */

	const getShareVariables = () => {
		const cssStyles: { [Key: string]: string | number } = {}

		props.options.forEach((element, index) => {
			cssStyles[`--share-ratio-${index}` as any] = getShare(element.contribution, fullPercentage) || 0
		})

		return cssStyles as React.CSSProperties
	}

	/**
	 * Check if the Outside Square is still dobigger than the label inside
	 * @param index
	 * @param factor
	 * @returns
	 */
	const checkSizes = (index: number, factor: number | undefined): boolean => {
		if (
			factor &&
			squareRefs.current[index] !== undefined &&
			labelRefs.current[index] !== undefined &&
			squareRefs.current[index].clientWidth * factor < labelRefs.current[index].clientWidth
		) {
			return true
		} else {
			return false
		}
	}

	const getShareElement = () => {
		return props.options.map((element, index) => {
			const share = getShare(element.contribution, fullPercentage)
			return (
				<div
					key={`PMS-${index}`}
					style={
						{
							'--share': share,
							'--share-bg-color': element.bgColor ? `${element.bgColor}` : '#fffff',
							'--share-color': element.textColor ? `${element.textColor}` : '#000000',
						} as React.CSSProperties
					}
					className={`payoutmodel-shares__share ${!share && 'payoutmodel-shares__share--hidden'}`}
				>
					<div
						ref={(instance: HTMLDivElement) => {
							squareRefs.current[index] = instance
						}}
						className="circle circle--employee"
					/>
					<div
						className={`amount amount--employee ${
							checkSizes(index, getShare(element.contribution, fullPercentage)) ? 'amount--alt' : ''
						}`}
					>
						<div
							className="amount__inner"
							ref={(instance: HTMLDivElement) => {
								labelRefs.current[index] = instance
							}}
						>
							<div className="amount__percentage--desktop">
								{getPercentage(element.contribution, true)} %
							</div>
							<div className="amount__percentage--mobile">
								{getPercentage(element.contribution, true)} %
							</div>
							<div className="amount__description amount__description--desktop description">
								<div className="description__inner">
									<span className="description__sum font-size-m ">
										{element.beforeSum && (
											<span className="font-size-xs">
												
												{element.beforeSum}
												<br />
											</span>
										)}

										<span className="font-style-alternative">
											{currencyFormat(element.type === PayoutModelOptions.lumpSum ? props.exactLumpSum :element.contribution , {
												variableFractionDigits: true,
											})}
										</span>
									</span>
									<div className="description__label font-size-xs">{element.label}</div>
								</div>
							</div>

							<div className="amount__description amount__description--mobile description">
								<div className="description__inner">
									<span className="description__sum font-size-m ">
										{element.beforeSum && (
											<span className="font-size-xs">
												{element.beforeSum}
												<br />
											</span>
										)}

										<span className="font-style-alternative">
										{currencyFormat(element.type === PayoutModelOptions.lumpSum ? props.exactLumpSum :element.contribution , {
												variableFractionDigits: true,
											})}
										</span>
									</span>
									<div className="description__label font-size-xs">{element.label}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		})
	}

	calcuateSum()

	return (
		<div style={getShareVariables()} className={getClassNames()}>
			{getShareElement()}
		</div>
	)
}

export default PayoutModelShares
