import { pieChartColors } from 'components/ChartsTheme'
import { PieChartData } from 'components/SunburstChart'
import React, { createContext, FunctionComponent, useEffect, useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import dateFormat from 'shared/helper/dateFormat'
import getTypeOf from 'shared/helper/getTypeOf'
import { numberFormat } from 'shared/helper/numberFormats'
import { FundAllocationComponentsData, FundAllocationData } from 'shared/interfaces'

// const noop = () => {}

export interface FundCompositionContextProps {
	data: FundAllocationComponentsData[]
	calculatedData: PieChartData[]
	metaData: { [key: string]: any }
	colorScheme: string[]
	toggleSlice: (indices: number[], showChildren: boolean) => void
	setNestedHeight: (indices: number[], height: number) => void
	chartLabel: string
}

export const FundCompositionDataContext = createContext<FundCompositionContextProps>({
	data: [],
	calculatedData: [],
	metaData: {},
	colorScheme: [],
	toggleSlice: () => {
		return
	},
	setNestedHeight: () => {
		return
	},
	chartLabel: '',
})

const FundCompositionDataWrapper: FunctionComponent<{ data: FundAllocationData; isDummyData?: boolean }> = ({
	data,
	isDummyData,
	children,
}) => {
	const [calculatedData, setCalculatedData] = useState<PieChartData[]>([])
	const metaData = useRef<{ [key: string]: any }>({
		dimension: 450,
		minimumRadius: 0,
		gap: 30,
		thickness: {
			default: 6,
			selected: 12,
		},
		count: 0,
		maxDepth: 0,
	})
	const totalAmount = useRef<number>(0)

	const defaultChartLabel = (
		<span className="default-label font-size-s font-family-alternative--light ">
			{true === isDummyData ? (
				<Trans i18nKey="view.fundComposition.pageTitle" values={{ date: dateFormat(data.updatedAt) }} />
			) : (
				<Trans
					i18nKey="view.fundComposition.assetAllocationDate"
					values={{ date: dateFormat(data.updatedAt) }}
				/>
			)}
		</span>
	)

	const chartLabel = useRef<string | any>(defaultChartLabel)

	const calculateStartAndEndPoints = (
		chartData: PieChartData | FundAllocationComponentsData[],
		previousAmount: number = 0
	) => {
		const isChild = 'object' === getTypeOf(chartData)

		let currentAmount = isChild ? (chartData as PieChartData).previousAmount : 0

		const totalAngle = 360
		const dataArray = isChild
			? ((chartData as PieChartData).components as PieChartData[])
			: (chartData as PieChartData[])

		const calculateAngle = (): number => {
			return (totalAngle / totalAmount.current) * currentAmount
		}

		dataArray.map((entry: PieChartData, index) => {
			entry.previousAmount = dataArray.reduce((accumulator, currentItem, currentIndex: number) => {
				if (currentIndex < index) {
					accumulator += currentItem.amount || 0
				}

				return accumulator
			}, previousAmount)

			if (entry.hasOwnProperty('components')) {
				entry.start = calculateAngle()
				currentAmount += entry.amount
				entry.end = calculateAngle()

				calculateStartAndEndPoints(entry, entry.previousAmount)
			} else {
				currentAmount += entry.amount
			}

			return entry
		})
	}

	const setupData = () => {
		const updatedData = [...data.allocation] as PieChartData[]

		calculateStartAndEndPoints(updatedData)
		setIndices(updatedData)
		metaData.current.minimumRadius =
			metaData.current.dimension / 2 - metaData.current.gap * metaData.current.maxDepth

		setShowChildren([0], 0, updatedData as PieChartData[], false)
		setCalculatedData(updatedData)
	}

	useEffect(() => {
		totalAmount.current = data.allocation.reduce((sum: number, current) => {
			sum += current.amount

			return sum
		}, 0)

		if (0 === totalAmount.current) {
			return
		}

		setupData()

		// eslint-disable-next-line
	}, [data])

	const setIndices = (chartData: PieChartData | FundAllocationComponentsData[]) => {
		const isChild = 'object' === getTypeOf(chartData)

		const dataArray = isChild
			? ((chartData as PieChartData).components as PieChartData[])
			: (chartData as PieChartData[])

		dataArray.map((entry: PieChartData, index) => {
			entry.index = index
			entry.indices = isChild ? (chartData as PieChartData).indices.concat([index]) : [index]

			if (entry.hasOwnProperty('components')) {
				setIndices(entry)
			}

			// setting maxDepth here while looping through entries
			if (metaData.current.maxDepth < entry.indices.length) {
				metaData.current.maxDepth = entry.indices.length
			}

			return entry
		})
	}

	const setShowChildren = (indices: number[], currentDepth: number = 0, entries: PieChartData[], show: boolean) => {
		return entries.map((item: any, index: number) => {
			const itemAllowedToShowChildren = item.indices.every(
				(current: number, itemIndex: number) => current === indices[itemIndex]
			)
			item.showChildren =
				(itemAllowedToShowChildren && currentDepth + 1 < indices.length) || (itemAllowedToShowChildren && show)

			item.highlight = index === indices[currentDepth] && currentDepth === indices.length - 1 && item.showChildren

			if (item.highlight) {
				chartLabel.current = (
					<span>
						{numberFormat(item.amount)} {item.unit}
						<span className="default-label font-size-s font-family-alternative--light ">{item.name}</span>
					</span>
				)
			}

			if (undefined !== item.components && currentDepth + 1 <= indices.length) {
				item.components = setShowChildren(indices, currentDepth + 1, item.components, show)
			}

			if (1 === indices.length) {
				item.anyChildrenShown = show
			}

			return item
		})
	}

	const toggleSlice = (indices: number[], showChildren: boolean) => {
		chartLabel.current = defaultChartLabel
		const entries = setShowChildren(indices, 0, calculatedData as PieChartData[], showChildren)
		setCalculatedData(entries)
	}

	/**
	 * HINT:
	 * this function directly changes the calculatedData object without using
	 * the setCalculatedData method. This is not ideal, updating the state causes
	 * too many re-renders.
	 *
	 * TODO:
	 * The calculation of the heights should happen in FundCompositionAccordion
	 * @param indices
	 * @param height
	 */
	const setAccordionHeights = (indices: number[], height: number) => {
		const result = indices.reduce((a: any, b: any) => {
			if (a.hasOwnProperty('components')) {
				return a.components[b]
			}

			return a[b]
		}, calculatedData)

		result.height = height
	}

	return (
		<FundCompositionDataContext.Provider
			value={{
				data: data.allocation,
				calculatedData,
				metaData: metaData.current,
				setNestedHeight: setAccordionHeights,
				toggleSlice,
				colorScheme: pieChartColors,
				chartLabel: chartLabel.current,
			}}
		>
			{children}
		</FundCompositionDataContext.Provider>
	)
}

export default FundCompositionDataWrapper
