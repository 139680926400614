import { ReactComponent as ActionIcon1Year } from 'assets/actionIcons/actionicon-1-year.svg'
import { ReactComponent as ActionIcon3Year } from 'assets/actionIcons/actionicon-3-year.svg'
import { ReactComponent as ActionIconAccount } from 'assets/actionIcons/actionicon-account.svg'
import { ReactComponent as ActionIconAmountDemi } from 'assets/actionIcons/actionicon-amount-demi.svg'
import { ReactComponent as ActionIconAmountFull } from 'assets/actionIcons/actionicon-amount-full.svg'
import { ReactComponent as ActionIconBonus } from 'assets/actionIcons/actionicon-bonus.svg'
import { ReactComponent as ActionIconChristmasBonus } from 'assets/actionIcons/actionicon-christmas.svg'
import { ReactComponent as ActionIconDeath } from 'assets/actionIcons/actionicon-death.svg'
import { ReactComponent as ActionIconDisability } from 'assets/actionIcons/actionicon-disability.svg'
import { ReactComponent as ActionIconDocuments } from 'assets/actionIcons/actionicon-documents.svg'
import { ReactComponent as ActionIconEarningsBonus } from 'assets/actionIcons/actionicon-earnings.svg'
import { ReactComponent as ActionIconGratuityBonus } from 'assets/actionIcons/actionicon-gratuity.svg'
import { ReactComponent as ActionIconHolidayBonus } from 'assets/actionIcons/actionicon-holiday.svg'
import { ReactComponent as ActionIconInfo } from 'assets/actionIcons/actionicon-info.svg'
import { ReactComponent as ActionIconMonthly } from 'assets/actionIcons/actionicon-monthly-2.svg'
import { ReactComponent as ActionIconPartner } from 'assets/actionIcons/actionicon-partner.svg'
import { ReactComponent as ActionIconRiskBenefitIncreased } from 'assets/actionIcons/actionicon-risk-benefit-increased.svg'
import { ReactComponent as ActionIconRiskBenefitNone } from 'assets/actionIcons/actionicon-risk-benefit-none.svg'
import { ReactComponent as ActionIconRiskBenefitStandard } from 'assets/actionIcons/actionicon-risk-benefit-standard.svg'
import { ReactComponent as ActionIconTariffAdditionalBonus } from 'assets/actionIcons/actionicon-tzug.svg'
import { ReactComponent as ActionIconVariableBonus } from 'assets/actionIcons/actionicon-variable.svg'
import { ReactComponent as AnimatedCheckmarkIcon } from 'assets/animated-checkmark.svg'
import { ReactComponent as AnimatedRadiobuttonIcon } from 'assets/animated-radiobutton.svg'
import { ReactComponent as AlertIcon } from 'assets/icons/alert.svg'
import { ReactComponent as AppIconWithName } from 'assets/icons/app-icon-with-name.svg'
import { ReactComponent as AppIcon } from 'assets/icons/app-icon.svg'
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg'
import { ReactComponent as IconCalendar } from 'assets/icons/calendar.svg'
import { ReactComponent as CallIcon } from 'assets/icons/call.svg'
import { ReactComponent as ChangeIcon } from 'assets/icons/change.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import { ReactComponent as ListMarkerCheck } from 'assets/icons/list-marker-check.svg'
import { ReactComponent as ListMarkerCross } from 'assets/icons/list-marker-cross.svg'
import { ReactComponent as ListMarkerNeutral } from 'assets/icons/list-marker-neutral.svg'
import { ReactComponent as LockedIcon } from 'assets/icons/locked.svg'
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg'
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg'
import { ReactComponent as AddIcon } from 'assets/icons/nanoicon-add.svg'
import { ReactComponent as HelpIcon } from 'assets/icons/nanoicon-help.svg'
import { ReactComponent as InfoIcon } from 'assets/icons/nanoicon-info.svg'
import { ReactComponent as ShareIcon } from 'assets/icons/nanoicon-share.svg'
import { ReactComponent as SucessIcon } from 'assets/icons/success.svg'
import { ReactComponent as TerminateDigitalParticipationIcon } from 'assets/icons/terminate-digital-participation.svg'
import { ReactComponent as VisualCheckIcon } from 'assets/icons/visual-check.svg'
import { ReactComponent as MenuIconLogin } from 'assets/menuIcons/icon-account-login.svg'
import { ReactComponent as MenuIconProfile } from 'assets/menuIcons/icon-adresse.svg'
import { ReactComponent as MenuIconLogout } from 'assets/menuIcons/icon-ausloggen.svg'
import { ReactComponent as MenuIconPayout } from 'assets/menuIcons/icon-auszahlung.svg'
import { ReactComponent as MenuIconComplaints } from 'assets/menuIcons/icon-beschwerden.svg'
import { ReactComponent as MenuIconBlocksAccount } from 'assets/menuIcons/icon-blocksaccount.svg'
import { ReactComponent as MenuIconLetter } from 'assets/menuIcons/icon-briefe.svg'
import { ReactComponent as MenuIconCall } from 'assets/menuIcons/icon-call.svg'
import { ReactComponent as MenuIconChart } from 'assets/menuIcons/icon-chart.svg'
import { ReactComponent as MenuIconChat } from 'assets/menuIcons/icon-chat.svg'
import { ReactComponent as MenuIconDocuments } from 'assets/menuIcons/icon-dokumente.svg'
import { ReactComponent as MenuIconDeferredCompensation } from 'assets/menuIcons/icon-entgeltumwandlung.svg'
import { ReactComponent as MenuIconFundComposition } from 'assets/menuIcons/icon-fondszusammensetzung.svg'
import { ReactComponent as MenuIconHelp } from 'assets/menuIcons/icon-help.svg'
import { ReactComponent as MenuIconInbox } from 'assets/menuIcons/icon-incomingletter.svg'
import { ReactComponent as MenuIconInfo } from 'assets/menuIcons/icon-info.svg'
import { ReactComponent as MenuIconEmail } from 'assets/menuIcons/icon-kommunikation.svg'
import { ReactComponent as MenuIconCard } from 'assets/menuIcons/icon-konto.svg'
import { ReactComponent as MenuIconPasswordReset } from 'assets/menuIcons/icon-passwort-zuruecksetzen.svg'
import { ReactComponent as MenuIconPending } from 'assets/menuIcons/icon-pending.svg'
import { ReactComponent as MenuIconRegister } from 'assets/menuIcons/icon-registrierung.svg'
import { ReactComponent as MenuIconRiskBenefit } from 'assets/menuIcons/icon-risikoleistungen.svg'
import { ReactComponent as MenuIconDashboard } from 'assets/menuIcons/icon-sachbearbeiter-dashboard.svg'
import { ReactComponent as MenuIconBaseData } from 'assets/menuIcons/icon-stammdaten.svg'
import { ReactComponent as MenuIconUploads } from 'assets/menuIcons/icon-useruploads.svg'
import { ReactComponent as MenuIconPension } from 'assets/menuIcons/icon-versorgungsguthaben.svg'
import { ReactComponent as MenuIconContract } from 'assets/menuIcons/icon-vertrag.svg'
import { ReactComponent as MenuIconConversions } from 'assets/menuIcons/icon-wandlungen.svg'
import { ReactComponent as MenuIconWelcome } from 'assets/menuIcons/icon-welcome.svg'

export enum IconType {
	action1Year = 'action1Year',
	action3Year = 'action3Year',
	actionAccount = 'actionAccount',
	actionAmountDemi = 'actionAmountDemi',
	actionAmountFull = 'actionAmountFull',
	actionBonus = 'bonus',
	actionChristmasBonus = 'christmasBonus',
	actionDeath = 'death',
	actionDisability = 'disability',
	actionDocuments = 'documents',
	actionEarningsBonus = 'earningsBonus',
	actionGratuityBonus = 'gratuityBonus',
	actionHolidayBonus = 'holidayBonus',
	actionInfo = 'actionInfo',
	actionPartner = 'partner',
	actionRiskBenefitIncreased = 'riskBenefitIncreased',
	actionRiskBenefitNone = 'riskBenefitNone',
	actionRiskBenefitStandard = 'riskBenefitStandard',
	actionSalary = 'salary',
	actionTariffAdditionalBonus = 'tariffAdditionalBonus',
	actionVariableBonus = 'variableBonus',
	add = 'add',
	alert = 'alert',
	animatedCheckmark = 'animatedCheckmark',
	animatedRadiobutton = 'animatedRadiobutton',
	appIcon = 'appIcon',
	appIconWithName = 'appIconWithName',
	arrow = 'arrow',
	calendar = 'calendar',
	call = 'call',
	change = 'change',
	close = 'close',
	delete = 'delete',
	help = 'help',
	info = 'info',
	locked = 'locked',
	logout = 'logout',
	mail = 'mail',
	menuBaseData = 'menuBaseData',
	menuBlocksAccount = 'menuBlocksAccount',
	menuCall = 'menuCall',
	menuCard = 'menuCard',
	menuChart = 'menuChart',
	menuChat = 'menuChat',
	menuComplaints = 'menuComplaints',
	menuContract = 'menuContract',
	menuConversions = 'menuConversions',
	menuDashboard = 'menuDashboard',
	menuDeferredCompensation = 'menuDeferredCompensation',
	menuDocuments = 'menuDocuments',
	menuEmail = 'menuEmail',
	menuFundComposition = 'menuFundComposition',
	menuHelp = 'menuHelp',
	menuInbox = 'menuInbox',
	menuInfo = 'menuInfo',
	menuLetter = 'menuLetter',
	menuLogin = 'menuLogin',
	menuLogout = 'menuLogout',
	menuPasswordReset = 'menuPasswordReset',
	menuPayout = 'menuPayout',
	menuPending = 'menuPending',
	menuPension = 'menuPension',
	menuProfile = 'menuProfile',
	menuRegister = 'menuRegister',
	menuRiskBenefit = 'menuRiskBenefit',
	menuUploads = 'menuUploads',
	menuWelcome = 'menuWelcome',
	share = 'share',
	success = 'success',
	terminateDigitalParticipation = 'terminateDigitalParticipation',
	visualCheck = 'visualCheck',
	listMarkerNeutral = 'listMarkerNeutral',
	listMarkerCheck = 'listMarkerCheck',
	listMarkerCross = 'listMarkerCross',
}

export const IconAssignment = {
	[IconType.action1Year]: ActionIcon1Year,
	[IconType.action3Year]: ActionIcon3Year,
	[IconType.actionAccount]: ActionIconAccount,
	[IconType.actionAmountDemi]: ActionIconAmountDemi,
	[IconType.actionAmountFull]: ActionIconAmountFull,
	[IconType.actionBonus]: ActionIconBonus,
	[IconType.actionChristmasBonus]: ActionIconChristmasBonus,
	[IconType.actionDeath]: ActionIconDeath,
	[IconType.actionDisability]: ActionIconDisability,
	[IconType.actionDocuments]: ActionIconDocuments,
	[IconType.actionEarningsBonus]: ActionIconEarningsBonus,
	[IconType.actionGratuityBonus]: ActionIconGratuityBonus,
	[IconType.actionHolidayBonus]: ActionIconHolidayBonus,
	[IconType.actionInfo]: ActionIconInfo,
	[IconType.actionPartner]: ActionIconPartner,
	[IconType.actionRiskBenefitIncreased]: ActionIconRiskBenefitIncreased,
	[IconType.actionRiskBenefitNone]: ActionIconRiskBenefitNone,
	[IconType.actionRiskBenefitStandard]: ActionIconRiskBenefitStandard,
	[IconType.actionSalary]: ActionIconMonthly,
	[IconType.actionTariffAdditionalBonus]: ActionIconTariffAdditionalBonus,
	[IconType.actionVariableBonus]: ActionIconVariableBonus,
	[IconType.add]: AddIcon,
	[IconType.alert]: AlertIcon,
	[IconType.animatedCheckmark]: AnimatedCheckmarkIcon,
	[IconType.animatedRadiobutton]: AnimatedRadiobuttonIcon,
	[IconType.appIcon]: AppIcon,
	[IconType.appIconWithName]: AppIconWithName,
	[IconType.arrow]: ArrowIcon,
	[IconType.calendar]: IconCalendar,
	[IconType.change]: ChangeIcon,
	[IconType.call]: CallIcon,
	[IconType.close]: CloseIcon,
	[IconType.delete]: DeleteIcon,
	[IconType.help]: HelpIcon,
	[IconType.info]: InfoIcon,
	[IconType.locked]: LockedIcon,
	[IconType.logout]: LogoutIcon,
	[IconType.mail]: MailIcon,
	[IconType.menuBaseData]: MenuIconBaseData,
	[IconType.menuBlocksAccount]: MenuIconBlocksAccount,
	[IconType.menuCall]: MenuIconCall,
	[IconType.menuCard]: MenuIconCard,
	[IconType.menuChart]: MenuIconChart,
	[IconType.menuChat]: MenuIconChat,
	[IconType.menuComplaints]: MenuIconComplaints,
	[IconType.menuContract]: MenuIconContract,
	[IconType.menuConversions]: MenuIconConversions,
	[IconType.menuDashboard]: MenuIconDashboard,
	[IconType.menuDeferredCompensation]: MenuIconDeferredCompensation,
	[IconType.menuDocuments]: MenuIconDocuments,
	[IconType.menuEmail]: MenuIconEmail,
	[IconType.menuFundComposition]: MenuIconFundComposition,
	[IconType.menuHelp]: MenuIconHelp,
	[IconType.menuInbox]: MenuIconInbox,
	[IconType.menuInfo]: MenuIconInfo,
	[IconType.menuLetter]: MenuIconLetter,
	[IconType.menuLogin]: MenuIconLogin,
	[IconType.menuLogout]: MenuIconLogout,
	[IconType.menuPasswordReset]: MenuIconPasswordReset,
	[IconType.menuPayout]: MenuIconPayout,
	[IconType.menuPending]: MenuIconPending,
	[IconType.menuPension]: MenuIconPension,
	[IconType.menuProfile]: MenuIconProfile,
	[IconType.menuRegister]: MenuIconRegister,
	[IconType.menuRiskBenefit]: MenuIconRiskBenefit,
	[IconType.menuUploads]: MenuIconUploads,
	[IconType.menuWelcome]: MenuIconWelcome,
	[IconType.share]: ShareIcon,
	[IconType.terminateDigitalParticipation]: TerminateDigitalParticipationIcon,
	[IconType.success]: SucessIcon,
	[IconType.visualCheck]: VisualCheckIcon,
	[IconType.listMarkerCheck]: ListMarkerCheck,
	[IconType.listMarkerNeutral]: ListMarkerNeutral,
	[IconType.listMarkerCross]: ListMarkerCross,
}
