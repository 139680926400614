import React, { FunctionComponent } from 'react'

export interface BigColorFigureProps {
	value: number | string
	verticalBarOffset?: number | null
	fill?: string
	className?: string
}

const BigColorFigure: FunctionComponent<BigColorFigureProps> = props => {
	const getClasses = (): string => {
		const classPrefix = 'big-color-figure--'
		const classes = ['big-color-figure']

		if (props.className) {
			classes.push(`${classPrefix}${props.className}`)
		}

		return classes.join(' ')
	}

	return (
		<div className={getClasses()} style={{ '--active-color': props.fill } as React.CSSProperties}>
			<span className="big-color-figure__figure font-size-l font-family-alternative--light">{props.value}</span>
		</div>
	)
}

export default BigColorFigure
