import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import i18n_de from './translations/de.json'
import i18n_en from './translations/en-US.json'
import LanguageDetector from 'i18next-browser-languagedetector';
import { activatedFeatures } from 'helper/activatedFeatures';



const { deepl = false } = activatedFeatures
const resources: { [key: string]: any } = {
	de: {
		translation: i18n_de,
	},

}

if (deepl) {
	resources.en = { translation: i18n_en, }
}

export const availableLanguages = [{ code: 'de-DE', key: 'de' }, { code: 'en-US', key: 'en' }]


i18n.use(initReactI18next).use(LanguageDetector) // passes i18n down to react-i18next
	.init({
		resources,
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
		fallbackLng: 'de',
		detection: {
			order: deepl ? ['cookie', 'htmlTag'] : undefined,
			caches: deepl ? ['cookie'] : undefined
		},
		react: {
			bindI18n: 'loaded languageChanged',
			bindI18nStore: 'added',
			useSuspense: true,
			transEmptyNodeValue: '️️⚠️ MISSING TRANSLATION ⚠️', // what to return for empty Trans
			transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
			transKeepBasicHtmlNodesFor: [
				'br',
				'strong',
				'i',
				'li',
				'ul',
				'ol',
				'p',
				'b',
				'<',
				'table',
				'tbody',
				'th',
				'td',
				'tr',
			], // don't convert to <1></1> if simple react elements
		},
	})

export default i18n
