import useAsyncEffect from '@n1ru4l/use-async-effect'
import useApi, { QueryKey } from 'hooks/useApi'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import routesDictionary from 'routes'
import BigNumberInput from 'shared/components/BigNumberInput'
import CompensationDataTable from 'shared/components/CompensationDataTable'
import CompensationShares from 'shared/components/CompensationShares'
import RangeSlider from 'shared/components/RangeSlider'
import SwitchSelect from 'shared/components/SwitchSelect'
import { CompensationInputType, CompensationUnit } from 'shared/enums'
import delay from 'shared/helper/delay'
import { sanitizeDecimalNumber } from 'shared/helper/sanitizeMaskedValue'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { BonusMonthListData, CompensationData, CompensationTypeData } from 'shared/interfaces'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'
import Button, { ButtonType } from '../../shared/components/Button'

const defaultPercentage = '50'
export const compensationTypesByNumber = {
	'01':'salary',
	'02': 'christmasBonus',
	'03':'holidayBonus',
	'04': 'tariffAdditionalBonus',
	'05': 'earningsBonus',
	'06': 'variableBonus',
	'07': 'bonus',
	'08': 'gratuityBonus',
}

const Bonus: React.FC<{ id: CompensationTypeData['id']; month: BonusMonthListData['month'] }> = ({ id, month }) => {
	const { getChildPath, navigateTo } = useRouteHelper()
	useBackButtonPath(`${getChildPath(routesDictionary.deferredCompensation, 'selectBonusAmount')}/${id}/${month}`)
	useNavigationTitle(<Trans i18nKey="view.deferredCompensation.pageTitle.bonus" />)
	const { t } = useTranslation()
	const api = useApi()
	const queryClient = useQueryClient()

	const simulationQuery: [
		QueryKey.compensationSimulationBonus,
		{
			type: string
			month: number
			percentage?: number
			amount?: number
		}
	] = [
		QueryKey.compensationSimulationBonus,
		{
			type: id,
			month,
			percentage: Number(defaultPercentage),
		},
	]
	const { bonusUnit, bonusValue } = queryClient.getQueryData<CompensationData>(simulationQuery) || {}

	const { data: settings } = useQuery(
		[QueryKey.compensationSimulationSettings, 'bonus'],
		api.getCompensationSimulationSettings
	)

	const [percentage, setPercentValue] = useState<number | undefined>(
		CompensationUnit.percent === bonusUnit ? bonusValue : settings ? settings.percentage.max / 2 : undefined
	)

	const [amount, setAmountValue] = useState<string | undefined>(
		CompensationUnit.euro === bonusUnit ? String(bonusValue) : undefined
	)

	const [inputType, setInputType] = useState<CompensationInputType>(
		CompensationUnit.euro === bonusUnit ? CompensationInputType.amount : CompensationInputType.percentage
	)

	const { data: simulation } = useQuery({ queryKey: simulationQuery, queryFn: api.getCompensationSimulationBonus })

	useEffect(() => {
		sessionStorage.removeItem('deferredCompensationSimulationData')
	}, [])

	const onSubmit = () => {
		sessionStorage.setItem(
			'deferredCompensationSimulationData',
			JSON.stringify({
				...simulation,
				type: 'bonusShare',
				bonusType: compensationTypesByNumber[id as keyof typeof compensationTypesByNumber ],
				id,
				input: {
					[inputType]:
						inputType === CompensationInputType.percentage ? percentage : sanitizeDecimalNumber(amount),
				},
			})
		)
		navigateTo(getChildPath(routesDictionary.deferredCompensation, 'summary'))
	}

	useEffect(() => {
		if (undefined !== settings && undefined === percentage) {
			setPercentValue(Math.round(settings.percentage.max / 2))
		}
	}, [settings, percentage])

	useAsyncEffect(
		function* (setErrorHandler: any, c: any) {
			if (undefined === percentage && undefined === amount) {
				return
			}

			let queryString
			let timeoutDuration = 0

			switch (inputType) {
				/**
				 * FIXME:
				 * somehow the query cache does not get updated for the initial
				 * percentage value.
				 * the defaultPercentage is a string at the moment and must be casted to an integer, in order to make this work.
				 *
				 * steps to reproduce:
				 * 1. do not cast percentage to an integer OR cast defaultPercentage to a number
				 * 2. load view
				 * 3. switch to amount and back to percentage
				 * 4. cached query is not set
				 */
				case CompensationInputType.percentage:
					queryString = {
						type: id,
						month,
						percentage: Number(percentage),
					}
					timeoutDuration = 500
					break

				case CompensationInputType.amount:
					queryString = {
						type: id,
						month,
						amount: Math.floor(sanitizeDecimalNumber(amount)),
					}
					break
			}

			/**
			 * delay fetching the query if percentage is changed.
			 * this prevents making too many requests to the api
			 */
			yield* c(delay(timeoutDuration))

			const updatedSimulation = yield* c(
				queryClient.fetchQuery(
					[QueryKey.compensationSimulationBonus, queryString],
					api.getCompensationSimulationBonus
				)
			)

			queryClient.setQueryData(simulationQuery, updatedSimulation)
		},
		[percentage, amount, inputType]
	)

	return (
		<div className="deferred-compensation grid grid--center-scroll center-view">
			{!!simulation && (
				<>
					<div className="grid__center">
						<p className="text-align--left margin--vertical">
							<Trans i18nKey="view.deferredCompensation.bonus.headline" />
						</p>

					
							<SwitchSelect
								options={[
									{
										value: CompensationInputType.percentage,
										label: t('view.deferredCompensation.buttonLabel.percent'),
									},
									{
										value: CompensationInputType.amount,
										label: t('view.deferredCompensation.buttonLabel.amount'),
									},
								]}
								value={inputType}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
									setInputType(event.target.value as CompensationInputType)
								}
							/>
					

						{settings && inputType === CompensationInputType.percentage && (
							<RangeSlider
								{...settings.percentage}
								unit="%"
								value={percentage || 0}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
									setPercentValue(Number(e.target.value))
								}
							/>
						)}

						{settings && inputType === CompensationInputType.amount && (
							<>
								<div className="margin--large margin--vertical">
									<CompensationShares
										ownContribution={simulation.ownContribution}
										employerContribution={simulation.employerContribution}
										sum={simulation.pensionTrust}
									/>
								</div>
								<BigNumberInput
									placeholder={t('view.deferredCompensation.compensationValuePlaceholder')}
									value={amount || ''}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										setAmountValue(e.target.value)
									}
									minValue={settings.amount.min}
									maxValue={settings.amount.max}
									allowDecimal={false}
								/>
								<CompensationDataTable
									ownContribution={simulation.ownContribution}
									employerBaseAmount={simulation.employerBaseAmount}
									matchingAmount={simulation.matchingAmount}
									sum={simulation.pensionTrust}
									unit={CompensationUnit.euro}
									viewDependentTranslations="bonus"
								/>
							</>
						)}
					</div>

					<Button
						onClick={onSubmit}
						className="grid__bottom"
						label={t('generic.next')}
						type={ButtonType.primary}
						disabled={
							(undefined === percentage && CompensationInputType.percentage === inputType) ||
							(undefined === amount && CompensationInputType.amount === inputType)
						}
					/>
				</>
			)}
		</div>
	)
}

export default Bonus
