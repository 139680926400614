import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { uid } from 'react-uid'
import Accordion, { AccordionProps } from 'shared/components/Accordion'
import { useScreenOrientation } from 'shared/hooks/useScreenOrientation'

export interface AccordionGroupProps {
	options: AccordionProps[]
	selectedItem?: number
	dataDriven?: boolean
	className?: string
	onSelectItem?: (selectedItem: number) => void
	iconColor?: string
	allowMultipleOpen?: boolean
}

const AccordionGroup: FunctionComponent<AccordionGroupProps> = (props) => {
	const [selectedItems, setSelected] = useState<boolean[]>(() => {
		const items = new Array(props.options.length).fill(false)

		if (props.selectedItem) {
			items[props.selectedItem] = true
		}

		return items
	})

	const contentHeights = useRef({})

	const [style, setStyle] = useState<React.CSSProperties>()

	/**
	 * this is kind of hacky to trigger triple rerender after changing orientation.
	 * but it is needed as the contentHeights ref is only updated after the layoutEffect
	 * in the accordions.
	 * to improve this, the height calculation and setting of the accordions needs rework
	 */
	const [currentOrientation, setCurrentOrientation] = useState<OrientationType>()
	const orientation = useScreenOrientation()

	const onClick = useCallback(
		(index: number) => {
			if (!props.dataDriven) {
				const currentSelectedItems = [...selectedItems]

				if (props.allowMultipleOpen) {
					const newState = [...currentSelectedItems]
					newState[index] = selectedItems[index] === true ? false : true
					setSelected(newState)
					return
				}

				const newState = new Array(props.options.length).fill(false)
				newState[index] = selectedItems[index] === true ? false : true

				setSelected(newState)
			}
		},
		[props.allowMultipleOpen, props.dataDriven, props.options.length, selectedItems]
	)

	useEffect(() => {
		if (props.selectedItem === undefined) {
			return
		}

		onClick(props.selectedItem)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.selectedItem])

	// useEffect(() => {
	// 	if (props.onSelectItem) {
	// 		props.onSelectItem(selectedItem)
	// 	}
	// 	// eslint-disable-next-line
	// }, [selectedItems])

	useEffect(() => {
		setStyle(getStyle())
		// eslint-disable-next-line
	}, [selectedItems, props.selectedItem, currentOrientation])

	useEffect(() => {
		const timeout = setTimeout(() => {
			setCurrentOrientation(orientation)
		})

		return () => clearTimeout(timeout)
	}, [orientation])

	const checkOpen = (index: number) => {
		if (!props.dataDriven) {
			return selectedItems[index] === true
		} else {
			return props.selectedItem === index
		}
	}

	const calculatePreviousOffsets = (index: number) => {
		const heights = selectedItems.map((v, i) => {
			if (selectedItems[i] === true) {
				return (contentHeights.current as any)[i]
			} else {
				return 0
			}
		})

		return heights.slice(0, index).reduce((acc, curr) => acc + curr, 0)
	}

	const renderAccordions = () => {
		return props.options.map((element, index) => {
			return (
				<div
					className={`accordion-group__accordion-wrapper ${checkOpen(index) && 'open'}`}
					key={uid(element)}
					style={
						{
							'--accordion-offset': `${calculatePreviousOffsets(index) || 0}px`,
						} as React.CSSProperties
					}
				>
					<Accordion
						id={element.id}
						header={element.header}
						open={checkOpen(index) || !!element.open}
						dataDriven={props.dataDriven}
						onClick={() => {
							onClick(index)
							if (element.onClick) {
								element.onClick()
							}
						}}
						afterRender={(height) => {
							contentHeights.current = { ...contentHeights.current, [index]: height }
						}}
						iconColor={props.iconColor || undefined}
					>
						{element.content}
					</Accordion>
				</div>
			)
		})
	}

	const getCumulativeHeight = () => {
		const heights = contentHeights.current as any

		const openAccordions = props.dataDriven
			? props.options.map((v) => {
					return v.open
			  })
			: selectedItems

		return openAccordions
			.map((v, i) => {
				if (v === true) {
					return heights[i]
				} else {
					return undefined
				}
			})
			.filter((v) => v)
			?.reduce((a, b) => a + b, 0)
	}

	const getStyle = () => {
		const cumulativeHeight = getCumulativeHeight()

		document.documentElement.style.setProperty('--accordion-content-height', `${cumulativeHeight}px`)

		return {
			'--content-height': `${cumulativeHeight}px`,
		} as React.CSSProperties
	}

	return (
		<div
			className={`accordion-group ${props.dataDriven ? 'accordion-group--controlled' : ''} ${
				props.className ? props.className : ''
			}`}
			style={style}
		>
			{renderAccordions()}
		</div>
	)
}

export default AccordionGroup
