import { FunctionComponent, useEffect, useState } from 'react'
import Button, { ButtonProps } from './Button'

const FALLBACK_COOLDOWN_IN_S = 30

export const ButtonWithCooldown: FunctionComponent<
	{ initiallyEnabled?: boolean; cooldownTime?: number } & ButtonProps
> = ({ onClick, cooldownTime, initiallyEnabled, className, disabled, ...props }) => {
	const [activeCoolDown, setActiveCoolDown] = useState(!!!initiallyEnabled)

	let cooldown: ReturnType<typeof setTimeout>

	const activateCooldown = () => {
		if (cooldown) {
			clearInterval(cooldown)
		}

		cooldown = setTimeout(() => {
			console.log('timeout over')
			setActiveCoolDown(false)
		}, (cooldownTime || FALLBACK_COOLDOWN_IN_S) * 1000)
	}

	useEffect(() => {
		activeCoolDown && activateCooldown()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeCoolDown])

	useEffect(() => {
		!initiallyEnabled && activateCooldown()

		return () => {
			clearInterval(cooldown)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Button
			className={`${activeCoolDown ? 'pointer-events--force-none' : ''}` + className}
			disabled={disabled || activeCoolDown}
			onClick={() => {
				if (activeCoolDown) {
					return
				}
				setActiveCoolDown(true)
				onClick()
			}}
			{...props}
		/>
	)
}
