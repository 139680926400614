import classNames from 'classnames'
import { HintsBox } from 'components/HintsBox'
import { IconType } from 'components/Icons'
import useAuthApi from 'hooks/useAuthApi'
import { t } from 'i18next'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { trackPromise } from 'react-promise-tracker'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import { ButtonWithCooldown } from 'shared/components/ButtonWithCooldown'
import Form, { FormFields, FormFieldType, FormRefActions } from 'shared/components/Form'
import Icon from 'shared/components/Icon'
import { TextInputType } from 'shared/components/TextInput'
import numbersOnlyString from 'shared/helper/numbersOnlyString'
import { NoNullField } from 'shared/helper/types/noNullField'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { MigrateUserArguments } from 'shared/interfaces'
import { useBackButtonClickHandler } from 'state/useHeaderState'
import {
	supportedPhoneNumberCountries,
	phoneNumberDefaultCountry,
	isSupportedPhoneNumberType,
} from 'supported-phone-number-countries'

type TMigrationStep = {
	formInputValues: TMigrationFormFieldValues
	handleOnSubmit: (
		submittedFields: TMigrationFormFieldValues
	) => Promise<boolean | { abort?: boolean; successful?: boolean; errorMessages?: string[] }>
	handleOnSuccess: () => void
}

type TMigrationFormFieldValues = NoNullField<MigrateUserArguments>

const Migration = () => {
	const { migrateUser } = useAuthApi()
	const [currentStep, setCurrentStep] = useState<number>(0)
	const [showStartScreen, setShowStartScreen] = useState(true)
	const filledOutFormInputs = useRef<TMigrationFormFieldValues>({})

	const getCurrentStep = () => {
		return currentStep > 0 ? currentStep - 1 : 0
	}

	const handleBackClick = (step: number) => {
		if (step === 0 && showStartScreen === false) {
			setShowStartScreen(true)
			return
		}

		setCurrentStep(step)
	}

	useBackButtonClickHandler({
		name: handleBackClick,
		parameters: getCurrentStep(),
	})

	const handleRegistrationResponse = ({
		response,
		fieldKeys,
	}: {
		response: Awaited<ReturnType<typeof migrateUser>>
		fieldKeys: Partial<keyof MigrateUserArguments>[]
	}) => {
		if (!response) {
			return false
		}
		const returnValue = {
			successful: true,
			errorMessages: [],
		}

		fieldKeys.forEach((key) => {
			if (key in response) {
				const fieldError = response[key] !== 'VALID' ? response[key] : false

				if (fieldError) {
					returnValue.successful = false
					returnValue.errorMessages.push(t(`generic.form.errors.${key}.${fieldError}`))
				}
			}
		})

		return returnValue
	}

	const handleOnSubmit = async (submittedFields: TMigrationFormFieldValues) => {
		const mergedSubmittedFields = {
			...filledOutFormInputs.current,
			...submittedFields,
		}

		filledOutFormInputs.current = mergedSubmittedFields

		const { email, emailTan, phoneNr, phoneNrTan, currentPassword } = mergedSubmittedFields

		const mappedRegistrationFields: MigrateUserArguments = {
			email,
			emailTan,
			phoneNr,
			phoneNrTan,
			currentPassword,
		}

		const response = await trackPromise(migrateUser(mappedRegistrationFields), 'migrate')
		const fieldKeysToValidate = Object.keys(submittedFields).filter((fieldKey) =>
			Object.keys(mappedRegistrationFields).includes(fieldKey)
		) as Partial<keyof MigrateUserArguments>[]

		return handleRegistrationResponse({ response, fieldKeys: fieldKeysToValidate })
	}

	const handleOnSuccess = async () => {
		setCurrentStep((step) => step + 1)
	}

	const currentRegistrationStep = migrationSteps[currentStep]
	const CurrentRegistrationStepComponent = currentRegistrationStep.component

	return (
		<div className="view-center center-view">
			{showStartScreen ? (
				<StartMigration onClick={() => setShowStartScreen(false)}></StartMigration>
			) : (
				<CurrentRegistrationStepComponent
					formInputValues={filledOutFormInputs.current}
					handleOnSubmit={handleOnSubmit}
					handleOnSuccess={handleOnSuccess}
				/>
			)}
		</div>
	)
}

const StartMigration: React.FunctionComponent<{ onClick: () => void }> = (props) => {
	return (
		<div className="migration grid  grid--center-scroll center-view">
			<div className="grid__top">
				<h1>
					<Trans i18nKey="view.migration.startMigration.headline"></Trans>
				</h1>
			</div>
			<div className="grid__center">
				<p>
					<Trans i18nKey="view.migration.startMigration.bodyText"></Trans>
				</p>
			</div>
			<div className="grid__bottom">
				<Button
					onClick={props.onClick}
					type={ButtonType.primary}
					label={<Trans i18nKey="generic.send"></Trans>}
				></Button>
			</div>
		</div>
	)
}

const Email: React.FunctionComponent<TMigrationStep> = (props) => {
	const { formInputValues = {}, handleOnSuccess } = props

	const [showTanInput, setShowTanInput] = useState(formInputValues.emailTan !== undefined)
	const [emailTan, setEmailTan] = useState<string | undefined>(formInputValues.emailTan)
	const [email, setEmail] = useState<string | undefined>(formInputValues.email)

	const handleOnEmailChange = useCallback(() => {
		if (showTanInput === false) {
			return
		}

		setEmail(undefined)
		setEmailTan(undefined)
		setShowTanInput(false)
	}, [showTanInput])

	const { t } = useTranslation()
	const [errorMessages] = useState<string[]>()

	const inputElements: FormFields = {
		email: {
			required: true,
			type: TextInputType.email,
			label: t('view.migration.email.inputLabel'),
			fieldType: FormFieldType.text,
			value: email,
			onChange: handleOnEmailChange,
		},
		emailTan: {
			required: true,
			inputMode: 'decimal',
			pattern: '[0-9,.]*',
			label: t('generic.form.fields.mfa.label'),
			autoComplete: 'one-time-code',
			fieldType: FormFieldType.text,
			valueFunction: {
				name: numbersOnlyString,
			},
			className: classNames({
				'visually-hidden': showTanInput !== true,
				'visually-hidden--false': showTanInput === true,
			}),
			disabled: showTanInput !== true,
			// icon: IconType.chat,
		},
	}

	const handleOnSubmit = async (submittedFields: Record<string, string>) => {
		if (submittedFields.emailTan === undefined) {
			// if (BLOCKED_REGISTRATION_EMAIL_DOMAINS?.some((domain) => submittedFields.email.endsWith(domain))) {
			// 	return {
			// 		errorMessages: [t(`generic.form.errors.email.NOT_PRIVATE`)],
			// 		successful: false,
			// 	}
			// }

			if (props.formInputValues.emailTan) {
				props.formInputValues.emailTan = undefined
			}

			const registrationStatus = await props.handleOnSubmit(submittedFields)

			if (typeof registrationStatus === 'object' && registrationStatus.successful === false) {
				return registrationStatus
			}

			setEmail(submittedFields.email)
			setShowTanInput(true)

			return { abort: true }
		}

		return props.handleOnSubmit(submittedFields)
	}

	const handleOnResendTan = () => {
		if (!email) {
			return
		}

		handleOnSubmit({ email })
	}

	return (
		<div className="form-view no-auto-hyphens">
			<div className="form-view__title">
				<h1>
					<Trans i18nKey="view.migration.email.headline" />
				</h1>
			</div>

			<>
				<Form
					className="form-view"
					fields={inputElements}
					onSubmit={handleOnSubmit}
					onSuccess={handleOnSuccess}
					errorMessages={errorMessages}
					submitLabel={t('generic.send')}
					promiseTracker={{ area: 'migrate' }}
					updatedValues={{ emailTan: emailTan }}
				>
					{' '}
					<div className="form-view__bodytext margin--bottom margin--small">
						{showTanInput ? (
							<HintsBox style={{ order: '1' }}>
								<Trans
									i18nKey="generic.form.fields.emailTan.hint"
									values={{ email }}
									components={{
										resendCode: (
											<ButtonWithCooldown
												className="margin--top margin--large"
												onClick={handleOnResendTan}
												type={[ButtonType.secondary, ButtonType.small]}
											/>
										),
									}}
								/>
							</HintsBox>
						) : (
							<p>
								<Trans i18nKey="view.migration.email.bodyText" />
							</p>
						)}
					</div>
				</Form>
			</>
		</div>
	)
}

const Phone: React.FunctionComponent<TMigrationStep> = (props) => {
	const { formInputValues = {}, handleOnSuccess } = props
	const [showTanInput, setShowTanInput] = useState(formInputValues.phoneNrTan !== undefined)
	const [phoneNrTan, setPhoneNrTan] = useState<string | undefined>(formInputValues.phoneNrTan)
	const [phoneNr, setPhoneNr] = useState<string | undefined>(formInputValues.phoneNr)

	const { t } = useTranslation()
	const [errorMessages] = useState<string[]>()

	const handleOnPhoneNumberChange = useCallback(() => {
		if (showTanInput === false) {
			return
		}

		setPhoneNr(undefined)
		setPhoneNrTan(undefined)
		setShowTanInput(false)
	}, [showTanInput])

	const handleOnResendTan = () => {
		if (!phoneNr) {
			return
		}

		handleOnSubmit({ phoneNr })
	}

	const inputElements: FormFields = {
		phoneNr: {
			value: '+49',
			required: true,
			type: TextInputType.text,
			usePhoneInput: true,
			label: t('view.migration.phone.inputLabel'),
			onChange: handleOnPhoneNumberChange,
			fieldType: FormFieldType.phoneInput,
			phoneInputProps: {
				countries: supportedPhoneNumberCountries,
				defaultCountry: phoneNumberDefaultCountry,
				addInternationalOption: false,
				international: true,
			},
		},
		phoneNrTan: {
			required: true,
			inputMode: 'decimal',
			pattern: '[0-9,.]*',
			label: t('generic.form.fields.mfa.label'),
			autoComplete: 'one-time-code',
			fieldType: FormFieldType.text,
			valueFunction: {
				name: numbersOnlyString,
			},
			className: classNames({
				'visually-hidden': showTanInput !== true,
				'visually-hidden--false': showTanInput === true,
			}),
			disabled: showTanInput !== true,
		},
	}

	const handleOnSubmit = async (submittedFields: Record<string, string>) => {
		if (isSupportedPhoneNumberType(submittedFields.phoneNr) === false) {
			return { successful: false, errorMessages: [t(`generic.form.errors.phoneNr.NOT_SUPPORTED`)] }
		}

		if (submittedFields.phoneNrTan === undefined) {
			const registrationStatus = await props.handleOnSubmit(submittedFields)

			if (props.formInputValues.phoneNrTan) {
				props.formInputValues.phoneNrTan = undefined
			}

			if (typeof registrationStatus === 'object' && registrationStatus.successful === false) {
				return registrationStatus
			}

			setPhoneNr(submittedFields.phoneNr)
			setShowTanInput(true)

			return { abort: true }
		}

		return props.handleOnSubmit(submittedFields)
	}

	return (
		<div className="form-view no-auto-hyphens">
			<div className="form-view__title">
				<h1>
					<Trans i18nKey="view.migration.phone.headline" />
				</h1>
			</div>

			<Form
				className="form-view"
				fields={inputElements}
				onSubmit={handleOnSubmit}
				alwaysAllowSubmit
				errorMessages={errorMessages}
				onSuccess={handleOnSuccess}
				submitLabel={t('generic.send')}
				promiseTracker={{ area: 'migrate' }}
				updatedValues={{ phoneNrTan: phoneNrTan }}
			>
				<div className="form-view__bodytext margin--vertical">
					<>
						<>
							<p>
								<Trans i18nKey="view.migration.phone.bodyText" />
							</p>

							<p>
								<Trans i18nKey="view.migration.phone.hint" />
							</p>

							<div>
								{showTanInput === false && (
									<HintsBox>
										<p className="flex flex--align-items-start">
											<Trans i18nKey="generic.form.fields.phoneNr.disclaimer" />
										</p>
									</HintsBox>
								)}

								{showTanInput && (
									<HintsBox style={{ order: '1' }}>
										<Trans
											i18nKey="generic.form.fields.phoneNrTan.hint"
											values={{
												phoneNr: formatPhoneNumberIntl(
													phoneNr as Parameters<typeof formatPhoneNumberIntl>[0]
												),
											}}
											components={{
												resendCode: (
													<ButtonWithCooldown
														className="margin--top"
														onClick={handleOnResendTan}
														type={[ButtonType.secondary, ButtonType.small]}
													/>
												),
											}}
										/>
									</HintsBox>
								)}
							</div>
						</>
					</>
				</div>
			</Form>
		</div>
	)
}
const Password: React.FunctionComponent<TMigrationStep> = (props) => {
	const sendPasswordForm = useRef<FormRefActions>()

	const inputElements: FormFields = useMemo(() => {
		let fields: FormFields = {
			currentPassword: {
				required: true,
				type: TextInputType.password,
				label: t('generic.password'),
				hidePasswordLabel: t('generic.hidePassword'),
				showPasswordLabel: t('generic.showPassword'),
				fieldType: FormFieldType.text,
			},
		}

		return fields
		// eslint-disable-next-line
	}, [])

	return (
		<div className="form-view">
			<div className="form-view__title">
				<h1>
					<Trans i18nKey={`view.migration.password.headline`} />
				</h1>
			</div>

			<Form
				ref={sendPasswordForm}
				className="form-view"
				fields={inputElements}
				onSubmit={props.handleOnSubmit}
				onSuccess={props.handleOnSuccess}
				promiseTracker={{ area: 'migrate' }}
				submitLabel={t(`generic.send`)}
			>
				<div className="form-view__bodytext"></div>
			</Form>
		</div>
	)
}

const Success: React.FC = () => {
	const { navigateTo } = useRouteHelper(routesDictionary.payoutOptions)

	const onClick = async () => {
		navigateTo('/logout')
	}

	return (
		<div className="grid center-view payout-success">
			<div className="grid__top"></div>
			<div className="grid__center">
				<h3>
					<b>
						<Trans i18nKey="view.migration.success.headline"></Trans>
					</b>
				</h3>
				<Icon
					type={IconType.success}
					color={'var(--color-cyan)'}
					className="center-view margin--top margin--large"
				></Icon>
				<p className="text-align--center margin--top">
					<Trans i18nKey="view.migration.success.body"></Trans>
				</p>
			</div>
			<div className="grid__bottom">
				<Button
					className="margin--top margin--large"
					type={ButtonType.primary}
					label={t('view.migration.success.button')}
					onClick={onClick}
				></Button>
			</div>
		</div>
	)
}

const migrationSteps = [
	{ name: 'setEmail', component: Email },
	{ name: 'setPhone', component: Phone },
	{ name: 'enterPassword', component: Password },
	{ name: 'success', component: Success },
]

export default Migration
