import { IconType } from 'components/Icons'
import useApi, { QueryKey } from 'hooks/useApi'
import { t } from 'i18next'
import { createRef, FunctionComponent, useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { useMutation, useQueryClient, useQuery } from 'react-query'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import Icon from 'shared/components/Icon'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath } from 'state/useHeaderState'

const EnterPensionDate: FunctionComponent<{
	pensionType: 'Ruhestand' | 'VorgezogenerRuhestand'
	exitDate?: string | null
	originalExitDate: string | null
}> = (props) => {
	const api = useApi()
	const initialDate = props.exitDate
		? new Date(props.exitDate).toLocaleDateString('de-DE', {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
		  })
		: null
	const { navigateTo, getChildPath } = useRouteHelper()
	const { data } = useQuery(QueryKey.payoutModelInProgress, api.getPayoutModelInProgress)

	const [pensionDate, setPensionDate] = useState<string | undefined>(
		initialDate ? initialDate.split('.').reverse().join('-') : undefined
	)
	const queryClient = useQueryClient()

	useBackButtonPath(getChildPath(routesDictionary.payoutOptions, 'benefitType'))

	const mutation = useMutation(api.patchPayoutModel, {
		onSuccess: (data) => {
			localStorage.removeItem('benefit-type')
			queryClient.setQueryData(QueryKey.payoutModelInProgress, data)
			nextView()
		},
	})

	const nextView = () => navigateTo(getChildPath(routesDictionary.payoutOptions, 'payoutModel'))
	const validLength = pensionDate && pensionDate.length === 10

	const minDate = props.originalExitDate ? new Date(props.originalExitDate) : new Date()
	const inValidDate = validLength && !(new Date(pensionDate).getTime() >= minDate.getTime())

	const pensionDateIsValid = validLength && !inValidDate
	const [inputDirty, setInputDirty] = useState(false)
	const dateInputRef = createRef<HTMLInputElement>()

	const getCurrentDateString = (): string => {
		let local = new Date()
		local.setMinutes(local.getMinutes() - local.getTimezoneOffset())
		return local.toJSON().slice(0, 10)
	}

	useEffect(() => {
		if (!dateInputRef.current) {
			return
		}

		const dateFieldValue =
			initialDate !== undefined ? initialDate?.split('.').reverse().join('-') : getCurrentDateString()

		dateInputRef.current.value = dateFieldValue || ''
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const uploadPensionDate = () => {
		if (!pensionDateIsValid) {
			return
		}

		const isAllowedPensionType = ['Ruhestand', 'VorgezogenerRuhestand'].includes(props.pensionType)
		const isSameAsSavedValue =
			data?.leistungsart?.type && isAllowedPensionType && props.pensionType === data.leistungsart.type
		const isSameDate =
			data?.leistungsart &&
			'austrittsdatum' in data.leistungsart &&
			data.leistungsart.austrittsdatum === pensionDate

		if (isSameAsSavedValue && isSameDate) {
			nextView()
			return
		}

		if (isAllowedPensionType) {
			const leistungsart = {
				type: props.pensionType as any,
				austrittsdatum: pensionDate,
			}

			mutation.mutate({ leistungsart })
		}
	}
	return (
		<>
			{' '}
			<div className="grid__center">
				<h3 className="font-family-alternative--light">
					<Trans i18nKey="view.payoutOptions.chapterOne.payoutDate.pensionQueryText" />
				</h3>

				<>
					<input
						type="date"
						ref={dateInputRef}
						placeholder="JJJJ-MM-TT"
						pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
						className="date-input h3 font-family-alternative--light"
						onChange={(e: any) => {
							!inputDirty && setInputDirty(true)
							setPensionDate(e.target.value)
						}}
					></input>

					<br />
				</>

				<div className="payout-date__info-box margin--top">
					<Icon type={IconType.alert} color={'var(--color-cyan)'}></Icon>
					<p className="text-color-blue margin-top margin">
						<Trans i18nKey="view.payoutOptions.chapterOne.payoutDate.infoText" />
					</p>
				</div>
			</div>
			<div className="grid__bottom">
				<Button
					type={ButtonType.primary}
					label={t('view.payoutOptions.chapterOne.benefitType.submit')}
					onClick={uploadPensionDate}
					disabled={!pensionDateIsValid}
				></Button>
				<div className="text-align--center">
					{/* Date must be in the future */}
					{!pensionDateIsValid && !initialDate && (
						<Trans i18nKey="view.payoutOptions.chapterOne.payoutDate.dateErrorText" />
					)}
					{!pensionDateIsValid && initialDate && props.originalExitDate && (
						<Trans
							i18nKey="view.payoutOptions.chapterOne.payoutDate.dateErrorTextAlt"
							values={{ date: new Date(props.originalExitDate).toLocaleDateString('de-DE') }}
						/>
					)}
				</div>
			</div>
		</>
	)
}

export default EnterPensionDate
