import { IconType } from 'components/Icons'
import { useAuth } from 'hooks/useAuth'
import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Button, { ButtonType } from 'shared/components/Button'
import Icon from 'shared/components/Icon'
import { useResetHeaderState } from 'state/useHeaderState'

const TerminateDigitalParticipation = (props: any, ref: any) => {
	useResetHeaderState()

	const { t } = useTranslation()
	const auth = useAuth()

	useEffect(() => {
		auth.signout()
		// eslint-disable-next-line
	}, [])

	return (
		<div className="terminate-digital-participation center-view">
			<div className="terminate-digital-participation__header flex flex--direction-column flex--align-items-center">
				<div className="page-title">
					<h1>
						<Trans i18nKey="view.terminateDigitalParticipation.pageTitle" />
					</h1>
				</div>

				<Icon
					className="terminate-digital-participation__icon margin--vertical margin--large no-margin--top"
					type={IconType.terminateDigitalParticipation}
				/>
				<p>
					<Trans i18nKey="view.terminateDigitalParticipation.copyIntro" />
				</p>
			</div>

			<div className="contact__phone ">
				<p>
					<b>
						<Trans i18nKey="view.terminateDigitalParticipation.call.headline" />
					</b>
					<br />
					<b className="font-family-alternative font-size-m">
						<Trans i18nKey="generic.helpHotline.tel" />
					</b>
					<br />
				</p>

				<Button type={ButtonType.primary} href={t('generic.helpHotline.telLink')}>
					<Trans i18nKey="view.terminateDigitalParticipation.call.button.label" />
				</Button>
			</div>

			<div className="contact__email">
				<p>
					<b>
						<Trans
							i18nKey="view.terminateDigitalParticipation.email.headline"
							values={{ emailAddress: t('generic.helpHotline.mail') }}
						>
							<a
								className="font-size-m font-family-alternative--medium"
								href={t('generic.helpHotline.mailLink')}
							>
								{' '}
							</a>
						</Trans>
					</b>
				</p>

				<Button href={t('generic.helpHotline.mailLink')} type={ButtonType.primary}>
					<Trans i18nKey="view.terminateDigitalParticipation.email.button.label" />
				</Button>
			</div>

			<div className="contact__footer">
				<p>
					<Trans i18nKey="view.terminateDigitalParticipation.call.copy" />
					<br />
					<Trans i18nKey="view.terminateDigitalParticipation.email.copy" />
				</p>
				<p>
					<Trans i18nKey="view.terminateDigitalParticipation.mail.copy" />
				</p>
				<p>
					<Trans i18nKey="view.terminateDigitalParticipation.mail.address" />
				</p>
			</div>
		</div>
	)
}

export default TerminateDigitalParticipation
