const numOfUppercaseLetters = (pw: string): number => {
	return pw.replace(/[^a-z]+/g, '').length
}

const numOfLowercaseLetters = (pw: string): number => {
	return pw.replace(/[^a-z]+/g, '').length
}

const numOfDigits = (pw: string): number => {
	return pw.replace(/[^0-9]+/g, '').length
}

const numOfSpecialCharacters = (pw: string): number => {
	return pw.replace(/[^\w]+/g, '').length
}

const matchesPasswordPolicy = (pw: string): boolean => {
	if (pw.length < 10) {
		return false
	}
	if (numOfUppercaseLetters(pw) < 1) {
		return false
	}
	if (numOfLowercaseLetters(pw) < 1) {
		return false
	}
	if (numOfDigits(pw) < 1) {
		return false
	}
	if (numOfSpecialCharacters(pw) < 1) {
		return false
	}

	return true
}

export default matchesPasswordPolicy
