import { useAuth } from 'hooks/useAuth'
import React from 'react'
import LoadingSpinner from 'shared/components/LoadingSpinner'
import BackButton from './BackButton'
import { LanguageSwitch } from './LangSwitch'
import Navigation from './Navigation'
import NavigationTitle from './NavigationTitle'

interface HeaderProps {
	className?: string
}

const Header: React.FC<HeaderProps> = (props) => {
	const { userData } = useAuth()
	const isAuthenticated = !!userData
	const getClassNames = (): string => {
		const classes: string[] = ['header']

		if (props.className) {
			classes.push(props.className)
		}

		return classes.join(' ')
	}

	return (
		<header className={getClassNames()}>
			<BackButton />

			<NavigationTitle />
			<Navigation />
			{!isAuthenticated && <LanguageSwitch />}
			<LoadingSpinner />
		</header>
	)
}

export default Header
