import { t } from 'i18next'
import { FC } from 'react'
import { Trans } from 'react-i18next'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { components } from 'types/api-interface'
import PayoutmodelDocumentsSummary from '../ChapterOne/PayoutDocuments/PayoutDocumentsSummary'

const Summary: FC<{
	data: components['schemas']['AuszahlungsoptionenArbeitskopie']
}> = (props) => {
	const { navigateTo, getChildPath } = useRouteHelper(routesDictionary.payoutOptions)

	// const onClick = (step: number) => {
	// 	navigateTo(`${getChildPath(routesDictionary.payoutOptions, 'payoutModelInputForm')}/${step}`)
	// }

	if (!props.data.leistungsart || !props.data.modell || !('type' in props.data.modell)) {
		return <></>
	}

	return (
		<div className="chapter-three-summary">
			<PayoutmodelDocumentsSummary showMinimalView={true} type="RENTENBESCHEINIGUNG" noFileContent={<></>} />

			<PayoutmodelDocumentsSummary showMinimalView={true} type="KRANKENVERSICHERUNG" noFileContent={<></>} />
			<PayoutmodelDocumentsSummary type="BANKVERBINDUNG" noFileContent={<></>} showMinimalView={true} />
			<Button
				className="payout-for-summary__button"
				type={[ButtonType.secondary, ButtonType.small]}
				label={t('generic.changeInputs')}
				onClick={() => {
					navigateTo(`${getChildPath(routesDictionary.payoutOptions, 'payoutDocuments')}`)
				}}
			></Button>
		</div>
	)
}

export const PayoutConfirmationChapterThree = {
	header: (
		<h3>
			<Trans i18nKey={'view.payoutOptions.payoutTypes.options.third.headlineShort'}></Trans>
		</h3>
	),
	content: Summary,
}
