import React from 'react'
import { Trans } from 'react-i18next'
import Captcha from 'shared/components/Captcha'
import { useResetHeaderState } from 'state/useHeaderState'

const ConfirmCaptcha = () => {
	useResetHeaderState()

	return (
		<div className="form-view">
			<div className="form-view__title">
				<h1>
					<Trans i18nKey="view.login.captcha.pageTitle" />
				</h1>
			</div>

			<div className="form-view__bodytext">
				<p>
					<Trans i18nKey="view.login.captcha.bodytext" />
				</p>
			</div>
			<div className="form-view__additional-content">
				<Captcha />
			</div>
		</div>
	)
}

export default ConfirmCaptcha
