import useApi, { QueryKey } from 'hooks/useApi'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import routesDictionary from 'routes'
import BigNumberInput from 'shared/components/BigNumberInput'
import Button, { ButtonType } from 'shared/components/Button'
import RangeSlider from 'shared/components/RangeSlider'
import SwitchSelect from 'shared/components/SwitchSelect'
import { currencyFormat } from 'shared/helper/numberFormats'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'
import { PayoutModelOptions } from 'views/PayoutModel'
import { components } from '../../../types/api-interface'
import PayoutModelShares from './PayoutModelShares'

export interface IMixedPayout {
	preSelection: string | undefined
	balance?: string
}

enum tabOptions {
	percent = 'percent',
	amount = 'amount',
}

const MixedPayout: FC<IMixedPayout> = ({ preSelection }) => {
	const { navigateTo, getChildPath } = useRouteHelper()
	useBackButtonPath(getChildPath(routesDictionary.payoutOptions, 'mixedPayoutPreslection'))
	useNavigationTitle(<Trans i18nKey="view.payoutModel.pageTitle" />)
	const { t } = useTranslation()
	const api = useApi()
	const queryClient = useQueryClient()
	const { data, status } = useQuery(QueryKey.pensionBalance, api.getPensionBalance)
	const payoutModelInProgressQuery = useQuery(QueryKey.payoutModelInProgress, api.getPayoutModelInProgress)
	const [showWarning, setShowWarning] = useState(false)
	const [touched, setTouched] = useState({
		[PayoutModelOptions.lumpSum]: false,
		[PayoutModelOptions.instalments20Years]: false,
		[PayoutModelOptions.instalments10Years]: false,
		[PayoutModelOptions.pension]: false,
	})
	const [lumpSumInput, setLumpSumInput] = useState(0)
	const [allowContinue, setAllowContinue] = useState(true)

	const mutation = useMutation(api.patchPayoutModel, {
		onSuccess: (data) => {
			localStorage.removeItem('mixed-payout-selection')
			queryClient.setQueryData(QueryKey.payoutModelInProgress, data)
			navigateTo(getChildPath(routesDictionary.payoutOptions, 'payoutSummary'))
		},
	})

	const possiblePayoutOptions = [
		PayoutModelOptions.lumpSum,
		PayoutModelOptions.instalments20Years,
		PayoutModelOptions.instalments10Years,
		PayoutModelOptions.pension,
	]

	const initialRender = useRef(true)

	/* get the payout settings from the query string
	and order them according to the input order */
	const extractPayoutSettings = () => {
		const settings = preSelection?.split(',') || []
		return possiblePayoutOptions.map((v) => (settings.includes(v) ? v : undefined)).filter((el) => el)
	}

	const allowedPayouts = useMemo(
		() => extractPayoutSettings(),
		// eslint-disable-next-line
		[preSelection]
	)
	const passiveInputKey = allowedPayouts?.[allowedPayouts.length - 1]

	if (!allowedPayouts) {
		console.log('payout selection missing')
	}

	const getSavedPayouts = (): components['schemas']['AuszahlungsmodellGemischt']['distribution'] | null => {
		if (payoutModelInProgressQuery?.data?.modell && 'type' in payoutModelInProgressQuery?.data?.modell) {
			if (payoutModelInProgressQuery?.data?.modell?.type === 'mixed') {
				return payoutModelInProgressQuery?.data?.modell
					?.distribution as components['schemas']['AuszahlungsmodellGemischt']['distribution']
			} else {
				return null
			}
		} else {
			return null
		}
	}

	const [tab, setTab] = useState(tabOptions.percent as string)
	const [sum, setSum] = useState(data?.totalAmount || 1)
	const [payouts, setPayouts] = useState<{ [key: string]: number }>(() => {
		const numberActivePayoutTypes = allowedPayouts?.length || 1
		const share = 100 / numberActivePayoutTypes
		const initPayouts: { [key: string]: number } = {}
		const savedPayouts = getSavedPayouts()

		possiblePayoutOptions.forEach((key) => {
			if (savedPayouts) {
				initPayouts[key] = allowedPayouts?.includes(key) ? (savedPayouts as any)[key] : 0
			} else {
				initPayouts[key] = allowedPayouts?.includes(key) ? share : 0
			}
		})

		let sum = 0

		Object.keys(initPayouts).forEach((key) => {
			sum += initPayouts[key]
		})

		if (passiveInputKey && sum !== 100) {
			initPayouts[passiveInputKey] = initPayouts[passiveInputKey] + 100 - sum
		}

		return initPayouts
	})

	/**
	 * init with zero
	 */
	const [payoutsAbsolute, setpayoutsAbsolute] = useState<{ [key: string]: number }>({
		[PayoutModelOptions.lumpSum]: 0,
		[PayoutModelOptions.instalments10Years]: 0,
		[PayoutModelOptions.instalments20Years]: 0,
		[PayoutModelOptions.pension]: 0,
	})

	const calcAbsolutePayout = () => {
		const update = Object.keys(payouts).reduce((prev: { [key: string]: any }, key: string) => {
			prev[key] = calcAbsolute(payouts[key])
			return prev
		}, {})

		setpayoutsAbsolute(update)
	}

	/**
	 * update percentages after using absolute numbers
	 */
	const updateAllPercentages = () => {
		const update = Object.keys(payoutsAbsolute).reduce((prev: { [key: string]: any }, key: string) => {
			prev[key] = Number(toPercent(payoutsAbsolute[key]).toFixed(2))
			return prev
		}, {})

		setPayouts(update)
	}

	// /**
	//  * calculate sum after absolute values changed
	//  */
	// useEffect(() => {
	// 	// console.log(payoutsAbsolute)
	// 	const sum: number = Object.values(payoutsAbsolute).reduce((acc, curr) => acc + curr)
	// 	setSum(sum)
	// }, [payoutsAbsolute])

	/**
	 * calculate absolute payout amount after relative values changed
	 */
	useEffect(() => {
		calcAbsolutePayout()
		// eslint-disable-next-line
	}, [payouts])

	// set absolute sum from data
	useEffect(() => {
		if (status === 'success') {
			setSum(data?.totalAmount || 0)
		}
	}, [status, data])

	//FIXME: simplify long typechecks, linter seems bugged at the moment

	useEffect(() => {
		if (payoutModelInProgressQuery?.data?.modell && 'type' in payoutModelInProgressQuery.data.modell) {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const isMixedPayout = payoutModelInProgressQuery?.data?.modell.type === 'mixed'

			if (payoutModelInProgressQuery?.data?.modell.type === 'mixed') {
				const value =
					(payoutModelInProgressQuery.data.modell.absoluteAmounts as Record<string, number>)?.lumpSum ||
					calcAbsolute(payouts.lumpSum)

				value && setLumpSumInput(value)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [payoutModelInProgressQuery.data, payoutModelInProgressQuery.status])

	/**
	 * convert absolute values to percent when you change from absolute-tab to percent-tab
	 */
	useEffect(() => {
		if (initialRender.current) {
			initialRender.current = false
		} else if (tab === tabOptions.percent) {
			updateAllPercentages()
		}
		// eslint-disable-next-line
	}, [tab])

	const calcAbsolute = (percent: number) => {
		return Math.round((sum / 100) * percent)
	}

	const toPercent = (v: number): number => {
		if (sum === 0) {
			return 0
		}
		return Number(((v / sum) * 100).toFixed(2))
	}

	if (!passiveInputKey) {
		return <></>
	}

	/* TODO: refacfor, simplify */
	const updatePercentages = (stringValue: number, key: keyof typeof payouts) => {
		const value = Number(stringValue)
		const oldValue = payouts[key]
		const passiveValue = payouts[passiveInputKey]
		const isIncrease = value > oldValue
		const delta = value - oldValue

		if ((isIncrease && delta <= passiveValue) || !isIncrease) {
			const updatePassiveValue = Number((passiveValue - delta).toFixed(2))
			setPayouts({ ...payouts, [key]: value, [passiveInputKey]: updatePassiveValue })
			!allowContinue && setAllowContinue(true)
		} else {
			setAllowContinue(false)
		}
	}

	const currencyToNumber = (value: string): number => {
		return Number(value.replace(' €', '').split('.').join(''))
	}

	const getAbsoluteAmounts = () => {
		const modell = payoutModelInProgressQuery.data?.modell
		return modell && 'type' in modell && modell.type === 'mixed' ? modell.absoluteAmounts : null
	}

	const getLumpSumPayload = () => {
		if (lumpSumInput) {
			return lumpSumInput
		}

		if (touched.lumpSum) {
			return 0
		}

		const absolutes = getAbsoluteAmounts() as Record<string, number>

		return absolutes?.lumpSum || 0
	}

	const onContinueClick = async () => {
		const isRelativeMode = tab === tabOptions.percent

		await mutation.mutate({
			modell: {
				type: 'mixed',
				distribution: {
					instalments20Years: payouts.instalments20Years,
					instalments10Years: payouts.instalments10Years,
					lumpSum: payouts.lumpSum,
					pension: payouts.pension,
				},

				/**
				 - Always save abslute amounts as zero, except when the user has activated absolute mode
				 */
				absoluteAmounts: {
					instalments20Years: 0,
					instalments10Years: 0,
					lumpSum: isRelativeMode ? 0 : getLumpSumPayload(),
					pension: 0,
				},
			},
		})
	}

	const getRangeSliderColor = (v: string) => {
		switch (v) {
			case PayoutModelOptions.instalments20Years:
				return 'range-slider--white'
			case PayoutModelOptions.instalments10Years:
				return 'range-slider--blue'
			case PayoutModelOptions.pension:
				return 'range-slider--dark-grey'
		}
	}

	const selector = (
		<SwitchSelect
			options={[
				{
					value: tabOptions.percent,
					label: t('view.payoutOptions.chapterOne.mixedPayout.percent'),
				},
				{
					value: tabOptions.amount,
					label: t('view.payoutOptions.chapterOne.mixedPayout.amount'),
				},
			]}
			value={tabOptions.percent}
			onChange={(e) => {
				setTab(e.target.value)
			}}
		></SwitchSelect>
	)

	const calcPercent = (sum: number, value: number) => {
		return Number(((100 / sum) * value).toFixed(2))
	}

	/**
	 *  Calculate the distribution of the remaining budget delta percentage.
	 * 	Value will only be deduced if the payou options
	 *  available as well as in the following order:
	 *
	 * 	1. pension
	 * 	2. instalments10Years
	 * 	3. instalments10Years
	 */
	const handleLumpSumInput = (e: React.FormEvent) => {
		const inputValue = (e.target as HTMLInputElement).value
		const value = currencyToNumber(inputValue)
		setLumpSumInput(value)

		// show warning if the lumpsum input is exceeds the available budget
		if (value > sum) {
			setShowWarning(true)
			return
		} else if (value < sum && showWarning) {
			setShowWarning(false)
		}

		const percent = calcPercent(sum, value)

		// amount decrease
		if (Number(payouts['lumpSum']) > Number(percent)) {
			updatePercentages(calcPercent(sum, value), 'lumpSum')
			return
		}

		// amount increase
		let increase = Number(percent) - payouts['lumpSum']

		const updateValues = { ...payouts }

		Object.entries(updateValues)
			.reverse()
			.forEach(([key, payout]) => {
				if (key === 'lumpSum') {
					return
				}

				if (increase === 0) {
					return
				}

				if (payout < increase) {
					updateValues[key] = 0
					increase = increase - payout
					return
				}

				updateValues[key] = payout - increase
				increase = 0
			})

		setPayouts({ ...updateValues, lumpSum: percent })
	}

	if (status !== 'success' || payoutModelInProgressQuery.status !== 'success') {
		return <></>
	}

	return (
		<div className="center-view ">
			<div>
				<div className="caption text-color-blue">
					<Trans i18nKey={`view.payoutOptions.chapterOne.mixedPayout.eyebrow`} />
				</div>
				<h3 className="no-margin--top">
					{' '}
					<Trans i18nKey={`view.payoutOptions.chapterOne.mixedPayout.headline`} />
				</h3>
				<div className="margin--top margin--bottom text-color-cyan">
					<Trans i18nKey={`view.payoutOptions.chapterOne.mixedPayout.available`} /> <br />
					{currencyFormat(data.totalAmount)} (Stand {new Date(data.date).toLocaleDateString()})
				</div>
			</div>
			<div className="margin--top">
				<PayoutModelShares
					exactLumpSum={lumpSumInput}
					showSums={tab === tabOptions.amount}
					options={[
						{
							contribution: payoutsAbsolute[PayoutModelOptions.lumpSum] || 0,
							label: t('view.payoutOptions.chapterOne.mixedPayout.lumpSum'),
							color: 'var(--color-cyan)',
							bgColor: 'var(--color-cyan)',
							textColor: 'var(--color-white)',
							type: PayoutModelOptions.lumpSum,
						},
						{
							contribution: payoutsAbsolute[PayoutModelOptions.pension] || 0,
							label: t('view.payoutOptions.chapterOne.mixedPayout.pension'),
							color: 'pink',
							bgColor: 'var(--color-grey--dark)',
							textColor: 'var(--color-white)',
							type: PayoutModelOptions.pension,
						},
						{
							contribution: payoutsAbsolute[PayoutModelOptions.instalments20Years] || 0,
							beforeSum: '',
							label: t('view.payoutOptions.chapterOne.mixedPayout.instalments20Years'),
							color: 'pink',
							bgColor: 'var(--color-white)',
							textColor: 'var(--color-blue)',
							type: PayoutModelOptions.instalments20Years,
						},
						{
							contribution: payoutsAbsolute[PayoutModelOptions.instalments10Years] || 0,
							label: t('view.payoutOptions.chapterOne.mixedPayout.instalments10Years'),
							beforeSum: '',
							color: 'pink',
							bgColor: 'var(--color-blue--medium)',
							textColor: 'var(--color-white)',
							type: PayoutModelOptions.instalments10Years,
						},
					]}
				/>

				<>
					{allowedPayouts.map((v, i) => {
						return (
							<div key={'payout-range-slider--' + i} className="mixed-payout-input">
								<p className="bold">
									<Trans i18nKey={`view.payoutOptions.chapterOne.mixedPayout.${v}`} />
									{v === passiveInputKey && (
										<Trans i18nKey={`view.payoutOptions.chapterOne.mixedPayout.passiveInputHint`} />
									)}
								</p>

								{v === 'lumpSum' && <> {selector}</>}

								{v === 'lumpSum' && tab === tabOptions.amount ? (
									<>
										<BigNumberInput
											className="no-margin--top "
											value={lumpSumInput}
											allowDecimal={false}
											onChange={handleLumpSumInput}
										/>
										{showWarning && (
											<p className="text-color-red warning margin--bottom">
												<b>
													<Trans
														i18nKey={`view.payoutOptions.chapterOne.mixedPayout.warning`}
													/>
												</b>
											</p>
										)}
									</>
								) : (
									<>
										<RangeSlider
											className={`no-margin--top  margin--auto ${getRangeSliderColor(
												v as string
											)}`}
											minValue={0}
											maxValue={100}
											unit="%"
											value={payouts[v as string]}
											readonly={v === passiveInputKey}
											onChange={(e: any) => {
												if (v !== undefined && touched[v as keyof typeof touched] === false) {
													const updateVal = { ...touched }
													updateVal[v as keyof typeof touched] = true
													setTouched(updateVal)
												}

												/**
												 * if the lumpSum range slider is touched, override the absolute input for lumpSum
												 */
												v === 'lumpSum' && setLumpSumInput(calcAbsolute(e.target.value))

												updatePercentages(e.target.value, v as string)
											}}
										></RangeSlider>
										{tab === tabOptions.amount && (
											<div
												className={`payout-range-slider__detail payout-range-slider__detail--${v}`}
											>
												<Trans i18nKey={`view.payoutOptions.chapterOne.mixedPayout.equals`} />{' '}
												{currencyFormat(payoutsAbsolute[v as string])}
											</div>
										)}
									</>
								)}
							</div>
						)
					})}
				</>
			</div>
			<div className="margin--top">
				<Button
					disabled={!allowContinue}
					label={t('view.payoutOptions.chapterOne.mixedPayout.buttonLabel')}
					type={ButtonType.primary}
					onClick={onContinueClick}
				/>
			</div>
		</div>
	)
}

export default MixedPayout
