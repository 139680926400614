import { IconType } from 'components/Icons'
import { useRoutes } from 'raviger'
import React from 'react'
import routesDictionary from 'routes'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import NotFound from 'views/NotFound'

export const riskOptionIcon: { [key: string]: IconType } = {
	X: IconType.actionRiskBenefitStandard,
	E: IconType.actionRiskBenefitIncreased,
	A: IconType.actionRiskBenefitNone,
}

export const riskYearsIcon: { [key: string]: IconType } = {
	1: IconType.action1Year,
	3: IconType.action3Year,
}

const RiskBenefit: React.FC = () => {
	const { routes, getMainPath } = useRouteHelper(routesDictionary.riskBenefit)
	const routeResult = useRoutes(routes, { basePath: getMainPath(routesDictionary.riskBenefit) })

	return routeResult || <NotFound />
}

export default RiskBenefit
