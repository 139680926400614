/* eslint-disable jsx-a11y/anchor-has-content */
import i18next from 'i18next'
import { FunctionComponent } from 'react'
import Button, { ButtonType } from 'shared/components/Button'

const PrivacyPolicy: FunctionComponent = (props) => {
	const handleDownload = () => {
		const link = document.createElement('a')
		link.target = '_blank'
		link.href = '/Pension_App_DSE.pdf'
		link.click()
	}
	return (
		<div className="text-align--left">
			{i18next.language.startsWith('de') ? (
				<>
					<div className="whole-terms-and-conditions text-align--left">
						<Button
							type={ButtonType.link}
							label={'Datenschutzerklärung herunterladen'}
							onClick={handleDownload}
						/>
						<h1>Datenschutzerklärung ZF Vorsorge App</h1>
						<p>Stand: 27. März 2023</p>
						<p>
							Diese Datenschutzerklärung informiert Sie über die Verarbeitung personenbezogener Daten im
							Rahmen der Nutzung der ZF Vorsorge App („<b>App</b>“). Die App wird von der ZF
							Friedrichshafen AG, Löwentaler Straße 20, 88046 Friedrichshafen („<b>ZF</b>“, „<b>wir</b>"
							oder „<b>uns</b>“) als datenschutzrechtlich Verantwortlicher angeboten. Die App wurde für ZF
							entwickelt und wird für ZF betrieben durch einen weisungsgebundenen Dienstleister. Die App
							ist als Webanwendung von einem Computer mit einem Webbrowser und auch als App mit einem
							mobilen Endgerät (Smartphone oder Tablet) nutzbar. Sie dient als Informations- und
							Interaktionstool zur Durchführung der betrieblichen Altersversorgung (im Speziellen: VO
							2019, „<b>ZF Vorsorge“</b>).
						</p>
						<p>
							Unter „personenbezogenen Daten“ sind sämtliche Informationen zu verstehen, die sich auf eine
							identifizierte oder identifizierbare natürliche Person beziehen.
						</p>
						<p>
							Weil uns der Schutz Ihrer Privatsphäre bei der Nutzung der App wichtig ist, möchten wir Sie
							mit den nachfolgenden Angaben darüber informieren, welche personenbezogenen Daten wir
							erheben, wenn Sie die App nutzen und wie wir mit diesen Daten umgehen. Sie können diese
							Datenschutzerklärung jederzeit unter dem Menüeintrag „Datenschutzerklärung“ innerhalb der
							App aufrufen.
						</p>
						<section>
							<h2>1. Datenverarbeitung im Rahmen der App </h2>
							<p>
								Im Zusammenhang mit der Bereitstellung und Nutzung der App speichern wir von Ihnen als
								Nutzer der App verschiedene personenbezogene Daten. <b>„Nutzer“</b> der App sind:
							</p>

							<p>
								Mitarbeiter der Gesellschaften der ZF-Unternehmensgruppe, die sich zum Nutzungszeitpunkt
								in einem Beschäftigungsverhältnis mit der ZF Friedrichshafen AG befinden oder befanden
								und berechtigt sind oder waren, an der ZF Vorsorge teilzunehmen. Zu der
								ZF-Unternehmensgruppe im vorstehenden Sinne gehören neben der ZF Friedrichshafen AG die
								mit der ZF Friedrichshafen AG im Sinne des § 15 AktG verbundenen Unternehmen und die
								Unternehmen, an denen ZF Friedrichshafen AG zu mindestens 50% beteiligt ist.
							</p>
							<p>
								Im Folgenden informieren wir Sie darüber, welche personenbezogenen Daten wir erheben und
								verarbeiten, wenn Sie die App installieren und verwenden:
							</p>

							<ol>
								<li>
									<p> Aktivierung eines Benutzerkontos und Anmeldung </p>
									<p>
										Um die App nutzen zu können, ist eine erstmalige Aktivierung des Benutzerkontos
										erforderlich, damit sich der Nutzer auf dem mobilen Endgerät oder dem Computer
										mit einem Webbrowser einloggen kann. Dabei verarbeiten wir die von Ihnen bei der
										Aktivierung angegebene private E-Mail-Adresse und Telefonnummer, die Ihnen
										zugesandten Aktivierungsdaten, das von Ihnen gewählte Passwort sowie die ZF VO
										Identifikationsnummer. Die Aktivierung des Benutzerkontos ist erst durch Eingabe
										eines neuen Passworts abgeschlossen. Wir protokollieren erfolgreiche
										Anmeldeversuche, fehlerhafte Anmeldeversuche und deren Anzahl, Änderungen des
										Passworts (nachfolgend zusammen „<b>weitere Anmeldeinformationen</b>“) und
										speichern die Gerätekennziffer (sog. Device-ID).
									</p>
									<p>
										Die laufende Anmeldung in der App erfolgt mit einer 2-Faktor-Authentifizierung.
										Der zweite Faktor, eine zufällig generierte sechsstellige Ziffer (<b>„TAN“</b>),
										wird Ihnen entweder per SMS oder E-Mail zugestellt. Im Rahmen der Aktivierung
										des Benutzerkontos erhalten Sie zur Verifizierung Ihrer Telefonnummer und
										E-Mail-Adresse jeweils eine TAN pro Kommunikationskanal. Nach Eingabe der
										persönlichen Anmeldedaten und des zweiten Faktors gelangen Sie zur Startseite
										der App.
									</p>
								</li>

								<li>
									{' '}
									<p>Abrufen und Anzeige von Informationen zur ZF Vorsorge</p>
									<p>
										Im Rahmen der App werden bestimmte Informationen verarbeitet, um Sie über die
										Performance und Ihren individuellen Kontostand Ihrer Entgeltwandlungen und Ihrer
										Arbeitgeberbeiträge zur ZF Vorsorge zu informieren. Diese Informationen umfassen
										insbesondere folgende personenbezogenen Daten in Bezug auf Ihr Einzelkonto:
										<br />
										&nbsp;
										<ul>
											<li>
												Stammdaten: Titel, Vor- und Nachname, Zusatzwort, Vorsatzwort,
												Anschrift, Benutzername (private E-Mail Adresse & ZF Vorsorge-ID);
											</li>

											<li>
												Wandlungsdaten: Entgeltumwandlungsbeträge, Arbeitgebergrundbeiträge,
												Matching-Beiträge;
											</li>

											<li>
												Informationen zum Kontostand (monatliche/jährliche Beiträge im Verlauf,
												Anzahl der bisherigen Beiträge unter Einbeziehung der Beiträge in
												vergangenen Jahren);
											</li>

											<li>
												Neuigkeiten bezüglich des Depot-Werts seit dem letzten Öffnen der App
												(Zugewinn / Verlust durch Kursentwicklung);
											</li>
											<li>Zusammensetzung der Kapitalanlage bzw. Fonds;</li>

											<li>
												Informationen zur persönlichen ZF Vorsorge (wochenaktueller Wert des
												Versorgungsguthabens, Aufteilung nach Eigenbeiträgen aus
												Entgeltumwandlung und Arbeitgeberbeiträgen (Arbeitgebergrundbeitrag und
												Matching-Beitrag) und Beitragsgarantie, Verlauf des Depots, jeweils
												unter Änderung der dargestellten Zeitspanne).
											</li>
										</ul>
										<br />
										Daneben erhalten Sie im Rahmen der App noch Informationen zum Kursverlauf
										(Performance / Kursverlauf des Spezialfonds, Verlauf des Vergleichsindex,
										Anteile am Spezialfonds im Depot, Wert eines Anteils jeweils unter Änderung der
										dargestellten Zeitspanne und des Wechsels zwischen verschiedenen
										Vergleichsindizes); zum Fonds (Zusammenfassung des Fonds und ggf. weitere
										Informationen) sowie allgemeine Informationen zur ZF Vorsorge sowie FAQs.{' '}
									</p>
								</li>

								<li>
									<p>
										Abschluss und Änderung von Entgeltumwandlungsvereinbarungen und Wahl der
										Auszahlungsoptionen
									</p>

									<p>
										Im Rahmen der App wird Ihnen ermöglicht, Ihre Entgeltumwandlung zu beantragen
										und im Nachgang zu erhöhen, zu reduzieren oder zu beenden. Ebenso erhalten Sie
										die Möglichkeit, in der App Ihre persönlichen Auszahlungsoptionen (20 Raten, 10
										Raten, Einmalkapital, Rente, Mischauszahlung) zu wählen. Vor Eintritt in die
										Altersrente werden Sie in der App erinnert, die Auszahlungsoptionen zu wählen.{' '}
									</p>

									<p>
										Um derartige Transaktion auszuführen (Entgeltumwandlung bzw. Wahl der
										Auszahlungsoptionen), erfolgt bei jeder Eingabe bzw. Veränderung von Daten die
										Authentifizierung mit einem zweiten Faktor (s. auch Punkt 1 „Aktivierung eines
										Benutzerkontos und Anmeldung“).{' '}
									</p>

									<p>
										Für diese Zwecke sind in der App Daten zu Ihrem Entgelt, Ihrem Alter und Ihrem
										voraussichtlichen Renteneintrittsalter hinterlegt. Ebenso wird Ihre Eingabe der
										Entgeltumwandlung weiterverarbeitet, um Sie im Rahmen der Entgeltabrechnung zu
										berücksichtigen. Die Wahl der Auszahlungsoptionen wird nach Eintritt des
										Leistungsfalls weiterverarbeitet, um Ihren individuellen Auszahlungsplan zu
										berücksichtigen. Zur Regelung des Vorgangs wurde eine Gesamtbetriebsvereinbarung
										(GBV Versorgungsordnung 2019 – Digital) abgeschlossen.{' '}
									</p>
								</li>
								<li>
									<p>Daten, die automatisch verarbeitet werden </p>

									<p>
										Im Rahmen Ihrer Nutzung der App erfassen und speichern wir bestimmte Daten
										automatisch. Bei jedem Zugriff eines Nutzers auf die App werden über diesen
										Vorgang bestimmte Informationen in einer Protokolldatei gespeichert. Zu den
										protokollierten Informationen gehören: Inhalt der Anforderung, Datum und Uhrzeit
										des Zugriffs, die übertragene Datenmenge, die Meldung, ob der Abruf erfolgreich
										war, Beschreibung des Typs des verwendeten Browsers, verwendetes Betriebssystem,
										die Gerätekennziffer sowie die Ihrem Endgerät zugewiesene IP-Adresse
										(nachfolgend zusammen „Protokolldaten“)
									</p>
								</li>
							</ol>
						</section>
						<section>
							<h2>2. Berechtigungen zum Zugriff auf Funktionen und Daten auf dem Endgerät </h2>
							<p>
								Um alle Funktionen der App nutzen zu können, müssen Sie der App Berechtigungen zum
								Zugriff auf Funktionen und Daten Ihres mobilen Endgeräts einräumen. Im Einzelnen sind
								folgende Zugriffe für die genannten Zwecke erforderlich:
								<ul>
									<li>
										in den Geräteeinstellungen („INTERNET“): Erlaubnis des Zugriffs auf das Internet
										zur Datenabfrage und Datenübermittlung.
									</li>
									<li>
										in den Geräteeinstellungen („ACCESS_NETWORK_STATE“): Erlaubnis zu prüfen, ob
										eine Internetverbindung besteht.
									</li>
									<li>
										in den Geräteeinstellungen („E-MAIL“): Erlaubnis, aus der App auf die
										E-MailApplikation des verwendeten mobilen Endgeräts zuzugreifen.
									</li>
									<li>
										in den Geräteeinstellungen („TELEFON“): Erlaubnis, aus der App auf die
										Anruffunktion des verwendeten mobilen Endgeräts zuzugreifen.
									</li>
									<li>
										in den Geräteeinstellungen („BENACHRICHTUNGEN“): Freigabe von PushMitteilungen,
										um den Nutzer direkte Benachrichtigungen zukommen zulassen, etwa über die
										Änderungen der Nutzungsbedingungen.
									</li>
								</ul>
							</p>
						</section>
						<section>
							<h2>3. Übertragung von Daten </h2>

							<p>
								<ol>
									<li>
										<p>Auftragsverarbeitung </p>
										<p>
											Zur Verarbeitung der Daten setzt ZF ausschließlich sorgfältig ausgewählte
											und regelmäßiger Kontrolle unterliegende Dienstleister als weisungsabhängige
											Auftragsverarbeiter ein. Dies ist insbesondere die Decadia GmbH,
											Kokereiallee 9-11, 45141 Essen für den technischen Betrieb und Support der
											App, die ihrerseits wiederum Unterauftragsverarbeiter einsetzt, welche die
											Daten auf in Europa befindlicher Infrastruktur speichern (insbesondere
											Amazon Web Services EMEA SARL).{' '}
										</p>
									</li>

									<li>
										<p>Sonstige Übertragung von personenbezogenen Daten </p>
										<p>
											Zur Verarbeitung der Daten setzt ZF ausschließlich sorgfältig ausgewählte
											und regelmäßiger Kontrolle unterliegende Dienstleister als weisungsabhängige
											Auftragsverarbeiter ein. Dies ist insbesondere die Decadia GmbH,
											Kokereiallee 9-11, 45141 Essen für den technischen Betrieb und Support der
											App, die ihrerseits wiederum Unterauftragsverarbeiter einsetzt, welche die
											Daten auf in Europa befindlicher Infrastruktur speichern (insbesondere
											Amazon Web Services EMEA SARL).{' '}
										</p>
									</li>
								</ol>
							</p>
						</section>
						<section>
							<h2>4. Zwecke und Rechtsgrundlagen der Verarbeitung </h2>

							<p>
								Die Zwecke, für die die personenbezogenen Daten verarbeitet werden, und die
								entsprechenden Rechtsgrundlagen haben wir im folgenden Abschnitt für Sie aufgeführt. Die
								Verarbeitung der personenbezogenen Daten durch die App ist auf Basis der folgenden
								gesetzlichen Vorschriften zulässig:
							</p>

							<p>
								<ol>
									<li>
										<p>
											Die von Ihnen im Rahmen der Aktivierung des Benutzerkontos eingegebenen oder
											durch uns protokollierten personenbezogenen Daten (E-Mail-Adresse, Passwort
											etc.) und die weiteren Anmeldeinformationen (Zweiter Faktor) verwenden wir,
											um Ihnen den Zugang zu Ihrem ZF Vorsorge Benutzerkonto zu gewähren, um Sie
											beim Login mithilfe von zwei Faktoren zu authentisieren und Anfragen zur
											Rücksetzung Ihres Passwortes nachzugehen. Die von Ihnen im Rahmen der
											Aktivierung oder einer Anmeldung eingegebenen bzw. protokollierten Daten
											werden von uns verarbeitet und verwendet, (1) um Ihre Berechtigung und
											Verwaltung des Benutzerkontos zu verifizieren; (2) die Nutzungsbedingungen
											der App sowie jede und alle damit verbundenen Rechte und Pflichten
											durchzusetzen und (3) mit Ihnen in Kontakt zu treten, in dem wir Ihnen
											technische Hinweise, Updates, Sicherheitsmeldungen oder etwa Nachrichten,
											die die Verwaltung des Benutzerkontos betreffen, senden können. Diese
											Datenverarbeitungen sind auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO zur
											Durchführung des Vertrags über die Nutzung der App gerechtfertigt. Sofern
											Sie hierin Ihre freiwillige Einwilligung erteilen, können wir Ihre
											Telefonnummer und Ihre E-MailAdresse zudem nutzen, um Sie aktiv zu
											kontaktieren, falls wir Probleme mit Ihrer Nutzung der ZF Vorsorge
											feststellen (z.B. wenn Ihr Antrag unvollständig ist oder Angaben darin
											unplausibel erscheinen). Diese Datenverarbeitungen werden auf Grundlage des
											Art. 6 Abs. 1 lit. a DSGVO gerechtfertigt.
										</p>
									</li>

									<li>
										<p>
											Wenn Sie unseren Kundenservice telefonisch kontaktieren, können wir Sie
											anhand Ihrer Telefonnummer identifizieren. Die Rechtsgrundlage für diese
											Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO. Unser und Ihr
											gleichlaufendes berechtigtes Interesse liegt darin, Ihr Anliegen effizienter
											bearbeiten zu können.
										</p>
									</li>

									<li>
										Die Verarbeitung der personenbezogenen Daten zum Abruf und zur Anzeige der
										Informationen zum Einzelkonto (Stammdaten, Informationen zum Kontostand,
										eingezahlte Beiträge, Beitragsgarantie, Neuigkeiten bezüglich des Depot-Werts
										seit dem letzten Öffnen der App, Informationen zum Kursverlauf,
										Fondszusammensetzung) erfolgt, um Ihnen die App und die damit verbundenen
										Funktionen zur Verfügung zu stellen. Diese Datenverarbeitungen beruhen ebenfalls
										auf Art. 6 Abs. 1 lit. b DSGVO, da sie zur Durchführung des
										Nutzungsverhältnisses hinsichtlich der App erforderlich sind.
									</li>

									<li>
										Die Speicherung und Verarbeitung Ihrer Entgeltdaten, Ihres Alters und Ihres
										möglichen Renteneintrittsalters erfolgt, um Ihnen die Möglichkeit einzuräumen,
										die Wahl Ihrer Entgeltumwandlung und Ihrer Auszahlungsoptionen digital bei uns
										zu hinterlegen. Nach erfolgreicher Eingabe werden diese Daten in der
										Entgeltabrechnung bei uns verarbeitet und berücksichtigt. Auch diese
										Datenverarbeitungen beruhen auf Art. 6 Abs. 1 lit. b DSGVO, da sie zur
										Durchführung des Nutzungsverhältnisses hinsichtlich der Appbasierten
										Vorsorgelösung erforderlich sind.
									</li>

									<li>
										Die Protokolldaten verwenden wir, (1) um Ihnen die App und die damit verbundenen
										Funktionen zur Verfügung zu stellen; (2) die Funktionen und Leistungsmerkmale
										der App zu verbessern und (3) Missbrauch sowie Fehlfunktionen (inklusive
										Fehlerbehebung) vorzubeugen und zu beseitigen. Diese Datenverarbeitungen beruhen
										auf gesetzlichen Erlaubnisvorschriften, die uns eine Verarbeitung
										personenbezogener Daten gestatten, weil (1) sie für die Erfüllung des Vertrags
										zur Nutzung der App zwischen Ihnen als betroffene Person und ZF erforderlich ist
										(Art. 6 Abs. 1 lit. b DSGVO) und weil (2) ZF ein berechtigtes Interesse daran
										hat, die Funktionsfähigkeit und den fehlerfreien Betrieb der App zu
										gewährleisten, welches die entgegenstehenden Rechten und Interessen der Nutzer
										am Schutz ihrer personenbezogenen Daten überwiegt (Art. 6 Abs. 1 lit. f DSGVO).{' '}
									</li>

									<li>
										Die Auswertung und Verarbeitung von Daten bei Systemabstürzen oder Fehlern bei
										der Nutzung der App, soweit es sich bei den Informationen um personenbezogene
										Daten handelt, beruht auf gesetzlichen Erlaubnisvorschriften, die uns eine
										Verarbeitung personenbezogener Daten gestatten, weil (1) sie für die Erfüllung
										des Vertrags zur Nutzung der App zwischen Ihnen als betroffene Person und ZF
										erforderlich ist (Art. 6 Abs. 1 lit. b DSGVO) und weil (2) ZF ein berechtigtes
										Interesse daran hat, die Funktionsfähigkeit und den fehlerfreien Betrieb der App
										zu gewährleisten, welches die entgegenstehenden Rechten und Interessen der
										Nutzer am Schutz ihrer personenbezogenen Daten überwiegt (Art. 6 Abs. 1 lit. f
										DSGVO).
									</li>
								</ol>
							</p>
						</section>
						<section>
							<h2>5. Zeitraum der Datenspeicherung</h2>
							<p>
								Im Allgemeinen löschen oder anonymisieren wir Ihre personenbezogenen Daten, sobald sie
								für die Zwecke, für die wir sie nach den vorstehenden Ziffern erhoben oder verwendet
								haben, nicht mehr erforderlich sind
							</p>

							<p>Protokolldaten (s.o.) löschen wir spätestens einen Monat nach ihrer Erhebung. </p>

							<p>
								Ihre Stammdaten (und ggf. die Stammdaten von Ihnen als Erben eingesetzter Personen),
								Ihre vollständige Kontohistorie (angewiesene Wandlungen, Kursentwicklungen etc.)
								einschließlich unserer damit verbundenen Kommunikation mit Ihnen speichern wir wie
								folgt:{' '}
							</p>

							<p>
								Für den Fall einer dauerhaften Betriebsrente löschen wir Ihre Daten (und ggf. diejenigen
								Ihrer Erben) 30 Jahre nach dem Tod des letzten Hinterbliebenen, ZF bekannten
								Anspruchsinhabers (also entweder Sie selbst oder Ihre Erben). Im Falle von durch Sie
								angewiesenen Zahlungen anstelle einer Rente löschen wir diese Daten 30 Jahre nach
								Auszahlung der letzten Rate.
							</p>
						</section>
						<section>
							{' '}
							<h2>6. Ihre Rechte</h2>
							<p>
								<ol>
									<li>
										<p>Auskunftsrecht</p>
										<p>
											Sie haben das Recht, von ZF jederzeit auf Antrag eine Auskunft über die von
											ZF verarbeiteten, Sie betreffenden personenbezogenen Daten im Umfang des
											Art. 15 DSGVO zu erhalten. Hierzu können Sie beispielsweise postalisch einen
											Antrag stellen an: ZF Friedrichshafen AG, Hauptverwaltung / ZF Forum,
											Löwentaler Straße 20, 88046 Friedrichshafen{' '}
										</p>
									</li>

									<li>
										<p>Recht zur Berichtigung unrichtiger Daten </p>
										<p>
											Sie haben das Recht, von ZF die unverzügliche Berichtigung der Sie
											betreffenden personenbezogenen Daten zu verlangen, sofern diese unrichtig
											sein sollten.
										</p>
									</li>

									<li>
										<p>Recht auf Löschung („Recht auf Vergessenwerden“) </p>
										<p>
											Sie haben das Recht, unter den in Art. 17 DSGVO beschriebenen
											Voraussetzungen von ZF die Löschung der Sie betreffenden personenbezogenen
											Daten zu verlangen. Diese Voraussetzungen sehen insbesondere ein
											Löschungsrecht vor, wenn die personenbezogenen Daten für die Zwecke, für die
											sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig
											sind sowie in Fällen der unrechtmäßigen Verarbeitung, des Vorliegens eines
											Widerspruchs oder des Bestehens einer Löschpflicht nach Unionsrecht oder dem
											Recht der Mitgliedstaaten, dem ZF unterliegt.
										</p>
									</li>

									<li>
										<p>Recht auf Einschränkung der Verarbeitung:</p>
										<p>
											Sie haben das Recht, von ZF die Einschränkung der Verarbeitung nach Maßgabe
											des Art. 18 DSGVO zu verlangen. Dieses Recht besteht insbesondere, wenn die
											Richtigkeit der personenbezogenen Daten zwischen dem Nutzer und ZF
											umstritten ist, für die Dauer, welche die Überprüfung der Richtigkeit
											erfordert sowie im Fall, dass der Nutzer bei einem bestehenden Recht auf
											Löschung anstelle der Löschung eine eingeschränkte Verarbeitung verlangt;
											ferner für den Fall, dass die Daten für die von ZF verfolgten Zwecke nicht
											länger erforderlich sind, der Nutzer sie jedoch zur Geltendmachung, Ausübung
											oder Verteidigung von Rechtsansprüchen benötigt sowie wenn die erfolgreiche
											Ausübung eines Widerspruchs zwischen ZF und dem Nutzer noch umstritten ist.
										</p>
									</li>

									<li>
										<p>Recht auf Datenübertragbarkeit </p>
										<p>
											Sie haben unter den Voraussetzungen des Art. 20 DSGVO das Recht, von ZF die
											sie betreffenden personenbezogenen Daten, die Sie ZF bereitgestellt haben,
											in einem strukturierten, gängigen, maschinenlesbaren Format zu erhalten
											und/oder durch uns an einen anderen Verantwortlichen übermitteln zu lassen.
										</p>
									</li>

									<li
										style={{ border: '1px solid var(--text-color)' }}
										className={'padding padding--small'}
									>
										<p>
											<b>Widerspruchsrecht</b>
										</p>
										<p>
											Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation
											ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener
											Daten, die u.a. aufgrund von Art. 6 Abs. 1 lit. f DSGVO erfolgt, Widerspruch
											einzulegen. ZF wird die Verarbeitung Ihrer personenbezogenen Daten
											einstellen, es sei denn, ZF kann zwingende schutzwürdige Gründe für die
											Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten
											überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder
											Verteidigung von Rechtsansprüchen.
										</p>
									</li>
									<li>
										<p>Beschwerderecht</p>
										<p>
											Sie haben ferner das Recht, sich bei der zuständigen Aufsichtsbehörde zu
											beschweren. Zuständige Aufsichtsbehörde ist:
										</p>
										<p>
											Der Landesbeauftragte für den Datenschutz Baden-Württemberg Postfach 10 29
											32, 70025 Stuttgart{' '}
										</p>
									</li>
								</ol>
							</p>
						</section>
						<section>
							<h2>7. Keine Verpflichtung zur Bereitstellung personenbezogener Daten</h2>
							<p>
								Die Nutzung dieser App ist freiwillig. Sie sind nicht verpflichtet, uns zu diesem Zweck
								personenbezogene Daten zur Verfügung zu stellen. In diesem Fall können Sie die ZF
								Vorsorge App jedoch leider nicht nutzen.{' '}
							</p>
						</section>
						<section>
							<h2>8. Kontakt</h2>
							<p>
								Bei Fragen oder Anmerkungen zu unserem Umgang mit Ihren personenbezogenen Daten wenden
								Sie sich bitte an die ZF Friedrichshafen AG, Hauptverwaltung, Löwentaler Straße 20,
								88046 Friedrichshafen, datenschutz@zf.com
							</p>
							<p>Der Datenschutzbeauftragte von ZF ist unter folgenden Kontaktdaten zu erreichen:</p>

							<p>
								<b>Datenschutzbeauftragter</b>, ZF Friedrichshafen AG, Löwentaler Straße 20, 88046
								Friedrichshafen; <a href="mailto:datenschutz@zf.com">datenschutz@zf.com</a>
							</p>

							<p>
								Bei Fragen oder Anmerkungen zum praktischen Umgang und zur Bedienung der App oder bei
								Supportanfragen wenden Sie sich bitte an die <a href="www.decadia.com">Decadia GmbH</a>:
								0800 4030188 (Montag-Freitag von 9.00 Uhr-14.00 Uhr, außer an gesetzlichen Feiertagen in
								NRW), kostenlos erreichbar aus dem deutschen Festnetz oder{' '}
								<a href="mailto:info@zfvorsorge.de">info@zfvorsorge.de</a>. Die Kontaktdaten der Decadia
								GmbH sind innerhalb der App unter dem Menüpunkt „Kontakt“ abrufbar.{' '}
							</p>
						</section>
					</div>
					<div className="margin--vertical">
						<Button
							type={ButtonType.link}
							label={'Datenschutzerklärung herunterladen'}
							onClick={handleDownload}
							className="margin--vertical"
						/>
					</div>
				</>
			) : (
				<>
					<Button type={ButtonType.link} label={'Download Privacy Policy'} onClick={handleDownload} />
					<h1>Privacy policy ZF Vorsorge App</h1>
					<p>Status: March 29, 2023</p>
					<p>
						This data protection declaration informs you about the processing of personal data within the
						scope of using the ZF Vorsorge App ("<b>App</b>"). The App is offered by ZF Friedrichshafen AG,
						Löwentaler Straße 20, 88046 Friedrichshafen ("<b>ZF</b>", "<b>wir</b>" or "<b>we</b>") as the
						data controller. The app was developed for ZF and is operated for ZF by a service provider bound
						by instructions. The app can be used as a web application from a computer with a web browser and
						also as an app with a mobile device (smartphone or tablet). It serves as an information and
						interaction tool for the implementation of the company pension plan (in particular: VO 2019, "
						<b>ZF Vorsorge</b>").
					</p>
					<p>Personal data means any information relating to an identified or identifiable natural person.</p>
					<p>
						Because protecting your privacy when using the app is important to us, we would like to use the
						following information to inform you about what personal data we collect when you use the app and
						how we handle this data. You can access this privacy policy at any time under the menu item
						"Privacy Policy" within the app.
					</p>

					<section>
						<h2>1. Data processing within the scope of the app </h2>
						<p>
							In connection with the provision and use of the App, we store various personal data from you
							as a user of the App. "<b>Users</b>" of the App are:
						</p>

						<p>
							Employees of ZF Group companies who are or were employed by ZF Friedrichshafen AG at the
							time of use and who are or were entitled to participate in the ZF Vorsorge scheme. In
							addition to ZF Friedrichshafen AG, the ZF Group of Companies as defined above includes
							companies affiliated with ZF Friedrichshafen AG within the meaning of Section 15 of the
							German Stock Corporation Act (AktG) and companies in which ZF Friedrichshafen AG holds an
							interest of at least 50%.
						</p>
						<p>
							In the following, we inform you about which personal data we collect and process when you
							install and use the app:
						</p>

						<ol>
							<li>
								<p> User account activation and login </p>
								<p>
									In order to use the app, initial activation of the user account is required so that
									the user can log in on the mobile device or computer with a web browser. In the
									process, we process the private e-mail address and telephone number you provided
									during activation, the activation data sent to you, the password you selected, and
									the ZF VO identification number. Activation of the user account is not complete
									until you enter a new password. We log successful login attempts, failed login
									attempts and their number, changes to the password (hereinafter collectively
									referred to as "<b>further login information</b>") and store the device
									identification number (so-called device ID).
								</p>
								<p>
									The ongoing login in the app is done with a 2-factor authentication. The second
									factor, a randomly generated six-digit number ("<b>TAN</b>"), is sent to you either
									by SMS or e-mail. As part of the user account activation process, you will receive
									one TAN per communication channel to verify your phone number and e-mail address.
									After entering your personal login data and the second factor, you will be taken to
									the start page of the app.
								</p>
							</li>

							<li>
								{' '}
								<p>Retrieving and displaying information about ZF Vorsorge </p>
								<p>
									As part of the app, certain information is processed to inform you about the
									performance and your individual account balance of your deferred compensation and
									your employer contributions to ZF Vorsorge. This information includes in particular
									the following personal data relating to your individual account:
									<br />
									&nbsp;
									<ul>
										<li>
											Master data: Title, first and last name, additional word, prefix word,
											address, user name (private e-mail address & ZF Vorsorge ID);
										</li>

										<li>
											Conversion data: Conversion amounts, basic employer contributions, matching
											contributions;
										</li>

										<li>
											Account balance information (monthly/annual contributions over history,
											number of contributions to date including contributions in past years);
										</li>

										<li>
											News regarding the portfolio value since the last time the app was opened
											(gain / loss due to price development);
										</li>
										<li>Composition of the capital investment or fund;</li>

										<li>
											Information on the personal ZF pension plan (weekly updated value of pension
											assets, breakdown by own contributions from deferred compensation and
											employer contributions (basic employer contribution and matching
											contribution) and contribution guarantee, history of the deposit, in each
											case changing the time period shown).
										</li>
									</ul>
									<br />
									In addition, the app also provides you with information on the price trend (
									Performance / price trend of the special fund, trend of the comparative index, units
									in the special fund in the securities account, value of a unit in each case with a
									change in the time period shown and the change between different comparative
									indices); on the fund (summary of the fund and further information, if applicable)
									as well as general information on ZF Vorsorge and FAQs.{' '}
								</p>
							</li>

							<li>
								<p>
									Conclusion and amendment of deferred compensation agreements and choice of payout
									options
								</p>

								<p>
									As part of the app, you will be able to apply for your deferred compensation and
									subsequently increase, reduce or terminate it. Likewise, you will be given the
									opportunity to select your personal payout options (20 installments, 10
									installments, lump sum, annuity, mixed payout) in the app. Before entering
									retirement, you will be reminded in the app to choose the payout options.{' '}
								</p>

								<p>
									In order to perform such transaction (conversion of charges or selection of payment
									options), authentication with a second factor is performed each time data is entered
									or changed (see also item 1 "Activation of a user account and login").{' '}
								</p>

								<p>
									For these purposes, data on your remuneration, age and expected retirement age are
									stored in the app. Likewise, your deferred compensation input is further processed
									to include you in the payroll process. Your choice of payout options will be further
									processed after the occurrence of the benefit event in order to take into account
									your individual payout plan. A general works agreement (GBV Versorgungsordnung 2019
									- Digital) has been concluded to regulate the process.{' '}
								</p>
							</li>
							<li>
								<p>Data that are processed automatically </p>

								<p>
									As part of your use of the app, we automatically collect and store certain data.
									Each time a user accesses the app, certain information about this process is stored
									in a log file. The logged information includes: Content of the request, date and
									time of access, the amount of data transferred, the message whether the retrieval
									was successful, description of the type of browser used, operating system used, the
									device identification number and the IP address assigned to your end device
									(hereinafter collectively referred to as "<b>log data</b>").
								</p>
							</li>
						</ol>
					</section>

					<section>
						<h2>2. Permissions to access functions and data on the end device </h2>
						<p>
							In order to use all functions of the app, you must grant the app permissions to access
							functions and data of your mobile device. In detail, the following accesses are required for
							the aforementioned purposes:
							<ul>
								<li>
									in the device settings ("INTERNET"): Permission to access the Internet for data
									retrieval and data transmission.
								</li>
								<li>
									in the device settings ("ACCESS_NETWORK_STATE"): Permission to check if there is an
									internet connection.
								</li>
								<li>
									in the device settings ("E-MAIL"): Permission to access the email application of the
									mobile device used from the app.
								</li>
								<li>
									in the device settings ("PHONE"): Permission to access the call function of the
									mobile device used from the app.
								</li>
								<li>
									in the device settings ("NOTIFICATIONS"): Enable push notifications to send direct
									notifications to the user, for example, about the changes in the terms of use.
								</li>
							</ul>
							We will use the granted permissions exclusively for the aforementioned purposes, even if a
							granted permission (e.g. for technical reasons) theoretically opens up further usage
							options. You can normally cancel a granted authorization in the settings of your device at
							any time (however, this depends on your device and the operating system, over which we have
							no influence).
						</p>
					</section>

					<section>
						<h2>3. Data transmission </h2>

						<p>
							<ol>
								<li>
									<p>Job processing </p>
									<p>
										For the processing of data, ZF exclusively uses carefully selected service
										providers subject to regular monitoring as instruction- dependent processors.
										This is in particular Decadia GmbH, Kokereiallee 9-11, 45141 Essen for the
										technical operation and support of the app, which in turn uses sub-processors
										that store the data on infrastructure located in Europe (in particular Amazon
										Web Services EMEA SARL).{' '}
									</p>
								</li>

								<li>
									<p>Other transfer of personal data </p>
									<p>
										In addition, personal data will be disclosed without the user's express prior
										consent only in cases permitted or required by law. In particular, such
										disclosure may be legally permissible if the processing is necessary for
										compliance with a legal obligation to which ZF is subject.{' '}
									</p>
								</li>
							</ol>
						</p>
					</section>

					<section>
						<h2>4. Purposes and legal bases of processing </h2>

						<p>
							We have listed the purposes for which the personal data is processed and the corresponding
							legal bases for you in the following section. The processing of personal data by the App is
							permitted on the basis of the following legal provisions:
						</p>

						<p>
							<ol>
								<li>
									<p>
										We use the personal data (e-mail address, password, etc.) entered by you or
										logged by us as part of the activation of the user account and the other login
										information (Second Factor) to grant you access to your ZF Vorsorge user
										account, to authenticate you using Two Factors when you log in, and to follow up
										on requests to reset your password. The data you enter or log in as part of
										activation or a login is processed and used by us (1) to verify your
										authorization and administration of the User Account; (2) to enforce the Terms
										of Use of the App and any and all rights and obligations related thereto; and
										(3) to contact you in which we may send you technical notices, updates, security
										notifications, or, for example, messages relating to the administration of the
										User Account. These data processing operations are justified on the basis of
										Art. 6 (1) lit. b DSGVO for the performance of the contract on the use of the
										App. Provided you give your voluntary consent herein, we may also use your
										telephone number and e-mail address to actively contact you if we identify
										problems with your use of ZF Vorsorge (e.g. if your application is incomplete or
										information therein appears implausible). These data processing operations are
										justified on the basis of Art. 6 para. 1 lit. a DSGVO.
									</p>
								</li>

								<li>
									<p>
										If you contact our customer service by phone, we can identify you by your phone
										number. The legal basis for this data processing is Art. 6 para. 1 lit. f DSGVO.
										Our and your concurrent legitimate interest is to be able to process your
										request more efficiently.
									</p>
								</li>

								<li>
									<p>
										The processing of personal data for retrieving and displaying information on the
										individual account (master data, information on the account balance, paid-in
										contributions, premium guarantee, news regarding the securities account value
										since the last time the app was opened, information on the price trend, fund
										composition) is carried out in order to make the app and the associated
										functions available to you. This data processing is also based on Art. 6 para. 1
										lit. b DSGVO, as it is necessary for the implementation of the user relationship
										with regard to the app.
									</p>
								</li>

								<li>
									<p>
										The storage and processing of your remuneration data, your age and your possible
										retirement age is done in order to give you the opportunity to digitally deposit
										your choice of deferred compensation and payout options with us. After
										successful entry, this data is processed and taken into account in our payroll
										accounting. This data processing is also based on Art. 6 Para. 1 lit. b DSGVO,
										as it is necessary for the implementation of the user relationship with regard
										to the app-based pension solution.
									</p>
								</li>

								<li>
									<p>
										We use the log data (1) to provide you with the App and related functions; (2)
										to improve the functions and performance features of the App; and (3) to prevent
										and eliminate misuse and malfunctions (including troubleshooting). These data
										processing operations are based on legal permissions that allow us to process
										personal data because (1) it is necessary for the performance of the contract
										for the use of the App between you as the data subject and ZF (Art. 6 para. 1
										lit. b DSGVO) and because (2) ZF has a legitimate interest in ensuring the
										functionality and error-free operation of the App, which outweighs the
										conflicting rights and interests of users in the protection of their personal
										data (Art. 6 para. 1 lit. f DSGVO).{' '}
									</p>
								</li>

								<li>
									<p>
										The evaluation and processing of data in the event of system crashes or errors
										in the use of the app, insofar as the information is personal data, is based on
										statutory permission requirements that allow us to process personal data because
										(1) it is necessary for the performance of the contract for the use of the app
										between you as the data subject and ZF (Art. 6 (1) lit. b DSGVO) and because (2)
										ZF has a legitimate interest in ensuring the functionality and error-free
										operation of the app, which outweighs the conflicting rights and interests of
										users in the protection of their personal data (Art. 6 (1) lit. f DSGVO).
									</p>
								</li>
							</ol>
						</p>
					</section>

					<section>
						<h2>5. Data storage period</h2>
						<p>
							In general, we will delete or anonymize your personal data as soon as it is no longer
							necessary for the purposes for which we collected or used it in accordance with the
							preceding paragraphs.
						</p>

						<p>We delete log data (see above) no later than one month after it has been collected. </p>

						<p>
							We store your master data (and, if applicable, the master data of persons appointed by you
							as heirs), your complete account history (instructed conversions, price developments, etc.)
							including our related communication with you as follows:{' '}
						</p>

						<p>
							In the case of a permanent occupational pension, we delete your data (and those of your
							heirs, if applicable) 30 years after the death of the last surviving ZF known claimant (i.e.
							either yourself or your heirs). In the case of payments directed by you in lieu of a
							pension, we will delete this data 30 years after the last installment has been paid.
						</p>
					</section>

					<section>
						{' '}
						<h2>6. Your rights</h2>
						<p>
							<ol>
								<li>
									<p>Right to information</p>
									<p>
										You have the right to obtain information from ZF at any time upon request about
										the personal data processed by ZF that concerns you within the scope of Art. 15
										DSGVO. For this purpose, you can, for example, send a request by mail to ZF
										Friedrichshafen AG, Headquarters/ZF Forum, Löwentaler Straße 20, 88046
										Friedrichshafen, Germany.{' '}
									</p>
									<p>
										Alternatively, you can, for example, submit a request by e-mail to:{' '}
										<a href="mailto:datenschutz@zf.com">datenschutz@zf.com</a>
										or <a href="mailto:dataprotection@zf.com">dataprotection@zf.com</a>.
									</p>
								</li>

								<li>
									<p>Right to rectify inaccurate data</p>
									<p>
										You have the right to request that ZF immediately correct any personal data
										concerning you that is inaccurate.
									</p>
								</li>

								<li>
									<p>Right to erasure ("right to be forgotten") </p>
									<p>
										You have the right to request that ZF delete the personal data concerning you
										under the conditions described in Art. 17 DSGVO. These conditions provide in
										particular for a right to erasure if the personal data are no longer necessary
										for the purposes for which they were collected or otherwise processed, as well
										as in cases of unlawful processing, the existence of an objection or the
										existence of an obligation to erase under Union law or the law of the Member
										States to which ZF is subject.
									</p>
								</li>

								<li>
									<p>Right to restriction of processing:</p>
									<p>
										You have the right to request ZF to restrict processing in accordance with Art.
										18 DSGVO. This right exists in particular if the accuracy of the personal data
										is disputed between the user and ZF, for the duration that the verification of
										the accuracy requires, as well as in the event that the user requests limited
										processing instead of erasure in the case of an existing right to erasure;
										furthermore, in the event that the data is no longer necessary for the purposes
										pursued by ZF, but the user requires it for the assertion, exercise or defense
										of legal claims, as well as if the successful exercise of an objection is still
										disputed between ZF and the user.
									</p>
								</li>

								<li>
									<p>Right to data portability</p>
									<p>
										Under the conditions of Art. 20 DSGVO, you have the right to receive from ZF the
										personal data concerning you that you have provided to ZF in a structured,
										common, machine-readable format and/or to have it transferred by us to another
										controller.
									</p>
								</li>

								<li
									style={{ border: '1px solid var(--text-color)' }}
									className={'padding padding--small'}
								>
									<p>
										<b>Right of objection</b>
									</p>
									<p>
										You have the right to object at any time, on grounds relating to your particular
										situation, to the processing of personal data concerning you which is carried
										out, inter alia, on the basis of Article 6(1)(f) DSGVO. ZF will stop processing
										your personal data, unless ZF can demonstrate compelling legitimate grounds for
										the processing which override your interests, rights and freedoms, or the
										processing serves the purpose of asserting, exercising or defending legal
										claims.
									</p>
								</li>
								<li>
									<p>Right of appeal</p>
									<p>
										You also have the right to complain to the competent supervisory authority. The
										competent supervisory authority is:
									</p>
									<p>
										The State Commissioner for Data Protection of Baden-Württemberg Postfach 10 29
										32, 70025 Stuttgart, Germany{' '}
									</p>
								</li>
							</ol>
						</p>
					</section>

					<section>
						<h2>7. No obligation to provide personal data</h2>
						<p>
							The use of this app is voluntary. You are not obliged to provide us with personal data for
							this purpose. In this case, however, you will unfortunately not be able to use the ZF
							Vorsorge app.{' '}
						</p>
					</section>

					<section>
						<h2>8. Contact</h2>
						<p>
							If you have any questions or comments about our handling of your personal data, please
							contact ZF Friedrichshafen AG, Head Office, Löwentaler Straße 20, 88046 Friedrichshafen,
							Germany, <a href="mailto:datenschutz@zf.com">datenschutz@zf.com</a>.
						</p>
						<p>The ZF Data Protection Officer can be reached at the following contact details:</p>

						<p>
							<b>Data Protection Officer</b>, ZF Friedrichshafen AG, Löwentaler Straße 20, 88046
							Friedrichshafen; <a href="mailto:datenschutz@zf.com">datenschutz@zf.com</a>
						</p>

						<p>
							If you have any questions or comments about the practical handling and operation of the app
							or if you have any support requests, please contact{' '}
							<a href="www.decadia.com">Decadia GmbH</a>: 0800 4030188 (Monday-Friday from 9:00 a.m.-1:00
							p.m., except on public holidays in NRW), reachable free of charge from a German landline or
							info@zfvorsorge.de. The contact details of Decadia GmbH are available within the app under
							the menu item "Contact".{' '}
						</p>
					</section>
					<section>
						<h2>9. Changes to this privacy policy</h2>
						<p>
							The services provided by ZF may be changed from time to time, in particular to further
							improve the functionalities of the App. Such changes may also affect the use of your
							personal data. For this reason, ZF reserves the right to change this privacy policy at any
							time. The current version can be accessed within the app under the menu item "Privacy
							Policy". Please inform yourself regularly about the current status of the data privacy
							statement in this way.{' '}
						</p>
					</section>
					<div className="margin--vertical">
						<Button
							type={ButtonType.link}
							label={'Download Privacy Policy'}
							onClick={handleDownload}
							className="margin--vertical"
						/>
					</div>
				</>
			)}
		</div>
	)
}

export default PrivacyPolicy
