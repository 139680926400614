import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import Form, { FormFieldType } from 'shared/components/Form'
import { TextInputType } from 'shared/components/TextInput'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath } from 'state/useHeaderState'
import { components } from 'types/api-interface'

const FormStepTaxes: React.FC<{
	data?: components['schemas']['AuszahlungsoptionenArbeitskopie'] | undefined | void | null
	onSubmit: (obj: components['schemas']['AuszahlungsoptionenArbeitskopie'] | null) => void
	editable?: boolean
}> = (props) => {
	const { t } = useTranslation()
	const { getChildPath } = useRouteHelper()
	useBackButtonPath(`${getChildPath(routesDictionary.payoutOptions, 'payoutModelInputForm')}/2`)

	/** TODO: Improve Form. Tax number needs to be saved in a ref cause number input wont allow pattern / leading zero  */
	const taxNumberRef = useRef<string>()

	const getPermanentResidentVal = () => {
		if (!props?.data?.tax?.staendigerWohnsitzInDeutschland) {
			return 'yes'
		}
		const { staendigerWohnsitzInDeutschland } = props?.data?.tax

		if (staendigerWohnsitzInDeutschland) {
			return 'yes'
		} else {
			return 'no'
		}
	}

	const savedTaxNumber = props?.data?.tax?.steueridentifikationsnummer

	useEffect(() => {
		taxNumberRef.current = savedTaxNumber
	}, [savedTaxNumber])

	return (
		<>
			<h3>{t('view.PayoutInputForm.taxes.headline')}</h3>

			{props.data && (
				<Form
					fields={{
						taxNumber: {
							fieldType: FormFieldType.text,
							label: t('view.PayoutInputForm.taxes.taxidentnumber'),
							value: props.data?.tax?.steueridentifikationsnummer || '',
							required: props.editable,
							minLength: 10,
							maxLength: 11,
							pattern: '[0-9]*',
							type: TextInputType.number,
							onChange: (e) => {
								taxNumberRef.current = (e.target as HTMLTextAreaElement).value
							},
							readOnly: !props.editable,
						},
						permanentResident: {
							fieldType: FormFieldType.select,
							className: 'select-with-background',
							useCustom: true,
							label: t('view.PayoutInputForm.taxes.permanentResident'),
							value: getPermanentResidentVal(),
							alternativeStyle: true,
							options: [
								{ label: t('view.PayoutInputForm.taxes.permanentResidentOptions.0'), value: 'yes' },
								{ label: t('view.PayoutInputForm.taxes.permanentResidentOptions.1'), value: 'no' },
							],
							required: props.editable,
							disabled: !props.editable,
						},
						confession: {
							fieldType: FormFieldType.select,
							className: 'select-with-background',
							useCustom: true,
							label: t('view.PayoutInputForm.taxes.confession'),
							alternativeStyle: true,
							value: props.data.tax?.konfession,
							options: [
								{
									label: t('view.PayoutInputForm.taxes.confessionOptions.0'),
									value: 'el',
								},
								{
									label: t('view.PayoutInputForm.taxes.confessionOptions.1'),
									value: 'ev ',
								},
								{
									label: t('view.PayoutInputForm.taxes.confessionOptions.2'),
									value: 'rk',
								},
								{
									label: t('view.PayoutInputForm.taxes.confessionOptions.3'),
									value: 'weitere',
								},
								{
									label: t('view.PayoutInputForm.taxes.confessionOptions.4'),
									value: 'keine',
								},
							],
							required: props.editable,
							disabled: !props.editable,
						},
						taxClass: {
							fieldType: FormFieldType.select,
							className: 'select-with-background',
							useCustom: true,
							label: t('view.PayoutInputForm.taxes.taxClass'),
							alternativeStyle: true,
							value: props.data.tax?.steuerklasse,
							options: [
								{
									label: t('view.PayoutInputForm.taxes.taxClassOptions.0'),
									value: 'I',
								},
								{
									label: t('view.PayoutInputForm.taxes.taxClassOptions.1'),
									value: 'II',
								},
								{
									label: t('view.PayoutInputForm.taxes.taxClassOptions.2'),
									value: 'III',
								},
								{
									label: t('view.PayoutInputForm.taxes.taxClassOptions.3'),
									value: 'IV',
								},
								{
									label: t('view.PayoutInputForm.taxes.taxClassOptions.4'),
									value: 'V',
								},
								{
									label: t('view.PayoutInputForm.taxes.taxClassOptions.5'),
									value: 'VI',
								},
							],
							required: props.editable,
							disabled: !props.editable,
						},
					}}
					onSubmit={(e: any) => {
						if (!taxNumberRef.current) {
							return
						}
						const submitData = props.editable
							? {
									tax: {
										steueridentifikationsnummer: taxNumberRef.current,
										staendigerWohnsitzInDeutschland: e.permanentResident === 'yes' ? true : false,
										konfession: e.confession,
										steuerklasse: e.taxClass,
									},
							  }
							: null
						props.onSubmit(submitData)
						return { successful: true, status: 200 }
					}}
					children={
						<>
							<div className="subheadline subheadline--taxnumber ">
								{t('view.PayoutInputForm.taxes.headlines.taxNumber')}
							</div>
							<div className="subheadline subheadline--taxclass ">
								{t('view.PayoutInputForm.taxes.headlines.taxClass')}
							</div>
						</>
					}
				></Form>
			)}
		</>
	)
}

export default FormStepTaxes
