import { IconType } from 'components/Icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import Form, { FormField, FormFieldType } from 'shared/components/Form'
import Icon from 'shared/components/Icon'
import useGlobalModalState from 'shared/hooks/useGlobalModalState'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath } from 'state/useHeaderState'
import { components } from 'types/api-interface'
import PayoutDocumentsModal from '../ChapterOne/PayoutDocuments/PayoutDocumentsModal'
import PayoutmodelDocumentsSummary from '../ChapterOne/PayoutDocuments/PayoutDocumentsSummary'

const FormStepSocialInsurance: React.FC<{
	data?: components['schemas']['AuszahlungsoptionenArbeitskopie'] | undefined | void | null
	onSubmit: (obj: components['schemas']['AuszahlungsoptionenArbeitskopie'] | null) => void
	editable?: boolean
}> = (props) => {
	const { t } = useTranslation()
	const [, modalActions] = useGlobalModalState()

	const { getChildPath } = useRouteHelper()
	useBackButtonPath(`${getChildPath(routesDictionary.payoutOptions, 'payoutModelInputForm')}/3`)

	const pensionCofnrimationRequired = () => {
		const type = props.data?.leistungsart?.type || false

		if (!type) {
			return false
		}

		return type === 'VorgezogenerRuhestand' || type === 'Erwerbsminderung'
	}

	const fields: { [key: string]: FormField } = {
		number: {
			fieldType: FormFieldType.text,
			label: t('view.PayoutInputForm.socialInsurance.number'),
			required: true,
			minLength: 12,
			maxLength: 12,
			value: props.data?.sozialversicherung?.rentenversicherungsnummer || '',
			readOnly: !props.editable,
		},
	}

	if (!props.data) {
		return <></>
	}

	return (
		<>
			<h3>{t('view.PayoutInputForm.socialInsurance.headline')}</h3>

			<Form
				submitLabel={`${t('generic.next')}
			`}
				fields={fields}
				onSubmit={(e: any) => {
					const payload: Partial<components['schemas']['AuszahlungsoptionenArbeitskopie']> = {
						sozialversicherung: {
							rentenversicherungsnummer: e.number,
						},
					}
					props.onSubmit(props.editable ? payload : null)
					return { successful: true, status: 200 }
				}}
				children={
					<>
						<div className="subheadline subheadline--social-insurance ">
							{t('view.PayoutInputForm.socialInsurance.subheadline')}
						</div>

						{pensionCofnrimationRequired() && (
							<div className="info-content info-content--social-insurance">
								{props.editable && (
									<div className="info-box">
										<Icon type={IconType.alert} color={'var(--color-cyan)'}></Icon>
										<p className="text-color-blue margin-top margin">
											{t('view.PayoutInputForm.socialInsurance.uploadHint')}
										</p>
									</div>
								)}

								<PayoutmodelDocumentsSummary
									type={'RENTENBESCHEINIGUNG'}
									noFileContent={
										<Button
											type={ButtonType.secondary}
											label={t('view.PayoutInputForm.uploadDocumentButton')}
											onClick={() => {
												modalActions.setContent(
													<PayoutDocumentsModal
														type={'RENTENBESCHEINIGUNG'}
													></PayoutDocumentsModal>
												)
												modalActions.openModal()
											}}
										></Button>
									}
								></PayoutmodelDocumentsSummary>
							</div>
						)}
					</>
				}
			></Form>
		</>
	)
}

export default FormStepSocialInsurance
