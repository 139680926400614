import { IconType } from 'components/Icons'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import Icon from 'shared/components/Icon'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'

const Success = () => {
	const { getMainPath, navigateTo } = useRouteHelper()
	useBackButtonPath()
	useNavigationTitle(<Trans i18nKey="view.deferredCompensation.pageTitle.default" />)

	const { t } = useTranslation()

	return (
		<div className="deferred-compensation deferred-compensation--success flex flex--direction-column flex--align-items-center center-view">
			<h1>
				<Trans i18nKey="view.deferredCompensation.success.headline" />
			</h1>

			<Icon
				type={IconType.animatedCheckmark}
				className="deferred-compensation__success-icon flex--grow margin--vertical no-margin--top"
			/>

			<p>
				<Trans i18nKey="view.deferredCompensation.success.bodytext" />
			</p>

			<Button
				type={ButtonType.primary}
				label={t('view.deferredCompensation.success.buttonLabel')}
				onClick={() => navigateTo(getMainPath(routesDictionary.conversions))}
			/>
		</div>
	)
}

export default Success
