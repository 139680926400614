import { API } from 'aws-amplify'
import { trackPromise } from 'react-promise-tracker'
import { useQuery } from 'react-query'
import { LoadingSpinnerArea } from 'shared/components/LoadingSpinner'
import { ApiErrorCode } from 'shared/enums'
import {
	ApiError,
	ChangeUserContactArguments,
	ChangeUserContactStatus,
	ChangeUserPasswordArguments,
	ChangeUserPasswordResult,
	MfaResponseData,
	MigrateUserArguments,
	MigrateUserStatus,
	PasswordResetArguments,
	RegisterUserArguments,
	RegisterUserStatus,
} from 'shared/interfaces'
import { TPasswordResetResponse } from 'views/Login/V2auth/ForgotPasswordV2'
import useApi from './useApi'


/**
 * API methods for v2 auth
 */

const AuthApi = () => {
	const { apiDataObject, getLocalAWSConfig } = useApi()
	const { data: awsConfig } = useQuery('awsConfig', getLocalAWSConfig)

	const changeConctactDetails = async (
		body: ChangeUserContactArguments
	): Promise<ChangeUserContactStatus | false> => {
		try {
			const data = await trackPromise(apiDataObject({ body }))
			const response = await trackPromise(
				API.post('payoutModelApi', `auth/contactdetails`, data)
					.then((response) => response)
					.catch((err) => err)
			)

			console.log('apiResponse', response)

			return response
		} catch (e: any) {
			return false
		}
	}

	const registerUser = async (body: RegisterUserArguments): Promise<RegisterUserStatus | false> => {
		try {
			const data = await trackPromise(apiDataObject({ body }))
			const response = await trackPromise(
				API.post('payoutModelApi', `auth/registrierung`, data)
					.then((response) => response)
					.catch((err) => err)
			)

			console.log('apiResponse', response)

			return response
		} catch (e: any) {
			return false
		}
	}

	const migrateUser = async (body: MigrateUserArguments): Promise<MigrateUserStatus | false> => {
		try {
			const data = await trackPromise(apiDataObject({ body }))
			const response = await trackPromise(
				API.post('payoutModelApi', `auth/migration`, data)
					.then((response) => response)
					.catch((err) => err)
			)

			return response
		} catch (e: any) {
			return false
		}
	}

	const changeUserPassword = async (body: ChangeUserPasswordArguments): Promise<ChangeUserPasswordResult | false> => {
		try {
			const data = await trackPromise(apiDataObject({ body }))
			const response = await trackPromise(
				API.post('payoutModelApi', `auth/passwordchange`, data)
					.then((response) => response)
					.catch((err) => err)
			)

			return response
		} catch (e: any) {
			return false
		}
	}

	const resetPassword = async (body: PasswordResetArguments): Promise<TPasswordResetResponse | false> => {
		try {
			const apiConfig = awsConfig?.aws_cloud_logic_custom.find((v) => {
				return v.name === 'payoutModelApi'
			})

			const response = await trackPromise(
				fetch(apiConfig?.endpoint + 'auth/resetpassword', {
					method: 'POST',
					headers: {
						'x-api-key': process.env.REACT_APP_APIGW_API_KEY || '',
					},
					body: JSON.stringify(body),
				})
			)
			const data = await response.json()

			console.log('data', data)

			return data
		} catch (e: any) {
			console.log('error', e)
			return false
		}
	}

	const resendTan = async (identNumber: string): Promise<boolean> => {
		try {
			const apiConfig = awsConfig?.aws_cloud_logic_custom.find((v) => {
				return v.name === 'payoutModelApi'
			})

			const response = await trackPromise(
				fetch(apiConfig?.endpoint + 'auth/resend', {
					method: 'POST',
					headers: {
						'x-api-key': process.env.REACT_APP_APIGW_API_KEY || '',
					},
					body: JSON.stringify({ identNumber }),
				})
			)

			if (response.status === 200) {
				return true
			} else {
				return false
			}
		} catch (e: any) {
			console.log('error', e)
			return false
		}
	}

	const requireMfa = async (transactionId: string): Promise<boolean> => {
		try {
			const data = await trackPromise(apiDataObject({ body: { transactionId } }))
			const response = await trackPromise(
				API.put('payoutModelApi', `auth/start`, data)
					.then((response) => response)
					.catch((err) => err)
			)

			return response
		} catch (e: any) {
			return false
		}
	}

	const setMfa = async ({
		transactionId,
		token,
	}: {
		transactionId: MfaResponseData['transactionId']
		token: string
	}): Promise<boolean | ({ successful: boolean } & ApiError)> => {
		const data = await trackPromise(apiDataObject({ body: { code: token, transactionId } }), LoadingSpinnerArea.mfa)

		console.log(data.body)

		const responseData = await trackPromise(
			API.post('payoutModelApi', `auth/validate`, data)
				.then(() => true)
				.catch((err) => {
					return err.response
				}),
			LoadingSpinnerArea.mfa
		)

		console.log(responseData, 'resData')

		if (responseData === true) {
			return responseData
		} else if ([401, 403].includes(responseData.status)) {
			return { successful: false, code: ApiErrorCode.MFA_INVALID, message: 'wrong tan' }
		}

		return { successful: false, code: ApiErrorCode.UNKNOWN_ERROR, message: '' }
	}

	return {
		registerUser,
		resetPassword,
		resendTan,
		requireMfa,
		changeConctactDetails,
		changeUserPassword,
		setMfa,
		migrateUser,
	}
}

export default AuthApi
