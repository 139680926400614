import useApi, { QueryKey } from 'hooks/useApi'
import { useUserGroup } from 'hooks/useUserGroup'
import { FC, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'
import { components } from 'types/api-interface'
import { PayoutModelOptions } from 'views/PayoutModel'

interface IConfirmPayoutModel {
	payoutModel?: PayoutModelOptions | undefined
}

const ConfirmPayoutModel: FC<IConfirmPayoutModel> = ({ payoutModel = undefined }) => {
	const { navigateTo, getChildPath } = useRouteHelper()
	const queryClient = useQueryClient()
	const api = useApi()
	const { t } = useTranslation()
	const { isVO20, isHigherUp } = useUserGroup()
	const { data: isEditable } = useQuery(QueryKey.payoutModelEditable, api.isPayoutModelEditable)

	const [selectedModel, setSelectedModel] = useState<PayoutModelOptions>()
	useBackButtonPath(getChildPath(routesDictionary.payoutOptions, 'payoutModel'))
	useNavigationTitle(<Trans i18nKey="view.payoutOptions.chapterOne.payoutModel.pageTitle" />)

	const payoutModelInProgressQuery = useQuery(QueryKey.payoutModelInProgress, api.getPayoutModelInProgress)

	const isErwerbsminderung = payoutModelInProgressQuery.data?.leistungsart?.type === 'Erwerbsminderung'

	const erwerbsminderungType =
		payoutModelInProgressQuery.data?.leistungsart?.type === 'Erwerbsminderung'
			? payoutModelInProgressQuery.data.leistungsart.erwerbsminderungType
			: undefined

	const isErwerbsminderungBefristet =
		erwerbsminderungType === 'befristetVoll' || erwerbsminderungType === 'befristetTeilweise'
	const isErwerbsminderungUnbefristet =
		erwerbsminderungType === 'unbefristetTeilweise' || erwerbsminderungType === 'unbefristetVoll'

	const mutation = useMutation(api.patchPayoutModel, {
		onSuccess: async (data) => {
			localStorage.removeItem('payout-model')
			await queryClient.setQueryData(QueryKey.payoutModelInProgress, data)
			navigateTo(getChildPath(routesDictionary.payoutOptions, 'payoutSummary'))
		},
	})

	useEffect(() => {
		if (payoutModel) {
			setSelectedModel(payoutModel)
		}
		// eslint-disable-next-line
	}, [])

	const updateData = (
		type:
			| components['schemas']['AuszahlungsmodellRente']['type']
			| components['schemas']['Auszahlungsmodel10JahresRate']['type']
			| components['schemas']['Auszahlungsmodel20JahresRate']['type']
			| components['schemas']['AuszahlungsmodelEinmalzahlung']['type']
			| 'mixed'
	) => {
		if (type === 'mixed') {
			return
		}
		const payload = {
			type,
		}
		if (!isEditable?.editable) {
			navigateTo(getChildPath(routesDictionary.payoutOptions, 'payoutSummary'))
			return
		}
		mutation.mutate({ modell: payload })
	}

	const payoutModelTexts = {
		[PayoutModelOptions.instalments20Years]: {
			headline: <Trans i18nKey="view.payoutOptions.chapterOne.confirmPayoutModel.models.twentyYears.headline" />,
			description: <Trans i18nKey="view.payoutOptions.chapterOne.confirmPayoutModel.models.twentyYears.body" />,
		},
		[PayoutModelOptions.instalments10Years]: {
			headline: <Trans i18nKey="view.payoutOptions.chapterOne.confirmPayoutModel.models.tenYears.headline" />,
			description: <Trans i18nKey="view.payoutOptions.chapterOne.confirmPayoutModel.models.tenYears.body" />,
		},
		[PayoutModelOptions.pension]: {
			headline: <Trans i18nKey="view.payoutOptions.chapterOne.confirmPayoutModel.models.pension.headline" />,
			description: (
				<>
					{!isErwerbsminderung && (
						<Trans
							i18nKey={`view.payoutOptions.chapterOne.confirmPayoutModel.models.pension.body${
								isVO20 ? 'Vo20' : ''
							}`}
						/>
					)}

					{isErwerbsminderungUnbefristet && (
						<Trans i18nKey="view.payoutOptions.chapterOne.payoutModel.options.pension.reducedPensionUnbefristet"></Trans>
					)}
					{isErwerbsminderungBefristet && (
						<Trans i18nKey="view.payoutOptions.chapterOne.payoutModel.options.pension.reducedPensionBefristet"></Trans>
					)}
				</>
			),
		},
		[PayoutModelOptions.mixed]: {
			headline: <Trans i18nKey="view.payoutOptions.chapterOne.confirmPayoutModel.models.mixed.headline" />,
			description: (() => {
				if (isHigherUp) {
					return <Trans i18nKey="view.payoutOptions.chapterOne.confirmPayoutModel.models.mixed.bodyEMGGET" />
				} else if (isVO20) {
					return <Trans i18nKey="view.payoutOptions.chapterOne.confirmPayoutModel.models.mixed.bodyVo20" />
				} else {
					return <Trans i18nKey="view.payoutOptions.chapterOne.confirmPayoutModel.models.mixed.body" />
				}
			})(),
		},
		[PayoutModelOptions.lumpSum]: {
			headline: <Trans i18nKey="view.payoutOptions.chapterOne.confirmPayoutModel.models.lumpSum.headline" />,
			description: <Trans i18nKey="view.payoutOptions.chapterOne.confirmPayoutModel.models.lumpSum.body" />,
		},
	}

	const nextViewClick = () => {
		if (!selectedModel) {
			return
		}
		switch (selectedModel) {
			case PayoutModelOptions.pension:
				updateData('pension')
				break
			case PayoutModelOptions.instalments10Years:
				updateData('instalments10Years')
				break
			case PayoutModelOptions.instalments20Years:
				updateData('instalments20Years')
				break
			case PayoutModelOptions.lumpSum:
				updateData('lumpSum')
				break
			case PayoutModelOptions.mixed:
				navigateTo(getChildPath(routesDictionary.payoutOptions, 'mixedPayoutPreslection'))
				break
		}
	}

	return (
		<div className="center-view grid">
			<div className="grid__top">
				<p className="text-color-blue">
					<Trans i18nKey="view.payoutOptions.chapterOne.confirmPayoutModel.headline" />
				</p>
				<h3 className="no-margin--top">{selectedModel && payoutModelTexts[selectedModel].headline}</h3>
			</div>

			<div className="grid__center">
				<p>{selectedModel && payoutModelTexts[selectedModel].description}</p>
			</div>
			<div className="margin margin-top margin--large">
				<Button
					type={ButtonType.primary}
					label={t('view.payoutOptions.chapterOne.confirmPayoutModel.button')}
					onClick={nextViewClick}
				></Button>
			</div>
		</div>
	)
}

export default ConfirmPayoutModel
