import useApi, { IPayoutInterval, QueryKey } from 'hooks/useApi'
import i18next, { t } from 'i18next'
import React, { useEffect } from 'react'
import { Trans } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'

import { currencyFormat } from 'shared/helper/numberFormats'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { components } from 'types/api-interface'
import { payoutProgressGuard, ProgressKeys } from './ChapterOne/PayoutOverview'

const formatPayoutDate = (year: number, month: number) =>
	new Date(year + '-' + month).toLocaleDateString(i18next.language, {
		year: 'numeric',
		month: 'long',
	})

const getPayoutInterval = (interval: IPayoutInterval, key: string) => {
	const { startMonth = undefined, endMonth = undefined, startYear, endYear = undefined } = interval

	return (
		<>
			{startYear && startMonth && <span className="payout-date">{formatPayoutDate(startYear, startMonth)}</span>}
			{endYear && endMonth && (
				<>
					{' '}
					– <span className="payout-date payout-date--end">{formatPayoutDate(endYear, endMonth)}</span>
				</>
			)}

			{key === 'pension' && (
				<span className="payout-date payout-date--lifelong">
					{' '}
					– <Trans i18nKey={`view.payoutOptions.chapterOne.payoutModelSummary.lifelong`} />{' '}
				</span>
			)}
		</>
	)
}

export const getMixedPayoutDistributions = (
	model: components['schemas']['AuszahlungsoptionenArbeitskopie']['modell'],
	balance: number,
	payoutIntervals?: {
		[key: string]: IPayoutInterval
	}
) => {
	if (model && 'type' in model) {
		if (model?.type !== 'mixed' || !balance) {
			return
		}

		const distributions = model?.distribution as unknown as { [key: string]: string }
		const absolutes =
			model?.absoluteAmounts as components['schemas']['AuszahlungsmodellGemischt']['absoluteAmounts']

		return (
			<div className="payout-summary__mixed-payout">
				<div className="mixed-payout-row">
					<div className="label">
						<Trans i18nKey={`view.payoutOptions.chapterOne.payoutModelSummary.payoutType`} />
					</div>
					<div className="label">
						<Trans i18nKey={`view.payoutOptions.chapterOne.payoutModelSummary.payoutInterval`} />
					</div>
					<div className="label text-align--right">
						<Trans i18nKey={`view.payoutOptions.chapterOne.payoutModelSummary.capitalFor`} />:
					</div>
				</div>
				{Object.keys(distributions)
					.sort()
					.map((key) => {
						if (!distributions[key]) {
							return <></>
						}

						return (
							<div className="mixed-payout-row">
								<div className="label">
									<Trans i18nKey={`view.payoutOptions.chapterOne.payoutModelSummary.${key}`} />:
								</div>

								{payoutIntervals && key in payoutIntervals && (
									<div className="label">{getPayoutInterval(payoutIntervals[key], key)}</div>
								)}

								<div className="value">
									{absolutes?.[key as keyof typeof absolutes]
										? currencyFormat(absolutes[key as keyof typeof absolutes] as number)
										: currencyFormat((Number(distributions[key]) / 100) * balance)}
								</div>
							</div>
						)
					})}
			</div>
		)
	} else {
		return
	}
}

const PayoutModelSummary: React.FC = () => {
	const getInterval = () => {
		if (data?.modell && 'type' in data.modell) {
			if (data?.modell.type === 'instalments10Years') {
				return 10
			} else if (data?.modell.type === 'instalments20Years') {
				return 20
			}
		}

		return 1
	}
	const { getChildPath, navigateTo } = useRouteHelper(routesDictionary.payoutOptions)
	const api = useApi()
	const { data, status } = useQuery(QueryKey.payoutModelInProgress, api.getPayoutModelInProgress)
	const balanceQuery = useQuery(QueryKey.pensionBalance, api.getPensionBalance)
	const typeIsNotErwerbsmidnerung = data?.leistungsart?.type !== 'Erwerbsminderung' || false
	const payoutYearQuery = useQuery([QueryKey.payoutYears, { interval: getInterval() }], api.getPayoutYears, {
		enabled: status === 'success' && typeIsNotErwerbsmidnerung,
	})
	const { data: payoutYearData } = payoutYearQuery
	const { data: isEditable } = useQuery(QueryKey.payoutModelEditable, api.isPayoutModelEditable)
	const isMixed = !!(data?.modell && 'type' in data.modell && data?.modell.type === 'mixed')

	useEffect(() => {
		payoutYearQuery.refetch()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const distributionData: any = (data?.modell as unknown as any)?.distribution

	const { data: mixedPayoutData } = useQuery(
		[
			QueryKey.mixedPayoutYears,
			{
				lumpSum: distributionData?.lumpSum || 0,
				instalments20Years: distributionData?.instalments20Years || 0,
				instalments10Years: distributionData?.instalments10Years || 0,
				pension: distributionData?.pension || 0,
			},
		],
		api.getMixedPayoutYears,
		{ enabled: isMixed }
	)

	const queryClient = useQueryClient()
	const mutation = useMutation(api.patchPayoutModel, {
		onSuccess: (data) => {
			queryClient.setQueryData(QueryKey.payoutModelInProgress, data)
			nextView()
		},
	})

	const nextView = () => navigateTo(getChildPath(routesDictionary.payoutOptions, 'payoutSuccess'))

	const onClick = async () => {
		if (data?.modell && data?.leistungsart) {
			if (payoutProgressGuard(data.progressKey as ProgressKeys, ProgressKeys.CHAPTER_TWO_COMPLETED)) {
				nextView()
				return
			}
			if (!isEditable?.editable) {
				nextView()
				return
			}
			mutation.mutate({ progressKey: ProgressKeys.CHAPTER_ONE_COMPLETED })
		}
	}

	const isBefristeteErwerbsminderung = () => {
		if (data?.leistungsart?.type !== 'Erwerbsminderung') {
			return false
		}

		if (data?.leistungsart?.erwerbsminderungType === 'befristetVoll') {
			return true
		}

		if (data?.leistungsart?.erwerbsminderungType === 'befristetTeilweise') {
			return true
		}

		return false
	}

	const startEndEqual =
		`${payoutYearData?.startYear}${payoutYearData?.startMonth}}` ===
		`${payoutYearData?.endYear}${payoutYearData?.endMonth}}`

	const payoutStart = payoutYearData?.startYear && payoutYearData.startMonth && (
		<>{formatPayoutDate(payoutYearData.startYear, payoutYearData.startMonth)}</>
	)

	const payoutEnd = payoutYearData?.endMonth && payoutYearData.endYear && (
		<>{formatPayoutDate(payoutYearData.endYear, payoutYearData.endMonth)}</>
	)

	return (
		<>
			{data?.modell && 'type' in data.modell && (
				<div className="grid center-view payout-summary">
					<div className="grid__top"></div>
					<div className="grid__center">
						<p className="text-color-cyan">
							<Trans i18nKey={`view.payoutOptions.chapterOne.payoutModelSummary.header`} />
						</p>
						<h3 className="no-margin--top">
							<Trans i18nKey={`view.payoutOptions.chapterOne.payoutModelSummary.${data?.modell?.type}`} />
						</h3>

						{typeIsNotErwerbsmidnerung && (
							<p className="text-color-cyan">
								<Trans i18nKey={`view.payoutOptions.chapterOne.payoutModelSummary.periodTitle`} />
							</p>
						)}

						{/** if modell is not mixed payout */}
						{payoutYearQuery.status === 'success' && !isMixed && typeIsNotErwerbsmidnerung && (
							<>
								{payoutYearData ? (
									<h3 className="no-margin--top">
										{payoutStart}
										{!startEndEqual && <> - {payoutEnd}</>}
										{payoutYearData.lifelong && (
											<>
												<Trans
													i18nKey={`view.payoutOptions.chapterOne.payoutModelSummary.lifelong`}
												/>
											</>
										)}
									</h3>
								) : (
									<>
										<h3 className="no-margin--top">-</h3>
									</>
								)}
							</>
						)}
						{data?.modell?.type === 'mixed' &&
							balanceQuery.data?.totalAmount &&
							mixedPayoutData &&
							getMixedPayoutDistributions(data.modell, balanceQuery.data.totalAmount, mixedPayoutData)}
						{isEditable?.editable && (
							<p>
								{!isBefristeteErwerbsminderung() ? (
									<Trans i18nKey={`view.payoutOptions.chapterOne.payoutModelSummary.text`} />
								) : (
									<Trans i18nKey={`view.payoutOptions.chapterOne.payoutModelSummary.textShortened`} />
								)}
							</p>
						)}

						<hr className="hr hr--narrow margin--top margin--bottom " />

						<p>
							<Trans i18nKey={`view.payoutOptions.chapterOne.payoutModelSummary.text2`} />
						</p>
					</div>

					<div className="grid__bottom">
						<Button
							type={ButtonType.primary}
							label={
								isEditable?.editable
									? t('view.payoutOptions.chapterOne.payoutModelSummary.button')
									: t('generic.next')
							}
							onClick={onClick}
							disabled={mutation.isLoading}
						></Button>
					</div>
				</div>
			)}
		</>
	)
}

export default PayoutModelSummary
