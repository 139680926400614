import useApi, { QueryKey } from 'hooks/useApi'
import { t } from 'i18next'
import { FC, useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { components } from 'types/api-interface'
import { getMixedPayoutDistributions } from '../PayoutSummary'

const Summary: FC<{
	data: components['schemas']['AuszahlungsoptionenArbeitskopie']
}> = (props) => {
	const api = useApi()
	const balanceQuery = useQuery(QueryKey.pensionBalance, api.getPensionBalance)
	const { navigateTo, getChildPath } = useRouteHelper()

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { data: pensionDateData, status: pensionDateStatus } = useQuery(
		[
			QueryKey.pensionDate,
			{
				leistungsart: props?.data?.leistungsart?.type as
					| 'Ruhestand'
					| 'VorgezogenerRuhestand'
					| 'Erwerbsminderung',
			},
		],
		api.getPensionDate,
		{
			enabled: !!props?.data?.leistungsart?.type,
		}
	)

	/** In cases when the leavingDate is already provided, e.g. not set by the user, the leavingDate will be displayed as  the next day  */
	const austrittsdatum = useMemo(() => {
		if (!props.data.leistungsart || !pensionDateData?.austrittsdatum) {
			return '-'
		}

		const {
			data: { leistungsart },
		} = props

		if (
			(leistungsart.type !== 'VorgezogenerRuhestand' && leistungsart.type !== 'Ruhestand') ||
			leistungsart.austrittsdatum === undefined
		) {
			return '-'
		}
		const leavingDate = pensionDateData.austrittsdatum.substring(0, 10)
		const dateNotEdited = leavingDate === leistungsart.austrittsdatum

		if (dateNotEdited) {
			const date = new Date(leavingDate)
			// next day
			date.setDate(date.getDate() + 1)
			return date.toLocaleDateString('de-DE')
		} else {
			return new Date(leistungsart.austrittsdatum).toLocaleDateString('de-DE')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.data, pensionDateData])

	if (!props.data.leistungsart || !props.data.modell || !('type' in props.data.modell)) {
		return <></>
	}

	const i18nLeistungsArtBasePath = 'view.payoutOptions.payoutConfirmation.chapterOne'

	const type = props?.data?.leistungsart.type
	const model = props?.data?.modell

	return (
		<div className="chapter-one-summary">
			{type === 'VorgezogenerRuhestand' && (
				<>
					<p className="text-color-cyan">
						<Trans i18nKey={`${i18nLeistungsArtBasePath}.benefitType`}></Trans>
						<br />
						<b>
							<Trans i18nKey={`${i18nLeistungsArtBasePath}.VorgezogenerRuhestand.name`}></Trans>
						</b>
					</p>

					<p>
						{' '}
						(<Trans i18nKey={`view.payoutOptions.chapterOne.benefitType.options.1.subLabel`}></Trans>)
					</p>

					<p className="margin--top">
						{' '}
						<Trans i18nKey={`${i18nLeistungsArtBasePath}.Ruhestand.leavingDate`}></Trans>
						<br />
						<b>{austrittsdatum}</b>
					</p>
				</>
			)}

			{type === 'Ruhestand' && (
				<>
					<p className="text-color-cyan">
						<Trans i18nKey={`${i18nLeistungsArtBasePath}.benefitType`}></Trans>
						<br />
						<b>
							<Trans i18nKey={`${i18nLeistungsArtBasePath}.Ruhestand.name`}></Trans>
						</b>
					</p>

					<p>
						{' '}
						<Trans i18nKey={`view.payoutOptions.chapterOne.benefitType.options.1.subLabel`}></Trans>
					</p>

					<p className="margin--top">
						{' '}
						<Trans i18nKey={`${i18nLeistungsArtBasePath}.Ruhestand.leavingDate`}></Trans>
						<br />
						<b>{austrittsdatum}</b>
					</p>
				</>
			)}

			{type === 'Ruhestand' && (
				<>
					<p className="text-color-cyan">
						<Trans i18nKey={`${i18nLeistungsArtBasePath}.benefitType`}></Trans>
						<br />
						<b>
							<Trans i18nKey={`${i18nLeistungsArtBasePath}.Ruhestand.name`}></Trans>
						</b>
					</p>

					<p>
						<Trans i18nKey={`view.payoutOptions.chapterOne.benefitType.options.1.subLabel`}></Trans>
					</p>

					<p className="margin--top">
						<Trans i18nKey={`${i18nLeistungsArtBasePath}.Ruhestand.leavingDate`}></Trans>
						<br />
						<b>{austrittsdatum}</b>
					</p>
				</>
			)}

			{type === 'Erwerbsminderung' && (
				<>
					<p className="text-color-cyan">
						<Trans i18nKey={`${i18nLeistungsArtBasePath}.benefitType`}></Trans>
						<br />
						<b>
							<Trans i18nKey={`${i18nLeistungsArtBasePath}.Erwerbsminderung.name`}></Trans>
						</b>
					</p>

					<p className="margin--top">
						{' '}
						<b>
							<Trans
								i18nKey={`view.payoutOptions.chapterOne.payoutReduction.options.${props.data.leistungsart.erwerbsminderungType}.headline`}
							></Trans>
						</b>
						<br />(
						<Trans
							i18nKey={`view.payoutOptions.chapterOne.payoutReduction.options.${props.data.leistungsart.erwerbsminderungType}.subHeadline`}
						></Trans>
						)
					</p>

					<p className="margin--top">
						<Trans i18nKey="view.payoutOptions.chapterOne.payoutEmployeeContribution.intro"></Trans>
						<br />{' '}
						<b>
							{props.data.leistungsart.payoutEmployeeContribution === true && (
								<Trans
									i18nKey={`view.payoutOptions.chapterOne.payoutEmployeeContribution.options.yes`}
								></Trans>
							)}
							{props.data.leistungsart.payoutEmployeeContribution === false && (
								<Trans
									i18nKey={`view.payoutOptions.chapterOne.payoutEmployeeContribution.options.no`}
								></Trans>
							)}
						</b>
					</p>
				</>
			)}

			<Button
				className="payout-for-summary__button"
				type={[ButtonType.secondary, ButtonType.small]}
				label={t('generic.changeInputs')}
				onClick={() => {
					navigateTo(getChildPath(routesDictionary.payoutOptions, 'benefitType'))
				}}
			></Button>
			{
				<>
					<p className="text-color-cyan">
						<Trans i18nKey={`${i18nLeistungsArtBasePath}.model`}></Trans>
						<br />
						<b>
							<Trans i18nKey={`view.payoutOptions.chapterOne.payoutModelSummary.${model?.type}`} />
						</b>
					</p>
					{model?.type === 'mixed' &&
						balanceQuery.data?.totalAmount &&
						getMixedPayoutDistributions(model, balanceQuery.data.totalAmount)}
				</>
			}
			<Button
				className="payout-for-summary__button"
				type={[ButtonType.secondary, ButtonType.small]}
				label={t('generic.changeInputs')}
				onClick={() => {
					navigateTo(`${getChildPath(routesDictionary.payoutOptions, 'payoutModel')}`)
				}}
			></Button>
		</div>
	)
}

export const PayoutConfirmationChapterOne = {
	header: (
		<h3>
			<Trans i18nKey={'view.payoutOptions.payoutTypes.options.first.headline'}></Trans>
		</h3>
	),
	content: Summary,
}
