import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import { useLatest } from 'react-use'
import { FormField, FormFieldType } from 'shared/components/Form'
import { RadiobuttonGroupProps } from 'shared/components/Radiobutton'
import { isDateString } from 'shared/helper/isDateString'
import parseDate from 'shared/helper/parseDate'
import { CheckboxProps } from '../components/Checkbox'
import { TextInputProps, TextInputType } from '../components/TextInput'

export enum FormErrorType {
	none = 'none',
	fieldInvalid = 'field-invalid',
	errorResponse = 'error-response',
	unchanged = 'unchanged',
	allFieldsEmpty = 'all-fields-empty',
}

export interface FormInput {
	value: string | number | string[]
	required?: boolean
	type?: string
}

export interface FormSubmitFields {
	[key: string]: string | number | undefined
}

type TFieldErrorType = 'error' | 'hint'

export type TFieldError<T extends TFieldErrorType> = {
	type: T
	message: string
}

function isRadioButtonGroup(field?: any): field is RadiobuttonGroupProps & {
	fieldType: FormFieldType.radioButtons
} {
	return field?.fieldType === FormFieldType.radioButtons
}

function isCheckbox(field?: any): field is CheckboxProps & {
	fieldType: FormFieldType.checkBox
} {
	return field?.fieldType === FormFieldType.checkBox
}

export const useForm = (
	initialValues: { [key: string]: TextInputProps },
	onSubmit: (submittedFields: FormSubmitFields) => any,
	onError: () => any,
	onlySubmitOnChangedValues: boolean = false,
	hideSubmitIfFieldsEmpty: boolean = false
) => {
	const { t } = useTranslation()
	const [inputs, setInputs] = useState(initialValues)
	const initialInputValues = useRef(initialValues)
	const currentInput = useRef<HTMLInputElement | null>(null)
	const [formError, setFormError] = useState<FormErrorType | undefined>()
	const formResetted = useRef<boolean>(false)
	const latestInputs = useLatest(inputs)
	const isSubmitEvent = useRef<boolean>(false)

	const validateCurrentInputField = (field: TextInputProps): { type: string; message?: string } | undefined => {
		if (null === currentInput.current) {
			return undefined
		}

		let fieldIsValid = false
		let error: TFieldError<'error' | 'hint'> = {
			type: 'error',
			message:
				field.ref.current.dataset.errorMessage ||
				field.errorMessage ||
				t(`generic.formErrors.${field.type || (field as FormField).fieldType}`),
		}

		if (isSubmitEvent.current === true && currentInput.current.checkValidity) {
			fieldIsValid = currentInput.current.checkValidity()
		} else {
			if (!fieldIsValid) {
				fieldIsValid = !field.required || !!currentInput.current.value || !!field.ref.current.value
			}
		}

		if ([FormFieldType.radioButtons].includes((field as FormField).fieldType)) {
			if (fieldIsValid && currentInput.current.required && !currentInput.current.value) {
				fieldIsValid = false
			}
		}

		if ([FormFieldType.select].includes((field as FormField).fieldType)) {
			if (fieldIsValid && field.required && !currentInput.current.value && !field.ref.current.value) {
				fieldIsValid = false
			}
		}

		if (isSubmitEvent.current === true && (field as FormField).fieldType === FormFieldType.phoneInput) {
			if (fieldIsValid && field.required && isPossiblePhoneNumber(currentInput.current.value) === false) {
				fieldIsValid = false
			}
		}

		if (false === fieldIsValid && undefined !== field.validityHints) {
			for (const [type, hint] of Object.entries(field.validityHints)) {
				/**
				 * typescript does not allow accessing validity keys via dynamic variable.
				 * therefore the ts-ignore comment is required
				 */
				// @ts-ignore
				if (true === currentInput.current.validity[type]) {
					error = { ...error, ...hint }
				}
			}
		}

		if ((field.required && !fieldIsValid) || !fieldIsValid || 'hint' === error.type) {
			return error
		}

		if (fieldIsValid && undefined !== field.error) {
			return undefined
		}
	}

	useEffect(() => {
		const validateForm = (): void => {
			if (FormErrorType.errorResponse === formError) {
				return
			}

			const firstInvalidField = Object.values(inputs).find((input) => {
				const inputRef = input?.ref?.current

				if (undefined !== input.error && !inputRef.disabled && 'hidden' !== inputRef.type) {
					return true
				}

				if (isRadioButtonGroup(input) && input.required) {
					return !inputRef.value
				}

				if (inputRef?.required && isCheckbox(input)) {
					return !inputRef.checked
				}

				if (
					inputRef.required &&
					!inputRef.disabled &&
					(input as FormField).fieldType === FormFieldType.phoneInput
				) {
					return isPossiblePhoneNumber(inputRef.value) !== true
				}

				if (inputRef.required && !inputRef.disabled && !inputRef.value) {
					return true
				}

				if (!inputRef.disabled) {
					return inputRef.checkValidity() !== true
				}

				return false
			})

			const unchanged = Object.keys(inputs).every((key) => {
				return inputs[key].value === initialInputValues.current[key].value
			})

			const allInputsEmpty = Object.keys(inputs).every((key) => '' === String(inputs[key].value).trim())

			let updatedFormError = FormErrorType.none

			if (firstInvalidField) {
				// scroll to first invalid input field, but only if a submit event was triggered
				if (true === isSubmitEvent.current) {
					isSubmitEvent.current = false
					const scrollElement = firstInvalidField.ref?.current?.parentElement

					scrollElement?.scrollIntoView({
						behavior: 'smooth',
						block: 'center',
					})
				}

				updatedFormError = FormErrorType.fieldInvalid
			}

			if (onlySubmitOnChangedValues && unchanged) {
				updatedFormError = FormErrorType.unchanged
			}

			if (hideSubmitIfFieldsEmpty && allInputsEmpty) {
				updatedFormError = FormErrorType.allFieldsEmpty
			}

			setFormError(updatedFormError)
		}

		validateForm()
		/**
		 * FIXME:
		 * initialValues cannot be included here as it breaks the form validation by
		 * resetting the formError
		 *
		 * to reproduce:
		 * add initialValues to dependency array
		 * enter wrong pin in tan-service app on login
		 */
		// eslint-disable-next-line
	}, [inputs, onlySubmitOnChangedValues, formError])

	const handleSubmit = (event?: React.MouseEvent) => {
		if (event) {
			event.preventDefault()
		}

		if (undefined !== formError && false === [FormErrorType.none, FormErrorType.unchanged].includes(formError)) {
			// set isSubmitEvent to true, to allow scrolling to first invalid input field in validateForm function
			isSubmitEvent.current = true

			let validatedFields: { [key: string]: TextInputProps } | undefined = undefined
			Object.entries(inputs).forEach(([name, input]) => {
				if (!input.ref) {
					return
				}

				const event = {
					target: input.ref.current,
				} as unknown as ChangeEvent<HTMLInputElement>

				validatedFields = handleInputChange(
					event,
					validatedFields,
					FormFieldType.date === (input as FormField).fieldType ? name : undefined
				)

				// currentInput.current = input.ref.current
				// validateCurrentInputField(input)
			})

			return onError()
		}

		const submittedFields = Object.keys(inputs).reduce((fields: FormSubmitFields, fieldKey: string) => {
			const currentField = inputs[fieldKey]
			let initialValue: string | number | undefined
			let value: string | number | undefined

			if (true === currentField.ref?.current?.disabled) {
				return fields
			}

			if (isCheckbox(currentField)) {
				const { checked: currentChecked } = currentField.ref.current
				const { value: checkboxValue, checked: initialChecked } = initialInputValues.current[
					fieldKey
				] as CheckboxProps

				initialValue = initialChecked ? checkboxValue : ''
				value = currentChecked ? checkboxValue : ''
			} else {
				switch (currentField.type) {
					case TextInputType.date:
						initialValue =
							undefined === initialInputValues.current[fieldKey].value
								? undefined
								: parseDate(initialInputValues.current[fieldKey].value)
						value = undefined === currentField.value ? undefined : parseDate(currentField.value)
						break

					case TextInputType.number:
						initialValue =
							undefined !== initialInputValues.current[fieldKey].value
								? Number(initialInputValues.current[fieldKey].value)
								: undefined
						/**
						 * return undefined if value is '' or undefined to prevent returning NaN or a false-positive 0
						 */
						value =
							'' === currentField.value || undefined === currentField.value
								? undefined
								: Number(currentField.value)
						break

					default:
						initialValue =
							undefined === initialInputValues.current[fieldKey].value
								? undefined
								: String(initialInputValues.current[fieldKey].value).trim()
						value = undefined === currentField.value ? undefined : String(currentField.value).trim()
				}
			}

			if (true === onlySubmitOnChangedValues && initialValue === value) {
				return fields
			}

			fields[fieldKey] = value

			return fields
		}, {})

		if (0 === Object.keys(submittedFields).length) {
			return
		}

		onSubmit(submittedFields)
	}

	const handleFormError = (error: FormErrorType) => {
		setFormError(error)
	}

	const handleInputChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		previouslyUpdatedInputs?: { [key: string]: TextInputProps },
		// this is required for validation DateFields
		originalInputFieldName?: string
	) => {
		if (event.persist) {
			event.persist()
		}

		let updatedInputs = { ...(previouslyUpdatedInputs || latestInputs.current) }

		if (event.target instanceof HTMLInputElement) {
			currentInput.current = event.target
		} else {
			const inputProps = updatedInputs[originalInputFieldName || (event.target as HTMLInputElement).name]
			currentInput.current = inputProps.ref.current

			let { value } = event.target as HTMLInputElement

			if (currentInput.current) {
				switch (currentInput.current?.type) {
					case 'date': {
						if (isDateString(value)) {
							const dateValue = new Date(value)
							value = `${dateValue.getFullYear()}-${String(dateValue.getMonth() + 1).padStart(
								2,
								'0'
							)}-${String(dateValue.getDate()).padStart(2, '0')}`
						}

						currentInput.current.value = value

						break
					}

					case 'tel': {
						/**
						 *  only update input value for phone input if changeFromOutside is set. otherwise the cursor is set to
						 * the end of the input field.
						 *
						 * the phone-input library sets the original field value it on its own, when the onChange event is triggered.
						 */
						if ((inputProps as FormField).fieldType === FormFieldType.phoneInput) {
							if (event.type === 'changeFromOutside') {
								currentInput.current.value = value
							}
						} else {
							currentInput.current.value = value
						}

						break
					}

					default:
						currentInput.current.value = value

						break
				}
			}
		}

		let updatedData: any

		if ('checkbox' === event.target.type) {
			const { checked } = event.target

			updatedData = {
				// checked: checked ? true : false,
				value: checked ? event.target.value : '',
			}
		}

		if ('radio' === event.target.type) {
			// if ( event.target.type ===  )
			const { checked } = event.target

			// updatedValue = checked ? value : ''
			// const options = (updatedInputs[originalInputFieldName || event.target.name] as any).options.map(
			// 	(option: RadiobuttonProps) => {
			// 		return {
			// 			...option,
			// 			checked: checked && option.value === event.target.value,
			// 		}
			// 	}
			// )

			updatedData = {
				// options,
				value: checked ? event.target.value : '',
			}
		}

		if (!['checkbox', 'radio'].includes(event.target.type)) {
			const { value } = event.target

			updatedData = {
				value,
			}
		}

		updatedInputs[originalInputFieldName || event.target.name] = {
			...updatedInputs[originalInputFieldName || event.target.name],
			...updatedData,
			error:
				event.type === 'changeFromOutside'
					? undefined
					: validateCurrentInputField(updatedInputs[originalInputFieldName || event.target.name]),
		}

		setInputs(updatedInputs)

		setFormError(FormErrorType.none)

		return updatedInputs
	}

	const handleFormReset = () => {
		formResetted.current = true
		setInputs(initialValues)
		setFormError(undefined)
	}

	return {
		handleFormError,
		handleSubmit,
		handleInputChange,
		handleFormReset,
		inputs,
		formError,
	}
}
