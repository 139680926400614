import { IconType } from 'components/Icons'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import Icon from 'shared/components/Icon'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'

const Contact = (props: any, ref: any) => {
	const { getMainPath } = useRouteHelper()
	useBackButtonPath(getMainPath(routesDictionary.dashboard))
	useNavigationTitle(<Trans i18nKey="view.contact.pageTitle" />)

	const { t } = useTranslation()

	return (
		<div className="contact center-view ">
			<div className="contact__header flex flex--direction-column flex--align-items-center">
				<Icon className="contact__icon margin--vertical margin--large no-margin--top" type={IconType.call} />
				<p>
					<Trans i18nKey="view.contact.copyIntro" />
				</p>
			</div>

			<div className="contact__phone ">
				<p>
					<b>
						<Trans i18nKey="view.contact.call.headline" />
					</b>
					<br />
					<b className="font-family-alternative font-size-m">
						<Trans i18nKey="generic.helpHotline.tel" />
					</b>
					<br />
				</p>

				<Button type={ButtonType.primary} href={t('generic.helpHotline.telLink')}>
					<Trans i18nKey="view.contact.call.button.label" />
				</Button>
			</div>

			<div className="contact__email">
				<p>
					<b>
						<Trans
							i18nKey="view.contact.email.headline"
							values={{ emailAddress: t('generic.helpHotline.mail') }}
						>
							<a
								className="font-size-m font-family-alternative--medium"
								href={t('generic.helpHotline.mailLink')}
							>
								{' '}
							</a>
						</Trans>
					</b>
				</p>

				<Button href={t('generic.helpHotline.mailLink')} type={ButtonType.primary}>
					<Trans i18nKey="view.contact.email.button.label" />
				</Button>
			</div>

			<div className="contact__footer">
				<p>
					<Trans i18nKey="view.contact.call.copy" />
					<br />
					<Trans i18nKey="view.contact.email.copy" />
				</p>
				<p>
					<Trans i18nKey="view.contact.mail.copy" />
				</p>
				<p>
					<Trans i18nKey="view.contact.mail.address" />
				</p>
			</div>
		</div>
	)
}

export default Contact
