import useApi, { QueryKey } from 'hooks/useApi'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'
import routesDictionary from 'routes'
import AccordionGroup from 'shared/components/AccordionGroup'
import Button, { ButtonType } from 'shared/components/Button'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'
import { PayoutConfirmationChapterOne } from './PayoutConfirmation/PayoutConfirmationChapterOne'
import { PayoutConfirmationChapterThree } from './PayoutConfirmation/PayoutConfirmationChapterThree'
import { PayoutConfirmationChapterTwo } from './PayoutConfirmation/PayoutConfirmationChapterTwo'

const PayoutConfirmation = () => {
	const { navigateTo, getChildPath } = useRouteHelper()
	useBackButtonPath(getChildPath(routesDictionary.payoutOptions, 'payoutOverview'))

	useNavigationTitle('Zusammenfassung')
	const api = useApi()

	const { data } = useQuery(QueryKey.payoutModelInProgress, api.getPayoutModelInProgress)

	const onClick = () => {
		navigateTo(`${getChildPath(routesDictionary.payoutOptions, 'payoutFinalStep')}`)
	}

	if (!data) {
		return <></>
	}

	return (
		<div className="grid center-view payout-confirmation">
			<div className="grid__top"></div>

			<div className="grid__center">
				<p className="margin--bottom">
					<Trans i18nKey="view.payoutOptions.payoutConfirmation.bodyText" />
				</p>
				<AccordionGroup
					options={[
						{
							header: PayoutConfirmationChapterOne.header,
							content: PayoutConfirmationChapterOne.content({ data: data }),
						},
						{
							header: PayoutConfirmationChapterTwo.header,
							content: PayoutConfirmationChapterTwo.content({ data: data }),
						},
						{
							header: PayoutConfirmationChapterThree.header,
							content: PayoutConfirmationChapterThree.content({ data: data }),
						},
					]}
				></AccordionGroup>
				<div className="footer moved-by-accordion ">
					<p>
						<Trans i18nKey="view.payoutOptions.payoutConfirmation.footerText" />
					</p>
					<Button type={ButtonType.primary} onClick={onClick}>
						<Trans i18nKey="view.payoutOptions.payoutConfirmation.button" />
					</Button>
				</div>
			</div>
		</div>
	)
}

export default PayoutConfirmation
