import { IconType } from 'components/Icons'
import { useQueryParams } from 'raviger'
import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import Icon, { IconSize } from 'shared/components/Icon'
import isTrue from 'shared/helper/isTrue'
import useGlobalFormState from 'shared/hooks/useGlobalFormState'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useResetHeaderState } from 'state/useHeaderState'
import { useAuth } from '../hooks/useAuth'
import Button, { ButtonType } from '../shared/components/Button'

const Logout = () => {
	useResetHeaderState()
	const [queryParams] = useQueryParams()

	const { t } = useTranslation()
	const auth = useAuth()
	const [, formActions] = useGlobalFormState()
	const { getMainPath, navigateTo } = useRouteHelper()

	useEffect(() => {
		formActions.resetState()
		auth.signout()
		// eslint-disable-next-line
	}, [])

	return (
		<div className="logout center-view flex flex--direction-column flex--align-items-center">
			<h1 className="page-title">
				{isTrue(queryParams.autoLogout) ? (
					<Trans i18nKey="view.logout.pageTitle.autoLogout" />
				) : (
					<Trans i18nKey="view.logout.pageTitle.default" />
				)}
			</h1>
			{isTrue(queryParams.autoLogout) && (
				<>
					<Icon
						className="logout__icon margin--vertical margin--large no-margin--top"
						type={IconType.logout}
						color="var(--color-cyan)"
						size={IconSize.extraLarge}
					/>
					<p>
						<Trans i18nKey="view.logout.bodytext.autoLogout" />
					</p>
				</>
			)}
			<Button
				type={ButtonType.primary}
				label={
					isTrue(queryParams.autoLogout)
						? t('view.logout.buttonLabel.autoLogout')
						: t('view.logout.buttonLabel.default')
				}
				onClick={() => navigateTo(getMainPath(routesDictionary.login))}
			/>
		</div>
	)
}

export default Logout
