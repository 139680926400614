import { IconType } from 'components/Icons'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import Icon from 'shared/components/Icon'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'

const RBSuccess: React.FC<{ state: 'default' | 'fastlane' }> = ({ state }) => {
	const { getMainPath, navigateTo } = useRouteHelper()

	useBackButtonPath()
	useNavigationTitle(<Trans i18nKey="generic.done" />)

	const { t } = useTranslation()

	return (
		<div className="rb-success deferred-compensation deferred-compensation--success flex flex--direction-column flex--align-items-center center-view">
			<h1>
				<Trans i18nKey={`view.riskBenefit.success.${state}`} />
			</h1>

			<Icon
				type={IconType.animatedCheckmark}
				className="deferred-compensation__success-icon flex--grow margin--vertical no-margin--top"
			/>

			<Button
				type={ButtonType.primary}
				label={t('view.riskBenefit.buttonLabel.goToDashboard')}
				onClick={() => navigateTo(getMainPath(routesDictionary.dashboard))}
			/>
		</div>
	)
}

export default RBSuccess
