import useApi, { QueryKey } from 'hooks/useApi'
import { t } from 'i18next'
import React from 'react'
import { Trans } from 'react-i18next'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import LoadingSpinner from 'shared/components/LoadingSpinner'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath } from 'state/useHeaderState'
import { payoutProgressGuard, ProgressKeys } from '../ChapterOne/PayoutOverview'
import FormSummary from './FormSummary'

const FormStepHealthInsurance: React.FC = (props) => {
	const { navigateTo, getChildPath } = useRouteHelper(routesDictionary.payoutOptions)
	const api = useApi()
	useBackButtonPath(`${getChildPath(routesDictionary.payoutOptions, 'payoutModelInputForm')}/5`)
	const { data } = useQuery(QueryKey.payoutModelInProgress, api.getPayoutModelInProgress)

	const queryClient = useQueryClient()

	const nextView = () => {
		navigateTo(getChildPath(routesDictionary.payoutOptions, 'payoutInputSuccess'))
	}

	const mutation = useMutation(api.patchPayoutModel, {
		onSuccess: (data) => {
			queryClient.setQueryData(QueryKey.payoutModelInProgress, data)
			nextView()
		},
	})

	const onNextClick = async () => {
		if (data?.modell && data?.leistungsart) {
			if (payoutProgressGuard(data.progressKey as ProgressKeys, ProgressKeys.CHAPTER_TWO_COMPLETED)) {
				nextView()
				return
			}

			await mutation.mutate({ progressKey: ProgressKeys.CHAPTER_TWO_COMPLETED })
		}
	}

	const onClick = (step: number) => {
		navigateTo(`${getChildPath(routesDictionary.payoutOptions, 'payoutModelInputForm')}/${step}`)
	}

	return (
		<div className="payout-form-summary">
			<h3>
				<Trans i18nKey="view.PayoutInputForm.summary.headline"></Trans>
			</h3>

			{data && <FormSummary data={data} onClick={onClick}></FormSummary>}

			<Button
				type={ButtonType.primary}
				label={
					mutation.isLoading ? <LoadingSpinner></LoadingSpinner> : t('view.PayoutInputForm.summary.button')
				}
				onClick={onNextClick}
				disabled={mutation.isLoading}
			></Button>
		</div>
	)
}

export default FormStepHealthInsurance
