import AuthApi from 'hooks/useAuthApi'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { trackPromise } from 'react-promise-tracker'
import { useQuery } from 'react-query'
import Button, { ButtonType } from 'shared/components/Button'
import { ButtonWithCooldown } from 'shared/components/ButtonWithCooldown'
import Form, { FormFields, FormFieldType } from 'shared/components/Form'
import LoadingSpinner, { LoadingSpinnerArea } from 'shared/components/LoadingSpinner'
import Modal, { ModalRefActions } from 'shared/components/Modal'
import { ApiError } from 'shared/interfaces'
import useApi, { QueryKey } from '../hooks/useApi'
import { ChallengeType, useAuth } from '../hooks/useAuth'
import { TextInputType } from '../shared/components/TextInput'

const AuthenticationModal = () => {
	const { t } = useTranslation()
	const transactionId = useRef<string>()
	const auth = useAuth()
	const api = useApi()
	const authApi = AuthApi()
	const challenge = auth.challenge
	const modal = useRef<ModalRefActions>()
	const [tan, setTan] = useState<string>()
	const [, setInputValues] = useState<{ [key: string]: any }>()
	const [errorMessages, setErrorMessages] = useState<string[]>()
	const aborted = useRef<boolean>(false)
	const { data, refetch } = useQuery(QueryKey.cognitoUserAttributes, api.getUserAttributes)
	const phoneNumber = data?.get('phone_number') || ''
	const obscuredPhoneNumber = useMemo(() => {
		if (!phoneNumber) {
			return
		}

		return '******' + phoneNumber?.slice(-4)
	}, [phoneNumber])

	useEffect(() => {
		refetch()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		transactionId.current = challenge?.transactionId
	}, [challenge?.transactionId])

	const inputElements: FormFields = {
		token: {
			value: tan,
			required: true,
			type: TextInputType.tel,
			onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
				setTan(e.target.value as string)
			},
			label: (
				<>
					{t('view.authentication.tan')}{' '}
					{tan && (
						<Button
							className="no-margin--left no-margin--right"
							type={[ButtonType.secondary, ButtonType.small]}
							label={t('view.authentication.pasteTan')}
							onClick={() => {
								setInputValues({
									token: String(tan),
								})
							}}
						/>
					)}
				</>
			),
			errorMessage: t('generic.formErrors.token'),
			fieldType: FormFieldType.text,
		},
	}

	useEffect(() => {
		if (undefined === challenge) {
			return
		}

		/**
		 * no open customChallenge and the last challenge was MFA.
		 * modal can be closed and navigation can continue
		 */
		if (
			ChallengeType.mfa !== challenge.customChallengeName &&
			ChallengeType.mfa === challenge.previousChallengeName
		) {
			modal.current?.closeModal()
		}

		if (ChallengeType.mfa === challenge.customChallengeName) {
			modal.current?.openModal()
		}
		// eslint-disable-next-line
	}, [
		challenge,
		// nextPath
	])

	const resendCode = () => {
		trackPromise(authApi.requireMfa(challenge?.transactionId || ''), 'sendTan')
	}

	const modalJSX = useMemo(
		() => (
			<Modal
				hideConfirm={true}
				header={<LoadingSpinner area={LoadingSpinnerArea.mfa} />}
				ref={modal}
				onCloseClick={() => {
					aborted.current = true
				}}
			>
				<div className="authentication-modal">
					<div className="authentication-modal__title">
						<h1>
							<Trans i18nKey="view.authentication.pageTitle" />
						</h1>
						<p>
							<Trans i18nKey="view.authentication.bodytext" />
						</p>
						<div className="margin--vertical">
							<p className="no-margin">
								<Trans i18nKey={'view.authentication.obscuredPhoneNumber'}></Trans>
							</p>
							<h2 className="no-margin">{obscuredPhoneNumber}</h2>
						</div>{' '}
						<ButtonWithCooldown type={[ButtonType.secondary, ButtonType.small]} onClick={resendCode}>
							<Trans i18nKey={'view.migration.enterCode.sendAgainButton'}></Trans>
						</ButtonWithCooldown>
					</div>
					<Form
						updatedValues={{ token: tan }}
						className="authentication-modal"
						fields={inputElements}
						onSubmit={(submittedFields: { token: string }) => {
							console.log(submittedFields)
							return authApi.setMfa({
								transactionId: challenge?.transactionId || '',
								token: submittedFields.token,
							})
						}}
						onSuccess={() => {
							auth.confirmedSecondFactor()
							const id = challenge?.transactionId

							if (true === aborted.current) {
								auth.removeMfaAction(id)
							} else {
								auth.runMfaAction(challenge?.transactionId)
							}

							setTan('')
							auth.resetChallenge()
						}}
						onError={(errorResponse: ApiError) => {
							console.log('errorResponse', errorResponse)
							setErrorMessages([t(`apiErrors.${errorResponse.code}`, { error: errorResponse.code })])
						}}
						errorMessages={errorMessages}
						submitLabel={t('generic.confirm')}
						promiseTracker={{ area: 'sendTan' }}
					></Form>
				</div>
			</Modal>
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[challenge?.transactionId]
	)

	return <>{modalJSX}</>
}

export default AuthenticationModal
