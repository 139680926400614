import classnames from 'classnames'
import { IconType } from 'components/Icons'
import { DefaultTFuncReturn } from 'i18next'
import { FunctionComponent, HTMLAttributes, PropsWithChildren, ReactElement } from 'react'
import useGlobalModalState from 'shared/hooks/useGlobalModalState'
import Icon from './Icon'

export interface TooltipProps extends HTMLAttributes<HTMLButtonElement> {
	label?: string | ReactElement | DefaultTFuncReturn
	header?: string | ReactElement | DefaultTFuncReturn
	headline?: string | ReactElement | DefaultTFuncReturn
	content?: string | ReactElement | DefaultTFuncReturn
	closeButtonLabel?: string | ReactElement | DefaultTFuncReturn
	icon?: IconType
	color?: string
	modalClassName?: string
}

const Tooltip: FunctionComponent<PropsWithChildren<TooltipProps>> = (props) => {
	const [, modalActions] = useGlobalModalState()

	return (
		<button
			className={classnames('tooltip', props.className)}
			onClick={(e) => {
				e.stopPropagation()

				modalActions.setHeader(props.headline)
				modalActions.setContent(props.content)
				modalActions.setButtonLabel(props.closeButtonLabel)
				modalActions.setClass(classnames('modal--tooltip', props.modalClassName))
				modalActions.openModal()
			}}
			type="button"
		>
			{props.label && <span className="tooltip__label">{props.label}</span>}
			<Icon
				type={props.icon ? props.icon : IconType.help}
				color={props.color ? props.color : 'var(--tooltip-icon-color)'}
			/>
		</button>
	)
}

export default Tooltip
