import useApi, { QueryKey } from 'hooks/useApi'
import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import CompensationDataTable from 'shared/components/CompensationDataTable'
import CompensationShares from 'shared/components/CompensationShares'
import { CompensationDisclaimer, CompensationStatus, CompensationType, CompensationUnit } from 'shared/enums'
import dateFormat from 'shared/helper/dateFormat'
import isEmptyObject from 'shared/helper/isEmptyObject'
import { currencyFormat, percentFormat } from 'shared/helper/numberFormats'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'

const SingleMonth: React.FC<{ year: number; month: number }> = ({ year, month }) => {
	useBackButtonPath()
	useNavigationTitle(
		<>
			<Trans i18nKey="view.conversions.conversion" />
			<br />
			<span className="font-family-light">
				{dateFormat(new Date(Date.UTC(0, month - 1)), { month: 'long' })} {year}
			</span>
		</>
	)

	const api = useApi()
	const { t } = useTranslation()
	const { data } = useQuery([QueryKey.compensationMonth, { year, month }], api.getSingleCompensation)
	const thisYear = new Date().getFullYear()
	const thisMonth = new Date().getMonth()
	const compensationLiesInThePast =
		new Date(thisYear, thisMonth, 1).getTime() > new Date(year, month - 1, 1).getTime()
	const compensationIsNotPerformed =
		(data && CompensationStatus.open === data.schemes.status && compensationLiesInThePast) ||
		(data &&
			CompensationStatus.confirmed === data.schemes.status &&
			CompensationDisclaimer.none === data.schemes.disclaimer)
	const compensationIsNotConfirmed =
		data && CompensationStatus.open === data.schemes.status && !compensationLiesInThePast

	const renderHeadline = useMemo(() => {
		if (undefined === data) {
			return null
		}

		const headline = compensationIsNotPerformed
			? t('view.conversions.singleMonth.headline.notperformed')
			: compensationIsNotConfirmed
			? t('view.conversions.singleMonth.headline.unconfirmed')
			: t('view.conversions.singleMonth.headline.confirmed')

		return <h3>{headline}:</h3>

		// eslint-disable-next-line
	}, [data])

	return (
		<div className="deferred-compensation flex flex--direction-column center-view">
			{!!data && (
				<>
					<div className="margin--vertical">
						{renderHeadline}

						{data.schemes.value > 0 && (
							<p className="text-color-cyan">
								<b>
									<Trans
										i18nKey={`view.conversions.singleMonth.${
											CompensationStatus.open === data.schemes.status
												? 'unconfirmed'
												: 'confirmed'
										}CompensationAmount`}
									/>
									:{' '}
									{CompensationUnit.percent === data.schemes.unit
										? percentFormat(data.schemes.value)
										: currencyFormat(data.schemes.value)}
								</b>
							</p>
						)}

						{data.bonus &&
							data.bonus
								.filter((bonus) => {
									// TODO: currently the api always returns a single empty object if no bonus is available
									if (isEmptyObject(bonus)) {
										return false
									}

									if (CompensationType.avwl === bonus.type) {
										return false
									}

									return true
								})
								.filter((bonus) => bonus.value || bonus.confirmedValue)
								.map((bonus, index: number) => {
									return (
										<React.Fragment key={`bonus-${index}`}>
											<p className="text-color-cyan">
												<b>
													<Trans
														i18nKey={`view.conversions.singleMonth.${
															CompensationStatus.open === data.schemes.status
																? 'unconfirmed'
																: 'confirmed'
														}SingleCompensationAmount`}
														values={{
															compensationType: t(
																`compensationType.${
																	(CompensationType as any)[bonus.type || 'bonus']
																}.title`
															),
														}}
													/>
													:{' '}
													{CompensationUnit.percent === bonus.unit
														? percentFormat(bonus.value || bonus.confirmedValue)
														: currencyFormat(bonus.value || bonus.confirmedValue)}
												</b>
											</p>
										</React.Fragment>
									)
								})}

						<p className="margin--vertical">
							<Trans
								i18nKey="view.conversions.singleMonth.salary"
								values={{ salary: currencyFormat(data.salary, { variableFractionDigits: true }) }}
							/>
						</p>
					</div>

					{CompensationStatus.confirmed === data.schemes.status && (
						<h3 className="margin--vertical">
							<Trans i18nKey="view.conversions.singleMonth.headline.confirmedCompensation" />:
						</h3>
					)}

					{(data.schemes.ownContribution !== 0 || data.schemes.employerBaseAmount !== 0 || data.schemes.matchingAmount !== 0) && (
						<CompensationShares
							ownContribution={data.schemes.ownContribution}
							employerContribution={data.schemes.employerContribution}
							sum={data.schemes.pensionTrust}
						/>
					)}

					<CompensationDataTable
						ownContribution={data.schemes.ownContribution}
						monthlyCompensation={data.schemes.monthlyCompensation}
						bonus={data.bonus}
						employerBaseAmount={data.schemes.employerBaseAmount}
						postGrantEmployerBaseAmount={data.schemes.postGrantEmployerBaseAmount}
						matchingAmount={data.schemes.matchingAmount}
						postGrantMatchingAmount={data.schemes.postGrantMatchingAmount}
						sum={data.schemes.pensionTrust}
						status={data.schemes.status}
						disclaimer={data.schemes.disclaimer}
						unit={data.schemes.unit || data.schemes.bonusUnit}
						liesInThePast={compensationLiesInThePast}
					/>
				</>
			)}
		</div>
	)
}

export default SingleMonth
