import { IconType } from 'components/Icons'
import React, { FunctionComponent } from 'react'
import Badge from 'shared/components/Badge'
import Icon from 'shared/components/Icon'
import { DocumentsData } from 'shared/interfaces'

export interface DocumentsDashboardProps {
	data: DocumentsData | null
}

const DocumentsDashboard: FunctionComponent<DocumentsDashboardProps> = ({ data }) => {
	return (
		<>
			<Badge count={data?.unreadCount} />
			<Icon type={IconType.actionDocuments} />
		</>
	)
}

export default DocumentsDashboard
