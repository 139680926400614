import React from 'react'
import { Trans } from 'react-i18next'

const SendUserIdLoginBodytext: React.FC = () => {
	return (
		<div className="margin--vertical">
			<p className="text-color-cyan text--bold-spaced text-align--center">
				<Trans i18nKey="view.login.sendUserId.loginHint.headline" />
			</p>
			<p>
				<Trans i18nKey="view.login.sendUserId.loginHint.bodytext" />
			</p>
			<p className="text-color-cyan">
				<Trans i18nKey="view.login.sendUserId.loginHint.hinttext" />
			</p>
		</div>
	)
}

export default SendUserIdLoginBodytext
