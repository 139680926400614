import useApi, { QueryKey } from 'hooks/useApi'
import React, { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import Form, { FormFieldType } from 'shared/components/Form'
import useGlobalModalState from 'shared/hooks/useGlobalModalState'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath } from 'state/useHeaderState'
import { components } from 'types/api-interface'
import PayoutDocumentsModal from '../ChapterOne/PayoutDocuments/PayoutDocumentsModal'
import PayoutmodelDocumentsSummary from '../ChapterOne/PayoutDocuments/PayoutDocumentsSummary'

enum IbanValidation {
	VALID = 'valid',
	INVALID = 'invalid',
	NOTFOUND = '',
	INPROGRESS = 'inProgress',
	PENDING = 'pending',
}

const FormStepBankAccount: React.FC<{
	data?: components['schemas']['AuszahlungsoptionenArbeitskopie'] | undefined | void | null
	onSubmit: (obj: components['schemas']['AuszahlungsoptionenArbeitskopie'] | null) => void
	editable?: boolean
}> = (props) => {
	const { t } = useTranslation()
	const { getChildPath } = useRouteHelper()

	useBackButtonPath(`${getChildPath(routesDictionary.payoutOptions, 'payoutModelInputForm')}/1`)
	const [validation, setValidation] = useState<IbanValidation | null>()
	const [inputDirty, setInputDirty] = useState(false)

	const [, modalActions] = useGlobalModalState()

	const [iban, setIban] = useState<string>(props.data?.bankingAccount?.IBAN || '')
	const api = useApi()

	const { data, refetch, isLoading } = useQuery([QueryKey.bankName, { iban: iban }], api.getBankName, {
		enabled: iban.length > 0,
	})

	let timeout: any = false

	//debuffer term before sending
	const debufferInput = (iban: string) => {
		if (timeout !== false) {
			clearTimeout(timeout)
		}

		timeout = setTimeout(
			(iban: string) => {
				const strippedIban = stripIban(iban)
				setIban(strippedIban || '')
			},
			1500,
			iban
		)
	}

	const bankRequestAllowed = useMemo(() => inputDirty && iban.length > 0, [inputDirty, iban])

	// use Saved Iban and BankName
	useEffect(() => {
		if (!props.data?.bankingAccount) {
			return
		}

		const { IBAN, bankname } = props.data.bankingAccount

		setIban(IBAN)
		setValidation(bankname === 'unbekannt' ? IbanValidation.NOTFOUND : IbanValidation.VALID)
	}, [props.data])

	// Get BankName From API
	useEffect(() => {
		bankRequestAllowed && refetch()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [iban])

	// set validation state
	useEffect(() => {
		if (!data) {
			return
		}

		if (data.isValid && data.belongsToSepa) {
			setValidation(IbanValidation.VALID)
			return
		}

		if (data.isValid && !data.belongsToSepa) {
			setValidation(IbanValidation.NOTFOUND)
			return
		}

		if (!data.isValid && !data.belongsToSepa) {
			setValidation(IbanValidation.INVALID)
		}
	}, [data])

	const stripIban = (iban: string) => {
		if (/\s/g.test(iban)) {
			const strippedIban = iban.replace(/\s/g, '')
			return strippedIban
		} else {
			return iban
		}
	}

	const getSavedBankName = () => props.data?.bankingAccount?.bankname || ''

	const getBankName = () => {
		if (iban === '') {
			return ''
		}

		switch (validation) {
			case IbanValidation.VALID:
				return bankRequestAllowed ? data?.bankname || '' : getSavedBankName()
			case IbanValidation.NOTFOUND:
				return 'unbekannt'
			default:
				return ''
		}
	}

	const getErrorMessage = () => {
		switch (validation) {
			case IbanValidation.INVALID:
				return <Trans i18nKey="view.PayoutInputForm.bankAccount.notValid" />
			case IbanValidation.NOTFOUND:
				return ''
		}
	}

	const allowContinue = (): boolean => {
		switch (validation) {
			case IbanValidation.NOTFOUND:
			case IbanValidation.VALID:
				return true
			default:
				return false
		}
	}

	return (
		<>
			<h3>{t('view.PayoutInputForm.bankAccount.headline')}</h3>
			{props.data && (
				<Form
					disabled={!allowContinue()}
					fields={{
						bankingAccount: {
							className: `${validation === IbanValidation.INVALID ? 'invalid' : ''}`,
							fieldType: FormFieldType.text,
							value: props.data?.bankingAccount?.IBAN || '',
							label: t('view.PayoutInputForm.bankAccount.accountNumber'),
							required: true,
							readOnly: !props.editable,
							onChange: (e: any) => {
								setInputDirty(true)
								debufferInput(e.target.value)
							},
						},
					}}
					onSubmit={(e: any) => {
						const submitData = props.editable
							? {
									bankingAccount: {
										IBAN: e.bankingAccount,
										bankname: getBankName(),
									},
							  }
							: null
						props.onSubmit(submitData)
						// TODO: this is only a  hack since the form expects an api request return obj
						return { successful: true, status: 200 }
					}}
					children={
						<>
							{iban !== '' && validation === IbanValidation.INVALID && (
								<div className="invalid-input-error text-color-red">
									<Trans i18nKey="view.PayoutInputForm.bankAccount.notValid" />
								</div>
							)}
							<div className="subheadline subheadline--bank-account ">
								<Trans i18nKey="view.PayoutInputForm.bankAccount.subheadline" />
							</div>
							<div className="bank-name margin--top margin--small">
								<p>
									<Trans i18nKey="view.PayoutInputForm.bankAccount.autoText" />
								</p>
								<h4 className="no-margin">
									{isLoading ? (
										<Trans i18nKey="view.PayoutInputForm.bankAccount.isLoading" />
									) : (
										<>
											{getBankName()} {iban && getErrorMessage()}
										</>
									)}
								</h4>
							</div>

							<p className="margin--top">
								<b>
									{' '}
									<Trans i18nKey="view.PayoutInputForm.bankAccount.documentTitle" />
								</b>
								<br />
								<Trans i18nKey="view.PayoutInputForm.bankAccount.documentInfo" />
							</p>
							<PayoutmodelDocumentsSummary
								type={'BANKVERBINDUNG'}
								noFileContent={
									<Button
										type={ButtonType.secondary}
										label={t('view.PayoutInputForm.uploadDocumentButton')}
										onClick={() => {
											modalActions.setContent(
												<PayoutDocumentsModal type={'BANKVERBINDUNG'}></PayoutDocumentsModal>
											)
											modalActions.openModal()
										}}
									></Button>
								}
							></PayoutmodelDocumentsSummary>
						</>
					}
				></Form>
			)}
		</>
	)
}

export default FormStepBankAccount
