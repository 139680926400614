import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Button, { ButtonType } from 'shared/components/Button'
import Icon from 'shared/components/Icon'
import Modal, { ModalPosition, ModalRefActions } from 'shared/components/Modal'
import { useAddToHomescreenPrompt } from 'shared/hooks/useAddToHomescreenPrompt'
import iPadInstallVideo from '../assets/videos/install-ipad.mp4'
import iPhoneInstallVideo from '../assets/videos/install-iphone.mp4'
import { IconType } from './Icons'

const InstallPromptModal: FunctionComponent = () => {
	const { t } = useTranslation()
	const modal = useRef<ModalRefActions>()
	const [promptEvent, promptToInstall, client, showPrompt] = useAddToHomescreenPrompt()
	const [isVisible, setVisibleState] = useState(false)
	const [isLoading, setIsLoading] = useState<boolean>()
	const [videoLoaded, setVideoLoaded] = useState<boolean>(false)
	const modalPosition = useRef<ModalPosition>()
	const modalHeaderTranslation = useRef<string>()

	useEffect(() => {
		if ((client.isIOS && !client.isInstalled) || promptEvent) {
			setTimeout(() => {
				setVisibleState(true)
			}, 500)
		}
		// eslint-disable-next-line
	}, [promptEvent, client])

	useEffect(() => {
		switch (isLoading) {
			case true:
				setTimeout(() => {
					modal.current?.openModal()
				}, 500)
				break

			case false:
				setTimeout(() => {
					setVideoLoaded(true)
				}, 500)
				break
		}
		// eslint-disable-next-line
	}, [isLoading])

	if (false === showPrompt || false === isVisible) {
		return null
	}

	const renderIOSPrompt = () => {
		return (
			<>
				<div className={`modal__video ${videoLoaded ? 'modal__video--visible' : ''}`}>
					<video
						autoPlay={true}
						muted={true}
						loop={true}
						playsInline={true}
						preload="auto"
						src={client.isIPad ? iPadInstallVideo : iPhoneInstallVideo}
						onLoadStart={() => setIsLoading(true)}
						onLoadedData={() => setIsLoading(false)}
					/>
				</div>

				<div className="install-prompt-modal__howto">
					<Trans i18nKey="component.installPromptModal.bodytext.safari">
						<Icon type={IconType.share} color="var(--color-cyan)" />
						<b className="font-family-alternative text-color-cyan" />
						<Icon type={IconType.add} color="var(--color-cyan)" />
					</Trans>

					<Icon type={IconType.appIcon} />
				</div>
			</>
		)
	}

	const renderSwitchToSafariPrompt = () => {
		if (true !== isLoading) {
			setIsLoading(true)
		}

		return (
			<div className="install-prompt-modal__howto">
				<Trans i18nKey="component.installPromptModal.bodytext.notSafariOnIOS" />
				<Icon type={IconType.appIcon} />
			</div>
		)
	}

	const renderInstallPrompt = () => {
		if (true !== isLoading) {
			setIsLoading(true)
		}

		return (
			<div className="flex flex--direction-column flex--align-items-center">
				<Icon type={IconType.appIconWithName} />

				<Button
					className="padding--horizontal padding--extra-large"
					type={ButtonType.primary}
					onClick={() => {
						modal.current?.closeModal()
						promptToInstall()
					}}
					label={t('component.installPromptModal.buttonLabel.install')}
				/>
			</div>
		)
	}

	const renderPrompt = () => {
		switch (true) {
			case client.isIOS && client.isSafariMobile && !client.isInstalled:
				modalPosition.current = client.isIPad ? ModalPosition.topRight : ModalPosition.bottom
				modalHeaderTranslation.current = 'mobile'
				return renderIOSPrompt()

			case client.isIOS && !client.isSafariMobile:
				modalPosition.current = client.isIPad ? ModalPosition.topRight : ModalPosition.bottom
				modalHeaderTranslation.current = 'mobile'
				return renderSwitchToSafariPrompt()

			case client.isAndroid:
				modalPosition.current = ModalPosition.bottom
				modalHeaderTranslation.current = 'mobile'
				return renderInstallPrompt()

			case client.isDesktop:
				modalPosition.current = ModalPosition.topRight
				modalHeaderTranslation.current = 'desktop'
				return renderInstallPrompt()
		}
	}

	return (
		<Modal
			ref={modal}
			className="install-prompt-modal"
			hideConfirm={true}
			header={<Trans i18nKey={`component.installPromptModal.header.${modalHeaderTranslation.current}`} />}
			headerClassName="font-family-alternative--medium font-size-default"
			position={modalPosition.current}
			onModalClose={() => setVisibleState(false)}
		>
			{renderPrompt()}
		</Modal>
	)
}

export default InstallPromptModal
