import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import Checkbox from 'shared/components/Checkbox'
import { OPTEOptionsOriginal, RiskOptions } from 'shared/enums'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'

const RBConditions: React.FC<{ riskId: RiskOptions; type?: OPTEOptionsOriginal; duration?: number }> = ({
	riskId,
	type,
	duration,
}) => {
	const { getChildPath, navigateTo } = useRouteHelper()
	useBackButtonPath(
		undefined !== duration
			? `${getChildPath(
					routesDictionary.riskBenefit,
					RiskOptions.disabilityBenefit === riskId
						? 'RBDisabilityBenefitChangeSelect'
						: 'RBPartnerBenefitChangeSelect'
			  )}/${type}`
			: getChildPath(
					routesDictionary.riskBenefit,
					RiskOptions.disabilityBenefit === riskId ? 'RBDisabilityBenefitChange' : 'RBPartnerBenefitChange'
			  )
	)
	useNavigationTitle(<Trans i18nKey="view.riskBenefit.pageTitle.default" />)

	const { t } = useTranslation()
	const [conditionsRead, setConditionsRead] = useState<boolean>(false)

	const conditions = useMemo(
		() => t(`view.riskBenefit.conditions.list.${riskId}`, { returnObjects: true }),
		// eslint-disable-next-line
		[]
	)

	const onClickHandler = useCallback(() => {
		navigateTo(
			`${getChildPath(
				routesDictionary.riskBenefit,
				RiskOptions.disabilityBenefit === riskId ? 'RBDisabilityBenefitSummary' : 'RBPartnerBenefitSummary'
			)}/${type}${undefined !== duration ? `/${duration}` : ''}`
		) // eslint-disable-next-line
	}, [])

	return (
		<div className="risk-benefit center-view grid grid--center-scroll rb-conditions">
			<h3 className="text-align--left grid__top">
				<Trans i18nKey="view.riskBenefit.conditions.headline" />
			</h3>

			<div className="grid__center">
				<ul className="risk-benefit__list">
					{Object.keys(conditions).map((key) => (
						<li key={`condition-list-${key}`}>
							<span>
								<Trans i18nKey={`view.riskBenefit.conditions.list.${riskId}.${key}`} />
							</span>
						</li>
					))}
				</ul>

				<Checkbox
					className="font-size-default margin"
					label={t('generic.hintsRead')}
					value={1}
					checked={conditionsRead}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConditionsRead(e.target.checked)}
				/>
			</div>

			<div className="grid__bottom">
				<Button
					onClick={onClickHandler}
					type={ButtonType.primary}
					label={t('generic.next')}
					disabled={!conditionsRead}
				/>
			</div>
		</div>
	)
}

export default RBConditions
