import React from 'react'
import { useTranslation } from 'react-i18next'
import useHeaderState from 'state/useHeaderState'
import { HeaderState } from '../state/useHeaderState'

const NavigationTitle: React.FC = () => {
	const { t } = useTranslation()

	const [navigationTitle] = useHeaderState<HeaderState['navigationTitle']>(
		(state: HeaderState) => state.navigationTitle
	)

	return <div className="navigation-title">{navigationTitle || t('partial.logo.appName')}</div>
}

export default NavigationTitle
