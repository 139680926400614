import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useResetHeaderState } from 'state/useHeaderState'

const Welcome: React.FC = () => {
	useResetHeaderState()
	const { t } = useTranslation()
	const { getMainPath } = useRouteHelper()

	return (
		<>
			<div className="center-view narrow-width flex flex--direction-column flex--align-items-center no-auto-hyphens">
				<div className="page-title">
					<h1>
						<Trans i18nKey="view.welcome.pageTitle" />
					</h1>
				</div>

				<div className="margin--top">
					<p className="text-color-cyan text--bold-spaced text-align--center">
						<Trans i18nKey="view.welcome.registration.headline" />
					</p>
					<p>
						<Trans i18nKey="view.welcome.registration.bodytext">
							<Button
								type={[ButtonType.link, ButtonType.text]}
								className="text-color-cyan"
								route={getMainPath(routesDictionary.registrationHelp)}
							>
								{' '}
							</Button>
						</Trans>
					</p>
					<Button
						type={ButtonType.primary}
						label={t('view.welcome.registration.buttonLabel')}
						route={getMainPath(routesDictionary.register)}
					/>
				</div>
				<div className="margin--top">
					<p className="text-color-cyan text--bold-spaced text-align--center">
						<Trans i18nKey="view.welcome.login.headline" />
					</p>
					<p>
						<Trans i18nKey="view.welcome.login.bodytext" />
					</p>
					<Button
						type={ButtonType.primary}
						label={t('view.welcome.login.buttonLabel')}
						route={getMainPath(routesDictionary.login)}
					/>
				</div>
			</div>
		</>
	)
}

export default Welcome
