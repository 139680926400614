import { IconType } from 'components/Icons'
import React, { FunctionComponent, ReactElement } from 'react'
import Icon from './Icon'

export interface HintProps {
	content: string | ReactElement
	icon?: IconType
	color?: string
}

const Hint: FunctionComponent<HintProps> = (props) => {
	return (
		<div className="hint flex flex--direction-column">
			<Icon
				className="hint__icon flex--align-self-center"
				type={props.icon ? props.icon : IconType.info}
				color={'var(--hint-icon-color)'}
			/>

			<div className="hint__content">{props.content}</div>
		</div>
	)
}

export default Hint
