import { API, Auth } from 'aws-amplify'
import axios from 'axios'
import { usePath } from 'raviger'
import { trackPromise } from 'react-promise-tracker'
import { QueryFunction, QueryFunctionContext, useQuery } from 'react-query'
import routesDictionary from 'routes'
import { LoadingSpinnerArea } from 'shared/components/LoadingSpinner'
import { OPTEOptions, OPTEOptionsOriginal, OPTTOptions, OPTTOptionsOriginal, RiskOptions } from 'shared/enums'
import { getLocalStorage } from 'shared/helper/localStorage'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import {
	ApiData,
	ApiError,
	BarChartData,
	BonusMonthListData,
	ChangeUserContactArguments,
	ChangeUserContactStatus,
	CompensationData,
	CompensationSimulationSettings,
	CompensationSimulationSettingsData,
	CompensationTypeData,
	DocumentData,
	DocumentsData,
	FundAllocationsData,
	FundPerformanceData,
	FundQueryStringParameters,
	IAppSetup,
	IPutNewRiskOptionBody,
	IPutNewRiskOptionBodyTransformed,
	IRiskOption,
	IRiskOptionAvailable,
	IRiskPartnerData,
	IRiskYearsAvailable,
	IUpdateAppSetup,
	IndiceData,
	MfaResponseData,
	PensionAssetsData,
	PensionAssetsStringParameters,
	SingleCompensationData,
	TimeRangeData,
	UserProfileData,
} from 'shared/interfaces'
import { components } from 'types/api-interface'
import { useAuth } from './useAuth'
import { useUserGroup } from './useUserGroup'

export enum MutationKey {
	patchPayoutModel = 'patchPayoutModel ',
}

export enum QueryKey {
	cognitoUserAttributes = 'cognitoUserAttributes',
	bausteinKonto = 'bausteinKonto',
	appSetup = 'appSetup',
	availableModels = 'availableModels',
	allCompensations = 'allCompensations',
	bankName = 'bankName',
	compensationTypes = 'compensationTypes',
	compensationYear = 'compensationYear',
	compensationMonth = 'compensationMonth',
	compensationSimulationSalary = 'compensationSimulationSalary',
	compensationSimulationBonus = 'compensationSimulationBonus',
	compensationSimulationSettings = 'compensationSimulationSettings',
	countries = 'countries',
	fundAllocation = 'fundAllocation',
	fundPerformance = 'fundPerformance',
	fundPerformanceIndices = 'fundPerformanceIndices',
	fundPerformanceTimeRanges = 'fundPerformanceTimeRanges',
	payoutModelInProgress = 'payoutModelInProgress',
	payoutModelEditable = 'payoutModelEditable',
	confirmedPayoutModel = 'confirmedPayoutModel',
	mixedPayoutYears = 'mixedPayoutYears',
	payoutTypes = 'payoutTypes',
	payoutYears = 'payoutYears',
	payoutModelConfirmationDate = 'payoutModelConfirmationDate',
	payoutDocuments = 'payoutDocuments',
	pensionAssets = 'pensionAssets',
	pensionAssetsViewIntervals = 'pensionAssetsViewIntervals',
	pensionAssetsReferenceYears = 'pensionAssetsReferenceYears',
	pensionDate = 'pensionDate',
	pensionBalance = 'pensionBalance',
	riskOptions = 'riskOptions',
	riskOptionsAvailable = 'riskOptionsAvailable',
	riskOptionsTypes = 'riskOptionsTypes',
	riskOptionsTypesV2 = 'riskOptionsTypesV2',
	riskOptionsYears = 'riskOptionsYears',
	riskSimulationBenefit = 'riskSimulationBenefit',
	riskSimulationCompensation = 'riskSimulationCompensation',
	riskPartnerData = 'riskPartnerData',
	termsOfServe = 'termsOfService',
	documents = 'documents',
	bonusMonth = 'bonusMonth',
	userProfile = 'userProfile',
	currentCognitoUserpool = 'currentCognitoUserpool',
}

export interface Query<V> {
	apiCall: (...args: any[]) => void
	queryParameters?: { [key: string]: string | number }
	variables?: V
}

export interface IPayoutInterval {
	startYear: number
	startMonth: number
	endYear: number
	endMonth: number
	lifelong: boolean
}

export interface awsConfig {
	aws_project_region: string
	aws_cognito_region: string
	aws_user_pools_id: string
	aws_user_pools_web_client_id: string
	aws_cloud_logic_custom: { name: string; endpoint: string; region: string }[]
	authenticationFlowType?: string
}

const useApi = () => {
	const auth = useAuth()
	const path = usePath()
	const { getMainPath, navigateTo } = useRouteHelper()

	// EMG, GET or Vorstand
	const { isHigherUp } = useUserGroup()

	const { data: awsConfig } = useQuery('awsConfig', getLocalAWSConfig)

	const apiDataObject = async (dataObject?: ApiData) => {
		/**
		 * if the user has been logged out on another device or the access token has
		 * been revoked, then auto-logout the current device
		 */
		Auth.currentUserInfo().then((userObject) => {
			if (
				null === userObject ||
				(null !== userObject && Object.entries(userObject).length === 0 && userObject.constructor === Object)
			) {
				/* prevent auto logout if user allready in logout view */

				if (path === '/logout') {
					return null
				}

				console.log('logout - cant get userInfo before request')
				return navigateTo(getMainPath(routesDictionary.logout), false, { autoLogout: true })
			}
		})

		const currentUser = await Auth.currentSession()

		const data = {
			...dataObject,
			headers: {
				Authorization: `Bearer ${currentUser.getIdToken().getJwtToken()}`,
			},
		}

		return data
	}

	function transformOPTOptionType(option: any, riskId?: RiskOptions): any {
		switch (option) {
			case OPTEOptionsOriginal.STANDARD:
				return OPTEOptions.STANDARD

			case OPTEOptionsOriginal.ONE_YEAR:
				return OPTEOptions.UP

			case OPTEOptionsOriginal.THREE_YEARS:
				return OPTEOptions.UP

			case OPTTOptionsOriginal.STANDARD:
				return OPTTOptions.STANDARD

			case OPTTOptionsOriginal.ONE_YEAR_UP:
				return OPTTOptions.UP

			case OPTTOptionsOriginal.THREE_YEAR_UP:
				return OPTTOptions.UP

			case OPTTOptionsOriginal.ONE_YEAR_DOWN:
				return OPTTOptions.DOWN

			case OPTTOptionsOriginal.THREE_YEAR_DOWN:
				return OPTTOptions.DOWN
		}
	}

	function resolveOPTOptionType(settings: { option: any; duration?: number; riskId: RiskOptions }): any {
		const { option, duration, riskId } = settings
		const types: { [key: string]: { [key: string]: any } } = {
			[RiskOptions.disabilityBenefit]: {
				0: {
					[OPTEOptions.STANDARD]: OPTEOptionsOriginal.STANDARD,
					[OPTEOptions.UP]: OPTEOptionsOriginal.ONE_YEAR,
				},
				1: {
					[OPTEOptions.STANDARD]: OPTEOptionsOriginal.STANDARD,
					[OPTEOptions.UP]: OPTEOptionsOriginal.ONE_YEAR,
				},
				3: {
					[OPTEOptions.STANDARD]: OPTEOptionsOriginal.STANDARD,
					[OPTEOptions.UP]: OPTEOptionsOriginal.THREE_YEARS,
				},
			},
			[RiskOptions.partnerBenefit]: {
				0: {
					[OPTTOptions.STANDARD]: OPTTOptionsOriginal.STANDARD,
					[OPTTOptions.UP]: OPTTOptionsOriginal.ONE_YEAR_UP,
					[OPTTOptions.DOWN]: OPTTOptionsOriginal.ONE_YEAR_DOWN,
				},
				1: {
					[OPTTOptions.STANDARD]: OPTTOptionsOriginal.STANDARD,
					[OPTTOptions.UP]: OPTTOptionsOriginal.ONE_YEAR_UP,
					[OPTTOptions.DOWN]: OPTTOptionsOriginal.ONE_YEAR_DOWN,
				},
				3: {
					[OPTTOptions.STANDARD]: OPTTOptionsOriginal.STANDARD,
					[OPTTOptions.UP]: OPTTOptionsOriginal.THREE_YEAR_UP,
					[OPTTOptions.DOWN]: OPTTOptionsOriginal.THREE_YEAR_DOWN,
				},
			},
		}

		return types[riskId][duration || 0][option]
	}

	const getUserAttributes = async () => {
		try {
			const user = await Auth.currentAuthenticatedUser()
			const response = await Auth.userAttributes(user)
			const attributes = new Map()
			response.forEach((v) => attributes.set(v.Name, v.Value))
			return attributes
		} catch {
			return null
		}
	}

	const getCompensationTypes = async (): Promise<CompensationTypeData[] | void> => {
		const data = await trackPromise(apiDataObject())

		const compensationTypeData = trackPromise(
			API.get('defferedCompensationApi', 'compensation/types', data)
				.then((response: { data: CompensationTypeData[] }) => response.data)
				.catch((err) => {
					console.log(err)
					return
				})
		)

		return compensationTypeData
	}

	const getPayout = async () => {
		const data = await trackPromise(apiDataObject())

		await trackPromise(
			API.get('payoutModelApi', 'payout', data)
				.then((reponse) => console.log(reponse))
				.catch((err) => console.log(err))
		)

		return true
	}

	async function getLocalAWSConfig(): Promise<awsConfig> {
		const config = await import(('../' + process.env.REACT_APP_AWS_CONFIG) as string)
		return config.default
	}

	const getIndices = async (): Promise<IndiceData[] | void> => {
		const data = await trackPromise(apiDataObject())

		const indiceData = await API.get('fundApi', 'indices', data)
			.then((response: { indices: IndiceData[] }) => response.indices)
			.catch((err) => {
				console.log(err)
				return
			})

		return indiceData
	}

	const getIndiceTimeRanges = async (): Promise<TimeRangeData[] | void> => {
		const data = await apiDataObject()

		const responseData = await API.get('fundApi', '/performance/intervals', data)
			.then((response: { performanceIntervals: TimeRangeData[] }) => response.performanceIntervals)
			.catch((err) => {
				console.log(err)
				return
			})

		return responseData
	}

	const getFundPerformance: QueryFunction<
		FundPerformanceData | void,
		[QueryKey.fundPerformance, FundQueryStringParameters]
	> = async ({
		queryKey,
		pageParam,
	}: QueryFunctionContext<[QueryKey.fundPerformance, FundQueryStringParameters], { area: LoadingSpinnerArea }>) => {
		const [, queryStringParameters] = queryKey
		const { area } = pageParam || {}

		const data = await trackPromise(apiDataObject({ queryStringParameters }), area)

		const fundData = await trackPromise(
			API.get('payoutModelApi', 'kursverlauf', data)
				.then((response: FundPerformanceData) => response)
				.catch((err) => {
					console.log(err)
					return
				}),
			area
		)

		return fundData
	}

	//V2
	// const getFundPerformance: QueryFunction<
	// 	FundPerformanceData | void,
	// 	[QueryKey.fundPerformance, FundQueryStringParameters]
	// > = async ({
	// 	queryKey,
	// 	pageParam,
	// }: QueryFunctionContext<[QueryKey.fundPerformance, FundQueryStringParameters], { area: LoadingSpinnerArea }>) => {
	// 	const [, queryStringParameters] = queryKey
	// 	const { area } = pageParam || {}

	// 	const data = await trackPromise(apiDataObject({ queryStringParameters }), area)

	// 	const fundData = await trackPromise(
	// 		API.get('payoutModelApi', 'kursverlauf', data)
	// 			.then((response: FundPerformanceData) => response)
	// 			.catch((err) => {
	// 				console.log(err)
	// 				return
	// 			}),
	// 		area
	// 	)

	// 	return fundData
	// }

	const getFundAllocation = async (): Promise<FundAllocationsData | void> => {
		const data = await trackPromise(apiDataObject())

		const responseData = await trackPromise(
			API.get('fundApi', 'allocation', data)
				.then((response: FundAllocationsData) => response)
				.catch((err) => {
					console.log(err)
					return
				})
		)

		return responseData
	}

	const getSingleCompensation: QueryFunction<
		SingleCompensationData | void,
		[
			QueryKey.compensationMonth,
			{
				year: number
				month: number
			}
		]
	> = async ({ queryKey }) => {
		const [, queryStringParameters] = queryKey

		const data = await trackPromise(apiDataObject({ queryStringParameters }))

		const compensationData = await trackPromise(
			API.get('defferedCompensationApi', 'compensation/date', data)
				.then((response: { data: SingleCompensationData }) => response.data)
				.catch((err) => {
					console.log(err)
					return
				})
		)

		return compensationData
	}

	const getCompensationSimulationSalary: QueryFunction<
		CompensationData | void,
		[
			QueryKey.compensationSimulationSalary,
			{
				percentage?: number
				amount?: number
			}
		]
	> = async ({ queryKey }) => {
		const [, queryStringParameters] = queryKey

		const data = await trackPromise(apiDataObject({ queryStringParameters }))

		const compensationSimulationData = await trackPromise(
			API.get(
				isHigherUp ? 'payoutModelApi' : 'defferedCompensationApi',
				isHigherUp ? 'entgeltumwandlung/anpassungarbeitnehmerbeitrag/prognose' : 'compensations',
				data
			)
				.then((response: CompensationData) => response)
				.catch((err) => {
					console.log(err)
					return
				})
		)

		return compensationSimulationData
	}

	const getCompensationSimulationBonus: QueryFunction<
		CompensationData | void,
		[
			QueryKey.compensationSimulationBonus,
			{
				type: string
				month: number
				percentage?: number
				amount?: number
			}
		]
	> = async ({ queryKey }) => {
		const [, queryStringParameters] = queryKey

		const data = await trackPromise(apiDataObject({ queryStringParameters }))

		const bonusSimulationData = await trackPromise(
			API.get('payoutModelApi', 'entgeltumwandlung/bonus', data)
				.then((response: CompensationData) => response)
				.catch((err) => {
					console.log(err)
					return
				})
		)

		return bonusSimulationData
	}

	const getCompensationSimulationSettings: QueryFunction<
		CompensationSimulationSettingsData | void,
		[QueryKey.compensationSimulationSettings, 'bonus' | 'salary']
	> = async ({ queryKey }) => {
		const [, type] = queryKey
		const data = await trackPromise(apiDataObject())

		/**
		 * only send params if current user is not part of EMG/GET
		 */
		const percentageData = {
			...data,
			queryStringParameters: !isHigherUp
				? {
						sliderValue: 2, // this can be any value
						type,
				  }
				: {
						/* 01: salary, 02: bonus */ type: type === 'salary' ? '01' : '02',
				  },
		}

		const amountData = {
			...data,
			queryStringParameters: !isHigherUp
				? {
						amount: 1000, // this can be any value
						type,
				  }
				: {
						type: type === 'salary' ? '01' : '02',
				  },
		}

		const api = isHigherUp ? 'payoutModelApi' : 'defferedCompensationApi'
		const pathAbsolut = isHigherUp ? 'entgeltumwandlung/anpassungarbeitnehmerbeitrag/prozent' : 'calculation'
		const pathPercent = isHigherUp ? 'entgeltumwandlung/anpassungarbeitnehmerbeitrag/absolut' : 'calculation'

		const [percentageSettingsData, amountSettingsData] = await trackPromise(
			Promise.all([
				API.get(api, pathAbsolut, percentageData)
					.then((response: { data: CompensationSimulationSettings }) => response.data)
					.catch((err) => {
						console.log(err)
						return
					}),
				API.get(api, pathPercent, amountData)
					.then((response: { data: CompensationSimulationSettings }) => response.data)
					.catch((err) => {
						console.log(err)
						return
					}),
			])
		)

		if (undefined !== percentageSettingsData && undefined !== amountSettingsData) {
			return {
				percentage: percentageSettingsData,
				amount: amountSettingsData,
			}
		}

		return
	}

	const getBonusMonthList: QueryFunction<BonusMonthListData[] | void, [QueryKey.bonusMonth, string]> = async ({
		queryKey,
	}) => {
		const [, id] = queryKey
		const data = await trackPromise(apiDataObject())

		const monthListData = trackPromise(
			API.get('defferedCompensationApi', `compensations/list/${id}`, data)
				.then((response: { response: BonusMonthListData[] }) => response.response)
				.catch((err) => {
					console.log(err)
					return
				})
		)

		return monthListData
	}

	const getCompensation: QueryFunction<
		BarChartData[] | void,
		[QueryKey.allCompensations | QueryKey.compensationYear, number?]
	> = async ({ queryKey }) => {
		const [, year] = queryKey || []

		const data = await trackPromise(apiDataObject())

		let apiPath = 'compensation'

		if (year) {
			apiPath += `/${year}`
		}

		const compensationData = await trackPromise(
			API.get('defferedCompensationApi', apiPath, data)
				.then((response: { data: BarChartData[] }) => response.data)
				.catch((err) => {
					console.log(err)
					return
				})
		)

		if (compensationData && year) {
			// FIXME: total amount should come from backend
			return compensationData.map((dataEntry: BarChartData) => {
				;(dataEntry.y1 as number) += dataEntry.y2 as number
				delete dataEntry.y2

				return dataEntry
			})
		}

		return compensationData
	}

	const setCompensationSalary = async (body: {
		percentage?: number
		amount?: number
	}): Promise<MfaResponseData | void> => {
		const data = await trackPromise(apiDataObject({ body }))

		const responseData = await trackPromise(
			API.put('defferedCompensationApi', 'compensation', data)
				.then((response: MfaResponseData) => {
					if (true === response.mfaRequired) {
						auth.requireSecondFactor(response.transactionId)
					}

					return response
				})
				.catch((err) => {
					console.log(err)
					return
				})
		)

		return responseData
	}

	const setCompensationBonus = async (body: {
		type: string
		month: number
		percentage?: number
		amount?: number
	}): Promise<MfaResponseData | void> => {
		const data = await trackPromise(apiDataObject({ body }))

		const responseData = await trackPromise(
			API.put('defferedCompensationApi', 'bonus', data)
				.then((response: MfaResponseData) => {
					if (true === response.mfaRequired) {
						auth.requireSecondFactor(response.transactionId)
					}

					return response
				})
				.catch((err) => {
					console.log(err)
					return
				})
		)

		return responseData
	}

	const getPensionAssets: QueryFunction<PensionAssetsData | void, [QueryKey.pensionAssets]> = async ({
		queryKey,
		pageParam,
	}: QueryFunctionContext<[QueryKey.pensionAssets], { area: LoadingSpinnerArea }>) => {
		const { area } = pageParam || {}

		const data = await trackPromise(apiDataObject(), area)

		const pensionAssetsData = await trackPromise(
			API.get('payoutModelApi', 'versorgungsguthaben-verlauf', data)
				.then((response: PensionAssetsData) => response)
				.catch((err) => {
					console.log(err)
					return
				}),
			area
		)

		return pensionAssetsData
	}

	const getPensionAssetsReferenceYears = async (): Promise<TimeRangeData[] | void> => {
		const data = await apiDataObject()

		const referenceYearsData = await API.get('fundApi', 'pensionAssets/referenceYears', data)
			.then((response: { pensionAssetsIntervals: TimeRangeData[] }) => response.pensionAssetsIntervals)
			.catch((err) => {
				console.log(err)
				return
			})

		return referenceYearsData
	}

	const getPensionAssetsViewIntervals = async (): Promise<TimeRangeData[] | void> => {
		const data = await apiDataObject()

		const viewIntervalsData = await API.get('fundApi', 'pensionAssets/viewIntervals', data)
			.then((response: { pensionAssetsViewIntervals: TimeRangeData[] }) => response.pensionAssetsViewIntervals)
			.catch((err) => {
				console.log(err)
				return
			})

		return viewIntervalsData
	}

	const getUserProfile = async (): Promise<UserProfileData | void> => {
		const data = await trackPromise(apiDataObject())

		const userProfileData = await trackPromise(
			API.get('defferedCompensationApi', 'profile/data', data)
				.then((response: { data: UserProfileData }) => {
					return response.data
				})
				.catch((err) => {
					// console.log(err.response)
					console.dir(err)
					return
				})
		)

		return userProfileData
	}

	const getBausteinKonto = async (): Promise<components['schemas']['BausteinkontoOverview'] | void> => {
		const data = await trackPromise(apiDataObject())

		const bausteine = await trackPromise(
			API.get('payoutModelApi', 'bausteinkonto', data)
				.then((response: components['schemas']['BausteinkontoOverview']) => response)
				.catch((err) => {
					console.log(err)
					return
				})
		)

		return bausteine
	}

	// const getBausteinKonto: any = async () => {
	// 	const data = await trackPromise(apiDataObject())

	// 	const bausteine = await API.get('payoutModelApi', 'bausteinkonto', data)
	// 		.then((response: components['schemas']['BausteinkontoOverview']) => response)
	// 		.catch((err) => {
	// 			console.log(err)
	// 			return
	// 		})

	// 	return bausteine
	// }

	const setUserProfile = async (profileSubmitData: any): Promise<MfaResponseData | { error: ApiError }> => {
		const data = await trackPromise(apiDataObject({ body: { ...profileSubmitData } }))

		const responseData = await trackPromise(
			API.put('defferedCompensationApi', 'profile/data', data)
				.then((response: MfaResponseData) => {
					if (true === response.mfaRequired) {
						auth.requireSecondFactor(response.transactionId)
					}

					return response
				})
				.catch((err) => {
					// console.log(err.response)
					return err.response
				})
		)

		return responseData
	}

	const getDocuments = async (): Promise<DocumentsData | void> => {
		const data = await trackPromise(apiDataObject())

		const documentsData = await trackPromise(
			API.get('defferedCompensationApi', 'ts-documents', data)
				.then((response: DocumentsData) => response)
				.catch((err) => {
					console.log(err)
					return
				})
		)

		return documentsData
	}

	const getDocumentLink = async (documentId: DocumentData['documentId'], area: string): Promise<string | void> => {
		const data = await trackPromise(apiDataObject(), area)

		const responseData = await trackPromise(
			API.get('defferedCompensationApi', `ts-documents/${documentId}`, data)
				.then((response: { url: string }) => response.url)
				.catch((err) => {
					console.log(err)
					return
				}),
			area
		)

		return responseData
	}

	const requestResetPassword = async ({
		userName,
		captchaResponseToken,
	}: {
		userName: string
		captchaResponseToken: string
	}): Promise<boolean> => {
		// const data = await apiDataObject()

		/**
		 * always return true to prevent guessing any userNames
		 */
		const responseData = await trackPromise(
			API.post('authApi', 'requestResetPassword', { body: { userName, captchaResponseToken } })
				.then(() => true)
				.catch(() => {
					return true
				})
		)

		return responseData
	}

	const resetPassword = async ({
		passwordResetToken,
	}: {
		passwordResetToken: string
	}): Promise<boolean | ApiError> => {
		// const data = await apiDataObject()

		console.log('init amplify for password reset')
		await auth.initializeAmplify(process.env.REACT_APP_OLD_AWS_USERPOOL)

		console.log('sending password request')

		const responseData = await trackPromise(
			API.post('authApi', 'resetPassword', { body: { passwordResetToken } })
				.then(() => true)
				.catch((err) => {
					return err.response
				})
		)

		console.log('received≤ password request,', responseData)

		return responseData
	}

	const updatePassword = async ({
		oldPassword,
		newPassword,
	}: {
		oldPassword: string
		newPassword: string
	}): Promise<MfaResponseData | ApiError> => {
		const data = await trackPromise(apiDataObject({ body: { oldPassword, newPassword } }))

		const responseData = await trackPromise(
			API.post('authApi', 'updatePassword', data)
				.then((response: MfaResponseData) => {
					if (true === response.mfaRequired) {
						auth.requireSecondFactor(response.transactionId)
					}

					return response
				})
				.catch((err: { response: ApiError }) => {
					return err.response
				})
		)

		return responseData
	}

	const terminateDigitalParticipation = async (): Promise<MfaResponseData | void> => {
		const data = await trackPromise(apiDataObject())

		const responseData = await trackPromise(
			API.post('authApi', 'terminateDigitalParticipation', data)
				.then((response: MfaResponseData) => {
					if (true === response.mfaRequired) {
						auth.requireSecondFactor(response.transactionId)
					}

					return response
				})
				.catch((err) => {
					console.log(err)
					return
				})
		)

		return responseData
	}

	const setMfa = async ({
		transactionId,
		token,
	}: {
		transactionId: MfaResponseData['transactionId']
		token: string
	}): Promise<boolean | ApiError> => {
		const data = await trackPromise(apiDataObject({ body: { token } }), LoadingSpinnerArea.mfa)

		const responseData = await trackPromise(
			API.post('authApi', `transactions/${transactionId}`, data)
				.then(() => true)
				.catch((err) => {
					return err.response
				}),
			LoadingSpinnerArea.mfa
		)

		return responseData
	}

	const getRiskOptions = async (): Promise<IRiskOption[] | void> => {
		const data = await trackPromise(apiDataObject())

		const responseData = await trackPromise(
			API.get('bavApi', 'risk/options', data)
				.then((response: { data: IRiskOption[] }) => response.data)
				.then((originalData) =>
					originalData.map((value) => {
						if (RiskOptions.designatePartner !== value.type && value.currentOption) {
							value.currentOption = transformOPTOptionType(value.currentOption)
						}

						return value
					})
				)
				.catch((err) => {
					console.log(err)
					return
				})
		)

		return responseData
	}

	const getRiskOptionsAvailable = async (): Promise<IRiskOptionAvailable | void> => {
		const data = await trackPromise(apiDataObject())

		const responseData = await trackPromise(
			API.get('bavApi', 'risk/options/available', data)
				.then((response: IRiskOptionAvailable) => response)
				.then((originalData) => {
					if (originalData.currentOPTTOption?.OPTT) {
						originalData.currentOPTTOption.OPTT = transformOPTOptionType(
							originalData.currentOPTTOption.OPTT
						)
					}

					if (originalData.futureRiskOPTTOption?.OPTT) {
						originalData.futureRiskOPTTOption.OPTT = transformOPTOptionType(
							originalData.futureRiskOPTTOption.OPTT
						)
					}

					if (originalData.currentOPTEOption?.OPTE) {
						originalData.currentOPTEOption.OPTE = transformOPTOptionType(
							originalData.currentOPTEOption.OPTE
						)
					}

					if (originalData.futureRiskOPTEOption?.OPTE) {
						originalData.futureRiskOPTEOption.OPTE = transformOPTOptionType(
							originalData.futureRiskOPTEOption.OPTE
						)
					}

					return originalData
				})
				.catch((err) => {
					console.log(err)
					return
				})
		)

		return responseData
	}

	const getRiskOptionsTypes: QueryFunction<
		OPTEOptions[] | OPTTOptions[] | void,
		[
			QueryKey.riskOptionsTypes,
			{
				riskId: RiskOptions
			}
		]
	> = async ({ queryKey }) => {
		const [, queryStringParameters] = queryKey

		/**
		 * v2 uses needs different params
		 */
		const data = await trackPromise(
			apiDataObject({
				queryStringParameters: isHigherUp
					? { riskOption: riskOptions[queryStringParameters.riskId] }
					: { riskId: queryStringParameters.riskId },
			})
		)

		// use new v2 api for EMG/GET/vorstand
		const api = isHigherUp ? 'payoutModelApi' : 'bavApi'
		const path = isHigherUp ? 'risikoleistungen/auswahloptionen' : 'risk/options/types'

		const responseData = await trackPromise(
			API.get(api, path, data)
				.then((response: { data: OPTEOptionsOriginal[] | OPTTOptionsOriginal[] }) => response.data)
				.then((originalData) =>
					[...originalData].map((value) => {
						return transformOPTOptionType(value)
					})
				)
				.catch((err) => {
					console.log(err)
					return
				})
		)

		return responseData
	}

	// /**
	//  * Returns the available Riskoptions for EMG/GET/Vorstand
	//  * @param param0
	//  * @returns
	//  */
	// const getRiskOptionsTypesV2: QueryFunction<
	// 	OPTEOptions[] | OPTTOptions[] | void,
	// 	[
	// 		QueryKey.riskOptionsTypes,
	// 		{
	// 			riskId: RiskOptions
	// 		}
	// 	]
	// > = async ({ queryKey }) => {
	// 	const [, queryStringParameters] = queryKey
	// 	const data = await trackPromise(apiDataObject({ queryStringParameters }))

	// 	const responseData = await trackPromise(
	// 		API.get('payoutModelApi', 'risk/options/types', data)
	// 			.then((response: { data: OPTEOptionsOriginal[] | OPTTOptionsOriginal[] }) => response.data)
	// 			.then((originalData) =>
	// 				[...originalData].map((value) => {
	// 					return transformOPTOptionType(value)
	// 				})
	// 			)
	// 			.catch((err) => {
	// 				console.log(err)
	// 				return
	// 			})
	// 	)

	// 	return responseData
	// }

	const getRiskSimulationCompensation: QueryFunction<
		CompensationData | void,
		[
			QueryKey.riskSimulationCompensation,
			{
				riskOption?: OPTEOptions | OPTTOptions
				riskId: RiskOptions
			}
		]
	> = async ({ queryKey }) => {
		const [, queryStringParameters] = queryKey

		const queryStringParametersTransformed = {
			...queryStringParameters,
			riskOption: resolveOPTOptionType({
				option: queryStringParameters.riskOption,
				riskId: queryStringParameters.riskId,
			}),
		}

		const data = await trackPromise(apiDataObject({ queryStringParameters: queryStringParametersTransformed }))

		const responseData = await trackPromise(
			API.get('bavApi', 'risk/calculate', data)
				.then((response: CompensationData) => response)
				.catch((err) => {
					console.log(err)
					return
				})
		)

		return responseData
	}

	const riskOptions = {
		[RiskOptions.partnerBenefit]: 'partnerBenefitOption',
		[RiskOptions.disabilityBenefit]: 'disabilityOption',
		[RiskOptions.designatePartner]: '',
	}

	const getRiskSimulationBenefit: QueryFunction<
		BarChartData[] | void,
		[
			QueryKey.riskSimulationBenefit,
			{
				riskOption?: OPTEOptions | OPTTOptions
				riskId: RiskOptions
			}
		]
	> = async ({ queryKey }) => {
		const [, queryStringParameters] = queryKey

		/* new api */
		if (isHigherUp) {
			// const queryStringParametersTransformed = {
			// 	...queryStringParameters,
			// 	riskOption: resolveOPTOptionType({
			// 		option: queryStringParameters.riskOption,
			// 		riskId: queryStringParameters.riskId,
			// 	}),
			// }
			const data = await trackPromise(
				apiDataObject({
					queryStringParameters: {
						riskOption: riskOptions[queryStringParameters.riskId],
						riskChoice: 'standard',
					},
				})
			)
			const responseData = await trackPromise(
				API.get('payoutModelApi', 'risikoleistungen/versorgungsguthaben', data)
					.then((response: { pensionCredit: number; riskBenefit: number }) => response)
					.then((originalData) =>
						Object.entries(originalData).reduce((transformedData: BarChartData[], [x, y]) => {
							transformedData.push({
								x,
								y,
							})

							return transformedData
						}, [])
					)
					.catch((err) => {
						console.log(err)
						return
					})
			)
			return responseData

			/* old api */
		} else {
			const queryStringParametersTransformed = {
				...queryStringParameters,
				riskOption: resolveOPTOptionType({
					option: queryStringParameters.riskOption,
					riskId: queryStringParameters.riskId,
				}),
			}
			const data = await trackPromise(apiDataObject({ queryStringParameters: queryStringParametersTransformed }))

			const responseData = await trackPromise(
				API.get('bavApi', 'risk/pensionAssets', data)
					.then((response: { pensionCredit: number; riskBenefit: number }) => response)
					.then((originalData) =>
						Object.entries(originalData).reduce((transformedData: BarChartData[], [x, y]) => {
							transformedData.push({
								x,
								y,
							})

							return transformedData
						}, [])
					)
					.catch((err) => {
						console.log(err)
						return
					})
			)
			return responseData
		}
	}

	const getRiskOptionsYears = async (): Promise<IRiskYearsAvailable | void> => {
		const data = await trackPromise(apiDataObject())

		const responseData = await trackPromise(
			API.get('bavApi', 'risk/options/year', data)
				.then((response: IRiskYearsAvailable) => response)
				.catch((err) => {
					console.log(err)
					return
				})
		)

		return responseData
	}

	const setRiskOption = async (
		requestData: IPutNewRiskOptionBody
	): Promise<MfaResponseData | { error: ApiError }> => {
		const requestDataTransformed: IPutNewRiskOptionBodyTransformed = {
			riskType: requestData.riskId,
			riskOption: resolveOPTOptionType({
				option: requestData.type,
				duration: requestData.duration,
				riskId: requestData.riskId,
			}),
		}

		const data = await trackPromise(apiDataObject({ body: { ...requestDataTransformed } }))

		const responseData = await trackPromise(
			API.put('bavApi', 'risk', data)
				.then((response: MfaResponseData) => {
					if (true === response.mfaRequired) {
						auth.requireSecondFactor(response.transactionId)
					}

					return response
				})
				.catch((err) => {
					return err.response
				})
		)

		return responseData
	}

	const getRiskPartnerData = async (): Promise<IRiskPartnerData | void> => {
		const data = await trackPromise(apiDataObject())

		const responseData = await trackPromise(
			API.get('bavApi', 'risk/survbenefit', data)
				.then((response: IRiskPartnerData) => response)
				.catch((err) => {
					console.log(err)
					return
				})
		)

		return responseData
	}

	const setRiskPartnerData = async (
		requestData: IRiskPartnerData
	): Promise<MfaResponseData | { error: ApiError }> => {
		const data = await trackPromise(apiDataObject({ body: { ...requestData } }))

		const responseData = await trackPromise(
			API.post('bavApi', 'risk/survbenefit', data)
				.then((response: MfaResponseData) => {
					if (true === response.mfaRequired) {
						auth.requireSecondFactor(response.transactionId)
					}

					return response
				})
				.catch((err) => {
					return err.response
				})
		)

		return responseData
	}

	const deleteRiskPartnerData = async (): Promise<MfaResponseData | { error: ApiError }> => {
		const data = await trackPromise(apiDataObject())

		const responseData = await trackPromise(
			API.del('bavApi', 'risk/survbenefit', data)
				.then((response: MfaResponseData) => {
					if (true === response.mfaRequired) {
						auth.requireSecondFactor(response.transactionId)
					}

					return response
				})
				.catch((err) => {
					return err.response
				})
		)

		return responseData
	}

	const getAppSetup = async (): Promise<IAppSetup | void> => {
		const data = await trackPromise(apiDataObject())

		const responseData = await trackPromise(
			API.get('authApi', 'appSetup', data)
				.then((response: IAppSetup) => response)
				.catch((err) => {
					console.log(err)
					return
				})
		)

		return responseData
	}

	const patchAppSetup = async (body: Partial<IUpdateAppSetup>): Promise<unknown | ApiError> => {
		const data = await apiDataObject({ body })

		const responseData = await trackPromise(
			API.patch('authApi', 'appSetup', data)
				.then((response: unknown) => response)
				.catch((err) => {
					console.log(err)
					return
				})
		)

		return responseData
	}

	const getPayoutYears: QueryFunction<
		IPayoutInterval | void,
		[QueryKey.payoutYears, { interval: 10 | 20 | 1 }]
	> = async ({ queryKey }) => {
		const [, queryStringParameters] = queryKey

		const data = await trackPromise(apiDataObject({ queryStringParameters }))
		const years = trackPromise(
			API.get('payoutModelApi', 'auszahlungsoptionen/modelle/jahresraten', data)
				.then((response) => response)
				.catch((err) => console.log(err))
		)
		return years
	}

	const getMixedPayoutYears: QueryFunction<
		{
			lumpSum: IPayoutInterval
			instalments20Years: IPayoutInterval
			instalments10Years: IPayoutInterval
			pension: IPayoutInterval
		} | void,
		[
			QueryKey.mixedPayoutYears,
			{ lumpSum: number; instalments20Years: number; instalments10Years: number; pension: number }
		]
	> = async ({ queryKey }) => {
		const [, queryStringParameters] = queryKey

		const data = await trackPromise(apiDataObject({ queryStringParameters }))
		const years = trackPromise(
			API.get('payoutModelApi', 'auszahlungsoptionen/modelle/mischauszahlung', data)
				.then((response) => response)
				.catch((err) => console.log(err))
		)
		return years
	}

	const getBankName: QueryFunction<
		{ bankname?: string; isValid?: boolean; belongsToSepa?: boolean; errorMessage?: string } | void,
		[QueryKey.bankName, { iban: string }]
	> = async ({ queryKey }) => {
		const [, queryStringParameters] = queryKey

		const data = await trackPromise(apiDataObject({ queryStringParameters }))
		const bankName = trackPromise(
			API.get('payoutModelApi', 'auszahlungsoptionen/bankname', data)
				.then((response) => response)
				.catch((err) => console.log(err))
		)
		return bankName
	}

	const getPayoutTypes = async (): Promise<{
		leistungsartTypes: readonly ('Ruhestand' | 'VorgezogenerRuhestand' | 'Erwerbsminderung')[]
		ruhestandAvailabilityYear: number
	}> => {
		const data = await apiDataObject()
		const payoutModel = trackPromise(
			API.get('payoutModelApi', 'auszahlungsoptionen/leistungsarten', data)
				.then((response) => response)
				.catch((err) => console.log(err))
		)
		return payoutModel
	}

	const confirmMixedPayout = async (payouts: any): Promise<{}> => {
		const data = await apiDataObject()
		const params = new URLSearchParams(payouts).toString()
		const request = trackPromise(
			API.get('payoutModelApi', `auszahlungsoptionen/modelle/mischauszahlung?${params}`, data)
				.then((response) => response)
				.catch((err) => console.log(err))
		)
		return request
	}

	const getCountriesOptions = async (): Promise<{ name: string; callingCode: string }[] | void> => {
		const data = await apiDataObject()
		const countries = trackPromise(
			API.get('payoutModelApi', 'auszahlungsoptionen/countries', data)
				.then((response) => response)
				.catch((err) => console.log(err))
		)
		return countries
	}

	const getPensionBalance = async (): Promise<{
		totalAmount: number
		guaranteedAdmount: number
		date: 'string'
		contributionComposition: {
			ownContribution: number
			ownAvwlContribution: number
			employerContribution: number
		}
	}> => {
		const data = await apiDataObject()
		const payoutModel = trackPromise(
			API.get('payoutModelApi', 'auszahlungsoptionen/versorgungsguthaben', data)
				.then((response) => response)
				.catch((err) => console.log(err))
		)
		return payoutModel
	}

	const getPensionDate: QueryFunction<
		{ austrittsdatum: string | null; rewritable: boolean } | void,
		[
			QueryKey.pensionDate,
			{
				leistungsart: 'Ruhestand' | 'VorgezogenerRuhestand' | 'Erwerbsminderung'
			}
		]
	> = async ({
		queryKey,
	}): Promise<{
		austrittsdatum: string
		rewritable: boolean
	}> => {
		const [, queryStringParameters] = queryKey
		const data = await apiDataObject({ queryStringParameters })
		const responseData = trackPromise(
			API.get('payoutModelApi', 'auszahlungsoptionen/austrittsdatum', data)
				.then((response) => response)
				.catch((err) => console.log(err))
		)
		return responseData
	}

	const getAvailablePayoutModels = async (): Promise<{
		lumpSum: boolean
		pension: boolean
		instalments10Years: boolean
		instalments20Years: boolean
		mixed: boolean
		restrictionReason: 'pensionAssetsTooLow' | 'erwerbsminderungBefristet' | 'leavingDateTooShortTerm'
	}> => {
		const data = await apiDataObject()
		const responseData = trackPromise(
			API.get('payoutModelApi', 'auszahlungsoptionen/modelle', data)
				.then((response) => response)
				.catch((err) => console.log(err))
		)
		return responseData
	}

	const getPayoutModelInProgress = async (): Promise<
		components['schemas']['AuszahlungsoptionenArbeitskopie'] | void
	> => {
		const data = await apiDataObject()

		const payoutModel = trackPromise(
			API.get('payoutModelApi', 'auszahlungsoptionen/arbeitskopie', data)
				.then((response) => response)
				.catch((err) => console.log(err))
		)
		return payoutModel
	}

	const getConfirmedPayoutModel = async (): Promise<components['schemas']['Auszahlungsoptionen'] | void> => {
		const data = await apiDataObject()

		const payoutModel = trackPromise(
			API.get('payoutModelApi', 'auszahlungsoptionen', data)
				.then((response) => response)
				.catch((err) => console.log(err))
		)
		return payoutModel
	}

	const getPayoutModelTransactionId = async (): Promise<{ transactionId: string }> => {
		const data = await apiDataObject()

		const transactionId = trackPromise(
			API.get('payoutModelApi', 'auszahlungsoptionen/arbeitskopie/bestaetigung/transactionId', data)
				.then((response) => {
					auth.requireSecondFactor(response.transactionId)

					return response
				})
				.catch((err) => console.log(err))
		)

		return transactionId
	}

	const confirmPayoutModel = async (transactionId: string): Promise<{ transactionId: string }> => {
		const data = await apiDataObject({ queryStringParameters: { transactionId } })

		const response = trackPromise(
			API.post('payoutModelApi', 'auszahlungsoptionen/arbeitskopie/bestaetigung', data)
				.then((response) => {
					return response
				})
				.catch((err) => console.log(err))
		)

		return response
	}

	const payoutModelConfirmationDate = async (): Promise<{ confirmedAt: string }> => {
		const data = await apiDataObject()

		const response = trackPromise(
			API.get('payoutModelApi', 'auszahlungsoptionen/lastconfirmationdate', data)
				.then((response) => {
					return response
				})
				.catch((err) => console.log(err))
		)

		return response
	}

	const getPayoutModelDocuments = async (): Promise<components['schemas']['FileData'][] | void> => {
		const data = await apiDataObject()

		const payoutModel = trackPromise(
			API.get('payoutModelApi', 'auszahlungsoptionen/arbeitskopie/files', data)
				.then((response) => response)
				.catch((err) => console.log(err))
		)
		return payoutModel
	}

	const getPayoutModelThumbnail = async (s3Path: string): Promise<{ image: string } | void> => {
		const data = await apiDataObject({ queryStringParameters: { s3Path: s3Path } })

		const payoutModel = trackPromise(
			API.get('payoutModelApi', 'auszahlungsoptionen/arbeitskopie/file/thumbnail', data)
				.then((response) => response)
				.catch((err) => console.log(err))
		)
		return payoutModel
	}

	const getPayoutModelDocumentLink = async (s3Key: string): Promise<{ url: string } | void> => {
		const data = await apiDataObject({ queryStringParameters: { s3Path: s3Key } })

		const fileUrl = trackPromise(
			API.get('payoutModelApi', 'auszahlungsoptionen/files/download', data)
				.then((response) => response)
				.catch((err) => console.log(err))
		)

		return fileUrl
	}

	const getThreadReport = async (fileId: string): Promise<{ isFileHarmful: boolean } | void> => {
		const data = await apiDataObject({ queryStringParameters: { fileId: fileId } })

		const fileUrl = trackPromise(
			API.get('payoutModelApi', 'auszahlungsoptionen/arbeitskopie/file/threatreport', data)
				.then((response) => response)
				.catch((err) => console.log(err))
		)

		return fileUrl
	}

	const patchPayoutModel = async (
		body: components['schemas']['AuszahlungsoptionenArbeitskopie']
	): Promise<components['schemas']['AuszahlungsoptionenArbeitskopie'] | void> => {
		const data = await apiDataObject({ body })

		const payoutModel = trackPromise(
			API.patch('payoutModelApi', 'auszahlungsoptionen/arbeitskopie', data)
				.then((response) => response)
				.catch((err) => err)
		)

		return payoutModel
	}

	const isPayoutModelEditable = async (): Promise<{ editable: boolean }> => {
		const data = await apiDataObject()

		const payoutModel = trackPromise(
			API.get('payoutModelApi', 'auszahlungsoptionen/editable', data)
				.then((response) => response)
				.catch((err) => err)
		)

		return payoutModel
	}

	const getTermsOfServiceConsent: QueryFunction<
		{
			newConsentNeeded: boolean
			usageOfContactDataAccepted: boolean
		} | void,
		[QueryKey.termsOfServe, { currentVersion: string }]
	> = async ({ queryKey }) => {
		const [, queryStringParameters] = queryKey
		const data = await apiDataObject({
			queryStringParameters,
		})

		const response = API.get('payoutModelApi', 'nutzungsbedingungen', data)

		return response
	}

	const putTermsOfServiceConsent = async (body: {
		termsOfServiceAccepted: boolean
		usageOfContactDataAccepted?: boolean
	}): Promise<any> => {
		const data = await apiDataObject({ body })

		try {
			const response = API.put('payoutModelApi', 'nutzungsbedingungen', data)
			return response
		} catch (e) {
			console.log(e)
			return false
		}
	}

	const getCurrentCognitoUserPool = async (params: { identNumber: string }): Promise<string> => {
		const apiConfig = awsConfig?.aws_cloud_logic_custom.find((v) => {
			return v.name === 'payoutModelApi'
		})

		if (!apiConfig?.endpoint) return ''

		try {
			const response = await fetch(apiConfig?.endpoint + 'auth/migration?' + new URLSearchParams(params), {
				method: 'GET',
				headers: {
					'x-api-key': process.env.REACT_APP_APIGW_API_KEY || '',
				},
			})

			if (response.status === 200 && response.body) {
				const data = await response.json()

				return data.userPoolId
			}

			return ''
		} catch (e) {
			return ''
		}
	}

	const sendPasswordForMigration = async (password: string): Promise<{ success: boolean; errorCode?: string }> => {
		const data = await trackPromise(apiDataObject({ body: { currentPassword: password } }))

		let errorCode: string | undefined = undefined
		let success: boolean | undefined

		try {
			await trackPromise<{ currentPassword: string }>(API.post('payoutModelApi', `auth/migration`, data))

			success = true
		} catch (e: any) {
			errorCode = e?.response?.status === 401 ? 'PASSWORD_INCORRECT' : 'undefined'
			success = false
		}

		return { success, errorCode }
	}

	const uploadFile = async (
		file: File,
		url: string,
		type: string,
		onProgress: any,
		fileIndex: number,
		cancelToken: any
	): Promise<any> => {
		const headers = {
			'Content-Type': type,
		}

		console.log({ file, url, type, onprogress, fileIndex })
		const upload = trackPromise(
			axios
				.put(url, file, {
					headers,
					cancelToken: cancelToken,
					onUploadProgress: (progressEvent) => {
						onProgress(progressEvent.loaded, fileIndex)
					},
				})
				.then((response) => response)
				.catch((err) => err)
		)
		console.log(upload)
		return upload
	}

	const deleteFile = async (key: string): Promise<any> => {
		const data = await trackPromise(apiDataObject({ queryStringParameters: { key: key } }))

		const uploadUrl = trackPromise(
			API.del('payoutModelApi', `auszahlungsoptionen/arbeitskopie/file`, data)
				.then((response) => response)
				.catch((err) => err)
		)

		return uploadUrl
	}

	const getUploadUrl = async (params: {
		type: components['schemas']['DocumentType']
		mimeType: components['schemas']['MimeType']
	}): Promise<components['schemas']['AuszahlungsoptionUploadUrl']> => {
		const data = await trackPromise(apiDataObject({ queryStringParameters: params }))

		const uploadUrl = trackPromise(
			API.get('payoutModelApi', `auszahlungsoptionen/arbeitskopie/files/uploadUrl`, data)
				.then((response) => response)
				.catch((err) => err)
		)

		return uploadUrl
	}

	const changeConctactDetails = async (
		body: ChangeUserContactArguments
	): Promise<ChangeUserContactStatus | false> => {
		try {
			const data = await trackPromise(apiDataObject({ body }))
			const response = await trackPromise(
				API.post('payoutModelApi', `auth/contactdetails`, data)
					.then((response) => response)
					.catch((err) => err)
			)

			console.log('apiResponse', response)

			return response
		} catch (e: any) {
			return false
		}
	}

	/**
	 * list of prefetchable queries
	 */
	const queries: {
		[key in QueryKey]?: Query<{ [key: string]: string | number | boolean }>
	} = {
		[QueryKey.compensationTypes]: { apiCall: getCompensationTypes },
		[QueryKey.bausteinKonto]: { apiCall: getBausteinKonto },
		[QueryKey.allCompensations]: { apiCall: getCompensation },
		[QueryKey.fundAllocation]: { apiCall: getFundAllocation },
		[QueryKey.payoutModelInProgress]: {
			apiCall: getPayoutModelInProgress,
		},

		[QueryKey.fundPerformance]: {
			apiCall: getFundPerformance,
			queryParameters: getLocalStorage('fundQueryStringParameters', {
				history: 30,
				compareIndice: 'dax',
			}),
			variables: { area: LoadingSpinnerArea.indiceComparisonDashboard },
		},
		[QueryKey.fundPerformanceIndices]: { apiCall: getIndices },
		[QueryKey.fundPerformanceTimeRanges]: { apiCall: getIndiceTimeRanges },
		[QueryKey.pensionAssets]: {
			apiCall: getPensionAssets,
			queryParameters: getLocalStorage('pensionAssetsStringParameters', {}),
			variables: { area: LoadingSpinnerArea.pensionAssetsDashboard },
		},
		[QueryKey.pensionAssetsViewIntervals]: { apiCall: getPensionAssetsViewIntervals },
		[QueryKey.pensionAssetsReferenceYears]: { apiCall: getPensionAssetsReferenceYears },
		[QueryKey.documents]: { apiCall: getDocuments },
		[QueryKey.userProfile]: { apiCall: getUserProfile },
		[QueryKey.riskOptions]: { apiCall: getRiskOptions },
		[QueryKey.riskOptionsAvailable]: { apiCall: getRiskOptionsAvailable },
	}

	// const mutations: {
	// 	[key in MutationKey]?: Query<{ [key: string]: string | number | boolean }>
	// } = {
	// 	[MutationKey.patchPayoutModel]: { apiCall: patchPayoutModel },
	// }

	// const getMutation = (
	// 	key: MutationKey,
	// 	queryParameters?: { [key: string]: string | number },
	// 	variables?: { [key: string]: undefined | string | number | boolean }
	// ): {
	// 	queryKey: (
	// 		| {
	// 				[key: string]: string | number
	// 		  }
	// 		| MutationKey
	// 	)[]
	// 	queryFn: ((variables?: any) => void) | undefined
	// } => {
	// 	const mutation = mutations[key]
	// 	const queryKey =
	// 		undefined !== mutation?.queryParameters ? [key, queryParameters || mutation?.queryParameters] : [key]

	// 	return {
	// 		queryKey: QueryKey,
	// 		queryFn: mutation?.apiCall.bind(null, { queryKey, pageParam: { ...variables } }),
	// 	}
	// }

	const getQuery = (
		key: QueryKey,
		queryParameters?: { [key: string]: string | number },
		variables?: { [key: string]: undefined | string | number | boolean }
	): {
		queryKey: (
			| {
					[key: string]: string | number
			  }
			| QueryKey
		)[]
		queryFn: ((variables?: any) => void) | undefined
	} => {
		const query = queries[key]
		const queryKey = undefined !== query?.queryParameters ? [key, queryParameters || query?.queryParameters] : [key]

		return {
			queryKey: queryKey,
			queryFn: query?.apiCall.bind(null, { queryKey, pageParam: { ...variables } }),
		}
	}

	return {
		apiDataObject,
		confirmMixedPayout,
		deleteFile,
		queries,
		confirmPayoutModel,
		getQuery,
		getCompensationTypes,
		getAvailablePayoutModels,
		getPayout,
		getFundAllocation,
		getCompensation,
		setCompensationSalary,
		setCompensationBonus,
		getFundPerformance,
		getPayoutTypes,
		getPayoutYears,
		getIndices,
		patchPayoutModel,
		getIndiceTimeRanges,
		getSingleCompensation,
		getPensionAssets,
		getBausteinKonto,
		getPayoutModelDocumentLink,
		getPayoutModelThumbnail,
		getPensionAssetsReferenceYears,
		getPensionAssetsViewIntervals,
		getPensionDate,
		getCompensationSimulationBonus,
		getCompensationSimulationSalary,
		getCountriesOptions,
		getBankName,
		getUserProfile,
		setUserProfile,
		terminateDigitalParticipation,
		getDocuments,
		getDocumentLink,
		getBonusMonthList,
		getPayoutModelDocuments,
		getCompensationSimulationSettings,
		getUserAttributes,
		requestResetPassword,
		resetPassword,
		updatePassword,
		uploadFile,
		setMfa,
		getPayoutModelInProgress,
		getConfirmedPayoutModel,
		getPayoutModelTransactionId,
		getThreadReport,
		getMixedPayoutYears,
		getPensionBalance,
		getUploadUrl,
		getRiskOptions,
		getRiskOptionsAvailable,
		getRiskOptionsTypes,
		getRiskSimulationCompensation,
		getRiskSimulationBenefit,
		getRiskOptionsYears,
		getRiskPartnerData,
		getTermsOfServiceConsent,
		putTermsOfServiceConsent,
		setRiskPartnerData,
		deleteRiskPartnerData,
		setRiskOption,
		sendPasswordForMigration,
		getAppSetup,
		patchAppSetup,
		payoutModelConfirmationDate,
		getCurrentCognitoUserPool,
		getLocalAWSConfig,
		isPayoutModelEditable,
		changeConctactDetails,
	}
}

export default useApi
