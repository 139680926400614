import React from 'react'
import SelectableBarGroup from 'shared/components/SelectableBarGroup'
import { BonusPayout } from 'views/DeferredCompensation/SelectBonusAmount'

export interface SalaryBonusSelectProps {
	options: BonusPayout[]
	selectedOption: any
	onClick: any
}

const SalaryBonusSelect: React.FC<SalaryBonusSelectProps> = props => {
	const selectableOptions = props.options.map((element, index) => {
		return {
			label: element.title,
			value: element.id,
			icon: element.icon,
			onClick: () => {
				props.onClick(element)
			},
		}
	})

	return (
		<SelectableBarGroup
			className="deferred-compensation__type"
			options={selectableOptions}
			selectedOption={{
				...props.selectedOption,
				value: props.selectedOption && props.selectedOption.id ? props.selectedOption.id : false,
			}}
		/>
	)
}

export default SalaryBonusSelect
