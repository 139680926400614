import PayoutModelList from 'components/PayoutModelList'
import useApi, { QueryKey } from 'hooks/useApi'
import { useUserGroup } from 'hooks/useUserGroup'
import { FunctionComponent, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'
import { components } from 'types/api-interface'

const PayoutEmployeeContribution: FunctionComponent<{ reduction: string | undefined }> = ({ reduction }) => {
	const { navigateTo, getChildPath } = useRouteHelper()
	const api = useApi()
	const queryClient = useQueryClient()
	useBackButtonPath(getChildPath(routesDictionary.payoutOptions, 'payoutReduction'))
	const { isVO20, isHigherUp } = useUserGroup()
	const transkey = isHigherUp ? 'EMGGET' : isVO20 ? 'Vo20' : ''
	const { data } = useQuery(QueryKey.payoutModelInProgress, api.getPayoutModelInProgress)

	const [value, setValue] = useState<string | undefined>()
	const mutation = useMutation(api.patchPayoutModel, {
		onSuccess: (payload) => {
			// console.log('payload', payload)
			localStorage.removeItem('reduction-type')
			localStorage.removeItem('benefit-type')

			queryClient.setQueryData(QueryKey.payoutModelInProgress, { ...payload })
			navigateTo(`${getChildPath(routesDictionary.payoutOptions, 'payoutModel')}`)
		},
	})

	useNavigationTitle(
		<Trans i18nKey={`view.payoutOptions.chapterOne.payoutReduction.options.${reduction}.headline`} />
	)

	const { t } = useTranslation()

	const preCheckedValue: boolean | null = useMemo(() => {
		return !data?.leistungsart || data.leistungsart.type !== 'Erwerbsminderung'
			? null
			: data.leistungsart.payoutEmployeeContribution
	}, [data])

	const items = [
		{
			value: 'true',
			label: t('view.payoutOptions.chapterOne.payoutEmployeeContribution.options.yes'),
			subLabel: t('view.payoutOptions.chapterOne.payoutEmployeeContribution.options.yesSubheadline' + transkey),
			description: '',
			checked: preCheckedValue === true,
		},
		{
			value: 'false',
			label: t('view.payoutOptions.chapterOne.payoutEmployeeContribution.options.no'),
			subLabel: t('view.payoutOptions.chapterOne.payoutEmployeeContribution.options.yesSubheadline' + transkey),
			description: '',
			checked: preCheckedValue === false,
		},
	]

	const onClick = (): void => {
		if (!reduction || !value) {
			return
		}
		const payload: components['schemas']['AuszahlungsoptionLeistungErwerbsminderung'] = {
			type: 'Erwerbsminderung',
			erwerbsminderungType:
				reduction as components['schemas']['AuszahlungsoptionLeistungErwerbsminderung']['erwerbsminderungType'],
			payoutEmployeeContribution: value === 'true' ? true : false,
		}

		mutation.mutate({ leistungsart: payload })
	}

	return (
		<div className="center-view">
			<h3 className="font-family-alternative--light">
				<Trans i18nKey="view.payoutOptions.chapterOne.payoutEmployeeContribution.intro"></Trans>
			</h3>
			{data && (
				<PayoutModelList
					className="grid__center"
					items={items}
					onChange={(v) => {
						setValue(v)
					}}
				/>
			)}
			<div className="padding padding--top">
				<Button
					className="grid__bottom"
					type={ButtonType.primary}
					label={t('view.payoutOptions.chapterOne.payoutEmployeeContribution.button')}
					onClick={onClick}
				/>
			</div>
		</div>
	)
}

export default PayoutEmployeeContribution
