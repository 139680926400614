import { IconType } from 'components/Icons'
import useApi from 'hooks/useApi'
import { useAuth } from 'hooks/useAuth'
import { useAWSConfig } from 'hooks/useAwsConfig'
import { useQueryParams } from 'raviger'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import Form, { FormFields, FormFieldType } from 'shared/components/Form'
import Icon, { IconSize } from 'shared/components/Icon'
import { TextInputType } from 'shared/components/TextInput'
import isDev from 'shared/helper/isDev'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useResetHeaderState } from 'state/useHeaderState'

const PasswordReset: React.FC = () => {
	useResetHeaderState()
	const { isSuccess: AWSConfigLoaded } = useAWSConfig()

	const [token, setToken] = useState()
	const [queryParams, setQueryParams] = useQueryParams()
	const api = useApi()
	const auth = useAuth()
	const { t } = useTranslation()
	const [mailSent, setMailSent] = useState<boolean>(false)
	const [tokenStatus, setTokenStatus] = useState<{ [key: string]: any }>()
	const { getMainPath, navigateTo } = useRouteHelper()

	const inputElements: FormFields = {
		userName: {
			value: isDev() ? '12343211' : '',
			required: true,
			type: TextInputType.text,
			autoComplete: 'username',
			label: t('view.passwordReset.formFieldLabels.idOrEmail'),
			fieldType: FormFieldType.text,
		},
		captchaResponseToken: {
			required: true,
			type: TextInputType.hidden,
			fieldType: FormFieldType.text,
		},
	}

	useEffect(() => {
		if (!AWSConfigLoaded) {
			return
		}

		if (!auth.amplifyInitialized) {
			auth.initializeAmplify(process.env.REACT_APP_OLD_AWS_USERPOOL)
			return
		}

		sendToken()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token, auth.amplifyInitialized, AWSConfigLoaded])

	useEffect(() => {
		const pwToken = queryParams.passwordResetToken

		if (pwToken && pwToken !== token) {
			setToken(pwToken)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParams])

	const sendToken = async () => {
		const passwordResetToken = token

		if (undefined === passwordResetToken) {
			return
		}

		const response = await api.resetPassword({ passwordResetToken })

		if (true !== response) {
			setTokenStatus({ success: false, errorCode: (response as any)?.data?.code })
		} else {
			setTokenStatus({ success: true })
		}
	}

	if (queryParams.passwordResetToken) {
		return (
			<>
				<div className="center-view flex flex--direction-column flex--align-items-center">
					<div className="page-title">
						<h1>
							<Trans i18nKey="view.passwordReset.pageTitle.temporaryPasswordSent" />
						</h1>
					</div>
					<Icon
						className="margin--vertical margin--large no-margin--top"
						type={IconType.mail}
						color="var(--color-cyan)"
						size={IconSize.extraLarge}
					/>
					{tokenStatus && false === tokenStatus.success && (
						<>
							<p>
								<Trans
									i18nKey={`apiErrors.${tokenStatus.errorCode}`}
									values={{ error: tokenStatus.errorCode }}
								/>
							</p>
							<Button
								type={ButtonType.secondary}
								label={t('view.passwordReset.buttonLabel.tryAgain')}
								onClick={() => {
									setQueryParams({ passwordResetToken: undefined })
									setMailSent(false)
								}}
							/>
						</>
					)}
					{tokenStatus && true === tokenStatus.success && (
						<Button
							type={ButtonType.primary}
							label={t('view.passwordReset.buttonLabel.login')}
							onClick={() => navigateTo(getMainPath(routesDictionary.login), false, {})}
						/>
					)}
				</div>
			</>
		)
	}

	if (true === mailSent) {
		return (
			<>
				<div className="center-view flex flex--direction-column flex--align-items-center">
					<div className="page-title">
						<h1>
							<Trans i18nKey="view.passwordReset.pageTitle.mailSent" />
						</h1>
					</div>
					<Icon
						className="margin--vertical margin--large no-margin--top"
						type={IconType.mail}
						color="var(--color-cyan)"
						size={IconSize.extraLarge}
					/>
					<Button
						type={ButtonType.secondary}
						label={t('view.passwordReset.buttonLabel.noEmailReceived')}
						onClick={() => setMailSent(false)}
					/>
				</div>
			</>
		)
	}

	return (
		<>
			<div className="form-view">
				<div className="form-view__title">
					<h1>
						<Trans i18nKey="view.passwordReset.pageTitle.default" />
					</h1>
				</div>
				<Form
					className="form-view"
					fields={inputElements}
					onSubmit={async (fields: { captchaResponseToken: string; userName: string }) => {
						try {
							const { isOldUserPool, userPoolId } = await auth.getUserPoolId(fields.userName, true)

							await auth.initializeAmplify(userPoolId)

							if (isOldUserPool) {
								console.log('is old userpool')
								const response = await api.requestResetPassword({
									userName: fields.userName,
									captchaResponseToken: fields.captchaResponseToken,
								})

								return { sucessful: response }
							} else {
								console.log('is new userpool')
								navigateTo(
									`${getMainPath(routesDictionary.passwordResetV2)}?email=${encodeURIComponent(
										fields.userName
									)}`
								)
								return { successful: true }
							}
						} catch (e) {
							console.log(e)
						}
					}}
					onSuccess={() => setMailSent(true)}
					submitLabel={t('view.passwordReset.buttonLabel.confirm')}
					captchaFieldName={'captchaResponseToken'}
				/>
			</div>
		</>
	)
}

export default PasswordReset
