import classNames from 'classnames'
// import { HintsBox } from 'components/HintsBox'
import useApi, { QueryKey } from 'hooks/useApi'
// import { ChangeUserContactArguments } from 'graphql'
// import { AuthQueryKey } from 'hooks/useAuthApi'
// import { useChangeUserContactDetails } from 'hooks/useChangeUserContactDetails'
// import { useConfirmModal } from 'hooks/useConfirmModal'
import { t } from 'i18next'
import { useCallback, useState } from 'react'
import { Trans } from 'react-i18next'
import { trackPromise } from 'react-promise-tracker'
import { useQuery } from 'react-query'
import routesDictionary from 'routes'
import { ButtonType } from 'shared/components/Button'
import { ButtonWithCooldown } from 'shared/components/ButtonWithCooldown'
import Form, { FormFields, FormFieldType } from 'shared/components/Form'
import TextInput, { TextInputType } from 'shared/components/TextInput'
import numbersOnlyString from 'shared/helper/numbersOnlyString'
import { NoNullField } from 'shared/helper/types/noNullField'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { ChangeUserContactArguments, ChangeUserContactErrors } from 'shared/interfaces'
import { useBackButtonClickHandler } from 'state/useHeaderState'
import { supportedPhoneNumberCountries, phoneNumberDefaultCountry } from 'supported-phone-number-countries'
// import { supportedPhoneNumberCountries, phoneNumberDefaultCountry } from 'supported-phone-number-countries'

const BLOCKED_REGISTRATION_EMAIL_DOMAINS = process.env.REACT_APP_BLOCKED_REGISTRATION_EMAIL_DOMAINS
	? String(process.env.REACT_APP_BLOCKED_REGISTRATION_EMAIL_DOMAINS)
			.split(',')
			.map((domain) => `@${domain.trim()}`)
	: undefined

const ChangeEmail: React.FunctionComponent = () => {
	const { navigateTo, getChildPath } = useRouteHelper()
	const [showTanInput, setShowTanInput] = useState<boolean>(false)
	const [emailTan, setEmailTan] = useState<string | undefined>()
	const [email, setEmail] = useState<string | undefined>()

	const { changeConctactDetails, getUserAttributes } = useApi()

	const { data, refetch } = useQuery(QueryKey.cognitoUserAttributes, getUserAttributes)
	const oldEmail = data?.get('email') || ''

	// const handleOnCloseConfirmModal = async () => {
	// 	await queryClient.refetchQueries(AuthQueryKey.userMetaData)
	// 	navigateTo(getMainPath(routesDictionary.profile || ''))
	// }

	const showConfirmScreen = () => {
		refetch()
		navigateTo(getChildPath(routesDictionary.profile, 'changeEmailSuccess'))
	}

	useBackButtonClickHandler({
		name: navigateTo,
		parameters: '/profile/overview',
	})

	const handleOnEmailChange = useCallback(() => {
		if (showTanInput === false) {
			return
		}

		setEmail(undefined)
		setEmailTan(undefined)
		setShowTanInput(false)
	}, [showTanInput])

	const handleOnSubmit = async (submittedFields: NoNullField<ChangeUserContactArguments>) => {
		if (BLOCKED_REGISTRATION_EMAIL_DOMAINS?.some((domain) => submittedFields.email?.endsWith(domain))) {
			return {
				errorMessages: [t(`view.form.errors.email.NOT_PRIVATE`)],
				successful: false,
			}
		}

		const returnValue = {
			successful: true,
			errorMessages: [],
		}

		try {
			const response = await trackPromise(changeConctactDetails(submittedFields), 'change-user-details')
			const fieldKeys = Object.keys(submittedFields)
			/**
			 * needed since axios wont catch 400 errors
			 */
			if (response && 'isAxiosError' in response) {
				return { successful: false, errorMessages: [t(`apiErrors.UNKNOWN_ERROR`)] }
			}

			if (response !== false && response.errors) {
				const errors = response.errors

				fieldKeys.forEach((key) => {
					console.log(key)
					if (key in errors) {
						const fieldError =
							errors[key as Extract<keyof ChangeUserContactArguments, keyof ChangeUserContactErrors>]

						if (fieldError) {
							returnValue.successful = false
							returnValue.errorMessages.push(t(`generic.form.errors.${key}.${fieldError}`))
						}
					}
				})
			}

			//last request did not include tan -> enable tan input
			if (returnValue.successful && submittedFields.emailTan === undefined) {
				setEmail(submittedFields.email)
				setShowTanInput(true)
				return { abort: true }
			}

			return returnValue
		} catch (e) {
			return { successful: false }
		}
	}

	const handleOnResendTan = () => {
		if (!email) {
			return
		}

		handleOnSubmit({ email })
	}

	const inputElements: FormFields = {
		email: {
			required: true,
			type: TextInputType.email,
			label: t('view.profile.changeEmail.form.newEmail'),
			autoComplete: 'username email',
			fieldType: FormFieldType.text,
			onChange: handleOnEmailChange,
			placeholder: t('view.profile.changeEmail.placeholder'),
		},
		emailTan: {
			required: true,
			inputMode: 'decimal',
			pattern: '[0-9,.]*',
			label: t('view.profile.changeEmail.form.tanLabel'),
			autoComplete: 'one-time-code',
			fieldType: FormFieldType.text,
			valueFunction: {
				name: numbersOnlyString,
			},
			className: classNames({
				'visually-hidden': showTanInput !== true,
				'visually-hidden--false': showTanInput === true,
			}),
			disabled: showTanInput !== true,
			phoneInputProps: {
				countries: supportedPhoneNumberCountries,
				defaultCountry: phoneNumberDefaultCountry,
				addInternationalOption: false,
				international: true,
			},
			// icon: IconType.phone,
		},
	}

	return (
		<div className="center-view form-view profile-change-email">
			<h2>
				<Trans i18nKey="view.profile.changeEmail.changeEmail" />
			</h2>
			<div>
				<TextInput
					label={<Trans i18nKey="view.profile.changeEmail.savedEmail" />}
					value={oldEmail}
					disabled
				></TextInput>
			</div>

			<Form
				className="form-view"
				fields={inputElements}
				onSubmit={handleOnSubmit}
				onSuccess={showConfirmScreen}
				submitLabel={t('generic.send')}
				updatedValues={{ emailTan }}
				alwaysAllowSubmit
				promiseTracker={{ area: 'change-user-details' }}
			>
				<div className="form-view__bodytext margin--vertical">
					{!showTanInput && <Trans i18nKey={'view.profile.changeEmail.hintText'}></Trans>}
					{showTanInput && (
						<div className="margin--top">
							<Trans i18nKey="view.profile.changeEmail.form.hint" values={{ email }} />
							<ButtonWithCooldown
								className="margin--top"
								onClick={handleOnResendTan}
								type={[ButtonType.small, ButtonType.secondary]}
							>
								<Trans i18nKey={'view.profile.changeEmail.form.hintButton'}></Trans>
							</ButtonWithCooldown>
						</div>
					)}{' '}
				</div>
			</Form>
			{/* </div> */}
		</div>
	)
}

export default ChangeEmail
