import { IconType } from 'components/Icons'
import { deepEqual } from 'helper/deep-equal'
import useApi, { QueryKey } from 'hooks/useApi'
import { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import { uid } from 'react-uid'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import Icon, { IconSize } from 'shared/components/Icon'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'
import { components } from 'types/api-interface'

export enum ProgressKeys {
	CHAPTER_ONE_COMPLETED = 'chapter-one-completed',
	CHAPTER_TWO_COMPLETED = 'chapter-two-completed',
	CHAPTER_THREE_COMPLETED = 'chapter-three-completed',
}

export const progressSteps = [
	ProgressKeys.CHAPTER_ONE_COMPLETED,
	ProgressKeys.CHAPTER_TWO_COMPLETED,
	ProgressKeys.CHAPTER_THREE_COMPLETED,
]

export const payoutProgressGuard = (
	currentStep: ProgressKeys | null | undefined,
	requiredStep: ProgressKeys | undefined
) => {
	if (
		!currentStep ||
		!requiredStep ||
		!Object.values(ProgressKeys).includes(currentStep) ||
		!Object.values(ProgressKeys).includes(currentStep)
	) {
		return false
	}

	return progressSteps.indexOf(currentStep) >= progressSteps.indexOf(requiredStep)
}

const PayoutOverview = () => {
	const { getMainPath, navigateTo, getChildPath } = useRouteHelper()

	useBackButtonPath(getMainPath(routesDictionary.dashboard))
	useNavigationTitle(<Trans i18nKey="view.payoutOptions.payoutTypes.pageTitle" />)
	const queryClient = useQueryClient()
	const api = useApi()
	const [pendingChanges, setPendingChanges] = useState(false)

	const { data, status } = useQuery(QueryKey.payoutModelInProgress, api.getPayoutModelInProgress)
	const confirmedPayoutmodelDate = useQuery(QueryKey.payoutModelConfirmationDate, api.payoutModelConfirmationDate)
	const confirmedPayoutModel = useQuery(QueryKey.confirmedPayoutModel, api.getConfirmedPayoutModel)
	const { data: isEditable } = useQuery(QueryKey.payoutModelEditable, api.isPayoutModelEditable)

	const filesQuery = useQuery(QueryKey.payoutDocuments, api.getPayoutModelDocuments)

	useEffect(() => {
		confirmedPayoutModel.refetch()
	}, [])

	const hasNewFiles = useMemo(() => {
		if (!confirmedPayoutmodelDate.data?.confirmedAt || !filesQuery.data) {
			return undefined
		}

		const newFile = filesQuery.data
			.filter((d) => !d.fileConfirmedAt)
			.find((v) => {
				return new Date(confirmedPayoutmodelDate.data.confirmedAt) < new Date(v.fileCreatedAt)
			})

		return !!newFile
	}, [confirmedPayoutmodelDate, filesQuery])
	useEffect(() => {
		if (data && confirmedPayoutModel.data && filesQuery.data && confirmedPayoutmodelDate.data) {
			const { progressKey, ...dataWithoutProgressKey } = data
			setPendingChanges(!deepEqual(confirmedPayoutModel.data, dataWithoutProgressKey) || !!hasNewFiles)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, confirmedPayoutModel.data, filesQuery.data, confirmedPayoutmodelDate.data])

	const { t } = useTranslation()

	const pensionConfirmationRequired = () => {
		const type = data?.leistungsart?.type || false

		if (!type) {
			return false
		}

		return type === 'VorgezogenerRuhestand' || type === 'Erwerbsminderung'
	}

	const insuranceConfirmationRequired = () => {
		const type = data?.krankenkasse?.type || false

		if (!type) {
			return false
		}

		return type === 'privat'
	}

	const containsFileType = (type: components['schemas']['DocumentType']): boolean => {
		// do not count allready merged files, as they will stay inside the documents list even when theusers deletes them
		// FIXME: `merge` does not exist in schema. Is it only missing?!
		const filterdData = filesQuery?.data?.filter((v) => v.uploadStatus === 'finished' && !(v as any).merged)

		if (filterdData?.length === 0 || !type) {
			return false
		}

		const result = filterdData?.filter((v) => {
			return v.fileName?.includes(type) || false
		})

		return (result && result.length > 0) || false
	}

	const getChapterOneResults = () => {
		if (data?.modell) {
			return (
				<>
					{data?.leistungsart?.type === 'Erwerbsminderung'
						? t(
								`view.payoutOptions.chapterOne.payoutReduction.options.${data.leistungsart.erwerbsminderungType}.headline`
						  )
						: ''}
					<Trans
						i18nKey={
							'view.payoutOptions.payoutConfirmation.chapterOne.' + data?.leistungsart?.type + '.name'
						}
					></Trans>

					{'type' in data.modell
						? `, ${t(`view.payoutOptions.chapterOne.payoutModelSummary.${data.modell.type}`)}`
						: ''}
				</>
			)
		}
	}

	const pensionFilesMissing = () => {
		return pensionConfirmationRequired() && !containsFileType('RENTENBESCHEINIGUNG')
	}

	const insuranceFilesMissing = () => {
		return insuranceConfirmationRequired() && !containsFileType('KRANKENVERSICHERUNG')
	}

	const bankFilesMissing = () => {
		return !containsFileType('BANKVERBINDUNG')
	}

	const applicationSuccessfull =
		confirmedPayoutmodelDate.status === 'success' && confirmedPayoutmodelDate.data !== undefined

	const anyFileMissing = (): boolean => {
		if (pensionFilesMissing() || insuranceFilesMissing() || bankFilesMissing()) {
			return true
		} else {
			return false
		}
	}

	const getMissingFilesInfo = () => {
		if (filesQuery.isFetching) {
			return [<p key={'documents-loading-1'}>Dateien werden geladen</p>]
		}

		const messages: string[] = []

		if (pensionFilesMissing()) {
			messages.push(t('view.payoutOptions.chapterThree.documents.pension.missing'))
		}

		if (insuranceFilesMissing()) {
			messages.push(t('view.payoutOptions.chapterThree.documents.insurance.missing'))
		}

		if (bankFilesMissing()) {
			messages.push(t('view.payoutOptions.chapterThree.documents.bankAccount.missing'))
		}

		if (messages.length === 0) {
			return t('view.payoutOptions.chapterThree.documents.misc.complete')
		}

		return messages.map((string, i) => (
			<div key={'missing-file-mssage-' + i} className="is-missing">
				{string}
			</div>
		))
	}

	const firstChapterComplete = payoutProgressGuard(
		data?.progressKey as ProgressKeys,
		ProgressKeys.CHAPTER_ONE_COMPLETED
	)

	const firstChapterInEditProgress =
		firstChapterComplete && data?.leistungsart && Object.keys(data?.modell || {}).length === 0

	const secondChapterComplete = payoutProgressGuard(
		data?.progressKey as ProgressKeys,
		ProgressKeys.CHAPTER_TWO_COMPLETED
	)
	const thirdChapterComplete =
		payoutProgressGuard(data?.progressKey as ProgressKeys, ProgressKeys.CHAPTER_THREE_COMPLETED) &&
		!anyFileMissing()

	const options = [
		{
			label: <Trans i18nKey="view.payoutOptions.payoutTypes.options.first.headline" />,
			subLabel: firstChapterComplete ? (
				getChapterOneResults()
			) : (
				<Trans i18nKey="view.payoutOptions.payoutTypes.options.first.subheadline" />
			),
			destination: 'benefitType',
			completed: firstChapterComplete && !firstChapterInEditProgress,
		},
		{
			label: <Trans i18nKey="view.payoutOptions.payoutTypes.options.second.headline" />,
			subLabel: secondChapterComplete ? (
				(data?.identity?.name.firstName || '') + ' ' + (data?.identity?.name.lastName || '')
			) : (
				<Trans i18nKey="view.payoutOptions.payoutTypes.options.second.subheadline" />
			),
			destination: 'payoutModelInputForm',
			completed: secondChapterComplete,
			disabled: !firstChapterComplete,
		},
		{
			label: <Trans i18nKey="view.payoutOptions.payoutTypes.options.third.headline" />,
			subLabel: secondChapterComplete ? (
				getMissingFilesInfo()
			) : (
				<Trans i18nKey="view.payoutOptions.payoutTypes.options.third.subheadline" />
			),
			destination: 'payoutDocuments',
			completed: thirdChapterComplete,
			disabled: !secondChapterComplete,
		},
	]

	useEffect(() => {
		queryClient.prefetchQuery(QueryKey.payoutTypes, api.getPayoutTypes)
	})

	const allowContinue = thirdChapterComplete && !anyFileMissing() && !firstChapterInEditProgress

	const completeChapter = () => {
		if (firstChapterInEditProgress) {
			navigateTo(getChildPath(routesDictionary.payoutOptions, 'payoutModel'))
			return
		}
		if (!firstChapterComplete) {
			navigateTo(getChildPath(routesDictionary.payoutOptions, 'benefitType'))
			return
		}

		if (!secondChapterComplete) {
			navigateTo(getChildPath(routesDictionary.payoutOptions, 'payoutModelInputForm') + '/0')
			return
		}

		if (!thirdChapterComplete) {
			navigateTo(getChildPath(routesDictionary.payoutOptions, 'payoutDocuments'))
		}
	}

	const confirmationDate = confirmedPayoutmodelDate.data?.confirmedAt
		? new Date(confirmedPayoutmodelDate.data?.confirmedAt).toLocaleDateString()
		: ''

	return (
		<div className="grid center-view overview">
			{!applicationSuccessfull && (
				<div className="grid__top">
					<p>
						<Trans i18nKey="view.payoutOptions.payoutTypes.introText"></Trans>
					</p>
				</div>
			)}

			{/**TODO: turn infobox into component */}
			{applicationSuccessfull && (
				<div className="info-box">
					<Icon type={IconType.success} size={IconSize.medium} color={'var(--color-cyan)'}></Icon>
					<p className="text-color-blue margin--vertical margin--small">
						<Trans
							i18nKey="view.payoutOptions.payoutTypes.successMessage"
							values={{ date: confirmationDate }}
						></Trans>
						{!isEditable?.editable && (
							<Trans i18nKey="view.payoutOptions.payoutTypes.notEditableMessage"></Trans>
						)}
					</p>
				</div>
			)}

			{!data && status === 'success' && (
				<div className="margin margin--top text-align--center">
					<Trans i18nKey="apiErrors.UNKNOWN_ERROR"></Trans>
				</div>
			)}

			{data && (
				<div className="grid__center">
					<div className="overview__buttons margin--top margin--large">
						{options.map((v) => (
							<div
								className={`overview__button ${v.disabled ? 'overview__button--disabled' : ''} ${
									v.completed ? 'overview__button--completed' : ''
								}`}
								key={uid(v)}
								role="button"
								onClick={() => {
									if (v.disabled) {
										return
									}

									if (v.destination === 'payoutModelInputForm') {
										navigateTo(
											`${getChildPath(routesDictionary.payoutOptions, 'payoutModelInputForm')}/0`
										)
									} else {
										navigateTo(getChildPath(routesDictionary.payoutOptions, v.destination))
									}
								}}
							>
								<div className="progress">
									<Icon type={IconType.visualCheck}></Icon>
								</div>
								<div className="label">
									<div className="label__headline">
										<h3>{v.label}</h3>
									</div>
									<p className="caption">{v.subLabel || ''}</p>
								</div>
								<Icon type={IconType.arrow}></Icon>
							</div>
						))}

						{pendingChanges && isEditable?.editable ? (
							<div
								className={`overview__success-indicator ${
									applicationSuccessfull ? 'overview__success-indicator--completed' : ''
								}`}
								style={{ color: 'var(--error-color)' }}
							>
								{' '}
								<Icon
									type={IconType.menuDocuments}
									size={IconSize.large}
									color={'var(--error-color)'}
								></Icon>
								<h3>
									<Trans i18nKey="view.payoutOptions.payoutTypes.pendingChanges"></Trans>
								</h3>
							</div>
						) : (
							<div
								className={`overview__success-indicator ${
									applicationSuccessfull ? 'overview__success-indicator--completed' : ''
								}`}
							>
								{' '}
								<Icon type={IconType.menuDocuments} size={IconSize.large}></Icon>
								<h3>
									<Trans i18nKey="view.payoutOptions.payoutTypes.success"></Trans>
								</h3>
							</div>
						)}

						<>
							{(firstChapterInEditProgress ||
								!firstChapterComplete ||
								!secondChapterComplete ||
								!thirdChapterComplete) && (
								<Button
									type={ButtonType.secondary}
									className="forward-button"
									onClick={() => {
										completeChapter()
									}}
								>
									{!firstChapterComplete ? (
										<Trans i18nKey={'view.payoutOptions.payoutTypes.buttonStart'} />
									) : (
										<Trans i18nKey={'view.payoutOptions.payoutTypes.buttonCompletion'} />
									)}
								</Button>
							)}

							{firstChapterComplete && secondChapterComplete && !thirdChapterComplete && (
								<Button
									type={ButtonType.primary}
									className="forward-button"
									onClick={() => {
										navigateTo(getChildPath(routesDictionary.payoutOptions, 'payoutConfirmation'))
									}}
								>
									<Trans i18nKey={'view.payoutOptions.payoutTypes.buttonIncomplete'} />
								</Button>
							)}
							{firstChapterComplete &&
								secondChapterComplete &&
								thirdChapterComplete &&
								((applicationSuccessfull && isEditable?.editable) || !applicationSuccessfull) && (
									<Button
										type={ButtonType.primary}
										className="forward-button"
										disabled={!allowContinue || (applicationSuccessfull && !pendingChanges)}
										onClick={() => {
											if (allowContinue) {
												navigateTo(
													getChildPath(routesDictionary.payoutOptions, 'payoutConfirmation')
												)
											}
										}}
									>
										{applicationSuccessfull ? (
											<Trans i18nKey={'view.payoutOptions.payoutTypes.buttonChange'} />
										) : (
											<Trans i18nKey={'view.payoutOptions.payoutTypes.button'} />
										)}
									</Button>
								)}
						</>
					</div>
				</div>
			)}
		</div>
	)
}

export default PayoutOverview
