import classnames from 'classnames'
import { FC, HTMLAttributes } from 'react'


type THintBoxType = 'default' | 'success'

type THintsBox = {
	type?: THintBoxType
}

const styleClasses: Record<
	THintBoxType,
	{
		backgroundColor?: string
		headingColor?: string
		textColor?: string
	}
> = {
	default: {
		backgroundColor: 'blue',
	},
	success: {
		backgroundColor: 'teal',
		headingColor: 'blue',
		textColor: 'blue',
	},
}

export const HintsBox: FC<THintsBox & HTMLAttributes<HTMLDivElement>> = ({
	type = 'default',
	children,
	className,
	...attributes
}) => {
	return (
		<div
			className={classnames(
				'flex flex--direction-column flex--grow flex--align-self-start background-color--blue',
				className,
				{
					[`background-color--${styleClasses[type].backgroundColor}`]: styleClasses[type].backgroundColor,
					[`text-color-${styleClasses[type].textColor}`]: styleClasses[type].textColor,
				}
			)}
			{...attributes}
		>
			<p
				className={classnames('bold-small-heading', {
					[`text-color-${styleClasses[type].headingColor}`]: styleClasses[type].headingColor,
				})}
			>
				{/* <Trans i18nKey="generic.hint" /> */}
			</p>
			{children}
		</div>
	)
}
