import useApi, { QueryKey } from 'hooks/useApi'
import { useAuth } from 'hooks/useAuth'
import { useUserGroup } from 'hooks/useUserGroup'
import { useState } from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import Checkbox from 'shared/components/Checkbox'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { MfaResponseData } from 'shared/interfaces'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'

const PayoutFinalStep = () => {
	const { navigateTo, getChildPath } = useRouteHelper()
	useBackButtonPath(getChildPath(routesDictionary.payoutOptions, 'payoutOverview'))
	const [checked, setChecked] = useState(false)
	const api = useApi()
	const auth = useAuth()
	const { isHigherUp, isVO20 } = useUserGroup()
	useNavigationTitle('Zusammenfassung')
	const { refetch } = useQuery(QueryKey.payoutModelConfirmationDate, api.payoutModelConfirmationDate)

	const onClick = async () => {
		// navigateTo(`${getChildPath(routesDictionary.payoutOptions, 'payoutModelInputForm')}/0`)
		const mfaResponse = await api.getPayoutModelTransactionId()

		if (undefined !== mfaResponse && undefined !== mfaResponse?.transactionId) {
			// console.log(mfaResponse.transactionId)
			auth.addMfaAction({
				transactionId: (mfaResponse as MfaResponseData).transactionId,
				onSuccess: async () => {
					navigateTo(`${getChildPath(routesDictionary.payoutOptions, 'payoutSentSucess')}`)
					await api.confirmPayoutModel(mfaResponse.transactionId)

					refetch()
				},
			})
		}
	}

	const getBodyText = (isVO20: boolean | undefined, isHigherUp: boolean | undefined) => {
		if (isHigherUp) {
			return <Trans i18nKey="view.payoutOptions.finalize.bodyTextEMGGET" />
		} else if (isVO20) {
			return <Trans i18nKey="view.payoutOptions.finalize.bodyTextVo20" />
		} else {
			return <Trans i18nKey="view.payoutOptions.finalize.bodyText" />
		}
	}

	return (
		<div className="grid center-view payout-confirmation">
			<div className="grid__top"></div>

			<div className="grid__center">
				<h3 className="margin--bottom">
					<Trans i18nKey="view.payoutOptions.finalize.headline" />
				</h3>

				<p className="margin--bottom">{getBodyText(isHigherUp, isVO20)}</p>

				<br />
				<Checkbox
					className="margin--top"
					onChange={(e: any) => {
						setChecked(e?.target?.checked ? true : false)
					}}
					checked={false}
					label={
						<h3 className="no-margin">
							<Trans i18nKey="generic.hintsRead" />
						</h3>
					}
				></Checkbox>

				<Button disabled={!checked} type={ButtonType.primary} onClick={onClick}>
					<Trans i18nKey="view.payoutOptions.finalize.button" />
				</Button>
			</div>
		</div>
	)
}

export default PayoutFinalStep
