import useMaskedInput from '@viewstools/use-masked-input'
import i18next from 'i18next'
import React, { FunctionComponent, useRef } from 'react'
import { conformToMask } from 'react-text-mask'
import { numberFormat } from 'shared/helper/numberFormats'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { sanitizeDecimalNumber } from '../helper/sanitizeMaskedValue'
import TextInput, { TextInputProps, TextInputType } from './TextInput'

interface BigNumberInputProps extends TextInputProps {
	allowDecimal?: boolean
}

const BigNumberInput: FunctionComponent<BigNumberInputProps> = (props) => {
	const attributes = () => {
		const filteredAttributes: any = { ...props }
		const propsToRemove = ['allowDecimal']

		for (const prop of Object.keys(filteredAttributes)) {
			if (propsToRemove.includes(prop)) {
				delete filteredAttributes[prop]
			}
		}

		return filteredAttributes
	}

	const input = useRef(null)

	const numberMask = createNumberMask({
		prefix: '',
		suffix: ' €',
		thousandsSeparatorSymbol: i18next.language.toLowerCase().startsWith('en') ? ',' : '.',
		allowDecimal: props.allowDecimal ?? true,
		decimalSymbol: i18next.language.toLowerCase().startsWith('en') ? '.' : ',',
		integerLimit: props.maxValue
			? String(numberFormat(props.maxValue, { fixedFractionDigits: props.allowDecimal ? 2 : 1 })).length
			: null,
	})

	const handleOnChange = (e: any) => {
		if (props.maxValue && sanitizeDecimalNumber(e.target.value) > props.maxValue) {
			e.target.value = conformToMask(
				String(numberFormat(props.maxValue, { fixedFractionDigits: props.allowDecimal ? 2 : 0 })),
				numberMask,
				{}
			).conformedValue
		}

		if (props.minValue && sanitizeDecimalNumber(e.target.value) < props.minValue) {
			e.target.value = conformToMask(
				String(numberFormat(props.maxValue, { fixedFractionDigits: props.allowDecimal ? 2 : 0 })),
				numberMask,
				{}
			).conformedValue
		}

		props.onChange && props.onChange(e)
	}

	const onChange = useMaskedInput({
		input,
		mask: numberMask,
		onChange: handleOnChange,
		value: props.value,
	})

	const handleOnBlur = (e: any) => {
		e.target.value = conformToMask(
			String(Math.floor(sanitizeDecimalNumber(e.target.value))),
			numberMask,
			{}
		).conformedValue
	}

	return (
		<TextInput
			{...attributes}
			minValue={undefined}
			maxValue={undefined}
			type={TextInputType.number}
			className="big-number-input"
			ref={input}
			onChange={onChange}
			onBlur={handleOnBlur}
		/>
	)
}

export default BigNumberInput
