import RiskPreview from 'components/RiskBenefit/RiskPreview'
import useApi, { QueryKey } from 'hooks/useApi'
import { useUserGroup } from 'hooks/useUserGroup'
import React, { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import SelectableBarGroup, { SelectableBarGroupOption } from 'shared/components/SelectableBarGroup'
import { OPTEOptions, OPTTOptions, RiskOptions } from 'shared/enums'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'
import { riskOptionIcon } from './RiskBenefit'

const RBSelectType: React.FC<{ riskId: RiskOptions }> = ({ riskId }) => {
	const { getChildPath, navigateTo } = useRouteHelper()
	const { isHigherUp, isVO20, isBoard } = useUserGroup()
	useBackButtonPath(
		isHigherUp
			? getChildPath(routesDictionary.riskBenefit, 'RBSelection')
			: getChildPath(
					routesDictionary.riskBenefit,
					RiskOptions.disabilityBenefit === riskId ? 'RBDisabilityBenefit' : 'RBPartnerBenefit'
			  )
	)
	useNavigationTitle(<Trans i18nKey="view.riskBenefit.pageTitle.default" />)

	const api = useApi()
	const { t } = useTranslation()



	const [selectedType, setSelectedType] = useState<OPTEOptions | OPTTOptions>()
	const [otherSelectedType, setOtherSelectedType] = useState<OPTEOptions | OPTTOptions>()

	const { data } = useQuery([QueryKey.riskOptionsTypes, { riskId }], api.getRiskOptionsTypes)

	const { data: availableRiskOptions } = useQuery(QueryKey.riskOptionsAvailable, api.getRiskOptionsAvailable)

	const dataKeys: { [key: string]: { [key: string]: any } } | undefined = useMemo(() => {
		if (undefined === availableRiskOptions) {
			return undefined
		}

		return {
			[RiskOptions.disabilityBenefit]: {
				futureType: availableRiskOptions?.futureRiskOPTEOption?.OPTE,
				validFrom:
					availableRiskOptions.currentOPTEOption.years[
						availableRiskOptions.currentOPTEOption.years.length - 1
					] + 1,
			},
			[RiskOptions.partnerBenefit]: {
				futureType: availableRiskOptions?.futureRiskOPTTOption?.OPTT,
				validFrom:
					availableRiskOptions.currentOPTTOption.years[
						availableRiskOptions.currentOPTTOption.years.length - 1
					] + 1,
			},
		}
	}, [availableRiskOptions])

	useEffect(() => {
		if (!dataKeys) {
			return
		}

		if (undefined !== dataKeys[riskId].futureType) {
			setSelectedType(dataKeys[riskId].futureType)
		}

		const otherRiskId = Object.keys(dataKeys).find((key) => key !== riskId)

		if (otherRiskId && undefined !== dataKeys[otherRiskId].futureType) {
			setOtherSelectedType(dataKeys[otherRiskId].futureType)
		}
	}, [availableRiskOptions, riskId, dataKeys])

	const options: SelectableBarGroupOption[] = useMemo(() => {
		if (!data) {
			return []
		}

		return (data as string[]).reduce((availableOptions: SelectableBarGroupOption[], value) => {
			const option = {
				label: t(`view.riskBenefit.types.${value}.label`),
				subheadline: t(
					`view.riskBenefit.types.${value}.shortDescription.${riskId}${isBoard ? 'Vorstand' : ''}`
				),
				content: (
					<Trans
						i18nKey={`view.riskBenefit.types.${value}.description.${riskId}${
							/**
							 * Options for 'Partnerbenefit' will have separate Texts
							 * as a description if users are "vo20-Users". This Applies to all options: e.g. X, A, E
							 *
							 * Vorstand gets separate texts too. But Vorstand will only have the * "X"-option anyway^
							 */
							riskId === 'partnerBenefit' && isVO20 ? 'Vo20' : ''
						}${isBoard ? 'Vorstand' : ''}`}
					/>
				),
				value,
				icon: riskOptionIcon[value],
				onClick: () => {
					setSelectedType(value as OPTEOptions | OPTTOptions)
				},
			}

			availableOptions.push(option)

			return availableOptions
		}, [])
	}, [data, riskId, t, isBoard, isVO20])

	const onClickHandler = () => {
		if (undefined === selectedType) {
			return
		}

		const route: { [key: string]: string | undefined } = {
			X:
				'X' === dataKeys![riskId].futureType
					? getChildPath(routesDictionary.riskBenefit, 'RBSuccessFastLane')
					: `${getChildPath(
							routesDictionary.riskBenefit,
							RiskOptions.disabilityBenefit === riskId
								? 'RBDisabilityBenefitConditions'
								: 'RBPartnerBenefitConditions'
					  )}/${selectedType}`,
			E: `${getChildPath(
				routesDictionary.riskBenefit,
				RiskOptions.disabilityBenefit === riskId
					? 'RBDisabilityBenefitChangeSelect'
					: 'RBPartnerBenefitChangeSelect'
			)}/${selectedType}`,
			A: `${getChildPath(
				routesDictionary.riskBenefit,
				RiskOptions.disabilityBenefit === riskId
					? 'RBDisabilityBenefitChangeSelect'
					: 'RBPartnerBenefitChangeSelect'
			)}/${selectedType}`,
		}

		navigateTo(route[selectedType])
	}

	return (
		<div
			className={`rb-select-type risk-benefit center-view grid grid--center-scroll visually-hidden visually-hidden--${String(
				!data
			)}`}
		>
			<h3 className="text-align--left grid__top">
				<Trans i18nKey={`view.riskBenefit.change.headline.${riskId}${isHigherUp ? 'NoChanges' : ''}`} />
			</h3>

			<div className="grid__center">
				{!isHigherUp && (
					<p className="text-color-cyan">
						{t('view.riskBenefit.change.validFrom', {
							year: dataKeys![riskId].validFrom,
						})}
					</p>
				)}
				{options && (
					<SelectableBarGroup
						className="margin--vertical full-width"
						options={options}
						selectedOption={{ value: selectedType }}
					/>
				)}
				<div className={`moved-by-accordion visually-hidden visually-hidden--${String(!selectedType)}`}>
					{selectedType && (
						<>
							<hr className="hr margin--vertical margin--large full-width " />

							<h3 className="text-align--left">
								<Trans
									i18nKey={`view.riskBenefit.change.effectOfSelection.headline${
										isHigherUp ? 'NoChanges' : ''
									}`}
								/>
							</h3>

							<Trans i18nKey="view.riskBenefit.change.effectOfSelection.description" />

							<RiskPreview riskId={riskId} type={selectedType} otherType={otherSelectedType} />
						</>
					)}
				</div>
				<div className="moved-by-accordion padding--bottom">
					{!isHigherUp && (
						<Button
							onClick={onClickHandler}
							type={ButtonType.primary}
							label={t('generic.next')}
							disabled={!selectedType}
						/>
					)}
				</div>
			</div>
		</div>
	)
}

export default RBSelectType
