import { IconType } from 'components/Icons'
import React, { FunctionComponent, PropsWithChildren, useEffect, useLayoutEffect, useRef, useState } from 'react'
import Icon from './Icon'

export interface AccordionProps {
	id?: string
	header: React.ReactElement | string
	highlighted?: boolean
	alternativeStyle?: boolean
	highlightColor?: any
	contentHeight?: any
	open?: boolean
	onClick?: () => void
	onOpen?: () => void
	afterRender?: (key: number) => void
	className?: string
	icon?: IconType
	iconColor?: string
	level?: number
	content?: any
	dataDriven?: boolean
}

const Accordion: FunctionComponent<PropsWithChildren<AccordionProps>> = (props) => {
	const [openState, setOpenState] = useState(props.open || false)
	const [cssStyles, setCssStyles] = useState(props.contentHeight as React.CSSProperties)
	const accordionContent = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (undefined !== props.open) {
			setOpenState(props.open)
		}
	}, [props.open])

	const getHeight = () => {
		if (accordionContent !== null && accordionContent.current !== null) {
			return accordionContent.current.getBoundingClientRect().height
		}
		return 0
	}

	const getClassNames = () => {
		const classPrefix: string = 'accordion--'
		const classes: string[] = ['accordion']

		if (props.className) {
			classes.push(props.className)
		}

		if (openState) {
			classes.push(`${classPrefix}open`)
		}

		if (props.highlighted) {
			classes.push(`${classPrefix}highlighted`)
		}

		if (props.alternativeStyle) {
			classes.push(`${classPrefix}alternative-style`)
		}

		return classes.join(' ')
	}

	const onClick = () => {
		if (!props.dataDriven) {
			toggleAccordion()
		}

		if (props.onClick) {
			props.onClick()
		}
	}

	const toggleAccordion = () => {
		setOpenState(openState === false ? true : false)

		if (undefined !== props.onOpen) {
			props.onOpen()
		}
	}

	const getColor = () => {
		if (!props.highlightColor) {
			return
		}

		return {
			'--accordion-color': props.highlightColor,
		} as React.CSSProperties
	}

	useEffect(() => {
		setCssStyles({ ...props.contentHeight, '--accordion-content-height': `${getHeight()}px` })
	}, [props.contentHeight])

	useLayoutEffect(() => {
		const height = getHeight()

		if (props.afterRender) {
			props.afterRender(height)
		}
	}, [openState, props])

	return (
		<div
			id={props.id}
			data-level={props.level !== undefined ? props.level.toString() : ''}
			className={getClassNames()}
		>
			<div className="accordion__head flex flex--align-items-center" style={getColor()} onClick={onClick}>
				<div className="head-slot">{props.header}</div>
				<div className="icon-slot flex">
					<Icon
						type={props.icon || IconType.arrow}
						color={props.iconColor ? props.iconColor : 'var(--accordion-toggle-color)'}
						rotate={openState ? -90 : 90}
					/>
				</div>
			</div>
			<div
				className={`accordion__content ${props.children ? '' : 'accordion__content--empty'}`}
				style={cssStyles}
				ref={accordionContent}
			>
				<div className="content-wrapper">{props.children}</div>
			</div>
		</div>
	)
}

export default Accordion
