import { useQuery } from 'react-query'
import useApi from './useApi'

export const useAWSConfig = () => {

	const api = useApi()
	const { data: awsConfig, isSuccess } = useQuery('awsConfig', api.getLocalAWSConfig)
	const configLoaded = !!awsConfig

	const getAWSConfig = (userPoolId: string) => {
		if (!awsConfig) {
			return null
		}

		const isNewUserPool = process.env.REACT_APP_OLD_AWS_USERPOOL !== userPoolId

		const config = { ...awsConfig, aws_user_pools_id: userPoolId }

		if (isNewUserPool) {
			config.authenticationFlowType = 'CUSTOM_AUTH'
			config.aws_user_pools_web_client_id = process.env.REACT_APP_NEW_AWS_CLIENT_ID || ''
			return config
		} else {
			return config
		}
	}

	return { getAWSConfig, configLoaded, isSuccess }
}
