import React, { FunctionComponent, useRef } from 'react'
import Radiobutton from 'shared/components/Radiobutton'
import RadiobuttonGroup, { radiobuttonGroupContext, RadiobuttonGroupContext } from 'shared/components/RadiobuttonGroup'

interface PayoutModelListProps {
	items: PayoutModelListItemProps[]
	className?: string
	onChange?: (value: any) => void
}

export interface PayoutModelListItemProps {
	value: number | string | boolean
	label: string
	subLabel?: string | JSX.Element
	description: string | JSX.Element
	disabled?: boolean
	checked?: boolean
}

const PayoutModelList: FunctionComponent<PayoutModelListProps> = (props) => {
	const descriptionRefs = useRef<{ [key: number]: HTMLDivElement }>({})

	const getClasses = (): string => {
		const classes = ['payout-model-list']

		if (props.className) {
			classes.push(props.className)
		}

		return classes.join(' ')
	}

	const getHeight = (component: HTMLDivElement) => {
		if (undefined === component) {
			return 0
		}

		return component.clientHeight
	}

	return (
		<RadiobuttonGroup
			onChange={(index: any) => {
				props.onChange && props.onChange(props.items?.[index]?.value)
			}}
			className={getClasses()}
		>
			<radiobuttonGroupContext.Consumer>
				{(context: RadiobuttonGroupContext) => {
					const descriptionHeight =
						undefined !== context.selectedItemIndex
							? getHeight(descriptionRefs.current[context.selectedItemIndex])
							: 0

					return (
						<div
							style={
								{
									'--description-height': `${descriptionHeight}px`,
								} as React.CSSProperties
							}
						>
							{props.items.map((data: any, index: number) => {
								if (undefined === context.selectedItemIndex && data.checked) {
									context.selectItem(index)
								}
								return (
									<div
										key={index}
										className={`payout-model-list__item ${
											data.disabled ? 'payout-model-list__item--disabled' : ''
										}`}
										data-checked={index === context.selectedItemIndex}
									>
										<Radiobutton
											className="payout-model-list__radiobutton "
											onChange={() => {
												props?.onChange && props.onChange(index)
												return context.selectItem(index)
											}}
											index={index}
											{...data}
											checked={index === context.selectedItemIndex}
											name="payout-model"
											label={
												<>
													<span className="main-label">{data.label}</span>
													{data.subLabel && (
														<span className="payout-model-list__sub-label">
															{data.subLabel}
														</span>
													)}
												</>
											}
										/>
										<div
											ref={(instance: HTMLDivElement) => {
												descriptionRefs.current[index] = instance
											}}
											className={`payout-model-list__item-description ${
												context.selectedItemIndex === index
													? 'payout-model-list__item-description--active'
													: ''
											}`}
										>
											<p>{data.description}</p>
										</div>
									</div>
								)
							})}
						</div>
					)
				}}
			</radiobuttonGroupContext.Consumer>
		</RadiobuttonGroup>
	)
}

export default PayoutModelList
