import useApi, { QueryKey } from 'hooks/useApi'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import Checkbox from 'shared/components/Checkbox'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'
import { PayoutModelOptions } from 'views/PayoutModel'
import { components } from '../../../types/api-interface'

const MixedPayoutPreselection = () => {
	const { navigateTo, getChildPath } = useRouteHelper()
	const api = useApi()
	const queryClient = useQueryClient()

	useBackButtonPath(getChildPath(routesDictionary.payoutOptions, 'payoutModel'))
	useNavigationTitle(<Trans i18nKey="view.payoutModel.pageTitle" />)

	const { data, refetch, status } = useQuery(QueryKey.availableModels, api.getAvailablePayoutModels)
	const payoutModelQuery = useQuery(QueryKey.payoutModelInProgress, api.getPayoutModelInProgress)

	const modell = payoutModelQuery?.data?.modell

	const savedSelection = localStorage.getItem('mixed-payout-selection')
	const parsedSavedSelection = savedSelection ? JSON.parse(savedSelection) : null

	useEffect(() => {
		queryClient.prefetchQuery(QueryKey.pensionBalance, api.getPensionBalance)
		refetch()
		// eslint-disable-next-line
	}, [])
	const [updateKey, setUpdateKey] = useState<string | undefined>()
	const [selected, setSelected] = useState<string[] | undefined>()

	const allowContinue = selected && selected.length >= 2

	useEffect(() => {
		if (updateKey && selected) {
			var updateState = selected

			if (updateState.includes(updateKey)) {
				updateState.splice(updateState.indexOf(updateKey), 1)
			} else {
				updateState.push(updateKey)
			}
			setSelected(updateState)
			setUpdateKey(undefined)
		}
		// eslint-disable-next-line
	}, [updateKey])

	const { t } = useTranslation()

	const onChange = (key: string) => {
		setUpdateKey(key)
	}

	const onClick = () => {
		if (allowContinue) {
			localStorage.setItem('mixed-payout-selection', JSON.stringify(selected))
			navigateTo(`${getChildPath(routesDictionary.payoutOptions, 'mixedPayout')}/${selected}`)
		}
	}

	const getPreselection = () => {
		const strings = []

		if (parsedSavedSelection) {
			return parsedSavedSelection
		}

		if (modell && 'type' in modell && modell.type === 'mixed') {
			if (
				(modell?.distribution as components['schemas']['AuszahlungsmodellGemischt']['distribution'])
					?.instalments10Years
			) {
				strings.push('instalments10Years')
			}
			if (
				(modell?.distribution as components['schemas']['AuszahlungsmodellGemischt']['distribution'])
					?.instalments20Years
			) {
				strings.push('instalments20Years')
			}
			if ((modell?.distribution as components['schemas']['AuszahlungsmodellGemischt']['distribution'])?.pension) {
				strings.push('pension')
			}
			if ((modell?.distribution as components['schemas']['AuszahlungsmodellGemischt']['distribution'])?.lumpSum) {
				strings.push('lumpSum')
			}
		}

		return strings
	}

	useEffect(() => {
		if (payoutModelQuery.data) {
			setSelected(getPreselection)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [payoutModelQuery.status])

	if (payoutModelQuery.status !== 'success' || status !== 'success') {
		return <></>
	}

	const options = [
		{
			label: <Trans i18nKey="view.payoutOptions.chapterOne.mixedPayout.lumpSum" />,
			value: PayoutModelOptions.lumpSum,
			disabled: !data?.lumpSum,
			checked: selected && selected.includes('lumpSum'),
		},
		{
			label: <Trans i18nKey="view.payoutOptions.chapterOne.mixedPayout.instalments20Years" />,
			value: PayoutModelOptions.instalments20Years,
			disabled: !data?.instalments20Years,
			checked: selected && selected.includes('instalments20Years'),
		},
		{
			label: <Trans i18nKey="view.payoutOptions.chapterOne.mixedPayout.instalments10Years" />,
			value: PayoutModelOptions.instalments10Years,
			disabled: !data?.instalments10Years,
			checked: selected && selected.includes('instalments10Years'),
		},
		{
			label: <Trans i18nKey="view.payoutOptions.chapterOne.mixedPayout.pension" />,
			value: PayoutModelOptions.pension,
			disabled: !data?.pension,
			checked: selected && selected.includes('pension'),
		},
	]

	return (
		<div className="center-view mixed-payout-preselection">
			<div>
				<div className="caption text-color-blue">
					{' '}
					<Trans i18nKey="view.payoutOptions.chapterOne.mixedPayout.strapline" />
				</div>
				<h3 className="no-margin--top">
					<Trans i18nKey="view.payoutOptions.chapterOne.mixedPayout.subHeadline" />
				</h3>
			</div>
			<div>
				{selected !== undefined &&
					data &&
					options.map(({ label, value, disabled, checked }, index) => (
						<div key={'mixed-payout-preselection-' + index} className={`${disabled ? 'disabled' : ''}`}>
							<hr className="hr hr--blue hr--slim" />
							<Checkbox
								className="margin margin--top margin--small"
								value={value}
								onChange={(e: any) => {
									onChange(e.target.value)
								}}
								checked={checked}
								disabled={data && !data[value as keyof typeof data]}
								label={<h3 className="font-family-alternative--light no-margin">{label}</h3>}
							/>{' '}
						</div>
					))}
			</div>
			<div className="margin--top">
				<Button
					disabled={!allowContinue}
					label={t('view.payoutOptions.chapterOne.mixedPayout.buttonLabel')}
					type={ButtonType.primary}
					onClick={onClick}
				/>
				{!allowContinue && (
					<p className="text-align--center">{t('view.payoutOptions.chapterOne.mixedPayout.minOptions')}</p>
				)}
			</div>
		</div>
	)
}

export default MixedPayoutPreselection
