import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react'
import Button from 'shared/components/Button'
import dateFormat from 'shared/helper/dateFormat'
import { currencyFormat } from 'shared/helper/numberFormats'
import { useDetectClickOutside } from 'shared/hooks/useDetectClickOutside'
import { SimpleChartData } from 'shared/interfaces'
import useResizeObserver from 'use-resize-observer'
import {
	// @ts-ignore
	Bar,
	// @ts-ignore
	LineSegment,
	// @ts-ignore
	Path,
	VictoryAxis,
	VictoryBar,
	// @ts-ignore
	VictoryChart,
	VictoryClipContainer,
	VictoryGroup,
	VictoryLabel,
	// @ts-ignore
	VictoryThemeDefinition,
	VictoryTooltip,
	VictoryZoomContainer,
} from 'victory'
import theme, { fallbackChartColor, fontFamilyAlternative, pensionAssetsChartStyle } from '../../components/ChartsTheme'
import { IconType } from '../../components/Icons'

export interface PensionAssetsChartProps {
	className?: string
	panToEnd?: boolean
	data: SimpleChartData[][]
}

enum PanDirection {
	left,
	right,
}

const animationDuration = 500
const animationDurationOnInit = 1000
const padding = 24
const chartMinHeight = pensionAssetsChartStyle.chartMinHeight
const panCount = 13
const visibleBars = 25
const minimumBarsCount = 24

const minimumPan = -3
const addToMaximumPan = 3

const LineSegmentVertical = (props: any) => {
	return <LineSegment {...props} x2={props.x2 + 10e-4} type={'grid'} />
}

const LineSegmentHorizontal = (props: any) => {
	return <LineSegment {...props} y2={props.y2 + 10e-4} type={'grid'} />
}

const TooltipLabel = (props: any) => {
	const styles = props.text.map((text: string, index: number) => {
		if (0 === index) {
			return {
				...props.style,
				fill: fallbackChartColor,
				fontSize: 8,
				letterSpacing: 1.5,
			}
		}

		return {
			...props.style,
			// use index - 1 as thee is a custom text before any data text
			fill: pensionAssetsChartStyle.barColors[index - 1] || pensionAssetsChartStyle.fallbackColor,
			fontFamily: fontFamilyAlternative,
			fontVariantNumeric: 'tabular-nums',
		}
	})

	const lineHeights = props.text.map((text: string, index: number) => {
		return 0 === index ? 2.2 : 1.2
	})

	// add figure space character to amounts that have less characters than the longest amount, so all amounts are center aligned
	const longestStringOfAmounts = Math.max(...props.text.slice(1).map((text: string) => text.length))
	const labels = props.text.map((text: string, index: number) => {
		if (0 === index) {
			return text
		}
		const additionalSpacing: string[] = Array(longestStringOfAmounts - text.length).fill('\u2007')
		return additionalSpacing.join('') + text
	})

	return <VictoryLabel {...props} style={styles} text={labels} lineHeight={lineHeights} dy={-2} />
}

const Tooltip = (props: any) => {
	const { active, x } = props
	if (false === active || undefined === x) {
		return null
	}

	return (
		<g>
			<VictoryTooltip
				{...props}
				constrainToVisibleArea={false}
				center={{ y: 0 }}
				style={{
					fontSize: 16,
				}}
				labelComponent={<TooltipLabel />}
				renderInPortal={true}
			/>
			<line
				x1={props.x}
				y1={0}
				x2={props.x + 10e-4}
				y2={props.height - padding}
				stroke="url(#tooltipLineGradient)"
				strokeWidth={2}
			/>
		</g>
	)
}

const TouchableBar = (props: any) => {
	const { index } = props
	return <Bar {...props} pathComponent={<Path data-index={index} />} />
}

let preventInteractionTimeout: NodeJS.Timer
let resizeObserverTimeout: NodeJS.Timer
let tooltipTimer: NodeJS.Timer

const PensionAssetsChart: FunctionComponent<PensionAssetsChartProps> = (props) => {
	const data = useMemo(() => {
		return props.data?.length > 0 ? props.data : [[]]
	}, [props.data])
	const dataCount = useMemo(() => data?.[0].length || 0, [data])
	const maximaY: number = useMemo(
		() => (data ? Math.max(...data.map((dataset) => Math.max(...dataset.map((d) => d.y)))) : 0),
		[data]
	)

	const ghostsData = useMemo(() => {
		const ghostData = { y: maximaY > 0 ? maximaY : 10000, type: 'ghost' }
		const ghostBars = []

		// fill up ghost entries if minimumBarsCount is not met
		while (dataCount + ghostBars.length < minimumBarsCount) {
			ghostBars.push({ ...ghostData, count: dataCount + ghostBars.length + 1 })
		}

		return ghostBars
	}, [dataCount, maximaY])

	const [chartWidth, setChartWidth] = useState<number>(0)
	const [chartHeight, setChartHeight] = useState<number>(0)
	const wrapperRef = useRef<HTMLDivElement>(null)

	useResizeObserver<HTMLDivElement>({
		ref: wrapperRef,
		onResize: ({ width, height }) => {
			if (undefined === height || undefined === width) {
				return
			}

			clearTimeout(resizeObserverTimeout)

			resizeObserverTimeout = setTimeout(() => {
				setChartHeight(height > chartMinHeight ? height : chartMinHeight)
				setChartWidth(width)
			}, 100)
		},
	})

	useEffect(() => {
		return () => {
			clearTimeout(preventInteractionTimeout)
			clearTimeout(resizeObserverTimeout)
			clearTimeout(tooltipTimer)
		}
	}, [])

	const chartInit = useRef<boolean>(true)
	const chartTheme: VictoryThemeDefinition = theme
	useDetectClickOutside(wrapperRef, () => setTooltipData({ active: false }))
	const allowPan = dataCount > visibleBars
	const maximumPan = useMemo<number>(() => dataCount + addToMaximumPan, [dataCount])
	const zoomDomain = useMemo((): [number, number] => {
		let [low, high] = [maximumPan - visibleBars, maximumPan]

		if (true === props.panToEnd) {
			low = dataCount - visibleBars
			high = dataCount
		}

		return [low, high]
	}, [dataCount, props.panToEnd])

	const [currentZoomDomain, setZoomDomain] = useState(zoomDomain)
	const [animate, setAnimate] = useState<boolean>(true)
	const [disallowedPanDirection, setDisallowedPanDirection] = useState<PanDirection>()
	const [tooltipData, setTooltipData] = useState<{ [key: string]: any }>()

	useEffect(() => {
		setZoomDomain(zoomDomain)
	}, [zoomDomain])

	/**
	 * pan left on click
	 */
	const panLeft = () => {
		let updatedZoomDomain: [number, number] = [
			Math.floor(currentZoomDomain[0] - panCount),
			Math.floor(currentZoomDomain[1] - panCount),
		]

		if (updatedZoomDomain[0] < minimumPan) {
			updatedZoomDomain = [minimumPan, minimumPan + visibleBars]
		}

		updateZoomDomain(updatedZoomDomain)
	}

	/**
	 * pan right on click
	 */
	const panRight = () => {
		let updatedZoomDomain: [number, number] = [
			Math.ceil(currentZoomDomain[0] + panCount),
			Math.ceil(currentZoomDomain[1] + panCount),
		]

		if (updatedZoomDomain[1] > maximumPan) {
			updatedZoomDomain = [maximumPan - visibleBars, maximumPan]
		}

		updateZoomDomain(updatedZoomDomain)
	}

	/**
	 * enables animation before calling setZoomDomain
	 * @param domain
	 */
	const updateZoomDomain = (domain: any) => {
		if (true === tooltipData?.active) {
			setTooltipData({ ...tooltipData, active: false })
		}

		if (false === animate) {
			setAnimate(true)

			// wait for next event loop, so animate is already set to false
			setTimeout(() => {
				setZoomDomain(domain)
			})
		} else {
			setZoomDomain(domain)
		}
	}

	const onBarHover = (barIndex: number) => {
		clearTimeout(tooltipTimer)
		let date: number = 0

		const chartSlicePerBar = chartWidth / visibleBars

		const barIndexInCurrentZoomDomain = barIndex + 1 - currentZoomDomain[0]

		const x = chartSlicePerBar * barIndexInCurrentZoomDomain
		// y needed to prevent view from crashing, has no effect on the tooltip
		const y = 1

		const amounts = data.map((pensionAsset: SimpleChartData[]) => {
			if (0 === date) {
				date = pensionAsset[barIndex].x
			}

			return currencyFormat(pensionAsset[barIndex].y)
		})

		const updatedTooltipData = {
			active: true,
			x,
			y,
			text: [dateFormat(date), ...amounts.reverse()],
		}

		tooltipTimer = setTimeout(() => {
			setTooltipData(updatedTooltipData)
		}, 16)
	}

	/**
	 * enable/disable pan direction if the currentZoomDomain
	 * has reached the beginning/end of the chart
	 */
	useEffect(() => {
		if (currentZoomDomain[0] <= minimumPan) {
			setDisallowedPanDirection(PanDirection.left)
		} else if (currentZoomDomain[1] >= maximumPan) {
			setDisallowedPanDirection(PanDirection.right)
		} else {
			setDisallowedPanDirection(undefined)
		}
	}, [maximumPan, currentZoomDomain, data])

	/**
	 * disable any pointer-events while the graph is animating
	 */
	useEffect(() => {
		clearTimeout(preventInteractionTimeout)
		// @ts-ignore
		const chartNode = undefined !== wrapperRef && null !== wrapperRef.current ? wrapperRef.current : undefined
		if (undefined !== chartNode) {
			chartNode.classList.add('pointer-events--force-none')

			preventInteractionTimeout = setTimeout(
				() => {
					chartNode.classList.remove('pointer-events--force-none')
					chartInit.current = false
				},
				chartInit.current ? animationDuration * 2 : animationDuration
			)
		}

		return () => clearTimeout(preventInteractionTimeout)
	}, [data, wrapperRef])

	const dates = useMemo(() => data?.[0].map((dataPoint: SimpleChartData) => dataPoint.x), [data])

	const classNames = useMemo(() => {
		const classes: string[] = ['pension-assets-chart', 'pointer-events--force-none']

		if (props.className) {
			classes.push(props.className)
		}

		return classes.join(' ')
	}, [props.className])

	return (
		<div className={classNames} ref={wrapperRef} style={{ minHeight: chartMinHeight }}>
			<Button
				className="pension-assets-chart__pan pension-assets-chart__pan--left"
				icon={IconType.arrow}
				iconRotate={180}
				iconColor="var(--pan-button-color)"
				onClick={panLeft}
				hidden={!allowPan}
				disabled={PanDirection.left === disallowedPanDirection}
			/>
			<Button
				className="pension-assets-chart__pan pension-assets-chart__pan--right"
				icon={IconType.arrow}
				iconColor="var(--pan-button-color)"
				onClick={panRight}
				hidden={!allowPan}
				disabled={PanDirection.right === disallowedPanDirection}
			/>
			<svg width={0} height={0} style={{ position: 'absolute' }}>
				<linearGradient id="verticalGradient" x1="0" x2="0" y1="0" y2="1">
					<stop offset="0%" stopColor="var(--pension-assets-chart-grid-color)" stopOpacity="0" />
					<stop offset="10%" stopColor="var(--pension-assets-chart-grid-color)" />
				</linearGradient>
				<linearGradient id="tooltipLineGradient" x1="0" x2="0" y1="0" y2="1">
					<stop offset="0" stopColor="var(--tooltip-background-color)" />
					<stop offset="1" stopColor="var(--tooltip-background-color)" stopOpacity="0" />
				</linearGradient>
				<linearGradient id="horizontalGradient" x1="0" x2="1" y1="0" y2="0">
					<stop offset="0%" stopColor="var(--pension-assets-chart-grid-color)" stopOpacity="0" />
					<stop offset="5%" stopColor="var(--pension-assets-chart-grid-color)" />
					<stop offset="95%" stopColor="var(--pension-assets-chart-grid-color)" />
					<stop offset="100%" stopColor="var(--pension-assets-chart-grid-color)" stopOpacity="0" />
				</linearGradient>
				<linearGradient id="ghostBarsGradient" x1="0" x2="0" y1="1" y2="0">
					<stop offset="0%" stopColor="var(--pension-assets-ghost-bars-color)" stopOpacity="0.15" />
					<stop offset="30%" stopColor="var(--pension-assets-ghost-bars-color)" stopOpacity="0.15" />
					<stop offset="100%" stopColor="var(--pension-assets-ghost-bars-color)" stopOpacity="0" />
				</linearGradient>
				<linearGradient id="maskGradientBoth" x1="0" y1="0" x2="1" y2="0">
					<stop offset="0%" stopColor="#000000" />
					<stop offset="30%" stopColor="#ffffff" />
					<stop offset="80%" stopColor="#ffffff" />
					<stop offset="100%" stopColor="#000000" />
				</linearGradient>
				<mask id="clipMaskBoth">
					<rect width={chartWidth} height={chartHeight} fill="url(#maskGradientBoth)" />
				</mask>
			</svg>
			{chartWidth > 0 && chartHeight > 0 && (
				<VictoryChart
					theme={chartTheme}
					height={chartHeight}
					width={chartWidth}
					// @ts-ignore
					responsive={false}
					scale={{ x: 'linear', y: 'linear' }}
					animate={{
						duration: animationDuration,
						onLoad: { duration: animationDurationOnInit },
						// @ts-ignore
						animationWhitelist: ['data', 'size', 'domain'],
					}}
					containerComponent={
						<VictoryZoomContainer
							// @ts-ignore
							className="pension-assets-chart__chart user-select--none-force"
							allowPan={false}
							allowZoom={false}
							zoomDimension="x"
							zoomDomain={{ x: currentZoomDomain }}
							clipContainerComponent={
								<VictoryClipContainer
									clipPadding={{
										left: padding,
										top: 0,
										right: padding,
										bottom: 0,
									}}
								/>
							}
						/>
					}
					domainPadding={{ x: [padding * 2, pensionAssetsChartStyle.barWidth * 3], y: [0, padding * 2] }}
					padding={{ bottom: padding, left: 0, right: 0, top: 0 }}
					events={[
						{
							target: 'parent',
							childName: 'bar',
							eventHandlers: {
								onMouseLeave: () => {
									return [
										{
											mutation: () => setTooltipData({ active: false }),
										},
									]
								},
								onTouchCancel: () => {
									return [
										{
											mutation: () => setTooltipData({ active: false }),
										},
									]
								},
								onMouseMove: (evt: any) => {
									return [
										{
											mutation: () => {
												const { index } = evt.target.dataset
												return index ? onBarHover(Number(index)) : null
											},
										},
									]
								},
								onTouchMove: (evt: any) => {
									return [
										{
											mutation: () => {
												if (undefined === evt.changedTouches[0]) {
													return
												}

												const target = document.elementFromPoint(
													evt.changedTouches[0].clientX,
													evt.changedTouches[0].clientY
												)
												const index = (target as HTMLElement)?.dataset?.index

												return index ? onBarHover(Number(index)) : null
											},
										},
									]
								},
							},
						},
					]}
					style={{
						parent: {
							touchAction: 'pan-y',
						},
					}}
				>
					<Tooltip {...tooltipData} />
					<VictoryAxis
						axisComponent={<LineSegmentHorizontal />}
						gridComponent={<LineSegmentVertical />}
						style={{
							axis: {
								fill: 'none',
								stroke: 'url(#horizontalGradient)',
								strokeOpacity: 0.2,
							},
							grid: {
								fill: 'none',
								stroke: 'url(#verticalGradient)',
								strokeOpacity: 0.2,
							},
							tickLabels: {
								fontSize: 10,
								opacity: 0.4,
								textAnchor: 'start',
							},
						}}
						tickLabelComponent={<VictoryLabel y={chartHeight - padding + 4} />}
						tickFormat={(t: any) => {
							const date = dates?.[t]
							return date ? dateFormat(date, { month: 'short', day: 'numeric' }) : ''
						}}
					/>
					<VictoryAxis
						gridComponent={<LineSegmentHorizontal />}
						offsetX={1}
						style={{
							axis: {
								fill: 'none',
								stroke: 'none',
							},
							grid: {
								fill: 'none',
								stroke: 'url(#horizontalGradient)',
								strokeOpacity: 0.2,
							},
							tickLabels: {
								fontSize: 10,
								opacity: 0.4,
								textAnchor: 'end',
							},
						}}
						tickLabelComponent={<VictoryLabel dy={-8} dx={padding + 2} />}
						tickFormat={(t) => currencyFormat(t, { fixedFractionDigits: 0 })}
						dependentAxis={true}
					/>
					<VictoryGroup
						colorScale={pensionAssetsChartStyle.barColors}
						offset={0}
						style={{
							data: {
								// mask: () => {
								// 	switch (disallowedPanDirection) {
								// 		case PanDirection.left:
								// 			return 'url(#clipMaskRight)'

								// 		case PanDirection.right:
								// 			return 'url(#clipMaskLeft)'

								// 		default:
								// 			return 'url(#clipMaskBoth)'
								// 	}
								// },
								mask: allowPan ? 'url(#clipMaskBoth)' : 'none',
							},
						}}
					>
						{dataCount > 0 &&
							[...data].reverse().map((d, i) => {
								const barChartData = d.map((dataPoint: SimpleChartData, index: number) => {
									const { x, y } = dataPoint

									const count = index + 1
									return { x, y, count }
								})
								return (
									<VictoryBar
										key={`stacked-bar-${i}`}
										data={barChartData}
										barWidth={pensionAssetsChartStyle.barWidth}
										cornerRadius={pensionAssetsChartStyle.cornerRadius}
										x="count"
										style={{
											data: {
												padding: 0,
												strokeWidth: 0,
												transform: `translateX(${3 * -i}px)`,
											},
										}}
									/>
								)
							})}
						{dataCount > 0 && (
							<VictoryBar
								animate={undefined}
								name="bar"
								data={data[0].map((dataPoint: SimpleChartData, index: number) => {
									const { x } = dataPoint
									const y = maximaY > 0 ? maximaY : 10000
									const count = index + 1
									return { x, y, count }
								})}
								barWidth={pensionAssetsChartStyle.barWidth * 3}
								x="count"
								cornerRadius={0}
								style={{
									data: {
										padding: 0,
										strokeWidth: 0,
										fill: 'none',
										transform: `translateX(${-pensionAssetsChartStyle.barWidth / 2}px)`,
									},
								}}
								dataComponent={<TouchableBar />}
							/>
						)}
						<VictoryBar
							name="bar"
							data={ghostsData}
							barWidth={pensionAssetsChartStyle.barWidth}
							x="count"
							cornerRadius={pensionAssetsChartStyle.cornerRadius}
							style={{
								data: {
									padding: 0,
									strokeWidth: 0,
									fill: 'url(#ghostBarsGradient)',
									transform: `translateX(${-pensionAssetsChartStyle.barWidth / 2}px)`,
								},
							}}
						/>
					</VictoryGroup>
				</VictoryChart>
			)}
		</div>
	)
}

export default PensionAssetsChart
