import { t } from 'i18next'
import { FC } from 'react'
import { uid } from 'react-uid'
import Button, { ButtonType } from 'shared/components/Button'
import { components } from 'types/api-interface'

type field = { label: string; value: string | number | undefined | boolean }
type section = { headline: string; values: field[] }[]

const FormSummary: FC<{
	data: components['schemas']['AuszahlungsoptionenArbeitskopie']
	onClick: (i: number) => void
}> = ({ data, onClick }) => {
	const getKonfessionString = (konfession: string) => {
		const values = ['el', 'ev', 'rk', 'weitere', 'keine']

		return t(`view.PayoutInputForm.taxes.confessionOptions.${values.indexOf(konfession)}`)
	}

	const sectionPersonalData: section = [
		{
			headline: t('view.PayoutInputForm.personal.headlines.personalData'),
			values: [
				{
					label: t('view.PayoutInputForm.personal.lastName'),
					value: data?.identity?.name.lastName,
				},
				{
					label: t('view.PayoutInputForm.personal.firstName'),
					value: data?.identity?.name.firstName,
				},
				{
					label: t('view.PayoutInputForm.personal.zfId'),
					value: '',
				},
				{
					label: t('view.PayoutInputForm.personal.sex.label'),
					value: data?.identity?.gender,
				},
				{
					label: t('view.PayoutInputForm.personal.birthday'),
					value: data?.identity?.birthday,
				},
			],
		},
		{
			headline: t('view.PayoutInputForm.personal.headlines.address'),
			values: [
				{
					label: t('view.PayoutInputForm.personal.address'),
					value: data?.identity?.contactDetails.street,
				},
				{
					label: t('view.PayoutInputForm.personal.postalCode'),
					value: data?.identity?.contactDetails.zip,
				},
				{
					label: t('view.PayoutInputForm.personal.city'),
					value: data?.identity?.contactDetails.city,
				},
				{
					label: t('view.PayoutInputForm.personal.country'),
					value: data?.identity?.contactDetails.country,
				},
			],
		},
		{
			headline: t('view.PayoutInputForm.personal.headlines.contact'),
			values: [
				{
					label: t('view.PayoutInputForm.personal.mail'),
					value: data?.identity?.contactDetails.emailAddress,
				},
				{
					label: t('view.PayoutInputForm.personal.countryCallingCode'),
					value: data?.identity?.contactDetails.countryCallingCode,
				},
				{
					label: t('view.PayoutInputForm.personal.phone'),
					value: data?.identity?.contactDetails.phoneNumber,
				},
			],
		},
	]

	const sectionBank: section = [
		{
			headline: t('view.PayoutInputForm.bankAccount.subheadline'),
			values: [
				{
					label: t('view.PayoutInputForm.bankAccount.accountNumber'),
					value: data?.bankingAccount?.IBAN,
				},
				{
					label: t('view.PayoutInputForm.bankAccount.bankname'),
					value: data?.bankingAccount?.bankname,
				},
			],
		},
	]

	const sectionTax: section = [
		{
			headline: t('view.PayoutInputForm.taxes.headlines.taxNumber'),
			values: [
				{
					label: t('view.PayoutInputForm.taxes.taxidentnumber'),
					value: data?.tax?.steueridentifikationsnummer,
				},
				{
					label: t('view.PayoutInputForm.taxes.permanentResident'),
					value: data?.tax?.staendigerWohnsitzInDeutschland ? 'ja' : 'nein',
				},
				{
					label: t('view.PayoutInputForm.taxes.confession'),
					value: data?.tax?.konfession && getKonfessionString(data.tax.konfession),
				},
			],
		},
		{
			headline: t('view.PayoutInputForm.taxes.taxClass'),
			values: [
				{
					label: t('view.PayoutInputForm.taxes.taxClass'),
					value: data?.tax?.steuerklasse,
				},
			],
		},
	]

	const sectionSocialInsurance: section = [
		{
			headline: t('view.PayoutInputForm.socialInsurance.headline'),
			values: [
				{
					label: t('view.PayoutInputForm.socialInsurance.number'),
					value: data?.sozialversicherung?.rentenversicherungsnummer,
				},
			],
		},
	]

	const sectionHealthInsurance: section = [
		{
			headline: t('view.PayoutInputForm.healthInsurance.headline'),
			values: [
				{
					label: t('view.PayoutInputForm.healthInsurance.type'),
					value: data?.krankenkasse?.type,
				},
				{
					label: t('view.PayoutInputForm.healthInsurance.name'),
					value: data?.krankenkasse?.name,
				},
				{
					label: t('view.PayoutInputForm.socialInsurance.pflegeversicherungHeadline'),
					value: data?.pflegeversicherung?.elterneigenschaft
						? t('view.PayoutInputForm.socialInsurance.checkbox') || ''
						: '-',
				},
				// {
				// 	label:
				// 		data?.krankenkasse?.type === 'privat'
				// 			? t('view.PayoutInputForm.healthInsurance.namePrivate')
				// 			: t('view.PayoutInputForm.healthInsurance.namePublic'),
				// 	value: data?.krankenkasse?.krankenkasse,
				// },
			],
		},
	]

	const renderSection = (section: section) => {
		return (
			<div className="payout-form-summary__section">
				{section.map((v, i) => {
					return (
						<div className="payout-form-summary__sub-section" key={i}>
							<p className="section-headline text-color-cyan subheadline bold">{v.headline}</p>
							<div className="payout-form-summary__values">
								{v.values.map(({ label, value }, j) => {
									return (
										<div className="row" key={j}>
											<div className="label">{label}</div>
											<div className="value">{value}</div>
										</div>
									)
								})}
							</div>
						</div>
					)
				})}
			</div>
		)
	}

	const allSections = [sectionPersonalData, sectionBank, sectionTax, sectionSocialInsurance, sectionHealthInsurance]
	return (
		<>
			{allSections.map((v, i) => {
				return (
					<div className="payout-form-summary__section" key={uid(v)}>
						{renderSection(v)}{' '}
						<Button
							className="payout-for-summary__button"
							type={[ButtonType.secondary, ButtonType.small]}
							label={t('generic.changeInputs')}
							onClick={() => {
								onClick(i + 1)
							}}
						></Button>
					</div>
				)
			})}
		</>
	)
}

export default FormSummary
