// Thanks to https://usehooks.com/useAuth/

import { Auth } from 'aws-amplify'
import { activatedFeatures } from 'helper/activatedFeatures'
import { initAmplify } from 'init-amplify'

import Cookies from 'js-cookie'
import { usePath } from 'raviger'
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { trackPromise } from 'react-promise-tracker'
import { useQueryClient } from 'react-query'
import routesDictionary from 'routes'
import { APIErrorCodeV2, ApiErrorCode, AppAccessRestriction, UserGroup } from 'shared/enums'
import isDev from 'shared/helper/isDev'
import { clearLocalStorage } from 'shared/helper/localStorage'

import { clearSessionStorage, getSessionStorage, setSessionStorage } from 'shared/helper/sessionStorage'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { MfaResponseData } from 'shared/interfaces'
import useApi from './useApi'
import useAuthApi from './useAuthApi'
import { useAWSConfig } from './useAwsConfig'
import { useLanguagePref } from './useStoredLanguagePref'

let refreshTokenTimeout: NodeJS.Timeout
let autoLogoutTimeout: NodeJS.Timeout

const { deepl } = activatedFeatures

type AuthVersion = '1' | '2'

// TODO: refactor AuthContext class with proper types
export class AuthContext {
	public checkTokenValidity?: any
	public userName?: string
	public authVersion?: AuthVersion
	public userData?: UserData
	public challenge?: Challenge
	public challengeV2?: any
	public userMigrated?: boolean
	public sendUserId?: any
	public sendChallengeAnswer?: any
	public sendChallengeAnswerV2?: any
	public authenticateMigratedUser?: any
	public signout?: any
	public requireSecondFactor?: any
	public confirmedSecondFactor?: any
	public resetChallenge?: any
	public addMfaAction?: any
	public removeMfaAction?: any
	public runMfaAction?: any
	public setCustomChallenge?: any
	public userInitialized?: boolean
	public amplifyInitialized?: boolean
	public userMigrationPending?: boolean
	public initializeAmplify?: any
	public getUserPoolId?: any
	public checkUserAuthentication?: any
	public session?: any
	public registerCode?: string

	public awsConfig?: {
		aws_project_region: string
		aws_cognito_region: string
		aws_user_pools_id: string
		aws_user_pools_web_client_id: string
		aws_cloud_logic_custom: { name: string; endpoint: string; region: string }[]
	}

	constructor(
		userName: string,
		userData: UserData,
		checkTokenValidity: any,
		authVersion: AuthVersion,
		challenge: Challenge,
		challengeV2: any,
		sendUserId: any,
		sendChallengeAnswer: any,
		sendChallengeAnswerV2: any,
		signout: any,
		requireSecondFactor: any,
		confirmedSecondFactor: any,
		resetChallenge: any,
		addMfaAction: any,
		removeMfaAction: any,
		runMfaAction: any,
		setCustomChallenge: any,
		userInitialized: boolean,
		amplifyInitialized: boolean,
		userMigrationPending: boolean,
		authenticateMigratedUser: any,
		initializeAmplify: any,
		getUserPoolId: any,
		checkUserAuthentication: any,
		session: any,
		registerCode: any
	) {
		this.checkTokenValidity = checkTokenValidity
		this.userName = userName
		this.userData = userData
		this.challenge = challenge
		this.challengeV2 = challenge
		this.sendUserId = sendUserId
		this.sendChallengeAnswer = sendChallengeAnswer
		this.sendChallengeAnswerV2 = sendChallengeAnswerV2
		this.authenticateMigratedUser = authenticateMigratedUser
		this.signout = signout
		this.requireSecondFactor = requireSecondFactor
		this.confirmedSecondFactor = confirmedSecondFactor
		this.resetChallenge = resetChallenge
		this.addMfaAction = addMfaAction
		this.removeMfaAction = removeMfaAction
		this.runMfaAction = runMfaAction
		this.setCustomChallenge = setCustomChallenge
		this.userInitialized = userInitialized
		this.amplifyInitialized = amplifyInitialized
		this.userMigrationPending = userMigrationPending
		this.initializeAmplify = initializeAmplify
		this.getUserPoolId = getUserPoolId
		this.checkUserAuthentication = checkUserAuthentication
		this.session = session
		this.registerCode = registerCode
	}
}

export interface UserData {
	appAccessRestriction: AppAccessRestriction
	aud: string
	auth_time: number
	noBEMGR: string
	groups: UserGroup[]
	'cognito:groups': UserGroup[]
	'cognito:username': string
	'custom: preferredLanguage': string
	email_verified: boolean
	email: string
	phone_number: string
	phone_number_verified: boolean
	employeeGroup: string
	event_id: string
	exp: number
	firstName: string
	iat: number
	iss: string
	lastName: string
	pensionRuleSetCode: string
	sub: string
	token_use: string
	wayOfParticipation: string
}

export interface UserCredentials {
	username: string
	password: string
}

export enum ChallengeType {
	password = 'PASSWORD_INITIATE_AUTH',
	setNewPassword = 'PASSWORD_RESET_REQUIRED',
	sendPassword = 'SEND_PASSWORD',
	setEmailAddress = 'EMAIL_REQUIRED',
	loginMfa = 'MFA_VERIFIER',
	mfa = 'MFA',
	captcha = 'CAPTCHA_REQUIRED',
}

export enum ChallengeTypeV2 {
	setNewPassword = 'PASSWORD_RESET_REQUIRED',
	forgotPassword = 'PASSWORD_FORGOTTEN',
	paswordRequired = 'PASSWORD_REQUIRED',
	customChallenge = 'CUSTOM_CHALLENGE', // SMS MFA
}

export interface AuthenticationResponse {}

export interface Challenge {
	customChallengeName?: ChallengeType
	lastChallengeError?: ApiErrorCode
	initialPasswordSet?: string
	remainingTries?: string
	previousChallengeName?: ChallengeType
	successful?: boolean
	transactionId?: MfaResponseData['transactionId']
	USERNAME?: string
}

export interface ChallengeV2 {
	challengeName?: ChallengeTypeV2
	lastChallengeError?: APIErrorCodeV2
	initialPasswordSet?: string
	remainingTries?: string
	previousChallengeName?: ChallengeType
	successful?: boolean
	transactionId?: MfaResponseData['transactionId']
	USERNAME?: string
}

export interface MfaAction {
	transactionId: string
	onSuccess: () => void
}

const authContext = createContext<AuthContext>({})

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }: any) {
	const auth: any = useProvideAuth()
	return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
	return useContext(authContext)
}

// Provider hook that creates auth object and handles state
function useProvideAuth() {
	// const [userData, setUserData] = useState<UserData | undefined>()
	const userData = useRef<UserData | undefined>()
	const registerCode = useRef<string | undefined>()
	const session = useRef<any>()
	const tokenIssuedAt = useRef<{ iat: number; diffToLocalTime: number }>()
	const user = useRef()
	const [challenge, setChallenge] = useState<Challenge | undefined>()
	const [challengeV2, setChallengeV2] = useState<ChallengeV2>({})
	const [userName, setUserName] = useState<string>()
	const mfaActions = useRef<{ [key: string]: () => void }>({})
	const path = usePath()
	const [authVersion, setAuthVersion] = useState<AuthVersion>()
	const { getMainPath, navigateTo } = useRouteHelper()
	const authApi = useAuthApi()

	// just used to force a re-render
	const [userInitialized, setUserInitialized] = useState<boolean>(false)
	const [amplifyInitialized, setAmplifyInitialized] = useState<boolean>(false)
	const queryClient = useQueryClient()
	const { checkLanguageSetting } = useLanguagePref()
	const api = useApi()
	const { getAWSConfig, configLoaded } = useAWSConfig()

	const getUserPoolId = async (userName?: string, force?: boolean) => {
		let userPoolId: string

		userPoolId = force ? '' : getSessionStorage('userPoolId', '')

		/** to initialize Amplify either userPoolId from sessionStorage is needed
		 *  or a userName to retrieve the userpoolid
		 *  */
		if (!userPoolId && !userName) {
			return { userPoolId: null, isOldUserPool: undefined }
		} else if (!userPoolId && userName) {
			userPoolId = await api.getCurrentCognitoUserPool({ identNumber: userName })
		}

		const isOldUserPool = process.env.REACT_APP_OLD_AWS_USERPOOL === userPoolId
		setAuthVersion(isOldUserPool ? '1' : '2')
		return { userPoolId, isOldUserPool }
	}

	/**
	 *  The users userPool is most likely not known at first.
	 *  It can be retrieved from session Storage to keep to user login on hard reload
	 *  or via API-Call on manual login
	 */
	const initializeAmplify = async (userPoolId?: string | null) => {
		if (!userPoolId) {
			console.log('could not init amplify: no userpoolid provided')
			return false
		}

		const awsConfig = getAWSConfig(userPoolId)

		if (!awsConfig) {
			console.log('could not init amplify: no AWS Config provided')
			return false
		}

		const initialized = await initAmplify(awsConfig)

		setAmplifyInitialized(initialized)
		setSessionStorage('userPoolId', userPoolId)

		return true
	}

	// /* Auth v2 method */
	// const requestPasswordReset = async (username: string) => {
	// 	// try {
	// 	// 	const response = Auth.forgotPassword(username)
	// 	// 	console.log(response)
	// 	// } catch (e: any) {
	// 	// 	setChallengeV2({ ...challengeV2, lastChallengeError: e.code })
	// 	// }
	// }

	const sendUserId = async ({ username }: { username: string }) => {
		try {
			const { userPoolId, isOldUserPool } = await getUserPoolId(username, true)

			if (!userPoolId) {
				return
			}

			await initializeAmplify(userPoolId)
			const authVersion: AuthVersion = isOldUserPool ? '1' : '2'

			// old version
			if (authVersion === '1') {
				const currentUser = await trackPromise(Auth.signIn(username.toLowerCase().trim()))
				user.current = currentUser
				setChallenge(currentUser.challengeParam)
			} else {
				/**
				 * save username
				 * username and password need to be send together in new auth flow
				 */
				setUserName(username)
				setChallengeV2({ challengeName: ChallengeTypeV2.paswordRequired })
			}

			return true
		} catch (e) {
			console.error('error', e)
			return false
		}
	}

	// const getCurrentUserPoolConfig = (): string => {
	// 	const config: AmplifyConfig = Amplify.configure()
	// 	return (config as any).userPoolId
	// }

	const updateTokenIssuedAt = (currentUserData: UserData) => {
		const { iat } = currentUserData
		const diffToLocalTime = iat * 1000 - new Date().getTime()

		tokenIssuedAt.current = {
			iat: iat * 1000,
			diffToLocalTime,
		}
	}

	/**
	 * TODO: USE SEPERATE HOOK
	 * V2
	 * login method for the new authentication flow
	 */
	const authenticateMigratedUser = async (fields: { [key: string]: string }) => {
		if (!fields.password || !userName) {
			return
		}

		try {
			const userSession = await Auth.signIn({ username: userName, password: fields.password })

			if (userSession.signInUserSession?.idToken?.payload?.['custom:weak_authenticated'] === 'true') {
				registerCode.current = fields.password
				navigateTo(getMainPath(routesDictionary.register), false, { skip: 'true' })
			} else {
				session.current = userSession
				setChallengeV2({ challengeName: userSession.challengeName })
			}
		} catch (e: any) {
			setChallengeV2({ ...challengeV2, lastChallengeError: e.code })
		}
	}

	const sendChallengeAnswer = async (answer: { [key: string]: string }): Promise<boolean | Challenge> => {
		const value = Object.values(answer)[0]

		try {
			const USERNAME = challenge?.USERNAME
			const currentUser = await trackPromise(Auth.sendCustomChallengeAnswer(user.current, value))
			const previousChallengeName = challenge?.customChallengeName
			user.current = currentUser

			if (currentUser.challengeParam?.customChallengeName === previousChallengeName) {
				return {
					...currentUser.challengeParam,
					successful: false,
					USERNAME,
				}
			}

			const updatedChallenge = {
				...currentUser.challengeParam,
				previousChallengeName,
				USERNAME,
			}

			setChallenge(updatedChallenge)

			return true
		} catch (e) {
			console.error('error', e)
			/**
			 * if an error is returned, the users' login session
			 * timed out. send the user to login screen again and
			 * reset formState
			 */
			setChallenge({ lastChallengeError: ApiErrorCode.SESSION_TIMEOUT })
			return false
		}
	}

	/**
	 *
	 *
	 */
	const sendChallengeAnswerV2 = async (answer: string) => {
		if (!session.current || !answer) {
			return false
		}

		try {
			const response = await Auth.sendCustomChallengeAnswer(session.current, answer)

			if (response.authenticationResult) {
				user.current = response
			}

			/* if the server responds with the same challenge, the answering most likely failed*/
			if (response.challengeName === challengeV2.challengeName) {
				if (response.Session) {
					session.current = response
				}

				setChallengeV2({ ...challengeV2, successful: false })
				return true
			}

			if (response.challengeName) {
				setChallengeV2({ challengeName: response.challengeName })
				return true
			}

			resetChallenge()
			return true
		} catch (e: any) {
			setChallengeV2({ ...challengeV2, successful: false, lastChallengeError: e.code })
			return false
		}
	}

	const signout = async () => {
		try {
			await Auth.signOut({ global: true })
			// } catch (e) {
			// console.log('signout error', e)
		} finally {
			Object.keys(Cookies.get()).forEach((cookieName) => {
				/**
				 * clear cookies from domain with an without leading dot,
				 * to make sure they are deleted in all browsers
				 */
				const neededAttributes = {
					path: '/',
					domain: `.${process.env.REACT_APP_COOKIE_DOMAIN}`,
				}
				Cookies.remove(cookieName, neededAttributes)

				neededAttributes.domain = String(process.env.REACT_APP_COOKIE_DOMAIN)
				Cookies.remove(cookieName, neededAttributes)
			})

			if (isDev()) {
				clearLocalStorage()
			}

			// clear all query caches
			queryClient.clear()
			userData.current = undefined
			resetChallenge()
			setUserInitialized(false)
			clearSessionStorage()
		}
	}

	const requireSecondFactor = (transactionId: MfaResponseData['transactionId']) => {
		authApi.requireMfa(transactionId)

		setChallenge({
			transactionId,
			USERNAME: userData.current ? userData.current['cognito:username'] : undefined,
			customChallengeName: ChallengeType.mfa,
		})
	}

	const confirmedSecondFactor = () => {
		setChallenge({
			previousChallengeName: ChallengeType.mfa,
		})
	}

	const setCustomChallenge = (newChallenge: ChallengeType) => {
		setChallenge({ ...challenge, customChallengeName: newChallenge, previousChallengeName: undefined })
	}

	const resetChallenge = () => {
		switch (authVersion) {
			case '1':
				return setChallenge(undefined)
			case '2':
				setChallengeV2({})
		}
	}

	const addMfaAction = (action: MfaAction) => {
		mfaActions.current[action.transactionId] = action.onSuccess
	}

	const removeMfaAction = (transactionId?: MfaAction['transactionId']) => {
		if (undefined === transactionId) {
			return
		}

		delete mfaActions.current[transactionId]
	}

	const runMfaAction = (transactionId?: MfaAction['transactionId']) => {
		if (undefined === transactionId) {
			return
		}

		// console.log('REF;', { ...mfaActions.current }, transactionId)

		const action = mfaActions.current[transactionId]

		// console.log('ACTION;', action)

		if (undefined !== action) {
			action()

			removeMfaAction(transactionId)
		}
	}

	const resolveUserGroups = (
		updatedUserData: any,
		userPoolId: string,
		weaklyAuthenticated: boolean,
		isOldUserPool: boolean
	) => {
		const userGroups = []

		userGroups.push(isOldUserPool ? UserGroup.NotMigratedToNewPool : UserGroup.MigratedToNewPool)

		userGroups.push(weaklyAuthenticated ? UserGroup.WeaklyAuthenticated : UserGroup.StronglyAuthenticated)

		switch (updatedUserData.appAccessRestriction) {
			case AppAccessRestriction.LEISTUNGSFALL:
				userGroups.push(UserGroup.Leistungsfall)
				break
			case AppAccessRestriction.UNVERFALLBAR_AUSGESCHIEDEN:
				userGroups.push(UserGroup.UnverfallbarAusgeschieden)
				break
		}

		if (updatedUserData.noBEMGR === 'true') {
			userGroups.push(UserGroup.noBEMGR)
		}

		if (['ZFVorsorge-VO2020', 'VO20'].includes(updatedUserData?.pensionRuleSetCode)) {
			userGroups.push(UserGroup.Vo20)
		}

		if (['ZFVorsorge-VO2019', 'VO19'].includes(updatedUserData?.pensionRuleSetCode)) {
			userGroups.push(UserGroup.Vo19)
		}
		if (updatedUserData?.employeeGroup === 'Vorstand') {
			userGroups.push(UserGroup.Vorstand)
		}
		if (updatedUserData?.employeeGroup === 'EMG') {
			userGroups.push(UserGroup.ExecutiveManagement)
		}
		if (updatedUserData?.employeeGroup === 'GET') {
			userGroups.push(UserGroup.GlobalExecutiveTeam)
		}

		return userGroups
	}

	/**
	 * check if current language equals the saved language
	 */
	const savePreferredLanguage = (userData: any) => {
		if (!deepl) {
			return
		}

		checkLanguageSetting(userData)
	}

	const checkUserAuthentication = async (bypassCache: boolean = false) => {
		// console.log('checking user auth')
		const { userPoolId, isOldUserPool } = await getUserPoolId()

		if (!userPoolId) {
			// console.log('no userpool found')
			return
		}

		if (!amplifyInitialized) {
			// console.log('initializing amplify')
			await initializeAmplify(userPoolId)
		}

		if (authVersion === undefined) {
			// console.log('setting auth version')
			setAuthVersion(isOldUserPool ? '1' : '2')
		}

		Auth.currentAuthenticatedUser({
			bypassCache, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
		})
			.then((currentUser) => {
				const userNotInitialized = undefined === userData.current

				const updatedUserData = currentUser.signInUserSession.idToken.payload

				if (updatedUserData['custom:weak_authenticated'] === 'true') {
					return
				}

				const userGroupsToAdd = resolveUserGroups(
					updatedUserData,
					userPoolId,
					!!currentUser.session,
					isOldUserPool
				)

				// TODO: ask backend to include email address to the JWT payload
				updatedUserData.email = currentUser?.attributes?.email

				updateTokenIssuedAt(updatedUserData)

				const userGroups: string[] = updatedUserData['cognito:groups'] || []
				updatedUserData.groups = [...userGroups, ...userGroupsToAdd]

				if (updatedUserData.groups.length === 0) {
					updatedUserData.groups = [UserGroup.None]
				}

				userData.current = updatedUserData

				/**
				 * start the autoLogoutTimeout after every tokenRefresh
				 */
				if (true === bypassCache) {
					clearTimeout(autoLogoutTimeout)
					const autoLogoutTime = Number(process.env.REACT_APP_AUTO_LOGOUT_IN_MINUTES) * 60 * 1000

					autoLogoutTimeout = setTimeout(() => {
						// console.log('autologout - timeout expired')
						navigateTo(getMainPath(routesDictionary.logout), false, { autoLogout: true })
					}, autoLogoutTime)
				}

				if (true === userNotInitialized) {
					setUserInitialized(true)
				}

				/**
				 * save preferred Language
				 */

				setTimeout(() => {
					savePreferredLanguage(updatedUserData)
				}, 3000)
			})
			.catch(() => {
				if (undefined !== userData.current) {
					// console.log('autologout - catch')
					navigateTo(getMainPath(routesDictionary.logout), false, { autoLogout: true })
				}
			})

		/**
		 * HINT:
		 * currently amplify does not return an error if the access token has
		 * been revoked, e.g. by logging out on another device
		 *
		 * as a workaround the currentUserInfo is checked.if it returns an
		 * empty object, the access token has been revoked
		 */
		Auth.currentUserInfo().then((userObject) => {
			if (null !== userObject && Object.entries(userObject).length === 0 && userObject.constructor === Object) {
				// console.log('autologout - empty object check')
				navigateTo(getMainPath(routesDictionary.logout), false, { autoLogout: true })
			}
		})
	}

	const checkTokenValidity = () => {
		if (undefined === tokenIssuedAt.current || undefined === userData.current) {
			return
		}

		clearTimeout(refreshTokenTimeout)

		const tokenAge = new Date().getTime() + tokenIssuedAt.current.diffToLocalTime - tokenIssuedAt.current.iat
		const maxTokenAge = Number(process.env.REACT_APP_AUTO_LOGOUT_IN_MINUTES) * 60 * 1000
		const remainingTokenValidity = maxTokenAge - tokenAge
		const autoRefreshTime = Number(process.env.REACT_APP_AUTO_TOKEN_REFRESH_IN_SECONDS) * 1000

		if (remainingTokenValidity <= 0) {
			navigateTo(getMainPath(routesDictionary.logout), false, { autoLogout: true })
			return
		}

		/**
		 * if the current tokens' validity is less than the autoRefreshTimeout,
		 * the token has to be refreshed immediately, to prevent it from being invalidated
		 *
		 * otherwise start a timer to refresh the token automatically after
		 * n seconds (autoRefreshTimeout).
		 * this only happens if the user does not interact with the app for the time
		 * of the autoRefreshTimeout
		 *
		 * as this effect only runs after changing the page or making an api request,
		 * maximum valid user sessing is maxTokenAge + autoRefreshTimeout
		 */
		if (autoRefreshTime > remainingTokenValidity) {
			checkUserAuthentication(true)
		} else {
			refreshTokenTimeout = setTimeout(() => {
				checkUserAuthentication(true)
			}, autoRefreshTime)
		}

		return () => clearTimeout(refreshTokenTimeout)
	}

	/**
	 * this effect only runs after changing the page
	 */
	useEffect(() => {
		checkTokenValidity()
		// eslint-disable-next-line
	}, [path])

	/**
	 * 1. this effect makes sure that the current user is loaded again when reloading the page
	 * 2. it clears the autoLogoutTimeout when the view is destroyed
	 * 3. it runs checkUserAuthentication with bypassCache set to false when challenge updates
	 *    this way userData is set when the user is logged in successfully
	 * 4. it runs checkUserAuthentication with bypassCache set to false when page becomes
	 *    visible again, to check if user was logged out
	 */
	useEffect(() => {
		configLoaded && checkUserAuthentication(false)
		return clearTimeout(autoLogoutTimeout)
		// eslint-disable-next-line
	}, [challenge, configLoaded, challengeV2])

	// usePageVisibility(() => checkUserAuthentication(false))

	// Return the user object and auth methods
	return {
		authVersion,
		userData: userData.current,
		userName,
		challenge,
		challengeV2,
		checkTokenValidity,
		sendUserId,
		initializeAmplify,
		sendChallengeAnswer,
		sendChallengeAnswerV2,
		signout,
		requireSecondFactor,
		confirmedSecondFactor,
		resetChallenge,
		addMfaAction,
		authenticateMigratedUser,
		removeMfaAction,
		runMfaAction,
		setCustomChallenge,
		userInitialized,
		amplifyInitialized,
		setAmplifyInitialized,
		getUserPoolId,
		checkUserAuthentication,
		session,
		registerCode: registerCode.current,
	}
}
