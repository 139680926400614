import { useAuth } from 'hooks/useAuth'
import React, { FunctionComponent, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Button, { ButtonType } from 'shared/components/Button'

const tanServiceUrl = process.env.REACT_APP_SERVICE_TAN_URL

const windowFeatures = {
	height: 800,
	width: 400,
	dialog: 1,
	alwaysRaised: 1,
	menubar: 0,
	toolbar: 0,
	location: 0,
	status: 0,
	left: 0,
	top: 0,
}

interface TanServiceButtonProps {
	onReceivedTan: (tan: number) => void
	onClick?: () => void
}

const TanServiceButton: FunctionComponent<TanServiceButtonProps> = ({ onReceivedTan, onClick }) => {
	const { t } = useTranslation()
	const auth = useAuth()
	const challenge = auth.challenge
	const openerwindow = useRef<any>(null)

	windowFeatures.left = window.screen.width / 2 - windowFeatures.width / 2
	windowFeatures.top = window.screen.height / 2 - windowFeatures.height / 2

	const onMessageReceivedFromApp = (event: MessageEvent) => {
		if (tanServiceUrl !== event.origin) {
			return
		}

		const { tan, event: messageEvent } = event.data

		switch (messageEvent) {
			case 'tanSent':
				onReceivedTan(tan)
				break

			case 'closeTanWindow':
				openerwindow.current.close()
				window.focus()
				break
		}
	}

	useEffect(() => {
		window.addEventListener('message', onMessageReceivedFromApp)
		return () => window.removeEventListener('message', onMessageReceivedFromApp)
		// eslint-disable-next-line
	}, [])

	const openTanService = () => {
		if (onClick) {
			onClick()
		}

		const parsedWindowFeatures = Object.entries(windowFeatures)
			.map((entry: [string, number]) => entry.join('='))
			.join(',')

		let url = tanServiceUrl

		if (challenge?.USERNAME) {
			url += `?id=${challenge.USERNAME}`
		}

		openerwindow.current = window.open(url, 'TanServiceWindow', parsedWindowFeatures)
	}

	return (
		<Button
			className="tan-service-button"
			type={ButtonType.primary}
			label={t('component.tanServiceButton.label')}
			onClick={openTanService}
		/>
	)
}

export default TanServiceButton
