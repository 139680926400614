import React, { FunctionComponent, PropsWithChildren } from 'react'

export interface BadgeProps {
	count?: number
}

const Badge: FunctionComponent<PropsWithChildren<BadgeProps>> = (props) => {
	return (
		<div className={`badge ${props.count || props.children ? 'badge--visible' : ''}`}>
			{props.count}
			{props.children}
		</div>
	)
}

export default Badge
