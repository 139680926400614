import React, { useEffect } from 'react'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import globalHook, { Store } from 'use-global-hook'

// Defining your own state and associated actions is required
export interface HeaderState {
	backButtonPath: string | undefined
	backButtonClickHandler:
		| {
				name: (parameters?: any) => void
				parameters?: any
		  }
		| undefined
	navigationTitle: string | React.ReactElement | undefined
}

// Associated actions are what's expected to be returned from globalHook
export interface HeaderActions {
	setBackButtonPath: (backButtonPath: HeaderState['backButtonPath']) => void
	setBackButtonClickHandler: (backButtonClickHandler: HeaderState['backButtonClickHandler']) => void
	setNavigationTitle: (navigationTitle: HeaderState['navigationTitle']) => void
	resetState: () => void
}

const setBackButtonPath = (store: Store<HeaderState, HeaderActions>, backButtonPath: HeaderState['backButtonPath']) => {
	store.setState({ ...store.state, backButtonPath })
}

const setBackButtonClickHandler = (
	store: Store<HeaderState, HeaderActions>,
	backButtonClickHandler: HeaderState['backButtonClickHandler']
) => {
	store.setState({ ...store.state, backButtonClickHandler })
}

const setNavigationTitle = (
	store: Store<HeaderState, HeaderActions>,
	navigationTitle: HeaderState['navigationTitle']
) => {
	store.setState({ ...store.state, navigationTitle })
}

const resetState = (store: Store<HeaderState, HeaderActions>) => {
	store.setState(initialState)
}

const initialState: HeaderState = {
	backButtonPath: undefined,
	backButtonClickHandler: undefined,
	navigationTitle: undefined,
}

const actions = {
	setBackButtonPath,
	setBackButtonClickHandler,
	setNavigationTitle,
	resetState,
}

// FIXME: use-global-hook 0.3 removed "react" as a property from globalHook, but the types are not updated yet
// @ts-ignore
// const useHeaderState = globalHook<HeaderState, HeaderActions>(initialState, actions)

const useHeaderState = globalHook<HeaderState, HeaderActions>(React, initialState, actions)

export default useHeaderState

export const useBackButtonPath = (path?: HeaderState['backButtonPath']) => {
	const { getParentPath } = useRouteHelper()

	const [, setValue] = useHeaderState<HeaderActions['setBackButtonPath']>(
		undefined,
		(headerActions: HeaderActions) => headerActions.setBackButtonPath
	)

	useEffect(() => {
		setValue(path || getParentPath())
		// eslint-disable-next-line
	}, [])
}

export const useBackButtonClickHandler = (handler: HeaderState['backButtonClickHandler'] = undefined) => {
	const [, setValue] = useHeaderState<HeaderActions['setBackButtonClickHandler']>(
		undefined,
		(headerActions: HeaderActions) => headerActions.setBackButtonClickHandler
	)

	useEffect(() => {
		setValue(handler)
		// eslint-disable-next-line
	}, [])
}

export const useNavigationTitle = (title: HeaderState['navigationTitle']) => {
	const [, setValue] = useHeaderState<HeaderActions['setNavigationTitle']>(
		undefined,
		(headerActions: HeaderActions) => headerActions.setNavigationTitle
	)

	useEffect(() => {
		setValue(title)
		// eslint-disable-next-line
	}, [])
}

export const useResetHeaderState = () => {
	const [, reset] = useHeaderState<HeaderActions['resetState']>(
		undefined,
		(headerActions: HeaderActions) => headerActions.resetState
	)

	useEffect(() => {
		reset()
		// eslint-disable-next-line
	}, [])
}
