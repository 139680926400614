import theme, { barStackOffset, lineWidth, riskBenefitPreviewChartStyle } from 'components/ChartsTheme'
import useApi, { QueryKey } from 'hooks/useApi'
import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import {
	BarChartDataType,
	BarWithMask,
	ChartLabel,
	ChartLegend,
	getMinimumYValues,
	getYValue,
	setDataType,
} from 'shared/components/BarChart'
import { OPTEOptions, OPTTOptions, RiskOptions } from 'shared/enums'
import useResizeObserver from 'use-resize-observer'
// @ts-ignore
import {
	LineSegment,
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryLegend,

	// @ts-ignore
	VictoryThemeDefinition,
} from 'victory'

export interface RiskPreviewBenefitProps {
	className?: string
	type?: OPTEOptions | OPTTOptions
	riskId: RiskOptions
}

let resizeObserverTimeout: NodeJS.Timer
const padding = 24
const chartHeight = riskBenefitPreviewChartStyle.chartHeight

const RiskPreviewBenefit: React.FC<RiskPreviewBenefitProps> = (props) => {
	const chartTheme: VictoryThemeDefinition = theme
	const { t } = useTranslation()
	const { className, type, riskId } = props
	const api = useApi()

	const { data } = useQuery(
		[QueryKey.riskSimulationBenefit, { riskOption: type, riskId }],
		api.getRiskSimulationBenefit
	)

	const getClasses = useCallback((): string => {
		const classes: string[] = ['risk-benefit__preview-graph']

		if (className) {
			classes.push(className)
		}

		return classes.join(' ')
	}, [className])

	const [chartWidth, setChartWidth] = useState<number>(0)
	const { ref: wrapperRef } = useResizeObserver({
		onResize: ({ width }) => {
			if (undefined === width) {
				return
			}

			clearTimeout(resizeObserverTimeout)

			resizeObserverTimeout = setTimeout(() => {
				const updatedWidth = width - padding * 2
				setChartWidth(updatedWidth)
			}, 100)
		},
	})

	const { minimumValue, percentageToAddToSmallValues, maximaY } = useMemo(
		() =>
			getMinimumYValues(
				(data || []).map((entry) => {
					/**
					 * if the y value is 0, set it to 0.001 to show a tiny graph
					 */
					if (0 === entry.y) {
						entry.y = 0.001
					}

					return entry
				}),
				'y',
				0.005
			),
		[data]
	)

	const renderBars: JSX.Element | null = useMemo(() => {
		if (!data) {
			return null
		}

		return (
			<VictoryBar
				alignment="middle"
				dataComponent={<BarWithMask />}
				labels={({ datum }) => setDataType(datum.y, BarChartDataType.currency)}
				labelComponent={<ChartLabel />}
				style={{
					data: {
						...riskBenefitPreviewChartStyle.data,
						stroke: (currentData: any) => {
							return (
								riskBenefitPreviewChartStyle.barColors[currentData.index] ||
								riskBenefitPreviewChartStyle.fallbackColor
							)
						},
					},
					labels: {
						...riskBenefitPreviewChartStyle.labels,
					},
				}}
				data={data}
				//TODO: fix types
				x={(param: any) => {
					return setDataType(param.x, BarChartDataType.string)
				}}
				//TODO: fix types
				y={(param: any) => {
					return getYValue(param.y, minimumValue, percentageToAddToSmallValues)
				}}
			/>
		)
	}, [data, minimumValue, percentageToAddToSmallValues])

	const renderLine: JSX.Element | null = useMemo(() => {
		const y = barStackOffset
		return (
			<LineSegment
				x1={0}
				x2={chartWidth}
				y1={y}
				y2={y}
				style={{
					stroke: riskBenefitPreviewChartStyle.topLineColor,
					strokeDasharray: `0,${lineWidth * 2}`,
					strokeWidth: lineWidth,
					strokeLinecap: 'round',
				}}
			/>
		)
	}, [chartWidth])

	/**
	 * sets the domain padding, so the bars are centered on the graph
	 */
	const domainPaddingX = useMemo(() => {
		const numberOfBars = (data && data.length) || 0
		// this is a "good guess" value, but works for 1 - 6 graphs, and probably more
		const barWidthIncludingPadding = riskBenefitPreviewChartStyle.barWidth * 2
		const domainPadding = Math.abs(numberOfBars * barWidthIncludingPadding - chartWidth) / 2

		return domainPadding
	}, [chartWidth, data])

	return (
		<div className={getClasses()}>
			<p className="margin">
				<Trans i18nKey={`view.riskBenefit.change.effectOfSelection.previewBenefit.${props.riskId}`} />
			</p>
			<div className="bar-chart__chart-wrapper">
				<div className="bar-chart__chart pointer-events--force-none" ref={wrapperRef}>
					{!!data && chartWidth > 0 && chartHeight > 0 && (
						<>
							<header className="margin">
								<h3 className="no-margin">{setDataType(maximaY, BarChartDataType.currency)}</h3>

								<p className="margin--vertical margin--small no-margin--bottom">
									<Trans
										i18nKey={`view.riskBenefit.change.effectOfSelection.previewBenefitCopytext`}
									/>
								</p>
							</header>
							<VictoryChart
								theme={chartTheme}
								width={chartWidth}
								height={chartHeight}
								/**
								 * if both values are 0 (0 === fake 0.001) the maxDomain should be set
								 * in order to show a small bar chart. Otherwise the bars would be as high as
								 * the chart, when both values are 0
								 */
								maxDomain={{ y: 0.001 === maximaY ? 1 : undefined }}
								domainPadding={{ x: domainPaddingX, y: [0, 0] }}
								padding={{
									left: 0,
									right: 0,
									top: padding,
									bottom: padding,
								}}
								animate={{
									duration: 400,
									onLoad: { duration: 400 },
									// @ts-ignore
									animationWhitelist: ['data', 'size', 'domain'],
								}}
							>
								{renderLine}
								{renderBars}
								<VictoryAxis
									style={{
										axis: {
											stroke: riskBenefitPreviewChartStyle.axis.color,
											opacity: riskBenefitPreviewChartStyle.axis.opacity,
										},
									}}
									tickLabelComponent={<></>}
								/>
							</VictoryChart>
							<VictoryLegend
								theme={theme}
								orientation="vertical"
								data={data.map((datum) => ({ name: t(`view.riskBenefit.chartLabels.${datum.x}`) }))}
								dataComponent={<ChartLegend chartStyle={riskBenefitPreviewChartStyle} />}
								labelComponent={<></>}
								borderComponent={<></>}
								containerComponent={<div className="bar-chart__legend" />}
							/>
						</>
					)}
				</div>
			</div>
		</div>
	)
}

export default RiskPreviewBenefit
