import { IconType } from 'components/Icons'
import { DefaultTFuncReturn } from 'i18next'
import React, { FunctionComponent } from 'react'
import Icon from './Icon'
import Radiobutton, { RadiobuttonProps } from './Radiobutton'

export interface SelectableBarProps extends RadiobuttonProps {
	subheadline?: string | React.ReactElement | DefaultTFuncReturn
	icon?: IconType
	iconColor?: string
}

const SelectableBar: FunctionComponent<SelectableBarProps> = (props) => {
	const getClasses = (): string => {
		const classes = ['selectable-bar']

		if (props.className) {
			classes.push(props.className)
		}

		if (true === props.checked) {
			classes.push('selectable-bar--selected')
		}

		if (true === props.disabled) {
			classes.push('selectable-bar--disabled')
		}

		return classes.join(' ')
	}

	const handleClick = () => {
		if (props.disabled) {
			return
		}

		if (props.onClick) {
			props.onClick()
		}
	}

	return (
		<div className={getClasses()}>
			{!!props.icon && <Icon className="selectable-bar__icon" type={props.icon} color={props.iconColor} />}

			{/* subheadline must be placed before radiobutton for conditional styling */}
			{!!props.subheadline && <div className="selectable-bar__subheadline">{props.subheadline}</div>}

			<Radiobutton
				className="selectable-bar__radiobutton"
				onClick={handleClick}
				onChange={props.onChange}
				value={props.value}
				label={props.label}
				name={props.name}
				checked={props.checked}
				disabled={props.disabled}
			/>
		</div>
	)
}

export default SelectableBar
