import useApi, { QueryKey } from './useApi'
import { UserProfileData } from '../shared/interfaces'
import { useQuery } from 'react-query'

export const useIsMaxAge = () => {
	const api = useApi()
	const { data: profileData }: { data?: UserProfileData } = useQuery(api.getQuery(QueryKey.userProfile))
	const isMaxAge = !!(profileData?.alter?.value && Number(profileData?.alter.value) >= 59)
	return isMaxAge
}
