import { IconType } from 'components/Icons'
import { useAuth } from 'hooks/useAuth'
import { Link } from 'raviger'
import React, { FunctionComponent } from 'react'
import Icon from 'shared/components/Icon'
import LoadingSpinner, { LoadingSpinnerArea } from 'shared/components/LoadingSpinner'

export enum DashboardTileSize {
	extraLarge = 'dashboard-tile--xl',
	large = 'dashboard-tile--l',
	medium = 'dashboard-tile--m',
	smallMedium = 'dashboard-tile--sm',
	small = 'dashboard-tile--s',
}
export enum DashboardTileMediumSize {
	extraLarge = 'dashboard-tile--bp-medium--xl',
	large = 'dashboard-tile--bp-medium--l',
	medium = 'dashboard-tile--bp-medium--m',
	smallMedium = 'dashboard-tile--bp-medium--sm',
	small = 'dashboard-tile--bp-medium--s',
}

export interface DashboardTileProps {
	href?: string
	className?: string
	loading?: boolean
	size: DashboardTileSize
	sizeMedium?: DashboardTileMediumSize
	stretchContent?: boolean
	header?: string | React.ReactElement
	loadingSpinnerArea?: LoadingSpinnerArea
}

const DashboardTile: FunctionComponent<DashboardTileProps> = (props) => {
	const { userData } = useAuth()

	const getClassNames = (): string => {
		const classes: string[] = ['dashboard-tile']

		if (userData?.groups) {
			userData.groups.map((group) => {
				return classes.push(`dashboard-tile--user-status-${group}`)
			})
		}

		if (props.className) {
			classes.push(props.className)
		}

		if (props.size) {
			classes.push(props.size)
		}

		if (props.sizeMedium) {
			classes.push(props.sizeMedium)
		}

		return classes.join(' ')
	}

	if (undefined === props.href) {
		return null
	}

	return (
		<Link href={props.href} className={getClassNames()}>
			{props.loadingSpinnerArea && <LoadingSpinner area={props.loadingSpinnerArea} delay={0} />}

			<div className="dashboard-tile__content">
				{props.header && <div className="dashboard-tile__top flex flex--direction-column">{props.header}</div>}

				<div
					className={`dashboard-tile__${props.stretchContent ? 'total' : 'center'}${
						undefined !== props.loading ? ` visually-hidden visually-hidden--${String(!props.loading)}` : ''
					}`}
				>
					{props.children}
				</div>

				<Icon className="dashboard-tile__bottom" type={IconType.arrow} />
			</div>
		</Link>
	)
}

export default DashboardTile
