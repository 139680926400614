import { useAsyncEffect } from '@n1ru4l/use-async-effect/dist/module/use-async-effect'
import TermsAndConditions from 'components/TermsAndConditions'
import { ChallengeType, useAuth } from 'hooks/useAuth'
import { Link, useQueryParams } from 'raviger'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import Form, { FormFields, FormFieldType, FormRefActions } from 'shared/components/Form'

import { TextInputType } from 'shared/components/TextInput'
import Tooltip from 'shared/components/Tooltip'
import { ApiErrorCode } from 'shared/enums'
import isDev from 'shared/helper/isDev'
import { parseQueryString } from 'shared/helper/parseQueryString'
import useGlobalFormState from 'shared/hooks/useGlobalFormState'
import useGlobalModalState from 'shared/hooks/useGlobalModalState'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonClickHandler } from 'state/useHeaderState'
import SendPasswordLoginBodytext from './LoginPartials/SendPasswordLoginBodytext'
import SendPasswordRegisterBodytext from './RegisterPartials/SendPasswordRegisterBodytext'

/** currently used for V1 */
const SendPassword: React.FunctionComponent = () => {
	const [queryParams, setQueryParams] = useQueryParams(parseQueryString)

	const { t } = useTranslation()
	const auth = useAuth()
	const challenge = auth.challenge
	const { getMainPath, navigateTo } = useRouteHelper()

	useBackButtonClickHandler({ name: auth.resetChallenge })

	const [errorMessages, setErrorMessages] = useState<string[]>()
	const [formState, formActions] = useGlobalFormState()
	const sendPasswordForm = useRef<FormRefActions>()
	const [, modalActions] = useGlobalModalState()

	const inputElements: FormFields = useMemo(() => {
		let fields: FormFields = {
			password: {
				value:
					formState.password ||
					(isDev()
						? queryParams.setInitialPassword
							? (process.env.REACT_APP_INITIAL_PASSWORD as string)
							: (process.env.REACT_APP_PASSWORD as string)
						: ''),
				required: true,
				type: TextInputType.password,
				label: queryParams.setInitialPassword
					? t('view.register.formFieldLabels.initialPassword')
					: t('generic.password'),
				hidePasswordLabel: queryParams.setInitialPassword
					? t('view.register.formFieldLabels.hideInitialPassword')
					: undefined,
				showPasswordLabel: queryParams.setInitialPassword
					? t('view.register.formFieldLabels.showInitialPassword')
					: undefined,
				autoComplete: queryParams.setInitialPassword ? 'none' : 'current-password',
				fieldType: FormFieldType.text,
				append: queryParams.setInitialPassword ? (
					<Tooltip
						label={t('view.register.sendPassword.help.headline')}
						header={t('view.register.sendPassword.help.header')}
						headline={t('view.register.sendPassword.help.headline')}
						content={<Trans i18nKey="view.register.sendPassword.help.content" />}
					/>
				) : undefined,
			},
		}

		if (!queryParams.register && queryParams.setInitialPassword) {
			fields['termsandconditions'] = {
				required: true,
				fieldType: FormFieldType.checkBox,
				label: t('view.login.formFieldLabels.readTermsAndConditions'),
				size: 'large',
				className: 'font-size-s margin--vertical',
				value: 1,
				prepend: (
					<Button
						type={[ButtonType.link]}
						label={t('view.register.buttonLabel.goToTermsAndConditions')}
						onClick={() => {
							modalActions.setContent(
								<div className="padding">
									<TermsAndConditions></TermsAndConditions>
								</div>
							)
							modalActions.openModal()
						}}
						className="font-size-s text-color-cyan margin--vertical"
					/>
				),
			}
		}

		return fields
		// eslint-disable-next-line
	}, [queryParams.setInitialPassword, queryParams.register, t])

	useAsyncEffect(
		function* (setErrorHandler: any, c: any) {
			const { initialPassword } = queryParams

			if (undefined === initialPassword) {
				return
			}

			setQueryParams({ initialPassword: undefined })

			yield* c(auth.sendChallengeAnswer({ password: initialPassword }))
		},
		[queryParams]
	)

	useEffect(() => {
		if (undefined === challenge) {
			return
		}

		switch (challenge.lastChallengeError) {
			case undefined:
				/**
				 * FIXME:
				 * lastChallengeError is undefined when the initial password is wrong.
				 * to handle that case the error message is triggered manually.
				 * this should be fixed in the backend
				 */
				if (
					'true' === challenge.initialPasswordSet &&
					ChallengeType.loginMfa === challenge.previousChallengeName
				) {
					setErrorMessages([
						t(`apiErrors.${ApiErrorCode.INITIAL_USERNAME_OR_PASSWORD_INCORRECT}`, {
							error: ApiErrorCode.INITIAL_USERNAME_OR_PASSWORD_INCORRECT,
						}),
						t('view.login.formErrors.remainingTries', {
							remainingTries: challenge.remainingTries,
						}),
					])

					sendPasswordForm.current?.handleFormError()
				}

				return

			case ApiErrorCode.ACCOUNT_TEMPORARY_LOCKED:
				navigateTo(getMainPath(routesDictionary.accountLocked))
				return

			default:
				setErrorMessages([
					t(`apiErrors.${challenge.lastChallengeError}`, { error: challenge.lastChallengeError }),
					t('view.login.formErrors.remainingTries', {
						remainingTries: challenge.remainingTries,
					}),
				])

				sendPasswordForm.current?.handleFormError()

				return
		}
		// eslint-disable-next-line
	}, [challenge])

	if (queryParams.initialPassword) {
		return null
	}

	return (
		<div className="form-view">
			<div className="form-view__title">
				<h1>
					<Trans
						i18nKey={`view.${queryParams.setInitialPassword ? 'register' : 'login'}.sendPassword.pageTitle`}
					/>
				</h1>
			</div>

			<div className="form-view__bodytext">
				{queryParams.setInitialPassword && <SendPasswordRegisterBodytext />}
			</div>

			<Form
				ref={sendPasswordForm}
				className="form-view"
				fields={inputElements}
				onSubmit={(submittedFields: { [key: string]: any }) => {
					formActions.setFields(submittedFields)
					return auth.sendChallengeAnswer(submittedFields)
				}}
				errorMessages={errorMessages}
				submitLabel={t(
					`view.${queryParams.setInitialPassword ? 'register' : 'login'}.buttonLabel.sendPassword`
				)}
			>
				{!queryParams.setInitialPassword && (
					<div className="form-view__additional-fields">
						<Link
							className="margin--bottom"
							href={getMainPath(routesDictionary.passwordReset)!}
							basePath="/"
						>
							{t('view.login.buttonLabel.forgotPassword')}
						</Link>
						<SendPasswordLoginBodytext />
					</div>
				)}
			</Form>
		</div>
	)
}

export default SendPassword
