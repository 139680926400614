import { UserGroup } from 'shared/enums'
import { useAuth } from './useAuth'

export const useUserGroup = () => {
	const { userData } = useAuth()

	const belongsToGroup = (group: UserGroup): boolean | undefined => {
		return userData?.groups?.includes(group)
	}

	const isLeftWithEntitlement = belongsToGroup(UserGroup.UnverfallbarAusgeschieden)
	const isLeistungsfall = belongsToGroup(UserGroup.Leistungsfall)
	const isStronglyAuthenticated = belongsToGroup(UserGroup.StronglyAuthenticated)
	const isVO19 = belongsToGroup(UserGroup.Vo19)
	const isVO20 = belongsToGroup(UserGroup.Vo20)
	const isEMG = belongsToGroup(UserGroup.ExecutiveManagement)
	const isBoard = belongsToGroup(UserGroup.Vorstand)
	const isExecutive = belongsToGroup(UserGroup.GlobalExecutiveTeam)
	const isHigherUp = isEMG || isBoard || isExecutive
	const notMigrated = belongsToGroup(UserGroup.NotMigratedToNewPool)
	const noBEMGR = belongsToGroup(UserGroup.noBEMGR)

	return {
		userData,
		groups: userData?.groups,
		belongsToGroup,
		isEMG,
		isVO19,
		isVO20,
		isBoard,
		isExecutive,
		isHigherUp,
		isLeftWithEntitlement,
		isLeistungsfall,
		isStronglyAuthenticated,
		notMigrated,
		noBEMGR
	}
}
