import isTrue from "shared/helper/isTrue";

export const activatedFeatures = {
	deepl: isTrue(process.env.REACT_APP_FEATURE_DEEPL),
	payoutOptions: isTrue(process.env.REACT_APP_FEATURE_PAYOUT_OPTIONS),
	updatedTermsDialog: isTrue(process.env.REACT_APP_FEATURE_UPDATED_TERMS_DIALOG),
	leistungsfallVersorgungsguthabenPermission: isTrue(process.env.REACT_APP_FEATURE_LEISTUNGSFALL_VERSORGUNGSGUTHABEN_PERMISSION)
}



