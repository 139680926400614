import classNames from 'classnames'
import { DefaultTFuncReturn, t } from 'i18next'
import { useCallback } from 'react'
import useGlobalModalState from 'shared/hooks/useGlobalModalState'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'

type TModalProps = {
	imageSrc?: string
	headline?: string | React.ReactElement | DefaultTFuncReturn
	content?: string | React.ReactElement | DefaultTFuncReturn
	buttonLabel?: string | React.ReactElement | DefaultTFuncReturn
	onCloseAction?: () => void
	onCloseNavigationPath?: string
	type?: 'default' | 'success'
}

type FShowModal = () => void

export const useModal = (props?: TModalProps): FShowModal => {
	const [, modalActions] = useGlobalModalState()
	const { navigateTo } = useRouteHelper()

	const showModal: FShowModal = useCallback(() => {
		const { headline, content, buttonLabel, onCloseAction, onCloseNavigationPath, type = 'default' } = props || {}

		modalActions.setClass(`modal--${type}`)
		modalActions.setContent(
			<div className="padding">
				{headline && <h2 className={classNames('text-align--center')}>{headline}</h2>}

				{content && <p className={classNames('text-align--center')}>{content}</p>}
			</div>
		)

		modalActions.openModal()
		modalActions.setButtonLabel(buttonLabel || t('generic.done'))

		if (onCloseAction) {
			modalActions.setOnCloseAction(onCloseAction)
		}

		if (onCloseNavigationPath) {
			modalActions.setOnCloseAction(() => navigateTo(onCloseNavigationPath, true))
		}
	}, [modalActions, navigateTo, props])

	return showModal
}
