import { IconType } from 'components/Icons'
import { useUserGroup } from 'hooks/useUserGroup'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { QueryStatus, useQuery } from 'react-query'
import routesDictionary from 'routes'
import BarChart, { BarChartDataType } from 'shared/components/BarChart'
import Icon from 'shared/components/Icon'
import { UserGroup } from 'shared/enums'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { BarChartData } from 'shared/interfaces'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'
import useApi, { QueryKey } from '../../hooks/useApi'

const AllYears: React.FC = () => {
	const { getMainPath, navigateTo } = useRouteHelper()

	useBackButtonPath(getMainPath(routesDictionary.dashboard))
	useNavigationTitle(<Trans i18nKey="view.conversions.conversionOverview" />)

	const api = useApi()
	const {
		status,
		data,
	}: {
		status: QueryStatus
		data?:
			| {
					jahr: number
					summeEigenbeitraege: number
					summeArbeitgeberbeitraege: number
					hatOffeneWandlungInDerZukunft: boolean
			  }[]
			| false
	} = useQuery(api.getQuery(QueryKey.allCompensations))

	const mappedData: BarChartData[] | undefined =
		data !== false
			? data?.map((v, i) => {
					const jahr = v.hatOffeneWandlungInDerZukunft ? `${v.jahr}*` : `${v.jahr}`
					return {
						x: jahr,
						y0: v.summeEigenbeitraege,
						y1: v.summeArbeitgeberbeitraege,
					}
			  })
			: undefined

	const { t } = useTranslation()
	const { belongsToGroup } = useUserGroup()
	const isVorstand = belongsToGroup(UserGroup.Vorstand)

	const onClick = (year: string) => {
		if (isVorstand) return
		year = year.replace(/\D/g, '')
		// Math.round is needed, as somehow the cached values are sometimes converted to a float
		navigateTo(`${getMainPath(routesDictionary.conversions)}/${Math.round(parseInt(year))}`)
	}

	const renderDisclaimer = () => {
		return (
			<div className="margin--vertical flex flex--align-items-start flex--justify-content-center">
				<Icon className="margin--horizontal no-margin--left" type={IconType.info} />
				<Trans i18nKey="view.conversions.noDataDisclaimer" />
			</div>
		)
	}

	return (
		<div className="conversions center-view">
			{'success' === status && false === data && renderDisclaimer()}

			{mappedData && (
				<BarChart
					className={`conversions__chart ${isVorstand ? 'conversions__chart--no-drilling' : ''}`}
					data={mappedData}
					yDataType={BarChartDataType.currency}
					xDataType={BarChartDataType.string}
					panToEnd={true}
					forceXLabel={true}
					clickHandler={onClick}
					legend={[t('view.conversions.legend.ownContribution'), t('view.conversions.legend.conversionSum')]}
				/>
			)}
		</div>
	)
}

export default AllYears
