import Amplify from 'aws-amplify'
import { awsConfig } from 'hooks/useApi'

export const initAmplify = async (config: awsConfig) => {
	await Amplify.configure(config)

	if ('local' !== process.env.REACT_APP_CUSTOM_NODE_ENV) {
		Amplify.configure({
			Auth: {
				cookieStorage: {
					domain: process.env.REACT_APP_COOKIE_DOMAIN,
					expires: 1,
					secure: true,
				},
			},
		})
	}

	return true
}
