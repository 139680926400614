import { IconType } from 'components/Icons'
import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'shared/components/Button'
import dateFormat from 'shared/helper/dateFormat'
import { BarChartData } from 'shared/interfaces'
import useResizeObserver from 'use-resize-observer'
import {
	// @ts-ignore
	Bar,
	VictoryAxis,
	VictoryBar,
	// @ts-ignore
	VictoryChart,
	// @ts-ignore
	VictoryClipContainer,
	VictoryLabel,
	VictoryLegend,
	VictoryStack,
	// @ts-ignore
	VictoryThemeDefinition,
	VictoryZoomContainer,
} from 'victory'
import theme, { barStackOffset, conversionsChartStyle } from '../../components/ChartsTheme'
import { currencyFormat } from '../helper/numberFormats'

enum PanDirection {
	left,
	right,
}

export enum BarChartDataType {
	currency,
	number,
	string,
}

export interface BarChartProps {
	data: BarChartData[]
	panToEnd?: boolean
	stackBars?: boolean
	forceXLabel?: boolean
	className?: string
	xDataType?: BarChartDataType
	yDataType?: BarChartDataType
	legend?: string[]
	clickHandler?: (parameters?: any) => void
}

let domainChangeTimeout: NodeJS.Timeout
const animationDuration = 500
const animationDurationOnInit = 750
const padding = 24
const chartMinHeight = conversionsChartStyle.chartMinHeight
const zoomDomainPadding = 0.5

export const ChartLabel = (props: any) => {
	return (
		<g className="bar-chart__label bar-chart__label--y" transform={`translate(${props.x}, ${props.height})`}>
			{/* when the bar value is negative, dy has to be set to the label's fontSize. otherwise the label is not correctly positioned */}
			<VictoryLabel {...props} x={0} y={0} dy={props.dy < 0 ? 0 : -conversionsChartStyle.labels.fontSize} />
		</g>
	)
}

const ChartLabelWithButton = (props: any) => {
	return (
		<g
			className="bar-chart__label bar-chart__label--y"
			transform={`translate(${props.x}, ${props.height - 70})`}
			onClick={props.events.onClick}
		>
			{/* when the bar value is negative, dy has to be set to the label's fontSize. otherwise the label is not correctly positioned */}
			<VictoryLabel {...props} x={0} y={0} dy={props.dy < 0 ? 0 : -conversionsChartStyle.labels.fontSize} />
			<g transform="translate(0,40)">
				<circle r="20" fill={conversionsChartStyle.labelButtonColor} />
				<polyline
					stroke={conversionsChartStyle.labelButtonIconColor}
					strokeLinecap="round"
					strokeWidth="3"
					fill="none"
					points="0,0 10,10 0,20"
					transform="translate(-2.5,-10)"
				/>
			</g>
		</g>
	)
}

export const ChartLegend = (props: any) => {
	const { name } = props.datum
	const fill = props.chartStyle.barColors[props.index]

	return (
		<div className="legend-item" style={{ '--legend-item-color': fill } as React.CSSProperties}>
			{name}
		</div>
	)
}

export const BarWithMask = (props: any) => {
	const lineWidth = 4
	const isFirstStack = 1 === (props.datum._stack || 1)
	const maskId = `${props.id}-${props.datum._stack}-mask`

	const { y0 } = props
	let { y } = props
	const datumY = props.datum.y
	let maskY

	if (0 === datumY) {
		return null
	}

	if (datumY > 0) {
		y = y - barStackOffset
		maskY = y - lineWidth
	}

	if (datumY < 0) {
		y = y + barStackOffset
		maskY = y0 + lineWidth
	}

	return (
		<g className="bar-chart__bar">
			<defs>
				<linearGradient
					id={`maskGradientFirstStack-${maskId}`}
					x1="0"
					y1={+(datumY < 0)}
					x2="0"
					y2={+(datumY > 0)}
				>
					<stop offset="0.4" stopColor="white" />
					<stop offset="0.9" stopColor="white" stopOpacity="0.4" />
					<stop offset="1" stopColor="white" stopOpacity="0" />
				</linearGradient>
				<linearGradient id={`maskGradient-${maskId}`} x1="0" y1={+(datumY < 0)} x2="0" y2={+(datumY > 0)}>
					<stop offset="0.4" stopColor="white" />
					<stop offset="0.9" stopColor="white" stopOpacity="0.4" />
					<stop offset="1" stopColor="white" stopOpacity="0" />{' '}
				</linearGradient>
				<mask
					id={maskId}
					fill={isFirstStack ? `url(#maskGradientFirstStack-${maskId})` : `url(#maskGradient-${maskId})`}
				>
					<rect
						x={props.x - props.barWidth / 2 - lineWidth}
						y={maskY}
						width={props.barWidth + lineWidth * 2}
						height={Math.abs(y0 - y)}
					/>
				</mask>
			</defs>

			<Bar
				{...props}
				y0={y0}
				y={y}
				style={{
					...props.style,
					mask: `url(#${maskId})`,
				}}
			/>
		</g>
	)
}

export const getMinimumYValues = (
	dataY: BarChartData[],
	property: 'y' | 'y0' | 'y1' = 'y',
	minimumPercentage = 0.05,
	negativeValues = false
) => {
	const filteredData = dataY
		? Object.values(dataY)
				.filter((d) => 0 < Math.abs(d[property] || 0))
				.map((d: BarChartData) => {
					// if (false === negativeValues) {
					return Math.abs(d[property] || 0)
					// }

					// return (d[property] || 0) * -1
				})
		: []

	if (0 === filteredData.length) {
		return { minimumValue: 0, percentageToAddToSmallValues: 0 }
	}

	const maximaY = Math.max(...filteredData)

	const minimumValue = maximaY * minimumPercentage
	const percentageToAddToSmallValues = 0.5

	return { minimumValue, percentageToAddToSmallValues, maximaY }
}

export const setDataType = (datum: any, type?: BarChartDataType) => {
	switch (type) {
		case BarChartDataType.currency:
			return currencyFormat(datum, { fixedFractionDigits: 2 })

		case BarChartDataType.number:
			return Number(datum)

		case BarChartDataType.string:
			return String(datum)

		default:
			return datum
	}
}

/**
 * the bars with a value need a minimumValue to be visible
 *
 * @param y
 * @param minimumValue
 * @param percentageToAddToSmallValues
 */
export const getYValue = (y: number, minimumValue: number, percentageToAddToSmallValues: number) => {
	const absY = Math.abs(y)
	let returnValue = y

	if (minimumValue + minimumValue * percentageToAddToSmallValues > absY) {
		const prefix = 0 > y ? -1 : 1
		returnValue = prefix * (minimumValue + y * percentageToAddToSmallValues)
	}

	return returnValue
}

let resizeObserverTimeout: NodeJS.Timer

const BarChart: FunctionComponent<BarChartProps> = (props) => {
	const { t } = useTranslation()
	const chartTheme: VictoryThemeDefinition = theme

	const data = useMemo(() => {
		return props.data
	}, [props.data])

	const [chartWidth, setChartWidth] = useState<number>(0)
	const [chartHeight, setChartHeight] = useState<number>(0)
	const { ref: wrapperRef } = useResizeObserver({
		onResize: ({ width, height }) => {
			if (undefined === height || undefined === width) {
				return
			}

			clearTimeout(resizeObserverTimeout)

			resizeObserverTimeout = setTimeout(() => {
				setChartHeight(height > chartMinHeight ? height : chartMinHeight)

				const updatedWidth = width - padding * 2
				setChartWidth(updatedWidth)
			}, 100)
		},
	})
	const renderStarDisclaimer = () => {
		return <div>{t('view.deferredCompensation.disclaimerForOpenCompensation')}</div>
	}

	const visibleBars = useMemo(() => {
		const barCount = data.length
		const barWidthIncludingPadding = conversionsChartStyle.barWidth * 2.5
		const minimumChartWidth = barWidthIncludingPadding * barCount
		const tooManyBars = chartWidth > 0 ? Math.ceil((minimumChartWidth - chartWidth) / barWidthIncludingPadding) : 0

		return tooManyBars > 0 ? barCount - tooManyBars : barCount
	}, [data.length, chartWidth])

	const allowPan = data.length > visibleBars

	const [currentZoomDomain, setZoomDomain] = useState<[number, number]>()
	// const [animate, setAnimate] = useState<boolean>(true)
	const animate = useRef<boolean>(true)
	const [disallowedPanDirection, setDisallowedPanDirection] = useState<PanDirection>()

	useEffect(() => {
		let low = 1
		let high = visibleBars

		if (true === props.panToEnd) {
			low = data.length - visibleBars + 1
			high = data.length
		}

		// center the bars
		low = low - zoomDomainPadding
		high = high + zoomDomainPadding

		setZoomDomain([low, high])
	}, [visibleBars, data.length, props.panToEnd])

	/**
	 * pan left on click
	 */
	const panLeft = () => {
		if (undefined === currentZoomDomain) {
			return
		}

		const updatedZoomDomain: [number, number] = [
			Math.floor(currentZoomDomain[0]) - zoomDomainPadding,
			Math.floor(currentZoomDomain[1]) - zoomDomainPadding,
		]

		if (currentZoomDomain[0] > 1) {
			updateZoomDomain(updatedZoomDomain)
		}
	}

	/**
	 * pan right on click
	 */
	const panRight = () => {
		if (undefined === currentZoomDomain) {
			return
		}

		const updatedZoomDomain: [number, number] = [
			Math.ceil(currentZoomDomain[0]) + zoomDomainPadding,
			Math.ceil(currentZoomDomain[1]) + zoomDomainPadding,
		]

		if (currentZoomDomain[1] < data.length) {
			updateZoomDomain(updatedZoomDomain)
		}
	}

	/**
	 * enables animation before calling setZoomDomain
	 * @param domain
	 */
	const updateZoomDomain = (domain: any) => {
		if (false === animate.current) {
			animate.current = true

			// wait for next event loop, so animate is already set to false
			setTimeout(() => {
				setZoomDomain(domain)
			})
		} else {
			setZoomDomain(domain)
		}
	}

	/**
	 * enable/disable pan direction if the currentZoomDomain
	 * has reached the beginning/end of the chart
	 */
	useEffect(() => {
		if (undefined === currentZoomDomain) {
			return
		}

		if (currentZoomDomain[0] < 1) {
			setDisallowedPanDirection(PanDirection.left)
		} else if (currentZoomDomain[1] > data.length) {
			setDisallowedPanDirection(PanDirection.right)
		} else {
			setDisallowedPanDirection(undefined)
		}
	}, [currentZoomDomain, data.length])

	const classNames = useMemo(() => {
		const classes: string[] = ['bar-chart']

		if (props.className) {
			classes.push(props.className)
		}

		return classes.join(' ')
	}, [props.className])

	const centerZoomDomain = (domain: any) => {
		if (undefined === currentZoomDomain) {
			return
		}

		clearTimeout(domainChangeTimeout)
		/**
		 * disable animation on animationEnd
		 * this prevents the animation on mouse/touch panning
		 */
		if (true === animate.current) {
			animate.current = false
		}

		domainChangeTimeout = setTimeout(() => {
			let low = Math.ceil(domain.x[0]) - zoomDomainPadding
			let high = Math.ceil(domain.x[1]) - zoomDomainPadding

			if (low === currentZoomDomain[0]) {
				low -= 0.00001
				high -= 0.00001
			}

			updateZoomDomain([low, high])
		}, 500)
	}

	const handleClick = (datum: any) => {
		if (undefined !== props.clickHandler) {
			props.clickHandler(datum)
		}
	}

	const renderBars: JSX.Element | null = useMemo(() => {
		if (undefined !== data[0].y0) {
			return null
		}

		const { minimumValue, percentageToAddToSmallValues } = getMinimumYValues(data)

		return (
			<VictoryBar
				dataComponent={<BarWithMask />}
				labels={({ datum }) => setDataType(datum.y, props.yDataType)}
				labelComponent={<ChartLabelWithButton />}
				style={{
					data: {
						...conversionsChartStyle.data,
					},
					labels: {
						...conversionsChartStyle.labels,
					},
				}}
				data={data}
				x={(data) => {
					const { x } = data as Record<string, number>

					return setDataType(x, props.xDataType)
				}}
				y={(data) => {
					const { y } = data as Record<string, number>

					return getYValue(y, minimumValue, percentageToAddToSmallValues)
				}}
				events={[
					{
						target: 'data',
						eventHandlers: {
							onClick: () => {
								return [
									{
										target: 'data',
										mutation: ({ datum }) => {
											handleClick(datum.x)
										},
									},
								]
							},
						},
					},
					{
						target: 'labels',
						eventHandlers: {
							onClick: (e: any) => {
								// prevent click event bubbling when clicking label
								e.stopPropagation()

								return [
									{
										target: 'data',
										mutation: ({ datum }) => {
											handleClick(datum.x)
										},
									},
								]
							},
						},
					},
				]}
			/>
		)
		// eslint-disable-next-line
	}, [data])

	const renderStackedBars: JSX.Element[] | null = useMemo(() => {
		if (undefined === data[0].y0) {
			return null
		}

		const minima = [getMinimumYValues(data, 'y0'), getMinimumYValues(data, 'y1')]

		return data.map((chartData: any, index) => {
			return (
				<VictoryStack
					key={index}
					labels={() => {
						return setDataType((data[index].y0 || 0) + (data[index].y1 || 0), props.yDataType)
					}}
					labelComponent={<ChartLabelWithButton />}
				>
					{[chartData.y0, chartData.y1].map((dataY: any, dataindex: number) => {
						return (
							<VictoryBar
								key={`bar-${index}-${dataindex}`}
								dataComponent={<BarWithMask />}
								style={{
									data: {
										...conversionsChartStyle.data,
										stroke: ({ datum }) => {
											return (
												conversionsChartStyle.barColors[datum._stack - 1] ||
												conversionsChartStyle.fallbackColor
											)
										},
									},
									labels: {
										...conversionsChartStyle.labels,
									},
								}}
								data={[
									{
										/**
										 * use string here to prevent issues from auto setting range,
										 * e.g. if data does not start with 1, but 4
										 */
										x: String(chartData.x),
										y: dataY,
									},
								]}
								y={(data) => {
									const { y } = data as Record<string, number>

									return getYValue(
										y,
										minima[dataindex].minimumValue,
										minima[dataindex].percentageToAddToSmallValues
									)
								}}
								events={[
									{
										target: 'data',
										eventHandlers: {
											onClick: () => {
												return [
													{
														target: 'data',
														mutation: ({ datum }) => {
															handleClick(datum.x)
														},
													},
												]
											},
										},
									},
									{
										target: 'labels',
										eventHandlers: {
											onClick: (e: any) => {
												// prevent click event bubbling when clicking label
												e.stopPropagation()

												return [
													{
														target: 'data',
														mutation: ({ datum }) => {
															handleClick(datum.x)
														},
													},
												]
											},
										},
									},
								]}
							/>
						)
					})}
				</VictoryStack>
			)
		})
		// eslint-disable-next-line
	}, [data])
	return (
		<div className={classNames}>
			<div className="bar-chart__chart-wrapper">
				<Button
					className="bar-chart__pan bar-chart__pan--left"
					icon={IconType.arrow}
					iconRotate={180}
					iconColor={conversionsChartStyle.panButtonIconColor}
					onClick={panLeft}
					hidden={!allowPan}
					disabled={PanDirection.left === disallowedPanDirection}
				/>

				<Button
					className="bar-chart__pan bar-chart__pan--right"
					icon={IconType.arrow}
					iconColor={conversionsChartStyle.panButtonIconColor}
					onClick={panRight}
					hidden={!allowPan}
					disabled={PanDirection.right === disallowedPanDirection}
				/>

				<div className="bar-chart__chart" ref={wrapperRef} style={{ minHeight: chartMinHeight }}>
					{data && undefined !== currentZoomDomain && chartWidth > 0 && chartHeight > 0 && (
						<VictoryChart
							theme={chartTheme}
							width={chartWidth}
							height={chartHeight}
							containerComponent={
								<VictoryZoomContainer
									responsive={false}
									allowPan={allowPan}
									allowZoom={false}
									zoomDimension="x"
									zoomDomain={{ x: currentZoomDomain }}
									clipContainerComponent={
										<VictoryClipContainer
											clipPadding={{
												left: padding,
												top: padding,
												right: padding,
												bottom: padding * 6,
											}}
										/>
									}
									onZoomDomainChange={(domain) => centerZoomDomain(domain)}
								/>
							}
							// extra space before first bar and after last bar
							domainPadding={{ x: [padding * 2, padding * 2], y: [0, 0] }}
							padding={{
								left: 0,
								right: 0,
								top: padding,
								bottom: padding * 6,
							}}
							animate={
								animate.current
									? {
											duration: animationDuration,
											onLoad: { duration: animationDurationOnInit },
											// @ts-ignore
											animationWhitelist: ['data', 'size'],
									  }
									: undefined
							}
						>
							{undefined !== data[0].y0 ? renderStackedBars : renderBars}
							<VictoryAxis
								style={{
									axis: {
										stroke: conversionsChartStyle.axis.color,
										opacity: conversionsChartStyle.axis.opacity,
									},
								}}
								/**
								 * explictly setting tickValues is necessary as otherwise
								 * there can be too many ticks, when scrolling to the end
								 */
								tickValues={data.map((datum) => {
									return datum.x
								})}
								tickFormat={(x) => {
									if (!props.forceXLabel && undefined !== data[0].y0) {
										if (x.includes('*')) {
											const month = parseInt(x.replace(/\D/g, ''))
											return (
												dateFormat(new Date(Date.UTC(0, month - 1)), {
													month: 'short',
												}) + '*'
											)
										}
										return dateFormat(new Date(Date.UTC(0, x - 1)), { month: 'short' })
									} else {
										return x
									}
								}}
								tickLabelComponent={
									<VictoryLabel
										y={chartHeight - 105}
										className="bar-chart__label bar-chart__label--x"
									/>
								}
								events={[
									{
										target: 'tickLabels',
										eventHandlers: {
											onClick: () => {
												return [
													{
														mutation: ({ datum }: { datum: number }) => {
															const x = data[datum - 1].x
															handleClick(x)
														},
													},
												]
											},
										},
									},
								]}
							/>
						</VictoryChart>
					)}
				</div>
				{props.legend && (
					<VictoryLegend
						theme={chartTheme}
						orientation="vertical"
						data={props.legend.map((name: string) => ({ name }))}
						dataComponent={<ChartLegend chartStyle={conversionsChartStyle} />}
						labelComponent={<></>}
						borderComponent={<></>}
						containerComponent={<div className="bar-chart__legend" />}
					/>
				)}
			</div>
			<>{renderStarDisclaimer()}</>
		</div>
	)
}

export default BarChart
