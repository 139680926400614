import React, { FunctionComponent, PropsWithChildren, ReactElement } from 'react'
import { numberFormat } from 'shared/helper/numberFormats'
import { useUniqueId } from '../hooks/useUniqueInputId'

interface RangeSliderProps {
	type?: string
	label?: string
	className?: string
	name?: string
	value?: string | number
	min?: number
	max?: number
	minValue?: number
	maxValue?: number
	unit?: string
	step?: number
	readonly?: boolean
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
	showDots?: boolean
	disabled?: boolean
}

const RangeSlider: FunctionComponent<PropsWithChildren<RangeSliderProps>> = (props) => {
	const id = useUniqueId('range-slider')

	const initalAttributes = () => {
		const filteredAttributes: any = { ...props }
		const propsToRemove = ['value', 'className', 'label', 'showDots', 'unit', 'minValue', 'maxValue', 'onChange']

		for (const prop of Object.keys(filteredAttributes)) {
			if (propsToRemove.includes(prop)) {
				delete filteredAttributes[prop]
			}
		}

		return filteredAttributes
	}

	const attributes = {
		...initalAttributes(),
		id: props.label ? id : undefined,
	}

	const getClasses = (): string[] => {
		const classes = []

		if (props.className) {
			classes.push(props.className)
		}

		if (true === props.disabled) {
			classes.push('range-slider--disabled')
		}

		if (true === props.readonly) {
			classes.push('range-slider--read-only')
		}

		return classes
	}

	const label = (): ReactElement | undefined => {
		if (!props.label) {
			return
		}

		return (
			<label className="input__label" htmlFor={id}>
				{props.label}
			</label>
		)
	}

	const renderDots = () => {
		if (!props.showDots) {
			return
		}

		const amount = (props.max as number) - (props.min as number) + 1

		return (
			<div className="range-slider__dots">
				{Array.from({ length: amount }, (v, k) => k + 1).map((key) => (
					<span key={`range-slider__dot--${key}`} className="range-slider__dot" />
				))}
			</div>
		)
	}

	const renderIndicator = () => {
		return (
			<div className="range-slider__indicator">
				{numberFormat(props.value)}
				{props.unit}
			</div>
		)
	}

	const renderScale = () => {
		return (
			<div className="range-slider__scale">
				<span>
					{props.min}
					{props.unit}
				</span>{' '}
				<span>
					{props.max}
					{props.unit}
				</span>
			</div>
		)
	}

	const getCurrentRange = () => {
		const currentValue = Number(props.value)
		const max = props.max as number
		const min = props.min as number

		if (currentValue === min) {
			return 0
		}

		return (currentValue - min) / (max - min)
	}

	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value: number = +event.target.value

		if (props.maxValue && value > props.maxValue) {
			event.target.value = String(props.maxValue)
		}

		if (props.minValue && value < props.minValue) {
			event.target.value = String(props.minValue)
		}

		if (props.onChange) {
			props.onChange(event)
		}
	}

	return (
		<div
			className={['input', 'input--range-slider', 'range-slider']
				.concat(getClasses())
				.filter((item) => undefined !== item)
				.join(' ')}
			style={
				{
					'--current-range': getCurrentRange(),
				} as React.CSSProperties
			}
		>
			{label()}
			{renderIndicator()}
			<input {...attributes} value={props.value} className="range-slider__tag" onChange={onChange} />
			{renderDots()}
			<div className="range-slider__current-range" />
			{renderScale()}
			{props.children}
		</div>
	)
}

RangeSlider.defaultProps = {
	type: 'range',
	min: 0,
	max: 100,
	step: 1,
	unit: '',
}

export default RangeSlider
