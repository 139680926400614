import useAsyncEffect from '@n1ru4l/use-async-effect'
import DocumentEntry from 'components/DocumentEntry'
import { IconType } from 'components/Icons'
import useApi, { QueryKey } from 'hooks/useApi'
import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { QueryStatus, useQuery, useQueryClient } from 'react-query'

import routesDictionary from 'routes'
import Icon from 'shared/components/Icon'
import SwitchSelect from 'shared/components/SwitchSelect'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { DocumentData, DocumentsData } from 'shared/interfaces'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'

const uploadedDocTypes = ['KRANKENVERSICHERUNG', 'RENTENBESCHEINIGUNG', 'BANKVERBINDUNG']

const Documents: React.FC = () => {
	const [tab, setTab] = useState<'inbox' | 'uploads'>('inbox')
	const { getMainPath } = useRouteHelper()
	useBackButtonPath(getMainPath(routesDictionary.dashboard))
	useNavigationTitle(<Trans i18nKey="view.documents.pageTitle" />)

	const api = useApi()
	const queryClient = useQueryClient()
	const { status, data }: { status: QueryStatus; data?: DocumentsData } = useQuery(api.getQuery(QueryKey.documents))
	const [currentDocument, setCurrentDocument] = useState<string>()

	useAsyncEffect(
		function* (setErrorHandler: any, c: any) {
			if (undefined === currentDocument) {
				return
			}

			const result = yield* c(queryClient.fetchQuery(QueryKey.documents, api.getDocuments, { staleTime: 0 }))

			if (!result) {
				return
			}

			queryClient.setQueryData(QueryKey.documents, result)
		},
		[currentDocument]
	)

	const handleOnOpenDocument = async (eventData: DocumentData) => {
		setCurrentDocument(eventData.documentId)
	}

	const filterDocuments = (getUploaded: boolean) => {
		return (v: DocumentData) => {
			return getUploaded ? uploadedDocTypes.includes(v.type) : !uploadedDocTypes.includes(v.type)
		}
	}
	const renderDocuments = () => {
		if ('success' !== status || true === [undefined, 0].includes(data?.documents?.length)) {
			return null
		}

		return (
			<>
				{data?.documents.filter(filterDocuments(tab !== 'inbox')).map((document) => {
					return (
						<DocumentEntry
							key={`document--${document.documentId}`}
							data={document}
							onOpenDocument={handleOnOpenDocument}
						/>
					)
				})}
			</>
		)
	}

	const renderDisclaimer = () => {
		if (
			'success' !== status ||
			false === [undefined, 0].includes(data?.documents?.filter(filterDocuments(tab !== 'inbox')).length)
		) {
			return null
		}

		return (
			<div className="margin--vertical flex flex--align-items-start flex--justify-content-center">
				<Icon className="margin--horizontal no-margin--left" type={IconType.info} />
				<Trans i18nKey="view.documents.noDataDisclaimer" />
			</div>
		)
	}

	return (
		<div className="documents center-view">
			<SwitchSelect
				options={[
					{
						value: 'inbox',
						label: <Trans i18nKey="view.documents.inbox" />,
					},
					{
						value: 'uploads',
						label: <Trans i18nKey="view.documents.uploads" />,
					},
				]}
				value={'inbox'}
				onChange={(e) => {
					if (e.target.value === 'inbox' || e.target.value === 'uploads') {
						setTab(e.target.value)
					}
				}}
			></SwitchSelect>
			{renderDisclaimer()} {renderDocuments()}
		</div>
	)
}

export default Documents
