import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath, useResetHeaderState } from 'state/useHeaderState'

const Imprint = () => {
	const { getMainPath } = useRouteHelper()
	useResetHeaderState()
	useBackButtonPath(getMainPath(routesDictionary.dashboard))

	const { t } = useTranslation()

	return (
		<div className="imprint center-view">
			<div className="center-view">
				<div className="page-title">
					<h1>
						<Trans i18nKey="view.imprint.pageTitle" />
					</h1>
				</div>

				<p>
					<Trans i18nKey="view.imprint.bodytext.responsibility">
						<a target="_blank" rel="noopener noreferrer" href={t('view.imprint.webLink')}>
							{' '}
						</a>
					</Trans>
				</p>
				<p>
					<Trans i18nKey="view.imprint.bodytext.address" />
				</p>
				<p>
					<Trans i18nKey="view.imprint.bodytext.contact">
						<a href={t('view.imprint.telLink')}>{'  '}</a>
						<a href={t('view.imprint.mailLink')}>{'  '}</a>
					</Trans>
				</p>
				<p>
					<Trans i18nKey="view.imprint.bodytext.boardMember" />
				</p>
				<p>
					<Trans i18nKey="view.imprint.bodytext.commercialRegister" />
				</p>
			</div>
		</div>
	)
}

export default Imprint
