import compositionIcon from 'assets/dashboard/compositionIcon.svg'
import contactIcon from 'assets/dashboard/contactIcon.svg'
import deferredCompensationIcon from 'assets/dashboard/deferredCompensation.svg'
import conversionsImage from 'assets/dashboard/transformationIcon.svg'
import { fallbackChartColor, lineChartColors } from 'components/ChartsTheme'
import DashboardTile, { DashboardTileMediumSize, DashboardTileSize } from 'components/Dashboard/DashboardTile'
import DocumentsDashboard from 'components/Dashboard/DocumentsDashboard'
import IndiceComparisonDashboard from 'components/Dashboard/IndiceComparisonDashboard'
import PensionAssetsDashboard from 'components/Dashboard/PensionAssetsDashboard'
import { IconType } from 'components/Icons'
import { activatedFeatures } from 'helper/activatedFeatures'
import useApi, { QueryKey } from 'hooks/useApi'

import { Fragment, useMemo } from 'react'
import { Trans } from 'react-i18next'
import { QueryStatus, useQuery } from 'react-query'
import routesDictionary from 'routes'
import Badge from 'shared/components/Badge'
import Icon from 'shared/components/Icon'
import { LoadingSpinnerArea } from 'shared/components/LoadingSpinner'
import dateFormat from 'shared/helper/dateFormat'
import { numberFormat, percentFormat } from 'shared/helper/numberFormats'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import {
	DocumentsData,
	FundData,
	FundPerformanceData,
	IndiceData,
	PensionAssetsData,
	UserProfileData,
} from 'shared/interfaces'
import { useResetHeaderState } from 'state/useHeaderState'
import { components } from 'types/api-interface'

import { payoutProgressGuard, ProgressKeys } from './Payout/ChapterOne/PayoutOverview'

const Dashboard = () => {
	useResetHeaderState()
	const api = useApi()
	const { getMainPath } = useRouteHelper()
	// const { isLeistungsfall } = useUserGroup()

	const {
		data: indiceComparison,
		status: indiceComparisonStatus,
	}: { data?: FundPerformanceData; status: QueryStatus } = useQuery(api.getQuery(QueryKey.fundPerformance))

	const {
		data: payoutModelInProgress,
	}: { data?: components['schemas']['AuszahlungsoptionenArbeitskopie']; status: QueryStatus } = useQuery(
		QueryKey.payoutModelInProgress,
		api.getPayoutModelInProgress
	)

	const { data: profile, status: profileStatus }: { data?: UserProfileData; status: QueryStatus } = useQuery(
		api.getQuery(QueryKey.userProfile)
	)

	const { data: pensionAssets, status: pensionAssetsStatus }: { data?: PensionAssetsData; status: QueryStatus } =
		useQuery(api.getQuery(QueryKey.pensionAssets))

	const { data: blocksAccountData }: { data?: components['schemas']['BausteinkontoOverview'] } = useQuery(
		api.getQuery(QueryKey.bausteinKonto)
	)

	const { data: documents, status: documentsStatus }: { data?: DocumentsData; status: QueryStatus } = useQuery(
		api.getQuery(QueryKey.documents)
	)

	const { data: indices, status: indicesStatus }: { data?: IndiceData[]; status: QueryStatus } = useQuery(
		api.getQuery(QueryKey.fundPerformanceIndices)
	)

	const payoutModelConfirmationDate = useQuery(QueryKey.payoutModelConfirmationDate, api.payoutModelConfirmationDate)

	const payoutModelIsInProgress =
		!payoutModelConfirmationDate.data &&
		payoutProgressGuard(payoutModelInProgress?.progressKey as ProgressKeys, ProgressKeys.CHAPTER_ONE_COMPLETED)

	const indicesComparisonPeriod = indiceComparison?.history

	const period = useMemo(() => {
		if (!indicesComparisonPeriod || !indiceComparison.indiceValues?.[0]?.x) {
			return <></>
		}

		const date = new Date(Number(indiceComparison.indiceValues[0].x))

		return (
			<>
				{dateFormat(date.getTime(), {
					year: 'numeric',
					month: '2-digit',
					day: '2-digit',
				})}
				{' – '}
				{dateFormat(indiceComparison.updatedAt, {
					year: 'numeric',
					month: '2-digit',
					day: '2-digit',
				})}
			</>
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [indicesComparisonPeriod])

	return (
		<div className="dashboard">
			<h2
				className={`dashboard__title text-align--left visually-hidden visually-hidden--${String(
					'success' !== profileStatus
				)}`}
			>
				<Trans i18nKey="view.dashboard.welcome" values={{ name: `${profile?.name?.value.trim()}` }} />
			</h2>

			<>
				<DashboardTile
					className="dashboard--indices-comparison"
					href={getMainPath(routesDictionary.indicesComparison)}
					size={DashboardTileSize.extraLarge}
					loadingSpinnerArea={LoadingSpinnerArea.indiceComparisonDashboard}
					loading={'success' === indiceComparisonStatus}
					header={
						<>
							<span className="title">
								<Trans i18nKey="view.dashboard.tile.indiceComparison.title" />
							</span>

							<div
								className={`visually-hidden visually-hidden--${String(
									'success' !== indiceComparisonStatus
								)}`}
							>
								{'success' === indiceComparisonStatus && undefined !== indiceComparison && (
									<div className="dashboard-tile__indice-comparison-status text-align--right flex--align-self-end">
										{indiceComparison.fundValues.map((fund: FundData, index: number) => {
											if (fund.lastValueFund === undefined) {
												return null
											}

											return (
												<Fragment key={index}>
													<span
														style={{ color: lineChartColors[index] || fallbackChartColor }}
														className="font-size-s"
													>
														{fund.fundDescription}
													</span>
													<span
														style={{ color: lineChartColors[index] || fallbackChartColor }}
														className="font-family-alternative--light font-size-l"
													>
														{percentFormat(fund.lastValueFund, {
															fixedFractionDigits: 2,
															addSignPrefix: true,
														})}
													</span>
												</Fragment>
											)
										})}
										{indiceComparison.fundValues.every(
											(item) => item.lastValueFund === undefined
										) &&
											'success' === indicesStatus &&
											undefined !== indices && (
												<>
													<span style={{ color: fallbackChartColor }} className="font-size-s">
														{
															indices.find(
																(indice: IndiceData) =>
																	indiceComparison.compareIndice === indice.value
															)?.label
														}
													</span>

													<span
														style={{ color: fallbackChartColor }}
														className="font-family-alternative--light font-size-l"
													>
														{`${numberFormat(indiceComparison?.lastValueIndice, {
															fixedFractionDigits: 2,
														})} ${indiceComparison.unit ?? ''}`}
													</span>
												</>
											)}

										<span>{period}</span>
									</div>
								)}
							</div>
						</>
					}
				>
					<div className="pointer-events--force-none">
						{'success' === indiceComparisonStatus && undefined !== indiceComparison && (
							<IndiceComparisonDashboard data={indiceComparison} />
						)}
					</div>
				</DashboardTile>
				<DashboardTile
					className="dashboard--fund-composition"
					href={getMainPath(routesDictionary.fundComposition)}
					size={DashboardTileSize.medium}
					stretchContent={true}
					header={
						<span className="title">
							<Trans i18nKey="view.dashboard.tile.fundComposition.title" />
						</span>
					}
				>
					<img className="padding" src={compositionIcon} alt="" />
				</DashboardTile>

				<DashboardTile
					className="dashboard--contact"
					href={getMainPath(routesDictionary.contact)}
					size={DashboardTileSize.small}
					stretchContent={true}
					header={
						<span className="title">
							<Trans i18nKey="view.dashboard.tile.contact.title" />
						</span>
					}
				>
					<img src={contactIcon} alt="" />
				</DashboardTile>
				<DashboardTile
					className="dashboard--risk-benefit"
					href={getMainPath(routesDictionary.riskBenefit)}
					size={DashboardTileSize.small}
					sizeMedium={DashboardTileMediumSize.smallMedium}
					stretchContent={true}
					header={
						<span className="title">
							<Trans i18nKey="view.dashboard.tile.riskBenefit.title" />
						</span>
					}
				>
					<Icon type={IconType.menuRiskBenefit} />
				</DashboardTile>
				<DashboardTile
					className="dashboard--profile"
					href={getMainPath(routesDictionary.profile)}
					size={DashboardTileSize.small}
					stretchContent={true}
					header={
						<span className="title">
							<Trans i18nKey="view.dashboard.tile.profile.title" />
						</span>
					}
				>
					<Icon type={IconType.actionAccount} />
				</DashboardTile>
				<DashboardTile
					className="dashboard--documents"
					href={getMainPath(routesDictionary.documents)}
					size={DashboardTileSize.small}
					sizeMedium={DashboardTileMediumSize.smallMedium}
					stretchContent={true}
					header={
						<span className="title">
							<Trans i18nKey="view.dashboard.tile.documents.title" />
						</span>
					}
				>
					{'success' === documentsStatus && undefined !== documents && (
						<DocumentsDashboard data={documents} />
					)}
				</DashboardTile>
				<DashboardTile
					className="dashboard--conversions"
					href={getMainPath(routesDictionary.conversions)}
					size={DashboardTileSize.medium}
					sizeMedium={DashboardTileMediumSize.smallMedium}
					stretchContent={true}
					header={
						<span className="title">
							<Trans i18nKey="view.dashboard.tile.conversions.title" />
						</span>
					}
				>
					<img className="padding" src={conversionsImage} alt="" />
				</DashboardTile>
				<DashboardTile
					className="dashboard--pension-assets"
					href={getMainPath(routesDictionary.pensionAssets)}
					size={DashboardTileSize.smallMedium}
					sizeMedium={DashboardTileMediumSize.smallMedium}
					stretchContent={true}
					loadingSpinnerArea={LoadingSpinnerArea.pensionAssetsDashboard}
					loading={'success' === pensionAssetsStatus}
					header={
						<span className="title">
							<Trans i18nKey="view.dashboard.tile.pensionAssets.title" />
						</span>
					}
				>
					{'success' === pensionAssetsStatus && undefined !== pensionAssets && (
						<PensionAssetsDashboard data={pensionAssets} blocksAccountData={blocksAccountData} />
					)}
				</DashboardTile>
				<DashboardTile
					className="dashboard--deferred-compensation"
					href={getMainPath(routesDictionary.deferredCompensation)}
					size={DashboardTileSize.extraLarge}
					stretchContent={true}
					header={
						<span className="title">
							<Trans i18nKey="view.dashboard.tile.deferredCompensation.title" />
						</span>
					}
				>
					<img src={deferredCompensationIcon} alt="" />
				</DashboardTile>
				<DashboardTile
					className="dashboard--faq"
					href={getMainPath(routesDictionary.faq)}
					size={DashboardTileSize.medium}
					sizeMedium={DashboardTileMediumSize.smallMedium}
					stretchContent={true}
					header={
						<span className="title">
							<Trans i18nKey="view.dashboard.tile.faq.title" />
						</span>
					}
				>
					<div className="flex">
						<Icon type={IconType.actionInfo} />
						<div className="font-family-alternative--medium font-size-s bp-medium-font-size-default margin margin--left margin--small">
							<Trans i18nKey="view.dashboard.tile.faq.buttonLabel" />
						</div>
					</div>
				</DashboardTile>

				{activatedFeatures.payoutOptions && (
					<DashboardTile
						className="dashboard--payout-model"
						href={getMainPath(routesDictionary.payoutOptions)}
						size={DashboardTileSize.extraLarge}
						stretchContent={true}
						header={
							<>
								<div className={`title ${payoutModelIsInProgress ? 'in-progress-indicator' : ''}`}>
									<>
										<Trans i18nKey="view.dashboard.tile.payoutModel.default" />
									</>
								</div>
								<span className="title"> </span>
								{payoutModelIsInProgress ? (
									<>
										{' '}
										<Badge children={<></>} />
										<p className="caption margin--top">
											<Trans i18nKey="view.dashboard.tile.payoutModel.inProgressHeadline" />{' '}
											<Trans i18nKey="view.dashboard.tile.payoutModel.inProgressBody" />
										</p>
									</>
								) : (
									<></>
								)}
							</>
						}
					>
						<Icon
							className={`padding ${payoutModelIsInProgress ? 'dim' : ''}`}
							type={IconType.menuPayout}
						/>
					</DashboardTile>
				)}
				<DashboardTile
					className="dashboard--blocks-account"
					href={getMainPath(routesDictionary.blocksAccount)}
					size={DashboardTileSize.extraLarge}
					stretchContent={true}
					header={
						<>
							<span className="title">
								<Trans i18nKey="view.dashboard.tile.blocksAccount.title" />
							</span>
						</>
					}
				>
					<Icon className={'padding'} type={IconType.menuBlocksAccount} />
				</DashboardTile>
			</>
		</div>
	)
}

export default Dashboard
