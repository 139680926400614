import useAsyncEffect from '@n1ru4l/use-async-effect'
import useApi, { QueryKey } from 'hooks/useApi'
import { useUserGroup } from 'hooks/useUserGroup'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import routesDictionary from 'routes'
import BigNumberInput from 'shared/components/BigNumberInput'
import CompensationDataTable from 'shared/components/CompensationDataTable'
import CompensationShares from 'shared/components/CompensationShares'
import SwitchSelect from 'shared/components/SwitchSelect'
import { CompensationInputType, CompensationUnit } from 'shared/enums'
import { currencyFormat } from 'shared/helper/numberFormats'
import { sanitizeDecimalNumber } from 'shared/helper/sanitizeMaskedValue'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { CompensationData } from 'shared/interfaces'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'
import Button, { ButtonType } from '../../shared/components/Button'
import RangeSlider from '../../shared/components/RangeSlider'

const defaultPercentage = process.env.REACT_APP_DEFAULT_SALARY_COMPENSATION_PERCENTAGE

const Salary: React.FC = () => {
	useBackButtonPath()
	useNavigationTitle(<Trans i18nKey="view.deferredCompensation.pageTitle.salary" />)

	const { t } = useTranslation()
	const api = useApi()
	const queryClient = useQueryClient()
	const { getChildPath, navigateTo } = useRouteHelper()
	const {isHigherUp} = useUserGroup()

	const simulationQuery: [QueryKey.compensationSimulationSalary, { percentage?: number }] = [
		QueryKey.compensationSimulationSalary,
		{ percentage: defaultPercentage as unknown as number },
	]

	const { unit, value } = queryClient.getQueryData<CompensationData>(simulationQuery) || {}

	const [percentage, setPercentValue] = useState<number | undefined>(
		CompensationUnit.percent === unit ? value : Number(defaultPercentage)
	)
	const [amount, setAmountValue] = useState<string | undefined>(
		CompensationUnit.euro === unit ? String(value) : undefined
	)

	const { data: settings } = useQuery(
		[QueryKey.compensationSimulationSettings, 'salary'],
		api.getCompensationSimulationSettings
	)
	const [inputType, setInputType] = useState<CompensationInputType>(
		CompensationUnit.euro === unit ? CompensationInputType.amount : CompensationInputType.percentage
	)

	const { data: simulation } = useQuery({ queryKey: simulationQuery, queryFn: api.getCompensationSimulationSalary })

	useEffect(() => {
		sessionStorage.removeItem('deferredCompensationSimulationData')
	}, [])

	const onSubmit = () => {
		sessionStorage.setItem(
			'deferredCompensationSimulationData',
			JSON.stringify({
				...simulation,
				type: 'salary',
				unit: inputType === CompensationInputType.percentage ? CompensationUnit.percent : CompensationUnit.euro,
				input: {
					[inputType]:
						inputType === CompensationInputType.percentage ? percentage : sanitizeDecimalNumber(amount),
				},
			})
		)
		navigateTo(getChildPath(routesDictionary.deferredCompensation, 'summary'))
	}

	useAsyncEffect(
		function* (setErrorHandler: any, c: any) {
			if (undefined === percentage && undefined === amount) {
				return
			}

			let queryString

			switch (inputType) {
				case CompensationInputType.percentage:
					queryString = {
						/**
						 * FIXME:
						 * somehow the query cache does not get updated for the initial
						 * percentage value.
						 * the defaultPercentage is a string at the moment and must be casted to an integer, in order to make this work.
						 *
						 * steps to reproduce:
						 * 1. do not cast percentage to an integer OR cast defaultPercentage to a number
						 * 2. load view
						 * 3. switch to amount and back to percentage
						 * 4. cached query is not set
						 */
						percentage,
					}
					break

				case CompensationInputType.amount:
					queryString = {
						amount: Math.floor(sanitizeDecimalNumber(amount)),
					}
					break
			}

			const updatedSimulation = yield* c(
				queryClient.fetchQuery(
					[QueryKey.compensationSimulationSalary, queryString],
					api.getCompensationSimulationSalary
				)
			)

			queryClient.setQueryData(simulationQuery, updatedSimulation)
		},
		[percentage, amount, inputType]
	)

	return (
		<div className="deferred-compensation grid grid--center-scroll center-view">
			{!!simulation && (
				<>
					<div className="grid__center">
						<p className="text-align--left margin--vertical">
							<Trans
								i18nKey="view.deferredCompensation.bodytext.salary"
								values={{ salary: currencyFormat(simulation.salary, { variableFractionDigits: true }) }}
							/>
						</p>

						<div className="margin--large margin--vertical">
							<CompensationShares
								ownContribution={simulation.ownContribution}
								employerContribution={simulation.employerContribution}
								sum={simulation.pensionTrust}
							/>
						</div>

						{!isHigherUp && (
							<SwitchSelect
								options={[
									{
										value: CompensationInputType.percentage,
										label: t('view.deferredCompensation.buttonLabel.percent'),
									},
									{
										value: CompensationInputType.amount,
										label: t('view.deferredCompensation.buttonLabel.amount'),
									},
								]}
								value={inputType}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									setInputType(event.target.value as CompensationInputType)
								}}
							/>
						)}

						{settings && inputType === CompensationInputType.percentage && (
							<RangeSlider
								{...settings.percentage}
								unit="%"
								showDots={true}
								value={percentage || 0}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
									setPercentValue(Number(e.target.value))
								}
							/>
						)}

						{settings && inputType === CompensationInputType.amount && (
							<BigNumberInput
								placeholder={t('view.deferredCompensation.compensationValuePlaceholder')}
								value={amount || ''}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAmountValue(e.target.value)}
								minValue={settings.amount.min}
								maxValue={settings.amount.max}
								allowDecimal={false}
							/>
						)}

						<CompensationDataTable
							ownContribution={simulation.ownContribution}
							employerBaseAmount={simulation.employerBaseAmount}
							matchingAmount={simulation.matchingAmount}
							sum={simulation.pensionTrust}
							unit={
								inputType === CompensationInputType.percentage
									? CompensationUnit.percent
									: CompensationUnit.euro
							}
							yearlySum={simulation.yearlySum}
						/>
					</div>

					<Button
						onClick={onSubmit}
						className="grid__bottom"
						label={t('generic.next')}
						type={ButtonType.primary}
						disabled={
							(undefined === percentage && CompensationInputType.percentage === inputType) ||
							(undefined === amount && CompensationInputType.amount === inputType)
						}
					/>
				</>
			)}
		</div>
	)
}

export default Salary
