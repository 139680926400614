import React, { FunctionComponent } from 'react'
import { FundAllocationComponentsData } from 'shared/interfaces'
// @ts-ignore
import { Slice, VictoryPie } from 'victory'
import { FundCompositionContextProps, FundCompositionDataContext } from './FundComposition/FundCompositionContext'

export interface PieChartData extends FundAllocationComponentsData {
	fill: string
	start: number
	end: number
	totalAmount: number
	previousAmount: number
	showChildren: boolean
	anyChildrenShown: boolean
	highlight: boolean
	index: number
	indices: number[]
	height?: number
}

export interface SunburstChartProps {
	data?: FundAllocationComponentsData[]
}

const cornerRadius = 10
const padAngle = 2

const SunburstChart: FunctionComponent<SunburstChartProps> = (props) => {
	return (
		<FundCompositionDataContext.Consumer>
			{(context: FundCompositionContextProps) => {
				const { metaData, calculatedData, toggleSlice, colorScheme, chartLabel } = context

				const renderPieChart = (
					pieChartData: PieChartData[],
					parentSlice?: PieChartData,
					index?: number
				): any => {
					const chartDepth = parentSlice ? parentSlice.indices.length + 1 : 1

					const getRadius = () => {
						let radius = metaData.minimumRadius

						radius += chartDepth * metaData.gap

						return radius
					}

					return (
						<g className={`pie-${chartDepth}-${index || 0}`} key={`pie-${chartDepth}-${index || 0}`}>
							<VictoryPie
								// @ts-ignore
								dataComponent={<Slice className="sunburst-chart__ring-slice" />}
								standalone={false}
								width={metaData.dimension}
								height={metaData.dimension}
								style={{
									data: {
										opacity: () => {
											let opacity = 0

											if (parentSlice) {
												if (true === parentSlice.showChildren) {
													opacity = 0.5
												}
											}

											return opacity
										},
										fill: 'var(--color-blue)',
										pointerEvents: 'none',
									},
									labels: { display: 'none' },
								}}
								radius={getRadius() - metaData.thickness.default / 2 + 0.5}
								innerRadius={getRadius() - metaData.thickness.default / 2 - 0.5}
							/>
							<VictoryPie
								// @ts-ignore
								dataComponent={<Slice className="sunburst-chart__data-slice" />}
								standalone={false}
								data={pieChartData}
								width={metaData.dimension}
								height={metaData.dimension}
								style={{
									data: {
										opacity: ({ datum }) => {
											let opacity = 1

											/**
											 * if only the first level is shown, show all slices fully opaque.
											 * if one first level slice shows its children, render the other
											 * first level slices half transparent
											 */
											if (1 === chartDepth && true !== datum.showChildren) {
												opacity = datum.anyChildrenShown ? 0.5 : 1
											}

											if (parentSlice) {
												if (true === parentSlice.showChildren) {
													opacity = datum.highlight || datum.showChildren ? 1 : 0.6
												} else {
													opacity = 0
												}
											}

											return opacity
										},
										fill: ({ datum }) => colorScheme[datum.indices[0]] || 'var(--color-white)',
										stroke: ({ datum }) =>
											datum.highlight || datum.showChildren
												? colorScheme[datum.indices[0]] || 'var(--color-white)'
												: 'transparent',
										strokeWidth: metaData.thickness.selected - metaData.thickness.default,
										filter: ({ datum }) =>
											datum.highlight || datum.showChildren ? 'url(#glow)' : 'none',
										pointerEvents: 'none',
									},
									labels: { display: 'none' },
								}}
								y="amount"
								radius={getRadius()}
								innerRadius={getRadius() - metaData.thickness.default}
								padAngle={padAngle}
								cornerRadius={cornerRadius}
								startAngle={parentSlice ? parentSlice.start : 0}
								endAngle={parentSlice ? parentSlice.end : 360}
							/>
							{/* this chart is only used for larger clickable areas */}
							<VictoryPie
								standalone={false}
								data={pieChartData}
								width={metaData.dimension}
								height={metaData.dimension}
								style={{
									data: {
										// fill: 'black',
										// fillOpacity: 0.4,
										fill: 'transparent',
										cursor: ({ datum }) => {
											let cursor = 'pointer'

											if (parentSlice && true !== parentSlice.showChildren) {
												cursor = 'default'
											}

											return cursor
										},
									},
									labels: { display: 'none' },
								}}
								y="amount"
								radius={getRadius() + metaData.gap / 2 - 1}
								innerRadius={getRadius() - metaData.gap / 2 - 1}
								startAngle={parentSlice ? parentSlice.start : 0}
								endAngle={parentSlice ? parentSlice.end : 360}
								events={[
									{
										target: 'data',
										eventHandlers: {
											onClick: () => {
												return [
													{
														target: 'data',
														mutation: ({ datum }) => {
															if (parentSlice) {
																if (parentSlice.showChildren) {
																	toggleSlice(datum.indices, !datum.showChildren)
																}
															} else {
																toggleSlice(datum.indices, !datum.showChildren)
															}
														},
													},
												]
											},
										},
									},
								]}
							/>

							{pieChartData.map((sliceData: PieChartData, sliceIndex: number) => {
								if (undefined !== sliceData.components) {
									return renderPieChart(sliceData.components as PieChartData[], sliceData, sliceIndex)
								}

								return null
							})}
						</g>
					)
				}

				return (
					<div className="sunburst-chart flex flex--grow">
						<div className="sunburst-chart__label font-size-l font-family-alternative--light">
							{chartLabel}
						</div>
						<svg style={{ height: 0, width: 0 }}>
							<defs>
								<filter id="glow" x="-40%" y="-40%" width="180%" height="180%">
									<feGaussianBlur className="blur" result="coloredBlur" stdDeviation="2" />
									<feMerge>
										<feMergeNode in="coloredBlur" />
										<feMergeNode in="SourceGraphic" />
									</feMerge>
								</filter>
							</defs>
						</svg>
						<svg
							className="sunburst-chart__wrapper"
							width="100%"
							height="100%"
							viewBox={`0 0 ${metaData.dimension} ${metaData.dimension}`}
						>
							{calculatedData && renderPieChart(calculatedData)}
						</svg>
					</div>
				)
			}}
		</FundCompositionDataContext.Consumer>
	)
}

export default SunburstChart
