import useApi from 'hooks/useApi'
import { ChallengeType, useAuth } from 'hooks/useAuth'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import Form, { FormFields, FormFieldType, FormRefActions } from 'shared/components/Form'
import { TextInputType } from 'shared/components/TextInput'
import { ApiErrorCode } from 'shared/enums'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath } from 'state/useHeaderState'

const SendPassword = () => {
	const { t } = useTranslation()
	const auth = useAuth()
	const api = useApi()
	const challenge = auth.challenge
	const { getMainPath, navigateTo, getChildPath } = useRouteHelper()

	useBackButtonPath(getChildPath(routesDictionary.migration, 'phone'))

	const [errorMessages, setErrorMessages] = useState<string[]>()
	const sendPasswordForm = useRef<FormRefActions>()

	// const modal = useRef<ModalRefActions>()
	const inputElements: FormFields = useMemo(() => {
		let fields: FormFields = {
			password: {
				required: true,
				type: TextInputType.password,
				label: t('generic.password'),
				hidePasswordLabel: t('generic.hidePassword'),
				showPasswordLabel: t('generic.showPassword'),
				fieldType: FormFieldType.text,
			},
		}

		return fields
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (undefined === challenge) {
			return
		}

		switch (challenge.lastChallengeError) {
			case undefined:
				/**
				 * FIXME:
				 * lastChallengeError is undefined when the initial password is wrong.
				 * to handle that case the error message is triggered manually.
				 * this should be fixed in the backend
				 */
				if (
					'true' === challenge.initialPasswordSet &&
					ChallengeType.loginMfa === challenge.previousChallengeName
				) {
					setErrorMessages([
						t(`apiErrors.${ApiErrorCode.INITIAL_USERNAME_OR_PASSWORD_INCORRECT}`, {
							error: ApiErrorCode.INITIAL_USERNAME_OR_PASSWORD_INCORRECT,
						}),
						t('view.login.formErrors.remainingTries', {
							remainingTries: challenge.remainingTries,
						}),
					])

					sendPasswordForm.current?.handleFormError()
				}

				return

			case ApiErrorCode.ACCOUNT_TEMPORARY_LOCKED:
				navigateTo(getMainPath(routesDictionary.accountLocked))
				return

			default:
				setErrorMessages([
					t(`apiErrors.${challenge.lastChallengeError}`, { error: challenge.lastChallengeError }),
					t('view.login.formErrors.remainingTries', {
						remainingTries: challenge.remainingTries,
					}),
				])

				sendPasswordForm.current?.handleFormError()

				return
		}
		// eslint-disable-next-line
	}, [challenge])

	return (
		<div className="form-view">
			<div className="form-view__title">
				<h1>
					<Trans i18nKey={`view.migration.password.headline`} />
				</h1>
			</div>

			<div className="form-view__bodytext"></div>

			<Form
				ref={sendPasswordForm}
				className="form-view"
				fields={inputElements}
				onSubmit={async (submittedFields: { [key: string]: any }) => {
					const password = submittedFields.password
					const response = await api.sendPasswordForMigration(password)

					if (!response.success) {
						setErrorMessages([t(`apiErrors.${response.errorCode}`)])
						sendPasswordForm.current?.handleFormError()
						return
					}

					navigateTo(getChildPath(routesDictionary.migration, 'success'))
				}}
				errorMessages={errorMessages}
				submitLabel={t(`generic.send`)}
			>
				<div className="form-view__additional-fields">
					{/* <Link className="margin--bottom" href={getMainPath(routesDictionary.passwordReset)!} basePath="/">
						{t('view.login.buttonLabel.forgotPassword')}
					</Link> */}
				</div>
			</Form>
		</div>
	)
}

export default SendPassword
