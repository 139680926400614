import useAsyncEffect from '@n1ru4l/use-async-effect'
import useApi, { QueryKey } from 'hooks/useApi'
import { useUserGroup } from 'hooks/useUserGroup'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import { CompensationType } from 'shared/enums'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { CompensationTypeData } from 'shared/interfaces'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'
import CompensationSelectWrapper from '../../components/DeferredCompensation/CompensationSelectWrapper'

const Selection: React.FC = () => {
	const { getMainPath, getChildPath, navigateTo } = useRouteHelper()
	useBackButtonPath(getMainPath(routesDictionary.dashboard))
	useNavigationTitle(<Trans i18nKey="view.deferredCompensation.pageTitle.default" />)
	const { noBEMGR } = useUserGroup()
	const { t } = useTranslation()
	const api = useApi()
	const queryClient = useQueryClient()

	const [selectedOption, setSelectedOption] = useState<CompensationTypeData>()

	const { data: compensationTypes } = useQuery(api.getQuery(QueryKey.compensationTypes))

	const onSubmitHandler = () => {
		if (undefined === selectedOption) {
			return
		}

		switch (selectedOption.type) {
			case CompensationType.salary:
				navigateTo(getChildPath(routesDictionary.deferredCompensation, 'salary'))
				break

			default:
				navigateTo(
					`${getChildPath(routesDictionary.deferredCompensation, 'selectBonusMonth')}/${selectedOption.id}`
				)
				break
		}
	}

	/**
	 * prefetch data for the next screens on option select
	 */
	useAsyncEffect(
		function* (setErrorHandler: any, c: any) {
			if (undefined !== selectedOption) {
				switch (selectedOption.type) {
					case 'salary':
						yield* [
							c(
								queryClient.prefetchQuery(
									[QueryKey.compensationSimulationSettings, 'salary'],
									api.getCompensationSimulationSettings
								)
							),
							c(
								queryClient.prefetchQuery(
									[
										QueryKey.compensationSimulationSalary,
										{
											percentage: Number(
												process.env.REACT_APP_DEFAULT_SALARY_COMPENSATION_PERCENTAGE
											),
										},
									],
									api.getCompensationSimulationSalary
								)
							),
						]
						break

					default:
						yield* c(
							queryClient.prefetchQuery([QueryKey.bonusMonth, selectedOption.id], api.getBonusMonthList)
						)
						break
				}
			}
		},
		[selectedOption]
	)

	if (noBEMGR) {
	}

	return (
		<div className="deferred-compensation grid grid--center-scroll center-view">
			<h3 className="text-align--left grid__top">
				<Trans i18nKey="view.deferredCompensation.selection.headline" />
			</h3>

			{noBEMGR ? (
				<>
					<p>
						<Trans i18nKey={'generic.noBEMGRDisclaimer'}></Trans>
					</p>
				</>
			) : (
				<>
					{compensationTypes && (
						<CompensationSelectWrapper
							className="deferred-compensation__type grid__center no-padding--left no-padding--right"
							options={compensationTypes}
							selectedOption={selectedOption}
							onClick={(element: any) => {
								setSelectedOption(element)
							}}
						/>
					)}

					<div className="moved-by-accordion grid__bottom">
						<Button
							onClick={onSubmitHandler}
							type={ButtonType.primary}
							label={t('generic.next')}
							disabled={!selectedOption}
						/>
					</div>
				</>
			)}
		</div>
	)
}

export default Selection
