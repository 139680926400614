import useAsyncEffect from '@n1ru4l/use-async-effect'
import AuthenticationModal from 'components/AuthenticationModal'
import DisclaimerModal from 'components/DisclaimerModal'
import InstallPromptModal from 'components/InstallPromptModal'
import useApi, { QueryKey } from 'hooks/useApi'
import { useTermsAndConditions } from 'hooks/useTermsAndConditions'
import { useUserGroup } from 'hooks/useUserGroup'
import { usePath, useRoutes } from 'raviger'
import React, { useEffect, useMemo, useRef } from 'react'
import { useQueryClient } from 'react-query'
import routesDictionary from 'routes'
import AppUpdateModal from 'shared/components/AppUpdateModal'
import GlobalModal from 'shared/components/GlobalModal'
import LoadingSpinner, { LoadingSpinnerArea } from 'shared/components/LoadingSpinner'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { ChallengeType, useAuth } from '../hooks/useAuth'
import Header from '../partials/Header'
import NotFound from './NotFound'

let redirectTimeout: NodeJS.Timeout

export const RouteWrapper: React.FC = () => {
	const { routes, getMainPath, getChildPath, navigateTo, redirectTo } = useRouteHelper()
	const { notMigrated } = useUserGroup()
	const queryClient = useQueryClient()
	useTermsAndConditions()

	// REDIRECTS
	redirectTo(getMainPath(routesDictionary.conversions), getChildPath(routesDictionary.conversions, 'allYears'))
	redirectTo(getMainPath(routesDictionary.payoutOptions), getChildPath(routesDictionary.payoutOptions, 'overview'))
	redirectTo(
		getMainPath(routesDictionary.deferredCompensation),
		getChildPath(routesDictionary.deferredCompensation, 'typeSelection')
	)

	redirectTo(getMainPath(routesDictionary.riskBenefit), getChildPath(routesDictionary.riskBenefit, 'RBSelection'))
	// redirectTo('/register', `${getMainPath(routesDictionary.login)}`, { ...queryParams, register: true })

	redirectTo('/hilfe', getMainPath(routesDictionary.registrationHelp))
	redirectTo('/test', getMainPath(routesDictionary.newTanProcess))

	const { userData, userInitialized, challenge } = useAuth()
	const routeResult = useRoutes(routes)
	const path = usePath()
	const api = useApi()
	const initialPrefetchPath = useRef<string | null>()

	/**
	 * save the initially visited path, to make sure to redirect the user to the
	 * desired page after authentication.
	 * Authentication only runs after the first render,
	 * where the private routes are not available, yet.
	 *
	 * When the user is authenticated an effect runs and navigates to the initial path.
	 */
	if (undefined === userData && null === sessionStorage.getItem('initialPath')) {
		if (
			null !== path &&
			!path.startsWith(routesDictionary.welcome.path) &&
			!path.startsWith(routesDictionary.register.path) &&
			!path.startsWith(routesDictionary.registrationHelp.path) &&
			!path.startsWith(routesDictionary.logout.path) &&
			!path.startsWith(routesDictionary.login.path) &&
			!path.startsWith(routesDictionary.accountLocked.path) &&
			!path.startsWith(routesDictionary.passwordReset.path) &&
			!path.startsWith(routesDictionary.terminateDigitalParticipation.path) &&
			!path.startsWith(routesDictionary.imprint.path)
		) {
			sessionStorage.setItem('initialPath', path)
		} else {
			sessionStorage.setItem('initialPath', routesDictionary.dashboard.path)
		}
	}

	useEffect(() => {
		if (true === userInitialized) {
			if (notMigrated) {
				navigateTo(getMainPath(routesDictionary.migration), true)
				return
			}

			const initialPath = sessionStorage.getItem('initialPath')
			if (null !== initialPath) {
				sessionStorage.removeItem('initialPath')
				initialPrefetchPath.current = initialPath
				navigateTo(initialPath, true)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userInitialized])

	/**
	 * prefetch data
	 */
	useAsyncEffect(
		function* (setErrorHandler: any, c: any) {
			if (true === userInitialized) {
				const isDashboard = initialPrefetchPath.current === getMainPath(routesDictionary.dashboard)

				yield* Object.keys(api.queries).map((value) => {
					let area

					switch (value) {
						case QueryKey.fundPerformance:
							area = isDashboard ? LoadingSpinnerArea.indiceComparisonDashboard : area
							break

						case QueryKey.pensionAssets:
							area = isDashboard ? LoadingSpinnerArea.pensionAssetsDashboard : area
							break
					}

					const query = api.getQuery(value as QueryKey, undefined, { area })

					return c(queryClient.prefetchQuery(query))
				})
			}
		},
		[userInitialized]
	)

	// scroll to top on every page transition
	// use scrollTop here to support Edge
	useEffect(() => {
		document.documentElement.scrollTop = 0
	}, [path])

	const ForceRedirect = useMemo(() => {
		clearTimeout(redirectTimeout)

		if (undefined === userData && !routeResult) {
			redirectTimeout = setTimeout(() => {
				if (path !== routesDictionary.welcome.path) {
					navigateTo(routesDictionary.welcome.path, true)
				}
			})
		}

		return null
		// eslint-disable-next-line
	}, [userData, routeResult])

	return (
		<>
			<Header />
			<main className="main">{routeResult || (userData ? <NotFound /> : ForceRedirect)}</main>
			{challenge && [challenge.previousChallengeName, challenge.customChallengeName].includes(ChallengeType.mfa) && (
				<>
					<AuthenticationModal />
				</>
			)}
			<GlobalModal />
			<DisclaimerModal />
			<InstallPromptModal />
			<AppUpdateModal />
			<LoadingSpinner area={LoadingSpinnerArea.center} />
		</>
	)
}

