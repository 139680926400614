import useApi, { QueryKey } from 'hooks/useApi'
import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import SelectableBarGroup, { SelectableBarGroupOption } from 'shared/components/SelectableBarGroup'
import { OPTEOptionsOriginal, RiskOptions } from 'shared/enums'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'
import { riskYearsIcon } from './RiskBenefit'

const RBSelectDuration: React.FC<{ riskId: RiskOptions; type?: OPTEOptionsOriginal }> = ({ riskId, type }) => {
	const { getChildPath, navigateTo } = useRouteHelper()
	useBackButtonPath(
		getChildPath(
			routesDictionary.riskBenefit,
			RiskOptions.disabilityBenefit === riskId ? 'RBDisabilityBenefitChange' : 'RBPartnerBenefitChange'
		)
	)
	useNavigationTitle(<Trans i18nKey="view.riskBenefit.pageTitle.default" />)

	const api = useApi()
	const { t } = useTranslation()

	const [selectedOption, setSelectedOption] = useState<{ value: number }>()

	const { data } = useQuery(QueryKey.riskOptionsYears, api.getRiskOptionsYears)

	const options: SelectableBarGroupOption[] | undefined = useMemo(() => {
		if (undefined === data) {
			return
		}

		return [
			{ key: 1, data: RiskOptions.disabilityBenefit === riskId ? data.OPTEOneYear : data.OPTTOneYear },
			{ key: 3, data: RiskOptions.disabilityBenefit === riskId ? data.OPTEThreeYear : data.OPTTThreeYear },
		].reduce((availableOptions: SelectableBarGroupOption[], currentOption) => {
			const disabled = 0 === currentOption.data.length

			const option = {
				label: t('view.riskBenefit.calendarYear', { count: currentOption.key }),
				subheadline: disabled ? t('view.riskBenefit.tooOld') : currentOption.data.join(', '),
				value: currentOption.key,
				disabled,
				icon: riskYearsIcon[currentOption.key],
				onClick: () => {
					setSelectedOption({ value: currentOption.key })
				},
			}

			availableOptions.push(option)

			return availableOptions
		}, [])
	}, [data, riskId, t])

	const onClickHandler = useCallback(() => {
		if (undefined === selectedOption) {
			return
		}

		navigateTo(
			`${getChildPath(
				routesDictionary.riskBenefit,
				RiskOptions.disabilityBenefit === riskId
					? 'RBDisabilityBenefitConditionsWithDuration'
					: 'RBPartnerBenefitConditionsWithDuration'
			)}/${type}/${selectedOption?.value}`
		)
	}, [selectedOption, riskId, type, getChildPath, navigateTo])

	return (
		<div
			className={`risk-benefit center-view grid grid--center-scroll visually-hidden visually-hidden--${String(
				!data
			)} rb-select-duration`}
		>
			<h3 className="text-align--left grid__top">
				<Trans i18nKey={`view.riskBenefit.change.headline.${riskId}`} />
			</h3>

			<div className="grid__center">
				<Trans i18nKey="view.riskBenefit.change.changePeriod" />

				{options && (
					<SelectableBarGroup
						className="margin--vertical full-width"
						options={options}
						selectedOption={selectedOption}
					/>
				)}
			</div>

			<div className="grid__bottom">
				<Button
					onClick={onClickHandler}
					type={ButtonType.primary}
					label={t('generic.next')}
					disabled={!selectedOption}
				/>
			</div>
		</div>
	)
}

export default RBSelectDuration
