import { DefaultTFuncReturn } from 'i18next'
import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import ChevronButton from 'shared/components/ChevronButton'
import scrollTo from 'shared/helper/scrollTo'
import Button, { ButtonType } from './Button'

export interface SnapOption {
	value: string | number
	label?: string | DefaultTFuncReturn
}

export interface SnapSliderProps {
	options: SnapOption[]
	value?: string | number
	onChange?: (value: string | number) => void
	className?: string
}

const SnapSlider: FunctionComponent<SnapSliderProps> = (props) => {
	const [selectedOption, setSelectedOption] = useState<SnapSliderProps['value']>(props.value)
	const buttonWrapper = useRef<HTMLDivElement>(null)

	const previousOption = () => {
		const currentOptionIndex = props.options.findIndex((option) => option.value === selectedOption)

		if (-1 === currentOptionIndex) {
			return
		}

		setSelectedOption(currentOptionIndex > 0 ? props.options[currentOptionIndex - 1].value : selectedOption)
	}

	const nextOption: any = () => {
		const currentOptionIndex = props.options.findIndex((option) => option.value === selectedOption)
		if (-1 === currentOptionIndex) {
			return
		}

		setSelectedOption(
			currentOptionIndex < props.options.length ? props.options[currentOptionIndex + 1].value : selectedOption
		)
	}

	const handleClick = (value: string | number) => {
		setSelectedOption(value)
	}

	useEffect(() => {
		if (buttonWrapper.current) {
			const currentButtonNode: HTMLButtonElement | null = buttonWrapper.current.querySelector('.button--primary')

			if (currentButtonNode) {
				const buttonWrapperWidth = buttonWrapper.current.clientWidth
				const scrollPosition =
					currentButtonNode.offsetLeft + currentButtonNode.clientWidth / 2 - buttonWrapperWidth / 2

				scrollTo(buttonWrapper.current, scrollPosition, true)
			}
		}

		if (selectedOption !== props.value && undefined !== props.onChange && undefined !== selectedOption) {
			props.onChange(selectedOption)
		}
		// eslint-disable-next-line
	}, [selectedOption])

	const getClassNames = (): string => {
		const classes: string[] = ['snap-slider']

		if (props.className) {
			classes.push(props.className)
		}

		return classes.join(' ')
	}

	const renderOptions = () => {
		return (
			<React.Fragment>
				{props.options.map(({ label, value }, index) => {
					return (
						<div key={index.toString()} className={`snap-slider__snappoint`}>
							<Button
								type={
									selectedOption === value
										? [ButtonType.small, ButtonType.primary]
										: [ButtonType.small, ButtonType.text]
								}
								label={label}
								onClick={() => {
									handleClick(value)
								}}
							/>
						</div>
					)
				})}
			</React.Fragment>
		)
	}

	return (
		<div className={getClassNames()}>
			<ChevronButton
				className="snap-slider__button"
				color="var(--snap-slider-button-color)"
				disabled={0 === props.options.findIndex((option) => option.value === selectedOption)}
				onClick={previousOption}
			/>

			<div ref={buttonWrapper} className="snap-slider__wrapper">
				{renderOptions()}
			</div>

			<ChevronButton
				className="snap-slider__button"
				color="var(--snap-slider-button-color)"
				forward={true}
				disabled={
					props.options.length === props.options.findIndex((option) => option.value === selectedOption) + 1
				}
				onClick={nextOption}
			/>
		</div>
	)
}

export default SnapSlider
