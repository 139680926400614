// @ts-ignore
import { VictoryThemeDefinition } from 'victory'

export const fallbackChartColor = 'var(--color-white)'

const textColor = 'var(--color-white)'
const grey = '#969696'

// Typography
const chartFontFamily = 'var(--font-system)'
export const fontFamilyAlternative = 'var(--font-alternative-light)'

const letterSpacing = 'normal'
// const fontSize = 'var(--font-size-default)'
export const fontSize = 16

// Strokes
const strokeLinecap = 'round'
const strokeLinejoin = 'round'

// Bar
export const barWidth = 40
export const barStackOffset = 12

export const conversionsChartStyle = {
	chartMinHeight: 400,
	barColors: ['var(--color-cyan)', 'var(--color-white)', 'var(--color-blue--super-dark)'],
	fallbackColor: 'var(--color-white)',
	cornerRadius: 10,
	barWidth: 40,
	data: {
		fill: 'transparent',
		stroke: 'var(--color-cyan)',
	},
	labels: {
		fontWeight: 'var(--font-system-bold)',
		fontSize: 14,
	},
	axis: {
		color: 'var(--color-white)',
		opacity: 0.2,
	},
	labelButtonColor: 'var(--color-cyan)',
	labelButtonIconColor: 'var(--color-white)',
	panButtonIconColor: 'var(--color-white)',
}

export const riskBenefitPreviewChartStyle = {
	chartHeight: 400,
	barColors: ['var(--color-cyan)', 'var(--color-white)'],
	fallbackColor: 'var(--color-white)',
	topLineColor: 'var(--color-white)',
	cornerRadius: 10,
	barWidth: 40,
	data: {
		fill: 'transparent',
		stroke: 'var(--color-cyan)',
	},
	labels: {
		fontWeight: 'var(--font-system-bold)',
		fontSize: 13,
	},
	// bigLabels: {
	// 	fontFamily: 'var(--font-alternative-medium)',
	// 	fontSize: 25,
	// },
	axis: {
		color: 'var(--color-white)',
		opacity: 0.2,
		fontSize: 13,
	},
	labelButtonColor: 'var(--color-cyan)',
	labelButtonIconColor: 'var(--color-white)',
	panButtonIconColor: 'var(--color-white)',
}

export const pensionAssetsChartStyle = {
	chartMinHeight: 400,
	barColors: ['var(--color-cyan)', 'var(--color-white)', 'var(--color-blue--super-dark)'],
	fallbackColor: 'var(--color-white)',
	cornerRadius: { top: 2, bottom: 2 },
	barWidth: 4,
}

export const indiceComparisonChartStyle = {
	chartMinHeight: 400,
}

// Line
export const lineChartColors = ['var(--color-cyan)', 'var(--color-blue--dark)', 'var(--color-white)']
// tooltip text color for line chart. a new color array is required as the grey color has to be darker
export const tooltipColors = ['var(--color-cyan)', 'var(--color-blue--dark)', 'var(--color-white)']
export const lineWidth = 4

// Pie
export const pieChartColors = [
	'var(--color-other-blue)',
	'var(--color-yellow)',
	'var(--color-green)',
	// 'var(--color-blue)',
	// 'var(--color-blue--medium)',
	// 'var(--color-blue--dark)',
	// 'var(--color-blue--super-dark)',
]

// Layout
const baseProps = {
	width: 450,
	height: 300,
	padding: 0,
	colorScale: lineChartColors,
}

// Labels
export const baseLabelStyles = {
	fontFamily: chartFontFamily,
	fontSize,
	letterSpacing,
	padding: 10,
	fill: textColor,
	stroke: 'transparent',
}

// @ts-ignore
const centeredLabelStyles = Object.assign({ textAnchor: 'middle' }, baseLabelStyles)

// Put it all together...
const theme: VictoryThemeDefinition = {
	// @ts-ignore
	area: Object.assign(
		{
			style: {
				data: {
					fill: textColor,
				},
				labels: centeredLabelStyles,
			},
		},
		baseProps
	),
	// @ts-ignore
	axis: Object.assign(
		{
			style: {
				axis: {
					fill: 'transparent',
					stroke: textColor,
					strokeWidth: 1,
					strokeLinecap,
					strokeLinejoin,
				},
				// @ts-ignore
				axisLabel: Object.assign({}, centeredLabelStyles, {
					padding: 25,
				}),
				grid: {
					fill: 'none',
					stroke: 'none',
					pointerEvents: 'painted',
				},
				ticks: {
					fill: 'transparent',
					size: 1,
					stroke: 'transparent',
				},
				tickLabels: Object.assign({}, centeredLabelStyles, {
					padding: 25,
				}),
			},
		},
		baseProps
	),
	// @ts-ignore
	bar: Object.assign(
		{
			barWidth,
			cornerRadius: 10,
			style: {
				data: {
					padding: 10,
					strokeWidth: 4,
				},
				labels: baseLabelStyles,
			},
		},
		baseProps
	),
	// @ts-ignore
	boxplot: Object.assign(
		{
			style: {
				max: {
					padding: 8,
					stroke: textColor,
					strokeWidth: 1,
				},
				maxLabels: baseLabelStyles,
				median: {
					padding: 8,
					stroke: textColor,
					strokeWidth: 1,
				},
				medianLabels: baseLabelStyles,
				min: {
					padding: 8,
					stroke: textColor,
					strokeWidth: 1,
				},
				minLabels: baseLabelStyles,
				q1: {
					padding: 8,
					fill: grey,
				},
				q1Labels: baseLabelStyles,
				q3: {
					padding: 8,
					fill: grey,
				},
				q3Labels: baseLabelStyles,
			},
			boxWidth: 20,
		},
		baseProps
	),
	// @ts-ignore
	candlestick: Object.assign(
		{
			style: {
				data: {
					stroke: textColor,
					strokeWidth: 1,
				},
				labels: centeredLabelStyles,
			},
			candleColors: {
				positive: '#ffffff',
				negative: textColor,
			},
		},
		baseProps
	),
	chart: baseProps,
	// @ts-ignore
	errorbar: Object.assign(
		{
			borderWidth: 8,
			style: {
				data: {
					fill: 'transparent',
					stroke: textColor,
					strokeWidth: 2,
				},
				labels: centeredLabelStyles,
			},
		},
		baseProps
	),
	// @ts-ignore
	group: Object.assign(
		{
			colorScale: lineChartColors,
		},
		baseProps
	),
	// @ts-ignore
	legend: {
		colorScale: lineChartColors,
		gutter: 0,
		orientation: 'vertical',
		titleOrientation: 'top',
		style: {
			data: {
				type: 'circle',
			},
			labels: baseLabelStyles,
			// @ts-ignore
			title: Object.assign({}, baseLabelStyles, { padding: 5 }),
		},
	},
	// @ts-ignore
	line: Object.assign(
		{
			style: {
				data: {
					fill: 'transparent',
					stroke: textColor,
					strokeWidth: 2,
				},
				labels: centeredLabelStyles,
			},
		},
		baseProps
	),
	pie: {
		style: {
			data: {
				padding: 10,
				stroke: 'transparent',
				strokeWidth: 1,
			},
			// @ts-ignore
			labels: Object.assign({}, baseLabelStyles, { padding: 20 }),
		},
		colorScale: lineChartColors,
		width: 400,
		height: 400,
		padding: 50,
	},
	// @ts-ignore
	scatter: Object.assign(
		{
			style: {
				data: {
					fill: textColor,
					stroke: 'transparent',
					strokeWidth: 0,
				},
				labels: centeredLabelStyles,
			},
		},
		baseProps
	),
	// @ts-ignore
	stack: Object.assign(
		{
			colorScale: lineChartColors,
		},
		baseProps
	),
	tooltip: {
		// @ts-ignore
		style: Object.assign({}, centeredLabelStyles, {
			pointerEvents: 'none',
		}),
		flyoutStyle: {
			fill: 'var(--tooltip-background-color)',
			pointerEvents: 'none',
		},
		flyoutPadding: 5,
		cornerRadius: 12,
		pointerLength: 0,
	},
	// @ts-ignore
	voronoi: Object.assign(
		{
			style: {
				data: {
					fill: 'transparent',
					stroke: 'transparent',
					strokeWidth: 0,
				},
				// @ts-ignore
				labels: Object.assign({}, centeredLabelStyles, {
					padding: 5,
					pointerEvents: 'none',
				}),
			},
		},
		baseProps
	),
}

export default theme
