import React, { FunctionComponent } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'

const AlreadyLoggedIn: FunctionComponent = () => {
	const { t } = useTranslation()
	const { getMainPath, navigateTo } = useRouteHelper()

	return (
		<div className="form-view">
			<h1 className="form-view__title">
				<Trans i18nKey="view.login.alreadyLoggedIn.pageTitle" />
			</h1>
			<Button
				onClick={() => navigateTo(getMainPath(routesDictionary.dashboard))}
				label={t('view.login.buttonLabel.alreadyLoggedIn')}
				type={ButtonType.primary}
			/>
		</div>
	)
}

export default AlreadyLoggedIn
