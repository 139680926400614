import PayoutModelList from 'components/PayoutModelList'
import useApi, { QueryKey } from 'hooks/useApi'
import { useIsMaxAge } from 'hooks/useIsMaxAge'
import { useUserGroup } from 'hooks/useUserGroup'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'
import { components } from 'types/api-interface'

const PayoutReduction = () => {
	useNavigationTitle(<Trans i18nKey="view.payoutOptions.chapterOne.payoutReduction.pageTitle" />)
	const api = useApi()
	const [value, setValue] = useState<string | undefined>()
	const { navigateTo, getChildPath } = useRouteHelper()
	useBackButtonPath(getChildPath(routesDictionary.payoutOptions, 'benefitType'))
	const payoutModelQuery = useQuery(QueryKey.payoutModelInProgress, api.getPayoutModelInProgress)
	const { data: isEditable } = useQuery(QueryKey.payoutModelEditable, api.isPayoutModelEditable)
	const { t } = useTranslation()
	const benefitType = payoutModelQuery.data?.leistungsart
	const queryClient = useQueryClient()
	const savedReductionType = localStorage.getItem('reduction-type')
	const parsedSavedReductionType = savedReductionType ? JSON.parse(savedReductionType) : null
	const isMaxAge = useIsMaxAge()
	const { isVO20, isHigherUp } = useUserGroup()

	const optionalMaxAgeDisclaimer =
		(isMaxAge && (
			<>
				<br />
				&nbsp;
				<br /> {t('view.payoutOptions.chapterOne.payoutReduction.maxAgeDisclaimer')}
			</>
		)) ||
		''

	const optionalMaxAgeDisclaimerLimited =
		(isMaxAge && (
			<>
				<br />
				&nbsp;
				<br />{' '}
				{t(
					'view.payoutOptions.chapterOne.payoutReduction.' +
						(() => {
							if (isHigherUp) {
								return 'maxAgeDisclaimerLimitedEMGGET'
							} else if (isVO20) {
								return 'maxAgeDisclaimerLimitedVo20'
							} else {
								return 'maxAgeDisclaimerLimited'
							}
						})()
				)}
			</>
		)) ||
		''

	const currentReduction =
		parsedSavedReductionType || (benefitType?.type === 'Erwerbsminderung' ? benefitType.erwerbsminderungType : '')
	const disableIfNotEditableAndNotCurrentReduction = (reduction: any) => {
		return !isEditable?.editable && reduction !== currentReduction
	}

	const items: {
		value: components['schemas']['AuszahlungsoptionLeistungErwerbsminderung']['erwerbsminderungType']
		label: string
		subLabel: string
		description: JSX.Element | string
		checked: boolean
		disabled?: boolean
	}[] = [
		{
			value: 'befristetVoll',
			label: t('view.payoutOptions.chapterOne.payoutReduction.options.befristetVoll.headline'),
			subLabel: t(
				`view.payoutOptions.chapterOne.payoutReduction.options.befristetVoll.subHeadline${
					isHigherUp ? 'EMGGET' : isVO20 ? 'Vo20' : ''
				}`
			),
			description: (
				<>
					{t('view.payoutOptions.chapterOne.payoutReduction.options.befristetVoll.description')}
					{optionalMaxAgeDisclaimerLimited}
				</>
			),
			checked: currentReduction === 'befristetVoll',
			disabled: disableIfNotEditableAndNotCurrentReduction('befristetVoll'),
		},
		{
			value: 'unbefristetVoll',
			label: t('view.payoutOptions.chapterOne.payoutReduction.options.unbefristetVoll.headline'),
			subLabel: t(
				`view.payoutOptions.chapterOne.payoutReduction.options.unbefristetVoll.subHeadline${
					isHigherUp ? 'EMGGET' : isVO20 ? 'Vo20' : ''
				}`
			),
			description: (
				<>
					{t('view.payoutOptions.chapterOne.payoutReduction.options.unbefristetVoll.description')}
					{optionalMaxAgeDisclaimer}
				</>
			),
			checked: currentReduction === 'unbefristetVoll',
			disabled: disableIfNotEditableAndNotCurrentReduction('unbefristetVoll'),
		},
		{
			value: 'befristetTeilweise',
			label: t('view.payoutOptions.chapterOne.payoutReduction.options.befristetTeilweise.headline'),
			subLabel: t(
				`view.payoutOptions.chapterOne.payoutReduction.options.befristetTeilweise.subHeadline${
					isHigherUp ? 'EMGGET' : isVO20 ? 'Vo20' : ''
				}`
			),
			description: (
				<>
					{' '}
					{(() => {
						if (isHigherUp) {
							return t(
								'view.payoutOptions.chapterOne.payoutReduction.options.befristetTeilweise.descriptionEMGGET'
							)
						} else if (isVO20) {
							return t(
								'view.payoutOptions.chapterOne.payoutReduction.options.befristetTeilweise.descriptionVo20'
							)
						} else {
							return t(
								'view.payoutOptions.chapterOne.payoutReduction.options.befristetTeilweise.description'
							)
						}
					})()}
				</>
			),
			checked: currentReduction === 'befristetTeilweise',
			disabled: disableIfNotEditableAndNotCurrentReduction('befristetTeilweise'),
		},
		{
			value: 'unbefristetTeilweise',
			label: t('view.payoutOptions.chapterOne.payoutReduction.options.unbefristetTeilweise.headline'),
			subLabel: t(
				`view.payoutOptions.chapterOne.payoutReduction.options.unbefristetTeilweise.subHeadline${
					isHigherUp ? 'EMGGET' : isVO20 ? 'Vo20' : ''
				}`
			),
			description: (
				<>
					{(() => {
						if (isHigherUp) {
							return t(
								'view.payoutOptions.chapterOne.payoutReduction.options.unbefristetTeilweise.descriptionEMGGET'
							)
						} else if (isVO20) {
							return t(
								'view.payoutOptions.chapterOne.payoutReduction.options.unbefristetTeilweise.descriptionVo20'
							)
						} else {
							return t(
								'view.payoutOptions.chapterOne.payoutReduction.options.unbefristetTeilweise.description'
							)
						}
					})()}
					{optionalMaxAgeDisclaimer}
				</>
			),
			checked: currentReduction === 'unbefristetTeilweise',
			disabled: disableIfNotEditableAndNotCurrentReduction('unbefristetTeilweise'),
		},
	]

	const mutation = useMutation(api.patchPayoutModel, {
		onSuccess: (payload) => {
			localStorage.removeItem('reduction-type')
			localStorage.removeItem('benefit-type')

			queryClient.setQueryData(QueryKey.payoutModelInProgress, { ...payload })
			navigateTo(`${getChildPath(routesDictionary.payoutOptions, 'payoutModel')}`)
		},
	})

	const goToPayoutEmployeeContribution = (value: 'unbefristetTeilweise' | 'unbefristetVoll') => {
		localStorage.setItem('reduction-type', JSON.stringify(value))
		navigateTo(`${getChildPath(routesDictionary.payoutOptions, 'PayoutEmployeeContribution')}/${value}`)
	}

	const patchWorkingCopy = (value: string) => {
		const payload: components['schemas']['AuszahlungsoptionLeistungErwerbsminderung'] = {
			type: 'Erwerbsminderung',
			erwerbsminderungType:
				value as components['schemas']['AuszahlungsoptionLeistungErwerbsminderung']['erwerbsminderungType'],
			payoutEmployeeContribution: false,
		}
		mutation.mutate({ leistungsart: payload })
	}

	const onClick = (): void => {
		switch (value) {
			case 'unbefristetVoll':
			case 'unbefristetTeilweise':
				goToPayoutEmployeeContribution(value)
				break
			default:
				value && patchWorkingCopy(value)
		}
	}

	return (
		<div className="center-view">
			<h3>
				<Trans i18nKey="view.payoutOptions.chapterOne.payoutReduction.introText" />
			</h3>

			<PayoutModelList
				className="grid__center"
				items={items}
				onChange={(v) => {
					setValue(v)
				}}
			/>
			<div className="padding padding--top">
				<Button
					disabled={!value}
					className="grid__bottom"
					type={ButtonType.primary}
					label={t('view.payoutOptions.chapterOne.payoutReduction.button')}
					onClick={onClick}
				/>
			</div>
		</div>
	)
}

export default PayoutReduction
