import useAsyncEffect from '@n1ru4l/use-async-effect'
import AlreadyLoggedIn from 'components/AlreadyLoggedIn'
import { IconType } from 'components/Icons'
import TermsAndConditions from 'components/TermsAndConditions'
import { useAuth } from 'hooks/useAuth'
import { useQueryParams } from 'raviger'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { trackPromise } from 'react-promise-tracker'
import Button, { ButtonType } from 'shared/components/Button'
import Form, { FormFieldType, FormFields } from 'shared/components/Form'
import Icon from 'shared/components/Icon'
import Modal, { ModalRefActions } from 'shared/components/Modal'
import { TextInputType } from 'shared/components/TextInput'
import { ApiErrorCode } from 'shared/enums'
import isDev from 'shared/helper/isDev'
import { parseQueryString } from 'shared/helper/parseQueryString'
import useGlobalFormState from 'shared/hooks/useGlobalFormState'
import { useResetHeaderState } from 'state/useHeaderState'
import SendUserIdLoginHint from './LoginPartials/SendUserIdLoginHint'
import SendUserIdNotRegisteredHint from './LoginPartials/SendUserIdNotRegisteredHint'
import SendUserIdRegisterBodytext from './RegisterPartials/SendUserIdRegisterBodytext'
import RegistrationIntro from './RegistrationIntro'

const SendUserId = () => {
	useResetHeaderState()

	const [queryParams] = useQueryParams(parseQueryString)
	const { t } = useTranslation()
	const auth = useAuth()
	const challenge = auth.challenge
	const [authTries, setAuthTries] = useState<number>(0)
	const [formState, formActions] = useGlobalFormState()

	const modal = useRef<ModalRefActions>()
	const inputElements: FormFields = useMemo(() => {
		let fields: FormFields = {
			username: {
				value:
					formState.username ||
					(isDev() ? queryParams.id || (process.env.REACT_APP_USER_ID as string) : queryParams.id || ''),
				required: true,
				type: TextInputType.text,
				autoComplete: 'username',
				label: queryParams.register
					? t('view.register.formFieldLabels.id')
					: t('view.login.formFieldLabels.idOrEmail'),
				fieldType: FormFieldType.text,
			},
		}

		if (queryParams.register) {
			fields['termsandconditions'] = {
				required: true,
				fieldType: FormFieldType.checkBox,
				label: t('view.login.formFieldLabels.readTermsAndConditions'),
				size: 'large',
				className: 'font-size-s margin--vertical',
				prepend: (
					<Button
						type={[ButtonType.link]}
						label={t('view.register.buttonLabel.goToTermsAndConditions')}
						onClick={() => modal.current?.openModal()}
						className="font-size-s text-color-cyan margin--vertical no-margin--top"
					/>
				),
			}
		}

		return fields
		// eslint-disable-next-line
	}, [queryParams.id, queryParams.register, t])

	useAsyncEffect(
		function* (setErrorHandler: any, c: any) {
			if (undefined === queryParams.id) {
				return
			}

			const loginSuccessful = yield* c(auth.sendUserId({ username: queryParams.id }))

			if (false === loginSuccessful) {
				setAuthTries(1)
			}
		},
		[queryParams]
	)

	useEffect(() => {
		/**
		 * if the users' login session timed out, reset formState
		 */
		if (ApiErrorCode.SESSION_TIMEOUT === challenge?.lastChallengeError) {
			formActions.resetState()
		}
		// eslint-disable-next-line
	}, [])

	if (auth.userData) {
		return <AlreadyLoggedIn />
	}

	if (queryParams.id) {
		if (0 === authTries) {
			return null
		}
	}

	if (queryParams.register && !formState.checksPassed) {
		return <RegistrationIntro />
	}

	const onHandleSubmit = async (submittedFields: { [key: string]: any }) => {
		formActions.setFields(submittedFields)
		return await trackPromise(auth.sendUserId(submittedFields), 'login')
	}

	return (
		<div className="login form-view">
			<div className="form-view__title">
				<h1>
					<Trans i18nKey={`view.${queryParams.register ? 'register' : 'login'}.sendUserId.pageTitle`} />
				</h1>
			</div>

			<div className="form-view__bodytext">
				{queryParams.register ? <SendUserIdRegisterBodytext /> : <SendUserIdLoginHint />}

				{ApiErrorCode.SESSION_TIMEOUT === challenge?.lastChallengeError && (
					<div className="margin--vertical flex flex--align-items-start flex--justify-content-center">
						<Icon className="margin--horizontal no-margin--left" type={IconType.info} />
						<Trans
							i18nKey={`apiErrors.${challenge.lastChallengeError}`}
							values={{ error: challenge.lastChallengeError }}
						/>
					</div>
				)}
			</div>

			<Form
				className="form-view"
				fields={inputElements}
				onSubmit={onHandleSubmit}
				submitLabel={t(`view.${queryParams.register ? 'register' : 'login'}.buttonLabel.sendUserId`)}
				promiseTracker={{ area: 'login', delay: 0 }}
			/>

			{queryParams.register ? (
				<Modal ref={modal} size="large" className="terms-and-conditions-modal" buttonLabel={t('generic.close')}>
					<TermsAndConditions />
				</Modal>
			) : (
				<SendUserIdNotRegisteredHint className="form-view__bodytext" />
			)}
		</div>
	)
}

export default SendUserId
