import { useRoutes } from 'raviger'
import React from 'react'
import routesDictionary from 'routes'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import NotFound from 'views/NotFound'

const DeferredCompensation: React.FC = () => {
	const { routes, getMainPath } = useRouteHelper(routesDictionary.deferredCompensation)

	const routeResult = useRoutes(routes, { basePath: getMainPath(routesDictionary.deferredCompensation) })

	return routeResult || <NotFound />
}

export default DeferredCompensation
