import { DefaultTFuncReturn } from 'i18next'
import React, { createContext, FunctionComponent, PropsWithChildren, ReactElement, useEffect, useState } from 'react'

export interface RadiobuttonGroupProps {
	className?: string
	onChange?: (data: unknown | undefined) => void
	headline?: string | React.ReactElement | DefaultTFuncReturn
	error?: { type: string; message?: string }
}

export interface RadiobuttonGroupContext {
	selectedItemIndex?: number
	selectItem: (index: number) => void
}

export const radiobuttonGroupContext = createContext<RadiobuttonGroupContext>({
	selectItem: () => {
		return
	},
})

const RadiobuttonGroup: FunctionComponent<PropsWithChildren<RadiobuttonGroupProps>> = (props) => {
	const [selectedItemIndex, setSelectedItemIndex] = useState<number | undefined>(undefined)

	const getClasses = (): string => {
		const classes = ['radiobutton-group']

		if (props.className) {
			classes.push(props.className)
		}

		return classes.join(' ')
	}

	useEffect(() => {
		props?.onChange?.(selectedItemIndex)
		// eslint-disable-next-line
	}, [selectedItemIndex])

	const selectItem = (index: number) => {
		setSelectedItemIndex(index)
	}

	const showError = (): ReactElement | undefined => {
		return (
			<span style={{ opacity: Number(undefined !== props.error) }} className="radiobutton-group__error">
				{props.error?.message}
			</span>
		)
	}

	return (
		<div className={getClasses()}>
			{props.headline && <h3 className="radiobutton-group__headline">{props.headline}</h3>}

			<div className="radiobutton-group__wrapper">
				<radiobuttonGroupContext.Provider value={{ selectedItemIndex, selectItem }}>
					{props.children}
				</radiobuttonGroupContext.Provider>
			</div>

			{showError()}
		</div>
	)
}

export default RadiobuttonGroup
