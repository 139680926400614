import { IconType } from 'components/Icons'
import { useAuth } from 'hooks/useAuth'
import React, { useEffect } from 'react'
import { Trans } from 'react-i18next'
import Icon, { IconSize } from 'shared/components/Icon'
import useGlobalFormState from 'shared/hooks/useGlobalFormState'
import { useResetHeaderState } from 'state/useHeaderState'

const AccountLocked = () => {
	useResetHeaderState()

	const auth = useAuth()
	const [, formActions] = useGlobalFormState()

	useEffect(() => {
		formActions.resetState()
		auth.signout()
		// eslint-disable-next-line
	}, [])

	return (
		<div className="account-locked center-view">
			<div className="center-view flex flex--direction-column flex--align-items-center">
				<div className="page-title">
					<h1>
						<Trans i18nKey="view.accountLocked.pageTitle" />
					</h1>
				</div>

				<Icon
					className="account-locked__icon margin--vertical margin--large no-margin--top"
					type={IconType.locked}
					color="var(--color-cyan)"
					size={IconSize.extraLarge}
				/>
				<Trans i18nKey="view.accountLocked.copy" />
			</div>
		</div>
	)
}

export default AccountLocked
