import { useUserGroup } from 'hooks/useUserGroup'
import React, { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Hint from 'shared/components/Hint'
import SwitchSelect, { SwitchSelectOption } from 'shared/components/SwitchSelect'
import { OPTEOptions, OPTTOptions, RiskOptions } from 'shared/enums'
import RiskPreviewBenefit from './RiskPreviewBenefit'
import RiskPreviewCompensation from './RiskPreviewCompensation'

export enum RiskPreviewType {
	benefit = 'benefit',
	compensation = 'compensation',
}

export interface RiskPreviewProps {
	className?: string
	type?: OPTEOptions | OPTTOptions
	otherType?: OPTEOptions | OPTTOptions
	riskId: RiskOptions
}

const RiskPreview: React.FC<RiskPreviewProps> = (props) => {
	const { t } = useTranslation()
	const { className, type, otherType, riskId } = props

	const [previewType, setPreviewType] = useState<RiskPreviewType>(RiskPreviewType.benefit)
	const { isHigherUp } = useUserGroup()

	const getClasses = useCallback((): string => {
		const classes: string[] = ['risk-preview']

		if (className) {
			classes.push(className)
		}

		return classes.join(' ')
	}, [className])

	return (
		<div className={getClasses()}>
			<SwitchSelect
				className="margin--vertical margin--large"
				options={Object.values(RiskPreviewType).reduce((options: SwitchSelectOption[], value: string) => {
					options.push({
						value,
						label: t(`view.riskBenefit.buttonLabel.${value}`),
					})

					return options
				}, [])}
				value={previewType}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
					setPreviewType(event.target.value as RiskPreviewType)
				}
			/>

			{RiskPreviewType.benefit === previewType && <RiskPreviewBenefit type={type} riskId={riskId} />}
			{RiskPreviewType.compensation === previewType && <RiskPreviewCompensation riskId={riskId} type={type} />}
			{!isHigherUp && (
				<Hint
					content={
						<Trans
							i18nKey={`view.riskBenefit.types.${type}.hint.${riskId}`}
							values={{ type: t(`view.riskBenefit.types.${otherType || OPTEOptions.STANDARD}.label`) }}
						/>
					}
				/>
			)}

			<p className="margin--vertical opacity--50 font-size-xs">
				<Trans i18nKey={`view.riskBenefit.previewDisclaimer.${previewType}`} />
			</p>
		</div>
	)
}

export default RiskPreview
