import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Image from 'shared/components/Image'
import Tooltip from 'shared/components/Tooltip'
import anschreibenAnschreiben_02Initialpasswort from '../../../assets/images/anschreiben-anschreiben-02-initialpasswort.png'
import anschreibenAnschreiben_02Initialpasswort_2x from '../../../assets/images/anschreiben-anschreiben-02-initialpasswort@2x.png'
import { getMusterInitPasswortUrl } from 'shared/static-assets'

const SendPasswordRegisterBodytext: React.FC = () => {
	const { t } = useTranslation()

	return (
		<>
			<div className="flex">
				<Image
					className="margin--small-negative margin--left margin--top drop-shadow--bottom-3-steps"
					srcSet={`${anschreibenAnschreiben_02Initialpasswort} 1x, ${anschreibenAnschreiben_02Initialpasswort_2x} 2x`}
					width="101"
					height="141"
					alt=""
					href={getMusterInitPasswortUrl()}
				/>

				<div className="margin--left">
					<p>
						<Trans i18nKey="view.register.sendPassword.bodytext" />
					</p>
					<div className="margin--vertical margin--small">
						<Tooltip
							label={t('view.register.letterHelp.header')}
							headline={t('view.register.letterHelp.header')}
							content={
								<Trans
									i18nKey="generic.helpHotline.content"
									values={{
										emailAddress: t('generic.helpHotline.mail'),
										phoneNumber: t('generic.helpHotline.tel'),
									}}
								>
									<a href={t('generic.helpHotline.telLink')}>{'  '}</a>
									<a href={t('generic.helpHotline.mailLink')}>{'  '}</a>
								</Trans>
							}
						/>
					</div>
				</div>
			</div>

			<p className="text-color-cyan margin--vertical">
				<Trans i18nKey="view.register.sendPassword.hinttext" />
			</p>
		</>
	)
}

export default SendPasswordRegisterBodytext
