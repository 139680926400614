import { FC } from 'react'
import { Trans } from 'react-i18next'
import routesDictionary from 'routes'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { components } from 'types/api-interface'
import FormSummary from '../ChapterTwo/FormSummary'

const Summary: FC<{
	data: components['schemas']['AuszahlungsoptionenArbeitskopie']
}> = (props) => {
	const { navigateTo, getChildPath } = useRouteHelper(routesDictionary.payoutOptions)

	const onClick = (step: number) => {
		navigateTo(`${getChildPath(routesDictionary.payoutOptions, 'payoutModelInputForm')}/${step}`)
	}

	if (!props.data.leistungsart || !props.data.modell || !('type' in props.data.modell)) {
		return <></>
	}

	return (
		<div className="chapter-two-summary payout-form-summary">
			<FormSummary
				data={props.data}
				onClick={(i: number) => {
					onClick(i)
				}}
			></FormSummary>
		</div>
	)
}
export const PayoutConfirmationChapterTwo = {
	header: (
		<h3>
			<Trans i18nKey={'view.payoutOptions.payoutTypes.options.second.headline'}></Trans>
		</h3>
	),
	content: Summary,
}
