import useApi, { QueryKey } from 'hooks/useApi'
import { useAuth } from 'hooks/useAuth'
import React, { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import dateFormat from 'shared/helper/dateFormat'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { IRiskPartnerData, MfaResponseData } from 'shared/interfaces'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'

const RBPartnerSummary: React.FC<{ state: 'default' | 'removePartner' }> = ({ state }) => {
	const { getChildPath, navigateTo } = useRouteHelper()
	useBackButtonPath(
		getChildPath(
			routesDictionary.riskBenefit,
			'removePartner' === state ? 'RBPartnerBenefit' : 'RBPartnerConditions'
		)
	)
	useNavigationTitle(<Trans i18nKey="view.riskBenefit.pageTitle.default" />)

	const { t } = useTranslation()
	const auth = useAuth()
	const api = useApi()
	const queryClient = useQueryClient()

	const { data } = useQuery(QueryKey.riskPartnerData, api.getRiskPartnerData, { enabled: 'removePartner' === state })

	const [cachedData, setCachedData] = useState<IRiskPartnerData>()

	useEffect(() => {
		if ('removePartner' === state) {
			return
		}

		const currentData = sessionStorage.getItem('riskPartnerData')

		if (null === currentData) {
			return navigateTo(getChildPath(routesDictionary.riskBenefit, 'RBPartnerForm'))
		}

		setCachedData(JSON.parse(currentData))
		// eslint-disable-next-line
	}, [state])

	useEffect(() => {
		if (undefined === data || 'removePartner' !== state) {
			return
		}

		setCachedData(data)
	}, [data, state])

	const onClickHandler = useCallback(async () => {
		if (undefined === cachedData) {
			return
		}

		const mfaResponse = await ('default' === state
			? api.setRiskPartnerData(cachedData)
			: api.deleteRiskPartnerData())

		if (undefined !== (mfaResponse as MfaResponseData).transactionId) {
			auth.addMfaAction({
				transactionId: (mfaResponse as MfaResponseData).transactionId,
				onSuccess: () => {
					queryClient.removeQueries(QueryKey.riskOptions)
					queryClient.removeQueries(QueryKey.riskPartnerData)
					sessionStorage.removeItem('riskPartnerData')
					navigateTo(getChildPath(routesDictionary.riskBenefit, 'RBSuccess'), true)
				},
			})
		}

		return mfaResponse
		// eslint-disable-next-line
	}, [cachedData])

	return (
		<div className="risk-benefit center-view grid grid--center-scroll rb-partner-form">
			<h3 className="text-align--left grid__top">
				<Trans i18nKey="view.riskBenefit.summary.headline" />
			</h3>

			<div className="grid__center">
				<p className="margin--vertical">
					<b>
						<Trans i18nKey="view.riskBenefit.partner.changeRequest" />
					</b>
				</p>

				<p className="text-color-cyan">
					<b>
						<Trans
							i18nKey={`view.riskBenefit.partner.${
								'removePartner' === state ? 'removePartner' : 'setPartner'
							}`}
						/>
					</b>
				</p>

				{'default' === state && (
					<p className="margin--vertical">
						<Trans i18nKey="view.riskBenefit.partner.description" />
					</p>
				)}

				<div className="risk-benefit__partner-form-summary margin--vertical">
					{cachedData && (
						<>
							{Object.keys(cachedData).map((item, index) => (
								<React.Fragment key={`partner-form-summary-item-${index}`}>
									<span>
										<Trans i18nKey={`view.riskBenefit.formFieldLabels.${item}`} />:
									</span>
									<span className={['VORNA_EP', 'NACHN_EP'].includes(item) ? 'bold' : ''}>
										{['GBDAT_EP'].includes(item)
											? dateFormat(new Date(cachedData[item as keyof IRiskPartnerData]))
											: cachedData[item as keyof IRiskPartnerData]}
									</span>
								</React.Fragment>
							))}
						</>
					)}
				</div>
			</div>

			<div className="grid__bottom">
				<Button onClick={onClickHandler} type={ButtonType.primary} label={t('generic.submit')} />
			</div>
		</div>
	)
}

export default RBPartnerSummary
