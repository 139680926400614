import theme, { fallbackChartColor, lineChartColors } from 'components/ChartsTheme'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useMeasure } from 'react-use'
import { FundData, FundPerformanceData } from 'shared/interfaces'
// @ts-ignore
import { VictoryGroup, VictoryLine, VictoryScatter, VictoryThemeDefinition } from 'victory'

export interface IndiceComparisonDashboardProps {
	data: FundPerformanceData
}

const lineAnimationDuration = 1500
const pointAnimationDuration = 250
const padding = 24

const IndiceComparisonDashboard: FunctionComponent<IndiceComparisonDashboardProps> = (props) => {
	const chartTheme: VictoryThemeDefinition = theme
	const [measureRef, dimensions] = useMeasure<HTMLDivElement>()
	const [scatterPointOpacity, setScatterPointOpacity] = useState<number>(0)

	useEffect(() => {
		const timer = setTimeout(() => {
			setScatterPointOpacity(1)
		}, lineAnimationDuration - pointAnimationDuration)

		return () => clearTimeout(timer)
	}, [])

	const { data, domainX, isIndice } = useMemo(() => {
		if (props.data === undefined) {
			return {}
		}

		let isIndice = false

		let data = [
			...props.data.fundValues.map((fund: FundData) => {
				return fund.data
			}),
		]

		/**
		 * if there is no fundData use the indiceValues in the chart
		 */
		if (0 === data.length || data.every((item) => item.length === 0)) {
			data = [props.data.indiceValues].filter((item: any) => {
				return item.length > 0
			})
			isIndice = true
		}

		const maximaX: number[] = data.map((dataset) => Math.max(...dataset.map((d) => d.x)))
		const minimaX: number[] = data.map((dataset) => Math.min(...dataset.map((d) => d.x)))
		const domainX: [number, number] = [Math.min(...minimaX), Math.max(...maximaX)]

		return {
			data,
			domainX,
			isIndice,
		}
	}, [props.data])

	return (
		<div style={{ position: 'absolute', inset: 0 }} ref={measureRef}>
			{dimensions.width > 0 && dimensions.height > 0 && (
				<VictoryGroup
					theme={chartTheme}
					height={dimensions.height}
					width={dimensions.width}
					scale={{ x: 'time', y: 'linear' }}
					domain={{ x: domainX }}
					domainPadding={{ y: 4 }}
					padding={{ bottom: 0, left: 0, right: padding, top: padding }}
				>
					{data?.map((d, i) => {
						if (d.length === 0) {
							return null
						}

						return (
							<VictoryLine
								data={d}
								animate={{
									duration: lineAnimationDuration,
								}}
								key={i}
								name="line-chart"
								interpolation="monotoneX"
								style={{
									parent: {
										pointerEvents: 'none',
									},
									data: {
										stroke: isIndice
											? fallbackChartColor
											: lineChartColors[i] || fallbackChartColor,
										strokeLinecap: 'round',
										strokeWidth: 4,
									},
								}}
							/>
						)
					})}
					{data?.map((d, i) => {
						if (d.length === 0) {
							return null
						}

						return (
							<VictoryScatter
								key={i}
								style={{
									data: {
										fill: isIndice ? fallbackChartColor : lineChartColors[i] || fallbackChartColor,
										opacity: scatterPointOpacity,
										transitionProperty: 'opacity',
										transitionDuration: `${pointAnimationDuration}ms`,
									},
								}}
								size={10}
								data={[d.at(-1)]}
							/>
						)
					})}
				</VictoryGroup>
			)}
		</div>
	)
}

export default IndiceComparisonDashboard
