import { Trans } from 'react-i18next'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath } from 'state/useHeaderState'

const StartMigration = () => {
	const { getChildPath, navigateTo, getMainPath } = useRouteHelper(routesDictionary.migration)

	useBackButtonPath(getMainPath(routesDictionary.dashboard))
	const navigate = () => {
		navigateTo(getChildPath(routesDictionary.migration, 'newEmail'))
	}

	return (
		<div className="migration grid  grid--center-scroll center-view">
			<div className="grid__top">
				<h1>
					<Trans i18nKey="view.migration.startMigration.headline"></Trans>
				</h1>
			</div>
			<div className="grid__center">
				<p>
					<Trans i18nKey="view.migration.startMigration.bodyText"></Trans>
				</p>
			</div>
			<div className="grid__bottom">
				<Button
					onClick={navigate}
					type={ButtonType.primary}
					label={<Trans i18nKey="generic.send"></Trans>}
				></Button>
			</div>
		</div>
	)
}

export default StartMigration
