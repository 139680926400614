import useApi, { QueryKey } from 'hooks/useApi'
import { useUserGroup } from 'hooks/useUserGroup'
import { FC, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'
import PayoutModelList, { PayoutModelListItemProps } from '../components/PayoutModelList'

interface IPayoutModel {
	aboveThreshold?: boolean
}

export enum PayoutModelOptions {
	pension = 'pension',
	instalments20Years = 'instalments20Years',
	instalments10Years = 'instalments10Years',
	mixed = 'mixed',
	lumpSum = 'lumpSum',
}

const PayoutModel: FC<IPayoutModel> = () => {
	const { getMainPath, navigateTo, getChildPath } = useRouteHelper()
	const [value, setValue] = useState<string>()
	const api = useApi()
	const { isVO20, isHigherUp } = useUserGroup()

	const payoutModelInProgressQuery = useQuery(QueryKey.payoutModelInProgress, api.getPayoutModelInProgress)

	const { data: isEditable } = useQuery(QueryKey.payoutModelEditable, api.isPayoutModelEditable)
	const isErwerbsminderung = payoutModelInProgressQuery.data?.leistungsart?.type === 'Erwerbsminderung'
	const erwerbsminderungType =
		payoutModelInProgressQuery.data?.leistungsart?.type === 'Erwerbsminderung'
			? payoutModelInProgressQuery.data.leistungsart.erwerbsminderungType
			: undefined

	const isErwerbsminderungBefristet =
		erwerbsminderungType === 'befristetVoll' || erwerbsminderungType === 'befristetTeilweise'
	const isErwerbsminderungUnbefristet =
		erwerbsminderungType === 'unbefristetTeilweise' || erwerbsminderungType === 'unbefristetVoll'

	const getBackButtonPath = () => {
		const savedType = payoutModelInProgressQuery.data?.leistungsart?.type

		switch (savedType) {
			case 'Ruhestand':
				return `${getChildPath(routesDictionary.payoutOptions, 'pensionDate')}/Ruhestand`
			case 'VorgezogenerRuhestand':
				return `${getChildPath(routesDictionary.payoutOptions, 'pensionDate')}/VorgezogenerRuhestand`
			case 'Erwerbsminderung':
				return getChildPath(routesDictionary.payoutOptions, 'payoutReduction')
		}

		return getMainPath(routesDictionary.dashboard)
	}

	useBackButtonPath(getBackButtonPath())
	useNavigationTitle(<Trans i18nKey="view.payoutOptions.chapterOne.payoutModel.pageTitle" />)
	const { status, data, refetch, isLoading, isFetching } = useQuery(
		QueryKey.availableModels,
		api.getAvailablePayoutModels
	)

	useEffect(() => {
		refetch()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const { t } = useTranslation()

	const savedPayoutModel = localStorage.getItem('payout-model')
	const parsedSavedPayoutModel = savedPayoutModel ? JSON.parse(savedPayoutModel) : null

	const currentPayoutModel =
		parsedSavedPayoutModel ||
		(payoutModelInProgressQuery.data?.modell &&
			'type' in payoutModelInProgressQuery.data.modell &&
			payoutModelInProgressQuery.data.modell.type)
	const disableIfNotEditableAndNotCurrentPayoutModel = (payoutModel: PayoutModelOptions) => {
		return !isEditable?.editable && currentPayoutModel !== payoutModel
	}

	const radioButtons: PayoutModelListItemProps[] = [
		{
			value: PayoutModelOptions.instalments20Years,
			label: t('view.payoutOptions.chapterOne.payoutModel.options.twentyYears.headline'),
			description: (
				<Trans i18nKey="view.payoutOptions.chapterOne.payoutModel.options.twentyYears.description"></Trans>
			),
			disabled:
				!data?.instalments20Years ||
				disableIfNotEditableAndNotCurrentPayoutModel(PayoutModelOptions.instalments20Years),
			checked: currentPayoutModel === PayoutModelOptions.instalments20Years && data?.instalments20Years,
		},
		{
			value: PayoutModelOptions.instalments10Years,
			label: t('view.payoutOptions.chapterOne.payoutModel.options.tenYears.headline'),
			description: (
				<Trans i18nKey="view.payoutOptions.chapterOne.payoutModel.options.tenYears.description"></Trans>
			),
			disabled:
				!data?.instalments10Years ||
				disableIfNotEditableAndNotCurrentPayoutModel(PayoutModelOptions.instalments10Years),
			checked: currentPayoutModel === PayoutModelOptions.instalments10Years && data?.instalments10Years,
		},
		{
			value: PayoutModelOptions.lumpSum,
			label: t('view.payoutOptions.chapterOne.payoutModel.options.single.headline'),
			description: <Trans i18nKey="view.payoutOptions.chapterOne.payoutModel.options.single.description"></Trans>,
			disabled: !data?.lumpSum || disableIfNotEditableAndNotCurrentPayoutModel(PayoutModelOptions.lumpSum),
			checked: currentPayoutModel === PayoutModelOptions.lumpSum && data?.lumpSum,
		},
		{
			value: PayoutModelOptions.pension,
			label: t('view.payoutOptions.chapterOne.payoutModel.options.pension.headline'),
			description: (
				<>
					{!isErwerbsminderung && (
						<Trans
							i18nKey={`view.payoutOptions.chapterOne.payoutModel.options.pension.description${
								isVO20 ? 'Vo20' : ''
							}`}
						></Trans>
					)}
					{isErwerbsminderungUnbefristet && (
						<Trans i18nKey="view.payoutOptions.chapterOne.payoutModel.options.pension.reducedPensionUnbefristet"></Trans>
					)}
					{isErwerbsminderungBefristet && (
						<Trans i18nKey="view.payoutOptions.chapterOne.payoutModel.options.pension.reducedPensionBefristet"></Trans>
					)}
				</>
			),
			disabled: !data?.pension || disableIfNotEditableAndNotCurrentPayoutModel(PayoutModelOptions.pension),
			checked: currentPayoutModel === PayoutModelOptions.pension && data?.pension,
		},
		{
			value: PayoutModelOptions.mixed,
			label: t('view.payoutOptions.chapterOne.payoutModel.options.mixed.headline'),
			description: (() => {
				if (isHigherUp) {
					return <Trans i18nKey="view.payoutOptions.chapterOne.payoutModel.options.mixed.descriptionEMGGET" />
				} else if (isVO20) {
					return <Trans i18nKey="view.payoutOptions.chapterOne.payoutModel.options.mixed.descriptionVo20" />
				} else {
					return <Trans i18nKey="view.payoutOptions.chapterOne.payoutModel.options.mixed.description" />
				}
			})(),
			disabled: !data?.mixed || disableIfNotEditableAndNotCurrentPayoutModel(PayoutModelOptions.mixed),
			checked: currentPayoutModel === PayoutModelOptions.mixed && data?.mixed,
		},
	]

	const handleSelect = (val: string) => {
		if (typeof val === 'number' && !isNaN(val)) {
			const updatedValue = radioButtons[val].value as PayoutModelOptions
			setValue(updatedValue)
			return
		}
		setValue(val)
	}

	const nextViewClick = () => {
		if (value && status === 'success') {
			localStorage.setItem('payout-model', JSON.stringify(value))
			navigateTo(`${getChildPath(routesDictionary.payoutOptions, 'confirmDetails')}/${value}`)
		}
	}

	const selectedValueDisabled = (data && value && value in data && !data[value as keyof typeof data]) || false

	return (
		<div className="center-view grid grid--center-scroll">
			{isLoading === false && isFetching === false && status === 'success' && data && (
				<>
					{!data?.restrictionReason && (
						<>
							<h3>
								<Trans i18nKey="view.payoutOptions.chapterOne.payoutModel.introText"></Trans>
							</h3>
						</>
					)}

					{data.restrictionReason && (
						<h3>
							<Trans
								i18nKey={`view.payoutOptions.chapterOne.payoutModel.restrictionReasons.${data.restrictionReason}`}
							></Trans>
						</h3>
					)}
					<PayoutModelList className="grid__center" items={radioButtons} onChange={handleSelect} />
				</>
			)}

			<div className="grid__bottom">
				<Button
					disabled={!value || selectedValueDisabled}
					type={ButtonType.primary}
					label={isEditable?.editable ? t('view.payoutModel.submit') : t('generic.next')}
					onClick={nextViewClick}
				/>
			</div>
		</div>
	)
}

export default PayoutModel
