import DeclineTermsAndConditions from 'components/UpdateTerms/DeclineTermsAndConditions'
import UpdateContactTerms from 'components/UpdateTerms/UpdateContactTerms'
import UpdatedTermsAndConditionsModalContent from 'components/UpdateTerms/UpdatedTermsAndConditions'
import { activatedFeatures } from 'helper/activatedFeatures'
import { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import useGlobalModalState from 'shared/hooks/useGlobalModalState'
import useApi, { QueryKey } from './useApi'
import { useAuth } from './useAuth'
import { useUserGroup } from './useUserGroup'

export const useTermsAndConditions = () => {
	const api = useApi()
	const auth = useAuth()
	const currenV = '2023-04-03'
	const { isStronglyAuthenticated } = useUserGroup()

	const { data } = useQuery([QueryKey.termsOfServe, { currentVersion: currenV }], api.getTermsOfServiceConsent, {
		enabled: activatedFeatures.updatedTermsDialog && isStronglyAuthenticated === true,
	})

	const mutation = useMutation(api.putTermsOfServiceConsent, {
		onSuccess: (data) => {
			modalActions.closeModal()
			window.location.reload()
		},
	})

	const [view, setView] = useState<'terms' | 'decline' | 'contact' | undefined>(undefined)

	useEffect(() => {
		if (data?.newConsentNeeded) {
			setView('terms')
		}
	}, [data])


	const show = data?.newConsentNeeded
	const [, modalActions] = useGlobalModalState()

	useEffect(() => {
		const views = {
			terms: {
				headline: <Trans i18nKey={'component.termsModal.updateTermsHeadline'} />,
				content: (
					<UpdatedTermsAndConditionsModalContent
						onAccept={() => {
							setView('contact')
						}}
						onDecline={() => {
							setView('decline')
						}}
					/>
				),
			},
			decline: {
				headline: <Trans i18nKey={'component.termsModal.declineTermsAndConditionsHeadline'} />,
				content: (
					<DeclineTermsAndConditions
						onDecline={async () => {
							const response = await api.putTermsOfServiceConsent({
								termsOfServiceAccepted: false,
								usageOfContactDataAccepted: false,
							})

							if (response) {
								auth.signout()
								modalActions.closeModal()
							}
						}}
						onBack={() => {
							setView('terms')
						}}
					/>
				),
			},
			contact: {
				headline: <Trans i18nKey={'component.termsModal.updateContactTermsHeadline'} />,
				content: (
					<UpdateContactTerms
						onAccept={() => {
							mutation.mutate({ termsOfServiceAccepted: true, usageOfContactDataAccepted: true })
						}}
						onPartialAccept={() => {
							mutation.mutate({ termsOfServiceAccepted: true, usageOfContactDataAccepted: false })
						}}
						onBack={() => {
							setView('terms')
						}}
					></UpdateContactTerms>
				),
			},
		}

		if (show && view !== undefined) {
			const { headline, content } = views[view]
			modalActions.setClass('terms-and-conditions-modal--update ')
			modalActions.setHideCloseButtons()
			modalActions.setHeader(headline)
			modalActions.setContent(content)
			modalActions.openModal()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [show, view])

	return { show }
}
