import classNames from 'classnames'
import React, { HTMLAttributes } from 'react'
import { Trans } from 'react-i18next'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'

const SendUserIdNotRegisteredHint: React.FC<HTMLAttributes<HTMLDivElement>> = ({ className }) => {
	const { getMainPath } = useRouteHelper()

	return (
		<div className={classNames(className, 'margin--vertical', ' text-align--center')}>
			<p className="text-color-cyan text--bold-spaced">
				<Trans i18nKey="view.login.sendUserId.notRegisteredHint.headline" />
			</p>
			<p>
				<Trans i18nKey="view.login.sendUserId.notRegisteredHint.bodytext">
					<Button type={[ButtonType.link, ButtonType.text]} route={getMainPath(routesDictionary.register)}>
						{' '}
					</Button>
				</Trans>
			</p>
		</div>
	)
}

export default SendUserIdNotRegisteredHint
