import { Country, parsePhoneNumber } from 'react-phone-number-input/input-max'

export const phoneNumberDefaultCountry = 'DE'

export const supportedPhoneNumberCountries: Country[] = [
	'AF',
	'AL',
	'DZ',
	'AD',
	"CA",
	'AI',
	'AG',
	'AM',
	'AW',
	'AU',
	'AT',
	'AZ',
	'BH',
	'BD',
	'BB',
	'BZ',
	'BM',
	'BT',
	'BO',
	'BA',
	'BW',
	'BN',
	'BG',
	'BF',
	'BL',
	'KH',
	'CM',
	'CV',
	'CF',
	'TD',
	'KM',
	'CK',
	'HR',
	'CY',
	'CZ',
	'CD',
	'DK',
	'DJ',
	'DM',
	'GQ',
	'ER',
	'EE',
	'ET',
	'FO',
	'FJ',
	'FI',
	'FR',
	'GF',
	'PF',
	'GA',
	'GM',
	'GE',
	'DE',
	'GH',
	'GI',
	'GR',
	'GL',
	'GD',
	'GP',
	'GG',
	'GN',
	'GW',
	'GY',
	'HT',
	'HN',
	'HK',
	'IS',
	'ID',
	'IQ',
	'IE',
	'IM',
	'IL',
	'IT',
	'CL',
	'JM',
	'JP',
	'JE',
	'KZ',
	'KE',
	'XK',
	'KG',
	'LA',
	'LV',
	'LB',
	'LS',
	'LY',
	'LI',
	'LT',
	'LU',
	'MO',
	'MK',
	'MG',
	'MW',
	'MV',
	'ML',
	'MT',
	'MQ',
	'MR',
	'MU',
	'YT',
	'MD',
	'MN',
	'ME',
	'MS',
	'MA',
	'MM',
	'NA',
	'NP',
	'NL',
	'NC',
	'NE',
	'NG',
	'NU',
	'NO',
	'OM',
	'PK',
	'PS',
	'PA',
	'PG',
	'PL',
	'PT',
	'RE',
	'RO',
	'RW',
	'WS',
	'SM',
	'ST',
	'SN',
	'RS',
	'SC',
	'SL',
	'SG',
	'SK',
	'SI',
	'SB',
	'SO',
	'SS',
	'ES',
	'SR',
	'SZ',
	'SE',
	'CH',
	'TW',
	'TJ',
	'TZ',
	'TL',
	'TG',
	'TO',
	'TT',
	'TN',
	'TC',
	'TV',
	'UG',
	"US",
	'UA',
	'GB',
	'UZ',
	'VU',
	'VG',
	'YE',
	'ZM',
	'ZW',
]


export const supportedPhoneNumberTypes: string[] = ['MOBILE', "FIXED_LINE_OR_MOBILE"]

export const exception: string = '+493334444'

export const isSupportedPhoneNumberCountry = (phoneNumber: string): boolean => {
	if (!phoneNumber) {
		return false
	}

	const parsedPhoneNumber = parsePhoneNumber(phoneNumber, phoneNumberDefaultCountry)
	if (parsedPhoneNumber?.country) {
		return supportedPhoneNumberCountries.includes(parsedPhoneNumber.country)
	}

	return false
}



export const isSupportedPhoneNumberType = (phoneNumber: string): boolean => {

	if (!phoneNumber) {
		return false
	}

	if (phoneNumber.startsWith(exception)) {
		return true
	}

	const parsedPhoneNumber = parsePhoneNumber(phoneNumber, phoneNumberDefaultCountry)


	const phoneNumberType = parsedPhoneNumber?.getType()


	if (phoneNumberType) {
		return supportedPhoneNumberTypes.includes(phoneNumberType)
	}

	return false
}
