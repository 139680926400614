import { ChallengeType, ChallengeTypeV2, useAuth } from 'hooks/useAuth'
import { useQueryParams, useRoutes } from 'raviger'
import React, { useEffect, useState } from 'react'
import routesDictionary from 'routes'
import { ApiErrorCode } from 'shared/enums'
import isTrue from 'shared/helper/isTrue'
import useGlobalFormState from 'shared/hooks/useGlobalFormState'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import SendUserId from './SendUserId'

const Login: React.FC = () => {
	const [queryParams] = useQueryParams()
	const [, formActions] = useGlobalFormState()
	const { routes, navigateTo, getChildPath, getMainPath } = useRouteHelper(routesDictionary.login)
	const routeResult = useRoutes(routes, { basePath: getMainPath(routesDictionary.login) })
	const auth = useAuth()
	const { challenge, challengeV2 } = auth
	const [view, setView] = useState<'login' | 'register'>(isTrue(queryParams.register) ? 'register' : 'login')

	useEffect(() => {
		switch (view) {
			case 'login':
				if (isTrue(queryParams.register)) {
					setView('register')
					formActions.resetState()
					auth.resetChallenge()
				}
				break
			case 'register':
				if (queryParams.initialPassword) {
					navigateTo(getMainPath(routesDictionary.register), false, {
						skip: 'partially',
						initialPassword: queryParams.initialPassword,
					})
					break
				}
		}
		// eslint-disable-next-line
	}, [queryParams])

	/**
	 * react to v2 challenges
	 */
	useEffect(() => {
		if (!challengeV2) {
			return
		}

		switch (challengeV2.challengeName) {
			case ChallengeTypeV2.paswordRequired:
				return navigateTo(getChildPath(routesDictionary.login, 'passwordLogin'))
			case 'CUSTOM_CHALLENGE':
				return navigateTo(getChildPath(routesDictionary.login, 'enterCode'))
			default:
				return navigateTo(getChildPath(routesDictionary.login, 'sendUserId'), false, {
					...queryParams,
				})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [challengeV2])

	useEffect(() => {
		if (undefined !== challenge && ApiErrorCode.SESSION_TIMEOUT === challenge.lastChallengeError) {
			return navigateTo(getChildPath(routesDictionary.login, 'sendUserId'))
		}

		if (undefined !== challenge && challenge.customChallengeName === challenge.previousChallengeName) {
			// console.log('do not navigate')
			return
		}

		switch (challenge?.customChallengeName) {
			case ChallengeType.sendPassword:
				// console.log('signInMfa')
				return navigateTo(getChildPath(routesDictionary.login, 'passwordLogin'), false, {
					...queryParams,
				})

			case ChallengeType.loginMfa:
				// console.log('signInMfa')
				return navigateTo(getChildPath(routesDictionary.login, 'sendMfa'), false, {
					...queryParams,
				})

			case ChallengeType.password:
				// console.log('navigate sendPassword')
				return navigateTo(getChildPath(routesDictionary.login, 'sendPassword'), false, {
					...queryParams,
					setInitialPassword: isTrue(challenge.initialPasswordSet),
				})

			case ChallengeType.setEmailAddress:
				// console.log('navigate setEmailAddress')
				return navigateTo(getChildPath(routesDictionary.login, 'setEmailAddress'), false, {
					...queryParams,
				})

			case ChallengeType.setNewPassword:
				// console.log('navigate setNewPassword')
				return navigateTo(getChildPath(routesDictionary.login, 'setPassword'), false, {
					...queryParams,
				})

			case ChallengeType.captcha:
				// console.log('navigate captcha')
				return navigateTo(getChildPath(routesDictionary.login, 'sendCaptcha'), false, {
					...queryParams,
				})

			default:
				// console.log('navigate default')

				return navigateTo(getChildPath(routesDictionary.login, 'sendUserId'), false, {
					...queryParams,
				})
		}
		// eslint-disable-next-line
	}, [challenge, view])

	return routeResult || <SendUserId />
}

export default Login
