/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { Auth } from 'aws-amplify'
import classNames from 'classnames'
import { HintsBox } from 'components/HintsBox'
import { IconType } from 'components/Icons'
import Image from 'shared/components/Image'
import TermsAndConditions from 'components/TermsAndConditions'
import { useAuth } from 'hooks/useAuth'
import useAuthApi from 'hooks/useAuthApi'
import { t } from 'i18next'
import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import { trackPromise } from 'react-promise-tracker'
import routesDictionary from 'routes'
import { ButtonType } from 'shared/components/Button'
import Form, { FormFields, FormFieldType } from 'shared/components/Form'
import Icon from 'shared/components/Icon'
import PasswordStrength from 'shared/components/PasswordStrength'
import { TextInputType } from 'shared/components/TextInput'
import Tooltip from 'shared/components/Tooltip'
import numbersOnlyString from 'shared/helper/numbersOnlyString'
import { getSessionStorage, setSessionStorage } from 'shared/helper/sessionStorage'
import { NoNullField } from 'shared/helper/types/noNullField'
import useGlobalModalState from 'shared/hooks/useGlobalModalState'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { RegisterUserArguments, RegisterUserErrors } from 'shared/interfaces'
import { useBackButtonClickHandler } from 'state/useHeaderState'
import {
	supportedPhoneNumberCountries,
	phoneNumberDefaultCountry,
	isSupportedPhoneNumberType,
} from 'supported-phone-number-countries'
import { RegistrationHelpChecklist } from './RegistrationHelpChecklist'
import anschreibenAnschreiben_01Teilnahme from 'assets/images/anschreiben-anschreiben-01-teilnahme.png'
import anschreibenAnschreiben_01Teilnahme_2x from 'assets/images/anschreiben-anschreiben-01-teilnahme@2x.png'
import anschreibenAnschreiben_02Initialpasswort from 'assets/images/anschreiben-anschreiben-02-initialpasswort.png'
import anschreibenAnschreiben_02Initialpasswort_2x from 'assets/images/anschreiben-anschreiben-02-initialpasswort@2x.png'
import { useQueryParams } from 'raviger'
import { parseQueryString } from 'shared/helper/parseQueryString'
import { ButtonWithCooldown } from 'shared/components/ButtonWithCooldown'
import { getMusterInitPasswortUrl, getMusterZfCardUrl } from 'shared/static-assets'
import PrivacyPolicy from 'components/PrivacyPolicy'

type TRegistrationFormFieldValues = NoNullField<RegisterUserArguments> & {
	acceptAgreementCheckbox?: 1
	usageOfContactDataAcceptedCheckbox?: 1
	confirmPassword?: string
}

const BLOCKED_REGISTRATION_EMAIL_DOMAINS = process.env.REACT_APP_BLOCKED_REGISTRATION_EMAIL_DOMAINS
	? String(process.env.REACT_APP_BLOCKED_REGISTRATION_EMAIL_DOMAINS)
			.split(',')
			.map((domain) => `@${domain.trim()}`)
	: undefined

const RegisterV2: React.FunctionComponent<{
	skip?: 'full' | 'partially' | undefined
	initialPassword: string | undefined
}> = () => {
	const [queryParams] = useQueryParams(parseQueryString)

	const { checkUserAuthentication, checkTokenValidity } = useAuth()
	const { registerUser } = useAuthApi()
	const [, modalActions] = useGlobalModalState()
	const { navigateTo, getMainPath } = useRouteHelper()
	const [currentStep, setCurrentStep] = useState<number>(0)
	const filledOutFormInputs = useRef<TRegistrationFormFieldValues>({})
	const [showRegistrationHelp, setShowRegistrationHelp] = useState<boolean>(
		getSessionStorage('registrationHelpChecklistCompleted') !== true
	)

	useEffect(() => {
		if (!queryParams?.skip || currentStep !== 0) {
			return
		}

		switch (queryParams.skip) {
			case 'full':
				setCurrentStep(1)
				break
			case 'partially':
				const initialPassword = queryParams.initialPassword

				if (!initialPassword) {
					break
				}

				filledOutFormInputs.current = { registrationCode: initialPassword }
				setShowRegistrationHelp(false)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParams])

	const getCurrentStep = () => {
		return currentStep > 0 ? currentStep - 1 : 0
	}

	const handleBackClick = (step: number) => {
		setCurrentStep((currentStep) => {
			if (currentStep === 0) {
				setShowRegistrationHelp((currentState: boolean) => {
					if (currentState === true) {
						navigateTo('' + routesDictionary.welcome)
					}

					return true
				})
				return currentStep
			}

			return currentStep - 1
		})
	}

	useBackButtonClickHandler({
		name: handleBackClick,
		parameters: getCurrentStep(),
	})

	const handleRegistrationResponse = ({
		response,
		fieldKeys,
	}: {
		response: Awaited<ReturnType<typeof registerUser>>
		fieldKeys: Partial<keyof RegisterUserArguments>[]
	}) => {
		if (!response) {
			return false
		}

		const returnValue = {
			successful: true,
			errorMessages: [],
		}

		if (response.errors) {
			const errors = response.errors

			fieldKeys.forEach((key) => {
				if (key in errors) {
					const fieldError = errors[key as Extract<keyof RegisterUserArguments, keyof RegisterUserErrors>]

					if (fieldError) {
						returnValue.successful = false
						returnValue.errorMessages.push(t(`generic.form.errors.${key}.${fieldError}`))
					}
				}
			})
		}

		return returnValue
	}

	const handleOnSubmit = async (submittedFields: TRegistrationFormFieldValues) => {
		const mergedSubmittedFields = {
			...filledOutFormInputs.current,
			...submittedFields,
		}

		filledOutFormInputs.current = mergedSubmittedFields

		const {
			email,
			emailTan,
			password,
			phoneNr,
			phoneNrTan,
			registrationCode,
			acceptAgreementCheckbox,
			usageOfContactDataAcceptedCheckbox,
		} = mergedSubmittedFields

		const mappedRegistrationFields: RegisterUserArguments = {
			email,
			emailTan,
			password,
			phoneNr,
			phoneNrTan,
			registrationCode,
			acceptAgreement: acceptAgreementCheckbox === 1,
			usageOfContactDataAccepted: usageOfContactDataAcceptedCheckbox === 1,
		}

		console.log('MAPPED FIELDS', mappedRegistrationFields, 'MERGED FIELDS', mergedSubmittedFields)

		const response = await trackPromise(registerUser(mappedRegistrationFields), 'register')
		const fieldKeysToValidate = Object.keys(submittedFields).filter(
			(fieldKey) =>
				submittedFields[fieldKey as keyof RegisterUserArguments] !== undefined &&
				Object.keys(mappedRegistrationFields).includes(fieldKey)
		) as Partial<keyof RegisterUserArguments>[]

		return handleRegistrationResponse({ response, fieldKeys: fieldKeysToValidate })
	}

	const handleOnSuccess = async () => {
		if (registrationSteps.length - 1 === currentStep) {
			setSessionStorage('showDigitalDocumentsOnlyHint', true)

			modalActions.setClass('modal--success')
			modalActions.setContent(
				<div className="padding">
					<h1 className="text-align--center no-margin">
						<Trans i18nKey="view.registerV2.successModal.headline" />
					</h1>
					<Icon
						type={IconType.success}
						color={'var(--color-cyan)'}
						className="center-view margin--vertical "
					></Icon>
				</div>
			)

			modalActions.openModal()
			modalActions.setButtonLabel(t('generic.done'))
			modalActions.setOnCloseAction(() => {
				checkUserAuthentication(true, () => navigateTo(getMainPath(routesDictionary.dashboard || '')))
			})

			return
		}

		checkTokenValidity()
		setCurrentStep((step) => step + 1)
	}

	// const onErrorHandle = async (error: AuthError) => {
	// 	// console.log({ error })
	// }

	const currentRegistrationStep = registrationSteps[currentStep]
	const CurrentRegistrationStepComponent = currentRegistrationStep.component

	return (
		<div className="view-center center-view">
			{showRegistrationHelp === true ? (
				<div className="max-content-width  flex flex--direction-column gap">
					<h1>
						<Trans i18nKey="component.registrationHelpChecklist.headline" />
					</h1>

					<p>
						<Trans i18nKey="component.registrationHelpChecklist.bodyText" />
					</p>

					<div className="flex flex--wrap flex--align-self-start drop-shadow--bottom-3-steps margin--vertical ">
						<Image
							className="margin--left margin--small-negative"
							srcSet={`${anschreibenAnschreiben_01Teilnahme} 1x, ${anschreibenAnschreiben_01Teilnahme_2x} 2x`}
							width="101"
							height="141"
							alt=""
							href={getMusterZfCardUrl()}
						/>
						<Image
							className="margin--left"
							srcSet={`${anschreibenAnschreiben_02Initialpasswort} 1x, ${anschreibenAnschreiben_02Initialpasswort_2x} 2x`}
							width="101"
							height="141"
							alt=""
							href={getMusterInitPasswortUrl()}
						/>
					</div>

					<p>
						<b>
							<Trans i18nKey="component.registrationHelpChecklist.checkList.headline" />
						</b>
					</p>

					<RegistrationHelpChecklist
						onSuccess={() => setShowRegistrationHelp(false)}
					></RegistrationHelpChecklist>
				</div>
			) : (
				<CurrentRegistrationStepComponent
					formInputValues={filledOutFormInputs.current}
					handleOnSubmit={handleOnSubmit}
					handleOnSuccess={handleOnSuccess}
				/>
			)}
		</div>
	)
}

type TRegistrationStep = {
	formInputValues: TRegistrationFormFieldValues
	handleOnSubmit: (
		submittedFields: TRegistrationFormFieldValues
	) => Promise<boolean | { abort?: boolean; successful?: boolean; errorMessages?: string[] }>
	handleOnSuccess: () => void
}

const EnterRegistrationCode: FunctionComponent<TRegistrationStep> = (props) => {
	const { formInputValues = {}, handleOnSubmit, handleOnSuccess } = props
	const { userData, initializeAmplify, getUserPoolId } = useAuth()
	// const [userName, setUserName] = useState<string>()
	const username = useMemo(() => {
		return userData?.['cognito:username'].toUpperCase()
	}, [userData])
	// useForcedMigrationModal(formInputValues.registrationCode === undefined)

	const handleLogin = async (submittedFields: Record<string, string>) => {
		const { username: id, registrationCode: password } = submittedFields

		const { userPoolId } = await getUserPoolId(id, true)

		await initializeAmplify(userPoolId)
		try {
			const userSession = await Auth.signIn({ username: id, password })

			if (userSession.signInUserSession) {
				return handleOnSubmit(submittedFields)
			}
		} catch (e: any) {
			return { successful: false, errorMessages: [t(`apiErrors.RegisterNotAuthorizedException`)] }
		}
	}

	const inputElements: FormFields = {
		username: {
			required: true,
			label: t('generic.form.fields.userId.label'),
			fieldType: FormFieldType.text,
			className: 'margin--bottom',
			// icon: IconType.profile,
		},
		registrationCode: {
			required: true,
			value: formInputValues.registrationCode,
			type: TextInputType.text,
			label: t('generic.form.fields.registrationCode.label'),
			fieldType: FormFieldType.text,
			className: 'margin--bottom',
			append: (
				<Tooltip
					label={t('view.registerV2.steps.enterRegistrationCode.link2')}
					headline={
						<h2 className="no-margin">
							<Trans i18nKey={'view.login.initialPasswordHelp.headline'}></Trans>
						</h2>
					}
					content={
						<div className="padding--bottom padding--horizontal">
							<Trans i18nKey="view.login.initialPasswordHelp.content"></Trans>
						</div>
					}
				/>
			),
			// icon: IconType.lock,
		},
	}

	return (
		<>
			<h1 className="medium-content-width margin margin--large no-margin--right">
				<Trans i18nKey="view.registerV2.steps.enterRegistrationCode.headline" />
			</h1>

			<p>
				<Trans i18nKey="view.registerV2.steps.enterRegistrationCode.text" />
			</p>
			<p>
				<Tooltip
					label={t('view.registerV2.steps.enterRegistrationCode.link')}
					headline={
						<h2 className="no-margin">
							<Trans i18nKey={'view.login.letterHelp.header'}></Trans>
						</h2>
					}
					content={
						<div className="padding--bottom padding--horizontal">
							<Trans
								i18nKey="generic.helpHotline.content"
								values={{
									emailAddress: t('generic.helpHotline.mail'),
									phoneNumber: t('generic.helpHotline.tel'),
								}}
							>
								<a href={t('generic.helpHotline.telLink')}>{'  '}</a>
								<a href={t('generic.helpHotline.mailLink')}>{'  '}</a>
							</Trans>
						</div>
					}
				/>
				{/* <Button
					type={ButtonType.link}
					label={<Trans i18nKey="view.registerV2.steps.enterRegistrationCode.link" />}
				></Button> */}
			</p>
			<div className="max-content-width  flex--m gap gap--extra-large flex--grow">
				<Form
					fields={inputElements}
					onSubmit={(submittedFields: Record<string, string>) =>
						trackPromise(handleLogin(submittedFields), 'register')
					}
					onSuccess={handleOnSuccess}
					updatedValues={username ? { username } : {}}
					submitLabel={t('generic.next')}
					alwaysAllowSubmit
					promiseTracker={{ area: 'register' }}
				></Form>
			</div>
		</>
	)
}

const SetPassword: FunctionComponent<TRegistrationStep> = (props) => {
	const { formInputValues = {}, handleOnSuccess } = props
	const [password, setPassword] = useState<string | undefined>(formInputValues.password)
	const [passwordIsValid, setPasswordIsValid] = useState<boolean>(false)

	const inputElements: FormFields = {
		password: {
			value: formInputValues.password,
			fieldType: FormFieldType.text,
			type: TextInputType.password,
			label: t('generic.form.fields.password.label'),
			required: true,
			autoComplete: 'new-password',
			onChange: (e: any) => {
				setPassword(e.target.value)
			},
			// icon: IconType.lock,
		},
		confirmPassword: {
			value: formInputValues.confirmPassword,
			fieldType: FormFieldType.text,
			type: TextInputType.password,
			label: t('generic.form.fields.confirmPassword.label'),
			required: true,
			// icon: IconType.lock,
		},
	}

	const handleOnSubmit = async (submittedFields: Record<string, string>) => {
		const values = [submittedFields.password, submittedFields.confirmPassword]
			.filter((v) => v)
			.map((v) => (typeof v === 'string' ? v.trim().replace(' ', '') : null))

		if (values.length !== 2) {
			return false
		}

		if (values[0] !== values[1]) {
			return {
				errorMessages: [t(`generic.form.errors.password.DO_NOT_MATCH`)],
				successful: false,
			}
		}

		if (passwordIsValid === false) {
			return {
				errorMessages: [t(`generic.form.errors.password.INVALID`)],
				successful: false,
			}
		}

		return props.handleOnSubmit(submittedFields)
	}

	return (
		<>
			<h1 className="margin margin--large no-margin--right">
				<Trans i18nKey="view.registerV2.steps.setPassword.headline" />
			</h1>
			<div className="max-content-width flex--m flex--grow gap gap--extra-large form-view">
				<Form
					fields={inputElements}
					onSubmit={handleOnSubmit}
					onSuccess={handleOnSuccess}
					submitLabel={t('generic.next')}
					alwaysAllowSubmit
					promiseTracker={{ area: 'register' }}
				>
					<div className="form-view__bodytext">
						<PasswordStrength password={password} onIsPasswordValid={setPasswordIsValid}>
							<p className="margin--top">
								<b>
									<Trans i18nKey="component.passwordStrength.hint" />
								</b>
							</p>
						</PasswordStrength>
					</div>
				</Form>

				{/* <HintsBox>
			
				</HintsBox>  */}
			</div>
		</>
	)
}

const SetEmailAddress: FunctionComponent<TRegistrationStep> = (props) => {
	const { formInputValues = {}, handleOnSuccess } = props
	const [showTanInput, setShowTanInput] = useState(formInputValues.emailTan !== undefined)
	const [emailTan, setEmailTan] = useState<string | undefined>(formInputValues.emailTan)
	const [email, setEmail] = useState<string | undefined>(formInputValues.email)

	const handleOnEmailChange = useCallback(() => {
		if (showTanInput === false) {
			return
		}

		setEmail(undefined)
		setEmailTan(undefined)
		setShowTanInput(false)
	}, [showTanInput])

	const handleOnSubmit = async (submittedFields: Record<string, string>) => {
		if (submittedFields.emailTan === undefined) {
			setEmailTan('')

			if (BLOCKED_REGISTRATION_EMAIL_DOMAINS?.some((domain) => submittedFields.email.endsWith(domain))) {
				return {
					errorMessages: [t(`generic.form.errors.email.NOT_PRIVATE`)],
					successful: false,
				}
			}

			const registrationStatus = await props.handleOnSubmit({ email: submittedFields.email, emailTan: undefined })

			if (typeof registrationStatus === 'object' && registrationStatus.successful === false) {
				return registrationStatus
			}

			setEmail(submittedFields.email)
			setShowTanInput(true)

			return { abort: true }
		}

		return props.handleOnSubmit(submittedFields)
	}

	const handleOnResendTan = () => {
		if (!email) {
			return
		}

		handleOnSubmit({ email })
	}

	const inputElements: FormFields = {
		email: {
			value: formInputValues.email,
			required: true,
			type: TextInputType.email,
			label: t('generic.form.fields.email.label'),
			autoComplete: 'username email',
			fieldType: FormFieldType.text,
			onChange: handleOnEmailChange,
			// icon: IconType.email,
		},
		emailTan: {
			value: emailTan,
			required: true,
			inputMode: 'decimal',
			pattern: '[0-9,.]*',
			label: t('generic.form.fields.mfa.label'),
			autoComplete: 'one-time-code',
			fieldType: FormFieldType.text,
			valueFunction: {
				name: numbersOnlyString,
			},
			className: classNames({
				'visually-hidden': showTanInput !== true,
				'visually-hidden--false': showTanInput === true,
			}),
			disabled: showTanInput !== true,
			// icon: IconType.chat,
		},
	}

	return (
		<div className="max-content-width">
			<div className="max-content-width flex--m flex--grow">
				<h1 className="medium-content-width margin margin--large no-margin--right">
					<Trans i18nKey="view.registerV2.steps.setEmailAddress.headline" />
				</h1>
			</div>

			<div className="max-content-width flex--m flex--grow gap gap--extra-large form-view">
				<Form
					fields={inputElements}
					onSubmit={handleOnSubmit}
					onSuccess={handleOnSuccess}
					submitLabel={t('generic.next')}
					alwaysAllowSubmit
					promiseTracker={{ area: 'register' }}
					updatedValues={{ emailTan }}
				>
					<div className="form-view__bodytext margin--auto">
						{' '}
						{showTanInput && (
							<HintsBox style={{ order: '1' }}>
								<Trans
									i18nKey="generic.form.fields.emailTan.hint"
									values={{ email }}
									components={{
										resendCode: (
											<ButtonWithCooldown
												className="margin--vertical"
												onClick={handleOnResendTan}
												type={[ButtonType.small, ButtonType.secondary]}
											/>
										),
									}}
								/>
							</HintsBox>
						)}
					</div>
				</Form>
			</div>
		</div>
	)
}

const SetPhoneNumber: FunctionComponent<TRegistrationStep> = (props) => {
	const { formInputValues = {}, handleOnSuccess } = props
	const [showTanInput, setShowTanInput] = useState(formInputValues.phoneNrTan !== undefined)
	const [phoneNrTan, setPhoneNrTan] = useState<string | undefined>(formInputValues.phoneNrTan)
	const [phoneNr, setPhoneNr] = useState<string | undefined>(formInputValues.phoneNr)

	const handleOnPhoneNumberChange = useCallback(() => {
		if (showTanInput === false) {
			return
		}

		setPhoneNr(undefined)
		setPhoneNrTan(undefined)
		setShowTanInput(false)
	}, [showTanInput])

	const handleOnSubmit = async (submittedFields: Record<string, string>) => {
		if (isSupportedPhoneNumberType(submittedFields.phoneNr) === false) {
			return { successful: false, errorMessages: [t(`generic.form.errors.phoneNr.NOT_SUPPORTED_TYPE`)] }
		}

		if (submittedFields.phoneNrTan === undefined) {
			const registrationStatus = await props.handleOnSubmit({
				phoneNr: submittedFields.phoneNr,
				phoneNrTan: undefined,
			})

			if (typeof registrationStatus === 'object' && registrationStatus.successful === false) {
				return registrationStatus
			}

			setPhoneNr(submittedFields.phoneNr)
			setShowTanInput(true)

			return { abort: true }
		}

		return props.handleOnSubmit(submittedFields)
	}

	const handleOnResendTan = () => {
		if (!phoneNr) {
			return
		}

		handleOnSubmit({ phoneNr })
	}

	const inputElements: FormFields = {
		phoneNr: {
			value: formInputValues.phoneNr,
			required: true,
			label: t('generic.form.fields.phoneNr.label'),
			autoComplete: 'tel',
			fieldType: FormFieldType.phoneInput,
			onChange: handleOnPhoneNumberChange,
			phoneInputProps: {
				countries: supportedPhoneNumberCountries,
				defaultCountry: phoneNumberDefaultCountry,
				addInternationalOption: false,
				international: true,
			},
			// icon: IconType.phone,
		},
		phoneNrTan: {
			required: true,
			inputMode: 'decimal',
			pattern: '[0-9,.]*',
			label: t('generic.form.fields.mfa.label'),
			autoComplete: 'one-time-code',
			fieldType: FormFieldType.text,
			valueFunction: {
				name: numbersOnlyString,
			},
			className: classNames({
				'visually-hidden': showTanInput !== true,
				'visually-hidden--false': showTanInput === true,
			}),
			disabled: showTanInput !== true,
		},
	}

	return (
		<div className="max-content-width">
			<div className="max-content-width flex--m flex--grow">
				<h1 className="medium-content-width margin margin--large no-margin--right">
					<Trans i18nKey="view.registerV2.steps.setPhoneNumber.headline" />
				</h1>
			</div>

			<div className="max-content-width flex--m flex--grow gap gap--extra-large form-view ">
				<Form
					fields={inputElements}
					onSubmit={handleOnSubmit}
					onSuccess={handleOnSuccess}
					submitLabel={t('generic.next')}
					updatedValues={{ phoneNrTan }}
					alwaysAllowSubmit
					promiseTracker={{ area: 'register' }}
				>
					<div className="form-view__bodytext">
						{showTanInput === false && (
							<div className="margin--vertical">
								<HintsBox>
									<p className="flex flex--align-items-start ">
										<Trans i18nKey="generic.form.fields.phoneNr.disclaimer" />
									</p>
								</HintsBox>
							</div>
						)}

						{showTanInput && (
							<HintsBox style={{ order: '1' }}>
								<Trans
									i18nKey="generic.form.fields.phoneNrTan.hint"
									values={{
										phoneNr: '****' + phoneNr?.slice(-4),
									}}
									components={{
										resendCode: (
											<ButtonWithCooldown
												className="margin--vertical"
												onClick={handleOnResendTan}
												type={[ButtonType.secondary, ButtonType.small]}
											/>
										),
									}}
								/>
							</HintsBox>
						)}
					</div>
				</Form>
			</div>
		</div>
	)
}

const AcceptAgreement: FunctionComponent<TRegistrationStep> = (props) => {
	const { formInputValues = {}, handleOnSubmit, handleOnSuccess } = props

	const [, modalActions] = useGlobalModalState()

	const showDataProtection = () => {
		modalActions.setContent(
			<div className="padding">
				<PrivacyPolicy></PrivacyPolicy>
			</div>
		)
		modalActions.openModal()
	}

	const inputElements: FormFields = {
		acceptAgreementCheckbox: {
			checked: formInputValues.acceptAgreementCheckbox === 1,
			label: t('view.registerV2.form.fields.acceptAgreement.label'),
			fieldType: FormFieldType.checkBox,
			required: true,
			value: 1,
		},
		usageOfContactDataAcceptedCheckbox: {
			checked: formInputValues.usageOfContactDataAcceptedCheckbox === 1,
			label: t('view.registerV2.form.fields.usageOfContactDataAccepted.label'),
			fieldType: FormFieldType.checkBox,
			prepend: (
				<>
					<hr className="hr margin--bottom" />
					<p className="margin--bottom">
						<Trans i18nKey="view.registerV2.steps.acceptAgreement.bodyText2">
							<a
								onClick={showDataProtection}
								className="underline cursor--pointer link"
								// download={true}
								// href={'/Pension_App_DSE.pdf'}
							></a>
						</Trans>
					</p>
				</>
			),
			value: 1,
		},
	}

	return (
		<div className="medium-content-width margin margin--large no-margin--right  flex flex--direction-column gap">
			<h1 className="no-margin--top">
				<Trans i18nKey="view.registerV2.steps.acceptAgreement.headline" />
			</h1>

			<p>
				<Trans i18nKey="view.registerV2.steps.acceptAgreement.bodyText" />
			</p>
			<Tooltip
				className="margin--vertical margin--default"
				modalClassName="modal--size-large"
				label={t('view.registerV2.steps.acceptAgreement.modalLinkText')}
				headline={t('view.registerV2.steps.acceptAgreement.modal.header')}
				content={
					<div className="padding--horizontal">
						<TermsAndConditions />
					</div>
				}
			/>

			<Form
				fields={inputElements}
				onSubmit={handleOnSubmit}
				onSuccess={handleOnSuccess}
				submitLabel={t('view.registerV2.form.submit')}
				alwaysAllowSubmit
				promiseTracker={{ area: 'register' }}
			/>
		</div>
	)
}

const registrationSteps = [
	{ name: 'enterRegistrationCode', component: EnterRegistrationCode },
	{ name: 'setPassword', component: SetPassword },
	{ name: 'setPhoneNumber', component: SetPhoneNumber },
	{ name: 'setEmailAddress', component: SetEmailAddress },
	{ name: 'acceptAgreement', component: AcceptAgreement },
]

export default RegisterV2
