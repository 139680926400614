import { Challenge, useAuth } from 'hooks/useAuth'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Form, { FormFields, FormFieldType } from 'shared/components/Form'
import { TextInputType } from 'shared/components/TextInput'
import { ApiErrorCode } from 'shared/enums'
import isDev from 'shared/helper/isDev'
import { FormSubmitFields } from 'shared/hooks/useForm'
import { useResetHeaderState } from 'state/useHeaderState'

const SetEmailAddress = () => {
	useResetHeaderState()

	const { t } = useTranslation()
	const auth = useAuth()
	const [errorMessages, setErrorMessages] = useState<string[]>()

	const inputElements: FormFields = {
		email: {
			value: isDev() ? (process.env.REACT_APP_USER_EMAIL as string) : '',
			required: true,
			type: TextInputType.email,
			label: t('view.register.formFieldLabels.privateEmailAddress'),
			fieldType: FormFieldType.text,
		},
		confirmEmail: {
			value: isDev() ? (process.env.REACT_APP_USER_EMAIL as string) : '',
			required: true,
			type: TextInputType.email,
			label: t('view.register.formFieldLabels.confirmPrivateEmailAddress'),
			fieldType: FormFieldType.text,
		},
	}

	return (
		<div className="form-view no-auto-hyphens">
			<div className="form-view__title">
				<h1>
					<Trans i18nKey="view.register.setEmailAddress.pageTitle" />
				</h1>
			</div>

			<div className="form-view__bodytext margin--vertical">
				<p>
					<Trans i18nKey="view.register.setEmailAddress.bodytext" />
				</p>
			</div>

			<Form
				className="form-view"
				fields={inputElements}
				onSubmit={(submittedFields: FormSubmitFields) => {
					if (submittedFields.email !== submittedFields.confirmEmail) {
						return {
							lastChallengeError: ApiErrorCode.EMAIL_ADDRESSES_DO_NOT_MATCH,
							successful: false,
						}
					}

					return auth.sendChallengeAnswer(submittedFields)
				}}
				onError={(errorResponse: Challenge) => {
					setErrorMessages([
						t(`apiErrors.${errorResponse.lastChallengeError}`, { error: errorResponse.lastChallengeError }),
					])
				}}
				errorMessages={errorMessages}
				submitLabel={t('view.register.buttonLabel.setEmailAddress')}
			/>
		</div>
	)
}

export default SetEmailAddress
