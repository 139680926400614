import i18next from 'i18next'
import React, { FunctionComponent } from 'react'
import Button, { ButtonType } from 'shared/components/Button'

const TermsAndConditions: FunctionComponent<{ showChanges?: boolean }> = ({ showChanges }) => {
	const handleDownload = () => {
		const link = document.createElement('a')
		link.target = '_blank'
		link.href = '/Nutzungsbedingungen_2023-03-27.pdf'
		link.click()
	}
	if (showChanges) {
		return (
			<div className="text-align--left">
				{i18next.language.startsWith('de') ? (
					<>
						<h3>Geänderte Nutzungsbedingungen</h3>
						<p>
							Wir entwickeln die ZF Vorsorge App ständig weiter. Einige neue Funktionen bedingen
							Änderungen in unseren Nutzungsbedingungen. Bitte akzeptieren Sie die geänderten
							Nutzungsbedingungen, um die App weiter nutzen zu können.
						</p>
						<p className="margin--top margin--bottom">
							<b>Kompakt zusammengefasst – das ändert sich:</b>
						</p>
						<ul>
							<li>
								Wir führen ein neues Login-Verfahren ein, über welches Sie die TAN per SMS zugeschickt
								bekommen. Der Login-Prozess wird damit schneller und bequemer, da Sie außer dem Passwort
								keine weitere ID-PIN merken müssen.
							</li>

							<li>
								Wir haben einige Änderungen vorgenommen, um die Nutzungsbedingungen an geänderte
								rechtliche Rahmenbedingungen anzupassen (insb. durch Aufnahme von Informationen über Ihr
								Widerrufsrecht, Anpassungen zu Gewährleistung und Haftung und Anpassungen der Regelungen
								zu zukünftigen Änderungen an der App sowie der Nutzungsbedingungen).
							</li>
						</ul>
					</>
				) : (
					<>
						<>
							<h3>Modified terms of use</h3>
							<p>
								We are constantly developing the ZF Vorsorge App. Some new functions require changes in
								our our terms of use. Please accept the changed terms of use in order to continue using
								the app.
							</p>
							<p className="margin--top margin--bottom">
								<b>Summarized compactly - that's what's changing:</b>
							</p>
							<ul>
								<li>
									We are introducing a new login procedure, via which you will receive the TAN by SMS.
									This will make the login process faster and more convenient, as you will not have to
									remember any other ID PIN apart from the password.
								</li>

								<li>
									We have made some changes in order to adapt the Terms of Use to changed legal legal
									framework (in particular by including information about your right of withdrawal,
									adjustments to warranty and liability, and adjustments to the provisions regarding
									future changes to the app and the terms of use).
								</li>
							</ul>
						</>
					</>
				)}
			</div>
		)
	}

	return (
		<div className="text-align--left">
			{i18next.language.startsWith('de') ? (
				<>
					<div className="whole-terms-and-conditions text-align--left">
						<Button
							type={ButtonType.link}
							label={'Nutzungsbedingungen herunterladen'}
							onClick={handleDownload}
						/>
						<h1>Nutzungsbedingungen ZF Vorsorge App </h1>
						<p>Stand: 27. März 2023</p>
						<h2>Präambel</h2>
						<p>
							Diese Nutzungsbedingungen regeln das Nutzungsverhältnis zwischen der ZF Friedrichshafen AG,
							Löwentaler Straße 20, 88046 Friedrichshafen („<b>ZF</b>“,„<b>wir</b>“ oder „<b>uns</b>“) und
							denjenigen Mitarbeitern der ZF-Unternehmensgruppe, die die von ZF angebotene Progressive Web
							App zur betrieblichen Altersversorgung („<b>App</b>“) nutzen („<b>Nutzer</b>“). Die App
							wurde für ZF entwickelt und wird für ZF betrieben durch einen weisungsgebundenen
							Dienstleister, Decadia GmbH. Die App ist als Webanwendung von einem Computer mit einem
							Webbrowser und auch als App mit einem mobilen Endgerät (Smartphone oder Tablet) nutzbar. Sie
							dient als Informations- und Interaktionstool zur Durchführung der betrieblichen
							Altersversorgung (im Speziellen: VO 2019, „<b>ZF Vorsorge</b>“).{' '}
						</p>
						<p>
							Der über die App angebotene Dienst richtet sich ausschließlich an Mitarbeiter der
							Gesellschaften der ZF-Unternehmensgruppe, die sich zum erstmaligen Nutzungszeitpunkt in
							einem Beschäftigungsverhältnis mit der ZF Friedrichshafen AG befinden oder befanden und
							berechtigt sind oder waren, an der ZF Vorsorge teilzunehmen. Zu der ZF-Unternehmensgruppe im
							vorstehenden Sinne gehören neben der ZF Friedrichshafen AG die mit der ZF Friedrichshafen AG
							im Sinne des § 15 AktG verbundenen Unternehmen und die Unternehmen, an denen ZF
							Friedrichshafen AG zu mindestens 50% beteiligt ist. ZF gewährt bestimmten Beschäftigten die
							Möglichkeit, im Rahmen der ZF Vorsorge Entgeltumwandlung zur betrieblichen Altersvorsorge zu
							leisten. Bei Erfüllung von gewissen Voraussetzungen leistet ZF zusätzlich einen Beitrag
							(Arbeitgebergrundbeitrag und ggf. Matching-Beitrag). Alle arbeitnehmer- und
							arbeitgeberfinanzierten Beiträge werden im ZF Pension Trust e.V. in Spezialfonds investiert
							und erzielen am Kapitalmarkt eine Rendite. ZF stellt jenen Beschäftigten die App als
							interaktives Informationstool zur Verfügung, um Anträge zur Entgeltumwandlung zu stellen und
							die individuelle Wertentwicklung der Entgeltumwandlung im Überblick zu halten. Dazu gehört
							etwa die Darstellung und Anzeige der monatlichen sowie jährlichen individuellen Wandlungen,
							Neuigkeiten bezüglich des Depot-Werts, des persönlichen Versorgungsguthabens, der Abschluss
							sowie die Anpassungen der individuellen Entgeltumwandlungsvereinbarungen mittels eines
							Wandlungswunsches, zur Auswahl von nach der Versorgung vorgesehenen Auszahlungsmodalitäten,
							die Anzeige von Dokumenten (bspw. Wandlungswunschbestätigungen), des Kursverlaufs bzw. der
							Performance des Spezialfonds, der Fonds-Informationen sowie generelle Informationen zur ZF
							Vorsorge. Gleichzeitig kann ein berechtigter Beschäftigter, der bislang keine
							Entgeltumwandlungen im Rahmen der ZF Vorsorge durchgeführt hat, die App nutzen. Dabei kann
							die App lediglich als Informationsquelle oder aber zur direkten Entgeltumwandlung genutzt
							werden.
						</p>
						<h2>1. Geltungsbereich / Nutzungsverhältnis</h2>{' '}
						<ol>
							<li>
								Diese Nutzungsbedingungen bilden die rechtliche Grundlage für die Nutzung der App durch
								den Nutzer.
							</li>
							<li>
								Diese Nutzungsbedingungen regeln nicht die Frage, ob eine Pflicht zur Nutzung der App
								oder eine Teilnahmeberechtigung an der ZF Vorsorge besteht, sondern lediglich
								Modalitäten der Verwendung der App.
							</li>
							<li>
								Neben diesen Nutzungsbedingungen gelten sämtliche betrieblichen Regelungen und
								Konzernrichtlinien der ZF einschließlich der jeweiligen spezifischen standortabhängigen
								betrieblichen Regelungen sowie der jeweiligen arbeitsvertraglichen Vereinbarungen
								zwischen ZF und den Nutzern. Die Voraussetzungen und Regelungen zur Teilnahme an der
								Entgeltumwandlung zur Altersvorsorge sowie die individuelle Umwandlungsvereinbarung mit
								dem Nutzer sind nicht Gegenstand der vorliegenden Nutzungsbedingungen. Weitere
								Informationen zur ZF Vorsorge und zur Geldanlage im ZF Pension Trust sowie den ZF
								Pension Trust e.V. sind über ZF erhältlich.
							</li>
							<li>
								Entgegenstehende oder von diesen Nutzungsbedingungen abweichende Bedingungen des Nutzers
								werden nicht anerkannt, es sei denn, ZF hat deren Geltung ausdrücklich zugestimmt. Dies
								gilt auch, wenn ZF in Kenntnis entgegenstehender oder von diesen Nutzungsbedingungen
								abweichender Bedingungen des Nutzers ihre Leistungen vorbehaltlos ausführt.
							</li>
							<li>
								Für die Nutzung der App ist die Durchführung des digitalen Teilnahmeverfahrens
								erforderlich, um die Zugangsdaten zur App zu erhalten. Diese Nutzungsbedingungen gelten
								nicht für die Durchführung des digitalen Teilnahmeverfahrens.{' '}
							</li>

							<li>
								Für die Nutzung der App ist sowohl für deren Aktivierung als auch für die jeweilige
								Anmeldung zudem eine Zwei-Faktor-Authentifizierung mittels SMS-TAN-Verfahren
								erforderlich.
							</li>
							<li>
								Wir speichern diesen Vertragstext nach Vertragsschluss nicht. Die aktuell gültigen
								Nutzungsbedingungen sind innerhalb der Web-App unter dem Reiter „Profil“ verfügbar,
								sodass der Nutzer sie einsehen sowie auf sein Endgerät herunterladen und dort speichern
								kann.
							</li>
						</ol>{' '}
						<h2>2. Leistungsumfang der App </h2>
						<ol>
							<li>
								Die App dient der informativen Darstellung eines Einzelkontos des jeweiligen Nutzers, um
								ihm einen Überblick über die Wertentwicklung und den individuellen Kontostand seiner
								Entgeltwandlungen für die betriebliche Altersvorsorge zu gewähren. Im interaktiven Teil
								der App hat der Nutzer die Möglichkeit, seine Entgeltumwandlung zu beantragen,
								anzupassen und/oder zu beenden.
							</li>

							<li>
								Im Einzelkonto werden folgende Informationen dem Nutzer zur Verfügung gestellt:
								<ul>
									<li>
										Stammdaten/App-Profil: Titel, Vor- und Nachname, Zusatzwort, Vorsatzwort,
										Anschrift, Benutzername (private E-Mail-Adresse & ZF Vorsorge-ID)
									</li>

									<li>
										Informationen aus dem HR-System der ZF: Gesellschaftseintritt,
										Beschäftigungsgrad, Bemessungsgrundlage
									</li>

									<li>
										Möglichkeit zur Entgeltumwandlung mittels eines Wandlungswunsches aus dem
										laufenden monatlichen Gehalt sowie aus Einmalzahlungen in Prozent- oder
										Betragsangaben
									</li>

									<li>
										Darstellung und Anzeige einer Historie der monatlichen sowie jährlichen
										individuellen Wandlungen, mit Ausweisung der Arbeitgebergrundbeiträge und
										Matching-Beiträge
									</li>

									<li>
										Informationen zum Kontostand (monatliche/jährliche Wandlungen im Verlauf, Anzahl
										der bisherigen Wandlungen unter Einbeziehung der Wandlungen in vergangenen
										Jahren)
									</li>

									<li>
										Möglichkeit der Auswahl von zum Renteneintritt vorgesehenen
										Auszahlungsmodalitäten
									</li>

									<li>
										Neuigkeiten bezüglich des Depot-Werts seit dem letzten Öffnen der App (Zugewinn
										/ Verlust durch Kursentwicklung);
									</li>

									<li>
										Informationen zum Kursverlauf (Performance / Kursverlauf der Spezialfonds,
										Verlauf von Vergleichsindizes)
									</li>

									<li>
										Informationen zu den Spezialfonds (Zusammenfassung des Fonds und ggf. weitere
										Informationen)
									</li>

									<li>
										Anzeige der erstellten Dokumente innerhalb der App (bspw.
										Wandlungswunschbestätigungen)
									</li>

									<li>allgemeine Informationen zur ZF Vorsorge mittels FAQs</li>
								</ul>
							</li>

							<li>
								ZF steht nicht über die gesetzlich vorgesehene Haftung hinaus dafür ein, dass die über
								die App bereitgestellten Informationen aktuell, richtig oder vollständig sind. Der
								Nutzer wird ausdrücklich in der App darauf hingewiesen, dass die über die App
								dargestellten Angaben durch aktuelle Entwicklungen (etwa Kursschwankungen, Änderungen im
								Kursverlauf) überholt sein können, ohne dass die Informationen, die in der App angezeigt
								werden, daran angepasst worden sind. Im Allgemeinen werden die Kurswerte mit einer
								zeitlichen Verzögerung von ca. 1 Woche angezeigt (ohne Einrechnung des aktuellen
								Handelstages), da der Fonds nicht öffentlich gelistet ist und die Kurswerte- Er- und
								Übermittlung erst nach dem entsprechenden Handelstag erfolgen kann.
							</li>

							<li>
								Die Übermittlung und Anzeige der Informationen in der App stellen weder eine
								Anlageberatung noch eine Anlagevermittlung oder sonstige Finanzdienstleistung gemäß KWG,
								WPHG und Finanzanlagenvermittlung gemäß GewO dar, noch sind diese mit einer
								Anlageberatung, Anlagevermittlung oder Finanzdienstleistung verbunden.
							</li>

							<li>
								Aus der Kursentwicklung der Vergangenheit können keine Rückschlüsse für aktuelle oder
								zukünftige Kursentwicklungen gezogen werden.
							</li>

							<li>Für die Nutzung der App entstehen den Nutzern keine Kosten.</li>
						</ol>
						<h2>3. Änderungen der App</h2>
						<ol>
							<li>
								Über das für die Aufrechterhaltung der Vertragsmäßigkeit der App erforderliche Maß
								hinausgehend kann ZF die App und deren Funktionsumfang ändern und anpassen, wenn ein
								triftiger Grund diese Änderung erfordert. Ein solcher liegt insbesondere vor bei
								Änderungen (i) zur Umsetzung geänderter gesetzlicher Anforderungen oder Rechtsprechung,
								(ii) zur Umsetzung gerichtlicher oder behördlicher Anordnungen, (iii) zur Umsetzung
								geänderter technischer Anforderungen wie bspw. einer neuen technischen Umgebung oder
								anderer betriebstechnischer Gründe, (iv) zur Anpassung an veränderte Marktgegebenheiten
								wie bspw. erhöhte Nutzerzahlen, (v) aufgrund von Verletzungen der Rechte Dritter,
								(vi) aufgrund von Verlust oder Änderungen von Lizenzbedingungen Dritter, deren Lizenzen
								für den Betrieb der App erforderlich sind, (vii) aufgrund von Liefer- oder
								Bereitstellungseinschränkungen von Dienstleistern von ZF, oder (viii) zugunsten des
								Nutzers.
							</li>
							<li>
								Für Änderungen der App und deren Funktionsumfangs entstehen Nutzern keine zusätzlichen
								Kosten. ZF wird den Nutzer über die Änderung in der App klar und verständlich
								informieren.
							</li>

							<li>
								Beeinträchtigt eine solche Änderung die Zugriffsmöglichkeiten auf oder die Nutzbarkeit
								der App für den Nutzer mehr als nur unerheblich („negative Änderung“), wird ZF den
								Nutzer mindestens acht Wochen im Voraus durch eine Nachricht in Textform (z.B. durch
								eine Mitteilung über die im Benutzerkonto hinterlegte E-Mail-Adresse des Nutzers)
								(„Änderungsmitteilung“) informieren. Die Änderungsmitteilung enthält die Merkmale und
								den Zeitpunkt der negativen Änderung sowie die nachstehend beschriebenen Rechte des
								Nutzers.
							</li>

							<li>
								Im Fall von negativen Änderungen hat der Nutzer das Recht, den Vertrag mit einer Frist
								von 30 Tagen unentgeltlich zu beenden. Die Frist beginnt mit dem Zugang der
								Änderungsmitteilung beim Nutzer zu laufen. Erfolgt die Änderung nach dem Zugang der
								Änderungsmitteilung beim Nutzer, beginnt die Frist erst mit dem Zeitpunkt der Änderung
								zu laufen. Die Beendigung des Vertrags ist ausgeschlossen, wenn die Zugriffsmöglichkeit
								oder die Nutzbarkeit der unveränderten App ohne zusätzliche Kosten erhalten bleibt.
							</li>

							<li>
								Diese Nutzungsbedingungen gelten entsprechend für alle Änderungen und für die App in der
								jeweils durch Änderungen geänderten Fassung.
							</li>

							<li>
								Zur Klarstellung: Das Hinzufügen, Entfernen und Anpassen von über die App verfügbaren
								Programmen stellt keine Änderung der App oder deren Funktionsumfangs dar und kann von ZF
								jederzeit ohne Berücksichtigung der Vorgaben dieser Ziffer 3 vorgenommen werden.
							</li>
						</ol>
						<h2>4. Vertragsschluss, Aktivierung des App-Benutzerkontos und Anmeldung</h2>
						<ol>
							<li>
								Die Bereitstellung der App durch ZF stellt ein verbindliches Angebot von ZF zur Nutzung
								der App für ZF-Vorsorge-berechtigte Nutzer dar.
							</li>
							<li>
								Der Nutzer nimmt dieses Angebot an, sobald er die Aktivierung seines App-Benutzerkontos
								(dazu sogleich) abgeschlossen und diese Nutzungsbedingungen angenommen hat. Bis zum
								Abschluss der Registrierung kann der Nutzer den Vorgang jederzeit abbrechen oder
								verändern.
							</li>

							<li>
								Der Vertrag kann in deutscher Sprache und in englischer Sprache geschlossen werden. Im
								Fall von Widersprüchen der jeweiligen Sprachfassungen gilt die Regelung in Ziffer 11.1.
							</li>
							<li>
								Um die App nutzen zu können, ist eine Aktivierung des App-Benutzerkontos über die App
								erforderlich, damit sich der Nutzer auf einem Endgerät mit Browser (Computer oder
								mobiles Endgerät) einloggen kann. Eine Aktivierung des App-Benutzerkontos ist nur mit
								einer gültigen privaten E-Mail-Adresse des Nutzers, der ZF Vorsorge
								Identifikationsnummer, einer gültigen Mobilfunknummer und der Eingabe eines per
								App-Anschreiben versendeten Initialpasswortes möglich. Das Initialpasswort erhält der
								Nutzer, sobald er seine Erklärung zur Teilnahme am digitalen Teilnahmeverfahren der ZF
								eingereicht hat. Diese Zugangsdaten dürfen keinem anderen bereits aktivierten
								App-Benutzerkonto zugeordnet sein.
							</li>

							<li>
								Aktivierung des App-Benutzerkontos: Der Nutzer kann die App über einen ihm zugesendeten
								personalisierten QR-Code oder URL oder über einen allgemeinen, dem Nutzer von ZF
								mitgeteilten Link mit einem Webbrowser von einem Computer oder mit einem mobilen
								Endgerät (Smartphone oder Tablet) öffnen.Der Nutzer muss im ersten Schritt seine ZF
								Vorsorge-Identifikationsnummer angeben sowie sein Initialpasswort eingeben, welches ihm
								per Anschreiben von ZF zugesendet wurde. Im Folgenden setzt der Nutzer selbst ein neues
								Passwort.Der Nutzer registriert einmalig eine gültige Mobilfunknummer, über die ihm
								mittels SMS-TAN-Verfahren eine erzeugte TAN zugesandt wird. Der Nutzer bestätigt diese
								Mobilfunknummer mittels der erhaltenen TAN.Der Nutzer vervollständigt sein Profil mit
								seiner privaten E-Mail-Adresse und bestätigt die E-Mail-Adresse ebenfalls mittels einer
								erhaltenen TAN.Im Folgenden akzeptiert der Nutzer die Nutzungsbedingungen. Anschließend
								kann er auf den „Weiter“-Button klicken, womit die Registrierung abgeschlossen
								ist.Danach kann die App entsprechend des beschriebenen Leistungsumfangs genutzt werden.
								Nach erfolgreicher Registrierung erhält der Nutzer eine Bestätigungsmail an seine
								angegebene private E-Mail-Adresse.
							</li>

							<li>
								Anmeldung bei der App: Nach der Aktivierung des Benutzerkontos erfolgt jede Anmeldung
								bei der App wiederum über die Eingabe der privaten E-Mail-Adresse, Eingabe des durch den
								Nutzer selbst gesetzten Passworts sowie der Authentifizierung mittels einer erhaltenen
								TAN.
							</li>

							<li>
								Nach 10 fehlgeschlagenen Anmeldeversuchen wird das Benutzerkonto für 24 Stunden
								gesperrt. Nach Ablauf der 24 Stunden hat der Nutzer erneut die Möglichkeit sich
								anzumelden.
							</li>
						</ol>
						<h2>
							5. Allgemeine Rechte und Pflichten des Nutzers, Rechteeinräumung und Regelungen zur Nutzung
							der App
						</h2>
						<ol>
							<li>
								Für die Nutzung der App muss der Nutzer über ein kompatibles und internetfähiges
								Endgerät wie ein Rechner oder ein Smartphone / Tablet mit Internetzugang verfügen, wobei
								sowohl ein dienstliches Gerät der ZF als auch ein privates Gerät verwendet werden darf.
								Hierbei werden folgende Browser unterstützt: Safari (iOS), Chrome/Samsung Internet
								(Android), Edge/ChromeFirefox (Windows), Safari (OS X). Da es sich um eine
								Webapplikation handelt, läuft diese auf den Endgeräten unabhängig vom Betriebssystem.
								Für die Erfüllung dieser technischen Voraussetzungen ist der Nutzer selbst
								verantwortlich.
							</li>
							<li>
								Ferner muss der Nutzer das digitale Teilnahmeverfahren beantragt haben und das
								App-Anschreiben mit seinen Zugangsdaten zur Registrierung erhalten haben. Für die
								Durchführung des digitalen Teilnahmeverfahrens ist der Nutzer selbst verantwortlich.
							</li>
							<li>
								Der Nutzer hat sowohl die einem App-Benutzerkonto zugeordneten Zugangsdaten als auch das
								systemseitig vergebene Initialpasswort und das vom Nutzer selbst gesetzte Passwort
								vertraulich zu behandeln und nicht an Dritte weiterzugeben.
							</li>
							<li>
								Der Nutzer darf die App ausschließlich für eigene Zwecke sowie in Übereinstimmung mit
								geltendem Recht und diesen Nutzungsbedingungen benutzen.
							</li>
							<li>
								Dem Nutzer ist es untersagt, automatische Systeme, Mechanismen, Software, Skripte,
								Programme und / oder andere Verfahren einzusetzen, die automatisierte Anfragen an die
								Server von ZF richten, es sei denn, es handelt sich hierbei um ZF-Software oder andere
								seitens ZF ausdrücklich und schriftlich autorisierte Verfahren.
							</li>
							<li>
								Dem Nutzer ist es untersagt, die App in einer Weise zu verwenden, die ihren technischen
								Ablauf stört oder überlastet sowie, in die App oder die mit der App verbundenen Server
								und Netzwerke einzugreifen und dadurch ihre Funktion zu beeinträchtigen oder sie zu
								unterbrechen.
							</li>
							<li>
								Die App einschließlich ihrer Inhalte ist urheberrechtlich geschützt. Es wird dem Nutzer
								ein nicht ausschließliches, auf die Dauer des Nutzungsverhältnisses beschränktes und
								nicht übertragbares Recht eingeräumt, die App gemäß diesen Nutzungsbedingungen zu
								nutzen. Nach Beendigung des Nutzungsvertrags endet diese Lizenz. Der Nutzer erwirbt kein
								Anrecht auf den Quellcode.
							</li>
							<li>
								Der Nutzer darf den über die App angebotenen Dienst insbesondere nicht modifizieren,
								anpassen, übersetzen oder Derivative basierend auf der App oder Teilen hiervon
								erstellen, es sei denn, dies ist ausdrücklich gesetzlich erlaubt; er darf auch nicht
								versuchen, Sicherheitseinstellungen von ZF zu umgehen oder zu manipulieren.
							</li>
							<li>
								Marken, Firmenlogos, URLs, sonstige Kennzeichen oder Schutzvermerke, Urhebervermerke,
								Seriennummern und alle anderen der Identifikation dienenden Merkmale von ZF dürfen nicht
								entfernt oder verändert werden.
							</li>
							<li>
								ZF behält sich vor, Einzelkonten bei Verdacht von Verstößen gegen diese Vereinbarung
								vorübergehend zu deaktivieren.
							</li>
						</ol>
						<h2>6. Widerrufsrecht</h2>
						<div style={{ border: '1px solid var(--text-color)' }} className="padding">
							<h3>Widerrufsbelehrung</h3>
							<p>
								<b>Widerrufsrecht</b>
							</p>
							<p>
								Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu
								widerrufen.
							</p>
							<p>Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsschlusses.</p>

							<p>
								Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (ZF Friedrichshafen AG, Löwentaler
								Straße 20, 88046 Friedrichshafen, info@zfvorsorge.de, 0800 4030188) mittels einer
								eindeutigen Erklärung (z. B. ein mit der Post versandter Brief oder eine E-Mail) über
								Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das
								beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist. Zur
								Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des
								Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
							</p>
							<p>
								<b>Folgen des Widerrufs</b>
							</p>
							<p>
								Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen
								erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten,
								die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns
								angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens
								binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren
								Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir
								dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es
								sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden
								Ihnen wegen dieser Rückzahlung Entgelte berechnet.
							</p>
						</div>
						<br />
						<div style={{ border: '1px solid var(--text-color)' }} className="padding">
							<h3>Muster-Widerrufsformular</h3>
							<p>
								(Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und
								senden Sie es zurück.)
							</p>
							<ul>
								<li>
									An ZF Friedrichshafen AG, Löwentaler Straße 20, 88046 Friedrichshafen,{' '}
									<a href="mailto:info@zfvorsorge.de">info@zfvorsorge.de</a>
								</li>
								<li>
									Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über
									den Kauf der folgenden Waren (*)/die Erbringung der folgenden Dienstleistung (*)
								</li>
								<li> Bestellt am (*)/erhalten am (*)</li>
								<li>Name des/der Verbraucher(s)</li>
								<li>Anschrift des/der Verbraucher(s)</li>
								<li>Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)</li>{' '}
								<li>Datum </li>
							</ul>
							<p>(*) Unzutreffendes streichen.</p>
						</div>
						<h2>7. Gewährleistung </h2>
						<p>ZF leistet für die App Gewährleistung entsprechend der gesetzlichen Bestimmungen.</p>
						<h2>8. Haftung</h2>{' '}
						<p>
							ZF haftet im Rahmen dieser Nutzungsbedingungen abschließend nach Maßgabe der folgenden
							Bestimmungen.
						</p>
						<ol>
							<li>
								ZF haftet für Vorsatz und grobe Fahrlässigkeit sowie bei Schäden aus der Verletzung des
								Lebens, des Körpers oder der Gesundheit unbeschränkt.
							</li>

							<li>
								In Fällen leichter Fahrlässigkeit haftet ZF bei Verletzung einer wesentlichen
								Vertragspflicht. Eine wesentliche Vertragspflicht im Sinne dieser Ziffer ist eine
								Pflicht, deren Erfüllung die Durchführung des Vertrages erst ermöglicht und auf deren
								Erfüllung sich der Vertragspartner deswegen regelmäßig verlassen darf.
							</li>

							<li>
								Die Haftung gemäß Ziffer 8.2 ist auf den im Zeitpunkt des Vertragsschlusses
								vorhersehbaren Schaden begrenzt, mit dessen Entstehung typischerweise gerechnet werden
								muss.
							</li>

							<li>
								Die Haftung für Schäden aufgrund von Datenverlust sind im Fall von Ziffer 8.2 auf den
								Betrag der Wiederherstellung der Daten beschränkt, der auch bei regelmäßiger und
								gefahrentsprechender Sicherung der Daten durch den Nutzer angefallen wäre.
							</li>

							<li>
								Die Haftungsbeschränkungen gelten zugunsten der von ZF im Zusammenhang mit der
								Entwicklung und dem Betrieb der App eingesetzten Mitarbeiter, Beauftragten und
								Erfüllungsgehilfen von ZF entsprechend.
							</li>

							<li>
								Eine etwaige Haftung von ZF für gegebene Garantien (die ausdrücklich als solche
								bezeichnet sein müssen) und für Ansprüche auf Grund des Produkthaftungsgesetzes bleibt
								unberührt.
							</li>

							<li>Eine weitergehende Haftung von ZF ist ausgeschlossen.</li>
						</ol>
						<h2>9. Laufzeit und Beendigung des Nutzungsverhältnisses</h2>
						<ol>
							<li>
								Das Nutzungsverhältnis beginnt mit Annahme dieser Nutzungsbedingungen seitens des
								Nutzers und läuft auf unbestimmte Zeit.
							</li>
							<li>
								Das Nutzungsverhältnis kann jederzeit mit einer Frist von zwei Wochen durch den Nutzer
								oder ZF gekündigt werden. Die Kündigung seitens des Nutzers erfolgt dadurch, dass er in
								der App unter seinem Profil die Teilnahme am digitalen Teilnahmeverfahren kündigt. Die
								Kündigung seitens ZF erfolgt dadurch, dass der Nutzer per E-Mail, an die von ihm
								hinterlegte E-Mail-Adresse, darüber informiert wird, dass seine Berechtigung entzogen
								wurde. Das Recht zur außerordentlichen Kündigung nach § 314 BGB bleibt unberührt.
							</li>
						</ol>
						<h2>10. Änderungen der Nutzungsbedingungen</h2>
						<ol>
							<li>
								ZF kann diese Nutzungsbedingungen mit Wirkung für die Zukunft ändern und anpassen, wenn
								für die Änderung ein triftiger Grund vorliegt und soweit die Änderungen unter
								Berücksichtigung der Interessen von ZF und der Nutzer zumutbar sind.
							</li>

							<li>
								Ein triftiger Grund liegt insbesondere vor, wenn die Änderungen aufgrund einer für ZF
								bei Vertragsschluss unvorhersehbaren Störung des Äquivalenzverhältnisses des Vertrages
								in nicht unbedeutendem Maße erforderlich sind oder aufgrund von Rechtsprechungs- oder
								Gesetzesänderungen für die weitere Durchführung des Vertrages erforderlich sind.
							</li>

							<li>
								Die Änderung einer Hauptleistungspflicht – mit Ausnahme der in Ziffer 3 beschriebenen
								Änderungen der App oder deren Funktionsumfangs – ist ausgeschlossen.
							</li>
							<li>
								ZF wird dem Nutzer die geänderten Bedingungen mindestens acht Wochen vor dem geplanten
								Inkrafttreten in Textform (z.B. durch eine Mitteilung über die im Benutzerkonto
								hinterlegte E-Mail-Adresse des Nutzers) übermitteln und auf die Neuregelungen sowie das
								Datum des Inkrafttretens gesondert hinweisen. Zugleich wird ZF dem Nutzer eine
								angemessene, mindestens acht Wochen lange Frist für die Erklärung einräumen, ob der
								Nutzer die geänderten Nutzungsbedingungen für die weitere Inanspruchnahme der App und
								ihrer Funktionen akzeptiert.
							</li>

							<li>
								Erfolgt innerhalb dieser Frist, welche ab Erhalt der Nachricht in Textform zu laufen
								beginnt, keine Erklärung des Nutzers (indem er z.B. einen Widerspruch per E-Mail an{' '}
								<a href="mailto:info@zfvorsorge.de">info@zfvorsorge.de</a> sendet), so gelten die
								geänderten Nutzungsbedingungen als vereinbart.
							</li>

							<li>
								ZF wird den Nutzer bei Fristbeginn gesondert auf diese Rechtsfolge, d.h. das
								Widerspruchsrecht, die Widerspruchsfrist und die Bedeutung des Schweigens, hinweisen.
							</li>
						</ol>
						<h2>11. Schlussbestimmungen </h2>
						<ol>
							<li>
								Im Fall von Widersprüchen zwischen der deutschen und anderen Sprachfassungen dieser
								Nutzungsbedingungen gilt vorrangig die deutsche Sprachfassung.
							</li>

							<li>
								Auf das Nutzungsverhältnis zwischen ZF und dem Nutzer findet das Recht der
								Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts Anwendung. Dies gilt nicht
								für Nutzer in Bezug auf solche Bestimmungen, die nach dem Recht, das ohne diese Klausel
								anwendbar wäre (also in der Regel des Landes, wo der Nutzer seinen Hauptwohnsitz hat),
								zwingend anwendbar sind und von denen nicht durch Vereinbarung abgewichen werden darf.
							</li>

							<li>
								Diese Vereinbarung bleibt auch bei rechtlicher Unwirksamkeit einzelner Punkte in ihren
								übrigen Teilen verbindlich. Anstelle der unwirksamen Punkte treten, soweit vorhanden,
								die gesetzlichen Vorschriften.
							</li>

							<li>
								Die Europäische Kommission stellt unter{' '}
								<a href="http://ec.europa.eu/consumers/odr/ ">http://ec.europa.eu/consumers/odr/ </a>{' '}
								eine Plattform zur Online-Streitbeilegung (OS) bereit. ZF ist weder bereit noch
								verpflichtet, an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
								teilzunehmen.
							</li>
						</ol>
						<h2>12. Kontakt</h2>
						<ol>
							<li>
								Bei Fragen zur Bedienung der App oder Supportanfragen zu den Funktionalitäten der App
								wenden Sie sich bitte an:
								<br />
								Decadia GmbH, Telefon: 0800 4030188 (Montag-Freitag von 9.00 Uhr-14.00 Uhr, außer an
								gesetzlichen Feiertagen in NRW), kostenlos erreichbar aus dem deutschen Festnetzoder{' '}
								<a href="mailto:info@zfvorsorge.de">info@zfvorsorge.de</a>
							</li>

							<li>
								Für Fragen und Anmerkungen zu unseren Nutzungsbedingungen sowie zur ZF Vorsorge wenden
								Sie sich bitte an: <br />
								&nbsp;
								<br />
								ZF Friedrichshafen AG,
								<br /> Abteilung Pension – and Insurance Management
								<br /> Löwentaler Straße, 2088046 Friedrichshafen
							</li>
						</ol>
						<div className="sticky-overlay"></div>
					</div>
					<Button
						type={ButtonType.link}
						label={'Nutzungsbedingungen herunterladen'}
						onClick={handleDownload}
					/>
				</>
			) : (
				<>
					<div className="whole-terms-and-conditions text-align--left">
						<Button
							type={ButtonType.link}
							label={'Download Terms and Conditions'}
							onClick={handleDownload}
						/>
						<h1>Terms and Conditions ZF Vorsorge App </h1>
						<p>Status: March 27, 2023</p>
						<h2>Preamble</h2>
						<p>
							These Terms of Use govern the usage relationship between ZF Friedrichshafen AG, Löwentaler
							Straße 20, 88046 Friedrichshafen („<b>ZF</b>“,„<b>we</b>“ or „<b>us</b>“) and those
							employees of the ZF Group of Companies who use the Progressive Web App for company pension
							plans ("<b>App</b>") offered by ZF ("
							<b>User</b>"). The App was developed for ZF and is operated for ZF by a service provider
							bound by instructions, Decadia GmbH. The App can be used as a web application from a
							computer with a web browser and also as an App with a mobile device (smartphone or tablet).
							It serves as an information and interaction tool for the implementation of the company
							pension scheme (in particular: VO 2019, "<b>ZF Vorsorge</b>").{' '}
						</p>
						<p>
							The service offered via the app is aimed exclusively at employees of companies in the ZF
							Group of Companies who are or were employed by ZF Friedrichshafen AG at the time of first
							use and are or were entitled to participate in ZF Vorsorge. In addition to ZF
							Friedrichshafen AG, the ZF Group of Companies as defined above includes companies affiliated
							with ZF Friedrichshafen AG within the meaning of Section 15 of the German Stock Corporation
							Act (AktG) and companies in which ZF Friedrichshafen AG holds an interest of at least 50%.
							ZF grants certain employees the opportunity to make deferred compensation contributions to
							the company pension scheme as part of ZF Vorsorge. If certain conditions are met, ZF makes
							an additional contribution (basic employer contribution and, if applicable, matching
							contribution). All employee- and employer-financed contributions are invested in special
							funds at ZF Pension Trust e.V. and generate a return on the capital market. ZF provides the
							app to those employees as an interactive information tool to submit applications for
							deferred compensation and to keep track of the individual performance of deferred
							compensation. This includes, for example, the presentation and display of the monthly and
							annual individual conversions, news regarding the deposit value, the personal pension
							credit, the conclusion and adjustments of the individual deferred compensation agreements by
							means of a conversion request, for the selection of payment modalities provided for after
							the pension, the display of documents (e.g. conversion request confirmations), the price
							trend or the performance of the special fund, the fund information as well as general
							information on the ZF pension plan. At the same time, an eligible employee who has not yet
							made any salary conversions within the scope of ZF Vorsorge can use the app. The app can be
							used only as a source of information or for direct salary conversion.
						</p>
						<h2>1. Scope of application / usage relationship</h2>{' '}
						<ol>
							<li>These Terms of Use form the legal basis for the use of the App by the User.</li>
							<li>
								These terms of use do not regulate the question of whether there is an obligation to use
								the app or an entitlement to participate in ZF Vorsorge, but merely modalities for using
								the app.
							</li>
							<li>
								In addition to these Terms of Use, all ZF company regulations and Group guidelines
								apply, including the respective specific location-dependent company regulations as well
								as the respective employment contract agreements between ZF and the users. The
								prerequisites and regulations for participation in deferred compensation for retirement
								provision as well as the individual conversion agreement with the user are not subject
								to these Terms of Use. Further information on ZF pension provision and investment in the
								ZF Pension Trust as well as ZF Pension Trust e.V. is available from ZF.
							</li>
							<li>
								Any terms and conditions of the user that conflict with or deviate from these Terms of
								Use shall not be recognized unless ZF has expressly agreed to their validity. This shall
								also apply if ZF performs its services without reservation in the knowledge of terms and
								conditions of the user that conflict with or deviate from these Terms of Use.
							</li>
							<li>
								In order to use the app, the digital participation procedure must be carried out in
								order to obtain the access data for the app. These terms of use do not apply to the
								execution of the digital participation procedure.{' '}
							</li>

							<li>
								In order to use the app, two-factor authentication using the SMS-TAN procedure is also
								required for both its activation and the respective login.
							</li>
							<li>
								We do not save this contract text after conclusion of the contract. The currently valid
								Terms of Use are available within the web app under the "Profile" tab, so that the user
								can view them as well as download them to his end device and save them there.
							</li>
						</ol>{' '}
						<h2>2. Scope of services of the app </h2>
						<ol>
							<li>
								The app is used for the informative display of an individual account of the respective
								user in order to provide him with an overview of the performance and the individual
								account balance of his deferred compensation for the company pension plan. In the
								interactive part of the app, the user has the option of applying for, adjusting and/or
								terminating his deferred compensation.
							</li>

							<li>
								In the individual account, the following information is provided to the user:
								<ul>
									<li>
										Master data/app profile: Title, first and last name, additional word, prefix
										word, Address, user name (private e-mail address & ZF Vorsorge-ID)
									</li>

									<li>
										Information from the ZF HR system: company entry, employment level, assessment
										basis
									</li>

									<li>
										Possibility to convert remuneration by means of a conversion request from the
										current monthly salary as well as from one- time payments in percent or in
										amount
									</li>

									<li>
										Presentation and display of a history of the monthly as well as annual
										individual conversions, with display of the employer's basic contributions and
										matching contributions
									</li>

									<li>
										Account balance information (monthly/annual conversions over time, number of
										conversions to date including conversions in past years)
									</li>

									<li>Possibility to choose the payment methods foreseen for retirement</li>

									<li>
										News regarding the portfolio value since the last time the app was opened (gain
										/ loss due to price development)
									</li>

									<li>
										Information on the price development (performance / price development of the
										special funds, development of comparative indices)
									</li>

									<li>
										Information on the special funds (summary of the fund and further information,
										if applicable)
									</li>

									<li>
										Display of created documents within the app (e.g. conversion request
										confirmations)
									</li>

									<li>General information about ZF Vorsorge via FAQs</li>
								</ul>
							</li>

							<li>
								Beyond the liability provided for by law, ZF does not guarantee that the information
								provided via the app is up-to-date, correct or complete. The user is expressly informed
								in the app that the information displayed via the app may be outdated by current
								developments (such as price fluctuations, changes in the price trend) without the
								information displayed in the app having been adjusted accordingly. In general, the price
								values are displayed with a time delay of approx. 1 week (without taking into account
								the current trading day), as the fund is not publicly listed and the price values can
								only be calculated and transmitted after the corresponding trading day.
							</li>

							<li>
								The transmission and display of the information in the app do not constitute investment
								advice or investment brokerage or any other financial service pursuant to the German
								Banking Act (KWG), the German Securities Trading Act (WPHG) and financial investment
								brokerage pursuant to the German Trade Regulation Act (GewO), nor are they connected
								with investment advice, investment brokerage or financial services.
							</li>

							<li>
								No conclusions for current or future price developments can be drawn from past price
								developments.
							</li>

							<li>Users do not incur any costs for using the app.</li>
						</ol>
						<h2>3. Changes to the app</h2>
						<ol>
							<li>
								Beyond what is necessary to maintain the contractual conformity of the App, ZF may
								modify and adapt the App and its scope of functions if a valid reason requires such
								modification. Such a reason exists in particular in the case of changes (i) to implement
								changed statutory requirements or case law, (ii) to implement court or official orders,
								(iii) to implement changed technical requirements such as a new technical environment or
								other operational reasons, (iv) to adapt to changed market conditions such as, for
								example, increased user numbers, (v) due to violations of the law. (v) due to
								infringement of third party rights, (vi) due to loss of or changes to third party
								license terms whose licenses are required for the operation of the App, (vii) due to
								delivery or provision restrictions of ZF's service providers, or (viii) for the benefit
								of the user.
							</li>
							<li>
								Users will not incur any additional costs for changes to the app and its scope of
								functions. ZF will inform the user about the change in the app in a clear and
								understandable manner.
							</li>

							<li>
								If such a change more than insignificantly affects the user's ability to access or use
								the app ("
								<b>negative change</b>"), ZF will notify the user at least eight weeks in advance by
								sending a message in text form (e.g., by sending a message via the user's e-mail address
								stored in the user account) ("change notification"). The change notification shall
								contain the characteristics and the time of the negative change as well as the rights of
								the user described below.
							</li>

							<li>
								In case of negative changes, the user has the right to terminate the contract free of
								charge with a notice period of 30 days. The period begins with the receipt of the change
								notification by the user. If the change is made after the user has received the change
								notification, the period shall only start to run from the time of the change.
								Termination of the contract is excluded if the accessibility or usability of the
								unchanged app is maintained without additional costs.
							</li>

							<li>
								These Terms of Use shall apply mutatis mutandis to any amendments and to the App as
								amended by any such amendments.
							</li>

							<li>
								For the avoidance of doubt, the addition, removal and customization of programs
								available through the App does not constitute a change to the App or its functionality
								and may be made by ZF at any time without regard to the requirements of this Section 3.
							</li>
						</ol>
						<h2>4. Conclusion of contract, activation of the app user account and registration</h2>
						<ol>
							<li>
								The provision of the app by ZF constitutes a binding offer by ZF for the use of the app
								for ZF provision-eligible users.
							</li>
							<li>
								The user accepts this offer as soon as he has completed the activation of his app user
								account (see below) and has accepted these terms of use. Until the registration is
								completed, the user can cancel or change the process at any time.
							</li>

							<li>
								The contract may be concluded in German and in English. In the event of contradictions
								between the respective language versions, the provision in Section 11.1 shall apply.
							</li>
							<li>
								In order to be able to use the app, activation of the app user account via the app is
								required so that the user can log in on an end device with a browser (computer or mobile
								end device). Activation of the app user account is only possible with a valid private
								e-mail address of the user, the ZF Vorsorge identification number, a valid mobile phone
								number and the entry of an initial password sent by app cover letter. The user will
								receive the initial password as soon as he has submitted his declaration of
								participation in the ZF digital participation procedure. This access data must not be
								assigned to any other already activated app user account.
							</li>

							<li>
								Activation of the app user account: The user can open the app via a personalized QR code
								or URL sent to him or via a general link communicated to the user by ZF using a web
								browser from a computer or with a mobile end device (smartphone or tablet). In the first
								step, the user must enter his ZF Vorsorge identification number and his initial
								password, which was sent to him by ZF in a cover letter. In the following, the user sets
								a new password himself. The user registers a valid mobile phone number once, via which a
								generated TAN is sent to him using the SMS-TAN procedure. The User confirms this mobile
								phone number by means of the TAN received. The user completes his profile with his
								private e-mail address and also confirms the e-mail address by means of a TAN received.
								The user then accepts the terms of use. He can then click on the "Continue" button,
								which completes the registration. The app can then be used in accordance with the scope
								of services described. After successful registration, the user receives a confirmation
								e- mail to the private e-mail address provided.
							</li>

							<li>
								Logging in to the app: After activating the user account, each login to the app is again
								performed by entering the private e-mail address, entering the password set by the user
								himself, and authentication by means of a TAN received.
							</li>

							<li>
								After 10 failed login attempts, the user account will be locked for 24 hours. After the
								24 hours have elapsed, the user has the opportunity to log in again.
							</li>
						</ol>
						<h2>
							5. General rights and obligations of the user, granting of rights and regulations for the
							use of the app
						</h2>
						<ol>
							<li>
								To use the app, the user must have a compatible and Internet-capable end device such as
								a computer or smartphone/tablet with Internet access, whereby both a ZF official device
								and a private device may be used. The following browsers are supported here: Safari
								(iOS), Chrome/Samsung Internet (Android), Edge/ChromeFirefox (Windows), Safari (OS X).
								Since it is a web application, it runs on the end devices regardless of the operating
								system. The user is responsible for meeting these technical requirements.
							</li>
							<li>
								Furthermore, the user must have applied for the digital participation procedure and
								received the app cover letter with his/her access data for registration. The user is
								responsible for the implementation of the digital participation procedure.
							</li>
							<li>
								The user must treat both the access data assigned to an app user account and the initial
								password assigned by the system and the password set by the user himself confidentially
								and not disclose it to third parties.
							</li>
							<li>
								The User may use the App solely for its own purposes and in accordance with applicable
								law and these Terms of Use.
							</li>
							<li>
								The user is prohibited from using automatic systems, mechanisms, software, scripts,
								programs and / or other procedures that make automated requests to ZF's servers, unless
								these are ZF software or other procedures expressly authorized by ZF in writing.
							</li>
							<li>
								The User is prohibited from using the App in any way that disrupts or overloads its
								technical operation, as well as from interfering with the App or servers and networks
								connected to the App, thereby impairing or interrupting its operation.
							</li>
							<li>
								The app including its contents is protected by copyright. The user is granted a
								non-exclusive, non-transferable right, limited to the duration of the usage
								relationship, to use the app in accordance with these terms of use. Upon termination of
								the user agreement, this license shall expire. The user does not acquire any right to
								the source code.
							</li>
							<li>
								In particular, the User shall not modify, adapt, translate or create derivatives based
								on the App or any part thereof, except as expressly permitted by law, nor attempt to
								circumvent or manipulate ZF security settings.
							</li>
							<li>
								Trademarks, company logos, URLs, other marks or protective notices, copyright notices,
								serial numbers, and all other identifying features of ZF may not be removed or altered.
							</li>
							<li>
								ZF reserves the right to temporarily deactivate individual accounts in the event of
								suspected violations of this agreement.
							</li>
						</ol>
						<h2>6. Right of withdrawal</h2>
						<div style={{ border: '1px solid var(--text-color)' }} className="padding">
							<h3>Cancellation policy</h3>
							<p>
								<b>Right of withdrawal</b>
							</p>
							<p>
								You have the right to cancel this contract within fourteen days without giving any
								reason.
							</p>
							<p>The withdrawal period is fourteen days from the date of conclusion of the contract.</p>

							<p>
								To exercise your right of withdrawal, you must inform us (ZF Friedrichshafen AG,
								Löwentaler Straße 20, 88046 Friedrichshafen, info@zfvorsorge.de, 0800 4030188) of your
								decision to withdraw from this contract by means of a clear declaration (e.g. a letter
								sent by post or an e-mail). You can use the attached sample cancellation form for this
								purpose, which is, however, not mandatory.
							</p>
							<p>
								In order to comply with the withdrawal period, it is sufficient that you send the
								notification of the exercise of the right of withdrawal before the expiry of the
								withdrawal period.
							</p>
							<p>
								<b>Consequences of the revocation</b>
							</p>
							<p>
								If you revoke this contract, we shall reimburse you all payments we have received from
								you, including delivery costs (with the exception of additional costs resulting from the
								fact that you have chosen a type of delivery other than the most favorable standard
								delivery offered by us), without undue delay and no later than within fourteen days from
								the day on which we received the notification of your revocation of this contract. For
								this repayment, we will use the same means of payment that you used for the original
								transaction, unless expressly agreed otherwise with you; in no case will you be charged
								for this repayment.
							</p>
						</div>
						<br />
						<div style={{ border: '1px solid var(--text-color)' }} className="padding">
							<h3>Sample cancellation form</h3>
							<p>(If you want to cancel the contract, please fill out and return this form).</p>
							<ul>
								<li>
									To ZF Friedrichshafen AG, Löwentaler Straße 20, 88046 Friedrichshafen,{' '}
									<a href="mailto:info@zfvorsorge.de">info@zfvorsorge.de</a>
								</li>
								<li>
									I/we (*) hereby revoke the contract concluded by me/us (*) for the purchase of the
									following goods (*)/provision of the following service (*)
								</li>
								<li> Ordered on (*)/received on (*)</li>
								<li>Name of the consumer(s)</li>
								<li>Address of the consumer(s)</li>
								<li>Signature of the consumer(s) (only in case of notification on paper)</li>{' '}
								<li>Date </li>
							</ul>
							<p>(*) Delete as applicable.</p>
						</div>
						<h2>7. Warranty </h2>
						<p>ZF provides warranty for the app in accordance with the statutory provisions.</p>
						<h2>8. Liability</h2>{' '}
						<p>
							ZF shall be conclusively liable within the scope of these Terms of Use in accordance with
							the following provisions.
						</p>
						<ol>
							<li>
								ZF shall be liable without limitation for intent and gross negligence as well as for
								damages resulting from injury to life, body or health.
							</li>

							<li>
								In cases of slight negligence, ZF shall be liable for breach of a material contractual
								obligation. An essential contractual obligation within the meaning of this clause is an
								obligation the fulfillment of which makes the execution of the contract possible in the
								first place and on the fulfillment of which the contractual partner may therefore
								regularly rely.
							</li>

							<li>
								The liability according to clause 8.2 is limited to the damage foreseeable at the time
								of the conclusion of the contract, the occurrence of which must typically be expected.
							</li>

							<li>
								The liability for damages due to loss of data are limited in the case of section 8.2 to
								the amount of the recovery of the data, which would have been incurred even if the user
								had backed up the data regularly and in accordance with the risk.
							</li>

							<li>
								The limitations of liability shall apply accordingly in favor of the employees, agents
								and vicarious agents of ZF used in connection with the development and operation of the
								app.
							</li>

							<li>
								Any liability of ZF for guarantees given (which must be expressly designated as such)
								and for claims based on the Product Liability Act shall remain unaffected.
							</li>

							<li>Any further liability on the part of ZF is excluded.</li>
						</ol>
						<h2>9. Term and termination of the usage relationship</h2>
						<ol>
							<li>
								The user relationship begins with the acceptance of these terms of use by the user and
								runs for an indefinite period.
							</li>
							<li>
								The user relationship can be terminated by the user or ZF at any time with two weeks'
								notice. Termination on the part of the user is effected by cancelling participation in
								the digital participation procedure in the app under his profile. Termination on the
								part of ZF is effected by informing the user by e-mail to the e-mail address stored by
								him that his authorization has been withdrawn. The right to extraordinary termination
								pursuant to Section 314 BGB remains unaffected.
							</li>
						</ol>
						<h2>10. Changes to the terms of use</h2>
						<ol>
							<li>
								ZF may amend and adjust these Terms of Use with effect for the future if there is good
								cause for the amendment and insofar as the amendments are reasonable taking into account
								the interests of ZF and the users.
							</li>

							<li>
								A valid reason exists in particular if the changes are necessary to a not insignificant
								extent due to a disturbance of the equivalence relationship of the contract which was
								unforeseeable for ZF at the time of conclusion of the contract or if they are necessary
								for the further execution of the contract due to changes in case law or legislation.
							</li>

							<li>
								The change of a main service obligation - with the exception of the changes of the app
								or its functional scope described in section 3 - is excluded.
							</li>
							<li>
								ZF will send the user the amended terms and conditions in text form (e.g. by means of a
								message via the user's e-mail address stored in the user account) at least eight weeks
								before the planned date of entry into force and will separately draw attention to the
								new provisions and the date of entry into force. At the same time, ZF will grant the
								user a reasonable period of at least eight weeks to declare whether the user accepts the
								amended terms of use for the further use of the app and its functions.
							</li>

							<li>
								If no declaration is made by the user within this period, which begins to run from
								receipt of the message in text form (e.g. by sending an objection by e-mail to{' '}
								<a href="mailto:info@zfvorsorge.de">info@zfvorsorge.de</a>), the amended Terms of Use
								shall be deemed agreed.
							</li>

							<li>
								ZF will separately inform the user of this legal consequence, i.e. the right of
								objection, the objection period and the significance of silence, at the start of the
								period.
							</li>
						</ol>
						<h2>11. Final provisions </h2>
						<ol>
							<li>
								In the event of any inconsistency between the German and other language versions of
								these Terms of Use, the German language version shall prevail.
							</li>

							<li>
								The user relationship between ZF and the user shall be governed by the laws of the
								Federal Republic of Germany, excluding the UN Convention on Contracts for the
								International Sale of Goods. This shall not apply to users in respect of such provisions
								which are mandatory under the law which would be applicable in the absence of this
								clause (i.e. as a rule the law of the country where the user has his principal place of
								residence) and which may not be deviated from by agreement.
							</li>

							<li>
								This agreement shall remain binding in its remaining parts even if individual points are
								legally invalid. The invalid points shall be replaced by the statutory provisions, if
								any.
							</li>

							<li>
								The European Commission provides a platform for online dispute resolution (OS) at{' '}
								<a href="http://ec.europa.eu/consumers/odr/ ">http://ec.europa.eu/consumers/odr/ </a>.
								ZF is neither willing nor obliged to participate in a dispute resolution procedure
								before a consumer arbitration board.
							</li>
						</ol>
						<h2>12. Contact</h2>
						<ol>
							<li>
								If you have any questions about using the app or support requests regarding the app's
								functionalities, please contact:
								<br />
								Decadia GmbH, phone: 0800 4030188 (Monday-Friday from 9.00 a.m.-1.00 p.m., except on
								public holidays in NRW), reachable free of charge from the German fixed network, or{' '}
								<a href="mailto:info@zfvorsorge.de">info@zfvorsorge.de</a>
							</li>

							<li>
								For questions and comments regarding our terms of use and ZF Vorsorge, please contact:
								<br />
								&nbsp;
								<br />
								ZF Friedrichshafen AG,
								<br /> Abteilung Pension – and Insurance Management
								<br /> Löwentaler Straße, 2088046 Friedrichshafen
							</li>
						</ol>
						<div className="sticky-overlay"></div>
					</div>
					<Button type={ButtonType.link} label={'Download Terms and Conditions'} onClick={handleDownload} />
				</>
			)}
		</div>
	)
}

export default TermsAndConditions
