import classNames from 'classnames'

import useApi, { QueryKey } from 'hooks/useApi'

import { t } from 'i18next'
import { useCallback, useState } from 'react'
import { Trans } from 'react-i18next'
import { trackPromise } from 'react-promise-tracker'
import { useQuery } from 'react-query'
import routesDictionary from 'routes'
import { ButtonType } from 'shared/components/Button'
import { ButtonWithCooldown } from 'shared/components/ButtonWithCooldown'
import Form, { FormFields, FormFieldType } from 'shared/components/Form'
import TextInput from 'shared/components/TextInput'
import numbersOnlyString from 'shared/helper/numbersOnlyString'
import { NoNullField } from 'shared/helper/types/noNullField'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { ChangeUserContactArguments, ChangeUserContactErrors } from 'shared/interfaces'
import { useBackButtonClickHandler } from 'state/useHeaderState'
import {
	supportedPhoneNumberCountries,
	phoneNumberDefaultCountry,
	isSupportedPhoneNumberType,
} from 'supported-phone-number-countries'


const ChangePhoneNumber: React.FunctionComponent = () => {
	const { navigateTo, getChildPath } = useRouteHelper()
	const [showTanInput, setShowTanInput] = useState<boolean>(false)
	const [phoneNoTan, setPhoneNoTan] = useState<string | undefined>()
	const [phoneNo, setPhoneNo] = useState<string | undefined>()

	const { changeConctactDetails, getUserAttributes } = useApi()

	const { data, refetch } = useQuery(QueryKey.cognitoUserAttributes, getUserAttributes)
	const oldPhone = data?.get('phone_number') || ''

	// const handleOnCloseConfirmModal = async () => {
	// 	await queryClient.refetchQueries(AuthQueryKey.userMetaData)
	// 	navigateTo(getMainPath(routesDictionary.profile || ''))
	// }

	const showConfirmScreen = () => {
		refetch()
		navigateTo(getChildPath(routesDictionary.profile, 'changePhoneSuccess'))
	}

	useBackButtonClickHandler({
		name: navigateTo,
		parameters: '/profile/overview',
	})

	const handleOnPhoneNumberChange = useCallback(() => {
		if (showTanInput === false) {
			return
		}

		setPhoneNo(undefined)
		setPhoneNoTan(undefined)
		setShowTanInput(false)
	}, [showTanInput])

	const handleOnSubmit = async (submittedFields: NoNullField<ChangeUserContactArguments>) => {
		if (!submittedFields.phoneNo) {
			return
		}

		if (isSupportedPhoneNumberType(submittedFields.phoneNo) === false) {
			return { successful: false, errorMessages: [t(`generic.form.errors.phoneNr.NOT_SUPPORTED_TYPE`)] }
		}

		const returnValue = {
			successful: true,
			errorMessages: [],
		}

		try {
			const response = await trackPromise(changeConctactDetails(submittedFields), 'change-user-details')
			const fieldKeys = Object.keys(submittedFields)

			/**
			 * needed since axios wont catch 400 errors
			 */
			if (response && 'isAxiosError' in response) {
				return { successful: false, errorMessages: [t(`apiErrors.UNKNOWN_ERROR`)] }
			}

			if (response !== false && response.errors) {
				const errors = response.errors

				fieldKeys.forEach((key) => {
					console.log(key)
					if (key in errors) {
						const fieldError =
							errors[key as Extract<keyof ChangeUserContactArguments, keyof ChangeUserContactErrors>]

						if (fieldError) {
							returnValue.successful = false
							returnValue.errorMessages.push(t(`generic.form.errors.${key}.${fieldError}`))
						}
					}
				})
			}

			//last request did not include tan -> enable tan input
			if (returnValue.successful && submittedFields.phoneNoTan === undefined) {
				setPhoneNo(submittedFields.phoneNo)
				setShowTanInput(true)

				return { abort: true }
			}

			return returnValue
		} catch (e) {
			return { successful: false }
		}
	}

	const handleOnResendTan = () => {
		if (!phoneNo) {
			return
		}

		handleOnSubmit({ phoneNo })
	}

	const inputElements: FormFields = {
		phoneNo: {
			required: true,
			label: t('view.profile.changePhoneNumber.form.newPhoneNumber'),
			autoComplete: 'tel',
			fieldType: FormFieldType.phoneInput,
			onChange: handleOnPhoneNumberChange,
			phoneInputProps: {
				countries: supportedPhoneNumberCountries,
				defaultCountry: phoneNumberDefaultCountry,
				addInternationalOption: false,
				international: true,
			},
		},
		phoneNoTan: {
			required: true,
			inputMode: 'decimal',
			pattern: '[0-9,.]*',
			label: t('view.profile.changePhoneNumber.form.tanLabel'),
			autoComplete: 'one-time-code',
			fieldType: FormFieldType.text,
			valueFunction: {
				name: numbersOnlyString,
			},
			value: '',
			className: classNames({
				'visually-hidden': showTanInput !== true,
				'visually-hidden--false': showTanInput === true,
			}),
			disabled: showTanInput !== true,
		},
	}

	return (
		<div className="center-view form-view">
			<div>
				<TextInput
					label={<Trans i18nKey="view.profile.changePhoneNumber.savedPhoneNumber" />}
					value={oldPhone}
					disabled
				></TextInput>
			</div>

			<div>
				<hr className="" />
			</div>

			<Form
				className="form-view"
				fields={inputElements}
				onSubmit={handleOnSubmit}
				onSuccess={showConfirmScreen}
				submitLabel={t('generic.send')}
				updatedValues={{ phoneNoTan: phoneNoTan }}
				alwaysAllowSubmit
				promiseTracker={{ area: 'change-user-details' }}
				onError={(error: any) => {
					console.log('error', error)
				}}
			>
				{showTanInput && (
					<div className="margin--top form-view__bodytext">
						<Trans
							i18nKey="view.profile.changePhoneNumber.form.hint"
							values={{ phoneNo: '*****' + phoneNo?.slice(-4) }}
						/>
						<ButtonWithCooldown
							className="margin--top"
							onClick={handleOnResendTan}
							type={[ButtonType.small, ButtonType.secondary]}
						>
							<Trans i18nKey={'view.profile.changePhoneNumber.form.hintButton'}></Trans>
						</ButtonWithCooldown>
					</div>
				)}
			</Form>
			{/* </div> */}
		</div>
	)
}

export default ChangePhoneNumber
