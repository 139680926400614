import useApi, { QueryKey } from 'hooks/useApi'
import { useAuth } from 'hooks/useAuth'
import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import { OPTEOptions, OPTTOptions, RiskOptions } from 'shared/enums'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { MfaResponseData } from 'shared/interfaces'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'

const RBSummary: React.FC<{ riskId: RiskOptions; type?: OPTEOptions | OPTTOptions; duration?: 1 | 3 }> = ({
	riskId,
	type,
	duration,
}) => {
	const { getChildPath, navigateTo } = useRouteHelper()
	useBackButtonPath(
		`${getChildPath(
			routesDictionary.riskBenefit,
			RiskOptions.disabilityBenefit === riskId ? 'RBDisabilityBenefitConditions' : 'RBPartnerBenefitConditions'
		)}/${type}${undefined !== duration ? `/${duration}` : ''}`
	)
	useNavigationTitle(<Trans i18nKey="view.riskBenefit.pageTitle.default" />)

	const { t } = useTranslation()
	const auth = useAuth()
	const api = useApi()
	const queryClient = useQueryClient()

	const { data } = useQuery(QueryKey.riskOptionsYears, api.getRiskOptionsYears)

	const years: number[] | undefined = useMemo(() => {
		if (undefined === data) {
			return
		}

		const availableYears = {
			1: RiskOptions.disabilityBenefit === riskId ? data.OPTEOneYear : data.OPTTOneYear,
			3: RiskOptions.disabilityBenefit === riskId ? data.OPTEThreeYear : data.OPTTThreeYear,
		}

		return availableYears[duration || 1]
	}, [data, duration, riskId])

	const onClickHandler = useCallback(async () => {
		const mfaResponse = await api.setRiskOption({ riskId, type: type!, duration })
		if (undefined !== (mfaResponse as MfaResponseData).transactionId) {
			auth.addMfaAction({
				transactionId: (mfaResponse as MfaResponseData).transactionId,
				onSuccess: () => {
					queryClient.prefetchQuery(QueryKey.riskOptionsAvailable)
					queryClient.removeQueries(QueryKey.riskOptions)
					navigateTo(getChildPath(routesDictionary.riskBenefit, 'RBSuccess'), true)
				},
			})
		}
		return mfaResponse
	}, [riskId, type, duration, api, auth, getChildPath, navigateTo, queryClient])

	return (
		<div className="rb-summary risk-benefit center-view grid grid--center-scroll">
			<h3 className="text-align--left grid__top">
				<Trans i18nKey="view.riskBenefit.summary.headline" />
			</h3>

			<div className="grid__center">
				<p className="margin--vertical">
					<b>
						<Trans i18nKey="view.riskBenefit.summary.subheadline" />
					</b>
				</p>

				<p>
					<Trans i18nKey="view.riskBenefit.summary.bodytext" />
				</p>

				<ul className="risk-benefit__list text-color-cyan margin--vertical">
					<li>
						<span>
							<Trans i18nKey={`view.riskBenefit.types.${type}.summary.${riskId}`} />
						</span>
					</li>
					{years && (
						<li>
							<span>
								<Trans
									i18nKey="view.riskBenefit.changeForCalendarYear"
									count={years.length}
									values={{
										years: !duration
											? `${t('generic.from')} ${years.join(', ')}`
											: years.join(', '),
									}}
								/>
							</span>
						</li>
					)}
				</ul>
			</div>

			<div className="grid__bottom">
				<Button onClick={onClickHandler} type={ButtonType.primary} label={t('generic.submit')} />
			</div>
		</div>
	)
}

export default RBSummary
