import { IconType } from 'components/Icons'
import useApi, { QueryKey } from 'hooks/useApi'
import { t } from 'i18next'
import { FC, useEffect, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'
import { uid } from 'react-uid'
import Button, { ButtonType } from 'shared/components/Button'
import Icon, { IconSize } from 'shared/components/Icon'
import SelectInput from 'shared/components/SelectInput'
import useGlobalModalState from 'shared/hooks/useGlobalModalState'
import { components } from 'types/api-interface'
import { PayoutDocumentUpload } from './PayoutDocumentUpload'
import { checkInsuranceConfirmationRequired, checkPensionConfirmationRequired } from './PayoutDocuments'
import { PayoutDocumentEntry, TDocumentEntryStates } from './PayoutDocumentsEntry'

export type TDocument = {
	type?: components['schemas']['DocumentType']
	mimeType?: components['schemas']['MimeType']
	file?: any
	name?: string
	page?: number
	status: TDocumentEntryStates
	uploadUrl?: string
}

const blankEntryState: TDocument = {
	type: undefined,
	mimeType: undefined,
	file: undefined,
	page: undefined,
	status: 'FIRSTUPLOAD',
	uploadUrl: undefined,
}

export interface IPayoutDocumentsModal {
	type: components['schemas']['DocumentType']
}

const PayoutDocumentsModal: FC<IPayoutDocumentsModal> = (props) => {
	const [type, setType] = useState<components['schemas']['DocumentType']>(props.type)
	const [uploadInProgress, setUploadInProgress] = useState(false)
	const [, modalActions] = useGlobalModalState()
	const [filesizeExeeded, setFilesizeExeeded] = useState<boolean>()
	const api = useApi()

	// init
	useEffect(() => {
		modalActions.setHeader(t('view.payoutOptions.chapterThree.upload.headline'))
		modalActions.setHideButton()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const { data } = useQuery(QueryKey.payoutModelInProgress, api.getPayoutModelInProgress)

	const [documents, setDocuments] = useState<Array<TDocument>>([
		{
			type: undefined,
			mimeType: undefined,
			file: undefined,
			page: undefined,
			status: 'FIRSTUPLOAD',
			uploadUrl: undefined,
		},
	])

	const onAbort = () => {
		setUploadInProgress(false)
		setDocuments([
			{
				type: undefined,
				mimeType: undefined,
				file: undefined,
				page: undefined,
				status: 'FIRSTUPLOAD',
				uploadUrl: undefined,
			},
		])
	}

	/**
	 * which document types are currently not supposed to be uploaded
	 */
	const forbiddenUploadTypes: Array<components['schemas']['DocumentType']> = useMemo(() => {
		const types = [
			!checkInsuranceConfirmationRequired(data) && 'KRANKENVERSICHERUNG',
			!checkPensionConfirmationRequired(data) && 'RENTENBESCHEINIGUNG',
		]

		const filteredTypes = types.filter((t) => t) as components['schemas']['DocumentType'][]

		return filteredTypes
	}, [data])

	const onFileSelect = async (file: File, index: number, type: components['schemas']['DocumentType']) => {
		const MAX_FILE_SIZE = 16 * 1048576 //megabytes * bytes - 16 Megabytes

		if (file.size > MAX_FILE_SIZE) {
			setFilesizeExeeded(true)
			return
		}

		if (!['image/jpeg', 'application/pdf', 'image/png'].includes(file.type)) {
			console.log('incorrect mime type')
			return
		}

		const documentsUpdate = [...documents]

		documentsUpdate[index] = {
			status: 'DISPLAY',
			file: file,
			name: file.name,
			page: index,
			type: type,
			mimeType: file.type as components['schemas']['MimeType'],
		}

		// add empty element
		documentsUpdate.push({ ...blankEntryState, status: 'UPLOAD' })
		setFilesizeExeeded(false)
		setDocuments(documentsUpdate)
	}

	const onDelete = (index: number) => {
		if (!documents[index]) {
			return
		}

		const documentsUpdate: Array<TDocument | null> = [...documents]
		documentsUpdate[index] = null

		const filteredDocuments = documentsUpdate.filter((v) => {
			return v !== null
		})

		// Add blank state element if list is empty
		if (filteredDocuments.length === 0) {
			filteredDocuments.push({
				...blankEntryState,
			})
		} else if (filteredDocuments.length === 1) {
			filteredDocuments[filteredDocuments.length - 1] = {
				...blankEntryState,
			}
		}

		if (filteredDocuments.includes(null)) {
			return
		}

		setDocuments(filteredDocuments as any)
	}

	const docsWithFiles = documents.filter((v) => v.file)
	const allowUploads = docsWithFiles.length > 0

	if (uploadInProgress) {
		return (
			<PayoutDocumentUpload
				docType={type}
				onAbort={onAbort}
				documents={docsWithFiles}
				onPrimaryButtonClick={() => {
					modalActions.closeModal()
				}}
			></PayoutDocumentUpload>
		)
	}

	const selectOptions: { label: string; value: string }[] = [
		{
			label: t('view.payoutOptions.chapterThree.documents.pension.documentName'),
			value: 'RENTENBESCHEINIGUNG',
		},
		{
			label: t('view.payoutOptions.chapterThree.documents.insurance.documentName'),
			value: 'KRANKENVERSICHERUNG',
		},
		{
			label: t('view.payoutOptions.chapterThree.documents.bankAccount.documentName'),
			value: 'BANKVERBINDUNG',
		},
	].filter((option) => {
		if (!forbiddenUploadTypes.length || props.type === option.value) {
			return true
		}

		return forbiddenUploadTypes.includes(option.value as components['schemas']['DocumentType']) ? false : true
	})

	return (
		<div className="payout-documents-modal-content">
			<SelectInput
				options={selectOptions}
				onChange={(value: any) => {
					setType(value)
				}}
				value={props.type}
				className={'select-with-background'}
				useCustom={true}
				label={
					<>
						<Trans i18nKey="view.payoutOptions.chapterThree.upload.documentType"></Trans>
					</>
				}
				alternativeStyle={true}
			/>

			{documents.map((v, i) => (
				<PayoutDocumentEntry
					key={uid(v)}
					name={v.name}
					state={v.status}
					file={v.file}
					isStaged={true}
					page={i}
					onFileSelect={onFileSelect}
					type={type}
					onDeleteClick={onDelete}
				/>
			))}

			{documents.length === 1 && (
				<p
					className="margin--horizontal
				"
				>
					<Trans i18nKey="view.payoutOptions.chapterThree.upload.bodyText"></Trans>
				</p>
			)}

			{filesizeExeeded && (
				<p className="padding">
					<Icon type={IconType.alert} size={IconSize.medium} className="margin"></Icon>
					<br />
					<Trans i18nKey="view.payoutOptions.chapterThree.upload.sizeExceeded"></Trans>
				</p>
			)}

			<Button
				disabled={!allowUploads}
				className="upload-button"
				type={ButtonType.primary}
				onClick={() => {
					setUploadInProgress(true)
				}}
			>
				<Trans i18nKey="view.payoutOptions.chapterThree.upload.uploadNow"></Trans>
			</Button>
		</div>
	)
}

export default PayoutDocumentsModal
