const getPdfsPath = (): string => {
  return `${process.env.REACT_APP_BAV_STATIC_WEB_ASSETS}/pdfs`
}

export const getMusterZfCardUrl = (): string => {
  return `${getPdfsPath()}/Muster_ZFCard.pdf`;
}

export const getMusterInitPasswortUrl = (): string => {
  return `${getPdfsPath()}/Muster_InitPasswort.pdf`;
}

export const getMusterIdPinUrl = (): string => {
  return `${getPdfsPath()}/Muster_ID-PIN.pdf`;
}

export const getZfVorsorgeRegistrierungsanleitungUrl = (): string => {
  return `${getPdfsPath()}/ZF-Vorsorge_Registrierungsanleitung.pdf`;
}
