import { IconType } from 'components/Icons'
import { DefaultTFuncReturn } from 'i18next'
import React, { forwardRef, FunctionComponent, ReactElement, useState } from 'react'
import { useUniqueId } from '../hooks/useUniqueInputId'
import Icon from './Icon'

export interface RadiobuttonGroupProps {
	name?: string
	label?: string | React.ReactElement | DefaultTFuncReturn
	value?: string
	options: RadiobuttonProps[]
	hidden?: boolean
	disabled?: boolean
	required?: boolean
	className?: string
}
export interface RadiobuttonProps {
	index?: number
	name?: string
	value?: string | number
	label?: string | React.ReactElement | DefaultTFuncReturn
	required?: boolean
	className?: string
	onClick?: any
	onChange?: any
	checkOnClick?: boolean
	errorMessage?: string | DefaultTFuncReturn
	checked?: boolean
	disabled?: boolean
	hidden?: boolean
	ref?: any
	radioPosition?: 'left' | 'right'
	highlight?: boolean
}

const Radiobutton: FunctionComponent<RadiobuttonProps> = forwardRef<HTMLInputElement, RadiobuttonProps>(
	({ radioPosition = 'left', ...props }, ref) => {
		const id = useUniqueId('radiobutton')

		const [attributes] = useState({
			...props,
			value: undefined,
			className: undefined,
			label: undefined,
			highlight: undefined,
			checked: undefined,
			defaultChecked: props.checked,
			id: props.label ? id : undefined,
		})

		const label = (): ReactElement | undefined => {
			if (!props.label) {
				return
			}

			return (
				<label className="input__label" htmlFor={id}>
					{props.label}
				</label>
			)
		}

		const pseudoLabel = (): ReactElement => {
			return (
				<label className="input__pseudo-radiobutton" htmlFor={id}>
					<Icon type={IconType.animatedRadiobutton} className="radiobutton__svg" />
				</label>
			)
		}

		const getClasses = (): string => {
			const classes = ['radiobutton']

			if (props.className) {
				classes.push(props.className)
			}

			if (props.highlight) {
				classes.push('radiobutton--highlighted')
			}

			if (true === props.checked) {
				classes.push('radiobutton--checked')
			}

			if (props.disabled) {
				classes.push(`radiobutton--disabled`)
			}

			if (radioPosition) {
				classes.push(`radiobutton--position-${radioPosition}`)
			}

			return classes.join(' ')
		}

		return (
			<label className={getClasses()} onClick={props.onClick}>
				<input
					ref={ref}
					{...attributes}
					type="radio"
					value={props.value}
					disabled={props.disabled}
					className="input__tag"
				/>
				{pseudoLabel()}
				{label()}
			</label>
		)
	}
)

export default Radiobutton
