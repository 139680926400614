import { FunctionComponent, useState } from 'react'
import { Trans } from 'react-i18next'
import Button, { ButtonType } from 'shared/components/Button'
import Checkbox from 'shared/components/Checkbox'
import Icon from 'shared/components/Icon'
import { IconType } from '../Icons'

const DeclineTermsAndConditions: FunctionComponent<{ onBack: () => void; onDecline: () => void }> = ({
	onBack,
	onDecline,
}) => {
	const [checked, setChecked] = useState(false)

	return (
		<div className="faq-changed-modal padding">
			<Icon type={IconType.alert} color={'var(--color-cyan)'}></Icon>
			<h3 className="text-color-orange ">
				<Trans i18nKey={'component.declineTermsAndConditions.headline'}></Trans>
			</h3>
			<Trans i18nKey={'component.declineTermsAndConditions.bodyText'}></Trans>
			<Button className="margin--top text-color-cyan" type={[ButtonType.secondary]} onClick={onBack}>
				<Trans i18nKey={'component.declineTermsAndConditions.goBack'}></Trans>
			</Button>{' '}
			<Checkbox
				className="margin--vertical flex flex--justify-content-center margin--large faq-changed-modal__checkbox"
				onChange={(e: any) => {
					setChecked(e?.target?.checked ? true : false)
				}}
				checked={false}
				label={
					<p className="no-margin">
						<Trans i18nKey={'component.declineTermsAndConditions.checkboxLabel'}></Trans>
					</p>
				}
			></Checkbox>
			<div className="faq-changed-modal__button">
				<Button type={ButtonType.primary} disabled={!checked} onClick={onDecline}>
					<Trans i18nKey="generic.confirm"></Trans>
				</Button>{' '}
			</div>
		</div>
	)
}

export default DeclineTermsAndConditions
