import { pensionAssetsChartStyle } from 'components/ChartsTheme'
import { IconType } from 'components/Icons'
import useApi, { QueryKey } from 'hooks/useApi'
import { useUserGroup } from 'hooks/useUserGroup'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import routesDictionary from 'routes'
import Accordion from 'shared/components/Accordion'
import BigColorFigure from 'shared/components/BigColorFigure'
import Icon from 'shared/components/Icon'
import PensionAssetsChart from 'shared/components/PensionAssetsChart'
import Tooltip from 'shared/components/Tooltip'
import dateFormat from 'shared/helper/dateFormat'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { PensionAssetsData, TimeRangeData } from 'shared/interfaces'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'
import { components } from 'types/api-interface'
import { currencyFormat } from '../shared/helper/numberFormats'

const PensionAssets: React.FC = () => {
	const { getMainPath, navigateTo } = useRouteHelper()
	useBackButtonPath(getMainPath(routesDictionary.dashboard))
	useNavigationTitle(<Trans i18nKey="view.pensionAssets.pageTitle" />)
	const { t } = useTranslation()
	const api = useApi()
	const { isVO20 } = useUserGroup()

	const { data: blocksAccount }: { data?: components['schemas']['BausteinkontoOverview'] } = useQuery(
		api.getQuery(QueryKey.bausteinKonto)
	)

	const { data }: { data?: PensionAssetsData } = useQuery(api.getQuery(QueryKey.pensionAssets, undefined))

	const { data: referenceYears }: { data?: TimeRangeData[] } = useQuery(
		api.getQuery(QueryKey.pensionAssetsReferenceYears)
	)

	const [accordionState, setAccordionState] = useState<{ height?: string; open?: boolean }[]>([
		{ height: '0', open: false },
		{ height: '0', open: false },
	])

	const onAccordionClick = (index: number) => {
		const newState = accordionState
		newState[index].open = accordionState[index].open ? false : true
		setAccordionState({ ...newState })
	}

	const afterAccordionRender = (height: any, index: number) => {
		const newState = accordionState
		newState[index].height = height
		setAccordionState(accordionState)
	}

	const getOwnContributions = () => {
		if (!data) {
			return
		}

		if (data.ownContributionsAvwl && data.ownContributionsAvwl > 0) {
			return (
				<Accordion
					className="pension-assets__own-contributions"
					header={
						<>
							<BigColorFigure
								fill={pensionAssetsChartStyle.barColors[2]}
								value={currencyFormat(data.ownContributionsSum)}
							/>

							<span className="text-color-black font-size-s label">
								{t('view.pensionAssets.ownContributionsSum')}
							</span>
						</>
					}
					open={accordionState[1].open}
					onOpen={() => {
						onAccordionClick(1)
					}}
					afterRender={(height: any) => {
						afterAccordionRender(height, 1)
					}}
					iconColor="var(--color-black)"
				>
					<div className="sum">{currencyFormat(data.ownContributions)}</div>
					<div className="label">
						<Trans i18nKey="view.pensionAssets.ownContributions" />
					</div>
					<div className="sum">{currencyFormat(data.ownContributionsAvwl)}</div>
					<div className="label">
						<Trans i18nKey="view.pensionAssets.ownContributionsAvwl" />
					</div>
				</Accordion>
			)
		} else {
			return (
				<>
					<BigColorFigure
						fill={pensionAssetsChartStyle.barColors[2]}
						value={currencyFormat(data.ownContributionsSum)}
					/>
					<div
						style={{ color: pensionAssetsChartStyle.barColors[2] }}
						className="total-description text-color-black font-size-s"
					>
						<Trans i18nKey="view.pensionAssets.ownContributionsSum" />
					</div>
				</>
			)
		}
	}

	const hasBlocks =
		!blocksAccount?.ausfinanziert && blocksAccount?.bausteine?.length && blocksAccount?.bausteine?.length > 0

	const getFiguresJsx = () => {
		return (
			<div>
				<div className="pension-assets__date text-align--center margin--vertical margin--small flex flex--justify-content-center">
					<span className="text--bold-spaced flex flex--align-items-center ">
						{data && (
							<Trans
								i18nKey="generic.updatedAt"
								values={{
									date: dateFormat(data.updatedAt, {
										weekday: 'long',
										year: 'numeric',
										month: '2-digit',
										day: '2-digit',
									}),
								}}
							/>
						)}
						<Tooltip
							color="var(--color-cyan)"
							icon={IconType.info}
							headline={t('view.pensionAssets.tooltip.headline')}
							content={t('view.pensionAssets.tooltip.content')}
						/>
					</span>
				</div>
				{data && (
					<div className={`pension-assets__figures`}>
						{hasBlocks ? (
							<>
								<BigColorFigure
									fill={'	var(--color-black)'}
									value={currencyFormat((blocksAccount?.total || 0) + data.totalPensionAssets)}
								/>

								<div style={{ color: 'var(--color-black)' }} className="total-descriptionont-size-s">
									{' '}
									<div className="flex flex--justify-content-between">
										<span>
											<Trans i18nKey="view.pensionAssets.total" />
										</span>
									</div>
								</div>

								<BigColorFigure
									fill={'	var(--color-grey)'}
									value={currencyFormat(blocksAccount?.total)}
								/>

								<div
									style={{ color: 'var(--color-grey)' }}
									className="total-description text-color-grey font-size-s"
								>
									{' '}
									<div className="flex flex--justify-content-between">
										<span>
											<Trans i18nKey="view.pensionAssets.blocksAccount" />
											<br />
											<span className="font-size-xs">
												<Trans i18nKey="view.pensionAssets.notInGraph" />
											</span>
										</span>
										{isVO20 && (
											<Icon
												onClick={() => {
													navigateTo(getMainPath(routesDictionary.blocksAccount))
												}}
												className="margin--horizontal no-margin--left"
												type={IconType.info}
											/>
										)}
									</div>
								</div>
							</>
						) : (
							<></>
						)}
						<BigColorFigure
							fill={pensionAssetsChartStyle.barColors[0]}
							value={currencyFormat(data.totalPensionAssets)}
						/>
						<div
							style={{ color: pensionAssetsChartStyle.barColors[0] }}
							className="total-description text-color-cyan font-size-s"
						>
							<Trans i18nKey="view.pensionAssets.totalPensionAssets" />
						</div>
						<BigColorFigure
							fill={pensionAssetsChartStyle.barColors[1]}
							value={currencyFormat(data.guaranteedPensionAssets)}
						/>
						<div
							style={{ color: pensionAssetsChartStyle.barColors[1] }}
							className="total-description font-size-s"
						>
							<Trans i18nKey="view.pensionAssets.guaranteedPensionAssets" /> <br />
							<span className="font-size-xs">
								<Trans i18nKey="view.pensionAssets.guaranteedPensionAssetsInfo" />
							</span>
						</div>
						{getOwnContributions()}
					</div>
				)}
			</div>
		)
	}

	const getGraph = () => {
		/** Slice to to remove bausteinkonto from graph  */
		const pensionAssetsWithoutBausteinKonto = [data?.pensionAssets.slice(0, 3)]

		return (
			<div className="pension-assets__graph flex--grow">
				{data && pensionAssetsWithoutBausteinKonto && (
					<PensionAssetsChart data={data.pensionAssets} panToEnd={false} />
				)}
			</div>
		)
	}

	const getEmployerContributionEntitlementDisclaimer = () => {
		if (!data?.employerContributionEntitlementDisclaimer) {
			return
		}

		return (
			<div className="margin--vertical flex flex--align-items-start flex--justify-content-center">
				<Icon className="margin--horizontal no-margin--left" type={IconType.info} />
				<div>
					<Trans i18nKey="view.pensionAssets.employerContributionEntitlementDisclaimer" />
				</div>
			</div>
		)
	}

	const getWabcoVO2020Disclaimer = () => {
		if (!data?.wabcoVO20Disclaimer) {
			return
		}

		return (
			<div className="margin--vertical flex flex--align-items-start flex--justify-content-center">
				<Icon className="margin--horizontal no-margin--left" type={IconType.info} />
				<div>
					<Trans i18nKey="view.pensionAssets.WabcoVO20" />
				</div>
			</div>
		)
	}

	const getCSS = () => {
		return {
			'--accordion-content-height': `${accordionState[0].height?.toString()}px`,
			'--accordion-content-height-2': `${accordionState[1].height?.toString()}px`,
		} as React.CSSProperties
	}

	const getClasses = () => {
		const classes = ['pension-assets', 'center-view', 'flex', 'flex--direction-column']

		if (accordionState[0]?.open) {
			classes.push('accordion-1-open')
		}

		if (accordionState[1]?.open) {
			classes.push('accordion-2-open')
		}

		if (referenceYears && referenceYears.length > 1) {
			classes.push('pension-assets--with-reference-years')
		}

		return classes.join(' ')
	}

	return (
		<div className={getClasses()} style={getCSS()}>
			{getFiguresJsx()}

			{getWabcoVO2020Disclaimer()}
			{getEmployerContributionEntitlementDisclaimer()}
			{getGraph()}
		</div>
	)
}

export default PensionAssets
