/**
 * thanks to
 * https://gist.github.com/rikukissa/cb291a4a82caa670d2e0547c520eae53
 * https://medium.com/swlh/a-simple-react-hook-to-prompt-ios-users-to-install-your-wonderful-pwa-4cc06e7f31fa
 */

import { useEffect, useState } from 'react'
import { getLocalStorage, setLocalStorage } from 'shared/helper/localStorage'
import { checkClient, ClientList } from './useClientClasses'

interface IBeforeInstallPromptEvent extends Event {
	readonly platforms: string[]
	readonly userChoice: Promise<{
		outcome: 'accepted' | 'dismissed'
		platform: string
	}>
	prompt(): Promise<void>
}

function checkPrompt() {
	const today = new Date().getTime()
	const lastPrompt = Number(getLocalStorage('installPrompt'))
	const diff = today - lastPrompt
	const diffInDays = Math.round(diff / (1000 * 3600 * 24))
	const prompt = isNaN(diffInDays) || diffInDays > Number(process.env.REACT_APP_INSTALL_PROMPT_REMINDER_IN_DAYS)

	if (prompt && 'localStorage' in window) {
		setLocalStorage('installPrompt', today)
	}

	return prompt
}

export function useAddToHomescreenPrompt(): [IBeforeInstallPromptEvent | null, () => void, ClientList, boolean] {
	const [promptEvent, setPromptEvent] = useState<IBeforeInstallPromptEvent | null>(null)
	const [client, setClient] = useState({})
	// const [appInstalled, setAppInstalled] = useState<boolean>(false)
	const [showPrompt, setShowPrompt] = useState<boolean>(false)

	const promptToInstall = () => {
		if (promptEvent) {
			return promptEvent.prompt()
		}
		return Promise.reject(new Error('Tried installing before browser sent "beforeinstallprompt" event'))
	}

	useEffect(() => {
		setClient(checkClient())
		// return () => console.log('CLEANUP INSTALL PROMPT', client)
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		setShowPrompt(checkPrompt())
		// return () => console.log('CLEANUP INSTALL PROMPT', showPrompt)
		// eslint-disable-next-line
	}, [])

	// useEffect(() => {
	// 	const installed = (e: any) => {
	// 		e.preventDefault()
	// 		setAppInstalled(true)
	// 	}

	// 	window.addEventListener('appinstalled', installed as any)
	// 	// Track event: The app was installed (banner or manual installation)
	// 	return () => {
	// 		window.removeEventListener('appinstalled', installed as any)
	// 	}
	// 	// eslint-disable-next-line
	// }, [])

	useEffect(() => {
		const ready = (e: IBeforeInstallPromptEvent) => {
			e.preventDefault()
			setPromptEvent(e)
		}

		window.addEventListener('beforeinstallprompt', ready as any)

		return () => {
			window.removeEventListener('beforeinstallprompt', ready as any)
		}
		// eslint-disable-next-line
	}, [])

	return [promptEvent, promptToInstall, client, showPrompt]
}
