import { HintsBox } from 'components/HintsBox'

import useAuthApi from 'hooks/useAuthApi'

import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { trackPromise } from 'react-promise-tracker'
import routesDictionary from 'routes'
import Form, { FormFields, FormFieldType } from 'shared/components/Form'
import PasswordStrength from 'shared/components/PasswordStrength'
import { TextInputType } from 'shared/components/TextInput'
import { NoNullField } from 'shared/helper/types/noNullField'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { ChangeUserPasswordArguments, ChangeUserPasswordErrors } from 'shared/interfaces'
import { useBackButtonClickHandler } from 'state/useHeaderState'

const ChangePassword: React.FunctionComponent = () => {
	const [newPassword, setNewPassword] = useState<string | undefined>()
	const [passwordIsValid, setPasswordIsValid] = useState<boolean>(false)

	const { navigateTo, getChildPath } = useRouteHelper()

	useBackButtonClickHandler({
		name: navigateTo,
		parameters: '/profile/overview',
	})
	const { t } = useTranslation()
	const { changeUserPassword } = useAuthApi()

	const showConfirmScreen = () => {
		navigateTo(getChildPath(routesDictionary.profile, 'changePasswordSuccess'))
	}

	const handleOnSubmit = async (submittedFields: Record<string, string>) => {
		const { oldPassword, newPassword, confirmNewPassword } = submittedFields
		const values = [newPassword, confirmNewPassword]
			.filter((v) => v)
			.map((v) => (typeof v === 'string' ? v.trim().replace(' ', '') : null))

		if (values.length !== 2) {
			return false
		}

		if (values[0] !== values[1]) {
			return {
				errorMessages: [t(`generic.form.errors.newPassword.DO_NOT_MATCH`)],
				successful: false,
			}
		}

		if (passwordIsValid === false) {
			return {
				errorMessages: [t(`generic.form.errors.newPassword.INVALID`)],
				successful: false,
			}
		}

		const response = await trackPromise(changeUserPassword({ oldPassword, newPassword }), 'change-password')

		if (!response) {
			return false
		}

		const returnValue = {
			successful: true,
			errorMessages: [],
		}

		if (response.errors) {
			const errors = response.errors as NonNullable<NoNullField<typeof response['errors']>>

			;['oldPassword', 'newPassword'].forEach((key) => {
				if (key in errors) {
					const fieldError =
						errors[key as Extract<keyof ChangeUserPasswordArguments, keyof ChangeUserPasswordErrors>]

					if (fieldError) {
						returnValue.successful = false
						returnValue.errorMessages.push(t(`generic.form.errors.${key}.${fieldError}`))
					}
				}
			})
		}

		return returnValue
	}

	const inputElements: FormFields = {
		oldPassword: {
			fieldType: FormFieldType.text,
			type: TextInputType.password,
			label: t('generic.form.fields.currentPassword.label'),
			required: true,
		},
		newPassword: {
			fieldType: FormFieldType.text,
			type: TextInputType.password,
			label: t('generic.form.fields.newPassword.label'),
			required: true,
			onChange: (e: any) => {
				setNewPassword(e.target.value)
			},
		},
		confirmNewPassword: {
			fieldType: FormFieldType.text,
			type: TextInputType.password,
			label: t('generic.form.fields.confirmNewPassword.label'),
			required: true,
		},
	}

	return (
		<div className="padding--left padding--right center-view profile-change-password">
			{/* <header className="margin--top margin--large">
				<div className="margin--bottom margin--default hidden-medium">
					<BackButton />
				</div>

				<div className="flex flex--align-items-center">
					<div className="text-color-gold margin--right bold-small-heading">
						<Trans i18nKey="view.profile.changePassword.smallHeadline" />
					</div>
					<hr className="hr hr--gold flex--grow" />
				</div>
			</header> */}
			{/* <div className="flex flex--align-items-center">
				<h1 className="margin--left">
					<Trans i18nKey="view.profile.changePassword.headline"></Trans>
				</h1>
			</div> */}

			{/* <p>
				<Trans i18nKey="view.profile.changePassword.copytext"></Trans>
			</p>

			<hr className="margin--vertical margin--large" /> */}

			<h3>
				<Trans i18nKey="view.profile.changePassword.form.headline"></Trans>
			</h3>

			<p className="margin--bottom margin--large">
				<Trans i18nKey="view.profile.changePassword.form.copytext"></Trans>
			</p>

			<div className="margin--top  max-content-width flex--l flex--grow gap gap--extra-large">
				<Form
					fields={inputElements}
					alwaysAllowSubmit
					submitLabel={t('generic.save')}
					onSubmit={handleOnSubmit}
					onSuccess={showConfirmScreen}
					promiseTracker={{ area: 'change-password' }}
				>
					{' '}
					<HintsBox className="margin--bottom margin--top">
						<p className="no-margin--top bold">
							<Trans i18nKey="component.passwordStrength.hint" />
						</p>
						<PasswordStrength
							password={newPassword}
							onIsPasswordValid={setPasswordIsValid}
						></PasswordStrength>
					</HintsBox>
				</Form>
			</div>
		</div>
	)
}

export default ChangePassword
