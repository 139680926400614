import { IconType } from 'components/Icons'
import useApi, { QueryKey } from 'hooks/useApi'
import { useUserGroup } from 'hooks/useUserGroup'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { uid } from 'react-uid'
import routesDictionary from 'routes'
import { AccordionProps } from 'shared/components/Accordion'
import AccordionGroup from 'shared/components/AccordionGroup'
import Button, { ButtonType } from 'shared/components/Button'
import Icon from 'shared/components/Icon'
import SwitchSelect from 'shared/components/SwitchSelect'
import dateFormat from 'shared/helper/dateFormat'
import getFirstDayOfNextMonth from 'shared/helper/getFirstDayOfNextMonth'
import { currencyFormat } from 'shared/helper/numberFormats'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'
import { components } from 'types/api-interface'

enum modeOptions {
	TODAY = 'today',
	PENSION_AGE_OR_LEFT_WITH_ENTITLEMENT = 'pensionAgeOrLeftWithEntitlement',
}

const BlocksAccount = () => {
	const { getMainPath, navigateTo } = useRouteHelper()
	useBackButtonPath(getMainPath(routesDictionary.dashboard))
	useNavigationTitle(<Trans i18nKey="view.accountBlocks.navigationName" />)

	const api = useApi()
	const [mode, setMode] = useState(modeOptions.TODAY)
	const [showManager, setShowManagerBlock] = useState(false)
	const { t } = useTranslation()
	const { isLeftWithEntitlement } = useUserGroup()
	const { status, data }: { status: string; data?: components['schemas']['BausteinkontoOverview'] } = useQuery(
		api.getQuery(QueryKey.bausteinKonto)
	)

	const isRetirementAgeReached = data ? data.today >= getFirstDayOfNextMonth(data.retirementDate) : false
	const ausfinanziert = data?.ausfinanziert

	useEffect(() => {
		if (data && (isRetirementAgeReached || isLeftWithEntitlement)) {
			setMode(modeOptions.PENSION_AGE_OR_LEFT_WITH_ENTITLEMENT)
		}
	}, [data, isLeftWithEntitlement, isRetirementAgeReached])

	const getChildBlocks = (v: components['schemas']['BausteinkontoBausteinValue']) => {
		const { label, total, totalAtRetirementDate } = v
		return (
			<div key={uid(v)} className="account-blocks__child-row">
				<div className="account-blocks__today-child">
					<div className="account-blocks__block-label">
						{' '}
						<Trans i18nKey={`view.accountBlocks.blocks.${label}`}></Trans>
					</div>
					{
						!(isRetirementAgeReached || isLeftWithEntitlement) ?
							<div className="account-blocks__block-amount hide-if-pension">{currencyFormat(total)}</div> : ""
					}
				</div>
				<div className="account-blocks__pension-child hide-if-today">
					{
						(isRetirementAgeReached || isLeftWithEntitlement) ?
							<div className="account-blocks__block-amount">{currencyFormat(total)}</div> :
							<div className="account-blocks__block-amount">{currencyFormat(totalAtRetirementDate)}</div>
					}
				</div>
			</div>
		)
	}

	const getBlocks = (v: components['schemas']['BausteinkontoBausteinValue']): AccordionProps | undefined => {
		const { total, label, totalAtRetirementDate, bausteine } = v

		if (!bausteine || bausteine.length === 0) {
			return undefined
		}

		/* show the disclaimer if a fuehrungskraefte bausteine is provided*/
		if (!showManager && label === 'fuehrungskraefte') {
			setShowManagerBlock(true)
		}

		return {
			header: (
				<div className="account-blocks__parent-row">
					<div className="account-blocks__today-parent">
						<div className="account-blocks__block-label">
							<Trans
								i18nKey={`view.accountBlocks.blocks.${label === 'sonstige' && !showManager ? 'sonstigeNoFuehrungskraefte' : label
									}`}
							></Trans>
						</div>
						{
							!(isRetirementAgeReached || isLeftWithEntitlement) ?
								<div className="account-blocks__block-amount hide-if-pension">{currencyFormat(total)}</div> : ""
						}
					</div>
					<div className="account-blocks__pension-parent hide-if-today">
						{
							(isRetirementAgeReached || isLeftWithEntitlement) ?
								<div className="account-blocks__block-amount">{currencyFormat(total)}</div> :
								<div className="account-blocks__block-amount ">{currencyFormat(totalAtRetirementDate)}</div>
						}

					</div>
				</div>
			),
			content: bausteine?.map((v) => getChildBlocks(v)),
		}
	}

	const options: Array<AccordionProps> | undefined =
		(data &&
			([...data.bausteine?.map((v) => getBlocks(v))].filter((el) => el !== undefined) as AccordionProps[])) ||
		[]

	const renderDisclaimer = () => {
		return (
			<div className="margin--vertical flex flex--align-items-start flex--justify-content-center">
				<Icon className="margin--horizontal no-margin--left" type={IconType.info} />
				<Trans i18nKey="view.accountBlocks.noData" />
			</div>
		)
	}

	const getAusfinanzierungDisclaimer = () => {
		return (
			<p className="disclaimer-ausfinanzierung">
				<Trans i18nKey="view.accountBlocks.paidOut"></Trans>
			</p>
		)
	}

	const getTableHeader = () => {
		return (
			<>
				{!(isRetirementAgeReached || isLeftWithEntitlement || ausfinanziert) ?
					<div className="account-blocks__switch">
						<SwitchSelect
							value={modeOptions.TODAY}
							options={[
								{
									label: t(`view.accountBlocks.switch.${modeOptions.TODAY}`),
									value: modeOptions.TODAY,
								},
								{
									label: (
										<Trans
											values={{ age: data?.retirementAge }}
											i18nKey={`view.accountBlocks.switch.${modeOptions.PENSION_AGE_OR_LEFT_WITH_ENTITLEMENT}`}
										/>
									),
									value: modeOptions.PENSION_AGE_OR_LEFT_WITH_ENTITLEMENT,
								},
							]}
							onChange={(e) => {
								setMode(e.target.value as modeOptions)
							}}
						></SwitchSelect>
					</div> : ""
				}
				<div className="account-blocks__table-description-header">
					<div className="account-blocks__table-description-today">
						{!(isRetirementAgeReached || isLeftWithEntitlement || ausfinanziert) && mode === 'today' ?
							<>
								<h4 className="text-color-cyan bold">
									<Trans i18nKey="view.accountBlocks.currentBalanceHeadline"></Trans>
								</h4>
								<p>
									<Trans
										i18nKey="view.accountBlocks.currentBalanceText"
										values={{ date: `${dateFormat(new Date(data?.today || 0))}` }}
									></Trans>
								</p>
							</> : ""

						}
					</div>
					<div className="account-blocks__table-description-pension hide-if-today">
						{!(isRetirementAgeReached || isLeftWithEntitlement || ausfinanziert) ?
							<h4 className="text-color-cyan bold">
								<Trans
									i18nKey="view.accountBlocks.pensionBalanceHeadline"
									values={{ age: data?.retirementAge }}
								></Trans>
							</h4>
							:
							<h4 className="text-color-cyan bold">
								<Trans i18nKey="view.accountBlocks.currentBalanceHeadline"></Trans>
							</h4>
						}
						{!(isRetirementAgeReached || isLeftWithEntitlement || ausfinanziert) ?
							<p>
								<Trans
									i18nKey="view.accountBlocks.pensionBalanceText"
									values={{
										age: data?.retirementAge,
										pensionDate: `${dateFormat(new Date(data?.retirementDate || 0))}`,
									}}
								></Trans>
							</p>
							: (isLeftWithEntitlement && !ausfinanziert) ?
								<p>
									<Trans
										i18nKey="view.accountBlocks.pensionAndCurrentBalanceText"
										values={{
											date: `${dateFormat(new Date(data?.today || 0))}`,
											age: data?.retirementAge,
											pensionDate: `${dateFormat(
												new Date(data?.retirementDate || 0)
											)}`,

										}}
									/>
								</p> :
								<p>
									<Trans i18nKey="view.accountBlocks.currentBalanceText"
										values={{
											date: `${dateFormat(new Date(data?.today || 0))}`,
										}}
									/>
								</p>
						}
					</div>
				</div>
			</>
		)
	}

	return (
		<div
			className={`grid  account-blocks account-blocks account-blocks--${mode} ${!(isRetirementAgeReached || isLeftWithEntitlement || ausfinanziert) ? '' : 'account-blocks--payout'
				}`}
		>
			<div className="grid__top">

				{status === 'success' && (data === undefined || data.bausteine.length === 0) ? renderDisclaimer() :
					<div className="grid__center  ">
						<div>
							<div className="account-blocks__intro">
								<p className="text-color-cyan">
									<Trans i18nKey="view.accountBlocks.intro"></Trans>
								</p>
							</div>
							<div className="payout-info">
								{ausfinanziert ? getAusfinanzierungDisclaimer() : ''}
							</div>
						</div>
						<div className="full-width">
							{getTableHeader()}

							<div className="account-blocks__table-header">
								<div className="account-blocks__table-header-today">
									<span className="text--bold-spaced text-align--left">
										<Trans i18nKey="view.accountBlocks.table.block"></Trans>
									</span>
									{
										!(isRetirementAgeReached || isLeftWithEntitlement) ?
											<span className="text--bold-spaced hide-if-today hide-if-pension">
												<Trans i18nKey="view.accountBlocks.table.amount"></Trans>
											</span> : ""
									}
								</div>
								<div className="account-blocks__table-header-pension ">
									<span className="text--bold-spaced">
										<Trans i18nKey="view.accountBlocks.table.amount"></Trans>
									</span>
								</div>
							</div>
							{data && (
								<>
									{options && <AccordionGroup allowMultipleOpen={true} options={options}></AccordionGroup>}
									<div className="account-blocks__sum moved-by-accordion">
										<div className="account-blocks__today-sum">
											<span className="h4 text-align--left">
												<Trans i18nKey="view.accountBlocks.sum"></Trans>
											</span>
											{
												!(isRetirementAgeReached || isLeftWithEntitlement) ?
													<span className="h4 hide-if-pension">{currencyFormat(data.total)}</span> : ""
											}
										</div>
										<div className="account-blocks__pension-sum hide-if-today">
											{
												(isRetirementAgeReached || isLeftWithEntitlement) ?
													<span className="h4">{currencyFormat(data.total)}</span> :
													<span className="h4">{currencyFormat(data.totalAtRetirementDate)}</span>
											}

										</div>
									</div>
									{showManager && (
										<div className="disclaimer">
											<p className="caption disclaimer moved-by-accordion">
												<Trans
													i18nKey="view.accountBlocks.disclaimer"
													values={{ age: data?.retirementAge }}
												></Trans>
											</p>
										</div>
									)}
								</>
							)}
							<div className="moved-by-accordion margin--bottom margin--large">
								<Button
									className="moved-by-accordion "
									type={ButtonType.primary}
									label={t('view.accountBlocks.button')}
									onClick={() => {
										navigateTo(getMainPath(routesDictionary.dashboard))
									}}
								/>
							</div>
						</div>
					</div>
				}
			</div>
		</div>
	)
}

export default BlocksAccount
