import { usePath, useQueryParams } from 'raviger'
import React, { useEffect, useRef, useState } from 'react'
import Icon from 'shared/components/Icon'
import VersionNumber from 'shared/components/VersionNumber'
import { RouteItem, useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useAuth } from '../hooks/useAuth'

const Navigation: React.FC = () => {
	const [queryParams] = useQueryParams()
	const auth = useAuth()
	const path = usePath()
	const exactMatch = useRef<number>(-1)
	const { navigateTo, filteredRoutesDictionary } = useRouteHelper()

	const [navigationOpen, toggleNavigation] = useState(false)

	useEffect(() => {
		exactMatch.current = -1
	})

	const handleClick = (route: RouteItem) => {
		toggleNavigation(!navigationOpen)
		navigateTo(route.path, false, route.queryParams || undefined)
	}

	const renderNavigationItem = (route: RouteItem, index: number) => {
		// remove leading slash for comparison
		const currentPath = path?.substring(1)
		const navigationItemPath = route.path.substring(1)

		let activeIcon = false

		if (exactMatch.current !== -1) {
			activeIcon = exactMatch.current === index
		} else {
			activeIcon =
				undefined !== currentPath &&
				(currentPath === navigationItemPath ||
					(navigationItemPath.length !== 0 && currentPath.startsWith(navigationItemPath)))
		}

		return (
			<div
				className={`navigation__item navigation__item--${navigationItemPath} ${
					activeIcon ? 'navigation__item--active' : ''
				}`}
				key={`navigation__item--${index}`}
			>
				<span className="navigation__link" onClick={() => handleClick(route)}>
					{route.icon && <Icon type={route.icon} className="navigation__icon" />}
					<span className="navigation__link-text">{route.navigationName || route.path}</span>
				</span>
			</div>
		)
	}

	const getHamburgerClasses = () => {
		const cssClasses = ['navigation__hamburger']

		cssClasses.push(!navigationOpen ? 'navigation__hamburger--open' : 'navigation__hamburger--close')

		return cssClasses.join(' ')
	}

	return (
		<div className="navigation">
			<label className="navigation__toggle" htmlFor="navigation-toggle">
				<div className={getHamburgerClasses()} />
			</label>

			<input
				className="hidden navigation__toggle-checkbox"
				type="checkbox"
				id="navigation-toggle"
				onChange={() => toggleNavigation(!navigationOpen)}
				checked={navigationOpen}
			/>

			<label className="navigation__background-toggle" htmlFor="navigation-toggle" />

			<nav className="navigation__items">
				{filteredRoutesDictionary
					.filter((item: RouteItem) => {
						/**
						 * - show only the items where showInNavigation is set to true
						 * - check if item should not be hidden if logged in
						 */
						return (
							item.showInNavigation &&
							((!auth.userData && !item.hideIfLoggedOut) || (auth.userData && !item.hideIfLoggedIn))
						)
					})
					.filter((route: RouteItem, index: number) => {
						const currentPath = path?.substring(1)

						if (-1 === exactMatch.current) {
							if (route.exactPath) {
								for (const exactPath of route.exactPath) {
									const samePath = currentPath ? exactPath.startsWith(currentPath) : false
									const routeQueryParams = new URLSearchParams(exactPath.split('?')[1])
									let sameQueryParams = true
									/**
									 * HINT:
									 * if it is required to support exactMatches without querystrings,
									 * this must be uncommented and tested if it works
									 */
									// if (undefined === routeQueryParams && true === samePath) {
									// exactMatch.current = index
									// return
									// }

									for (const key of routeQueryParams.keys()) {
										if (false === sameQueryParams) {
											break
										}

										sameQueryParams = queryParams.hasOwnProperty(key)
									}

									if (samePath && sameQueryParams) {
										exactMatch.current = index
									}
								}
							}
						}

						return route
					})
					.map(renderNavigationItem)}
				<VersionNumber />
			</nav>
		</div>
	)
}

export default Navigation
