import { useEffect } from 'react'
import convertToDashCase from 'shared/helper/convertToDashCase'

export interface ClientList {
	isIOS?: boolean
	isIPad?: boolean
	isIPhone?: boolean
	isSafariMobile?: boolean
	isAndroid?: boolean
	isDesktop?: boolean
	isInstalled?: boolean
}

export function checkClient(): ClientList {
	const ua = window.navigator.userAgent
	const webkit = !!ua.match(/WebKit/i)
	const isSafari = !!ua.match(/Safari/i) && !ua.match(/CriOS/i)
	const isIPhone = !!ua.match(/iPhone/i)

	/**
	 * the else-part is required for iPadOS with the setting
	 * "Request Desktop Website" set to true
	 */
	const isIPad = !isIPhone && (!!ua.match(/iPad/i) || (isSafari && 'standalone' in navigator))
	const isIOS = isIPad || isIPhone
	const isAndroid = !!ua.match(/android/i)
	const isDesktop = !isIOS && !isAndroid
	const isSafariMobile = isIOS && webkit && !ua.match(/CriOS/i)
	const isInstalled = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true

	return { isIOS, isIPad, isIPhone, isSafariMobile, isAndroid, isDesktop, isInstalled }
}

export const useClientClasses = () => {
	function getClasses() {
		const clients = checkClient()
		const classes = []

		for (const [client, isTrue] of Object.entries(clients)) {
			if (isTrue) {
				classes.push(`client-${convertToDashCase(client)}`)
			}
		}

		return classes
	}

	useEffect(() => {
		document.documentElement.classList.add(...getClasses())
	}, [])
}
