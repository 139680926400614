import { useUserGroup } from 'hooks/useUserGroup'
import i18n from 'i18n'
import React, { FunctionComponent, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Accordion from 'shared/components/Accordion'
import Icon from 'shared/components/Icon'
import Tooltip from 'shared/components/Tooltip'
import { CompensationDisclaimer, CompensationStatus, CompensationType, CompensationUnit } from 'shared/enums'
import { currencyFormat } from 'shared/helper/numberFormats'
import { BonusData } from 'shared/interfaces'
import { IconType } from '../../components/Icons'

export interface CompensationDataTableProps {
	ownContribution?: number
	bonus?: BonusData[]
	employerBaseAmount?: number
	postGrantEmployerBaseAmount?: number
	matchingAmount?: number
	postGrantMatchingAmount?: number
	monthlyCompensation?: number
	sum?: number
	yearlySum?: number
	status?: CompensationStatus
	disclaimer?: CompensationDisclaimer
	unit?: CompensationUnit
	hideDisclaimer?: boolean
	hideGrossAmount?: boolean
	viewDependentTranslations?: 'bonus' | 'salary' | 'conversion' | 'riskPreview'
	liesInThePast?: boolean
}

const CompensationDataTable: FunctionComponent<CompensationDataTableProps> = (props) => {
	const { t } = useTranslation()
	const { isHigherUp } = useUserGroup()

	const [accordionOpen, setAccordionOpen] = useState(false)
	const [accordionContentHeight, setAccordionContentHeight] = useState(0)

	const onAccordionClick = () => {
		setAccordionOpen(accordionOpen ? false : true)
	}

	const afterAccordionRender = (height: any) => {
		setAccordionContentHeight(height)
	}

	const getCSS = () => {
		return {
			'--accordion-content-height': `${accordionContentHeight.toString()}px`,
		} as React.CSSProperties
	}

	const compensationIsNotPerformed = CompensationStatus.open === props.status && props.liesInThePast

	const renderOwnContribution = () => {
		if ((undefined === props.monthlyCompensation && undefined === props.bonus) || props.ownContribution === 0) {
			return (
				<>
					<span className="text-color-cyan font-size-s compensation-data-table__currency">
						{currencyFormat(props.ownContribution)}
					</span>
					<span className="text-color-cyan font-size-s compensation-data-table__currency-label">
						{t('component.compensationDataTable.ownContribution')}
						<Tooltip
							color={'var(--color-cyan)'}
							headline={t('component.compensationDataTable.ownContributionTooltip.headline')}
							content={t('component.compensationDataTable.ownContributionTooltip.content')}
						/>
					</span>
				</>
			)
		}

		return (
			<Accordion
				className="compensation-data-table__own-contribution"
				header={
					<>
						<span className="text-color-cyan font-size-s compensation-data-table__currency">
							{currencyFormat(props.ownContribution)}
						</span>
						<span className="text-color-cyan font-size-s compensation-data-table__currency-label">
							{t('component.compensationDataTable.ownContribution')}
							<Tooltip
								color={'var(--color-cyan)'}
								headline={t('component.compensationDataTable.ownContributionTooltip.headline')}
								content={t('component.compensationDataTable.ownContributionTooltip.content')}
							/>
						</span>
					</>
				}
				open={accordionOpen}
				onOpen={() => {
					onAccordionClick()
				}}
				afterRender={(height: any) => {
					afterAccordionRender(height)
				}}
				iconColor="var(--color-cyan)"
			>
				{!!props.monthlyCompensation && (
					<>
						<span className="text-color-cyan font-size-s compensation-data-table__currency">
							{currencyFormat(props.monthlyCompensation)}
						</span>
						<span className="text-color-cyan font-size-s compensation-data-table__currency-label">
							{t('component.compensationDataTable.monthlyCompensation')}
						</span>
					</>
				)}

				{props.bonus &&
					props.bonus.filter((bonus) => bonus.value || bonus.confirmedValue).map((bonus, index) => {
						const unit = bonus.confirmed ? bonus.confirmedUnit : bonus.unit
						const value = bonus.confirmed ? bonus.confirmedValue : bonus.value
						return (
							<React.Fragment key={`bonus-${index}`}>
								<span className="text-color-cyan font-size-s compensation-data-table__currency">
									{CompensationUnit.euro === unit ? currencyFormat(value) : '-'}
								</span>
								<span className="text-color-cyan font-size-s compensation-data-table__currency-label">
									{t(`compensationType.${(CompensationType as any)[bonus.type || 'bonus']}.title`)}
									{CompensationUnit.percent === unit && (
										<>
											<br />
											<span className="font-size-xs">
												{t(`component.compensationDataTable.percentageCompensation`)}
											</span>
										</>
									)}
								</span>
							</React.Fragment>
						)
					})}
			</Accordion>
		)
	}

	const renderDisclaimer = useMemo(() => {
		let disclaimer: string

		switch (props.disclaimer) {
			case CompensationDisclaimer.expected:
				return null

			case CompensationDisclaimer.none:
				disclaimer = t('component.compensationDataTable.disclaimer.denied')
				break

			case CompensationDisclaimer.different:
				disclaimer = t('component.compensationDataTable.disclaimer.partial')
				break

			case CompensationDisclaimer.negative:
				disclaimer = t('component.compensationDataTable.disclaimer.negative')
				break

			default:
				disclaimer = t(`component.compensationDataTable.disclaimer.${props.unit}`)
		}

		if (compensationIsNotPerformed) {
			disclaimer = t('component.compensationDataTable.disclaimer.denied')
		}

		return (
			<p className="compensation-data-table__disclaimer flex flex--align-items-start">
				<Icon className="margin--horizontal no-margin--left" type={IconType.info} />
				{disclaimer}
			</p>
		)

		// eslint-disable-next-line
	}, [props.disclaimer, props.unit])

	return (
		<div className="compensation-data-table" style={getCSS()}>
			{undefined !== props.ownContribution && renderOwnContribution()}

			{undefined !== props.employerBaseAmount && (
				<>
					<span className="font-size-s compensation-data-table__currency">
						{currencyFormat(props.employerBaseAmount)}
					</span>

					<span className="font-size-s compensation-data-table__currency-label">
						{props.viewDependentTranslations &&
						i18n.exists(
							`component.compensationDataTable.employerBaseAmountInView.${props.viewDependentTranslations}`
						) ? (
							<Trans
								i18nKey={`component.compensationDataTable.employerBaseAmountInView.${props.viewDependentTranslations}`}
							/>
						) : (
							t('component.compensationDataTable.employerBaseAmount')
						)}

						<Tooltip
							headline={t('component.compensationDataTable.employerBaseAmountTooltip.headline')}
							content={t('component.compensationDataTable.employerBaseAmountTooltip.content')}
						/>
					</span>
				</>
			)}

			{undefined !== props.postGrantEmployerBaseAmount && (
				<>
					<span className="font-size-s compensation-data-table__currency">
						{currencyFormat(props.postGrantEmployerBaseAmount)}
					</span>

					<span className="font-size-s compensation-data-table__currency-label">
						<Trans i18nKey="component.compensationDataTable.postGrantEmployerBaseAmount" />
						<Tooltip
							headline={t('component.compensationDataTable.postGrantEmployerBaseAmountTooltip.headline')}
							content={t('component.compensationDataTable.postGrantEmployerBaseAmountTooltip.content')}
						/>
					</span>
				</>
			)}

			{undefined !== props.matchingAmount && (
				<>
					<span className="font-size-s compensation-data-table__currency">
						{currencyFormat(props.matchingAmount)}
					</span>
					<span className="font-size-s compensation-data-table__currency-label">
						{props.viewDependentTranslations &&
						i18n.exists(
							`component.compensationDataTable.matchingAmountInView.${props.viewDependentTranslations}`
						) ? (
							<Trans
								i18nKey={`component.compensationDataTable.matchingAmountInView.${props.viewDependentTranslations}`}
							/>
						) : (
							t('component.compensationDataTable.matchingAmount')
						)}

						<Tooltip
							headline={t('component.compensationDataTable.matchingAmountTooltip.headline')}
							content={t(
								`component.compensationDataTable.matchingAmountTooltip.${
									isHigherUp ? 'contentEMGGET' : 'content'
								}`
							)}
						/>
					</span>
				</>
			)}

			{undefined !== props.postGrantMatchingAmount && (
				<>
					<span className="font-size-s compensation-data-table__currency">
						{currencyFormat(props.postGrantMatchingAmount)}
					</span>
					<span className="font-size-s compensation-data-table__currency-label">
						<Trans i18nKey="component.compensationDataTable.postGrantMatchingAmount" />
						<Tooltip
							headline={t('component.compensationDataTable.postGrantMatchingAmountTooltip.headline')}
							content={t(`component.compensationDataTable.postGrantMatchingAmountTooltip.content`)}
						/>
					</span>
				</>
			)}

			{(undefined !== props.sum || undefined !== props.yearlySum) && (
				<div className="compensation-data-table__divider" />
			)}

			{undefined !== props.sum && (
				<>
					<b className="font-size-m compensation-data-table__currency">{currencyFormat(props.sum)}</b>
					<b className="font-size-s compensation-data-table__currency-label">
						{t('component.compensationDataTable.sum')}{' '}
						{undefined !== props.yearlySum && <>({t('generic.monthly')})</>}
					</b>
				</>
			)}

			{undefined !== props.yearlySum && (
				<>
					<b className="font-size-m compensation-data-table__currency">{currencyFormat(props.yearlySum)}</b>

					<b className="font-size-s compensation-data-table__currency-label">
						{t('component.compensationDataTable.sum')} ({t('generic.yearly')})
					</b>
				</>
			)}

			{true !== props.hideGrossAmount && (
				<p className="compensation-data-table__gross-amount font-size-xs">
					{t('component.compensationDataTable.allGrossAmounts')}
				</p>
			)}

			{true !== props.hideDisclaimer && renderDisclaimer}
		</div>
	)
}

export default CompensationDataTable
