import { IconType } from 'components/Icons'
import useApi, { QueryKey } from 'hooks/useApi'
import React, { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import SelectableBarGroup from 'shared/components/SelectableBarGroup'
import dateFormat from 'shared/helper/dateFormat'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { BonusMonthListData, CompensationTypeData } from 'shared/interfaces'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'

export enum BonusPayoutType {
	all = 'all',
	share = 'share',
}

export interface BonusPayout {
	type: BonusPayoutType
	title: string
	icon: IconType
	id: string
}

const SelectBonusMonth: React.FC<{ id: CompensationTypeData['id'] }> = ({ id }) => {
	const { getChildPath, navigateTo } = useRouteHelper()
	useBackButtonPath(getChildPath(routesDictionary.deferredCompensation, 'typeSelection'))
	useNavigationTitle(<Trans i18nKey="view.deferredCompensation.pageTitle.bonus" />)

	const { t } = useTranslation()
	const api = useApi()
	const [selectedOption, setSelectedOption] = useState<BonusMonthListData>()

	const { data } = useQuery([QueryKey.bonusMonth, id], api.getBonusMonthList)

	const onSubmitHandler = () => {
		if (undefined === selectedOption) {
			return
		}

		navigateTo(
			`${getChildPath(routesDictionary.deferredCompensation, 'selectBonusAmount')}/${id}/${selectedOption.month}`
		)
	}

	const selectableOptions = useMemo(() => {
		if (undefined === data) {
			return undefined
		}

		return data.map((element: BonusMonthListData) => {
			return {
				label: (
					<>
						{dateFormat(new Date(Date.UTC(0, element.month - 1)), { month: 'long' })}{' '}
						<span className="padding--horizontal font-size-s">{element.year}</span>
					</>
				),
				value: element.month,
				onClick: () => {
					setSelectedOption(element)
				},
			}
		})
	}, [data])

	return (
		<div className="deferred-compensation grid grid--center-scroll center-view">
			<h3 className="text-align--left grid__top">
				<Trans i18nKey="view.deferredCompensation.selectBonusMonth.headline" />
			</h3>

			{selectableOptions && (
				<SelectableBarGroup
					className="deferred-compensation__bonus-month"
					options={selectableOptions}
					selectedOption={{
						...selectedOption,
						value: selectedOption && selectedOption.month ? selectedOption.month : false,
					}}
				/>
			)}

			<Button
				className="grid__bottom"
				onClick={onSubmitHandler}
				type={ButtonType.primary}
				label={t('generic.next')}
				disabled={!selectedOption}
			/>
		</div>
	)
}

export default SelectBonusMonth
