import classNames from 'classnames'
import { AnchorHTMLAttributes, FunctionComponent, ImgHTMLAttributes, useMemo } from 'react'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'

interface IImage {
	route?: string
}

const Image: FunctionComponent<
	IImage & AnchorHTMLAttributes<HTMLAnchorElement> & ImgHTMLAttributes<HTMLImageElement>
> = ({ className, onClick, route, href, style, ...props }) => {
	const { navigateTo } = useRouteHelper()
	const Tag = (href ? 'a' : 'span') as keyof JSX.IntrinsicElements
	const hrefProps = useMemo(
		() =>
			href
				? {
						href,
						target: '_blank',
						rel: 'noopener noreferrer',
				  }
				: undefined,
		[href]
	)

	const handleOnClick = (e: any) => {
		if (route) {
			navigateTo(route)
		}

		if (onClick) {
			onClick(e)
		}
	}

	return (
		<Tag
			className={classNames('image', className, {
				'cursor--pointer': !!onClick || !!href,
			})}
			onClick={handleOnClick}
			{...hrefProps}
		>
			<img
				src={props.src}
				srcSet={props.srcSet}
				height={props.height}
				width={props.width}
				alt={props.alt || ''}
			/>

			{href && (
				<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="24" cy="24" r="24" fill="white" />
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M30.2938 24.1469C30.2938 28.0945 27.0932 31.2938 23.1469 31.2938C19.1981 31.2938 16 28.0945 16 24.1469C16 20.1993 19.1981 17 23.1469 17C27.0932 17 30.2938 20.1993 30.2938 24.1469V24.1469Z"
						stroke="#004E7E"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M28.3438 29.3446L35.9991 37"
						stroke="#004E7E"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path d="M21 24H25" stroke="#004E7E" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M23 22L23 26" stroke="#004E7E" strokeLinecap="round" strokeLinejoin="round" />
				</svg>
			)}
		</Tag>
	)
}

export default Image
