import { useUserGroup } from 'hooks/useUserGroup'
import React, { useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import { AccordionProps } from 'shared/components/Accordion'
import AccordionGroup from 'shared/components/AccordionGroup'
import Button, { ButtonType } from 'shared/components/Button'
import scrollTo from 'shared/helper/scrollTo'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'

interface FaqOptions {
	id?: string
	headline: string
	content?: string
	links: { id: string; label: string }[]
}

type FaqQuickLinks = {
	id: string
	label: string
}

const FAQ: React.FC = () => {
	const { getMainPath } = useRouteHelper()
	const { isHigherUp, isBoard } = useUserGroup()
	useBackButtonPath(getMainPath(routesDictionary.dashboard))
	useNavigationTitle(<Trans i18nKey="view.faq.pageTitle" />)

	const { t } = useTranslation()

	const questions: FaqOptions[] = t(`view.faq.array${isHigherUp ? 'EMGGET' : ''}`, { returnObjects: true })

	const faqRef = useRef<HTMLDivElement>(null)

	const questionRefs = useRef<{ [key: string]: number }>({})

	const [selectedItem, setSelectedItem] = useState<number>()

	const onSelectItem = (currentlySelectedItem: number) => {
		setSelectedItem(currentlySelectedItem)
	}

	const onQuickLinkClick = (selectedItemId: string) => {
		if (null === faqRef.current) {
			return
		}

		setSelectedItem(questionRefs.current[selectedItemId])

		const selectedItemNode = faqRef.current.querySelector(`#${selectedItemId}`) as HTMLDivElement
		const headerHeight = parseInt(getComputedStyle(document.querySelector('.header') as Element).height, 10)

		function getOffSetTop(element: HTMLElement | null): number {
			if (element) {
				const parentOffset = getOffSetTop(element.offsetParent as HTMLElement)
				return element.offsetTop + parentOffset
			}
			return 0
		}

		scrollTo(document.documentElement, getOffSetTop(selectedItemNode) - headerHeight)
	}
	const renderQuickLinks = (key: number, links: FaqQuickLinks[]) => {
		return links.map((link, index) => (
			<Button
				key={`${key}-${index}`}
				type={[ButtonType.secondary, ButtonType.small]}
				onClick={() => onQuickLinkClick(link.id)}
				label={link.label}
			/>
		))
	}

	const getAccordionData = () => {
		const options: AccordionProps[] = []

		for (const [key, question] of questions.entries()) {
			if (undefined !== question.id) {
				questionRefs.current = { ...questionRefs.current, [question.id]: key }
			}

			options.push({
				id: question.id,
				header: (
					<h4 className="flex">
						<span className="margin--right margin--small">{key + 1}</span>
						<span className="text-align--left">
							{t(`view.faq.array${isHigherUp ? 'EMGGET' : ''}.${key}.headline`, {
								interpolation: { escapeValue: false },
							})}
						</span>
					</h4>
				),
				content: (
					<div className="font-size-s">
						{question.content && (
							<Trans i18nKey={`view.faq.array${isHigherUp || isBoard ? 'EMGGET' : ''}.${key}.content`} />
						)}

						{question.links && (
							<>
								<h3 className="no-margin--bottom text--bold-spaced">
									{t('view.faq.quickLinksHeadline')}
								</h3>
								<div className="faq__quick-links flex flex--align-start flex--wrap">
									{renderQuickLinks(key, question.links)}
								</div>
							</>
						)}
					</div>
				),
			})
		}

		return options
	}

	return (
		<div ref={faqRef} className="faq">
			<AccordionGroup options={getAccordionData()} selectedItem={selectedItem} onSelectItem={onSelectItem} />
		</div>
	)
}

export default FAQ
