import { IconType } from 'components/Icons'
import useApi from 'hooks/useApi'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import Button, { ButtonType } from 'shared/components/Button'
import Icon from 'shared/components/Icon'
import { components } from 'types/api-interface'

export type TDocumentEntryStates = 'UPLOAD' | 'DISPLAY' | 'FIRSTUPLOAD'

export const PayoutDocumentEntry: FC<{
	state: TDocumentEntryStates
	type: components['schemas']['DocumentType']
	page: number
	isStaged?: boolean
	file: File | undefined
	fileId?: string
	name: string | undefined
	s3Path?: string
	uploadedDate?: string
	loadExternalThumbnail?: boolean
	numberOfPages?: number
	editable?: boolean
	onFileSelect: (file: File, index: number, type: components['schemas']['DocumentType']) => void
	onDeleteClick: (index: number) => void
}> = (props) => {
	const inputFile = useRef<HTMLInputElement | null>(null)
	// const [file, setFile] = useState<File | null>()
	const [image, setImage] = useState<string>()
	const [isThreat, setIsThreat] = useState<boolean>(false)
	const api = useApi()
	const isPdf = useMemo(() => {
		return props.file?.type === 'application/pdf'
	}, [props.file])

	const handleFileSelect = (file: File) => {
		props.onFileSelect(file, props.page, props.type)
	}

	const getThumbnail = async (path: string) => {
		const image = await api.getPayoutModelThumbnail(path)

		image && setImage(image.image)
	}

	const getThreadReport = async (id: string) => {
		const threatReport = await api.getThreadReport(id)

		if (threatReport?.isFileHarmful === true) {
			setIsThreat(true)
		}
	}

	useEffect(() => {
		if (!props.s3Path || !props.loadExternalThumbnail) {
			return
		}

		getThumbnail(props.s3Path)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.s3Path, props.loadExternalThumbnail])

	useEffect(() => {
		if (!props.fileId || !props.loadExternalThumbnail) {
			return
		}

		getThreadReport(props.fileId)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.fileId, props.loadExternalThumbnail])

	const status = props.state

	return (
		<>
			<input
				ref={inputFile}
				className="hidden-input"
				accept=".jpg,.pdf,.png"
				type="file"
				onChange={(e) => {
					if (e.target.files?.[0]) {
						handleFileSelect(e.target.files[0])
					}
				}}
			/>

			{status === 'FIRSTUPLOAD' && (
				<Button
					type={ButtonType.secondary}
					onClick={() => {
						inputFile.current?.click()
					}}
				>
					<Trans i18nKey="generic.addDocument" />
				</Button>
			)}

			{status === 'UPLOAD' && (
				<Button
					className="add-page"
					type={[ButtonType.secondary, ButtonType.small]}
					onClick={() => {
						inputFile.current?.click()
					}}
				>
					<Trans i18nKey="generic.addPage" />
				</Button>
			)}

			{status === 'DISPLAY' && (
				<div className="payout-document-entry">
					<div
						className={`payout-document-entry__image ${
							props.file || image ? 'payout-document-entry__image--has-thumbnail' : ''
						}`}
					>
						{isThreat && (
							<div className="sophos-mock">
								<span></span>
								<span></span>
								<span></span>
								<span></span>
								<span></span>
								<span></span>
								<span></span>
								<span></span>
								<span></span>
								<span></span>
							</div>
						)}
						{!isThreat && (
							<>
								{isPdf && props.isStaged && (
									<div className="pdf-preview">
										<Icon type={IconType.menuDocuments}></Icon>
										<h4>PDF</h4>
									</div>
								)}
								{props.file && !isPdf && (
									<img src={URL.createObjectURL(props.file)} alt="Dokument Vorschau" />
								)}
								{image && <img src={`data:image/jpeg;base64,${image}`} alt="Dokument Vorschau"></img>}
								{!props.file && !image && (
									<div className="payout-document-entry__loading-spinner"></div>
								)}
							</>
						)}
					</div>
					<div className="payout-document-entry__meta">
						{props.numberOfPages !== undefined ? (
							<p className="text-color-cyan">
								<b>
									<Trans i18nKey="generic.pages" />: {props.numberOfPages}
								</b>
							</p>
						) : (
							<p className="text-color-cyan">
								<b>
									{' '}
									<Trans i18nKey="generic.page" /> {props.isStaged ? props.page + 1 : props.page}
								</b>
							</p>
						)}

						{props.loadExternalThumbnail && (
							<p className="file-date">
								{' '}
								<Trans i18nKey="generic.uploaded" />
							</p>
						)}

						{props.uploadedDate && (
							<p className="file-date">{new Date(props.uploadedDate).toLocaleString()}</p>
						)}
						{props.editable && (
							<div
								tabIndex={0}
								className="delete"
								onClick={() => {
									props.onDeleteClick(props.page)
								}}
							>
								<Icon type={IconType.delete} />
							</div>
						)}
					</div>
				</div>
			)}
		</>
	)
}
