import { IconType } from 'components/Icons'
import TermsAndConditions from 'components/TermsAndConditions'
import { activatedFeatures } from 'helper/activatedFeatures'
import matchesPasswordPolicy from 'helper/matchesPasswordPolicy'
import useApi, { QueryKey } from 'hooks/useApi'
import { useAuth } from 'hooks/useAuth'
import { useLanguagePref } from 'hooks/useStoredLanguagePref'
import i18next from 'i18next'
import React, { useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import Form, { FormField, FormFields, FormFieldType } from 'shared/components/Form'
import Icon, { IconSize } from 'shared/components/Icon'
import Modal, { ModalRefActions } from 'shared/components/Modal'
import { ApiErrorCode } from 'shared/enums'
import dateFormat from 'shared/helper/dateFormat'
import { FormSubmitFields } from 'shared/hooks/useForm'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { ApiError, MfaResponseData, UserProfileData, UserProfileField } from 'shared/interfaces'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'
import TextInput, { TextInputType } from '../../shared/components/TextInput'
import PrivacyPolicy from 'components/PrivacyPolicy'

const ProfileOverview = (props: any, ref: any) => {
	const { getMainPath, navigateTo, getChildPath } = useRouteHelper()
	useBackButtonPath(getMainPath(routesDictionary.dashboard))
	useNavigationTitle(<Trans i18nKey="view.profile.pageTitle" />)

	const { t } = useTranslation()

	const api = useApi()
	const queryClient = useQueryClient()
	const auth = useAuth()
	const { data: profileData }: { data?: UserProfileData } = useQuery(api.getQuery(QueryKey.userProfile))
	const [errorMessages, setErrorMessages] = useState<string[]>()
	const { saveLanguageSetting } = useLanguagePref()
	const passwordModal = useRef<ModalRefActions>()
	const [passwordErrorMessages, setPasswordErrorMessages] = useState<string[]>()
	const removeConsentModal = useRef<ModalRefActions>()
	const termsAndConditionsModal = useRef<ModalRefActions>()

	const contactTermsModal = useRef<ModalRefActions>()
	const changeLanguageModal = useRef<ModalRefActions>()
	const successModal = useRef<ModalRefActions>()
	const wayOfParticipation = auth.userData?.wayOfParticipation

	const { isSuccess, data, refetch } = useQuery(QueryKey.cognitoUserAttributes, api.getUserAttributes)
	const oldPhone = data?.get('phone_number') || ''
	const oldEmail = data?.get('email') || ''

	useEffect(() => {
		console.log('refetch')
		refetch()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const fields: FormFields = {
		name: {
			required: true,
			type: TextInputType.text,
			icon: IconType.actionAccount,
			iconSize: IconSize.large,
			fieldType: FormFieldType.text,
			renderAsText: true,
		},
		versorgungsId: {
			required: true,
			type: TextInputType.number,
			label: t('view.profile.formFieldLabels.idNumber'),
			fieldType: FormFieldType.text,
		},
		gesellschaftseintritt: {
			required: false,
			type: TextInputType.text,
			label: t('view.profile.formFieldLabels.entryDate'),
			fieldType: FormFieldType.text,
			valueFunction: {
				name: (value, options) => (value ? dateFormat(value, options) : '-'),
			},
		},
		// email: {
		// 	required: true,
		// 	type: TextInputType.email,
		// 	label: t('view.profile.formFieldLabels.privateEmailAddress'),
		// 	fieldType: FormFieldType.text,
		// },
	}

	// const mailFormField = {
	// 	email: {
	// 		required: true,
	// 		type: TextInputType.email,
	// 		label: t('view.profile.formFieldLabels.privateEmailAddress'),
	// 		fieldType: FormFieldType.text,
	// 	},
	// }

	// const phoneFormField = {
	// 	phone: {
	// 		required: true,
	// 		type: TextInputType.tel,
	// 		label: t('view.profile.formFieldLabels.privateEmailAddress'),
	// 		fieldType: FormFieldType.text,
	// 	},
	// }

	const changeLanguageElements: FormFields = {
		languageSetting: {
			fieldType: FormFieldType.select,
			className: 'select-with-background',
			useCustom: true,
			label: t('view.profile.formFieldLabels.changeLanguage'),
			value: i18next.language,
			alternativeStyle: true,

			options: [
				{ label: t('generic.availableLanguages.de'), value: 'de-DE' },
				{ label: t('generic.availableLanguages.en'), value: 'en-EN' },
			],
			required: true,
		},
	}

	const passwordInputElements: FormFields = {
		oldPassword: {
			value: '',
			required: true,
			type: TextInputType.password,
			label: t('view.profile.formFieldLabels.currentPassword'),
			autoComplete: 'current-password',
			errorMessage: t('view.profile.formErrors.currentPassword'),
			fieldType: FormFieldType.text,
		},
		newPassword: {
			value: '',
			required: true,
			type: TextInputType.password,
			label: t('view.profile.formFieldLabels.newPassword'),
			errorMessage: t('view.profile.formErrors.newPassword'),
			autoComplete: 'new-password',
			fieldType: FormFieldType.text,
		},
		confirmNewPassword: {
			value: '',
			required: true,
			type: TextInputType.password,
			label: t('view.profile.formFieldLabels.confirmNewPassword'),
			errorMessage: t('view.profile.formErrors.confirmNewPassword'),
			fieldType: FormFieldType.text,
		},
	}

	const mapFormFields: any = (fieldKey: string, fieldEntry: UserProfileField) => {
		// HINT: not needed, as the fields are not nested anymore
		// if ('object' === typeof Object.values(fieldEntry)[0]) {
		// 	return Object.entries(fieldEntry).flatMap((entry: [string, UserProfileField]) => {
		// 		return mapFormFields(entry[0], entry[1])
		// 	})
		// }

		return undefined !== fields[fieldKey]
			? [
					fieldKey,
					{
						...fields[fieldKey],
						...fieldEntry,
						disabled: !fieldEntry.editable,
					},
			  ]
			: undefined
	}

	const inputElements = profileData
		? Object.entries(profileData)
				.map((entry: [string, unknown]) => mapFormFields(entry[0], entry[1]))
				.filter((entry: [string, UserProfileField]) => undefined !== entry)
				.sort((a: [string, FormField], b: [string, FormField]) => {
					const fieldsOrder = Object.keys(fields)
					if (fieldsOrder.indexOf(a[0]) > fieldsOrder.indexOf(b[0])) {
						return 1
					} else {
						return -1
					}
				})
				.reduce((obj: { [key: string]: FormField }, entry: [string, UserProfileField]) => {
					delete entry[1].editable

					obj[entry[0]] = entry[1] as unknown as FormField

					return obj
				}, {})
		: {}

	return (
		<div className="center-view">
			<div className="profile">
				{profileData && (
					<Form
						className="profile"
						fields={inputElements}
						onSubmit={async (submittedFields: FormSubmitFields) => {
							const mfaResponse = await api.setUserProfile(submittedFields)

							if (undefined !== (mfaResponse as MfaResponseData).transactionId) {
								auth.addMfaAction({
									transactionId: (mfaResponse as MfaResponseData).transactionId,
									onSuccess: () => {
										queryClient.removeQueries(QueryKey.userProfile)
										successModal.current?.openModal()
									},
								})
							}

							return mfaResponse
						}}
						onError={(errorResponse: { error: ApiError }) => {
							setErrorMessages([t(`apiErrors.${errorResponse.error}`, { error: errorResponse.error })])
						}}
						errorMessages={errorMessages}
						submitLabel={t('view.profile.buttonLabel.saveProfile')}
						onlySubmitOnChangedValues={true}
						hideSubmit={true}
					>
						<div className="profile__readonly-fields">
							<TextInput
								disabled
								value={t(`view.profile.formFieldLabels.partitipationType.${wayOfParticipation}`) || ''}
								label={t('view.profile.formFieldLabels.partitipationType.label')}
								className="paritipation-type"
							></TextInput>
						</div>
					</Form>
				)}
				{isSuccess && oldPhone && oldEmail && (
					<>
						{' '}
						<TextInput
							disabled
							value={oldEmail}
							label={t('view.profile.formFieldLabels.privateEmailAddress')}
							className="paritipation-type"
						></TextInput>
						<Button
							className="no-margin--top no-margin--left"
							label={t('view.profile.formFieldLabels.changeEmail')}
							type={[ButtonType.secondary, ButtonType.small]}
							onClick={() => navigateTo(getChildPath(routesDictionary.profile, 'changeEmail'))}
						/>
						<TextInput
							disabled
							value={oldPhone}
							label={t('view.profile.formFieldLabels.phoneNumber')}
							className="paritipation-type"
						></TextInput>
						<Button
							className="no-margin--top  no-margin--left"
							label={t('view.profile.formFieldLabels.changePhoneNumber')}
							type={[ButtonType.secondary, ButtonType.small]}
							onClick={() => navigateTo(getChildPath(routesDictionary.profile, 'changePhone'))}
						/>
					</>
				)}
			</div>

			{/* V2 Phone && Email */}

			<hr className="hr hr--narrow margin--vertical" />

			<div>
				<h2>
					<Trans i18nKey="view.profile.additionalOptions" />
				</h2>

				<Button
					className="margin--vertical"
					label={t('view.profile.changePasswordModal.buttonLabel')}
					type={[ButtonType.secondary, ButtonType.small]}
					onClick={() =>
						auth.authVersion === '1'
							? passwordModal.current?.openModal()
							: navigateTo(getChildPath(routesDictionary.profile, 'changePassword'))
					}
				/>

				<Button
					className="margin--vertical"
					label={t('view.profile.removeConsentModal.buttonLabel')}
					type={[ButtonType.secondary, ButtonType.small]}
					onClick={() => removeConsentModal.current?.openModal()}
				/>

				<Button
					className="margin--vertical"
					label={t('view.profile.termsAndConditionsModal.buttonLabel')}
					type={[ButtonType.secondary, ButtonType.small]}
					onClick={() => termsAndConditionsModal.current?.openModal()}
				/>

				<Button
					className="download-dse button download-dse button--secondary button--small"
					onClick={() => contactTermsModal.current?.openModal()}
				>
					{t('view.profile.dataProtectionDownload.buttonLabel')}
				</Button>

				{activatedFeatures.deepl && (
					<Button
						className="margin--vertical"
						label={t('view.profile.changeLanguageModal.buttonLabel')}
						type={[ButtonType.secondary, ButtonType.small]}
						onClick={() => changeLanguageModal.current?.openModal()}
					/>
				)}
			</div>

			<Modal ref={passwordModal} hideConfirm={true} header={t('view.profile.changePasswordModal.modalHeader')}>
				<div className="form-view">
					<h1 className="form-view__title">
						<Trans i18nKey="view.profile.changePasswordModal.modalHeadline" />
					</h1>

					<Form
						className="form-view"
						fields={passwordInputElements}
						onSubmit={async (submittedFields: FormSubmitFields) => {
							if (submittedFields.newPassword !== submittedFields.confirmNewPassword) {
								return {
									code: ApiErrorCode.PASSWORDS_DO_NOT_MATCH,
									successful: false,
								}
							}

							if (false === matchesPasswordPolicy(String(submittedFields.newPassword))) {
								return {
									code: ApiErrorCode.PASSWORD_NOT_COMPLY_WITH_POLICY,
									successful: false,
								}
							}

							const mfaResponse = await api.updatePassword({
								oldPassword: submittedFields.oldPassword as string,
								newPassword: submittedFields.newPassword as string,
							})

							if (undefined !== (mfaResponse as MfaResponseData).transactionId) {
								auth.addMfaAction({
									transactionId: (mfaResponse as MfaResponseData).transactionId,
									onSuccess: () => passwordModal.current?.closeModal(),
								})
							}

							return mfaResponse
						}}
						onError={(errorResponse: ApiError) => {
							setPasswordErrorMessages([
								t(`apiErrors.${errorResponse.code}`, { error: errorResponse.code }),
							])
						}}
						errorMessages={passwordErrorMessages}
						submitLabel={t('view.profile.changePasswordModal.confirm')}
					/>
				</div>
			</Modal>

			<Modal
				ref={changeLanguageModal}
				hideConfirm={true}
				header={t('view.profile.changeLanguageModal.modalHeader')}
			>
				<div className="form-view">
					<h1 className="form-view__title">
						<Trans i18nKey="view.profile.changeLanguageModal.modalHeadline" />
					</h1>

					<Form
						className="form-view"
						fields={changeLanguageElements}
						onSubmit={async (e: any) => {
							const lang = e.languageSetting

							if (!lang) return

							if (lang !== i18next.language) {
								await saveLanguageSetting(lang)
								i18next.changeLanguage(lang)
								window.location.reload()
							}
							changeLanguageModal?.current?.closeModal()
							return { sucess: true, status: 200 }
						}}
						onError={(errorResponse: ApiError) => {
							setPasswordErrorMessages([
								t(`apiErrors.${errorResponse.code}`, { error: errorResponse.code }),
							])
						}}
						submitLabel={t('view.profile.changeLanguageModal.confirm')}
					/>
				</div>
			</Modal>

			<Modal
				ref={removeConsentModal}
				header={t('view.profile.removeConsentModal.modalHeader')}
				buttonLabel={t('generic.cancel')}
			>
				<div className="padding--horizontal">
					<h1 className="no-margin--top">
						<Trans i18nKey="view.profile.removeConsentModal.modalHeadline" />
					</h1>

					<p>
						<Trans i18nKey="view.profile.removeConsentModal.content" />
					</p>

					<div className="margin--vertical">
						<Button
							label={t('view.profile.removeConsentModal.confirm')}
							type={[ButtonType.secondary, ButtonType.small]}
							onClick={async () => {
								const mfaResponse = await api.terminateDigitalParticipation()

								if (undefined !== (mfaResponse as MfaResponseData).transactionId) {
									auth.addMfaAction({
										transactionId: (mfaResponse as MfaResponseData).transactionId,
										onSuccess: () =>
											navigateTo(getMainPath(routesDictionary.terminateDigitalParticipation)),
									})
								}
							}}
						/>
					</div>
				</div>
			</Modal>

			<Modal ref={contactTermsModal} className="terms-and-conditions-modal" buttonLabel={t('generic.close')}>
				<PrivacyPolicy />
			</Modal>

			<Modal
				ref={termsAndConditionsModal}
				className="terms-and-conditions-modal"
				buttonLabel={t('generic.close')}
			>
				<TermsAndConditions />
			</Modal>

			<Modal ref={successModal} buttonLabel={t('generic.close')}>
				<h1 className="padding--horizontal no-margin--top">
					<Trans i18nKey="view.profile.successModal.modalHeadline" />
				</h1>
				<Icon type={IconType.animatedCheckmark} className="modal__success-icon" />
			</Modal>
		</div>
	)
}

export default ProfileOverview
