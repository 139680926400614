interface MathExt extends Math {
	easeInOutQuad(timeElapsed: number, start: number, distance: number, duration: number): number
}
/**
 * Custom easing function
 *
 * @param {number} t current time
 * @param {number} b start value
 * @param {number} c change in value
 * @param {number} d duration
 * @returns {number}
 */
;(Math as MathExt).easeInOutQuad = (t, b, c, d) => {
	t /= d / 2
	if (t < 1) {
		return (c / 2) * t * t + b
	}
	t--

	return (-c / 2) * (t * (t - 2) - 1) + b
}

/**
 * polyfill for smooth scrolling
 *
 * @param element
 * @param to
 * @param horizontal
 */
const scrollTo = (element: HTMLElement, to: number, horizontal: boolean = false) => {
	if (typeof window.scrollTo !== 'function') {
		return
	}

	if ('scrollBehavior' in document.documentElement.style) {
		element.style.scrollBehavior = 'smooth'
		element.scrollTo(horizontal ? to : 0, horizontal ? 0 : to)

		return
	}

	// if ('scrollSnapType' in document.documentElement.style) {
	// 	// @ts-ignore
	// 	element.style.scrollSnapType = 'none'
	// }

	document.addEventListener('mousewheel', () => quit())
	document.addEventListener('touchmove', () => quit())
	const start = horizontal ? element.scrollLeft : element.scrollTop
	const distance = to - start
	const duration = 250

	let timeStart: number | null = null
	let timeElapsed
	let stopEarly = false

	requestAnimationFrame(time => loop(time))

	const loop = (time: number) => {
		if (stopEarly) {
			return
		}

		if (timeStart === null) {
			timeStart = time
		}

		timeElapsed = time - timeStart

		if (horizontal) {
			element.scrollTo((Math as MathExt).easeInOutQuad(timeElapsed, start, distance, duration), 0)
		} else {
			element.scrollTo(0, (Math as MathExt).easeInOutQuad(timeElapsed, start, distance, duration))
		}

		if (timeElapsed < duration) {
			requestAnimationFrame(currentTime => loop(currentTime))
		} else {
			end()
		}
	}

	const end = () => {
		if (horizontal) {
			element.scrollTo(start + distance, 0)
		} else {
			element.scrollTo(0, start + distance)
		}

		timeStart = null

		// if ('scrollSnapType' in document.documentElement.style) {
		// 	// @ts-ignore
		// 	element.style.scrollSnapType = ''
		// }

		document.removeEventListener('mousewheel', () => quit())
		document.removeEventListener('touchmove', () => quit())
	}

	const quit = () => {
		stopEarly = true

		// if ('scrollSnapType' in document.documentElement.style) {
		// 	// @ts-ignore
		// 	element.style.scrollSnapType = ''
		// }

		document.removeEventListener('mousewheel', () => quit())
		document.removeEventListener('touchmove', () => quit())
	}
}

export default scrollTo
