import { IconType } from 'components/Icons'
import RiskPreviewBenefit from 'components/RiskBenefit/RiskPreviewBenefit'
import useApi, { QueryKey } from 'hooks/useApi'
import { useUserGroup } from 'hooks/useUserGroup'
import { useIsMaxAge } from 'hooks/useIsMaxAge'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import Icon from 'shared/components/Icon'
import SubmenuBar from 'shared/components/SubmenuBar'
import SubmenuBarGroup from 'shared/components/SubmenuBarGroup'
import { OPTEOptions, OPTTOptions, RiskOptions } from 'shared/enums'
import dateFormat from 'shared/helper/dateFormat'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { IRiskPartnerData } from 'shared/interfaces'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'
import { riskOptionIcon } from './RiskBenefit'

const RBOverview: React.FC<{ riskId: RiskOptions }> = ({ riskId }) => {
	useBackButtonPath()
	useNavigationTitle(<Trans i18nKey="view.riskBenefit.pageTitle.default" />)

	const api = useApi()
	const { t } = useTranslation()
	const { getChildPath, navigateTo } = useRouteHelper()
	const { isHigherUp, isVO20, isBoard } = useUserGroup()
	const isMaxAge = useIsMaxAge()

	const { data, status } = useQuery(QueryKey.riskOptionsAvailable, api.getRiskOptionsAvailable)
	const { data: partnerData } = useQuery(QueryKey.riskPartnerData, api.getRiskPartnerData, {
		enabled: RiskOptions.partnerBenefit === riskId,
	})

	const dataKeys: { [key: string]: { [key: string]: any } } | undefined = useMemo(() => {
		if (undefined === data) {
			return undefined
		}

		return {
			[RiskOptions.disabilityBenefit]: {
				currentType: data?.currentOPTEOption.OPTE,
				futureType: data?.futureRiskOPTEOption?.OPTE,
				currentYears: data?.currentOPTEOption?.years,
				futureYears: data?.futureRiskOPTEOption?.years,
				changeable: data?.opteChangeable,
				notChangeableCause: data?.opteNotChangeableCause,
				nextChangeAvailable: data?.opteNextChangeAvailable,
			},
			[RiskOptions.partnerBenefit]: {
				currentType: data?.currentOPTTOption.OPTT,
				futureType: data?.futureRiskOPTTOption?.OPTT,
				currentYears: data?.currentOPTTOption?.years,
				futureYears: data?.futureRiskOPTTOption?.years,
				changeable: data?.opttChangeable,
				notChangeableCause: data?.opttNotChangeableCause,
				nextChangeAvailable: data?.opttNextChangeAvailable,
			},
		}
	}, [data])

	const onClickHandler = useCallback(() => {
		navigateTo(
			getChildPath(
				routesDictionary.riskBenefit,
				RiskOptions.disabilityBenefit === riskId ? 'RBDisabilityBenefitChange' : 'RBPartnerBenefitChange'
			)
		)
	}, [riskId, getChildPath, navigateTo])

	useEffect(() => {
		isHigherUp && onClickHandler()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className="flex flex--direction-column rb-overview">
			<div
				className={`risk-benefit center-view grid grid--center-scroll visually-hidden visually-hidden--${String(
					'success' !== status
				)}`}
			>
				{!!data && false === data.hasAnySchemes && (
					<div className="margin--vertical flex flex--align-items-start flex--justify-content-center">
						<Icon className="margin--horizontal no-margin--left" type={IconType.info} />
						<Trans i18nKey="view.riskBenefit.overview.noSchemesDisclaimer" />
					</div>
				)}

				{dataKeys && !!data && true === data.hasAnySchemes && (
					<>
						<h3 className="text-align--left grid__top">
							<Trans i18nKey={`view.riskBenefit.overview.headline.${riskId}`} />
						</h3>

						<div className="grid__center">
							{dataKeys[riskId].currentYears && (
								<p className="text-color-cyan">
									{t('view.riskBenefit.forCalendarYear', {
										count: dataKeys[riskId].currentYears.length,
										years: dataKeys[riskId].currentYears.join(', '),
									})}
								</p>
							)}
							<SubmenuBarGroup className="margin--vertical">
								<SubmenuBar
									icon={riskOptionIcon[dataKeys[riskId].currentType]}
									headline={t(`view.riskBenefit.types.${dataKeys[riskId].currentType}.label`)}
									subheadline={
										<>
											{t(
												`view.riskBenefit.types.${dataKeys[riskId].currentType}.shortDescription.${riskId}${isBoard ? 'Vorstand' : ''}`
											)}
										</>
									}
								/>
							</SubmenuBarGroup>
							<Trans
								i18nKey={`view.riskBenefit.types.${dataKeys[riskId].currentType}.description.${riskId}${
									/**
									 * Options for 'Partnerbenefit' will have separate Texts
									 * as a description if users are "vo20-Users". This Applies to all options: e.g. X, A, E
									 * 
									 * Vorstand gets separate texts too. But Vorstand will only have the * "X"-option anyway^
									 */
									riskId === 'partnerBenefit' && isVO20 ? 'Vo20' : ''
								}${isBoard ? 'Vorstand' : ''}`}
							/>

							{riskId === 'disabilityAllowance' && isMaxAge && (
								<p>
									<Trans
										i18nKey={`view.riskBenefit.types.${dataKeys[riskId].currentType}.description.meltAwayDisclaimer`}
									/>
								</p>
							)}

							{/* if the duration is 3 years and the user cannot change the option, then show the RiskPreviewBenefit view */}
							{3 === dataKeys[riskId].currentYears?.length && false === dataKeys[riskId].changeable && (
								<>
									<h3 className="text-align--left margin--top margin--large">
										<Trans i18nKey="view.riskBenefit.overview.headline.yourBenefit" />
									</h3>

									<RiskPreviewBenefit type={dataKeys[riskId].currentType} riskId={riskId} />

									<p className="margin--vertical opacity--50 font-size-xs">
										<Trans i18nKey="view.riskBenefit.previewDisclaimer.benefit" />
									</p>
								</>
							)}
							<hr className="hr margin--vertical margin--large full-width" />
							<h3 className="text-align--left">
								<Trans i18nKey={`view.riskBenefit.overview.changeSetting.${riskId}`} />
							</h3>
							{dataKeys[riskId].changeable && (
								<>
									{dataKeys[riskId].futureType && (
										<>
											<p className="text-color-cyan">
												{t('view.riskBenefit.benefitForCalendarYear', {
													count: [OPTEOptions.STANDARD, OPTTOptions.STANDARD].includes(
														dataKeys[riskId].futureType
													)
														? 2
														: dataKeys[riskId].futureYears.length,
													years: [OPTEOptions.STANDARD, OPTTOptions.STANDARD].includes(
														dataKeys[riskId].futureType
													)
														? `${t('generic.from')} ${dataKeys[riskId].futureYears.join(
																', '
														  )}`
														: dataKeys[riskId].futureYears.join(', '),
												})}
											</p>

											<SubmenuBarGroup className="margin--vertical">
												<SubmenuBar
													icon={riskOptionIcon[dataKeys[riskId].futureType]}
													headline={t(
														`view.riskBenefit.types.${dataKeys[riskId].futureType}.label`
													)}
													subheadline={t(
														`view.riskBenefit.types.${dataKeys[riskId].futureType}.shortDescription.${riskId}`
													)}
												/>
											</SubmenuBarGroup>
										</>
									)}

									<p className="text-color-cyan">
										<Trans i18nKey="view.riskBenefit.changeSetting" />
									</p>
									<p>
										<Trans
											i18nKey="view.riskBenefit.changeSettingUntil"
											values={{ date: dateFormat(data.optionBorderDateTimestamp) }}
										/>
									</p>
								</>
							)}
							{!dataKeys[riskId].changeable && (
								<>
									<p>
										<Trans
											i18nKey={`view.riskBenefit.overview.changeNotPossible.${dataKeys[riskId].notChangeableCause}`}
											values={{ date: dateFormat(dataKeys[riskId].nextChangeAvailable) }}
										/>
									</p>
								</>
							)}
							<Button
								onClick={onClickHandler}
								type={ButtonType.primary}
								label={t('generic.change')}
								disabled={!dataKeys[riskId].changeable}
							/>
						</div>
					</>
				)}
			</div>
			{RiskOptions.partnerBenefit === riskId && (
				<div
					className={`risk-benefit center-view grid grid--center-scroll visually-hidden visually-hidden--${String(
						!data
					)}`}
				>
					<div className="grid__center">
						<hr className="hr margin--vertical margin--large full-width " />

						<h3 className="text-align--left">
							<Trans i18nKey="view.riskBenefit.partner.headline" />
						</h3>

						{partnerData && 0 !== Object.keys(partnerData).length ? (
							<>
								<Trans i18nKey="view.riskBenefit.overview.designatedPartner.alreadySet" />
								<div className="risk-benefit__partner-form-summary margin--vertical">
									{Object.keys(partnerData).map((item, index) => (
										<React.Fragment key={`partner-form-summary-item-${index}`}>
											<span>
												<Trans i18nKey={`view.riskBenefit.formFieldLabels.${item}`} />:
											</span>
											<span className={['VORNA_EP', 'NACHN_EP'].includes(item) ? 'bold' : ''}>
												{'GBDAT_EP' === item
													? dateFormat(new Date(partnerData[item as keyof IRiskPartnerData]))
													: partnerData[item as keyof IRiskPartnerData]}
											</span>
										</React.Fragment>
									))}
								</div>
							</>
						) : (
							<Trans i18nKey="view.riskBenefit.overview.designatedPartner.description" />
						)}

						<Button
							className="no-margin--bottom"
							onClick={() => {
								navigateTo(getChildPath(routesDictionary.riskBenefit, 'RBPartnerForm'))
							}}
							type={ButtonType.secondary}
							label={t(
								`view.riskBenefit.overview.designatedPartner.${
									0 !== Object.keys(partnerData || {}).length ? 'changePartner' : 'setPartner'
								}`
							)}
						/>

						{0 !== Object.keys(partnerData || {}).length && (
							<Button
								className="no-margin--bottom"
								onClick={() => {
									navigateTo(getChildPath(routesDictionary.riskBenefit, 'RBPartnerRemove'))
								}}
								type={ButtonType.secondary}
								label={t('view.riskBenefit.overview.designatedPartner.removePartner')}
							/>
						)}
					</div>
				</div>
			)}
		</div>
	)
}

export default RBOverview
