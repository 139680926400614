import { Auth } from 'aws-amplify'
import useApi, { QueryKey } from 'hooks/useApi'
import { useAuth } from 'hooks/useAuth'
import { t } from 'i18next'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import Form, { FormFields, FormFieldType, FormRefActions } from 'shared/components/Form'
import { TextInputType } from 'shared/components/TextInput'
import { trackPromise } from 'react-promise-tracker'
import { FormSubmitFields } from 'shared/hooks/useForm'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonClickHandler, useBackButtonPath, useResetHeaderState } from 'state/useHeaderState'
import useAuthApi from 'hooks/useAuthApi'
import { ButtonWithCooldown } from 'shared/components/ButtonWithCooldown'

type MFACode = 'phone_number' | 'email'
type codeAction = 'confirm_attribute' | 'respond_to_challenge'

const EnterCode: React.FunctionComponent<{ type: MFACode; action: codeAction }> = ({ type, action }) => {
	useResetHeaderState()
	const enterCodeForm = useRef<FormRefActions>()
	const api = useApi()
	const { session } = useAuth()
	const authApi = useAuthApi()
	const { sendChallengeAnswerV2, challengeV2, resetChallenge } = useAuth()
	const [resendSucess, setResendSuccess] = useState<boolean>()
	const [resendError, setResendError] = useState<string>()

	const [errorMessages, setErrorMessages] = useState<string[]>()
	const { getChildPath, navigateTo } = useRouteHelper(routesDictionary.migration)

	const { data } = useQuery(QueryKey.cognitoUserAttributes, api.getUserAttributes)

	const storedParam = data?.get(type)

	const getBackButtonPath = () => {
		/**
		 * No backbuttonPath since user is answering login-sendChallengeAnswer.
		 * Use setChallenge() for navigation inside login route
		 * */
		if (action === 'respond_to_challenge') {
			return undefined
		}

		switch (type) {
			case 'email':
				return getChildPath(routesDictionary.migration, 'newEmail')
			case 'phone_number':
				return getChildPath(routesDictionary.migration, 'phone')
		}
	}

	useBackButtonPath(getBackButtonPath())
	useBackButtonClickHandler(action === 'respond_to_challenge' ? { name: resetChallenge } : undefined)

	const navigate = () => {
		switch (type) {
			case 'email':
				navigateTo(getChildPath(routesDictionary.migration, 'phone'))
				return
			case 'phone_number':
				navigateTo(getChildPath(routesDictionary.migration, 'password'))
		}
	}

	const resendCode = async (type: MFACode) => {
		if (!storedParam) {
			return
		}

		try {
			const user = await Auth.currentAuthenticatedUser()
			await Auth.deleteUserAttributes(user, [type])
			await Auth.updateUserAttributes(user, {
				[type]: storedParam,
			})
			setResendSuccess(true)
		} catch {
			setResendSuccess(false)
		}
	}

	const inputElements: FormFields = {
		code: {
			required: true,
			type: TextInputType.text,
			label: t('view.migration.enterCode.inputLabel'),
			fieldType: FormFieldType.text,
		},
	}

	const confirmAttribute = async (submittedFields: FormSubmitFields) => {
		try {
			const value = String(submittedFields?.code)
			const user = await Auth.currentAuthenticatedUser()
			await Auth.verifyUserAttributeSubmit(user, type, value)
			return { successful: true, status: 200 }
		} catch (e: any) {
			if (e.code) {
				setErrorMessages([t(`apiErrors.${e.code}`)])
				enterCodeForm.current?.handleFormError()
			}

			return { successful: false, status: 500 }
		}
	}

	const respondToChallenge = async (submittedFields: FormSubmitFields) => {
		await sendChallengeAnswerV2(submittedFields.code)
	}

	useEffect(() => {
		if (challengeV2.successful || challengeV2.lastChallengeError === undefined) {
			return
		}

		if (challengeV2.successful === false) {
			setErrorMessages([t(`apiErrors.incorrectTAN`)])
		}

		if (challengeV2.lastChallengeError) {
			switch (challengeV2.lastChallengeError) {
				case 'UserLambdaValidationException':
					setErrorMessages([t(`apiErrors.expiredTAN`)])
			}
		}
		enterCodeForm.current?.handleFormError()
	}, [challengeV2])

	const obscuredWayOfContact = useMemo(() => {
		if (action === 'respond_to_challenge' || !data) {
			return null
		}

		const contact = data.get(type)

		switch (type) {
			case 'email':
				const [first, ...rest] = contact?.split('@')
				return `${first[0]}****@${rest}`
			case 'phone_number':
				return '******' + contact?.slice(-4)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type, data])

	useEffect(() => {}, [])

	const resendCodeForLogin = async () => {
		setResendError('')
		const username = session?.current?.username
		const response = await authApi.resendTan(username)
		if (response === true) {
			setResendSuccess(true)
		} else {
			setResendError('apiErrors.TANresendError')
			setResendSuccess(false)
		}
	}

	return (
		<div className="migration form-view">
			<div className="grid__top">
				<h1>
					<Trans i18nKey="view.migration.enterCode.headline"></Trans>
				</h1>
			</div>
			<div className="form-view__bodytext">
				<div>
					<p>
						<Trans
							i18nKey="view.migration.enterCode.bodyText"
							values={{ type: t(`view.migration.enterCode.${type}`) }}
						></Trans>
					</p>

					{obscuredWayOfContact && (
						<>
							<p className="margin--top bold">
								<Trans i18nKey={`view.migration.enterCode.obscured.${type}`}></Trans>
							</p>
							<h3 className="no-margin--top margin--top margin--small font-family-alternative--light">
								{obscuredWayOfContact}
							</h3>
						</>
					)}
				</div>

				<Form
					ref={enterCodeForm}
					className="form-view"
					fields={inputElements}
					onSubmit={(submittedFields: FormSubmitFields) => {
						return action === 'confirm_attribute'
							? trackPromise(confirmAttribute(submittedFields), 'sendTan')
							: trackPromise(respondToChallenge(submittedFields), 'sendTan')
					}}
					onSuccess={() => navigate()}
					errorMessages={errorMessages}
					submitLabel={t('generic.send')}
					promiseTracker={{ area: 'sendTan' }}
				>
					<div className="form-view__bodytext margin--vertical">
						<p>
							{action === 'confirm_attribute' && (
								<Trans i18nKey="view.migration.enterCode.sendAgain"></Trans>
							)}
						</p>

						{storedParam && action === 'confirm_attribute' && (
							<Button
								type={[ButtonType.secondary, ButtonType.small]}
								className="margin--top margin--s"
								onClick={() => resendCode(type)}
							>
								<Trans i18nKey={'view.migration.enterCode.sendAgainButton'}></Trans>{' '}
							</Button>
						)}

						<>
							<Trans i18nKey={'view.migration.enterCode.resendAfterCountdownText'}></Trans>
							<ButtonWithCooldown
								type={[ButtonType.secondary, ButtonType.small]}
								className="resend-tan margin--vertical margin--s"
								onClick={() => trackPromise(resendCodeForLogin(), 'sendTan')}
							>
								<Trans i18nKey={'view.migration.enterCode.sendAgainButton'}></Trans>{' '}
							</ButtonWithCooldown>
						</>

						{resendSucess && (
							<p className="text-color-blue">
								<Trans i18nKey="view.migration.enterCode.sendAgainSuccess"></Trans>
							</p>
						)}

						{resendError && (
							<p className="text-color-red">
								<Trans i18nKey="apiErrors.TANresendError"></Trans>
							</p>
						)}
					</div>
				</Form>
			</div>
		</div>
	)
}

export default EnterCode
