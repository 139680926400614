import useApi, { QueryKey } from 'hooks/useApi'
import { t } from 'i18next'
import { FC, useEffect } from 'react'
import { Trans } from 'react-i18next'
import { useMutation, useQueryClient, useQuery } from 'react-query'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'

const DisplayPensionDate: FC<{
	pensionType: 'Ruhestand' | 'VorgezogenerRuhestand'
	exitDate: string
}> = (props) => {
	const api = useApi()
	const { navigateTo, getChildPath } = useRouteHelper()

	const queryClient = useQueryClient()

	const calculatePensionStart = (date: string) => {
		const pensionDate = new Date(date)
		pensionDate.setDate(pensionDate.getDate() + 1)
		return pensionDate.toLocaleDateString()
	}

	const { data: isEditable } = useQuery(QueryKey.payoutModelEditable, api.isPayoutModelEditable)

	const { data } = useQuery(QueryKey.payoutModelInProgress, api.getPayoutModelInProgress)

	const mutation = useMutation(api.patchPayoutModel, {
		onSuccess: (payload) => {
			queryClient.setQueryData(QueryKey.payoutModelInProgress, { ...payload, ...data })
		},
	})

	useEffect(() => {
		if (mutation.status === 'success') {
			navigateTo(getChildPath(routesDictionary.payoutOptions, 'payoutModel'))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mutation])

	const confirmBenefitType = () => {
		const leistungsart = {
			type: props.pensionType,
			austrittsdatum: new Date(props.exitDate)
				.toLocaleDateString('de-DE', {
					year: 'numeric',
					month: '2-digit',
					day: '2-digit',
				})
				.split('.')
				.reverse()
				.join('-'),
		}
		if (!isEditable?.editable) {
			navigateTo(getChildPath(routesDictionary.payoutOptions, 'payoutModel'))
			return
		}
		mutation.mutate({ leistungsart, modell: {} })
	}

	return (
		<>
			<div className="grid__center">
				<p className="text-color-blue">
					<Trans i18nKey="view.payoutOptions.chapterOne.payoutDate.exitDateText" />
					<br />
					<b>{new Date(props?.exitDate || '').toLocaleDateString()}</b>
				</p>
				<hr className="hr hr--blue hr--slim margin--bottom  margin--top" />
				<h3 className="font-family-alternative--light">
					<Trans i18nKey="view.payoutOptions.chapterOne.payoutDate.pensionDateText" />
					<br />
					<b>{props?.exitDate && calculatePensionStart(props.exitDate)}</b>
				</h3>
			</div>

			<div className="grid__bottom">
				<Button
					type={ButtonType.primary}
					label={t('view.payoutOptions.chapterOne.benefitType.submit')}
					onClick={confirmBenefitType}
				></Button>
			</div>
		</>
	)
}

export default DisplayPensionDate
