import { pensionAssetsChartStyle } from 'components/ChartsTheme'
import { FunctionComponent } from 'react'
import { Trans } from 'react-i18next'
import BigColorFigure from 'shared/components/BigColorFigure'
import { currencyFormat } from 'shared/helper/numberFormats'
import { PensionAssetsData } from 'shared/interfaces'
import { components } from 'types/api-interface'

export interface PensionAssetsDashboardProps {
	data: PensionAssetsData
	blocksAccountData?: components['schemas']['BausteinkontoOverview']
}

const PensionAssetsDashboard: FunctionComponent<PensionAssetsDashboardProps> = ({ blocksAccountData, ...props }) => {
	const hasBlocks =
		!blocksAccountData?.ausfinanziert &&
		blocksAccountData?.bausteine?.length &&
		blocksAccountData?.bausteine?.length > 0

	return (
		<div className="pension-assets-dashboard">
			{(hasBlocks && blocksAccountData?.total !== 0 && (
				<>
					<BigColorFigure
						fill={'	var(--color-black)'}
						value={currencyFormat((blocksAccountData?.total || 0) + props.data.totalPensionAssets)}
					/>

					<div style={{ color: 'var(--color-black)' }} className="total-descriptionont-size-s">
						<div className="flex flex--justify-content-between">
							<span>
								<Trans i18nKey="view.pensionAssets.totalWithBlocks" />
							</span>
						</div>
					</div>
				</>
			)) || <></>}

			<BigColorFigure
				fill={pensionAssetsChartStyle.barColors[0]}
				value={currencyFormat(props.data.totalPensionAssets)}
			/>

			<div
				style={{ color: pensionAssetsChartStyle.barColors[0] }}
				className="total-description text-color-cyan font-size-s"
			>
				<Trans i18nKey="view.dashboard.tile.pensionAssets.totalPensionAssets" />
			</div>

			<BigColorFigure
				fill={pensionAssetsChartStyle.barColors[1]}
				value={currencyFormat(props.data.guaranteedPensionAssets)}
			/>

			<div style={{ color: pensionAssetsChartStyle.barColors[1] }} className="total-description font-size-s">
				<Trans i18nKey="view.dashboard.tile.pensionAssets.guaranteedPensionAssets" />
			</div>
		</div>
	)
}

export default PensionAssetsDashboard
