import { IconType } from 'components/Icons'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import SelectableBarGroup from 'shared/components/SelectableBarGroup'
import dateFormat from 'shared/helper/dateFormat'
import { CompensationTypeData } from 'shared/interfaces'

export interface CompensationSelectProps {
	options: CompensationTypeData[]
	selectedOption?: CompensationTypeData
	onClick: any
	className?: string
}

const CompensationSelectWrapper: React.FC<CompensationSelectProps> = props => {
	const { t } = useTranslation()

	const selectableOptions = props.options.map(element => {
		return {
			subheadline: element.salaryChangesTillDate ? (
				true === element.hasAlreadyDeferredCompensation ? (
					<Trans
						i18nKey={'view.deferredCompensation.selection.conversionPossible'}
						values={{
							date: dateFormat(element.salaryChangesTillDate),
							year: dateFormat(new Date(Date.UTC(element.relatedYear!, 0)), { year: 'numeric' }),
						}}
					/>
				) : (
					<Trans
						i18nKey={'view.deferredCompensation.selection.conversionPossibleIncludingMonth'}
						values={{
							date: dateFormat(element.salaryChangesTillDate),
							month: dateFormat(new Date(Date.UTC(0, element.relatedMonth! - 1)), { month: 'long' }),
						}}
					/>
				)
			) : (
				''
			),
			label: t(`compensationType.${element.type}.title`),
			content: t(`compensationType.${element.type}.content`),
			value: element.id,
			icon: (element.type as unknown) as IconType,
			onClick: () => {
				props.onClick(element)
			},
		}
	})

	const getClassNames = (): string => {
		const classes: string[] = ['compensation-select-wrapper']

		if (props.className) {
			classes.push(props.className)
		}

		return classes.join(' ')
	}

	return (
		<SelectableBarGroup
			className={getClassNames()}
			options={selectableOptions}
			selectedOption={{
				...props.selectedOption,
				value: props.selectedOption && props.selectedOption.id ? props.selectedOption.id : false,
			}}
		/>
	)
}

export default CompensationSelectWrapper
