import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import routesDictionary from 'routes'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { BarChartData } from 'shared/interfaces'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'
import useApi, { QueryKey } from '../../hooks/useApi'
import BarChart, { BarChartDataType } from '../../shared/components/BarChart'

type SingleYearData = {
	x: number
	y0: number
	y1: number
	y2: number
	istOffeneWandlungInDerZukunft: boolean
}[]

const SingleYear: React.FC<{ year: number }> = ({ year }) => {
	const { getMainPath, navigateTo } = useRouteHelper()
	useBackButtonPath()
	useNavigationTitle(
		<>
			<Trans i18nKey="view.conversions.conversions" />
			<br />
			<span className="font-family-light">{year}</span>
		</>
	)

	const api = useApi()
	const { t } = useTranslation()
	const { data }: { data?: SingleYearData | false } = useQuery([QueryKey.compensationYear, year], api.getCompensation)

	const onClick = (month: string) => {
		month = month.replace(/\D/g, '')
		// Math.round is needed, as somehow the cached values are sometimes converted to a float
		navigateTo(`${getMainPath(routesDictionary.conversions)}/${year}/${Math.round(parseInt(month))}`)
	}
	const panToEnd = useMemo(() => Number(year) <= new Date().getFullYear(), [year])
	const mappedData: BarChartData[] | undefined =
		data !== false
			? data?.map((v, i) => {
					const monat = v.istOffeneWandlungInDerZukunft ? `${v.x}*` : `${v.x}`
					return {
						x: monat,
						y0: v.y0,
						y1: v.y1,
						y2: v.y2,
					}
			  })
			: undefined

	return (
		<div className="conversions center-view">
			{!!mappedData && (
				<BarChart
					className="conversions__chart"
					data={mappedData}
					yDataType={BarChartDataType.currency}
					xDataType={BarChartDataType.string}
					panToEnd={panToEnd}
					stackBars={true}
					clickHandler={onClick}
					legend={[
						t('view.conversions.legend.ownContribution'),
						t('view.conversions.legend.totalInclMatching'),
					]}
				/>
			)}
		</div>
	)
}

export default SingleYear
