import classnames from 'classnames'
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import { usePrevious } from 'react-use'
import dateFormat from 'shared/helper/dateFormat'
import { FundData, FundPerformanceData } from 'shared/interfaces'
import useResizeObserver from 'use-resize-observer'
import {
	// @ts-ignore
	LineSegment,
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryGroup,
	VictoryLabel,
	VictoryLine,
	VictoryPortal,
	VictoryScatter,
	// @ts-ignore
	VictoryThemeDefinition,
	VictoryTooltip,
	VictoryVoronoiContainer,
} from 'victory'
import { numberFormat, percentFormat } from '../shared/helper/numberFormats'
import useGlobalModalState from '../shared/hooks/useGlobalModalState'
import theme, {
	fallbackChartColor,
	fontFamilyAlternative,
	indiceComparisonChartStyle,
	lineChartColors,
	lineWidth,
	tooltipColors,
} from './ChartsTheme'

export interface IndicesChartProps {
	data: FundPerformanceData
	className?: string
}

const animationDuration = 1000
const padding = 24
const chartMinHeight = indiceComparisonChartStyle.chartMinHeight

const LineSegmentVertical = (props: any) => {
	return <LineSegment {...props} x2={props.x2 + 10e-4} type={'grid'} />
}

const LineSegmentHorizontal = (props: any) => {
	return <LineSegment {...props} y2={props.y2 + 10e-4} type={'grid'} />
}

const TooltipLabel = (props: any) => {
	const styles = props.text.map((text: string, index: number) => {
		if (0 === index) {
			return {
				...props.style,
				fill: fallbackChartColor,
				fontSize: 8,
				letterSpacing: 1.5,
			}
		}

		return {
			...props.style,
			// use index - 1 as there is a custom text before any data text
			fill: props.activePoints[index - 1].style.data.color,
			fontFamily: fontFamilyAlternative,
		}
	})

	const lineHeights = props.text.map((text: string, index: number) => {
		return 0 === index ? 2.2 : 1.2
	})

	return <VictoryLabel {...props} style={styles} lineHeight={lineHeights} dy={-2} />
}

const Tooltip = (props: any) => {
	const { x } = props.datum
	const date = dateFormat(x)
	props.text.unshift(date)

	return (
		<g>
			<VictoryTooltip
				{...props}
				constrainToVisibleArea={false}
				center={{ y: 0 }}
				style={{
					fontSize: 16,
				}}
				labelComponent={<TooltipLabel />}
				renderInPortal={true}
			/>
			<line
				x1={props.x}
				y1={0}
				x2={props.x + 10e-4}
				y2={props.height - padding}
				stroke="url(#tooltipLineGradient)"
				strokeWidth={2}
			/>
		</g>
	)
}

const EventFlyout = (props: any) => {
	const { x, y, datum } = props
	const [, modalActions] = useGlobalModalState()

	const handleOnClick = useCallback(() => {
		modalActions.setHideButton()
		modalActions.setHeader(
			dateFormat(datum.x, {
				month: '2-digit',
				day: '2-digit',
				year: 'numeric',
			})
		)
		modalActions.setContent(
			<p className="font-size-default no-margin">
				<Trans i18nKey={`view.indicesComparison.events.${datum.name}`} />
			</p>
		)
		modalActions.openModal()
	}, [datum.name, datum.x, modalActions])

	return (
		<g
			transform={`translate(${x - 12} ${y - 23.5})`}
			onClick={handleOnClick}
			cursor={'pointer'}
			pointerEvents={'all'}
		>
			<circle cx="12" cy="12" r="12" fill="var(--color-blue--medium)" stroke="white" />
			<path d="M12 11.5V19" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
			<circle cx="12.0005" cy="6.75" r="1.5" fill="white" />
		</g>
	)
}

let resizeObserverTimeout: NodeJS.Timer

const IndicesChart: FunctionComponent<IndicesChartProps> = (props) => {
	const chartTheme: VictoryThemeDefinition = theme

	const [chartWidth, setChartWidth] = useState<number>(0)
	const [chartHeight, setChartHeight] = useState<number>(0)
	const { ref: wrapperRef } = useResizeObserver({
		onResize: ({ width, height }) => {
			if (undefined === height || undefined === width) {
				return
			}

			clearTimeout(resizeObserverTimeout)

			resizeObserverTimeout = setTimeout(() => {
				setChartHeight(height > chartMinHeight ? height : chartMinHeight)
				setChartWidth(width)
			}, 100)
		},
	})

	const [rawData, setRawData] = useState<FundPerformanceData>()
	const previousData = usePrevious(props.data)
	const [animationState, setAnimationState] = useState<'animate' | 'idle'>('animate')

	useEffect(() => {
		const dateHasChanged = JSON.stringify(props.data) !== JSON.stringify(previousData)

		if (animationState === 'animate' && dateHasChanged === false) {
			// wait for next event loop to set data, to prevent issues with graph animation
			// otherwise the change of data is not visualized. this is probably an issue with victory
			setTimeout(() => {
				setRawData(props.data)
			})

			return
		}

		if (animationState === 'idle' && dateHasChanged) {
			setAnimationState('animate')
		}
	}, [animationState, previousData, props.data])

	const { data, domainX, domainY, eventsData } = useMemo(() => {
		if (rawData === undefined) {
			return {}
		}

		const data = [
			...rawData.fundValues.map((fund: FundData) => {
				return fund.data
			}),
			rawData.indiceValues,
		]

		const maximaX: number[] = data.map((dataset) => Math.max(...dataset.map((d) => d.x)))
		const minimaX: number[] = data.map((dataset) => Math.min(...dataset.map((d) => d.x)))
		const maximaY: number[] = data.map((dataset) => Math.max(...dataset.map((d) => d.y)))
		const minimaY: number[] = data.map((dataset) => Math.min(...dataset.map((d) => d.y)))
		const domainX: [number, number] = [Math.min(...minimaX), Math.max(...maximaX)]
		const domainY: [number, number] = [Math.min(...minimaY), Math.max(...maximaY)]

		let eventsData

		if (chartWidth > 0) {
			const domainXPointWidth = (domainX[1] - domainX[0]) / chartWidth
			const domainXPointHeight = (domainY[1] - domainY[0]) / chartHeight

			const offsetX = 30 * domainXPointWidth
			const offsetY = 40 * domainXPointHeight
			let offsetYMultiplier = 1
			let distanceX = 0

			eventsData = rawData.kursverlaufEreignisse
				.filter(({ datum }) => domainX[0] <= datum && domainX[1] >= datum)
				.reduce(
					(
						previousValue: { x: number; y: number; y0: number; name: string }[],
						{ datum, name },
						index,
						array
					) => {
						if (index > 0) {
							const distanceToPreviousPoint = datum - array[index - 1].datum
							distanceX = distanceX + distanceToPreviousPoint

							if (distanceX < offsetX) {
								offsetYMultiplier++
							} else {
								distanceX = 0
								offsetYMultiplier = 1
							}
						}

						const y = domainY[1] - offsetY * offsetYMultiplier

						return [...previousValue, { x: datum, y, y0: domainY[0], name }]
					},
					[]
				)
		}

		return {
			data,
			domainX,
			domainY,
			eventsData,
		}
	}, [rawData, chartWidth, chartHeight])

	const classNames = useMemo(() => {
		const classes: string[] = ['indice-comparison-chart']

		if (props.className) {
			classes.push(props.className)
		}

		return classes.join(' ')
	}, [props.className])

	return (
		<div className={classNames} ref={wrapperRef} style={{ minHeight: chartMinHeight }}>
			<svg width={0} height={0}>
				<linearGradient id="verticalGradient" x1="0" x2="0" y1="0" y2="1">
					<stop offset="0%" stopColor="var(--color-cyan)" stopOpacity="0" />
					<stop offset="10%" stopColor="var(--color-cyan)" />
				</linearGradient>
				<linearGradient id="tooltipLineGradient" x1="0" x2="0" y1="0" y2="1">
					<stop offset="0" stopColor="var(--tooltip-background-color)" />
					<stop offset="1" stopColor="var(--tooltip-background-color)" stopOpacity="0" />
				</linearGradient>
				<linearGradient id="horizontalGradient" x1="0" x2="1" y1="0" y2="0">
					<stop offset="0%" stopColor="var(--color-cyan)" stopOpacity="0" />
					<stop offset="5%" stopColor="var(--color-cyan)" />
					<stop offset="95%" stopColor="var(--color-cyan)" />
					<stop offset="100%" stopColor="var(--color-cyan)" stopOpacity="0" />
				</linearGradient>
			</svg>
			{data && chartWidth > 0 && chartHeight > 0 && (
				<VictoryChart
					theme={chartTheme}
					height={chartHeight}
					width={chartWidth}
					scale={{ x: 'time', y: 'linear' }}
					domain={{ x: domainX }}
					animate={
						animationState === 'animate'
							? {
									duration: animationDuration,
									onEnd: () => {
										setAnimationState('idle')
									},
							  }
							: undefined
					}
					containerComponent={
						<VictoryVoronoiContainer
							className={classnames('indice-comparison-chart__chart', {
								'pointer-events--force-none': animationState === 'animate',
							})}
							labels={({ datum }) => {
								if (0 !== props.data.fundValues.length) {
									return percentFormat(datum.y, { addSignPrefix: true, variableFractionDigits: true })
								} else {
									return `${numberFormat(datum.y, {
										fixedFractionDigits: 2,
									})} ${props.data.unit ?? ''}`
								}
							}}
							labelComponent={<Tooltip />}
							voronoiDimension="x"
							voronoiBlacklist={['line-chart', 'bar-chart']}
						/>
					}
					domainPadding={{ x: [lineWidth, padding * 2], y: [lineWidth, padding * 2] }}
					padding={{ bottom: padding, left: 0, right: 0, top: 0 }}
					style={{
						parent: {
							touchAction: 'pan-y',
							/**
							 * fix for Safari
							 * Otherwise the graph is not visible when the graph is smaller the chartMinHeight
							 */
							minHeight: 'inherit',
						},
					}}
				>
					<VictoryAxis
						axisComponent={<LineSegmentHorizontal />}
						gridComponent={<LineSegmentVertical />}
						style={{
							axis: {
								fill: 'none',
								stroke: 'url(#horizontalGradient)',
								strokeOpacity: 0.2,
							},
							grid: {
								fill: 'none',
								stroke: 'url(#verticalGradient)',
								strokeOpacity: 0.2,
							},
							tickLabels: {
								fontSize: 10,
								opacity: 0.4,
							},
						}}
						tickLabelComponent={<VictoryLabel y={chartHeight - padding + 4} />}
						tickFormat={(t) => dateFormat(t, { month: '2-digit', day: '2-digit', year: 'numeric' })}
					/>

					<VictoryAxis
						gridComponent={<LineSegmentHorizontal />}
						style={{
							axis: {
								fill: 'none',
								stroke: 'none',
							},
							grid: {
								fill: 'none',
								stroke: 'url(#horizontalGradient)',
								// stroke: 'var(--color-cyan)',
								// remove first line from grid
								// strokeOpacity: (tick: any) => (tick.index < tick.ticks.length - 1 ? 0.2 : 0),
								strokeOpacity: 0.2,
							},
							tickLabels: {
								fontSize: 10,
								opacity: 0.4,
								textAnchor: 'end',
							},
						}}
						tickLabelComponent={<VictoryLabel dy={-8} dx={padding + 2} />}
						dependentAxis={true}
						crossAxis={false}
						tickFormat={(t) => {
							if (0 !== props.data.fundValues.length) {
								return percentFormat(t, { addSignPrefix: true, fixedFractionDigits: 1 })
							} else {
								return `${numberFormat(t, {
									variableFractionDigits: true,
								})} ${props.data.unit ?? ''}`
							}
						}}
					/>

					{data.map((d, i) => {
						/**
						 * always use the fallbackChartColor for the last data item,
						 * as it is always the comparison indice
						 */
						const color =
							i + 1 < data.length ? lineChartColors[i] || fallbackChartColor : fallbackChartColor
						const textColor =
							i + 1 < data.length ? tooltipColors[i] || fallbackChartColor : fallbackChartColor

						return (
							<VictoryGroup key={i} data={d} domain={{ x: domainX }}>
								<VictoryLine
									name="line-chart"
									interpolation="monotoneX"
									style={{
										data: {
											stroke: color,
											strokeLinecap: 'round',
											strokeWidth: lineWidth,
										},
									}}
								/>
								<VictoryPortal>
									<VictoryScatter
										style={{
											data: {
												fill: color,
												color: textColor,
											},
										}}
										size={(datum) => {
											return datum.active ? 10 : 0
										}}
									/>
								</VictoryPortal>
							</VictoryGroup>
						)
					})}

					{eventsData && eventsData.length > 0 && (
						<VictoryBar
							name="bar-chart"
							barWidth={1}
							cornerRadius={1}
							data={eventsData}
							style={{
								data: {
									fill: 'transparent',
									stroke: 'var(--color-white)',
									strokeWidth: 1,
								},
							}}
							labels={() => ''}
							labelComponent={
								<VictoryTooltip
									constrainToVisibleArea={true}
									active={true}
									flyoutComponent={<EventFlyout />}
								/>
							}
							animate={{
								onEnter: {
									before: () => ({
										_y: domainY[0],
									}),
								},
								onLoad: {
									before: () => ({
										_y: domainY[0],
									}),
								},
							}}
						/>
					)}
				</VictoryChart>
			)}
		</div>
	)
}

export default IndicesChart
