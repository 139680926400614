import React, { FunctionComponent, PropsWithChildren, useCallback } from 'react'

export interface SubmenuBarGroupProps {
	className?: string
}

const SubmenuBarGroup: FunctionComponent<PropsWithChildren<SubmenuBarGroupProps>> = (props) => {
	const { className } = props

	const getClasses = useCallback((): string => {
		const classes: string[] = ['submenu-bar-group']

		if (className) {
			classes.push(className)
		}

		return classes.join(' ')
	}, [className])

	return <div className={getClasses()}>{props.children}</div>
}

export default SubmenuBarGroup
