import classNames from 'classnames'
import TanServiceButton from 'components/TanServiceButton'
import { useAuth } from 'hooks/useAuth'
import { useQueryParams } from 'raviger'
import { useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import Button, { ButtonType } from 'shared/components/Button'
import { ValidityStateType } from 'shared/components/DateInput'
import Form, { FormFields, FormFieldType } from 'shared/components/Form'
import { TextInputType } from 'shared/components/TextInput'
import { useResetHeaderState } from 'state/useHeaderState'
import SendMfaLoginInfotext from './LoginPartials/SendMfaLoginInfotext'
import SendMfaRegisterInfotext from './RegisterPartials/SendMfaRegisterInfotext'

const SendMfa = () => {
	const { t } = useTranslation()
	const auth = useAuth()

	useResetHeaderState()

	// const [errorMessages, setErrorMessages] = useState<string[]>()
	const [queryParams] = useQueryParams()
	const [tanServiceOpened, setTanServiceOpened] = useState<boolean>(false)
	const [tan, setTan] = useState<number | undefined>()
	const [inputValues, setInputValues] = useState<{ [key: string]: any }>()

	const inputElements: FormFields = {
		token: {
			value: '',
			required: true,
			type: TextInputType.tel,
			label: tan ? (
				<Button
					className="no-margin--left no-margin--right"
					type={[ButtonType.secondary, ButtonType.small]}
					label={t('view.login.buttonLabel.pasteTan')}
					onClick={() => {
						setInputValues({
							token: String(tan),
						})
					}}
				/>
			) : undefined,
			errorMessage: t('generic.formErrors.token'),
			fieldType: FormFieldType.text,
			minLength: 6,
			maxLength: 6,
			validityHints: {
				[ValidityStateType.tooShort]: { type: 'hint', message: t('generic.formHints.token') },
				[ValidityStateType.tooLong]: { type: 'hint', message: t('generic.formHints.token') },
			},
		},
	}

	return (
		<div className="form-view">
			<div className="form-view__title">
				<h1>
					<Trans i18nKey={`view.${queryParams.register ? 'register' : 'login'}.sendMfa.pageTitle`} />
				</h1>
			</div>

			<div className="form-view__bodytext">
				<p>
					<Trans i18nKey={`view.${queryParams.register ? 'register' : 'login'}.sendMfa.bodytext`} />
				</p>

				{queryParams.register ? <SendMfaRegisterInfotext /> : <SendMfaLoginInfotext />}

				<p className="margin--top">
					<Trans i18nKey="view.login.sendMfa.step1">
						<b className="text-color-cyan" />
					</Trans>
				</p>
				<TanServiceButton onReceivedTan={setTan} onClick={setTanServiceOpened.bind(null, true)} />
				<p
					className={`visually-hidden visually-hidden--half-opaque visually-hidden--${String(
						!tanServiceOpened
					)}`}
				>
					<Trans i18nKey="view.login.sendMfa.step2">
						<b className="text-color-cyan" />
					</Trans>
				</p>
			</div>
			<div
				className={classNames(
					'visually-hidden',
					'visually-hidden--half-opaque',
					`visually-hidden--${String(!tanServiceOpened)}`,
					{
						'pointer-events--none': !tanServiceOpened,
					}
				)}
			>
				<Form
					className="form-view"
					fields={inputElements}
					onSubmit={auth.sendChallengeAnswer}
					errorMessages={[t('view.authentication.wrongTan')]}
					submitLabel={t('generic.confirm')}
					updatedValues={inputValues}
					hideSubmitIfFieldsEmpty={true}
				/>
			</div>
		</div>
	)
}

export default SendMfa
