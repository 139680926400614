import { useEffect } from 'react'

const Breakpoints: { [key: string]: number } = {
	small: 0,
	smallMedium: 360,
	medium: 1024,
	large: 1400,
}

export const useBreakpointClasses = () => {
	const isClient = typeof window === 'object'

	function getSize() {
		if (isClient) {
			const sizes = []

			for (const [breakpoint, minSize] of Object.entries(Breakpoints)) {
				if (window.innerWidth >= minSize) {
					sizes.push(breakpoint)
				}
			}

			return sizes
		} else {
			return undefined
		}
	}

	useEffect((): any => {
		if (!isClient) {
			return false
		}

		function handleResize() {
			const size = getSize()
			if (undefined !== size) {
				for (const breakpoint of Object.keys(Breakpoints)) {
					document.documentElement.classList.toggle(`breakpoint-${breakpoint}`, size.includes(breakpoint))
				}
			}
		}

		handleResize()

		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
		// eslint-disable-next-line
	}, [])
}
