import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import Image from 'shared/components/Image'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useResetHeaderState } from 'state/useHeaderState'
import anschreibenAnschreiben_01Teilnahme from '../assets/images/anschreiben-anschreiben-01-teilnahme.png'
import anschreibenAnschreiben_01Teilnahme_2x from '../assets/images/anschreiben-anschreiben-01-teilnahme@2x.png'
import anschreibenAnschreiben_02Initialpasswort from '../assets/images/anschreiben-anschreiben-02-initialpasswort.png'
import anschreibenAnschreiben_02Initialpasswort_2x from '../assets/images/anschreiben-anschreiben-02-initialpasswort@2x.png'

import infographicRegister from '../assets/images/infographic-register.png'
import infographicRegister_2x from '../assets/images/infographic-register@2x.png'
import stepbystepPdfCover from '../assets/images/stepbystep-pdf-cover.jpg'
import stepbystepPdfCover_2x from '../assets/images/stepbystep-pdf-cover@2x.jpg'
import { getMusterInitPasswortUrl, getMusterZfCardUrl, getZfVorsorgeRegistrierungsanleitungUrl } from 'shared/static-assets'

const RegistrationHelp: React.FC = () => {
	useResetHeaderState()
	const { t } = useTranslation()
	const { getMainPath } = useRouteHelper()

	return (
		<>
			<div className="center-view narrow-width flex flex--direction-column flex--align-items-center">
				<div className="page-title">
					<h1>
						<Trans i18nKey="view.registrationHelp.pageTitle" />
					</h1>
				</div>

				<div className="margin--top">
					<p className="text-color-cyan text--bold-spaced text-align--center">
						<Trans i18nKey="view.registrationHelp.stepByStep.headline" />
					</p>
					<p>
						<Trans i18nKey="view.registrationHelp.stepByStep.bodytext" />
					</p>
					<div className="flex margin--vertical">
						<Image
							srcSet={`${stepbystepPdfCover} 1x, ${stepbystepPdfCover_2x} 2x`}
							width="93"
							height="131"
							className="drop-shadow--bottom-3-steps margin--right"
							href={getZfVorsorgeRegistrierungsanleitungUrl()}
						/>

						<p>
							<Trans i18nKey="view.registrationHelp.stepByStep.pdfText" />
						</p>
					</div>
					<Button
						className="no-margin--top margin--bottom margin--large"
						type={ButtonType.primary}
						target="_blank"
						label={t('view.registrationHelp.stepByStep.buttonLabel')}
						href={getZfVorsorgeRegistrierungsanleitungUrl()}
					/>
				</div>

				<div className="margin--top">
					<p className="text-color-cyan text--bold-spaced text-align--center">
						<Trans i18nKey="view.registrationHelp.securityFocus.headline" />
					</p>
					<Trans i18nKey="view.registrationHelp.securityFocus.bodytext" />
				</div>

				<img
					className="margin--vertical"
					srcSet={`${infographicRegister} 1x, ${infographicRegister_2x} 2x`}
					width="356"
					height="746"
					alt=""
				/>

				<div className="margin--vertical">
					<p className="text-color-cyan text--bold-spaced text-align--center">
						<Trans i18nKey="view.registrationHelp.letters.headline" />
					</p>
					<p>
						<Trans i18nKey="view.registrationHelp.letters.bodytext" />
					</p>
				</div>

				<div className="flex flex--wrap flex--align-self-start drop-shadow--bottom-3-steps margin--small">
					<Image
						className="margin--left margin--small-negative"
						srcSet={`${anschreibenAnschreiben_01Teilnahme} 1x, ${anschreibenAnschreiben_01Teilnahme_2x} 2x`}
						width="101"
						height="141"
						alt=""
						href={getMusterZfCardUrl()}
					/>
					<Image
						className="margin--left"
						srcSet={`${anschreibenAnschreiben_02Initialpasswort} 1x, ${anschreibenAnschreiben_02Initialpasswort_2x} 2x`}
						width="101"
						height="141"
						alt=""
						href={getMusterInitPasswortUrl()}
					/>
				</div>

				<div className="margin--top margin--large">
					<p className="text-color-cyan text--bold-spaced text-align--center">
						<Trans i18nKey="view.registrationHelp.ready.headline" />
					</p>
					<p>
						<Trans i18nKey="view.registrationHelp.ready.bodytext" />
					</p>

					<Button
						type={ButtonType.primary}
						label={t('view.registrationHelp.ready.buttonLabel')}
						route={getMainPath(routesDictionary.register)}
					/>
				</div>
				{/* 
				<Modal
					ref={modal}
					header={t('view.register.intro.preparation.video.header')}
					buttonLabel={t('generic.close')}
					size="large"
					onModalClose={() => modalVideo.current?.pause()}
				>
					<video
						className="video margin no-margin--top"
						src={t('generic.registerHowToVideoSrcAlternativeLanguage')}
						poster={tutorialVideoPosterImage}
						controls={true}
						ref={modalVideo}
					/>
				</Modal> */}
			</div>
		</>
	)
}

export default RegistrationHelp
