import { HintsBox } from 'components/HintsBox'
import { useAuth } from 'hooks/useAuth'
import useAuthApi from 'hooks/useAuthApi'
import { useModal } from 'hooks/useModal'

import { t } from 'i18next'
import { useQueryParams } from 'raviger'
import { FC, FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import { trackPromise } from 'react-promise-tracker'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import Form, { FormFields, FormFieldType } from 'shared/components/Form'
import PasswordStrength from 'shared/components/PasswordStrength'
import { TextInputType } from 'shared/components/TextInput'
import numbersOnlyString from 'shared/helper/numbersOnlyString'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { UserGenericError, UserPasswordError } from 'shared/interfaces'
import { useNavigationTitle } from 'state/useHeaderState'
type TPasswordResetFormFieldValues = {
	identNumber?: string
	resetCode?: string
	emailAddress?: string
	newPassword?: string
	tanCode?: string
}

type FnPasswordReset = (data: TPasswordResetFormFieldValues) => Promise<TPasswordResetResponse | undefined>

export type TPasswordResetResponse = {
	identNumber: boolean
	state: boolean
	tanCode: boolean
	resetCode: boolean
	newPassword: boolean
	mfaAddress?: string
	errors?: {
		emailAddress?: UserGenericError
		identNumber?: UserGenericError
		tanCode?: UserGenericError
		resetCode?: UserGenericError
		newPassword?: UserPasswordError
	}
}

const PasswordReset: FC<{ id?: string }> = (props) => {
	const [queryParams] = useQueryParams()
	const { amplifyInitialized, getUserPoolId, initializeAmplify } = useAuth()
	const { getMainPath, currentPath, navigateTo } = useRouteHelper()
	const [currentStep, setCurrentStep] = useState<keyof typeof passwordResetSteps>(
		queryParams.resetCode && queryParams.identNumber ? 'setNewPassword' : 'enterIdentifier'
	)

	const api = useAuthApi()
	const filledOutFormInputs = useRef<TPasswordResetFormFieldValues>({})
	const mfaAddress = useRef<TPasswordResetResponse['mfaAddress']>()

	useNavigationTitle(<Trans i18nKey="view.passwordReset.navigationName"></Trans>)

	const passwordResetEndpoint = useMemo(async (): Promise<string> => {
		const configRequest = await fetch(process.env.REACT_APP_CONFIG_FILE_PATH as string)
		const config: Record<string, Record<string, string>> = await configRequest.json()

		return config['move37-rentnerportal']?.resetPasswordEndpoint
	}, [])

	const showConfirmModal = useModal({
		headline: t('view.passwordReset.successModal.headline'),
		content: t('view.passwordReset.successModal.content'),
		onCloseNavigationPath: getMainPath(routesDictionary.login || ''),
		type: 'success',
	})

	const showInvalidCodeModal = useModal({
		headline: t('view.passwordReset.invalidCodeModal.headline'),
		content: t('view.passwordReset.invalidCodeModal.content'),
		onCloseAction: () => {
			currentPath && navigateTo(currentPath, true, {})
			setCurrentStep('enterIdentifier')
		},

		buttonLabel: t('generic.next'),
	})

	const sendPasswordReset: FnPasswordReset = useCallback(
		async (data) => {
			const fetchUrl = await passwordResetEndpoint

			if (fetchUrl === undefined) {
				return
			}

			try {
				const request = await fetch(`${fetchUrl}`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(data),
				})
				const passwordResetResponse: TPasswordResetResponse = await request.json()

				return passwordResetResponse
			} catch (error) {}
		},
		[passwordResetEndpoint]
	)

	const handleResponse = ({
		response,
		fieldKeys,
	}: {
		response: Awaited<ReturnType<typeof sendPasswordReset> | false>
		fieldKeys: Partial<keyof TPasswordResetFormFieldValues>[]
	}) => {
		if (!response) {
			return false
		}

		const returnValue = {
			successful: true,
			errorMessages: [],
		}

		if (response.errors) {
			const errors = response.errors

			fieldKeys.forEach((key) => {
				if (key in errors) {
					const fieldError = errors[key]

					if (key === 'resetCode') {
						showInvalidCodeModal()
					}

					if (fieldError) {
						returnValue.successful = false
						returnValue.errorMessages.push(t(`generic.form.errors.${key}.${fieldError}`))
					}
				}
			})
		}

		if (currentStep === 'setNewPassword' && response.resetCode === false) {
			showInvalidCodeModal()
		}

		mfaAddress.current = response.mfaAddress

		return returnValue
	}

	const handleOnSubmit = async (submittedFields: TPasswordResetFormFieldValues) => {
		const mergedSubmittedFields = {
			identNumber: queryParams.identNumber ? `${queryParams.identNumber}` : undefined,
			resetCode: queryParams.resetCode ? queryParams.resetCode : undefined,
			...filledOutFormInputs.current,
			...submittedFields,
		}

		filledOutFormInputs.current = mergedSubmittedFields

		if (!amplifyInitialized) {
			const { userPoolId } = await getUserPoolId(mergedSubmittedFields.identNumber, true)
			await initializeAmplify(userPoolId)
		}

		const response = await trackPromise(api.resetPassword(mergedSubmittedFields), 'password-reset')

		return handleResponse({
			response,
			fieldKeys: Object.keys(mergedSubmittedFields) as Partial<keyof TPasswordResetFormFieldValues>[],
		})
	}

	const handleOnSuccess = async () => {
		if (currentStep === 'confirmWithMfa') {
			showConfirmModal()
			return
		}

		if (currentStep === 'setNewPassword') {
			setCurrentStep('confirmWithMfa')
		}
	}

	useEffect(() => {
		if (!queryParams.resetCode && !queryParams.identNumber && currentStep !== 'enterIdentifier') {
			setCurrentStep('enterIdentifier')
		}

		if (queryParams.resetCode) {
			if (!queryParams.identNumber) {
				showInvalidCodeModal()
				return
			}

			if (currentStep !== 'setNewPassword') {
				setCurrentStep('setNewPassword')
			}

			handleOnSubmit({})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParams.resetCode, queryParams.identNumber])

	const currentPasswordResetStep = currentStep ? passwordResetSteps[currentStep] : null

	if (!currentPasswordResetStep) {
		return null
	}

	const CurrentPasswordResetStepComponent = currentPasswordResetStep.component

	return (
		<div>
			<div className="center-view">
				<CurrentPasswordResetStepComponent
					id={props.id}
					handleOnSubmit={handleOnSubmit}
					handleOnSuccess={handleOnSuccess}
					mfaAddress={mfaAddress.current}
				/>
			</div>
		</div>
	)
}

type TPasswordResetStep = {
	id?: string
	handleOnSubmit: (submittedFields: TPasswordResetFormFieldValues) => Promise<
		| false
		| {
				errorMessages: string[]
				successful: boolean
		  }
	>
	handleOnSuccess: () => void
	mfaAddress?: TPasswordResetResponse['mfaAddress']
}

const EnterIdentifier: FunctionComponent<TPasswordResetStep> = (props) => {
	const [queryParams] = useQueryParams()
	const [emailSent, setEmailSent] = useState(false)
	const [errorMessage, setErrorMessages] = useState<string[] | undefined>()

	// useEffect(() => {
	// 	currentPath && navigateTo(currentPath, true, {})
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [queryParams.id])

	const handleOnSubmit = async (submittedFields: TPasswordResetFormFieldValues) => {
		const response = await props.handleOnSubmit(submittedFields)

		if (typeof response === 'object' && response.successful === true) {
			setEmailSent(true)

			return {
				abort: true,
			}
		}

		return response
	}

	const handleOnIdentifierChange = useCallback(() => {
		if (emailSent === false) {
			return
		}

		setEmailSent(false)
	}, [emailSent])

	useEffect(() => {
		if (!queryParams.email) {
			return
		}

		const response = handleOnSubmit({ emailAddress: queryParams.email }) as any

		if (response && response?.successful) {
			setErrorMessages([`apiErrors.${response?.successful}`])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParams.email])

	const inputElements: FormFields = {
		emailAddress: {
			value: queryParams?.email,
			required: true,
			label: t('generic.form.fields.email.label'),
			fieldType: FormFieldType.text,
			type: TextInputType.email,
			placeholder: t('view.profile.changeEmail.placeholder'),
			onChange: handleOnIdentifierChange,
		},
	}

	return (
		<div className="form-view">
			<h2 className="medium-content-width">
				{emailSent ? (
					<Trans i18nKey="view.passwordReset.steps.enterIdentifier.headlineSent" />
				) : (
					<Trans i18nKey="view.passwordReset.steps.enterIdentifier.headline" />
				)}
			</h2>

			<div className="flex--m gap gap--extra-large flex--grow">
				{emailSent && (
					<HintsBox style={{ order: '1' }}>
						<Trans i18nKey="view.passwordReset.steps.enterIdentifier.copytext" />
					</HintsBox>
				)}

				{!emailSent && (
					<Form
						fields={inputElements}
						onSubmit={handleOnSubmit}
						submitLabel={t('generic.next')}
						errorMessages={errorMessage}
						alwaysAllowSubmit
						promiseTracker={{ area: 'password-reset' }}
					/>
				)}
			</div>
		</div>
	)
}

const SetNewPassword: FunctionComponent<TPasswordResetStep> = (props) => {
	const { handleOnSuccess } = props
	const [password, setPassword] = useState<string | undefined>()
	const [passwordIsValid, setPasswordIsValid] = useState<boolean>(false)

	const inputElements: FormFields = {
		newPassword: {
			fieldType: FormFieldType.text,
			type: TextInputType.password,
			label: t('generic.form.fields.newPassword.label'),
			required: true,
			autoComplete: 'new-password',
			onChange: (e: any) => {
				setPassword(e.target.value)
			},
		},
		confirmNewPassword: {
			fieldType: FormFieldType.text,
			type: TextInputType.password,
			label: t('generic.form.fields.confirmNewPassword.label'),
			required: true,
		},
	}

	const handleOnSubmit = (submittedFields: TPasswordResetFormFieldValues & { confirmNewPassword: string }) => {
		const { newPassword, confirmNewPassword } = submittedFields

		const values = [newPassword, confirmNewPassword]
			.filter((v) => v)
			.map((v) => (typeof v === 'string' ? v.trim().replace(' ', '') : null))

		if (values.length !== 2) {
			return false
		}

		if (values[0] !== values[1]) {
			return {
				errorMessages: [t(`generic.form.errors.password.DO_NOT_MATCH`)],
				successful: false,
			}
		}

		if (passwordIsValid === false) {
			return {
				errorMessages: [t(`generic.form.errors.password.INVALID`)],
				successful: false,
			}
		}

		return props.handleOnSubmit({ newPassword })
	}

	return (
		<div className="form-view">
			<h2 className="medium-content-width">
				<Trans i18nKey="view.passwordReset.steps.setNewPassword.headline" />
			</h2>
			<div className="flex--m flex--grow gap gap--extra-large">
				<Form
					fields={inputElements}
					onSubmit={handleOnSubmit}
					onSuccess={handleOnSuccess}
					submitLabel={t('generic.next')}
					alwaysAllowSubmit
					promiseTracker={{ area: 'password-reset' }}
				/>
				<HintsBox>
					<PasswordStrength password={password} onIsPasswordValid={setPasswordIsValid}>
						<p className="no-margin--top">
							<Trans i18nKey="component.passwordStrength.hint" />
						</p>
					</PasswordStrength>
				</HintsBox>
			</div>
		</div>
	)
}

const ConfirmWithMfa: FunctionComponent<TPasswordResetStep> = (props) => {
	const { handleOnSubmit, handleOnSuccess, mfaAddress } = props
	const [tanCode, setTanCode] = useState<string | undefined>()

	const handleOnResendTan = () => {
		setTanCode('')

		handleOnSubmit({ tanCode: undefined })
	}

	const inputElements: FormFields = {
		tanCode: {
			value: tanCode,
			required: true,
			inputMode: 'decimal',
			pattern: '[0-9,.]*',
			label: t('generic.form.fields.mfa.label'),
			autoComplete: 'one-time-code',
			fieldType: FormFieldType.text,
			valueFunction: {
				name: numbersOnlyString,
			},
		},
	}

	return (
		<div className="form-view">
			<div className="flex--m flex--grow margin--vertical">
				<h2 className="medium-content-width no-margin">
					<Trans i18nKey="view.passwordReset.steps.confirmWithMfa.headline" values={{ mfaAddress }} />
				</h2>
				{/* <Image className="hidden-medium--lt" srcSet={illustrationSms} width="220" alt="" /> */}
			</div>

			<div className="flex--m flex--grow gap gap--extra-large">
				<Form
					fields={inputElements}
					onSubmit={handleOnSubmit}
					onSuccess={handleOnSuccess}
					submitLabel={t('generic.next')}
					updatedValues={{ tanCode }}
					alwaysAllowSubmit
					promiseTracker={{ area: 'password-reset' }}
				>
					{' '}
					<HintsBox style={{ order: '1' }}>
						<div className="text-align--center">
							<Trans
								i18nKey="view.passwordReset.steps.confirmWithMfa.hint"
								values={{
									mfaAddress,
								}}
								components={{
									resendCode: (
										<Button
											className="margin--top"
											onClick={handleOnResendTan}
											type={[ButtonType.secondary, ButtonType.small]}
										/>
									),
								}}
							/>
						</div>
					</HintsBox>
				</Form>
			</div>
		</div>
	)
}

const passwordResetSteps = {
	enterIdentifier: { name: 'enterIdentifier', component: EnterIdentifier },
	setNewPassword: { name: 'setNewPassword', component: SetNewPassword },
	confirmWithMfa: { name: 'confirmWithMfa', component: ConfirmWithMfa },
}

export default PasswordReset
