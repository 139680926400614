import classnames from 'classnames'
import { IconType } from 'components/Icons'
import { FunctionComponent, HTMLAttributes, PropsWithChildren, useEffect, useMemo } from 'react'
import { Trans } from 'react-i18next'
import Icon from './Icon'

const PasswordStrength: FunctionComponent<
	PropsWithChildren<
		{ password?: string; onIsPasswordValid?: (valid: boolean) => void } & HTMLAttributes<HTMLDivElement>
	>
> = (props) => {
	const { password, onIsPasswordValid, children, className } = props

	const passwordStatus = useMemo(() => {
		return {
			numbers: password ? new RegExp('(?=.*[0-9])').test(password) : false,
			lower: password ? new RegExp('(?=.*[a-z])').test(password) : false,
			capital: password ? new RegExp('(?=.*[A-Z])').test(password) : false,
			special: password ? new RegExp('(?=.*[!@#$&*=_%?|\\-\\\\/{}[\\]+])').test(password) : false,
			length: password ? new RegExp('(?=.{10,})').test(password) : false,
		}
	}, [password])

	useEffect(() => {
		const passwordIsValid = Object.values(passwordStatus).some((value) => value === false) === false

		onIsPasswordValid?.(passwordIsValid)
	}, [passwordStatus, onIsPasswordValid])

	return (
		<div className={classnames('password-strength', className)}>
			{children}
			{Object.keys(passwordStatus).map((key, i) => (
				<div className="margin--vertical margin--medium" key={'password-strength-' + i}>
					<Icon
						color="var(--color--green)"
						type={
							passwordStatus[key as keyof typeof passwordStatus]
								? IconType.listMarkerCheck
								: IconType.listMarkerNeutral
						}
					></Icon>{' '}
					<span className="margin margin--left">
						<Trans i18nKey={`component.passwordStrength.conditions.${key}`}></Trans>
					</span>
				</div>
			))}
		</div>
	)
}

export default PasswordStrength
