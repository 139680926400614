import { IconType } from 'components/Icons'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import Icon from 'shared/components/Icon'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'

const MigrationSuccess: React.FC = () => {
	const { navigateTo } = useRouteHelper(routesDictionary.payoutOptions)

	const onClick = async () => {
		navigateTo('/logout')
	}

	return (
		<div className="grid center-view payout-success">
			<div className="grid__top"></div>
			<div className="grid__center">
				<h3>
					<b>
						<Trans i18nKey="view.migration.success.headline"></Trans>
					</b>
				</h3>
				<Icon
					type={IconType.success}
					color={'var(--color-cyan)'}
					className="center-view margin--top margin--large"
				></Icon>
				<p className="text-align--center margin--top">
					<Trans i18nKey="view.migration.success.body"></Trans>
				</p>
			</div>
			<div className="grid__bottom">
				<Button
					className="margin--top margin--large"
					type={ButtonType.primary}
					label={t('view.migration.success.button')}
					onClick={onClick}
				></Button>
			</div>
		</div>
	)
}

export default MigrationSuccess
