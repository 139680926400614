import { IconType } from 'components/Icons'
import React from 'react'
import Icon from 'shared/components/Icon'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import useHeaderState from 'state/useHeaderState'
import { HeaderState } from '../state/useHeaderState'

const BackButton: React.FC = () => {
	const { navigateTo } = useRouteHelper()
	const [backButtonPath] = useHeaderState<HeaderState['backButtonPath']>((state: HeaderState) => state.backButtonPath)
	const [backButtonClickHandler] = useHeaderState<HeaderState['backButtonClickHandler']>(
		(state: HeaderState) => state.backButtonClickHandler
	)

	const getClassNames = (): string => {
		const classPrefix: string = 'back-button--'
		const classes: string[] = ['back-button']

		if (undefined === backButtonPath && undefined === backButtonClickHandler) {
			classes.push(classPrefix + 'hidden')
		}

		return classes.join(' ')
	}

	const onClickHandler = () => {
		if (undefined !== backButtonClickHandler) {
			return backButtonClickHandler.name(backButtonClickHandler.parameters)
		}

		if (undefined !== backButtonPath) {
			return navigateTo(backButtonPath)
		}
	}

	return (
		<div className={getClassNames()} onClick={onClickHandler}>
			<Icon type={IconType.arrow} color="var(--color-cyan)" rotate={180} />
		</div>
	)
}

export default BackButton
