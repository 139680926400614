import { useRoutes } from 'raviger'
import React, { useEffect } from 'react'
import routesDictionary from 'routes'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ProfileOverview from './ProfileOverview'

const Profile: React.FC = () => {
	const { routes, getMainPath, navigateTo, getChildPath } = useRouteHelper(routesDictionary.profile)

	const routeResult = useRoutes(routes, { basePath: getMainPath(routesDictionary.profile) })

	useEffect(() => {
		return navigateTo(getChildPath(routesDictionary.profile, 'profileOverview'))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return routeResult || <ProfileOverview />
}

export default Profile
