import { activatedFeatures } from 'helper/activatedFeatures'
import { availableLanguages } from 'i18n'
import i18next from 'i18next'
import { useState } from 'react'

export const LanguageSwitch = () => {
	const [lang, setLang] = useState<string>(i18next.language)

	const reload = (): void => {
		window.location.reload()
	}

	if (!activatedFeatures.deepl) {
		return <div className="lang-switch"></div>
	}

	return (
		<div className="lang-switch">
			{availableLanguages.map((v) => {
				return (
					<span
						key={v.code}
						className={`${
							v.code === lang ? 'font-family-alternative--medium' : 'font-family-alternative--light'
						}`}
						onClick={() => {
							setLang(v.code)
							i18next.changeLanguage(v.code, reload)
						}}
					>
						{v.key}
					</span>
				)
			})}
		</div>
	)
}
