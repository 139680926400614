import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import Checkbox from 'shared/components/Checkbox'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'

const RBPartnerConditions: React.FC = () => {
	useBackButtonPath()
	useNavigationTitle(<Trans i18nKey="view.riskBenefit.pageTitle.default" />)

	const { t } = useTranslation()
	const [conditionsRead, setConditionsRead] = useState<boolean>(false)
	const { getChildPath, navigateTo } = useRouteHelper()

	const onClickHandler = () => {
		navigateTo(getChildPath(routesDictionary.riskBenefit, 'RBPartnerSummary'))
	}

	return (
		<div className="risk-benefit center-view grid grid--center-scroll rb-partner-conditions">
			<h3 className="text-align--left grid__top">
				<Trans i18nKey="view.riskBenefit.partner.headline" />
			</h3>

			<div className="grid__center">
				<p>
					<b>
						<Trans i18nKey="view.riskBenefit.partner.setPartner" />
					</b>
				</p>
				<Trans i18nKey="view.riskBenefit.partner.conditions" />

				<Checkbox
					className="font-size-default margin"
					label={t('generic.condtionsRead')}
					value={1}
					checked={conditionsRead}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConditionsRead(e.target.checked)}
				/>
			</div>

			<div className="grid__bottom">
				<Button
					onClick={onClickHandler}
					type={ButtonType.primary}
					label={t('generic.next')}
					disabled={!conditionsRead}
				/>
			</div>
		</div>
	)
}

export default RBPartnerConditions
