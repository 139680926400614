import { Auth } from 'aws-amplify'
import i18next from 'i18next'
import { useState } from 'react'

export const useLanguagePref = () => {
	const [language, setLang] = useState<string>(i18next.language)

	const checkLanguageSetting = (userData: any) => {
		if (!userData || !userData['custom:preferredLanguage']) {
			saveLanguageSetting(i18next.language)
			return
		}

		if (userData['custom:preferredLanguage'] !== i18next.language) {
			switchLanguage(userData['custom:preferredLanguage'])
			setLang(userData['custom:preferredLanguage'])

			window.location.reload()
		}
	}

	const saveLanguageSetting = async (language: string) => {
		const user = await Auth.currentAuthenticatedUser()
		await Auth.updateUserAttributes(user, {
			'custom:preferredLanguage': language,
		})
	}

	const switchLanguage = (language: string) => {
		if (language === i18next.language) {
			return
		}

		i18next.changeLanguage(language)
		setLang(language)
	}

	return { checkLanguageSetting, saveLanguageSetting, language, switchLanguage }
}
