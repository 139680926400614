import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath } from 'state/useHeaderState'

const FormStepTaxes: React.FC<{
	onClick: () => void
}> = (props) => {
	const { t } = useTranslation()
	const { getChildPath } = useRouteHelper()
	useBackButtonPath(`${getChildPath(routesDictionary.payoutOptions, 'overview')}`)
	return (
		<div className="payout-input-intro">
			<p className="text-color-cyan font-size-small">
				<Trans i18nKey="view.PayoutInputForm.intro.info"></Trans>
			</p>
			<h3>
				<b>{t('view.PayoutInputForm.intro.headline')}</b>
			</h3>
			<div className="payout-input-intro__list">
				<Trans i18nKey="view.PayoutInputForm.intro.list"></Trans>
			</div>
			<Button
				onClick={props.onClick}
				type={ButtonType.primary}
				label={<Trans i18nKey="view.PayoutInputForm.intro.button"></Trans>}
			></Button>
		</div>
	)
}

export default FormStepTaxes
