import classNames from 'classnames'
import React, { HTMLAttributes } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Image from 'shared/components/Image'
import Tooltip from 'shared/components/Tooltip'
import anschreibenAnschreiben_03ZftanService from '../../../assets/images/anschreiben-anschreiben-03-zftan-service.png'
import anschreibenAnschreiben_03ZftanService_2x from '../../../assets/images/anschreiben-anschreiben-03-zftan-service@2x.png'
import { getMusterIdPinUrl } from 'shared/static-assets'

const SendMfaRegisterInfotext: React.FC<HTMLAttributes<HTMLDivElement>> = ({ className }) => {
	const { t } = useTranslation()

	return (
		<div className={classNames(className, 'margin--vertical')}>
			<p className="text-color-cyan">
				<Trans i18nKey="view.register.sendMfa.infotext.part1" />
			</p>
			<div className="flex margin--vertical">
				<Image
					className="margin--small-negative margin--left margin--top drop-shadow--bottom-3-steps"
					srcSet={`${anschreibenAnschreiben_03ZftanService} 1x, ${anschreibenAnschreiben_03ZftanService_2x} 2x`}
					width="101"
					height="141"
					alt=""
					href={getMusterIdPinUrl()}
				/>

				<div className="margin--left">
					<p>
						<Trans i18nKey="view.register.sendMfa.infotext.part2" />
					</p>
					<div className="margin--vertical margin--small">
						<Tooltip
							label={t('view.register.letterHelp.header')}
							headline={t('view.register.letterHelp.header')}
							content={
								<Trans
									i18nKey="generic.helpHotline.content"
									values={{
										emailAddress: t('generic.helpHotline.mail'),
										phoneNumber: t('generic.helpHotline.tel'),
									}}
								>
									<a href={t('generic.helpHotline.telLink')}>{'  '}</a>
									<a href={t('generic.helpHotline.mailLink')}>{'  '}</a>
								</Trans>
							}
						/>
					</div>
				</div>
			</div>
			<p className="text-color-cyan">
				<Trans i18nKey="view.register.sendMfa.infotext.part3" />
			</p>
		</div>
	)
}

export default SendMfaRegisterInfotext
