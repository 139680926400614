import useApi, { QueryKey } from 'hooks/useApi'
import { useAuth } from 'hooks/useAuth'
import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { uid } from 'react-uid'
import Checkbox from 'shared/components/Checkbox'
import Icon, { IconSize } from 'shared/components/Icon'
import Modal, { ModalRefActions } from 'shared/components/Modal'
import { getSessionStorage, setSessionStorage } from 'shared/helper/sessionStorage'
import { IconType } from './Icons'

const DisclaimerModal: FunctionComponent = () => {
	const { t } = useTranslation()
	const modal = useRef<ModalRefActions>()

	const auth = useAuth()
	const api = useApi()
	const { data } = useQuery(QueryKey.appSetup, api.getAppSetup, {
		enabled: auth.userInitialized && undefined === getSessionStorage('disclaimerShown'),
	})
	const [dontShowAgain, setDontShowAgain] = useState<boolean>(false)

	useEffect(() => {
		if (data?.disclaimer) {
			modal.current?.openModal()
		}
	}, [data])

	if (!data?.disclaimer) {
		return null
	}

	const onCloseModal = () => {
		if (true === dontShowAgain) {
			api.patchAppSetup({ neverShowDisclaimerAgain: data.disclaimer })
		}

		setSessionStorage('disclaimerShown', true)
	}

	return (
		<Modal
			ref={modal}
			className="disclaimer-modal no-auto-hyphens"
			header={t('component.disclaimerModal.header')}
			headerClassName="no-margin--bottom"
			buttonLabel={t('component.disclaimerModal.buttonLabel')}
			onButtonClick={onCloseModal}
			forceAction={true}
		>
			<Icon
				className="flex--align-self-center no-margin--top"
				type={IconType.alert}
				color="var(--color-cyan)"
				size={IconSize.large}
			/>
			<div className="margin text-align--left">
				<h3 className="text-color-cyan no-margin--top">{t('component.disclaimerModal.headline')}</h3>

				{Object.keys(t(`component.disclaimerModal.disclaimer.${data.disclaimer}`, { returnObjects: true })).map(
					(key, index) => (
						<p key={uid(key, index)}>
							<Trans i18nKey={`component.disclaimerModal.disclaimer.${data.disclaimer}.${key}`} />
						</p>
					)
				)}

				<Checkbox
					className="margin--top"
					label={t('component.disclaimerModal.doNotShowAgain')}
					checked={dontShowAgain}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						const { checked } = e.target
						setDontShowAgain(checked)
					}}
				/>
			</div>
		</Modal>
	)
}

export default DisclaimerModal
