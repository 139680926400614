import { IconType } from 'components/Icons'
import useApi, { QueryKey } from 'hooks/useApi'
import { useUserGroup } from 'hooks/useUserGroup'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import routesDictionary from 'routes'
import SubmenuBar, { SubmenuBarProps } from 'shared/components/SubmenuBar'
import SubmenuBarGroup from 'shared/components/SubmenuBarGroup'
import { RiskOptions } from 'shared/enums'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { IRiskOption } from 'shared/interfaces'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'

const RBSelection: React.FC = () => {
	const { getChildPath, getMainPath } = useRouteHelper()
	const { noBEMGR } = useUserGroup()

	useBackButtonPath(getMainPath(routesDictionary.dashboard))
	useNavigationTitle(<Trans i18nKey="view.riskBenefit.pageTitle.default" />)

	const api = useApi()
	const { t } = useTranslation()

	const { data: riskOptions }: { data?: IRiskOption[] } = useQuery(api.getQuery(QueryKey.riskOptions))

	const initialSubmenuItems = {
		[RiskOptions.disabilityBenefit]: {
			headline: <Trans i18nKey="view.riskBenefit.selection.submenuItems.disabilityBenefit" />,
			route: getChildPath(routesDictionary.riskBenefit, 'RBDisabilityBenefit'),
			icon: IconType.actionDisability,
		},
		[RiskOptions.partnerBenefit]: {
			headline: <Trans i18nKey="view.riskBenefit.selection.submenuItems.partnerBenefit" />,
			route: getChildPath(routesDictionary.riskBenefit, 'RBPartnerBenefit'),
			icon: IconType.actionDeath,
		},
		[RiskOptions.designatePartner]: {
			headline: <Trans i18nKey="view.riskBenefit.selection.submenuItems.designatePartner" />,
			route: getChildPath(routesDictionary.riskBenefit, 'RBPartnerForm'),
			icon: IconType.actionPartner,
		},
	}

	const [submenuItems, setSubmenuItems] = useState<{ [key in RiskOptions]: SubmenuBarProps }>()

	/**
	 * after riskOptions are fetched set the subheadline
	 */
	useEffect(() => {
		if (undefined === riskOptions) {
			return
		}

		// map RiskOptions to their current values for translations
		const currentOptions = Object.values(RiskOptions).reduce(
			(options: { [key: string]: IRiskOption | undefined }, riskOption: string) => {
				options[riskOption] = riskOptions?.find((option) => option.type === riskOption)

				return options
			},
			{}
		)

		// add subheadline to submenuItems by getting the current value from queried riskOptions
		const updatedSubmenuItems = Object.keys(initialSubmenuItems).reduce(
			(acc: { [key: string]: SubmenuBarProps }, key: string) => {
				const currentItem = initialSubmenuItems[key as RiskOptions]
				const isRouteDefined = undefined !== currentItem.route
				const type = currentOptions[key]?.currentOption
				const count = currentOptions[key]?.currentDuration

				if (false === isRouteDefined) {
					return acc
				}

				let subheadline

				switch (key) {
					case RiskOptions.designatePartner:
						subheadline = type
						break

					default:
						subheadline = type
							? `${t('view.riskBenefit.selection.yourSelection')} ${t(
									`view.riskBenefit.types.${type}.label`
							  )} ${count && count > 0 ? t('generic.year', { count }) : ''}`
							: undefined
				}

				acc[key] = {
					...currentItem,
					subheadline,
				}

				return acc
			},
			{}
		)
		setSubmenuItems(updatedSubmenuItems as { [key in RiskOptions]: SubmenuBarProps })

		// eslint-disable-next-line
	}, [riskOptions])

	return (
		<div className="risk-benefit  center-view rb-selection">
			<h3 className="text-align--left">
				<Trans i18nKey="view.riskBenefit.selection.headline" />
			</h3>

			{noBEMGR ? (
				<p>
					<Trans i18nKey={'generic.noBEMGRDisclaimer'}></Trans>
				</p>
			) : (
				<SubmenuBarGroup>
					{submenuItems &&
						Object.values(submenuItems).map((item, index) => (
							<SubmenuBar key={`submenu-item-${index}`} {...item} />
						))}
				</SubmenuBarGroup>
			)}
		</div>
	)
}

export default RBSelection
