import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Modal, { ModalRefActions } from 'shared/components/Modal'

const AppUpdateModal: FunctionComponent = () => {
	const { t } = useTranslation()
	const modal = useRef<ModalRefActions>()
	const [appUpdateAvailable, setAppUpdateAvailable] = useState<boolean>(false)

	useEffect(() => {
		const messageListener = (e: any) => {
			if (e.origin !== window.location.origin) {
				return
			}

			if (e.data && true === e.data.appUpdateAvailable) {
				setAppUpdateAvailable(true)
				window.removeEventListener('message', messageListener)
			}
		}

		window.addEventListener('message', messageListener)

		return () => window.removeEventListener('message', messageListener)
	}, [])

	useEffect(() => {
		if (true === appUpdateAvailable) {
			modal.current?.openModal()
		}
	}, [appUpdateAvailable])

	if (false === appUpdateAvailable) {
		return null
	}

	return (
		<Modal
			ref={modal}
			forceAction={true}
			className="modal--reload"
			header={t('component.appUpdateModal.header')}
			onButtonClick={() => window.location.reload()}
			onModalClose={() => setAppUpdateAvailable(false)}
			buttonLabel={t('component.appUpdateModal.buttonLabel')}
		>
			<p className="padding no-padding--top">
				<Trans i18nKey="component.appUpdateModal.bodytext" />
			</p>
		</Modal>
	)
}

export default AppUpdateModal
