import useApi, { QueryKey } from 'hooks/useApi'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { uid } from 'react-uid'
import routesDictionary from 'routes'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { components } from 'types/api-interface'
import FormStepBankAccount from './FormStepBankAccount'
import FormStepHealthInsurance from './FormStepHealthInsurance'
import FormStepIntro from './FormStepIntro'
import FormStepPerson from './FormStepPerson'
import FormStepSocialInsurance from './FormStepSocialInsurance'
import FormStepSummary from './FormStepSummary'
import FormStepTaxes from './FormStepTaxes'

const PayoutModelInputForm: React.FC<{ step?: number }> = (props) => {
	const { getChildPath, navigateTo } = useRouteHelper()
	const queryClient = useQueryClient()
	const api = useApi()
	const [step, setStep] = useState<number>(props.step ? Number(props.step) : 0)
	const { data, status } = useQuery(QueryKey.payoutModelInProgress, api.getPayoutModelInProgress)
	const userDataQuery = useQuery(QueryKey.userProfile, api.getUserProfile)
	const { data: isEditable } = useQuery(QueryKey.payoutModelEditable, api.isPayoutModelEditable)
	const mutation = useMutation(api.patchPayoutModel, {
		onSuccess: (data) => {
			queryClient.setQueryData(QueryKey.payoutModelInProgress, data)
			nextStep()
		},
	})

	useEffect(() => {
		props.step && setStep(props.step)
	}, [props.step])

	const updateData = (data: components['schemas']['AuszahlungsoptionenArbeitskopie'] | null) => {
		if (!data) {
			nextStep()
			return
		}

		mutation.mutate(data)
	}

	const nextStep = () => {
		console.log(status)
		const stepNum = Number(step)
		if (stepNum + 1 < steps.length) {
			navigateTo(`${getChildPath(routesDictionary.payoutOptions, 'payoutModelInputForm')}/${stepNum + 1}`)
		}
	}

	const steps = [
		<FormStepIntro onClick={nextStep} />,
		<FormStepPerson
			onSubmit={updateData}
			data={data}
			userData={userDataQuery.data}
			editable={isEditable?.editable}
		/>,
		<FormStepBankAccount data={data} onSubmit={updateData} editable={isEditable?.editable} />,
		<FormStepTaxes data={data} onSubmit={updateData} editable={isEditable?.editable} />,
		<FormStepSocialInsurance data={data} onSubmit={updateData} editable={isEditable?.editable} />,
		<FormStepHealthInsurance data={data} onSubmit={updateData} editable={isEditable?.editable} />,
		<FormStepSummary />,
	]

	return (
		<div className="center-view grid">
			<div className="grid__top">
				<div className="payout-input-progress">
					{steps.map((v, i) => (
						<div
							key={uid(v)}
							className={`payout-input-progress__step ${
								i <= Number(step) && 'payout-input-progress__step--active'
							}`}
						></div>
					))}
				</div>
			</div>
			<div className="grid__center">
				<div className="payout-model-form "> {steps[step]}</div>
			</div>
		</div>
	)
}

export default PayoutModelInputForm
