import { IconType } from 'components/Icons'
import TermsAndConditions from 'components/TermsAndConditions'
import React, { FunctionComponent, useState } from 'react'
import { Trans } from 'react-i18next'
import Button, { ButtonType } from 'shared/components/Button'

import Icon from 'shared/components/Icon'

const UpdatedTermsAndConditionsModalContent: FunctionComponent<{ onAccept: () => void; onDecline: () => void }> = ({
	onAccept,
	onDecline,
}) => {
	const [showChanges, setShowChanges] = useState(true)

	return (
		<div className="faq-changed-modal padding ">
			<Icon type={IconType.change}></Icon>
			<div className="text-align--left">
				<TermsAndConditions showChanges={true} />
			</div>
			<Button
				className="faq-changed-modal__show-all margin--top"
				type={[ButtonType.small]}
				onClick={() => {
					setShowChanges(showChanges ? false : true)
				}}
			>
				<Trans i18nKey="view.profile.termsAndConditionsModal.showAllButtonLabel"></Trans>
				<Icon type={IconType.arrow} rotate={showChanges ? 90 : -90}></Icon>
			</Button>{' '}
			{!showChanges && <TermsAndConditions showChanges={false} />}
			{/* <Checkbox
				className="margin--top flex flex--justify-content-center margin--large"
				onChange={(e: any) => {
					setChecked(e?.target?.checked ? true : false)
				}}
				checked={false}
				label={<h3 className="no-margin">Ich akzeptiere die geänderten Nutzungsbedingungen</h3>}
			></Checkbox> */}
			<div className="faq-changed-modal__button__small margin--top ">
				<Button type={ButtonType.link} className="faq-changed-modal__decline" onClick={onDecline}>
					<Trans i18nKey="view.profile.termsAndConditionsModal.declineTerms"></Trans>
				</Button>{' '}
				<Button onClick={onAccept} type={ButtonType.primary} className="faq-changed-modal__accept">
					<Trans i18nKey="view.profile.termsAndConditionsModal.accept"></Trans>
				</Button>{' '}
			</div>
		</div>
	)
}

export default UpdatedTermsAndConditionsModalContent
