import { Auth } from 'aws-amplify'
import { HintsBox } from 'components/HintsBox'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { trackPromise } from 'react-promise-tracker'
import routesDictionary from 'routes'
import Form, { FormFields, FormFieldType } from 'shared/components/Form'
import { TextInputType } from 'shared/components/TextInput'

import { FormSubmitFields } from 'shared/hooks/useForm'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath, useResetHeaderState } from 'state/useHeaderState'
import { supportedPhoneNumberCountries, phoneNumberDefaultCountry } from 'supported-phone-number-countries'

const Phone: React.FunctionComponent = () => {
	useResetHeaderState()

	const { navigateTo, getChildPath } = useRouteHelper()
	useBackButtonPath(getChildPath(routesDictionary.migration, 'newEmail'))
	const [debugVal, setDebugVal] = useState()

	const { t } = useTranslation()
	const [errorMessages] = useState<string[]>()

	const inputElements: FormFields = {
		phone: {
			value: '+49',
			required: true,
			type: TextInputType.text,
			usePhoneInput: true,
			label: t('view.migration.phone.inputLabel'),
			fieldType: FormFieldType.phoneInput,
			phoneInputProps: {
				countries: supportedPhoneNumberCountries,
				defaultCountry: phoneNumberDefaultCountry,
				addInternationalOption: false,
				international: true,
			},

			onChange: (v: any) => {
				setDebugVal(v.target.value)
			},
		},
	}

	const onSubmit = async (submittedFields: FormSubmitFields) => {
		return trackPromise(sendPhoneNumber(submittedFields), 'sendPhoneNumber')
	}

	const sendPhoneNumber = async (submittedFields: FormSubmitFields) => {
		try {
			const phone = submittedFields?.phone

			if (!phone) {
				return { successful: false, status: 500 }
			}

			const user = await Auth.currentAuthenticatedUser()
			await Auth.deleteUserAttributes(user, ['phone_number'])
			await Auth.updateUserAttributes(user, {
				phone_number: phone,
			})

			return { successful: true, status: 200 }
		} catch (e) {
			console.log(e)
			return { successful: false, status: 500 }
		}
	}

	return (
		<div className="form-view no-auto-hyphens">
			<div className="form-view__title">
				<h1>
					<Trans i18nKey="view.migration.phone.headline" />
				</h1>
			</div>

			<Form
				className="form-view"
				fields={inputElements}
				onSubmit={onSubmit}
				onError={(error: any) => {
					console.log(error)
				}}
				errorMessages={errorMessages}
				onSuccess={() => {
					navigateTo(`${getChildPath(routesDictionary.migration, 'enterTextMessage')}`)
				}}
				submitLabel={t('generic.send')}
				promiseTracker={{ area: 'sendPhoneNumber' }}
			>
				<div className="form-view__bodytext margin--vertical">
					<p>{debugVal && <>Debug: {debugVal}</>}</p>

					<>
						<>
							<p>
								<Trans i18nKey="view.migration.phone.bodyText" />
							</p>

							<p>
								<Trans i18nKey="view.migration.phone.hint" />
							</p>

							<HintsBox>
								<p className="flex flex--align-items-start">
									<Trans i18nKey="generic.form.fields.phoneNr.disclaimer" />
								</p>
							</HintsBox>
						</>
					</>
				</div>
			</Form>
		</div>
	)
}

export default Phone
