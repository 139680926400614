import { IconType } from 'components/Icons'
import { useAuth } from 'hooks/useAuth'
import React, { Fragment, useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { uid } from 'react-uid'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import Checkbox from 'shared/components/Checkbox'
import Icon from 'shared/components/Icon'
import Image from 'shared/components/Image'
import Modal, { ModalRefActions } from 'shared/components/Modal'
import Tooltip from 'shared/components/Tooltip'
import { ApiErrorCode } from 'shared/enums'
import useGlobalFormState from 'shared/hooks/useGlobalFormState'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import anschreibenAnschreiben_01Teilnahme from '../../assets/images/anschreiben-anschreiben-01-teilnahme.png'
import anschreibenAnschreiben_01Teilnahme_2x from '../../assets/images/anschreiben-anschreiben-01-teilnahme@2x.png'
import anschreibenAnschreiben_02Initialpasswort from '../../assets/images/anschreiben-anschreiben-02-initialpasswort.png'
import anschreibenAnschreiben_02Initialpasswort_2x from '../../assets/images/anschreiben-anschreiben-02-initialpasswort@2x.png'
import anschreibenAnschreiben_03ZftanService from '../../assets/images/anschreiben-anschreiben-03-zftan-service.png'
import anschreibenAnschreiben_03ZftanService_2x from '../../assets/images/anschreiben-anschreiben-03-zftan-service@2x.png'
import registrationHelpVideoThumb from '../../assets/images/registration-help-video-thumb.jpg'
import registrationHelpVideoThumb_2x from '../../assets/images/registration-help-video-thumb@2x.jpg'
import tutorialVideoPosterImage from '../../assets/images/tutorial-poster-image.jpg'
import { getMusterIdPinUrl, getMusterInitPasswortUrl, getMusterZfCardUrl } from 'shared/static-assets'

const RegistrationIntro: React.FC = () => {
	const { t } = useTranslation()
	const { getMainPath } = useRouteHelper()
	const [formState, formActions] = useGlobalFormState()
	const auth = useAuth()
	const challenge = auth.challenge
	const modal = useRef<ModalRefActions>()

	useEffect(() => {
		/**
		 * if the users' login session timed out, reset formState
		 */
		if (ApiErrorCode.SESSION_TIMEOUT === challenge?.lastChallengeError) {
			formActions.resetState()
		}
		// eslint-disable-next-line
	}, [])

	return (
		<div className="center-view narrow-width flex flex--direction-column flex--align-items-center no-auto-hyphens">
			<div className="page-title">
				<h1>
					<Trans i18nKey="view.register.intro.pageTitle" />
				</h1>
			</div>

			{ApiErrorCode.SESSION_TIMEOUT === challenge?.lastChallengeError && (
				<div className="margin--vertical flex flex--align-items-start flex--justify-content-center">
					<Icon className="margin--horizontal no-margin--left" type={IconType.info} />
					<Trans
						i18nKey={`apiErrors.${challenge.lastChallengeError}`}
						values={{ error: challenge.lastChallengeError }}
					/>
				</div>
			)}

			<div className="margin--vertical">
				<p className="text-color-cyan text--bold-spaced text-align--center">
					<Trans i18nKey="view.register.intro.preparation.headline" />
				</p>

				<div className="flex margin--vertical margin--small">
					<img
						onClick={() => modal.current?.openModal()}
						className="margin--right margin--default cursor--pointer drop-shadow--bottom-3-steps"
						srcSet={`${registrationHelpVideoThumb} 1x, ${registrationHelpVideoThumb_2x} 2x`}
						width="161"
						height="91"
						alt=""
					/>

					<p>
						<Trans i18nKey="view.register.intro.preparation.bodytext">
							<Button
								type={[ButtonType.link, ButtonType.text]}
								className="text-color-cyan"
								onClick={() => modal.current?.openModal()}
							>
								{' '}
							</Button>

							<Button
								type={[ButtonType.link, ButtonType.text]}
								className="text-color-cyan"
								route={getMainPath(routesDictionary.registrationHelp)}
							>
								{' '}
							</Button>
						</Trans>
					</p>
				</div>
			</div>

			<div className="margin--top">
				<p className="text-color-cyan text--bold-spaced text-align--center">
					<Trans i18nKey="view.register.intro.letters.headline" />
				</p>
				<p>
					<Trans i18nKey="view.register.intro.letters.bodytext" />
				</p>
				<div className="margin--vertical margin--small no-margin--bottom">
					<Tooltip
						label={t('view.register.intro.letters.help.header')}
						headline={t('view.login.letterHelp.header')}
						content={
							<Trans
								i18nKey="generic.helpHotline.content"
								values={{
									emailAddress: t('generic.helpHotline.mail'),
									phoneNumber: t('generic.helpHotline.tel'),
								}}
							>
								<a href={t('generic.helpHotline.telLink')}>{'  '}</a>
								<a href={t('generic.helpHotline.mailLink')}>{'  '}</a>
							</Trans>
						}
					/>
				</div>

				<div className="flex flex--wrap margin--vertical drop-shadow--bottom-3-steps margin--small">
					<Image
						className="margin--left margin--small-negative"
						srcSet={`${anschreibenAnschreiben_01Teilnahme} 1x, ${anschreibenAnschreiben_01Teilnahme_2x} 2x`}
						width="101"
						height="141"
						alt=""
						href={getMusterZfCardUrl()}
					/>
					<Image
						className="margin--left"
						srcSet={`${anschreibenAnschreiben_02Initialpasswort} 1x, ${anschreibenAnschreiben_02Initialpasswort_2x} 2x`}
						width="101"
						height="141"
						alt=""
						href={getMusterInitPasswortUrl()}
					/>
					<Image
						className="margin--left"
						srcSet={`${anschreibenAnschreiben_03ZftanService} 1x, ${anschreibenAnschreiben_03ZftanService_2x} 2x`}
						width="101"
						height="141"
						alt=""
						href={getMusterIdPinUrl()}
					/>
				</div>

				<p className="margin--vertical">
					<Trans i18nKey="view.register.intro.letters.checksHeadline" />
				</p>

				{(t('view.register.intro.letters.checks', { returnObjects: true }) as string[]).map(
					(check, index, array) => {
						return (
							<Fragment key={uid(check, index)}>
								<Checkbox
									className="font-size-m font-family-alternative--light"
									label={check}
									value={1}
									size="large"
									checked={formState[`lettersChecked${index}`]}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										formActions.setFields({ [`lettersChecked${index}`]: e.target.checked })
									}}
								/>
								{index !== array.length - 1 && (
									<hr className="hr hr--blue hr--slim margin--bottom no-margin--top" />
								)}
							</Fragment>
						)
					}
				)}

				<Button
					type={ButtonType.primary}
					label={t('view.register.buttonLabel.intro')}
					onClick={formActions.setFields.bind(null, { checksPassed: true })}
					disabled={!(formState.lettersChecked0 && formState.lettersChecked1 && formState.lettersChecked2)}
				/>
			</div>

			<Modal
				ref={modal}
				header={t('view.register.intro.preparation.video.header')}
				buttonLabel={t('generic.close')}
				size="large"
			>
				<video
					className="video margin no-margin--top"
					src={t('generic.registerHowToVideoSrc')}
					poster={tutorialVideoPosterImage}
					controls={true}
				/>
			</Modal>
		</div>
	)
}

export default RegistrationIntro
