import { DefaultTFuncReturn } from 'i18next'
import React, { FunctionComponent, useRef } from 'react'
import AccordionGroup from './AccordionGroup'
import SelectableBar, { SelectableBarProps } from './SelectableBar'

export interface SelectableBarGroupOption extends SelectableBarProps {
	content?: string | React.ReactElement | DefaultTFuncReturn
}

export interface SelectableBarGroupProps {
	options: SelectableBarGroupOption[]
	selectedOption: any
	className?: string
}

const SelectableBarGroup: FunctionComponent<SelectableBarGroupProps> = (props) => {
	const selectedOptionIndex = useRef<number | undefined>()

	const accordionData = props.options.map((element, index) => {
		const active = props.selectedOption && props.selectedOption.value === element.value

		if (active) {
			selectedOptionIndex.current = index
		}

		return {
			header: <SelectableBar {...element} checked={active} />,
			open: active,
			content: element.content ? element.content : '',
		}
	})

	const getClassNames = (): string => {
		const classes: string[] = ['selectable-bar-group']

		if (props.className) {
			classes.push(props.className)
		}

		return classes.join(' ')
	}

	return (
		<AccordionGroup
			className={getClassNames()}
			selectedItem={selectedOptionIndex.current}
			options={accordionData}
			dataDriven={true}
		/>
	)
}

export default SelectableBarGroup
