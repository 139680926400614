import { t } from 'i18next'
import { usePath } from 'raviger'
import routesDictionary from 'routes'
import Form, { FormFields, FormFieldType } from 'shared/components/Form'
import { setSessionStorage } from 'shared/helper/sessionStorage'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'

type TRegistrationHelpChecklist = {
	onSuccess: () => void
}

export const RegistrationHelpChecklist: React.FC<TRegistrationHelpChecklist> = ({ onSuccess }) => {
	const path = usePath()
	const { getMainPath } = useRouteHelper()

	/** added 'async' to make handleSubmit return a Promise
	 * trackPromise will otherwise throw an error if no Promise object is provided
	 */
	const handleOnSubmit = async () => {
		if (path === getMainPath(routesDictionary.registerHelp)) {
			setSessionStorage('registrationHelpChecklistCompleted', true)
		}

		return true
	}

	const handleOnSuccess = () => {
		onSuccess()
	}

	const inputElements: FormFields = {
		initialLetter: {
			className: ' background-color--blue',
			checkAlignment: 'center',

			label: (
				<span className="flex gap">
					<span className="flex flex--direction-column gap">
						<span className="font-size-m  text-color-gold">
							{t('component.registrationHelpChecklist.checkList.initialLetter.headline')}
						</span>
					</span>
				</span>
			),
			fieldType: FormFieldType.checkBox,
			required: true,
			value: 1,
		},
		passwordLetter: {
			className: ' background-color--blue',
			checkAlignment: 'center',

			label: (
				<span className="flex gap">
					<span className="flex flex--direction-column gap">
						<span className="font-size-m  text-color-gold">
							{t('component.registrationHelpChecklist.checkList.passwordLetter.headline')}
						</span>
					</span>
				</span>
			),
			fieldType: FormFieldType.checkBox,
			required: true,
			value: 1,
		},
	}

	return (
		<div className="registration-help-checklist">
			<Form
				fields={inputElements}
				onSubmit={handleOnSubmit}
				onSuccess={handleOnSuccess}
				submitLabel={t('component.registrationHelpChecklist.button')}
				alwaysAllowSubmit
			/>
		</div>
	)
}
