import { IconType } from 'components/Icons'
import { DefaultTFuncReturn } from 'i18next'
import React, { FunctionComponent, useCallback, useState } from 'react'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import Icon, { IconSize } from './Icon'

export interface SubmenuBarProps {
	route?: string
	headline: string | React.ReactElement | DefaultTFuncReturn
	className?: string
	onClick?: () => void
	subheadline?: string | React.ReactElement | DefaultTFuncReturn
	icon?: IconType
	iconColor?: string
}

const SubmenuBar: FunctionComponent<SubmenuBarProps> = (props) => {
	const { navigateTo } = useRouteHelper()
	const { route, className, onClick } = props
	const [selectable] = useState<boolean>([props.onClick, props.route].some((item) => undefined !== item))

	const getClasses = useCallback((): string => {
		const classes = ['submenu-bar']

		if (className) {
			classes.push(className)
		}

		if (false === selectable) {
			classes.push('submenu-bar--unselectable')
		}

		return classes.join(' ')
	}, [className, selectable])

	const handleClick = useCallback(() => {
		if (onClick) {
			onClick()
		}

		if (route) {
			navigateTo(route)
		}
	}, [onClick, route, navigateTo])

	return (
		<div className={getClasses()} onClick={handleClick}>
			{!!props.icon && <Icon className="submenu-bar__icon" type={props.icon} color={props.iconColor} />}

			<div className="submenu-bar__headline">{props.headline}</div>

			{!!props.subheadline && <div className="submenu-bar__subheadline">{props.subheadline}</div>}

			{selectable && (
				<Icon
					className="submenu-bar__go-to-icon"
					type={IconType.arrow}
					size={IconSize.extraSmall}
					color="var(--submenu-bar-icon-color"
				/>
			)}
		</div>
	)
}

export default SubmenuBar
