import useApi from 'hooks/useApi'
import React, { FunctionComponent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import dateFormat from 'shared/helper/dateFormat'
import { DocumentData } from 'shared/interfaces'
import Button, { ButtonType } from '../shared/components/Button'
import LoadingSpinner from '../shared/components/LoadingSpinner'

export interface DocumentEntryProps {
	data: DocumentData
	className?: string
	onOpenDocument?: (data: DocumentData) => void
}

const DocumentEntry: FunctionComponent<DocumentEntryProps> = (props) => {
	const { t } = useTranslation()
	const api = useApi()
	const [documentRead, setDocumentRead] = useState<boolean | undefined>(props?.data?.read)
	const [documentRequested, setDocumentRequested] = useState<boolean>(false)
	const loadingSpinnerArea = `document-${props.data.documentId}`

	const getClasses = () => {
		const classes = ['document']

		if (props.className) {
			classes.push(`document--${props.className}`)
		}

		classes.push(`document--${documentRead ? 'read' : 'unread'}`)

		return classes.join(' ')
	}


	const gerUrlFromS3Path = async (path: string) => {
		const response = await api.getPayoutModelDocumentLink(path)

		return response ? response.url : null
	}

	const handleOnClick = async () => {
		setDocumentRequested(true)
		setDocumentRead(true)

		const documentUrl = props.data.s3Path
			? await gerUrlFromS3Path(props.data.s3Path)
			: await api.getDocumentLink(props.data.documentId, loadingSpinnerArea)

		if (documentUrl) {
			console.log(documentUrl)
			setTimeout(() => {
				const win = window.open(
					typeof documentUrl === 'string' ? documentUrl : '',
					'_blank',
					'noopener,noreferrer'
				)

				if (null !== win) {
					win.focus()
				}
			})
		}

		setDocumentRequested(false)

		if (props.onOpenDocument) {
			props.onOpenDocument(props.data)
		}
	}

	return (
		<div className={getClasses()}>
			<div className="document__title">{t(`component.documentEntry.type.${props.data.type}`)}</div>
			<div className="document__date">
				{dateFormat(props.data.timestamp, {
					year: 'numeric',
					month: '2-digit',
					day: '2-digit',
					hour: '2-digit',
					minute: '2-digit',
				})}
			</div>

			<LoadingSpinner area={loadingSpinnerArea} delay={0} />
			<Button
				className="document__link"
				label={t('component.documentEntry.buttonLabel')}
				type={[ButtonType.small, ButtonType.secondary]}
				onClick={handleOnClick}
				disabled={documentRequested}
			/>
		</div>
	)
}

export default DocumentEntry
