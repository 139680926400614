import classNames from 'classnames'
import { IconType } from 'components/Icons'
import { DefaultTFuncReturn } from 'i18next'
import React, { forwardRef, FunctionComponent, ReactElement, useState } from 'react'
import { useUniqueId } from '../hooks/useUniqueInputId'
import Icon from './Icon'

export interface CheckboxProps {
	name?: string
	value?: string | number
	label?: string | React.ReactElement | DefaultTFuncReturn
	required?: boolean
	className?: string
	onChange?: any
	errorMessage?: string | DefaultTFuncReturn
	error?: { type: string; message?: string }
	checked?: boolean
	disabled?: boolean
	size?: 'large'
	prepend?: React.ReactElement
	append?: React.ReactElement
	ref?: any
	checkPosition?: 'left' | 'right'
	checkAlignment?: 'start' | 'center' | 'end'
	highlight?: boolean
}

const Checkbox: FunctionComponent<CheckboxProps> = forwardRef<HTMLInputElement, CheckboxProps>(
	({ checkPosition = 'left', checkAlignment = 'start', errorMessage, error, ...props }, ref) => {
		const id = useUniqueId('checkbox')

		const [attributes] = useState({
			...props,
			value: undefined,
			className: undefined,
			label: undefined,
			checked: undefined,
			size: undefined,
			append: undefined,
			prepend: undefined,
			highlight: undefined,
			name: props.name ? props.name : undefined,
			defaultChecked: props.checked,
			id: props.label ? id : undefined,
		})

		const label = (): ReactElement | undefined => {
			if (!props.label) {
				return
			}

			return (
				<label className="input__label" htmlFor={id}>
					{props.label}
				</label>
			)
		}

		const pseudoLabel = (): ReactElement => {
			return (
				<label className={classNames('input__pseudo-checkbox', 'flex--no-shrink')} htmlFor={id}>
					<Icon type={IconType.animatedCheckmark} className="checkbox__svg" />
				</label>
			)
		}

		const showError = (): ReactElement | undefined => {
			return (
				<span style={{ opacity: Number(undefined !== error) }} className="input__error">
					{error?.message}
				</span>
			)
		}

		const getClasses = (): string[] => {
			const classes = []

			if (props.className) {
				classes.push(props.className)
			}

			if (props.size) {
				classes.push(`checkbox--${props.size}`)
			}

			if (props.highlight) {
				classes.push('checkbox--highlighted')
			}

			if (true === props.checked) {
				classes.push('checkbox--checked')
			}

			if (error !== undefined) {
				classes.push('checkbox--error')
			}

			if (checkPosition) {
				classes.push(`checkbox--position-${checkPosition}`)
			}

			if (checkAlignment) {
				classes.push(`checkbox--alignment-${checkAlignment}`)
			}

			return classes
		}

		return (
			<div
				className={['input', 'input--checkbox', 'checkbox']
					.concat(getClasses())
					.filter((item) => undefined !== item)
					.join(' ')}
			>
				{props.prepend && <div className="checkbox__prepend">{props.prepend}</div>}
				<input ref={ref} {...attributes} type="checkbox" value={props.value} className="input__tag" />
				{pseudoLabel()}
				{label()}
				{showError()}
				{props.append && <div className="checkbox__append">{props.append}</div>}
			</div>
		)
	}
)

export default Checkbox
