import classNames from 'classnames'
import React, { HTMLAttributes } from 'react'
import { Trans } from 'react-i18next'

const SendMfaLoginInfotext: React.FC<HTMLAttributes<HTMLDivElement>> = ({ className }) => {
	return (
		<div className={classNames(className, 'margin--vertical')}>
			<p className="text-color-cyan">
				<Trans i18nKey="view.login.sendMfa.infotext" />
			</p>
		</div>
	)
}

export default SendMfaLoginInfotext
