// @ts-ignore
import { PieChartData } from 'components/SunburstChart'
import React, { FunctionComponent } from 'react'
import { uid } from 'react-uid'
import Accordion from 'shared/components/Accordion'
import { numberFormat } from 'shared/helper/numberFormats'
import { FundCompositionContextProps, FundCompositionDataContext } from './FundCompositionContext'

const FundCompositionAccordion: FunctionComponent = (props) => {
	return (
		<FundCompositionDataContext.Consumer>
			{(context: FundCompositionContextProps) => {
				const { calculatedData, toggleSlice, colorScheme, setNestedHeight } = context

				const getLevelHeightVar = (level: number) => {
					const foundItem = getNestedStartItem(calculatedData, level)
					let levelHeight: any

					if (foundItem) {
						levelHeight = calculateHeight(foundItem, 0)
					} else {
						levelHeight = '0'
					}

					return {
						'--accordion-level-height': `${levelHeight.toString()}px`,
					} as React.CSSProperties
				}

				const getNestedStartItem: any = (
					item: PieChartData[],
					targetLevel: number,
					currentLevel: number = 0
				) => {
					const startItem = item.find((element) => element.showChildren === true)

					if (startItem === undefined) {
						return false
					}

					if (startItem && startItem.components && currentLevel < targetLevel) {
						return getNestedStartItem(startItem.components as PieChartData[], targetLevel, currentLevel + 1)
					}

					return startItem
				}

				const calculateHeight = (dataElement: PieChartData, height: number) => {
					height += dataElement.height ? dataElement.height : 1

					if (dataElement.components) {
						const obj = dataElement.components as PieChartData[]

						const foundItem = obj.find((element) => element.showChildren === true)

						if (foundItem) {
							height = calculateHeight(foundItem, height)
						}
					}

					return height
				}

				const renderData: any = (chartData: PieChartData[] | PieChartData, level: number = 0) => {
					let chartDataArray: PieChartData[]
					chartDataArray = convertChartData(chartData)

					return chartDataArray.map((item, index) => {
						return (
							<React.Fragment key={uid(item)}>
								{item.components ? renderDataAccordion(item, level) : getSingleRowJSX(item)}
							</React.Fragment>
						)
					})
				}

				const renderDataAccordion: any = (chartData: PieChartData[] | PieChartData, level: number) => {
					let chartDataArray: PieChartData[]
					chartDataArray = convertChartData(chartData)

					return chartDataArray.map((item, index) => (
						<React.Fragment key={uid(item)}>{getAccordionJSX(item, level)}</React.Fragment>
					))
				}

				const getAccordionJSX = (item: PieChartData, level: number) => {
					return (
						<Accordion
							level={level}
							open={item.showChildren}
							onOpen={() => toggleSlice(item.indices, !item.showChildren)}
							contentHeight={getLevelHeightVar(level + 1)}
							afterRender={(accordionHeight: number) => setNestedHeight(item.indices, accordionHeight)}
							header={
								<div data-level={level.toString()} className="data-row data-row--accordion">
									<div className="data-row__label">{item.name}</div>
									<div className="data-row__amount">
										{numberFormat(item.amount)}{' '}
										{item.unit}
									</div>
								</div>
							}
							highlighted={item.highlight || false}
							highlightColor={colorScheme[item.indices[0]] || 'var(--color-white)'}
						>
							{renderData(item.components, level + 1)}
						</Accordion>
					)
				}

				const convertChartData: any = (chartData: PieChartData[] | PieChartData) => {
					let chartDataArray = []
					if (!Array.isArray(chartData)) {
						chartDataArray = [chartData]
					} else {
						chartDataArray = chartData
					}

					return chartDataArray
				}

				const getSingleRowJSX: any = (item: PieChartData, level: number) => {
					const classes = `data-row data-row--single ${item.highlight ? 'data-row--highlighted' : ''}`

					return (
						<div data-level={level} className={classes}>
							<div className="data-row__label">{item.name}</div>
							<div className="data-row__amount">
								{numberFormat(item.amount)}
								{item.unit}
							</div>
						</div>
					)
				}

				return (
					<div style={getLevelHeightVar(0)} className="fund-composition__accordion">
						{renderData(calculatedData)}
					</div>
				)
			}}
		</FundCompositionDataContext.Consumer>
	)
}

export default FundCompositionAccordion
