import axios from 'axios'
import useApi, { QueryKey } from 'hooks/useApi'
import { FC, useEffect, useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'
import Button, { ButtonType } from 'shared/components/Button'
import { components } from 'types/api-interface'
import { TDocument } from './PayoutDocumentsModal'

export const PayoutDocumentUpload: FC<{
	documents: TDocument[]
	onUploadComplete?: () => void
	onAbort?: () => void
	onPrimaryButtonClick?: () => void
	docType: components['schemas']['DocumentType']
}> = (props) => {
	const [status, setStatus] = useState<'UPLOADING' | 'PROCESSING' | 'COMPLETE'>('UPLOADING')
	const api = useApi()

	const controllerRef = useRef<any>()

	const { refetch } = useQuery(QueryKey.payoutDocuments, api.getPayoutModelDocuments)

	const [progress, setProgress] = useState<{ progress: number; size: number }[]>(
		props.documents.map((v) => {
			return { progress: 0, size: v.file.size }
		})
	)

	const onUploadProgress = (fileProgress: number, i: number) => {
		const updateProgress = [...progress]
		updateProgress[i].progress = fileProgress
		setProgress(updateProgress)
	}

	const onAbort = () => {
		if (controllerRef.current) {
			controllerRef.current.cancel()
		}

		if (props.onAbort) {
			props.onAbort()
		}
	}

	const getDoctsWithUrls = async (): Promise<TDocument[]> => {
		const docsToSend: TDocument[] = []

		for (var i = 0; i < props.documents.length; i++) {
			const doc = props.documents[i]

			if (doc.type) {
				const data = await api.getUploadUrl({
					type: doc.type,
					mimeType: doc.file.type as components['schemas']['MimeType'],
				})
				docsToSend.push({ uploadUrl: data.url, ...doc })
			}
		}

		return docsToSend
	}

	const uploadFiles = async () => {
		controllerRef.current = axios.CancelToken.source()

		const docsWithUploadUrls = await getDoctsWithUrls()

		if (!docsWithUploadUrls) {
			console.log('error: did not receive upload urls')
			return
		}

		console.log('uploading files', docsWithUploadUrls)
		Promise.all(
			docsWithUploadUrls.map((v) => {
				if (v.uploadUrl && v.mimeType && v.page !== undefined) {
					return api.uploadFile(
						v.file,
						v.uploadUrl,
						v.mimeType,
						onUploadProgress,
						v.page,
						controllerRef.current.token
					)
				}
				return undefined
			})
		)
			.then((res) => {
				if (
					res.some((response) => {
						return response.status && response.status === 200
					})
				) {
					setStatus('COMPLETE')
					refetch()
				}
			})

			.catch((e) => {
				console.log(e)
			})
	}

	const calcTotalUploadProgress = (): number => {
		const total = progress.reduce(
			(prev, curr) => {
				return {
					size: prev.size + curr.size,
					progress: prev.progress + curr.progress,
				}
			},
			{ size: 0, progress: 0 }
		)

		const uploadProgressPercent = total.progress / total.size

		if (uploadProgressPercent === 1) {
		}

		return uploadProgressPercent
	}

	useEffect(() => {
		uploadFiles()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (status === 'COMPLETE') {
			props.onUploadComplete && props.onUploadComplete()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status])
	return (
		<div className="upload">
			<h3 className="headline">
				{status === 'UPLOADING' && <Trans i18nKey="view.payoutOptions.chapterThree.upload.inProgress"></Trans>}
				{status === 'COMPLETE' && <Trans i18nKey="view.payoutOptions.chapterThree.upload.completed"></Trans>}
			</h3>
			<div
				className="progress-bar"
				style={{ '--progress': calcTotalUploadProgress() } as React.CSSProperties}
			></div>

			<p className="progress-text">
				{status === 'UPLOADING' && <Trans i18nKey="view.payoutOptions.chapterThree.upload.inProgress"></Trans>}
				{status === 'PROCESSING' && 'Daten werden verarbeitet, einen moment bitte...'}
				{status === 'COMPLETE' && (
					<Trans i18nKey="view.payoutOptions.chapterThree.upload.completedCaption"></Trans>
				)}
			</p>

			{status === 'UPLOADING' && (
				<Button
					type={ButtonType.small}
					onClick={() => {
						onAbort()
					}}
				>
					Upload Abbrechen
				</Button>
			)}

			<Button
				type={ButtonType.primary}
				onClick={() => {
					props.onPrimaryButtonClick && props.onPrimaryButtonClick()
				}}
				disabled={status === 'UPLOADING' || status === 'PROCESSING'}
			>
				{status !== 'COMPLETE' && <Trans i18nKey="view.payoutOptions.chapterThree.upload.pleaseWait" />}
				{status === 'COMPLETE' && <Trans i18nKey="view.payoutOptions.chapterThree.upload.uploadComplete" />}
			</Button>
		</div>
	)
}
