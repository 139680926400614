import useApi, { QueryKey } from 'hooks/useApi'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import routesDictionary from 'routes'
import Form, { FormFieldType } from 'shared/components/Form'
import { TextInputType } from 'shared/components/TextInput'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { UserProfileData } from 'shared/interfaces'
import { useBackButtonPath } from 'state/useHeaderState'
import { components } from 'types/api-interface'

const FormStepPerson: React.FC<{
	data?: components['schemas']['AuszahlungsoptionenArbeitskopie'] | undefined | void
	userData: UserProfileData | void | undefined
	editable?: boolean
	onSubmit: (obj: components['schemas']['AuszahlungsoptionenArbeitskopie'] | null) => void
}> = (props) => {
	const { t } = useTranslation()
	const { getChildPath } = useRouteHelper()
	useBackButtonPath(`${getChildPath(routesDictionary.payoutOptions, 'payoutModelInputForm')}/0`)
	const api = useApi()

	const data = props.data
	const countryQuery = useQuery(QueryKey.countries, api.getCountriesOptions)
	const userProfileQuery: any = useQuery(api.getQuery(QueryKey.userProfile))

	const profileData = userProfileQuery?.data

	const formatDate = (dateString: string) => {
		return dateString
			.split('.')
			.reverse()
			.map((v) => {
				return v.length === 1 ? `0${v}` : v
			})
			.join('-')
	}

	// This function is used to remove the country code from the phone number, since users might add it manually
	const removeCountryCodes = (phoneNumber: string) => {
		const countryCodes = countryQuery.data?.map((v) => v.callingCode)
		if (!countryCodes) {
			return phoneNumber
		}

		let strippedNumber = phoneNumber

		countryCodes.forEach((v) => {
			if (phoneNumber.startsWith(v)) {
				strippedNumber = phoneNumber.replace(v, '')
			}
		})

		return strippedNumber
	}

	return (
		<>
			<h3>{t('view.PayoutInputForm.personal.headline')}</h3>
			{data && countryQuery.data && userProfileQuery.data && (
				<Form
					fields={{
						lastName: {
							autoCapitalize: 'on',
							fieldType: FormFieldType.text,
							label: t('view.PayoutInputForm.personal.lastName'),
							required: true,
							value: data?.identity?.name.lastName,
							readOnly: !props.editable,
						},
						firstName: {
							autoCapitalize: 'on',
							fieldType: FormFieldType.text,
							label: t('view.PayoutInputForm.personal.firstName'),
							required: true,
							value: data?.identity?.name.firstName,
							readOnly: !props.editable,
						},
						zfId: {
							fieldType: FormFieldType.text,
							label: t('view.PayoutInputForm.personal.zfId'),
							value: profileData?.identifikationsnummer?.value as unknown as string,
							readOnly: true,
						},
						sex: {
							fieldType: FormFieldType.select,
							className: 'select-with-background',
							useCustom: true,
							label: t('view.PayoutInputForm.personal.sex.label'),
							alternativeStyle: true,
							placeholder: t('view.PayoutInputForm'),
							value: 'M',
							options: [
								{ label: t('view.PayoutInputForm.personal.sex.options.1'), value: 'W' },
								{ label: t('view.PayoutInputForm.personal.sex.options.0'), value: 'M' },
							],
							required: true,
							disabled: !props.editable,
						},
						birthDay: {
							fieldType: FormFieldType.text,
							type: TextInputType.date,
							label: t('view.PayoutInputForm.personal.birthday'),
							value: data?.identity?.birthday,
							readOnly: !props.editable,
						},
						address: {
							autoCapitalize: 'on',
							fieldType: FormFieldType.text,
							label: t('view.PayoutInputForm.personal.address'),
							value: data?.identity?.contactDetails.street,
							required: true,
							readOnly: !props.editable,
						},
						city: {
							autoCapitalize: 'on',
							fieldType: FormFieldType.text,
							label: t('view.PayoutInputForm.personal.city'),
							value: data?.identity?.contactDetails.city,
							required: true,
							readOnly: !props.editable,
						},
						plz: {
							fieldType: FormFieldType.text,
							label: t('view.PayoutInputForm.personal.postalCode'),
							value: data?.identity?.contactDetails.zip,
							required: true,
							type: TextInputType.number,
							readOnly: !props.editable,
						},
						country: {
							fieldType: FormFieldType.select,
							value:
								data?.identity?.contactDetails.country &&
								countryQuery?.data?.filter((v) => v.name === data.identity?.contactDetails.country)
									.length
									? data?.identity?.contactDetails.country
									: 'Deutschland',
							className: 'select-with-background',
							useCustom: true,
							label: t('view.PayoutInputForm.personal.country'),
							alternativeStyle: true,
							options: countryQuery?.data?.map((v) => {
								return { value: v.name, label: v.name }
							}) || [{ value: '', label: '' }],
							required: true,
							disabled: !props.editable,
						},
						countryCallingCode: {
							fieldType: FormFieldType.select,
							value:
								countryQuery?.data?.find(
									(v) => data?.identity?.contactDetails.countryCallingCode === v.callingCode
								)?.name || 'Deutschland',
							className: 'select-with-background',
							useCustom: true,
							label: t('view.PayoutInputForm.personal.countryCallingCode'),
							alternativeStyle: true,
							options: countryQuery?.data?.map((v, i) => {
								return { value: v.name, label: v.name + ' (' + v.callingCode + ')' }
							}) || [{ value: '', label: '' }],

							required: true,
							placeholder: 'pick country',
							disabled: !props.editable,
						},
						phone: {
							type: TextInputType.text,
							label: t('view.PayoutInputForm.personal.phone'),
							value: data?.identity?.contactDetails.phoneNumber,
							pattern: '^\\+?[0-9]+',
							fieldType: FormFieldType.text,
							readOnly: !props.editable,
						},
						mail: {
							fieldType: FormFieldType.text,
							type: TextInputType.email,
							label: t('view.PayoutInputForm.personal.mail'),
							value: data?.identity?.contactDetails.emailAddress,
							readOnly: !props.editable,
						},
					}}
					onSubmit={(e: any) => {
						const submitData = {
							identity: {
								name: {
									firstName: e.firstName,
									lastName: e.lastName,
								},
								gender: e.sex,
								birthday: props.editable ? formatDate(e.birthDay) : '',
								contactDetails: {
									street: e.address,
									city: e.city,
									country: e.country,
									countryCallingCode: countryQuery.data?.find((v) => v.name === e.countryCallingCode)
										?.callingCode,
									phoneNumber: removeCountryCodes(`${e.phone || ''}`),
									emailAddress: e.mail || '',
									zip: `${e.plz}`,
								},
							},
						}
						props.onSubmit(props.editable ? submitData : null)
						// TODO: this is only a  hack since the form expects an api request return obj
						return { successful: true, status: 200 }
					}}
					children={
						<>
							{' '}
							<div className="subheadline subheadline--personal ">
								{t('view.PayoutInputForm.personal.headlines.personalData')}
							</div>
							<div className="subheadline subheadline--address">
								{t('view.PayoutInputForm.personal.headlines.address')}
							</div>
							<div className="subheadline subheadline--contact">
								{t('view.PayoutInputForm.personal.headlines.contact')}
							</div>
						</>
					}
				/>
			)}
		</>
	)
}

export default FormStepPerson
