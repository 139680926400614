import { useBreakpointClasses } from 'hooks/useBreakpointClasses'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { useClientClasses } from 'shared/hooks/useClientClasses'
import { ProvideAuth, useAuth } from '../hooks/useAuth'
import { RouteWrapper } from './RouteWrapper'

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			staleTime: 30000,
			cacheTime: Number(process.env.REACT_APP_AUTO_LOGOUT_IN_MINUTES) * 60 * 1000,
		},
	},
})

window.process = {
	...window.process,
}

const App: React.FC = () => {
	useBreakpointClasses()
	useAuth()
	useClientClasses()

	return (
		<QueryClientProvider client={queryClient}>
			<ReactQueryDevtools initialIsOpen={false} />
			<ProvideAuth>
				<RouteWrapper />
			</ProvideAuth>
		</QueryClientProvider>
	)
}

export default App
