import useApi, { QueryKey } from 'hooks/useApi'
import React, { useCallback } from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'
import CompensationDataTable from 'shared/components/CompensationDataTable'
import CompensationShares from 'shared/components/CompensationShares'
import {
	CompensationDisclaimer,
	CompensationStatus,
	CompensationUnit,
	OPTEOptions,
	OPTTOptions,
	RiskOptions,
} from 'shared/enums'
import { currencyFormat, percentFormat } from 'shared/helper/numberFormats'

export interface RiskPreviewCompensationProps {
	className?: string
	type?: OPTEOptions | OPTTOptions
	riskId: RiskOptions
}

const RiskPreviewCompensation: React.FC<RiskPreviewCompensationProps> = (props) => {
	const { className, type, riskId } = props

	const api = useApi()

	const { data } = useQuery(
		[QueryKey.riskSimulationCompensation, { riskOption: type, riskId }],
		api.getRiskSimulationCompensation
	)

	const getClasses = useCallback((): string => {
		const classes: string[] = ['risk-preview-compensation']

		if (className) {
			classes.push(className)
		}

		return classes.join(' ')
	}, [className])

	return (
		<div className={getClasses()}>
			{!!data && (
				<>
					<p className="text-color-cyan">
						<b>
							<Trans
								i18nKey={`view.conversions.singleMonth.${
									CompensationStatus.open === data.status ? 'unconfirmed' : 'confirmed'
								}CompensationAmount`}
							/>
							:{' '}
							{CompensationUnit.percent === data.unit
								? percentFormat(data.value)
								: currencyFormat(data.value)}
						</b>
					</p>

					<CompensationShares
						className="margin--vertical"
						ownContribution={data.ownContribution}
						employerContribution={data.employerContribution}
						sum={data.pensionTrust}
					/>

					<CompensationDataTable
						ownContribution={data.ownContribution}
						monthlyCompensation={data.monthlyCompensation}
						employerBaseAmount={data.employerBaseAmount}
						postGrantEmployerBaseAmount={data.postGrantEmployerBaseAmount}
						matchingAmount={data.matchingAmount}
						postGrantMatchingAmount={data.postGrantMatchingAmount}
						sum={data.pensionTrust}
						yearlySum={data.yearlySum}
						status={data.status}
						unit={data.unit}
						disclaimer={CompensationDisclaimer.expected}
					/>
				</>
			)}
		</div>
	)
}

export default RiskPreviewCompensation
