import { IconType } from 'components/Icons'
import useApi, { QueryKey } from 'hooks/useApi'
import { t } from 'i18next'
import React from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import Icon from 'shared/components/Icon'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'

const PayoutSuccess: React.FC = () => {
	const { getChildPath, navigateTo } = useRouteHelper(routesDictionary.payoutOptions)

	const onClick = async () => {
		navigateTo(getChildPath(routesDictionary.payoutOptions, 'overview'))
	}
	const api = useApi()
	const { data: isEditable } = useQuery(QueryKey.payoutModelEditable, api.isPayoutModelEditable)

	return (
		<div className="grid center-view payout-success">
			<div className="grid__top"></div>
			{isEditable?.editable && (
				<div className="grid__center">
					<h3>
						<b>
							<Trans i18nKey="view.payoutOptions.chapterOne.success.headline"></Trans>{' '}
						</b>
					</h3>
					<Icon
						type={IconType.success}
						color={'var(--color-cyan)'}
						className="center-view margin--top margin--large"
					></Icon>
				</div>
			)}

			<div className="grid__bottom">
				<Button
					className="margin--top margin--large"
					type={ButtonType.primary}
					label={t('view.payoutOptions.chapterOne.success.button')}
					onClick={onClick}
				></Button>
			</div>
		</div>
	)
}

export default PayoutSuccess
