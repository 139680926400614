import useApi, { QueryKey } from 'hooks/useApi'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import Form, { FormField, FormFieldType } from 'shared/components/Form'
import { TextInputType } from 'shared/components/TextInput'
import { FormSubmitFields } from 'shared/hooks/useForm'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { IRiskPartnerData } from 'shared/interfaces'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'

const RBPartnerForm: React.FC = () => {
	useBackButtonPath()
	useNavigationTitle(<Trans i18nKey="view.riskBenefit.pageTitle.default" />)

	const { t } = useTranslation()
	const api = useApi()

	const { data } = useQuery(QueryKey.riskPartnerData, api.getRiskPartnerData)
	const [cachedData, setCachedData] = useState<{ [key: string]: string }>()
	const { getChildPath, navigateTo } = useRouteHelper()

	useEffect(() => {
		const currentData = sessionStorage.getItem('riskPartnerData')
		setCachedData(currentData ? JSON.parse(currentData) : {})
		sessionStorage.removeItem('riskPartnerData')
	}, [])

	const inputElements = useMemo(() => {
		if (undefined === data || undefined === cachedData) {
			return
		}

		const inputKeys: { [key: string]: FormField | {} } = {
			VORNA_EP: { type: TextInputType.text },
			NACHN_EP: { type: TextInputType.text },
			GBDAT_EP: { type: TextInputType.date },
			GBORT_EP: { type: TextInputType.text },
			STRAS_EP: { type: TextInputType.text },
			PLZ_EP: { type: TextInputType.text },
			ORT_EP: { type: TextInputType.text },
			LAND_EP: { type: TextInputType.text },
		}

		return Object.entries(inputKeys).reduce((fields: { [key: string]: any }, [key, settings]) => {
			fields[key] = {
				value: cachedData[key] || data[key as keyof IRiskPartnerData] || '',
				required: true,
				fieldType: FormFieldType.text,
				label: t(`view.riskBenefit.formFieldLabels.${key}`),
				...settings,
			}

			return fields
		}, {})
	}, [data, cachedData, t])

	const onHandleSubmit = useCallback((submittedFields: FormSubmitFields) => {
		if (!isValidGBDAT(submittedFields["GBDAT_EP"] as string)) {
			return {
				errorMessages: t(`view.riskBenefit.error.GBDAT_Error`),
				successful: false
			}
		}
		sessionStorage.setItem(
			'riskPartnerData',
			JSON.stringify({
				...submittedFields,
			})
		)

		return true
	}, [])

	const isValidGBDAT = (date: string) => {
		const datum: Date = new Date(date);

		if (!isNaN(datum.getTime())) {
			return datum.getFullYear() >= 1900;
		} else {
			return false;
		}
	}

	return (
		<div className="risk-benefit center-view grid grid--center-scroll rb-partner-form">
			<h3 className="text-align--left grid__top">
				<Trans i18nKey="view.riskBenefit.partner.headline" />
			</h3>
			<div className="grid__center">
				<Trans i18nKey="view.riskBenefit.overview.designatedPartner.description" />

				<p className="margin--vertical">
					<b>
						<Trans i18nKey="view.riskBenefit.partner.setPartner" />
					</b>
				</p>

				<p>
					<Trans i18nKey="view.riskBenefit.partner.description" />
				</p>

				{inputElements && cachedData && (
					<Form
						fields={inputElements}
						onSubmit={onHandleSubmit}
						onSuccess={() => {
							navigateTo(getChildPath(routesDictionary.riskBenefit, 'RBPartnerConditions'))
						}}
						submitLabel={t('generic.next')}
					>
						{' '}
						<Button
							className="no-margin--bottom"
							onClick={() => {
								navigateTo(getChildPath(routesDictionary.riskBenefit, 'RBPartnerRemove'))
							}}
							type={ButtonType.secondary}
							label={t('view.riskBenefit.overview.designatedPartner.removePartner')}
						/>
					</Form>
				)}
			</div>
		</div>
	)
}

export default RBPartnerForm
